import React from "react";
import { refs } from "../../repos/constants";
import { formatCurrency } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";

import { TableEmptyRow } from "../shared/DataTable";
import { TableLoadingIndicator } from "../shared/DataTable";

export const EntryGeneratorsDetailsTable = ({
                                              editingEntry, isLoading, orderConfirmationDetails, currency,
                                              isFormOwner, isFormSubmitted, onItemRowSelected, onItemRemoveClicked,
                                            }) => {
  //#region Render
  return (
    <div className={"form-section table-section"}>
      <div className={"data-table"}>
        <table>
          <thead>
          <tr>
            <th scope="col" className={"index-col-head"}>#</th>
            <th scope="col">Generator Model (or ATS)</th>
            <th scope="col" style={{ width: "15%" }}>Generator Type</th>
            <th scope="col" className={"right-aligned"} style={{ width: "15%" }}>
              Price
            </th>
            <th scope="col" className={"right-aligned"} style={{ width: "10%" }}>
              Quantity
            </th>
            <th scope="col" className={"right-aligned"} style={{ width: "15%" }}>
              Amount
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {isLoading && <TableLoadingIndicator colspan={7} />}

          {orderConfirmationDetails && orderConfirmationDetails.length > 0 && !isLoading &&
            orderConfirmationDetails.map((item, index) =>
              <EntryDetailsRow key={item['detailsId']} index={index} item={item} currency={currency}
                               isLoading={isLoading} isFormOwner={isFormOwner} isFormSubmitted={isFormSubmitted}
                               onItemRowSelected={onItemRowSelected}
                               onItemRemoveClicked={onItemRemoveClicked}
              />
            )
          }

          {(!orderConfirmationDetails || orderConfirmationDetails.length === 0) && !isLoading &&
            <TableEmptyRow colSpan={7} />
          }

          <EntryDetailsTableFooterRow currency={currency} orderConfirmationDetails={orderConfirmationDetails} />
          </tbody>
        </table>
      </div>
    </div>
  )
  //#endregion
}


const EntryDetailsRow = ({
                           index, item, currency, isLoading, isFormOwner, isFormSubmitted,
                           onItemRowSelected, onItemRemoveClicked,
                         }) => {
  //#region Utilities
  const renderItemDisplayName = () => {
    let _displayTerm = '';
    if (item['gensetModel']) _displayTerm = item['gensetModel'];
    else if (item['atsName']) _displayTerm = item['atsName'];
    else _displayTerm = <span className={"na-value"}>(NA)</span>
    return _displayTerm;
  }

  const renderItemType = () => {
    let itemType = item['type'];
    let generatorType = refs.inventory.generatorType;
    if (itemType === generatorType.none) return <span className={"na-value"}>{generatorType[itemType]}</span>
    else return <span>{generatorType[itemType]}</span>
  }

  const getItemAmount = () => {
    return formatCurrency(item['price'] * item['quantity']);
  }
  //#endregion

  return (
    <tr>
      <td className={"index-col"}>{index + 1}</td>
      <td className={"has-hint-icon"}>
        {isFormOwner() &&
          <>
            {!isFormSubmitted() &&
              <>
                <a href="#" role={"button"} onClick={(ev) => onItemRowSelected(ev, item, index)}>
                  {renderItemDisplayName()}
                </a>
                {item.isEditing && <i className="fa-solid fa-pen-to-square" style={{ color: 'gray' }}></i>}
              </>
            }
            {isFormSubmitted() && renderItemDisplayName() && <i>hi there!</i>}
          </>
        }
        {!isFormOwner() && renderItemDisplayName()}
      </td>
      <td>
        {renderItemType()}
      </td>
      <td className={"right-aligned"}>
        <span className="currency-symbol">{getCurrency(currency).sign}</span>
        {formatCurrency(item['price'])}
      </td>
      <td className={"right-aligned"}>
        {item['quantity']}
      </td>
      <td className={"right-aligned"}>
        <span className="currency-symbol">{getCurrency(currency).sign}</span>
        {getItemAmount()}
      </td>
      <td className={"row-controls"}>
        {!isFormSubmitted() &&
          <button type={"button"} className={"btn btn-light btn-sm danger-button"} title={"Remove item"}
                  disabled={isLoading} onClick={(ev) => onItemRemoveClicked(ev, item, index)}>
            <i className="fa-solid fa-circle-minus"></i>
          </button>
        }
      </td>
    </tr>
  )
}


export const calculateTotalAmount = (orderConfirmationDetails) => {
  if (!orderConfirmationDetails || orderConfirmationDetails.length === 0) return 0;

  let amounts = orderConfirmationDetails.map(item => item['price'] * item['quantity']);
  return amounts.reduce((accumulated, current) => accumulated + current);
}


const EntryDetailsTableFooterRow = (props) => {
  const {
    currency,
    orderConfirmationDetails,
  } = props;

  return (
    <tr className={"footer-row"}>
      <td colSpan={5} className={"right-aligned"}>
        Total:
      </td>
      <td className={"right-aligned"}>
        <span className={"currency-symbol"}>{getCurrency(currency).sign}</span>
        {formatCurrency(calculateTotalAmount(orderConfirmationDetails))}
      </td>
      <td colSpan={3}>
      </td>
    </tr>
  )
}
