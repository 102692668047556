import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { refServices } from "../../repos/apiServices";

import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { FormSideLabel } from "./FormSideLabel";

export const EntryPOEstimation = ({
                                    poDuration, onPoDurationChanged,
                                    poDate, onPoDateChanged,
                                    isEditing, editingEntry,
                                    poEstimationUpdateRequest,
                                    hasErrors, poEstimationErrors, isSubmitting,
                                  }) => {
  //#region States
  const [poDurationOptions, setPoDurationOptions] = useState([]);
  //#endregion

  //#region Effects
  useEffect(() => {
    loadPoDurations();
  }, []);

  const loadPoDurations = () => {
    setPoDurationOptions(refs.sales.poDurationOptions);
  }
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Utilities
  const getDateClassName = () => {
    let className = "form-control";
    if (poDate) className += " has-autocompleted-value";
    return className;
  }

  const shouldRenderInputField = () => {
    return isEditing;
  }

  const shouldDisableInputField = () => {
    return false;
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={"form-section"}>
        <h2 title={"Purchase Order Estimation"}>PO Estimation</h2>

        <div className={"entry-form inquiry-form"}>
          <div className={"form-label"}>
            <label htmlFor={"poDuration"}>
              Duration:
            </label>
          </div>
          <div className={"form-input"}>
            {shouldRenderInputField() &&
              <select id={"poDuration"} name={"poDuration"} className={"form-control form-select"} disabled={isSubmitting}
                      value={poDuration} onChange={onPoDurationChanged}>
                {poDurationOptions && poDurationOptions.map(duration => <option key={duration['id']} value={duration['id']}>{duration['name']}</option>)}
              </select>
            }
            {!shouldRenderInputField() && editingEntry &&
              <div className={"readonly-field"}>
                {refs.sales.poDurations[editingEntry['estimatePoDuration']]}
              </div>
            }
          </div>
          {poEstimationUpdateRequest  && poEstimationUpdateRequest["estimatePoDuration"] !== poEstimationUpdateRequest["estimatePoDurationNew"] &&
            <FormSideLabel value={refs.sales.poDurations[poEstimationUpdateRequest["estimatePoDurationNew"]]} />
          }

          {poDuration !== refs.sales.poDurations.unspecified &&
            <>
              <div className={"form-label"}>
                <label htmlFor={"poDate"}>
                  Date:
                </label>
              </div>
              <div className={"form-input"}>
                {shouldRenderInputField() &&
                  <>
                    <DatePicker id={"poDate"} className={getDateClassName()} placeholderText={"Type a valid future date or choose a preset duration"} required={false}
                                dateFormat={formatters.datetimePicker} minDate={new Date()} todayButton={"Today"} showWeekNumbers
                                disabled={isSubmitting} autoComplete={"off"}
                                selected={poDate} onChange={onPoDateChanged} />
                    <FieldErrorMessages visible={hasErrors} messages={poEstimationErrors["poDate"]} />
                  </>
                }
                {!shouldRenderInputField() && editingEntry &&
                  <div className={"readonly-field"}>
                    <Moment date={editingEntry['estimatePoDate']} format={formatters.datetime} />
                  </div>
                }
              </div>
              {poEstimationUpdateRequest && poEstimationUpdateRequest['estimatePoDate'] !== poEstimationUpdateRequest['estimatePoDateNew'] &&
                <FormSideLabel value={poEstimationUpdateRequest['estimatePoDateNew']} type={'datetime'} />
              }
            </>
          }

        </div>
      </div>
    </>
  )
  //#endregion
}
