import React from "react";

export const SortIndicator = ({ isAscending = true }) => {
  const getStyle = () => {
    return {
      color: 'gray',
      marginLeft: '8px',
    }
  }

  return (
    <span style={getStyle()}>
      {isAscending && <i className={"fa-solid fa-caret-up"} />}
      {!isAscending && <i className={"fa-solid fa-caret-down"} />}
    </span>
  )
}
