//#region "Listing contexts"
export const contexts = {
  salesInquiries: 'cxt_sales_inquiries',
  quotationRequests: 'cxt_quotation_requests',
  orderConfirmations: 'cxt_order_confirmations',

  generatorStocks: 'cxt_stocks',
  stockInspections: 'cxt_stock_inspections',
  serviceProfiles: 'cxt_service_profiles',
  serviceGeneratorJobHistories: 'cxt_service_generator_job_histories',
  jobHistories: 'cxt_job_histories',

  customerProfiles: 'cxt_customer_profiles',
  contactLogs: 'cxt_contact_logs',
};

export const clearAllViewContexts = () => {
  localStorage.removeItem(contexts.salesInquiries);
  localStorage.removeItem(contexts.quotationRequests);
  localStorage.removeItem(contexts.orderConfirmations);

  localStorage.removeItem(contexts.generatorStocks);
  localStorage.removeItem(contexts.stockInspections);
  localStorage.removeItem(contexts.serviceProfiles);
  localStorage.removeItem(contexts.serviceGeneratorJobHistories);
  localStorage.removeItem(contexts.jobHistories);

  localStorage.removeItem(contexts.customerProfiles);
  localStorage.removeItem(contexts.contactLogs);
};

export const loadListContext = (contextName) => {
  let context = localStorage.getItem(contextName);
  return context ? JSON.parse(context) : null;
};

const addOrUpdateContextProperty = (contextName, propertyName, propertyValue) => {
  let context = loadListContext(contextName);
  if (context) {
    context[propertyName] = propertyValue;
  } else {
    context = {};
    context[propertyName] = propertyValue;
  }
  localStorage.setItem(contextName, JSON.stringify(context));
  return context;
};

export const getPaginationContext = (contextName) => {
  let context = loadListContext(contextName);
  if (context === null) return null;
  if (context['pagination'] === '' || context['pagination'] === null) return null;
  return context['pagination'];
};

export const savePaginationContext = (contextName, updatedPagination) => {
  return addOrUpdateContextProperty(contextName, 'pagination', updatedPagination);
};

export const getUpdateRequestsContext = (contextName) => {
  let context = loadListContext(contextName);
  if (context === null) return null;
  if (context['updateRequests'] === '' || context['updateRequests'] === null) return null;
  return context['updateRequests'];
};

export const saveUpdateRequestsContext = (contextName, isModalOpened = false) => {
  return addOrUpdateContextProperty(contextName, 'updateRequests', {
    'isModalOpened': isModalOpened,
  });
};

export const getCancelRequestsContext = (contextName) => {
  let context = loadListContext(contextName);
  if (context === null) return null;
  if (context['cancelRequests'] === '' || context['cancelRequests'] === null) return null;
  return context['cancelRequests'];
};

export const saveCancelRequestsContext = (contextName, isModalOpened = false) => {
  return addOrUpdateContextProperty(contextName, 'cancelRequests', {
    'isModalOpened': isModalOpened,
  });
};

export const getSearchPanelOpenContext = (contextName) => {
  let context = loadListContext(contextName);
  if (context === null) return null;
  if (context['isSearchPanelOpen'] === '' || context['isSearchPanelOpen'] === null) return null;
  return context['isSearchPanelOpen'];
};

export const saveSearchPanelOpenContext = (contextName, isSearchOpen) => {
  return addOrUpdateContextProperty(contextName, 'isSearchPanelOpen', isSearchOpen);
};

export const getSearchPanelOptionsContext = (contextName) => {
  let context = loadListContext(contextName);
  if (context === null) return null;
  return context['searchPanelOptions'] ?? null;
};

export const saveSearchPanelOptionsContext = (contextName, searchOptions) => {
  return addOrUpdateContextProperty(contextName, 'searchPanelOptions', searchOptions);
};

export const clearSearchPanelOptionsContext = (contextName) => {
  let context = loadListContext(contextName);
  if (context === null) return null;
  delete context['searchPanelOptions'];
  localStorage.setItem(contextName, JSON.stringify(context));
};
//#endregion

//#region "Listing view settings"
export const viewSettings = {
  salesInquiries: 'vs_sales_inquiries',
  quotationRequests: 'vs_quotation_requests',
  orderConfirmations: 'vs_order_confirmations',

  generatorStocks: 'vs_stocks',
  stockInspections: 'vs_stock_inspections',
  serviceProfiles: 'vs_service_profiles',
  serviceGeneratorJobHistories: 'vs_service_generator_job_history',
  jobHistories: 'vs_job_histories',
  customerProfiles: 'vs_customer_profiles',
  contactLogs: 'vs_contact_logs',
};

export const getViewSettings = (settingsNamespace) => {
  let settings = localStorage.getItem(settingsNamespace);
  return settings ? JSON.parse(settings) : null;
};

export const saveViewSettings = (settingsNamespace, settings) => {
  localStorage.setItem(settingsNamespace, JSON.stringify(settings));
};
//#endregion
