import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { customerServices } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { role } from "../../repos/constants";
import { customer } from "../../repos/constants";
import { formModes } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatSourceDescription } from "../../repos/utilities";
import { cancelNeedsReview } from "../../repos/cancelUtilities";
import { cancelHasBeenReviewed } from "../../repos/cancelUtilities";
import { shouldExpandCancelSection } from "../../repos/cancelUtilities";
import { isEntryCanceled } from "../../repos/cancelUtilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { EntryInquiryDetails } from "../sales/EntryInquiryDetails";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { BreadcrumbItem, Breadcrumbs } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { EntryCustomerDetails } from "./EntryCustomerDetails";
import { EntryQuotationDetails } from "./EntryQuotationDetails";
import { QuotationEntryTable } from "./QuotationEntryTable";
import { CancelStatus } from "../shared/CancelStatus";

import '../shared/EntryForm.css';
import './QuotationRequestEntryPage.css';


export function QuotationRequestEntryPage() {
  //#region States
  //#region formEditMode
  const [formEditMode, setFormEditMode] = useState(formModes.quotationRequest.newEntry);
  // newEntry 0 = new form, customer details to be entered
  // editEntry 1 = customer details edit mode, quotation details form disabled
  // quotationEntry 2 = customer details read-only mode, quotation details form enabled
  //#endregion

  const [entryPermissions, setEntryPermissions] = useState();
  const [inquiryListPermissions, setInquiryListPermissions] = useState();

  // Contact Details form
  const [requestDate, setRequestDate] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [businessType, setBusinessType] = useState(1);
  const [contactName, setContactName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [designation, setDesignation] = useState('');
  const [source, setSource] = useState(2);
  const [sourceDescription, setSourceDescription] = useState('');
  const [address, setAddress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [currency, setCurrency] = useState(refs.currency.usd.code);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const [editingEntry, setEditingEntry] = useState(null);
  const [customerProfileId, setCustomerProfileId] = useState(null);
  const [customerProfileStatus, setCustomerProfileStatus] = useState(customer.profileStatus.draft);

  // Quotation Details form
  const [gensetOrATSKeyword, setGensetOrATSKeyword] = useState('');
  const [gensetModelId, setGensetModelId] = useState('');
  const [atsId, setAtsId] = useState('');
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [itemRemarks, setItemRemarks] = useState('');
  const [hasQuotationDetailsErrors, setHasQuotationDetailsErrors] = useState(false);
  const [quotationDetailsErrors, setQuotationDetailsErrors] = useState({});
  const [isSubmittingDetails, setIsSubmittingDetails] = useState(false);

  const [editingQuotationDetails, setEditingQuotationDetails] = useState(null);
  const [isQuotationDetailsEditing, setIsQuotationDetailsEditing] = useState(false);

  // Quotation Details table
  const [isLoading, setIsLoading] = useState(false);
  const [quotationDetails, setQuotationDetails] = useState([]);
  //#endregion

  //#region Hooks
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [formOwnerStatus, setFormOwnerStatus] = useState(refs.sales.isFormOwner.inapplicable);
  //#endregion

  //#region Effects
  useEffect(() => {
    resetFormFields();
    setIsLoading(true);
    fetchPermissions().then(() => {
        if (location.state != null) { // Edit mode
          let state = location.state;
          if (state['requestId']) {
            setFormEditMode(formModes.quotationRequest.quotationEntry);

            let selectedEntry = location.state;
            prepareEntryForEditMode(selectedEntry);
            fetchQuotationDetails(selectedEntry['requestId']);
            return;
          }
          if (state['customerId']) { // Customer Profile quick action
            prepareCustomerDetails(state);
          }
        } else { // New mode
          setFormEditMode(formModes.quotationRequest.newEntry);
          setFormOwnerStatus(refs.sales.isFormOwner.yes);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);
  //#endregion

  //#region Utilities
  const fetchPermissions = () => {
    return authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        setEntryPermissions(response.data['quotationRequests']);
        setInquiryListPermissions(response.data['salesInquiries']);
      });
  }

  const fetchQuotationDetails = (requestId) => {
    setIsLoading(true);
    salesServices.fetchQuotationRequestDetails(requestId)
      .then((response) => {
        setQuotationDetails(response.data['requestDetails']);
      })
      .finally(() => setIsLoading(false));
  }

  const resetFormFields = () => {
    setRequestDate(new Date());
    setCurrency(refs.currency.usd.code);
    setCustomerName('');
    setBusinessType(1);
    setContactName('');
    setContactNo('');
    setDesignation('');
    setAddress('');
    setRemarks('');
  }

  const prepareEntryForEditMode = (editingEntry) => {
    let _editingEntry = editingEntry;
    setEditingEntry(_editingEntry);
    setRequestDate(new Date(_editingEntry['requestedDatetime']));
    setCurrency(parseInt(_editingEntry['currency']));
    setRemarks(_editingEntry['remarks']);

    prepareCustomerDetails(_editingEntry['customer']);

    let sourceId = _editingEntry['source'];
    setSource(sourceId);
    updateSourceDescription(sourceId);

    setCancelReason(_editingEntry['cancelReason'] ? _editingEntry['cancelReason'] : '');
    setIsCancelExpanded(shouldExpandCancelSection(_editingEntry));

    if (_editingEntry['requestedBy']['id'] === auth.getUserId()['eid']) {
      setFormOwnerStatus(refs.sales.isFormOwner.yes);
    }
    else {
      setFormOwnerStatus(refs.sales.isFormOwner.no);
    }
  }

  const prepareCustomerDetails = (customer) => {
    setCustomerProfileId(customer['id']);
    setCustomerProfileStatus(customer['profileStatus']);

    setCustomerName(customer['customerName']);
    setBusinessType(customer['businessType']['id']);
    setContactName(customer['contactFullName']);
    setContactNo(customer['contactNo']);
    setDesignation(customer['designation'] ? customer['designation'] : '');
    setAddress(customer['address'] ? customer['address'] : '');
  }

  const resetQuotationFormFields = () => {
    setHasQuotationDetailsErrors(false);
    setQuotationDetailsErrors({
      keyword: [],
      price: [],
      quantity: [],
    });
    setGensetOrATSKeyword('');
    setGensetModelId('');
    setAtsId('');
    setPrice(0);
    setQuantity(1);
    setItemRemarks('');
  }

  const getBusinessTypeDisplay = () => {
    if (editingEntry === null) {
      return "";
    }
    return editingEntry['customer']['businessType']['name'];
  }

  const isFormOwner = () => {
    return formOwnerStatus === refs.sales.isFormOwner.yes;
  }

  const isFormElementDisabled = () => {
    return isSubmitting || isSubmittingDetails || formEditMode === formModes.quotationRequest.editEntry;
  }

  const isRequestDraft = () => {
    if (editingEntry === null) return true;
    return editingEntry && editingEntry['reviewStatus'] === refs.sales.reviewStatus.draft;
  }

  // !
  const isRequestSubmitted = () => {
    if (!editingEntry) return false;
    return editingEntry['reviewStatus'] !== refs.sales.reviewStatus.draft && editingEntry['reviewStatus'] !== refs.sales.reviewStatus.pendingReview;
  }

  const isRequestApproved = () => {
    return isRequestSubmitted() && editingEntry['reviewStatus'] === refs.sales.reviewStatus.approved;
  }

  const isRequestRejected = () => {
    return isRequestSubmitted() && editingEntry['reviewStatus'] === refs.sales.reviewStatus.rejected;
  }

  const isRequestReviewed = () => {
    return isRequestApproved() || isRequestRejected();
  }

  const userIsReviewer = () => {
    return entryPermissions && entryPermissions['reviewLevel'] === role.quotationRequest.reviewLevels.reviewer;
  }

  const getSubmitButtonLabel = () => {
    return isRequestSubmitted() ? "Submitted for review" : "Submit for review";
  }

  const getApproveButtonLabel = () => {
    return isRequestApproved() ? "Approved" : "Approve";
  }

  const getRejectButtonLabel = () => {
    return isRequestRejected() ? "Rejected" : "Reject";
  }
  //#endregion

  //#region Control handlers - Customer Details
  const onRequestDateChanged = (date) => {
    setRequestDate(date);
    setFormErrors({ ...formErrors, requestDate: [] });
  }

  const onCustomerNameChanged = (ev) => {
    setCustomerName(ev.target.value);
    setFormErrors({ ...formErrors, customerName: [] });
  }

  const onBusinessTypeChanged = (ev) => {
    setBusinessType(parseInt(ev.target.value));
  }

  const onContactNameChanged = (ev) => {
    setContactName(ev.target.value);
    setFormErrors({ ...formErrors, contactName: [] });
  }

  const onContactNoChanged = (ev) => {
    setContactNo(ev.target.value);
    setFormErrors({ ...formErrors, contactNo: [] });
  }

  const onDesignationChanged = (ev) => {
    setDesignation(ev.target.value);
  }

  const onAddressChanged = (ev) => {
    setAddress(ev.target.value);
  }

  const onSourceChanged = (ev) => {
    let sourceId = ev.target.value;
    updateSourceDescription(sourceId);
    setSource(parseInt(sourceId));
  }

  const updateSourceDescription = (sourceId) => {
    setSourceDescription(formatSourceDescription(sourceId));
  }

  const onRemarksChanged = (ev) => {
    setRemarks(ev.target.value);
  }

  const onCurrencyChanged = (ev) => {
    setCurrency(parseInt(ev.target.value));
  }

  const fillUpCustomerDetailsFromInquiry = (salesInquiry) => {
    // clear up the currently tracking customer profile
    setCustomerProfileId(null);
    setCustomerProfileStatus(customer.profileStatus.draft);

    setCustomerName(salesInquiry['contactFullName']);
    setContactName(salesInquiry['contactFullName']);
    setContactNo(salesInquiry['contactNo']);
    let _addressShort = "";
    let _township = salesInquiry['township'];
    let _city = salesInquiry['city'];
    if (_township !== null) {
      _addressShort = _township['name'];
    } else {
      _addressShort = _city['name'];
    }
    setAddress(_addressShort);
    setSource(salesInquiry['source']);
    setBusinessType(salesInquiry['businessType']['id']);
  }

  const fillUpCustomerDetailsFromProfile = (customer) => {
    prepareCustomerDetails(customer);
  }

  const isContactFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      reportDate: [],
      customerName: [],
      contactName: [],
      contactNo: [],
    }
    if (!requestDate) {
      _hasErrors = true;
      _formErrors['requestDate'] = ['Request date is required.'];
    }
    if (!customerName || customerName.trim() === '') {
      _hasErrors = true;
      _formErrors['customerName'] = ['Customer name is required.'];
    }
    if (!contactName || contactName.trim() === '') {
      _hasErrors = true;
      _formErrors['contactName'] = ['Contact name is required.'];
    }
    if (!contactNo || contactNo.trim() === '') {
      _hasErrors = true;
      _formErrors['contactNo'] = ['Contact phone number is required.'];
    }
    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareCustomerDetailsPayload = () => {
    let requesterId = auth.getUserId()['eid'];
    return {
      'requested_datetime': requestDate,
      'requested_by': requesterId,
      'currency': currency,
      'customer_name': customerName,
      'business_type_id': businessType,
      'source': source,
      'contact_fullname': contactName,
      'contact_no': contactNo,
      'designation': designation,
      'address': address,
      'remarks': remarks,
    }
  }

  const onCustomerSaveClicked = (ev, isEditMode = false) => {
    if (!isContactFormFieldsValid()) {
      setHasErrors(true);
      return;
    } else setHasErrors(false);

    setIsSubmitting(true);
    let payload = {
      ...prepareCustomerDetailsPayload(),
    }
    if (customerProfileId) {
      payload['customer_id'] = customerProfileId;
    }
    if (!isEditMode) {
      salesServices.saveQuotationRequestCustomerDetails(payload)
        .then((response) => {
          let quotationRequest = response.data['quotationRequest'];
          resetFormFields();
          prepareEntryForEditMode(quotationRequest);
          setFormEditMode(formModes.quotationRequest.quotationEntry);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      payload['request_id'] = editingEntry['requestId'];
      salesServices.updateQuotationRequestCustomerDetails(payload)
        .then((response) => {
          let updatedQuotationRequest = response.data['quotationRequest'];
          resetFormFields();
          prepareEntryForEditMode(updatedQuotationRequest);
          setFormEditMode(formModes.quotationRequest.quotationEntry);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsSubmitting(false));
    }
  }

  const onCustomerCancelClicked = (ev) => {
    setFormEditMode(formModes.quotationRequest.quotationEntry);
  }

  const onCustomerEditClicked = (ev) => {
    setFormEditMode(formModes.quotationRequest.editEntry);
  }

  const onCustomerProfileCreateClicked = (ev) => {
    setIsSubmitting(true);
    let employeeId = auth.getUserId()['eid'];
    let payload = {
      'employee_id': employeeId,
      'profile_id': customerProfileId,
      'new_profile_status': customer.profileStatus.active,
    };
    customerServices.setProfileStatus(payload)
      .then((response) => {
        let profile = response.data['profile'];
        setCustomerProfileStatus(profile['status']);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  }
  //#endregion

  //#region Control handlers - Quotation Details
  const onGensetOrATSKeywordChanged = (ev, keyword, prevKeyword) => {
    if (keyword.trim() === '') {
      setGensetOrATSKeyword('');
      setGensetModelId('');
      setAtsId('');
      return false;
    }
    if (keyword === prevKeyword) {
      return false;
    }
    setGensetOrATSKeyword(keyword);
    setGensetModelId('');
    setAtsId('');
    return true;
  }

  const onGeneratorSuggestionClicked = (ev, generator) => {
    setGensetOrATSKeyword(generator['gensetModel']); // TODO: Does this cause the dropdown to flicker?
    setGensetModelId(generator['id']);
    setAtsId('');
  }

  const onAtsSuggestionClicked = (ev, ats) => {
    setGensetOrATSKeyword(ats['name']);
    setAtsId(ats['id']);
    setGensetModelId('');
  }

  const onPriceChanged = (ev) => {
    setPrice(ev.target.value.trim());
  }

  const onPriceBlurred = (ev) => {
    // sanitize the price value
    let _priceStr = ev.target.value.trim();
    if (_priceStr === '' || isNaN(parseFloat(_priceStr))) {
      setPrice(0);
    } else {
      let _price = parseFloat(_priceStr);
      if (_price < 0) {
        setPrice(0);
      } else {
        setPrice(_price);
        setFormErrors({ ...formErrors, price: [] });
      }
    }
  }

  const onQuantityChanged = (ev) => {
    setQuantity(ev.target.value.trim());
  }

  const onQuantityBlurred = (ev) => {
    let _quantity = parseInt(ev.target.value.trim());
    if (isNaN(_quantity)) {
      _quantity = 1;
    }
    if (_quantity === 0) {
      _quantity = 1;
    }
    setQuantity(_quantity);
    setFormErrors({ ...formErrors, quantity: [] });
  }

  const onItemRemarkChanged = (ev) => {
    setItemRemarks(ev.target.value);
  }

  const isQuotationFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      keyword: [],
      price: [],
      quantity: [],
    }
    if (!gensetModelId && !atsId) {
      _hasErrors = true;
      _formErrors['keyword'] = ['Please make sure to select a proper genset (or) ATS item.'];
    }
    if (price <= 0) {
      _hasErrors = true;
      _formErrors['price'] = ['Please enter a valid price value.'];
    }
    if (quantity <= 0) {
      _hasErrors = true;
      _formErrors['quantity'] = ['Quantity has to be more than 0.'];
    }
    setQuotationDetailsErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareQuotationDetailsPayload = () => {
    return {
      'request_id': editingEntry['requestId'],
      'generator_id': gensetModelId ? gensetModelId : null,
      'ats_id': atsId ? atsId : null,
      'price': price,
      'quantity': quantity,
      'remarks': itemRemarks,
    };
  }

  const onQuotationItemAddClicked = (ev) => {
    if (!isQuotationFormFieldsValid()) {
      setHasQuotationDetailsErrors(true);
      setSubmissionError('Fix the errors above before submitting for review.');
      return;
    } else {
      setHasQuotationDetailsErrors(false);
      setSubmissionError('');
    }

    let payload = prepareQuotationDetailsPayload();
    setIsSubmittingDetails(true);
    salesServices.addQuotationRequestDetails(payload)
      .then((response) => {
        fetchQuotationDetails(editingEntry['requestId']);
        resetQuotationFormFields();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsSubmittingDetails(false));
  }

  const onQuotationItemRowSelected = (ev, quotationItem, rowIndex) => {
    ev.preventDefault();
    for (let item of quotationDetails) {
      item.isEditing = false;
    }
    quotationItem.isEditing = true;
    prepareQuotationDetailsForEditMode(quotationItem);
  }

  const onQuotationDetailsEditClicked = (ev) => {
    setTimeout(() => {
      setIsQuotationDetailsEditing(true);
    }, 200);
  }

  const onQuotationDetailsClearClicked = (ev) => {
    for (let item of quotationDetails) {
      item.isEditing = false;
    }
    setTimeout(() => {
      setIsQuotationDetailsEditing(false);
      setEditingQuotationDetails(null);
      resetQuotationFormFields();
    }, 200);
  }

  const prepareQuotationDetailsForEditMode = (quotationDetailsEntry) => {
    let _editingDetailsEntry = quotationDetailsEntry;
    setEditingQuotationDetails(_editingDetailsEntry);
    if (_editingDetailsEntry['generator']) {
      setGensetOrATSKeyword(_editingDetailsEntry['generator']['gensetModel']);
      setGensetModelId(_editingDetailsEntry['generator']['id']);
      setAtsId('');
    } else if (_editingDetailsEntry['ats']) {
      setGensetOrATSKeyword(_editingDetailsEntry['ats']['name']);
      setAtsId(_editingDetailsEntry['ats']['id']);
      setGensetModelId('');
    }
    setPrice(_editingDetailsEntry['price'])
    setQuantity(_editingDetailsEntry['quantity']);
    setItemRemarks(_editingDetailsEntry['remarks']);
  }

  const onQuotationDetailsUpdateClicked = (ev) => {
    if (!isQuotationFormFieldsValid()) {
      setHasQuotationDetailsErrors(true);
      return;
    } else setHasQuotationDetailsErrors(false);

    let payload = prepareQuotationDetailsPayload();
    payload['details_id'] = editingQuotationDetails['detailsId'];
    setIsSubmittingDetails(true);
    salesServices.updateQuotationRequestDetails(payload)
      .then((response) => {
        fetchQuotationDetails(editingEntry['requestId']);

        for (let item of quotationDetails) {
          item.isEditing = false;
        }
        setIsQuotationDetailsEditing(false);
        setEditingQuotationDetails(null);
        resetQuotationFormFields();
      })
      .catch((error) => {
        console.error(error['response']);
      })
      .finally(() => setIsSubmittingDetails(false));
  }

  const onQuotationDetailsCancelClicked = (ev) => {
    setIsQuotationDetailsEditing(false);
  }

  const onQuotationItemRemoveClicked = (ev, quotationItem, rowIndex) => {
    ev.preventDefault();
    onQuotationDetailsClearClicked(null);
    setIsSubmittingDetails(true);
    salesServices.removeQuotationRequestDetails(editingEntry['requestId'], quotationItem['detailsId'])
      .then((response) => {
        fetchQuotationDetails(editingEntry['requestId']);
      })
      .finally(() => setIsSubmittingDetails(false));
  }

  const onSubmitForReviewClicked = (ev) => {
    let requesterId = auth.getUserId()['eid'];
    let payload = {
      'request_id': editingEntry['requestId'],
      'requested_by': requesterId,
    };
    setIsSubmitting(true);

    salesServices.submitQuotationRequestForReview(payload)
      .then((response) => {
        let reviewStatus = parseInt(response.data['reviewStatus']);
        if (reviewStatus !== refs.sales.reviewStatus.pendingReview) return;
        editingEntry['reviewStatus'] = reviewStatus;
      })
      .catch((error) => {
        console.error(error['response']);
        let _error = error['response']['data']['error'];
        setSubmissionError(_error);
      })
      .finally(() => setIsSubmitting(false));
  }
  //#endregion

  //#region Control handlers - Review
  const onApproveOrRejectClicked = (ev, status) => {
    let reviewerId = auth.getUserId()['eid'];
    let payload = {
      'request_id': editingEntry['requestId'],
      'reviewed_by': reviewerId,
      'review_status': status,
    };
    setIsSubmitting(true);

    salesServices.approveOrRejectQuotationRequest(payload)
      .then((response) => {
        editingEntry['reviewStatus'] = parseInt(response.data['reviewStatus']);
      })
      .catch((error) => {
        console.error(error['response']);
        let _error = error['response']['data']['error'];
        setSubmissionError(_error);
      })
      .finally(() => setIsSubmitting(false));
  }
  //#endregion

  //#region Control handlers - Cancellation
  const [isCancelExpanded, setIsCancelExpanded] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [isCancelSubmitting, setIsCancelSubmitting] = useState(false);
  const [hasCancelErrors, setHasCancelErrors] = useState(false);
  const [cancelError, setCancelError] = useState('');

  const onCancelExpandClicked = (ev) => {
    setIsCancelExpanded(!isCancelExpanded);
  }

  const onCancelReasonChanged = (ev) => {
    setCancelReason(ev.target.value);
  }

  const onCancelSubmitClicked = (ev) => {
    let _hasError = false;
    let _errorMsg = '';
    if (cancelReason.trim() === '') {
      _hasError = true;
      _errorMsg = 'Please provide a valid reason.';
    }
    setHasCancelErrors(_hasError);
    if (_hasError) {
      setCancelError(_errorMsg);
      return;
    }

    let payload = {
      'request_id': editingEntry['requestId'],
      'cancel_reason': cancelReason,
      'eid': auth.getUserId()['eid'],
    }

    setIsCancelSubmitting(true);
    salesServices.submitQuotationRequestCancel(payload)
      .then((response) => {
        let updatedRequest = response.data['quotationRequest'];
        setEditingEntry(updatedRequest);

        setCancelReason(updatedRequest['cancelReason'] ? updatedRequest['cancelReason'] : '');
        setIsCancelExpanded(shouldExpandCancelSection(updatedRequest));
      })
      .catch((error) => {
        console.error(error['response']);
      })
      .finally(() => setIsCancelSubmitting(false));
  }

  const isAllowCancelButton = () => {
    if (editingEntry === null || isRequestDraft()) return false;
    let _reviewStatus = editingEntry['reviewStatus'];
    if (_reviewStatus !== refs.sales.reviewStatus.approved && _reviewStatus !== refs.sales.reviewStatus.rejected) {
      return false;
    }
    let allowCancel = false;
    if (isFormOwner()) {
      allowCancel = true;
    }
    return allowCancel;
  }

  const onCancelApproveOrRejectClicked = (ev, status) => {
    let reviewerId = auth.getUserId()['eid'];
    let payload = {
      'request_id': editingEntry['requestId'],
      'reviewer_id': reviewerId,
      'review_status': status,
    };
    setIsSubmitting(true);

    salesServices.reviewQuotationRequestCancel(payload)
      .then((response) => {
        let updatedRequest = response.data['quotationRequest'];
        setEditingEntry(updatedRequest);
      })
      .catch((error) => {
        console.log(error['response']);
        let _error = error['response']['data']['error'];
        setCancelError(_error);
      })
      .finally(() => setIsSubmitting(false));
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container quotation-requests-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.quotationRequestsList.displayShort} anchorTo={routes.quotationRequestsList.url} />
              <BreadcrumbItem text={routes.quotationRequestEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Quotation Request Entry</h1>
          </div>

          <form autoComplete={"off"}>
            <EntryCustomerDetails editingEntry={editingEntry}
                                  requestDate={requestDate} onRequestDateChanged={onRequestDateChanged}
                                  currency={currency} onCurrencyChanged={onCurrencyChanged}
                                  customerProfileId={customerProfileId} customerProfileStatus={customerProfileStatus}
                                  customerName={customerName} onCustomerNameChanged={onCustomerNameChanged}
                                  businessType={businessType} onBusinessTypeChanged={onBusinessTypeChanged}
                                  getBusinessTypeDisplay={getBusinessTypeDisplay}
                                  contactName={contactName} onContactNameChanged={onContactNameChanged}
                                  contactNo={contactNo} onContactNoChanged={onContactNoChanged}
                                  designation={designation} onDesignationChanged={onDesignationChanged}
                                  address={address} onAddressChanged={onAddressChanged}
                                  source={source} onSourceChanged={onSourceChanged} sourceDescription={sourceDescription}
                                  remarks={remarks} onRemarksChanged={onRemarksChanged}
                                  hasErrors={hasErrors} formErrors={formErrors} isSubmitting={isSubmitting} formMode={formEditMode}
                                  isSubmittingDetails={isSubmittingDetails}
                                  onSaveClicked={onCustomerSaveClicked} onCancelClicked={onCustomerCancelClicked} onEditClicked={onCustomerEditClicked}
                                  onCreateProfileClicked={onCustomerProfileCreateClicked}
                                  isFormSubmitted={isRequestSubmitted} isFormOwner={isFormOwner}
                                  fillUpDetailsFromInquiry={fillUpCustomerDetailsFromInquiry}
                                  fillUpDetailsFromProfile={fillUpCustomerDetailsFromProfile}
            />

            <EntryQuotationDetails editingDetailsEntry={editingQuotationDetails}
                                   gensetOrATSKeyword={gensetOrATSKeyword} onGensetOrATSKeywordChanged={onGensetOrATSKeywordChanged}
                                   onGeneratorSuggestionClicked={onGeneratorSuggestionClicked} onAtsSuggestionClicked={onAtsSuggestionClicked}
                                   gensetModelId={gensetModelId} atsId={atsId}
                                   price={price} onPriceChanged={onPriceChanged} onPriceBlurred={onPriceBlurred}
                                   quantity={quantity} onQuantityChanged={onQuantityChanged} onQuantityBlurred={onQuantityBlurred}
                                   remarks={itemRemarks} onRemarksChanged={onItemRemarkChanged} currency={currency}
                                   hasErrors={hasQuotationDetailsErrors} formErrors={quotationDetailsErrors} formMode={formEditMode}
                                   onAddClicked={onQuotationItemAddClicked}
                                   onEditClicked={onQuotationDetailsEditClicked} onClearClicked={onQuotationDetailsClearClicked}
                                   onUpdateClicked={onQuotationDetailsUpdateClicked} onCancelClicked={onQuotationDetailsCancelClicked}
                                   isFormElementDisabled={isFormElementDisabled} isSubmittingDetails={isSubmittingDetails}
                                   isFormSubmitted={isRequestSubmitted} isDetailsEditing={isQuotationDetailsEditing}
                                   isFormOwner={isFormOwner}
            />

            <QuotationEntryTable editingEntry={editingEntry} isLoading={isLoading} quotationDetails={quotationDetails}
                                 currency={currency} isFormSubmitted={isRequestSubmitted} isFormOwner={isFormOwner}
                                 onItemRowSelected={onQuotationItemRowSelected}
                                 onItemRemoveClicked={onQuotationItemRemoveClicked}
            />

            {formEditMode !== formModes.quotationRequest.newEntry &&
              <div className={"form-section"}>
                <div className={"form-button-controls"}>
                  <div className="left-side">
                    {!userIsReviewer() && isFormOwner() &&
                      <button className={"btn btn-primary"} disabled={isFormElementDisabled() || isRequestSubmitted()}
                              onClick={onSubmitForReviewClicked}>
                        {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                        {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                        <span>{getSubmitButtonLabel()}</span>
                      </button>
                    }
                    {isAllowCancelButton() &&
                      <button type={"button"} className={"btn btn-secondary"}
                              disabled={isSubmitting || isCancelSubmitting}
                              onClick={onCancelExpandClicked}>
                        {!isCancelExpanded && <i className="fa-solid fa-circle-xmark"></i>}
                        {isCancelExpanded && <i className="fa-solid fa-circle-chevron-down"></i>}
                        <span>Cancel</span>
                      </button>
                    }

                    {userIsReviewer() &&
                      <>
                        <button className={"btn btn-success"} disabled={isSubmitting || isRequestReviewed()}
                                onClick={(ev) => onApproveOrRejectClicked(ev, refs.sales.reviewStatus.approved)}>
                          {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                          {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                          <span>{getApproveButtonLabel()}</span>
                        </button>
                        <button type={"button"} className={"btn btn-danger"} disabled={isSubmitting || isRequestReviewed()}
                                onClick={(ev) => onApproveOrRejectClicked(ev, refs.sales.reviewStatus.rejected)}>
                          <i className={"fa-solid fa-xmark"}></i>
                          <span>{getRejectButtonLabel()}</span>
                        </button>
                      </>
                    }

                    {submissionError &&
                      <div className={"inline-error-message"}>
                        {submissionError}
                      </div>
                    }
                  </div>
                </div>

                {isCancelExpanded &&
                  <>
                    <h2>Cancel Quotation Request</h2>

                    <div className={"entry-form"}>
                      <div className={"form-label"}>
                        <label htmlFor={"cancelReason"}>
                          Reason:<span className={"error-message"}>*</span>
                        </label>
                      </div>
                      <div className={"form-input"}>
                        {!isEntryCanceled(editingEntry) && !cancelNeedsReview(entryPermissions['cancelLevel'], editingEntry) &&
                          <>
                            <textarea id={"cancelReason"} className={"form-control"} rows={3} autoComplete={"none"} disabled={isCancelSubmitting}
                                      value={cancelReason} onChange={onCancelReasonChanged}>
                            </textarea>
                            <FieldErrorMessage visible={hasCancelErrors} message={cancelError} />
                          </>
                        }
                        {(isEntryCanceled(editingEntry) || cancelNeedsReview(entryPermissions['cancelLevel'], editingEntry)) &&
                          <ReadonlyField>{cancelReason}</ReadonlyField>
                        }
                      </div>
                    </div>

                    {!isEntryCanceled(editingEntry) &&
                      <div className={"form-button-controls"}>
                        <div className={"left-side"}>
                          {!cancelNeedsReview(entryPermissions['cancelLevel'], editingEntry) &&
                            <button type={"button"} className={"btn btn-secondary"}
                                    disabled={isSubmitting || isCancelSubmitting}
                                    onClick={onCancelSubmitClicked}>
                              {isCancelSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                              {!isCancelSubmitting && <i className="fa-solid fa-file-circle-xmark"></i>}
                              <span>Submit Cancel</span>
                            </button>
                          }
                          {cancelNeedsReview(entryPermissions['cancelLevel'], editingEntry) &&
                            <>
                              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isCancelSubmitting}
                                      onClick={(ev) => onCancelApproveOrRejectClicked(ev, refs.sales.reviewStatus.approved)}>
                                {isCancelSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                                {!isCancelSubmitting && <i className={"fa-solid fa-check"}></i>}
                                <span>Approve</span>
                              </button>
                              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isCancelSubmitting}
                                      onClick={(ev) => onCancelApproveOrRejectClicked(ev, refs.sales.reviewStatus.rejected)}>
                                <i className={"fa-solid fa-xmark"}></i>
                                <span>Reject</span>
                              </button>
                            </>
                          }
                          {!cancelNeedsReview(entryPermissions['cancelLevel'], editingEntry) && editingEntry['cancelStatus'] !== null &&
                            <span className={"cancel-warning"}>
                              <i className={"fa-solid fa-triangle-exclamation"}></i>
                              <span>Waiting for approval.</span>
                            </span>
                          }
                        </div>
                      </div>
                    }

                    {cancelHasBeenReviewed(entryPermissions['cancelLevel'], editingEntry) &&
                      <div className={"form-button-controls"}>
                        <div className={"left-side"}>
                          <CancelStatus status={editingEntry['cancelStatus']}
                                        reviewedBy={editingEntry['cancelReviewedBy']}
                                        reviewDate={editingEntry['cancelReviewDate']} />
                        </div>
                      </div>
                    }
                  </>
                }

              </div>
            }
          </form>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
