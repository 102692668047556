import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { formatters } from "../../repos/constants";
import { expandSuggestionsResult } from "../../repos/searchUtilities";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { EmployeeBranchGroupsDropdown } from "../shared/EmployeesDropdown";
import { AutoCompleteResults } from "../shared/AutoCompleteResults";

import "../shared/SearchPanel.css";


const serviceTypes = refs.inventory.serviceType;
const jobStatuses = refs.inventory.jobStatus;

export const JobHistoriesListSearchPanel = ({
                                              isSearchOpen, isSearchLoading, isListLoading,
                                              searchFields,
                                              onServicedDateFromChanged,
                                              onServicedDateToChanged,
                                              onServicedByChanged,
                                              onCustomerNameChanged,
                                              onGeneratorSerialChanged,
                                              gensetModelTerm, onGensetModelTermChanged, onGensetModelSuggestionClicked,
                                              onServiceTypeChanged,
                                              onJobStatusChanged,
                                              onSearchClicked, onClearClicked,
                                              hasErrors, errorMessage,
                                            }) => {
  //#region States
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);

  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = () => {
    setIsLoadingEmployees(true);
    refServices2.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _responseData = response['data'];
        setEmployeeBranchGroups(_responseData);
      })
      .finally(() => setIsLoadingEmployees(false));
  }
  //#endregion

  //#region Utilities
  const getSearchPanelClass = () => {
    return "search-panel" + (isSearchOpen ? " search-panel-open" : " search-panel-hide");
  }

  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (searchFields['serviced_date_from'] && !isToDate) className += " has-autocompleted-value";
    else if (searchFields['serviced_date_to'] && isToDate) className += " has-autocompleted-value";
    return className;
  }

  const isFormControlsDisabled = () => {
    return isSearchLoading || isListLoading;
  }
  //#endregion

  //#region Control handlers
  const getGensetModelClassName = () => {
    let className = "form-control md-field";
    if (searchFields['genset_model_id']) className += " has-autocompleted-value";
    return className;
  }

  const getEmptyGensetSuggestion = () => {
    return {
      'id': '0',
      'gensetModel': '(No genset)',
    };
  }

  let _prevGensetModelTerm = '';
  let _gensetModelTerm = '';
  const onGensetModelTermFieldChanged = (ev) => {
    _prevGensetModelTerm = _gensetModelTerm.trim();
    _gensetModelTerm = ev.target.value.trim();

    let isGensetModelChanged = onGensetModelTermChanged(ev, _gensetModelTerm, _prevGensetModelTerm);
    if (!isGensetModelChanged) return;

    if (_gensetModelTerm.length >= 3) {
      refServices2.searchGenerators(_gensetModelTerm)
        .then((response) => {
          let suggestions = [getEmptyGensetSuggestion(), ...response['data']];
          setGeneratorSuggestions(suggestions);
          expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
        })
    }
    else {
      setGeneratorSuggestions([]);
    }
  }

  const onGensetModelTermBlurred = (ev) => {
    setTimeout(() => {
      setGeneratorSuggestions([]);
    }, 200);
  }

  const onGensetModelFieldClicked = (ev) => {
    setGeneratorSuggestions([getEmptyGensetSuggestion()]);
    expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
  }

  const onGensetModelClicked = (ev, generator) => {
    onGensetModelSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
  }
  //#endregion

  //#region Render
  return (
    <div className={getSearchPanelClass()}>
      <form onSubmit={onSearchClicked}>
        <div className={"search-fields"}>
          <div className={"date-field from-date-field"}>
            <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                        placeholderText={"Report date from."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={searchFields['serviced_date_from']} onChange={onServicedDateFromChanged} />
          </div>
          <div className={"date-field"}>
            <DatePicker id={"dateTo"} className={getDateClassName(true)}
                        placeholderText={"Report date to."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={searchFields['serviced_date_to']} onChange={onServicedDateToChanged} />
          </div>

          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"searchEmployee"} name={"searchEmployee"}
                                        isDisabled={isFormControlsDisabled() || isLoadingEmployees}
                                        value={searchFields['serviced_by_id']} onChange={(ev) => onServicedByChanged(ev.target.value)} />
        </div>

        <div className={"search-fields"}>
          <input type={"text"} id={"searchCustomerName"} className={"form-control md-field"}
                 autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"Customer Name"} value={searchFields['customer_name']} onChange={onCustomerNameChanged} />

          <input type={"text"} id={"searchGeneratorSerial"} className={"form-control md-field padded-left"}
                 autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"Generator Serial"} value={searchFields['generator_serial']} onChange={onGeneratorSerialChanged} />

          <input type={"text"} id={"searchGensetModel"} className={getGensetModelClassName()}
                 autoComplete={"off"} disabled={isFormControlsDisabled()} placeholder={"Genset model"}
                 value={gensetModelTerm} onChange={onGensetModelTermFieldChanged} onBlur={onGensetModelTermBlurred}
                 onClick={onGensetModelFieldClicked} />
          {generatorSuggestions && generatorSuggestions.length > 0 &&
            <AutoCompleteResults id={"gensetModelSuggestions"} style={{ width: '400px' }}
                                 collection={generatorSuggestions} itemDisplayField={"gensetModel"}
                                 onItemClicked={onGensetModelClicked} />
          }
        </div>

        <div className={"search-fields"}>
          <select id={"searchServiceType"} name={"searchServiceType"} className={"form-control form-select lg-field"}
                  disabled={isFormControlsDisabled()}
                  value={searchFields['service_type']} onChange={(ev) => onServiceTypeChanged(ev.target.value)}>
            <option value={-1}>- Any -</option>
            <option value={serviceTypes.tnc}>{serviceTypes[serviceTypes.tnc]}</option>
            <option value={serviceTypes.pm}>{serviceTypes[serviceTypes.pm]}</option>
            <option value={serviceTypes.regular}>{serviceTypes[serviceTypes.regular]}</option>
            <option value={serviceTypes.repair}>{serviceTypes[serviceTypes.repair]}</option>
            <option value={serviceTypes.emergency}>{serviceTypes[serviceTypes.emergency]}</option>
            <option value={serviceTypes.inspection}>{serviceTypes[serviceTypes.inspection]}</option>
          </select>
          <select id={"searchServiceType"} name={"searchServiceType"} className={"form-control form-select md-field"}
                  disabled={isFormControlsDisabled()}
                  value={searchFields['job_status']} onChange={(ev) => onJobStatusChanged(ev.target.value)}>
            <option value={-1}>- Any -</option>
            <option value={jobStatuses.draft}>{jobStatuses[jobStatuses.draft]}</option>
            <option value={jobStatuses.pendingReview}>{jobStatuses[jobStatuses.pendingReview]}</option>
            <option value={jobStatuses.active}>{jobStatuses[jobStatuses.active]}</option>
          </select>
        </div>

        <div className={"search-fields"}>
          <FieldErrorMessage visible={hasErrors} message={errorMessage} />
        </div>

        <div className={"search-controls"}>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
        </div>
      </form>
    </div>
  )
  //#endregion
}
