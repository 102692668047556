import React from "react";

import "./AutoCompleteResults.css";

/// Results container
export const AutoCompleteResults = (props) => {
  const {
    id, style,
    collection, onItemClicked, itemDisplayField,
  } = props;

  const getClassName = () => {
    return "autocomplete-results";
  }

  return (
    <div id={id} className={getClassName()} style={style}>
      {
        collection.map(item =>
          <AutoCompleteResultItem key={item.id} item={item} displayField={itemDisplayField} onItemClicked={onItemClicked} />
        )
      }
    </div>
  )
}

/// Results container
export const AutoCompleteNestedResults = (props) => {
  const {
    id, style,
    collection, onItemClicked, itemDisplayField, childCollectionField, childItemDisplayField, onChildItemClicked,
  } = props;

  const getClassName = () => {
    return "autocomplete-results";
  }

  return (
    <div id={id} className={getClassName()} style={style}>
      {
        collection.map(item =>
          <AutoCompleteResultItemHeader item={item} key={item.id} onItemClicked={onItemClicked} itemDisplayField={itemDisplayField}
                                        childCollectionField={childCollectionField} childItemDisplayField={childItemDisplayField}
                                        onChildItemClicked={onChildItemClicked} />
        )
      }
    </div>
  )
}

export const AutoCompleteResultItemHeader = (props) => {
  const {
    item, onItemClicked, itemDisplayField, childCollectionField, childItemDisplayField, onChildItemClicked,
  } = props;

  const itemClassName = () => {
    return "autocomplete-result-item result-item-header";
  }

  const onClick = (ev, item) => {
    ev.stopPropagation();
    onItemClicked(ev, item);
  }

  return (
    <>
      <div className={itemClassName()} onClick={(ev) => onClick(ev, item)}>
        {item[itemDisplayField]}
      </div>
      {item[childCollectionField] && item[childCollectionField].length > 0 &&
        item[childCollectionField].map(childItem =>
          <AutoCompleteResultItem key={childItem.id} item={childItem} hasOffset={true}
                                  displayField={childItemDisplayField} onItemClicked={onChildItemClicked} />
        )
      }
    </>
  )
}

export const AutoCompleteResultItem = (props) => {
  const {
    item, onItemClicked, displayField, hasOffset,
  } = props;

  const itemClassName = () => {
    let className = "autocomplete-result-item";
    if (hasOffset) className += " result-item-offset";
    return className;
  }

  const onClick = (ev, item) => {
    ev.stopPropagation();
    onItemClicked(ev, item);
  }

  return (
    <div className={itemClassName()} onClick={(ev) => onClick(ev, item)}>
      {item[displayField]}
    </div>
  )
}

/// Results container
export const AutoCompleteGroupedResults = (props) => {
  const {
    id, collections, collectionLabels, collectionDisplayFields, collectionItemClickHandlers,
  } = props;

  const getCollectionLabel = (index) => {
    return collectionLabels[index];
  }

  const getDisplayField = (index) => {
    return collectionDisplayFields[index];
  }

  const getItemClickHandler = (index) => {
    return collectionItemClickHandlers[index];
  }

  let _key = 0;
  const getNextKey = () => {
    return _key++;
  }

  return (
    <div id={id} className={"autocomplete-results"} style={{ width: 400 }}>
      {collections && collections.length > 0 && collections.map((collection, index) =>
        <div key={index}>
          <div className={"autocomplete-group-header"}>{getCollectionLabel(index)}</div>
          {collection.length > 0 && collection.map((item, idx) =>
            <AutoCompleteResultItem key={getNextKey()} item={item} hasOffset={true}
                                    displayField={getDisplayField(index)}
                                    onItemClicked={(ev) => getItemClickHandler(index)(ev, item)} />
          )}
        </div>
      )}
    </div>
  )
}
