import React from "react";
import { useNavigate } from "react-router-dom";

import { navigableRoutes as routes } from "../../repos/constants";
import { ModalContainer } from "../shared/ModalContainer";

export const NewServiceJobModal = ({
                                     isOpen, onRequestClose,
                                   }) => {
  const navigate = useNavigate();

  //#region Control handlers
  const onPreventiveMaintenanceClicked = (ev) => {
    // NOTE(yemon): customerId, serviceProfileId, serviceGeneratorId
    navigate(routes.preventiveMaintenance.url);
  }

  const onRegularClicked = (ev) => {
  }

  const onRepairClicked = (ev) => {
  }

  const onEmergencyBreakdownClicked = (ev) => {
  }

  const onInspectionClicked = (ev) => {
  }
  //#endregion

  //#region Render
  return (
    <ModalContainer elementId={"new-service-job-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose} isLoading={false}
                    title={"New Service Job"}
                    shortDescription={"Choose an option to create a new Service Entry Form..."}>
      <div className={"modal-controls-grid"}>
        <div className={"modal-controls-group"}>
          Under-warranty Services
        </div>
        <div className={"modal-controls-items"}>
          <button type={"button"} disabled={false} onClick={onPreventiveMaintenanceClicked}>
            <i className="fa-solid fa-screwdriver-wrench"></i>
            <span>Preventive Maintenance</span>
          </button>
          <button type={"button"} disabled={true} onClick={onRegularClicked}>
            <i className="fa-solid fa-clock-rotate-left"></i>
            <span>Regular</span>
          </button>
          <button type={"button"} disabled={true} onClick={onRepairClicked}>
            <i className="fa-solid fa-hammer"></i>
            <span>Repair</span>
          </button>
        </div>

        <div className={"modal-controls-group"}>
          Over-warranty & On-call Services
        </div>
        <div className={"modal-controls-items"}>
          <button type={"button"} disabled={true} onClick={onEmergencyBreakdownClicked}>
            <i className="fa-solid fa-warning"></i>
            <span>Emergency Breakdown</span>
          </button>
          <button type={"button"} disabled={true} onClick={onInspectionClicked}>
            <i className="fa-solid fa-search"></i>
            <span>Inspection</span>
          </button>
        </div>
      </div>
    </ModalContainer>
  )
  //#endregion
}
