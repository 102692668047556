import React from "react";
import Moment from "react-moment";

import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";

export const ServiceProfileStatus = ({
                                       profileStatus,
                                       profileActiveDatetime,
                                       profileInactiveDatetime
                                     }) => {
  return (
    <>
      <span>{refs.inventory.serviceProfileStatus[profileStatus]}</span>&nbsp;
      {profileStatus === refs.inventory.serviceProfileStatus.active && profileActiveDatetime &&
        <>since <Moment date={profileActiveDatetime} format={formatters.datetimeShort} /></>
      }
      {profileStatus === refs.inventory.serviceProfileStatus.inactive && profileInactiveDatetime &&
        <>since <Moment date={profileInactiveDatetime} format={formatters.datetimeShort} /></>
      }
    </>
  )
}
