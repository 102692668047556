import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { EMPTY_GUID } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ControlsGrid } from "../shared/ControlsGrid";
import { ControlsGroup } from "../shared/ControlsGrid";
import { ControlsItems } from "../shared/ControlsGrid";
import { ControlButton } from "../shared/ControlsGrid";

import "../shared/ListingPage.css";


export const ManageReferencesPage = () => {
  //#region States
  const navigate = useNavigate();
  //#endregion

  //#region Control handlers
  const onStatesDivisionsClicked = (ev) => {
    navigate(routes.statesDivisionsSetup.url);
  }

  const onBusinessTypesClicked = (ev) => {
    navigate(routes.businessTypesSetup.url);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.manageReferences.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.manageReferences.display}</h1>
          </div>

          <ControlsGrid>
            <ControlsGroup title={"Regions"} />
            <ControlsItems>
              <ControlButton icon={"fa-building-columns"} label={"States & Divisions"}
                             disabled={false} onClick={onStatesDivisionsClicked} />
              <ControlButton icon={"fa-map"} label={"Cities & Townships"}
                             disabled={false} onClick={null} />
            </ControlsItems>

            <ControlsGroup title={"Customer"} />
            <ControlsItems>
              <ControlButton icon={"fa-briefcase"} label={"Business Types"}
                             disabled={false} onClick={onBusinessTypesClicked} />
            </ControlsItems>

            <ControlsGroup title={"Generator"} />
            <ControlsItems>
              <ControlButton icon={"fa-industry"} label={"Manufacturers"}
                             disabled={false} onClick={null} />
              <ControlButton icon={"fa-arrows-turn-to-dots"} label={"Alternators"}
                             disabled={false} onClick={null} />
              <ControlButton icon={"fa-hard-drive"} label={"Machines"}
                             disabled={false} onClick={null} />
              <ControlButton icon={"fa-toggle-off"} label={"Controllers"}
                             disabled={false} onClick={null} />
            </ControlsItems>

          </ControlsGrid>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
