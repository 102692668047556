import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { EMPTY_GUID } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { inventoryServices as inventoryServices2 } from "../../repos/apiServices2";
import { technicalServices } from "../../repos/apiServices2";
import { getListRowSerial } from "../../repos/utilities";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { StockInspectionsListSearchPanel } from "./StockInspectionsListSearchPanel";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { NullBlankField } from "./NullBlankField";
import { SortIndicator } from "../shared/SortIndicator";
import { ActorPureNameDisplay } from "../sales/ActorNameDisplay";
import { JobStatusBadge } from "./JobStatusBadge";
import { StockInspectionsListSummaryModal } from "./StockInspectionsListSummaryModal";
import { stockInspectionSummaryTypes } from "./StockInspectionsListSummaryModal";

import "../shared/DataTable.css";
import "../shared/ListingPage.css";
import "./StockInspectionsListPage.css";


// NOTE(yemon): Backward compatibility sake, especially when it comes to ISO datetime format.
// Need to remove when everything is migrated to API2.
const isUsingApi2 = true;

const inspectionTypes = refs.inventory.stockInspectionType;

export const StockInspectionsListPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [stockInspections, setStockInspections] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [servicePermissions, setServicePermissions] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.jobHistories);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext !== null) {
      triggerSearchByContext(searchOptionsContext, paginationContext);
    }
    else {
      fetchStockInspections(paginationContext ? paginationContext['currentPage'] : 1);
    }
    //resetSearchPanelFields();
    fetchListPermissions();
  }, []);

  const fetchListPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response['data'];
        setServicePermissions(_servicePermissions);
      });
  }

  const prepareListPayload = (page) => {
    let settings = getViewSettings(viewSettings.stockInspections);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const fetchStockInspections = (page) => {
    resetListingStates();
    setIsLoading(true);

    const payload = prepareListPayload(page);
    technicalServices.searchStockInspections(payload)
      .then((response) => {
        updateListingStates(response['data']);

        // NOTE(yemon): resetting the summary type would automatically re-trigger the summary callback
        summaryModal.resetSummary();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const updateListingStates = (responseData) => {
    setStockInspections(responseData['data']);
    setPagination(responseData['pagination']);
    setSorting(responseData['sorting']);
    savePaginationContext(contexts.stockInspections, responseData['pagination']);
  }

  const resetListingStates = () => {
    setStockInspections([]);
    setPagination(null);
    setSorting(null);
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();
    setIsSearchOpen(false);
    saveSearchPanelOpenContext(contexts.stockInspections, false);

    let paginationContext = getPaginationContext(contexts.stockInspections);
    fetchListPermissions();
    fetchStockInspections(paginationContext ? paginationContext['currentPage'] : 1);
  }

  const onRowClicked = (ev, stockInspection) => {
    let inspectionId = stockInspection['inspectionId'];
    let stockId = stockInspection['stockId'];
    let inspectionType = stockInspection['inspectionType'];
    setTimeout(() => {
      if (inspectionType === inspectionTypes.arrival) {
        navigate(routes.arrivalInspection.url, {
          state: {
            'stockId': stockId,
            'inspectionId': inspectionId,
          }
        });
      }
      else {
        navigate(routes.stockInspection.url, {
          state: {
            'stockId': stockId,
            'inspectionId': inspectionId,
          }
        });
      }
    }, 200);
  }

  const onRowStockClicked = (ev, stockInspection) => {
    let stockId = stockInspection['stockId'];
    setTimeout(() => {
      navigate(routes.stockDetails.url, {
        state: {
          'stockId': stockId,
        }
      });
    })
  }

  const onPageClick = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchStockInspections(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchStockInspections(page);
    }
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchStockInspections(page);
    }
  }
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [searchFields, setSearchFields] = useState({});
  const [searchOptions, setSearchOptions] = useState(null);
  const [gensetModelSearchTerm, setGensetModelSearchTerm] = useState('');

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Control handlers; Search
  const onSearchToggleClicked = () => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.stockInspections, _isOpen);
  }

  const validateSearchDateRanges = () => {
    let validation = {
      hasErrors: false,
      errorMessage: '',
    };

    let _fromDate = searchFields['inspection_date_from'];
    let _toDate = searchFields['inspection_date_to'];
    if (_fromDate && _toDate) {
      if (_toDate < _fromDate) {
        validation.hasErrors = true;
        validation.errorMessage = 'Invalid inspection date range.';
      }
    }
    if (validation.hasErrors) {
      return validation;
    }

    if (_fromDate && !_toDate) {
      _toDate = new Date();
    }
    if (!_fromDate && _toDate) {
      // set the first day of the current 'to date' month
      _fromDate = new Date(_toDate.getFullYear(), _toDate.getMonth(), 1);
    }
    validation.fromDate = _fromDate;
    validation.toDate = _toDate;

    return validation;
  }

  const validateAndPrepareSearchOptions = () => {
    let dateValidation = validateSearchDateRanges();
    if (dateValidation.hasErrors) {
      setHasSearchErrors(true);
      setSearchErrorMessage(dateValidation.errorMessage);
      return null;
    }

    let searchOptions = {
      ...searchFields,
    };

    if (!dateValidation.hasErrors && (dateValidation.fromDate && dateValidation.toDate)) {
      setSearchFields({
        ...searchFields,
        'inspection_date_from': dateValidation.fromDate,
        'inspection_date_to': dateValidation.toDate,
      });
      if (isUsingApi2) {
        searchOptions['inspection_date_from'] = dateValidation.fromDate.toISOString();
        searchOptions['inspection_date_to'] = dateValidation.toDate.toISOString();
      }
      else {
        searchOptions['inspection_date_from'] = dateValidation.fromDate.toLocaleString();
        searchOptions['inspection_date_to'] = dateValidation.toDate.toLocaleString();
      }
    }

    return searchOptions;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;
    setSearchOptions(searchOptions);

    let searchOptionsContext = {
      ...searchOptions,
      'genset_model_term': gensetModelSearchTerm,
    };
    saveSearchPanelOptionsContext(contexts.stockInspections, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    };
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);

    technicalServices.searchStockInspections(listPayload)
      .then((response) => {
        setIsSearching(true);
        updateListingStates(response['data']);

        summaryModal.fetchSummary(summaryType, true, listPayload['search_options']);
      })
      .finally(() => {
        setIsSearchLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.stockInspections);
    fetchStockInspections(1);
    resetSearchPanelFields();
  }

  const resetSearchPanelFields = () => {
    setIsSearching(false);
    setSearchOptions(null);
    setSearchFields({
      'inspection_date_from': null,
      'inspection_date_to': null,
      'inspected_by_id': '',
      'generator_serial': '',
      'genset_model_id': '',
      'inspection_type': -1,
      'job_status': -1,
    });
    setGensetModelSearchTerm('');
    setHasSearchErrors(false);
    setSearchErrorMessage('');
  }

  const onInspectionDateFromSearchChanged = (fromDate) => {
    setSearchFields({
      ...searchFields,
      'inspection_date_from': fromDate,
    });
  }

  const onInspectionDateToSearchChanged = (toDate) => {
    setSearchFields({
      ...searchFields,
      'inspection_date_to': toDate,
    });
  }

  const onInspectedBySearchChanged = (employeeId) => {
    setSearchFields({
      ...searchFields,
      'inspected_by_id': employeeId,
    });
  }

  const onGeneratorSerialSearchChanged = (ev) => {
    setSearchFields({
      ...searchFields,
      'generator_serial': ev.target.value,
    });
  }

  const onGensetModelTermSearchChanged = (ev, gensetModelTerm, prevGensetModelTerm) => {
    if (gensetModelTerm.trim() === '') {
      setGensetModelSearchTerm('');
      setSearchFields({
        ...searchFields,
        'genset_model_id': '',
      });
      return false;
    }
    if (gensetModelTerm === prevGensetModelTerm) {
      return false;
    }
    setGensetModelSearchTerm(gensetModelTerm);
    setSearchFields({
      ...searchFields,
      'genset_model_id': '',
    });
    return true;
  }

  const onGensetModelSuggestionClicked = (ev, generator) => {
    setGensetModelSearchTerm(generator['gensetModel']);
    setSearchFields({
      ...searchFields,
      'genset_model_id': generator['id'],
    });
  }

  const onInspectionTypeSearchChanged = (inspectionType) => {
    setSearchFields({
      ...searchFields,
      'inspection_type': parseInt(inspectionType),
    });
  }

  const onJobStatusChanged = (jobStatus) => {
    setSearchFields({
      ...searchFields,
      'job_status': parseInt(jobStatus),
    });
  }
  //#endregion

  //#region Quick Summary modal;
  const [isSummaryModalOpened, setIsSummaryModalOpened] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [summaryType, setSummaryType] = useState(stockInspectionSummaryTypes.inspectionType);
  const [summaryData, setSummaryData] = useState([]);
  const [summaryTotal, setSummaryTotal] = useState(0);

  useEffect(() => {
    summaryModal.fetchSummary(summaryType, isSearching, searchOptions);
  }, [summaryType])

  const summaryModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsSummaryModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsSummaryModalOpened(false);
      }, 200);
    },

    fetchSummary: function(type, isSearching = false, searchOptions = null) {
      let payload = {
        'uid': auth.getUserId(),
        'type': type,
        'isSearching': isSearching,
        'searchOptions': searchOptions,
      };

      setIsSummaryLoading(true);
      technicalServices.summarizeStockInspections(payload)
        .then((response) => {
          const responseData = response['data'];
          setTimeout(() => {
            setSummaryData(responseData['data']);
            setSummaryTotal(responseData['total']);
          }, 500);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setIsSummaryLoading(false));
    },

    resetSummary: function() {
      setSummaryType(stockInspectionSummaryTypes.inspectionType);
      setSummaryData([]);
      setSummaryTotal(0);
    },
  }
  //#endregion

  //#region View Settings modal; Handlers and other utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    }
    else {
      fetchStockInspections(1);
    }
  }
  //#endregion

  //#region Listing view context
  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.stockInspections);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.stockInspections);
    if (searchOptions === null) {
      return null;
    }

    setSearchFields({
      'inspection_date_from': searchOptions['inspection_date_from'] ? new Date(searchOptions['inspection_date_from']) : null,
      'inspection_date_to': searchOptions['inspection_date_to'] ? new Date(searchOptions['inspection_date_to']) : null,
      'inspected_by_id': searchOptions['inspected_by_id'],
      'generator_serial': searchOptions['generator_serial'],
      'genset_model_id': searchOptions['genset_model_id'],
      'inspection_type': searchOptions['inspection_type'],
      'job_status': searchOptions['job_status'],
    });
    setGensetModelSearchTerm(searchOptions['genset_model_term']);

    return searchOptions;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container stock-inspections-list-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.stockInspections.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.stockInspections.display}</h1>

            <div className={"listing-controls"}>
              <div className={"left"}>
                <button className={"btn btn-secondary"} disabled={isLoading || isSearchLoading}
                        onClick={onRefreshClicked}>
                  {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>

                <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading}
                        onClick={onSearchToggleClicked}>
                  {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                  {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                  <span>Search</span>
                </button>
              </div>

              <div className={"right"}>
                <button type={"button"} className={"btn btn-secondary"} disabled={isLoading}
                        onClick={summaryModal.onOpenButtonClicked}>
                  <i className={"fa-regular fa-rectangle-list"}></i>
                  <span>Quick Summary...</span>
                </button>
              </div>
            </div>
          </div>

          <StockInspectionsListSummaryModal isOpen={isSummaryModalOpened} onRequestClose={summaryModal.onCloseButtonClicked}
                                            isSearching={isSearching} searchFields={searchFields}
                                            isSummaryLoading={isSummaryLoading}
                                            summaryType={summaryType} setSummaryType={setSummaryType}
                                            data={summaryData} total={summaryTotal} />

          <StockInspectionsListSearchPanel isSearchOpen={isSearchOpen} isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                           searchFields={searchFields}
                                           onInspectionDateFromChanged={onInspectionDateFromSearchChanged}
                                           onInspectionDateToChanged={onInspectionDateToSearchChanged}
                                           onTestedByChanged={onInspectedBySearchChanged}
                                           onGeneratorSerialChanged={onGeneratorSerialSearchChanged}
                                           gensetModelTerm={gensetModelSearchTerm}
                                           onGensetModelTermChanged={onGensetModelTermSearchChanged}
                                           onGensetModelSuggestionClicked={onGensetModelSuggestionClicked}
                                           onInspectionTypeChanged={onInspectionTypeSearchChanged}
                                           onJobStatusChanged={onJobStatusChanged}
                                           onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                           hasErrors={hasSearchErrors} errorMessage={searchErrorMessage} />

          <div className={"data-table"}>
            <table>
              <thead>
                <StockInspectionsTableHeading />
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={6} />}

              {stockInspections && stockInspections.length > 0 && !isLoading &&
                stockInspections.map((stockInspection, index) =>
                  <StockInspectionsTableRow key={stockInspection['inspectionId']} stockInspection={stockInspection} index={index}
                                            currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                            onRowClicked={onRowClicked} onRowStockClicked={onRowStockClicked} />
                )
              }

              {!stockInspections || (stockInspections.length === 0 && !isLoading &&
                  <TableEmptyRow colSpan={6} />
              )}
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.stockInspections}
                               onViewSettingsSaved={onViewSettingsSaved} />
            }
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
//#endregion
}


const StockInspectionsTableHeading = () => {
  return (
    <tr>
      <th scope={"col"} className={"index-col-head"}>#</th>
      <th scope={"col"}>
        Inspection Date
        <SortIndicator isAscending={false} />
      </th>
      <th scope={"col"}>Tested By / Branch</th>
      <th scope={"col"}>Generator Serial / Genset Model</th>
      <th scope={"col"}>Inspection Type</th>
      <th scope={"col"}>Status</th>
    </tr>
  )
}

const StockInspectionsTableRow = ({
                                    stockInspection, index,
                                    currentPage, pageSize,
                                    onRowClicked, onRowStockClicked,
                                  }) => {
  return (
    <tr>
      <td className={"index-col"}>{getListRowSerial(pageSize, currentPage, index)}</td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={`View the ${inspectionTypes[stockInspection['inspectionType']]} inspection history entry`}
           onClick={(ev) => onRowClicked(ev, stockInspection)}>
          <Moment date={stockInspection['inspectionDatetime']} format={formatters.datetimeShort} />
        </a>
      </td>
      <td>
        <ActorPureNameDisplay employeeId={stockInspection['inspectedById']}
                              employeeName={stockInspection['inspectedByFullName']} />
        {stockInspection['inspectedById'] !== EMPTY_GUID &&
          <>&nbsp;/&nbsp;
            <span title={"Branch"}>
              {stockInspection['inspectedByBranchName'] ? stockInspection['inspectedByBranchName'] : '-'}
            </span>
          </>
        }
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={"View the generator stock"}
           onClick={(ev) => onRowStockClicked(ev, stockInspection)}>
          <span title={"Generator serial"}>{stockInspection['generatorSerial']}</span>&nbsp;/&nbsp;
          <span title={"Genset model"}>{stockInspection['gensetModel']}</span>
        </a>
      </td>
      <td>
        {inspectionTypes[stockInspection['inspectionType']]}
      </td>
      <td>
        <JobStatusBadge status={stockInspection['jobStatus']} />
      </td>
    </tr>
  )
}
