import React from "react";

import '../shared/EntryForm.css';

export const HourMinutesPicker = ({
                                    hourId, minuteId, disabled,
                                    hour, onHourChanged,
                                    minute, onMinuteChanged,
                                  }) => {
  const getInlineLabelStyle = () => {
    return {
      marginLeft: "8px",
      marginRight: "8px",
    }
  }

  return (
    <div className={"inline-field-group"}>
      <select id={hourId} name={hourId} className={"select-field"} disabled={disabled}
              style={{ textAlign: "right" }}
              value={hour} onChange={onHourChanged}>
        <option value={0}>-</option>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value, index) =>
          <option key={index} value={value}>{value}</option>)
        }
      </select>
      <span style={getInlineLabelStyle()}>:</span>
      <select id={minuteId} name={minuteId} className={"select-field"} disabled={disabled}
              value={minute} onChange={onMinuteChanged}>
        <option value={0}>00</option>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59].map(
          (value, index) => <option key={index} value={value}>
            {value.toLocaleString("en-US", { minimumIntegerDigits: 2 })}
          </option>
        )}
      </select>
    </div>
  )
}

export const MinutesPicker = ({
                                id, disabled,
                                minute, onMinuteChanged,
                              }) => {
  return (
    <select id={id} name={id} className={"form-control form-select"} disabled={disabled}
            style={{ textAlign: "right" }}
            value={minute} onChange={onMinuteChanged}>
      <option value={0}>00</option>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60].map(
        (value, index) => <option key={index} value={value}>
          {value.toLocaleString("en-US", { minimumIntegerDigits: 2 })}
        </option>
      )}
    </select>
  )
}

export const formatHourMinutes = (hour, minute) => {
  if ((hour === null || hour === 0) && (minute === null || minute === 0)) {
    return "--:--";
  }

  let _hour = "";
  let _minute = "";
  if (hour === null || hour === 0) {
    _hour = "00";
  }
  else {
    _hour = parseInt(hour).toLocaleString("en-US", { minimumIntegerDigits: 2 });
  }
  if (minute === null || minute === 0) {
    _minute = "00";
  }
  else {
    _minute = parseInt(minute).toLocaleString("en-US", { minimumIntegerDigits: 2 });
  }
  return `${_hour}:${_minute}`;
}

export const formatMinutes = (minute) => {
  if (minute === null || minute === 0) {
    return "- min";
  }
  return `${parseInt(minute).toLocaleString("en-US", { minimumIntegerDigits: 2 })} min`;
}
