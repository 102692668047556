export const validateSearchDateRange = (fromDate, toDate) => {
  let validation = {
    hasErrors: false,
    errorMessage: '',
  };
  if (fromDate && toDate) {
    if (toDate < fromDate) {
      validation.hasErrors = true;
      validation.errorMessage = 'Invalid date range.';
      return validation;
    }
  }

  if (fromDate && !toDate) {
    toDate = new Date();
  }
  if (!fromDate && toDate) {
    // set the first day of the current 'to date' month
    fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
  }
  validation.fromDate = fromDate;
  validation.toDate = toDate;
  return validation;
}

export const expandSuggestionsResult = (elementId, suggestionsElementId) => {
  setTimeout(() => {
    let fieldElement = document.getElementById(elementId);
    let suggestionsElement = document.getElementById(suggestionsElementId);
    if (suggestionsElement) {
      suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
      suggestionsElement.style.left = `${fieldElement.offsetLeft}px`;
    }
  }, 100);
}

export const getEmptyGensetSuggestion = () => {
  return {
    'id': '0',
    'gensetModel': '(No genset)',
  };
}

//#region Form field style helpers
export const getSmWidth = () => {
  return {
    width: '160px'
  }
}

export const getMidWidth = () => {
  return {
    width: '220px'
  }
}

export const getLeftPadding = () => {
  return {
    marginLeft: '8px'
  }
}
//#endregion
