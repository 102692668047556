import React from "react";

import { useAuth } from "../auth/AuthProvider";

import { LoginInfo } from "../auth/LoginInfo";

import './Header.css';


export const Header = () => {
  const auth = useAuth();

  const toggleNavigation = () => {
    document.getElementById('navbar').classList.toggle('show');
  }

  const hideNavigation = () => {
    document.getElementById('navbar').classList.remove('show');
  }

  return (
    <div className="header navbar navbar-expand-md fixed-top">
      <div className="nav-expand-button">
        <button type={"button"} onClick={toggleNavigation} onBlur={hideNavigation}>
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>

      <div className="logo">
        <img src="/logo.png" width="64" height="64" alt="logo" />
      </div>
      <div className="title">
        <a href="/">
          <span className="company-name">PTI</span>
          <span>MIS</span>
        </a>
      </div>

      {auth.isLoggedIn && <LoginInfo />}
    </div>
  )
}
