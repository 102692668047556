import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { EmployeeBranchGroupsDropdown } from "../shared/EmployeesDropdown";

import "../shared/SearchPanel.css";


const serviceTypes = refs.inventory.serviceType;
const callTypes = refs.customer.callType;
const contactTypes = refs.customer.contactType;
const contactSatisfactions = refs.customer.satisfaction;

export const CareContactLogsSearchPanel = ({
                                             isSearchOpen, isSearchLoading, isListLoading,
                                             searchFields,
                                             onRecordedDateFromChanged,
                                             onRecordedDateToChanged,
                                             onRecordedByChanged,
                                             onCustomerNameChanged,
                                             onCallTypeChanged,
                                             onContactTypeChanged,
                                             onFollowupServiceHistoryTypeChanged,
                                             onContactSatisfactionChanged,
                                             onSearchClicked, onClearClicked,
                                             hasErrors, errorMessage,
                                           }) => {
  //#region States
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = () => {
    setIsLoadingEmployees(true);
    refServices2.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _responseData = response['data'];
        setEmployeeBranchGroups(_responseData);
      })
      .finally(() => setIsLoadingEmployees(false));
  }
  //#endregion

  //#region Utilities
  const getSearchPanelClass = () => {
    return "search-panel" + (isSearchOpen ? " search-panel-open" : " search-panel-hide");
  }

  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (searchFields['recorded_date_from'] && !isToDate) className += " has-autocompleted-value";
    else if (searchFields['recorded_date_to'] && isToDate) className += " has-autocompleted-value";
    return className;
  }

  const isFormControlsDisabled = () => {
    return isSearchLoading || isListLoading;
  }
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <div className={getSearchPanelClass()}>
      <form onSubmit={onSearchClicked}>
        <div className={"search-fields"}>
          <div className={"date-field from-date-field"}>
            <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                        placeholderText={"Report date from."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={searchFields['recorded_date_from']} onChange={onRecordedDateFromChanged} />
          </div>
          <div className={"date-field"}>
            <DatePicker id={"dateTo"} className={getDateClassName(true)}
                        placeholderText={"Report date to."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={searchFields['recorded_date_to']} onChange={onRecordedDateToChanged} />
          </div>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"searchEmployee"} name={"searchEmployee"}
                                        isDisabled={isFormControlsDisabled() || isLoadingEmployees}
                                        value={searchFields['recorded_by_id']} onChange={(ev) => onRecordedByChanged(ev.target.value)} />
          <input type={"text"} id={"searchCustomerName"} className={"form-control md-field"}
                 autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"Customer Name"} value={searchFields['customer_name']} onChange={onCustomerNameChanged} />
        </div>

        <div className={"search-fields"}>
          <select id={"searchCallType"} name={"searchCallType"} className={"form-control form-select sm-field"}
                  disabled={isFormControlsDisabled()}
                  value={searchFields['call_type']} onChange={(ev) => onCallTypeChanged(ev.target.value)}>
            <option value={-1}>- Any -</option>
            <option value={callTypes.inbound}>{callTypes[callTypes.inbound]}</option>
            <option value={callTypes.outbound}>{callTypes[callTypes.outbound]}</option>
          </select>
          <select id={"searchContactType"} name={"searchContactType"} className={"form-control form-select lg-field"}
                  disabled={isFormControlsDisabled()}
                  value={searchFields['contact_type']} onChange={(ev) => onContactTypeChanged(ev.target.value)}>
            <option value={-1}>- Any -</option>
            <option value={contactTypes.regularCall}>{contactTypes[contactTypes.regularCall]}</option>
            <option value={contactTypes.postServiceCall}>{contactTypes[contactTypes.postServiceCall]}</option>
            <option value={contactTypes.inquiry}>{contactTypes[contactTypes.inquiry]}</option>
            <option value={contactTypes.complaint}>{contactTypes[contactTypes.complaint]}</option>
          </select>
          <select id={"searchContactType"} name={"searchContactType"} className={"form-control form-select lg-field"}
                  disabled={isFormControlsDisabled()}
                  value={searchFields['followup_service_history_type']} onChange={(ev) => onFollowupServiceHistoryTypeChanged(ev.target.value)}>
            <option value={-1}>- Any -</option>
            <option value={serviceTypes.na}>- (NA) -</option>
            <option value={serviceTypes.tnc}>{serviceTypes[serviceTypes.tnc]}</option>
            <option value={serviceTypes.pm}>{serviceTypes[serviceTypes.pm]}</option>
            <option value={serviceTypes.regular}>{serviceTypes[serviceTypes.regular]}</option>
            <option value={serviceTypes.repair}>{serviceTypes[serviceTypes.repair]}</option>
            <option value={serviceTypes.emergency}>{serviceTypes[serviceTypes.emergency]}</option>
            <option value={serviceTypes.inspection}>{serviceTypes[serviceTypes.inspection]}</option>
          </select>
          <select id={"searchContactSatisfaction"} name={"searchContactSatisfaction"} className={"form-control form-select md-field"}
                  disabled={isFormControlsDisabled()}
                  value={searchFields['contact_satisfaction']} onChange={(ev) => onContactSatisfactionChanged(ev.target.value)}>
            <option value={-1}>- Any -</option>
            <option value={contactSatisfactions.na}>- (NA) -</option>
            <option value={contactSatisfactions.satisfied}>{contactSatisfactions[contactSatisfactions.satisfied]}</option>
            <option value={contactSatisfactions.dissatisfied}>{contactSatisfactions[contactSatisfactions.dissatisfied]}</option>
            <option value={contactSatisfactions.unreachable}>{contactSatisfactions[contactSatisfactions.unreachable]}</option>
          </select>
        </div>

        <div className={"search-fields"}>
          <FieldErrorMessage visible={hasErrors} message={errorMessage} />
        </div>

        <div className={"search-controls"}>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
        </div>
      </form>
    </div>
  )
  //#endregion
}
