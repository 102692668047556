import React from "react";

import "../shared/StatusBadge.css";

export const JobStatusBadge = ({ status }) => {
  const getBadgeClass = () => {
    let className = "badge";
    switch (status) {
      case 0:
        return className + " badge-draft";
      case 1:
        return className + " badge-pending-review";
      case 2:
        return className + " badge-approved";
      default:
        return className + " badge-draft";
    }
  }

  const getBadgeDescription = () => {
    switch (status) {
      case 0:
        return "Draft";
      case 1:
        return "Pending Review";
      case 2:
        return "Active";
      default:
        return "(NA)";
    }
  }

  return (
    <span className={getBadgeClass()}>{getBadgeDescription()}</span>
  )
}
