import ReactModal from 'react-modal';
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";
import { isEmployeeAssignedToInquiry } from "../../repos/utilities";

import { ModalCloseButton } from "../shared/ModalContainer";
import { ActorNameDisplay } from "./ActorNameDisplay";

import "../shared/Modals.css";


export const DuplicateContactsModal = ({
                                         isOpen, isLoading,
                                         onRequestClose,
                                         duplicateContacts,
                                       }) => {
  const navigate = useNavigate();

  const onRowClicked = (ev, inquiry) => {
    ev.preventDefault();
    setTimeout(() => {
      navigate(routes.salesInquiriesEntry.url, { state: inquiry, replace: true });
    }, 200);
  }

  return (
    <ReactModal isOpen={isOpen} id={"duplicate-contacts-modal"}
                className={"popup-modal"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onRequestClose}>
      <div className={"modal-heading"}>
        <h2>Duplicate Contacts Found</h2>
      </div>
      {isLoading &&
        <div className={"modal-loading-indicator"}>
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      }
      {!isLoading &&
        <>
          <ModalCloseButton onRequestClose={onRequestClose} />
          <div className={"modal-contents"}>
            <div>
              Inquiry contacts that have exactly the same <i>contact name</i>, <i>contact number</i>, and also <i>resides in the same city</i>.
            </div>
          </div>

          <div className={"modal-data-table"}>
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Contact No</th>
                <th scope="col">City/Township</th>
                <th scope="col">Primary Reporter</th>
                <th scope="col">Last Entry Date</th>
              </tr>
              </thead>
              <tbody>
              {duplicateContacts && duplicateContacts.length > 0 &&
                duplicateContacts.map((inquiryContact, index) =>
                  <DuplicateContactRow inquiryContact={inquiryContact} onRowClicked={onRowClicked}
                                       index={index} key={inquiryContact["entryId"]} />
                )
              }
              </tbody>
            </table>
          </div>
        </>
      }
    </ReactModal>
  )
}


const DuplicateContactRow = ({
                               inquiryContact, index, onRowClicked,
                             }) => {
  const auth = useAuth();

  return (
    <tr key={index}>
      <td className={"index-col"}>{index + 1}</td>
      <td>
        {/*{isEmployeeAssignedToInquiry(auth.getUserId()['eid'], inquiryContact['reporters']) ?*/}
        {/*  <a href="#" role={"button"} className={"record-link"} onClick={(ev) => onRowClicked(ev, inquiryContact)}>*/}
        {/*    {inquiryContact["contactFullName"]}*/}
        {/*  </a>*/}
        {/*  :*/}
        {/*  <>{inquiryContact["contactFullName"]}</>*/}
        {/*}*/}
        {inquiryContact["contactFullName"]}
      </td>
      <td>
        {inquiryContact["contactNo"]}
      </td>
      <td>
        {getRegionDisplay(inquiryContact['city'], inquiryContact['township'])}
      </td>
      <td>
        <ActorNameDisplay employee={inquiryContact["primaryReporter"]["salesPerson"]} />
      </td>
      <td>
        <Moment date={inquiryContact["lastEntryDatetime"]} format={formatters.datetimeShort} />
      </td>
    </tr>
  )
}
