import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { role as roleConstants } from "../../repos/constants";
import { customer } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getCancelRequestsContext } from "../../repos/viewContexts";
import { saveCancelRequestsContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";
import { formatCurrency } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";
import { validateSearchDateRange } from "../../repos/searchUtilities";
import { doVirtualDownload } from "../../repos/exports";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { TableEmptyRow } from "../shared/DataTable";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { ReviewStatusBadge } from "../quotation/ReviewStatusBadge";
import { OrderConfirmationCancelRequestsModal } from "./OrderConfirmationCancelRequestsModal";
import { OrderConfirmationSearchPanel } from "./OrderConfirmationSearchPanel";
import { GeneratorsSummary } from "../sales/GeneratorsSummary";

import "../shared/DataTable.css";
import '../shared/ListingPage.css';
import "./OrderConfirmationListPage.css";


export function OrderConfirmationListPage() {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [orderConfirmations, setOrderConfirmations] = useState([]);
  const [sorting, setSorting] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [listPermissions, setListPermissions] = useState({});

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // flag to indicate whether the last post back was a search trigger
  const [isExporting, setIsExporting] = useState(false);

  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [companyNameSearch, setCompanyNameSearch] = useState('');
  const [contactNoSearch, setContactNoSearch] = useState('');
  const [gensetModelTermSearch, setGensetModelTermSearch] = useState('');
  const [gensetModelIdSearch, setGensetModelIdSearch] = useState('');
  const [paymentTermSearch, setPaymentTermSearch] = useState(0);
  const [reviewStatusSearch, setReviewStatusSearch] = useState(-1);
  const [dateFromSearch, setDateFromSearch] = useState(null);
  const [dateToSearch, setDateToSearch] = useState(null);
  const [reporterIdSearch, setReporterIdSearch] = useState('');
  const [currencyCodeSearch, setCurrencyCodeSearch] = useState(0);

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.orderConfirmations);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext != null) {
      // trigger search with the existing context
      triggerSearchByContext(searchOptionsContext, paginationContext);
    } else {
      // load the regular list
      fetchOrderConfirmationsList(paginationContext ? paginationContext['currentPage'] : 1);
    }

    let cancelRequestContext = getCancelRequestsContext(contexts.quotationRequests);
    if (cancelRequestContext && cancelRequestContext['isModalOpened']) cancelRequestsModal.onOpenButtonClicked();
    fetchOrderConfirmationCancelRequests();
  }, []);

  const prepareListPayload = (page) => {
    let settings = getViewSettings(viewSettings.orderConfirmations);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }
  //#endregion

  //#region Utilities
  const fetchOrderConfirmationsList = (page) => {
    setIsLoading(true);
    resetListingStates();
    let listPayload = prepareListPayload(page);
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _listPermissions = response.data['orderConfirmations'];
        setListPermissions(_listPermissions);

        return salesServices.orderConfirmationsList(listPayload);
      })
      .then((response) => {
        updateListingStates(response.data);
      })
      .catch((error) => {
        console.error(error['response']['data']);
      })
      .finally(() => setIsLoading(false));
  }

  const updateListingStates = (responseData) => {
    setOrderConfirmations(responseData['data']);
    setSorting(responseData['sorting']);
    savePaginationContext(contexts.orderConfirmations, responseData['pagination']);
    setPagination(responseData['pagination']);
  }

  const resetListingStates = () => {
    setOrderConfirmations([]);
    setSorting(null);
    setPagination(null);
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();
    setIsSearchOpen(false);
    saveSearchPanelOpenContext(contexts.orderConfirmations, false);

    let paginationContext = getPaginationContext(contexts.orderConfirmations);
    fetchOrderConfirmationsList(paginationContext ? paginationContext['currentPage'] : 1);
    fetchOrderConfirmationCancelRequests();
  }

  const onNewEntryClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.orderConfirmationEntry.url);
    }, 200);
  }

  const onRowClicked = (ev, entry) => {
    setTimeout(() => {
      navigate(routes.orderConfirmationEntry.url, { state: entry });
    }, 200);
  }

  const onPageClicked = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    } else {
      fetchOrderConfirmationsList(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    fetchOrderConfirmationsList(page);
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    fetchOrderConfirmationsList(page);
  }
  //#region

  //#region Search panel; Control handlers, loaders and utilities
  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.orderConfirmations);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.orderConfirmations);
    if (searchOptions === null) return null;

    // NOTE(yemon): Field values in the context were saved from the postback payload.
    setCustomerNameSearch(searchOptions['customer_name']);
    setCompanyNameSearch(searchOptions['company_name']);
    setContactNoSearch(searchOptions['contact_no']);

    setPaymentTermSearch(searchOptions['payment_term']);
    setReviewStatusSearch(searchOptions['review_status']);
    setReporterIdSearch(searchOptions['reporter_id']);
    setCurrencyCodeSearch(searchOptions['currency_code']);

    setGensetModelTermSearch(searchOptions['genset_model_term']);
    setGensetModelIdSearch(searchOptions['genset_model_id']);

    setDateFromSearch(searchOptions['date_from'] ? new Date(searchOptions['date_from']) : null);
    setDateToSearch(searchOptions['date_to'] ? new Date(searchOptions['date_to']) : null);

    return searchOptions;
  }

  const onSearchToggleClicked = (ev) => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.orderConfirmations, _isOpen);
  }

  const onCustomerNameSearchChanged = (ev) => {
    setCustomerNameSearch(ev.target.value);
  }

  const onCompanyNameSearchChanged = (ev) => {
    setCompanyNameSearch(ev.target.value);
  }

  const onContactNoSearchChanged = (ev) => {
    setContactNoSearch(ev.target.value);
  }

  const onGensetModelTermSearchChanged = (ev, gensetModelTerm, prevGensetModelTerm) => {
    if (gensetModelTerm.trim() === '') {
      setGensetModelTermSearch('');
      setGensetModelIdSearch('');
      return false;
    }
    if (gensetModelTerm === prevGensetModelTerm) {
      return false;
    }
    setGensetModelTermSearch(gensetModelTerm);
    setGensetModelIdSearch('');
    return true;
  }

  const onGensetModelSuggestionClicked = (ev, generator) => {
    setGensetModelTermSearch(generator['gensetModel']);
    setGensetModelIdSearch(generator.id);
  }

  const onPaymentTermSearchChanged = (paymentTermId) => {
    setPaymentTermSearch(paymentTermId);
  }

  const onReviewStatusSearchChanged = (statusId) => {
    setReviewStatusSearch(statusId);
  }

  const onDateFromSearchChanged = (fromDate) => {
    setDateFromSearch(fromDate);
  }

  const onDateToSearchChanged = (toDate) => {
    setDateToSearch(toDate);
  }

  const onReporterIdSearchChanged = (employeeId) => {
    setReporterIdSearch(employeeId);
  }

  const onCurrencyCodeSearchChanged = (currencyCode) => {
    setCurrencyCodeSearch(currencyCode);
  }

  const validateAndPrepareSearchOptions = () => {
    let dateValidation = validateSearchDateRange(dateFromSearch, dateToSearch);
    if (dateValidation.hasErrors) {
      setHasSearchErrors(true);
      setSearchErrorMessage(dateValidation.errorMessage);
      return null;
    }

    let searchOptions = {
      'customer_name': customerNameSearch,
      'company_name': companyNameSearch,
      'contact_no': contactNoSearch,
      'payment_term': paymentTermSearch,
      'review_status': reviewStatusSearch,
      'reporter_id': reporterIdSearch,
      'currency_code': currencyCodeSearch,
    };
    if (gensetModelIdSearch) {
      searchOptions['genset_model_id'] = gensetModelIdSearch;
    }
    if (!dateValidation.hasErrors && (dateValidation.fromDate && dateValidation.toDate)) {
      setDateFromSearch(dateValidation.fromDate);
      setDateToSearch(dateValidation.toDate);
      searchOptions['date_from'] = dateValidation.fromDate.toLocaleString();
      searchOptions['date_to'] = dateValidation.toDate.toLocaleString();
    }
    return searchOptions;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;

    let searchOptionsContext = {
      ...searchOptions,
      'genset_model_term': gensetModelTermSearch,
    }
    saveSearchPanelOptionsContext(contexts.orderConfirmations, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    }
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);
    setIsLoading(true);
    salesServices.orderConfirmationsList(listPayload)
      .then((response) => {
        setIsSearching(true);
        updateListingStates(response.data);
      })
      .catch((error) => {
        setIsSearching(false);
        setIsLoading(false);
      })
      .finally(() => {
        setIsSearchLoading(false);
        setIsLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const resetSearchPanelFields = () => {
    setIsSearching(false);
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    setCustomerNameSearch('');
    setContactNoSearch('');
    setCompanyNameSearch('');
    setGensetModelTermSearch('');
    setGensetModelIdSearch('');
    setPaymentTermSearch(0);
    setReviewStatusSearch(-1);
    setReviewStatusSearch(-1);
    setDateFromSearch(null);
    setDateToSearch(null);
    setReporterIdSearch('');
    setCurrencyCodeSearch(0);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.orderConfirmations);
    resetSearchPanelFields();
    fetchOrderConfirmationsList(1);
  }

  const onExcelExportButtonClicked = (ev) => {
    let listOptionsPayload = prepareListPayload(1);

    if (isSearching) {
      let searchOptions = validateAndPrepareSearchOptions();
      if (searchOptions === null) return;
      listOptionsPayload['is_searching'] = isSearching;
      listOptionsPayload['search_options'] = searchOptions;
    }

    setIsExporting(true);
    salesServices.postOrderConfirmationListExport(listOptionsPayload)
      .then((response) => {
        doVirtualDownload(response, 'OrderConfirmations', 'xlsx');
      })
      .catch((error) => {
        console.error(error['response']);
      })
      .finally(() => setIsExporting(false));
  }
  //#endregion

  //#region Control handlers; Cancel Requests modal related stuffs
  const [isCancelRequestsModalOpen, setIsCancelRequestsModalOpen] = useState(false);
  const [isCancelRequestsLoading, setIsCancelRequestsLoading] = useState(false);
  const [cancelRequests, setCancelRequests] = useState([]);

  const cancelRequestsModal = {
    onOpenButtonClicked: function() {
      saveCancelRequestsContext(contexts.orderConfirmations, true);
      setTimeout(() => {
        setIsCancelRequestsModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      saveCancelRequestsContext(contexts.orderConfirmations, false);
      setTimeout(() => {
        setIsCancelRequestsModalOpen(false);
      }, 200);
    },
  }

  const fetchOrderConfirmationCancelRequests = () => {
    setIsCancelRequestsLoading(true);
    let tableOptions = {
      'uid': auth.getUserId(),
    };
    return salesServices.orderConfirmationCancelRequestsList(tableOptions)
      .then((response) => {
        let _cancelRequests = response.data.data;
        setCancelRequests(_cancelRequests);
      })
      .finally(() => setIsCancelRequestsLoading(false));
  }

  const onCancelRequestRowClicked = (ev, cancelRequest) => {
    ev.preventDefault();
    setTimeout(() => {
      navigate(routes.orderConfirmationEntry.url, { state: cancelRequest });
    })
  }
  //#endregion

  //#region View Settings modal; handlers and utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    } else {
      fetchOrderConfirmationsList(1);
    }
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container purchase-agreements-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.orderConfirmationsList.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>Order Confirmations</h1>
            <div className="listing-controls">
              <button className={"btn btn-secondary"} disabled={isLoading}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              {listPermissions && listPermissions['editLevel'] !== roleConstants.orderConfirmation.editLevels.readOnly &&
                <button className="btn btn-primary btn-md" disabled={isLoading} onClick={onNewEntryClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New Entry
                </button>
              }
              <button type={"button"} className={"btn btn-secondary"} disabled={isLoading || isCancelRequestsLoading}
                      onClick={cancelRequestsModal.onOpenButtonClicked}>
                {isCancelRequestsLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isCancelRequestsLoading && <i className="fa-solid fa-file-circle-xmark"></i>}
                <span>Cancel Requests...</span>
              </button>

              <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button>
            </div>
          </div>

          {isSearchOpen &&
            <OrderConfirmationSearchPanel isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                          customerName={customerNameSearch} onCustomerNameChanged={onCustomerNameSearchChanged}
                                          companyName={companyNameSearch} onCompanyNameChanged={onCompanyNameSearchChanged}
                                          contactNo={contactNoSearch} onContactNoChanged={onContactNoSearchChanged}
                                          gensetModelId={gensetModelIdSearch} gensetModel={gensetModelTermSearch}
                                          onGensetModelChanged={onGensetModelTermSearchChanged} onGensetModelSuggestionClicked={onGensetModelSuggestionClicked}
                                          paymentTermId={paymentTermSearch} onPaymentTermIdChanged={onPaymentTermSearchChanged}
                                          reviewStatus={reviewStatusSearch} onReviewStatusChanged={onReviewStatusSearchChanged}
                                          dateFrom={dateFromSearch} onDateFromChanged={onDateFromSearchChanged}
                                          dateTo={dateToSearch} onDateToChanged={onDateToSearchChanged}
                                          reporterId={reporterIdSearch} onReporterIdChanged={onReporterIdSearchChanged}
                                          currencyCode={currencyCodeSearch} onCurrencyChanged={onCurrencyCodeSearchChanged}
                                          onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                          onExportExcelClicked={onExcelExportButtonClicked} isExporting={isExporting}
                                          hasErrors={hasSearchErrors} errorMessage={searchErrorMessage}
            />
          }

          <OrderConfirmationCancelRequestsModal isOpen={isCancelRequestsModalOpen} isLoading={isCancelRequestsLoading}
                                                onRequestClose={cancelRequestsModal.onCloseButtonClicked}
                                                cancelRequests={cancelRequests}
                                                onCancelRequestRowClicked={onCancelRequestRowClicked}
          />

          <div className="data-table order-confirmation-table">
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Company</th>
                <th scope="col">Generators</th>
                <th scope="col" className={"right-aligned"}>Qty</th>
                <th scope="col" className={"right-aligned"}>Total Amount</th>
                <th scope="col">Payment Term</th>
                <th scope="col">Date</th>
                <th scope="col">Created By</th>
                <th scope="col">Status</th>
              </tr>
              </thead>

              <tbody>
              {isLoading && <TableLoadingIndicator colspan={10} />}

              {!isLoading && orderConfirmations && orderConfirmations.length > 0 &&
                orderConfirmations.map((entry, index) =>
                  <OrderConfirmationRow key={entry['entryId']} entry={entry} index={index}
                                        currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                        onRowClicked={onRowClicked}
                  />
                )
              }

              {!orderConfirmations || (orderConfirmations.length === 0 && !isLoading) &&
                <TableEmptyRow colSpan={10} />
              }
              </tbody>
            </table>

            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClicked}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.orderConfirmations} onViewSettingsSaved={onViewSettingsSaved}
              />
            }
          </div>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const OrderConfirmationRow = ({
                                entry, index,
                                currentPage, pageSize,
                                onRowClicked,
                              }) => {
  const renderCompanyName = (customer) => {
    if (!customer['companyName'] || customer['companyName'] === '') return <span className="na-value">-</span>
    return <span>{customer['companyName']}</span>
  }

  const renderGeneratorsSummary = (generatorDetails) => {
    if (!generatorDetails || generatorDetails.length === 0) return <span className="na-value">-</span>
    return <GeneratorsSummary generators={generatorDetails} summarized={false} />
  }

  const renderTotalQuantity = (generatorDetails) => {
    if (!generatorDetails || generatorDetails.length === 0) return <span className={"na-value"}>0</span>
    return <>{generatorDetails.map(item => item['quantity']).reduce((accumulated, current) => accumulated + current)}</>
  }

  const renderTotalAmount = (generatorDetails) => {
    if (!generatorDetails || generatorDetails.length === 0) return <span className={"na-value"}>0</span>
    return (
      <>
        {formatCurrency(generatorDetails.map(item => item['quantity'] * item['price']).reduce((accumulated, current) => accumulated + current))}
      </>
    )
  }

  return (
    <>
      <tr>
        <td className={"index-col"}>{(pageSize * (currentPage - 1) + (index + 1))}</td>
        <td className={"customer-name-col"}>
          <a href="#" role={"button"} className={"record-link"} onClick={(ev) => onRowClicked(ev, entry)}>
            {entry['customer']['customerName']}
          </a>
        </td>
        <td className={"company-col"}>
          {renderCompanyName(entry['customer'])}
        </td>
        <td className={"genset-model-col"}>
          {renderGeneratorsSummary(entry['generators'])}
        </td>
        <td className={"genset-qty-col right-aligned"}>
          {renderTotalQuantity(entry['generators'])}
        </td>
        <td className={"genset-price-col right-aligned"}>
          <span className="currency-symbol">{getCurrency(entry['currency']).sign}</span>
          {renderTotalAmount(entry['generators'])}
        </td>
        <td className={"payment-term-col"}>
          {refs.sales.paymentTerm[entry['paymentTerm']]}
        </td>
        <td className={"date-col"}>
          <Moment date={entry['entryDatetime']} format={formatters.datetimeShort} />
        </td>
        <td className={"actor-col"}>
          <ActorNameDisplay employee={entry['createdBy']} />
        </td>
        <td className={"status-col"}>
          <ReviewStatusBadge status={entry['reviewStatus']} />
        </td>
      </tr>
    </>
  )
}
