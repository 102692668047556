import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Moment from "react-moment";

import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { salesServices } from "../../repos/apiServices";
import { getSourceDisplay } from "../../repos/utilities";
import { getRegionDisplay } from "../../repos/utilities";
import { formatSourceName } from "../../repos/utilities";

import { SourceDropdown } from "../shared/SourceRadios";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { AutoCompleteNestedResults } from "../shared/AutoCompleteResults";
import { WarningMessage } from "./FormMessages";
import { ActorNameDisplay } from "./ActorNameDisplay";
import { FormSideLabel } from "./FormSideLabel";
import { DuplicateContactsModal } from "./DuplicateContactsModal";

export const EntryContactDetails = ({
                                      contactName, onContactNameChanged,
                                      contactNo, onContactNoChanged,
                                      customerName, onCustomerNameChanged,
                                      cityId, townshipId, regionTerm, onRegionChanged, onRegionSuggestionCityClicked, onRegionSuggestionTownshipClicked,
                                      source, onSourceChanged, sourceDescription,
                                      regionSuggestions, setRegionSuggestions,
                                      businessType, onBusinessTypeChanged,
                                      isEditMode, isEditAllowed, isEditing, isSubmitting, hasErrors, formErrors,
                                      editingEntry, onEditClicked, onUpdateClicked, onCancelClicked,
                                      isReviewAllowed, contactUpdateRequest, isLoadingPermissions, entryPermissions,
                                      onApproveClicked, onRejectClicked,
                                      isInquiryHistoryEditMode, checkContactDuplicates,
                                      hasDuplicatedContacts, duplicateContacts, resetDuplicateContacts,
                                    }) => {

  //#region States
  const [businessTypes, setBusinessTypes] = useState([]);
  //#endregion

  //#region Effects
  useEffect(() => {
    refServices2.fetchBusinessTypes().then((response) => setBusinessTypes(response.data));
  }, []);
  //#endregion

  //#region Utilities
  const hasFormMessages = () => {
    return contactUpdateRequest != null || hasDuplicatedContacts;
  }

  const getRegionClassName = () => {
    let className = "form-control";
    if (cityId || townshipId) className += " has-autocompleted-value";
    return className;
  }

  const expandRegionSuggestionResults = () => {
    setTimeout(() => {
      let fieldElement = document.getElementById('region');
      let suggestionsElement = document.getElementById('regionSuggestionResults');
      if (suggestionsElement) suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
    }, 100);
  }

  const renderRegionDisplayIfDifferent = () => {
    let townshipId = contactUpdateRequest['township'] !== null ? contactUpdateRequest['township']['id'] : -1;
    let newTownshipId = contactUpdateRequest['townshipNew'] !== null ? contactUpdateRequest['townshipNew']['id'] : -1;
    if (townshipId !== newTownshipId) {
      return <FormSideLabel value={getRegionDisplay(contactUpdateRequest['cityNew'], contactUpdateRequest['townshipNew'])} />
    } else if (contactUpdateRequest['city']['id'] !== contactUpdateRequest['cityNew']['id']) {
      return <FormSideLabel value={getRegionDisplay(contactUpdateRequest['cityNew'], null)} />
    } else return <></>
  }
  //#region

  //#region Control handlers
  const onViewDuplicatedContactsClicked = (ev) => {
    if (!hasDuplicatedContacts) return;
    duplicateContactsModal.onOpenButtonClicked();
  }

  const onContactNoBlurred = (ev) => {
    const _contactNo = ev.target.value.trim();
    let _entryId = editingEntry !== null ? editingEntry['entryId'] : null;
    checkContactDuplicates({
      enteredContactNo: _contactNo,
      selectedCityId: cityId,
      inquiryEntryId: _entryId,
    })
  }

  let _prevRegionTerm = '';
  let _regionTerm = '';
  const onRegionFieldChanged = (ev) => {
    _prevRegionTerm = _regionTerm.trim();
    _regionTerm = ev.target.value;

    let isRegionChanged = onRegionChanged(ev, _regionTerm, _prevRegionTerm);
    if (!isRegionChanged) return;

    resetDuplicateContacts();

    refServices2.searchRegions(_regionTerm)
      .then((response) => {
        setRegionSuggestions(response.data);
        expandRegionSuggestionResults();
      })
      .catch((error) => {
        setRegionSuggestions([]);
      });
  }

  const onRegionFieldBlurred = (ev) => {
    // a small delay to make sure that the selected suggestion is to be assigned properly to gensetModel state
    setTimeout(() => {
      setRegionSuggestions([]);
    }, 200);
  }

  const onRegionCityClicked = (ev, city) => {
    onRegionSuggestionCityClicked(ev, city);
    setRegionSuggestions([]);
    let _entryId = editingEntry !== null ? editingEntry['entryId'] : null;
    checkContactDuplicates({
      enteredContactNo: contactNo,
      selectedCityId: city.id,
      inquiryEntryId: _entryId
    });
  }

  const onRegionTownshipClicked = (ev, township) => {
    onRegionSuggestionTownshipClicked(ev, township);
    setRegionSuggestions([]);
    let _entryId = editingEntry !== null ? editingEntry['entryId'] : null;
    checkContactDuplicates({
      enteredContactNo: contactNo,
      selectedCityId: township.cityId,
      inquiryEntryId: _entryId
    });
  }

  const onCancelButtonClicked = (ev) => {
    setRegionSuggestions([]);
    onCancelClicked();
  }
  //#endregion

  //#region Control handlers; Duplicate contacts modal
  const [isDuplicateContactsModalOpen, setIsDuplicateContactsModalOpen] = useState(false);

  const duplicateContactsModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsDuplicateContactsModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsDuplicateContactsModalOpen(false);
      }, 200);
    },
  }

  //#endregion


  //#region Render
  return (
    <div className={"form-section"}>
      <h2>Contact Details</h2>

      <div className={"entry-form inquiry-form"}>
        {hasFormMessages() &&
          <div className={"form-message"}>
            {contactUpdateRequest &&
              <WarningMessage>
                <b><ActorNameDisplay employee={contactUpdateRequest.requestedBy} /></b>&nbsp;requested to update the following details on&nbsp;
                <b><Moment date={contactUpdateRequest.requestedDate} format={"MMMM DD, YYYY"} /></b>.
                {isReviewAllowed && <>&nbsp;Click the <b>Approve</b> or <b>Reject</b> button below after review.</>}
              </WarningMessage>
            }

            {hasDuplicatedContacts &&
              <WarningMessage>
                <div>
                  There is one or more contact persons similar to the one below in other inquiry entries.
                </div>
                <div style={{ marginTop: '8px' }}>
                  <button type={"button"} className={"btn btn-outline-secondary"} onClick={onViewDuplicatedContactsClicked}>View...</button>
                </div>
              </WarningMessage>
            }
          </div>
        }

        {hasDuplicatedContacts &&
          <DuplicateContactsModal isOpen={isDuplicateContactsModalOpen} isLoading={false}
                                  onRequestClose={duplicateContactsModal.onCloseButtonClicked}
                                  duplicateContacts={duplicateContacts}
          />
        }

        <div className={"form-label"}>
          <label htmlFor={"contactName"}>
            Contact Name:<span className={"error-message"}>*</span>
          </label>
        </div>
        <div className={"form-input"}>
          {!isEditing && editingEntry &&
            <div className={"readonly-field"}>{editingEntry.contactFullName}</div>
          }
          {isEditing &&
            <>
              <input type={"text"} id={"contactName"} className={"form-control"} autoComplete={"off"} disabled={isSubmitting}
                     value={contactName} onChange={onContactNameChanged} maxLength={100} />
              <FieldErrorMessages visible={hasErrors} messages={formErrors.contactName} />
            </>
          }
        </div>
        {contactUpdateRequest && contactUpdateRequest.contactFullName !== contactUpdateRequest.contactFullNameNew &&
          <FormSideLabel value={contactUpdateRequest.contactFullNameNew} />
        }

        <div className={"form-label"}>
          <label htmlFor={"contactNo"}>
            Contact No:<span className="error-message">*</span>
          </label>
        </div>
        <div className={"form-input"}>
          {!isEditing && editingEntry &&
            <div className={"readonly-field"}>{editingEntry.contactNo}</div>
          }
          {isEditing &&
            <>
              <input type={"text"} id={"contactNo"} className={"form-control"} autoComplete={"off"} disabled={isSubmitting}
                     value={contactNo} onChange={onContactNoChanged} onBlur={onContactNoBlurred} maxLength={50} />
              <FieldErrorMessages visible={hasErrors} messages={formErrors.contactNo} />
            </>
          }
        </div>
        {contactUpdateRequest && contactUpdateRequest.contactNo !== contactUpdateRequest.contactNoNew &&
          <FormSideLabel value={contactUpdateRequest.contactNoNew} />
        }

        <div className={"form-label"}>
          <label htmlFor={"contactName"}>
            Customer Name:
          </label>
        </div>
        <div className={"form-input"}>
          {!isEditing && editingEntry &&
            <div className={"readonly-field"}>{editingEntry.customerName ?? "-"}</div>
          }
          {isEditing &&
            <>
              <input type={"text"} id={"contactName"} className={"form-control"} autoComplete={"off"} disabled={isSubmitting}
                     value={customerName} onChange={onCustomerNameChanged} maxLength={100} />
            </>
          }
        </div>
        {contactUpdateRequest && contactUpdateRequest.customerName !== contactUpdateRequest.customerNameNew &&
          <FormSideLabel value={contactUpdateRequest.customerNameNew} />
        }

        <div className={"form-label"}>
          <label htmlFor={"cityTownship"}>
            City/Township:<span className="error-message">*</span>
          </label>
        </div>
        <div className={"form-input"}>
          {!isEditing && editingEntry &&
            <div className={"readonly-field"}>{getRegionDisplay(editingEntry.city, editingEntry.township)}</div>
          }
          {isEditing &&
            <input type={"text"} id={"region"} className={getRegionClassName()} placeholder={"Type city or township name to search"}
                   disabled={isSubmitting} autoComplete={"off"}
                   value={regionTerm} onChange={onRegionFieldChanged} onBlur={onRegionFieldBlurred} />
          }
          {regionSuggestions && regionSuggestions.length > 0 &&
            <AutoCompleteNestedResults id={"regionSuggestionResults"} style={{ width: 400, maxHeight: 600 }}
                                       collection={regionSuggestions} itemDisplayField={"name"} onItemClicked={onRegionCityClicked}
                                       childCollectionField={"townships"} childItemDisplayField={"name"}
                                       onChildItemClicked={onRegionTownshipClicked} />
          }
          {isEditing && <FieldErrorMessages visible={hasErrors} messages={formErrors.region} />}
        </div>
        {contactUpdateRequest && renderRegionDisplayIfDifferent()}

        <div className={"form-label"}>
          <label>
            Source:
          </label>
        </div>
        <div className={"form-input"}>
          {!isEditing && editingEntry &&
            <div className={"readonly-field"}>
              {formatSourceName(editingEntry['source'])}
            </div>
          }
          {isEditing &&
            <SourceDropdown sourceId={source} onSourceChanged={onSourceChanged} isDisabled={isSubmitting} />
          }
          {isEditing && sourceDescription &&
            <div className={"form-field-message"}>
              {sourceDescription}
            </div>
          }
        </div>
        {contactUpdateRequest && contactUpdateRequest.source !== contactUpdateRequest['sourceNew'] &&
          <FormSideLabel value={formatSourceName(contactUpdateRequest['sourceNew'])} />
        }

        <div className={"form-label"}>
          <label htmlFor={"businessType"}>
            Business Type:
          </label>
        </div>
        <div className={"form-input"}>
          {!isEditing && editingEntry &&
            <div className={"readonly-field"}>{editingEntry.businessType.name}</div>
          }
          {isEditing &&
            <select id={"businessType"} name={"businessType"} className={"form-control form-select"} disabled={isSubmitting}
                    value={businessType} onChange={onBusinessTypeChanged}>
              {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
            </select>
          }
        </div>
        {contactUpdateRequest && contactUpdateRequest.businessType.id !== contactUpdateRequest.businessTypeNew.id &&
          <FormSideLabel value={contactUpdateRequest.businessTypeNew.name} />
        }
      </div>
    </div>
  )
  //#endregion

}
