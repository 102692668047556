import React from "react";

import { getSourceDisplay } from "../../repos/utilities";

export const SourceDropdown = ({ sourceId, onSourceChanged, isDisabled }) => {
  return (
    <select id={"source"} name={"source"} className={"form-control form-select"} disabled={isDisabled}
            value={sourceId} onChange={onSourceChanged}>
      <option key={1} value={1}>{getSourceDisplay(1)}</option>
      <option key={2} value={2}>{getSourceDisplay(2)}</option>
      <option key={3} value={3}>{getSourceDisplay(3)}</option>
    </select>
  )
}

export const SourceRadios = ({ sourceId, onSourceChanged, isDisabled }) => {
  return (
    <>
      <label className={"option-field"}>
        <input type={"radio"} id={"sourceSocial"} name={"source"} disabled={isDisabled}
               value={1} checked={sourceId === 1} onChange={onSourceChanged} />
        {getSourceDisplay(1)}
      </label>
      <label className={"option-field"}>
        <input type={"radio"} id={"sourceOwn"} name={"source"} disabled={isDisabled}
               value={2} checked={sourceId === 2} onChange={onSourceChanged} />
        {getSourceDisplay(2)}
      </label>
      <label className={"option-field"}>
        <input type={"radio"} id={"sourceMarketing"} name={"source"} disabled={isDisabled}
               value={3} checked={sourceId === 3} onChange={onSourceChanged} />
        {getSourceDisplay(3)}
      </label>
    </>
  )
}
