import React from "react";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { authService } from "../../repos/apiServices";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { InfoMessage } from "../sales/FormMessages";
import { WarningMessage } from "../sales/FormMessages";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";

import '../shared/ContentArea.css';
import '../shared/DataTable.css';
import './InvoiceEntryPage.css';


export const InvoiceEntryPage = () => {
  //#region States
  const [invoiceDate, setInvoiceDate] = useState(null);

  const [gensetType, setGensetType] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  //#endregion

  //#region Effects
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Utilities
  const getEntryDateClassName = () => {
    let className = "form-control";
    if (invoiceDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container sales-inquiries-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.invoicesList.display} anchorTo={routes.invoicesList.url} />
              <BreadcrumbItem text={routes.invoiceEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>{routes.invoiceEntry.display}</h1>
          </div>

          <form autoComplete={"off"}>
            <div className={"form-section"}>
              <div className={"entry-form invoice-entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"invoice-number"}>
                    Invoice No:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"invoice-date"}>
                    Invoice Date:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"currency"}>
                    Currency:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"sales-person"}>
                    Sales Person:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-input"}>
                  <ReadonlyField>
                    <i>(Save the invoice first to generate)</i>
                  </ReadonlyField>
                </div>

                <div className={"form-input"}>
                  <DatePicker id={"invoice-date"} className={getEntryDateClassName()} placeholderText={"Type a valid date or click to choose"} 
                              dateFormat={formatters.datetimePicker} todayButton={"Today"} required={true} showWeekNumbers
                              disabled={isSubmitting} autoComplete={"none"}
                              selected={null} onChange={null} />
                  <FieldErrorMessage visible={hasErrors} message={formErrors['entryDate']} />
                </div>

                <div className={"form-input"}>
                  <select id={"currency"} className={"form-control form-select"} disabled={isSubmitting}
                          value={null} onChange={null}>
                    {Object.entries(refs.currency).map(([key, item], index) =>
                      <option key={key} value={item.code}>{item.name} ({item.sign})</option>
                    )}
                  </select>
                </div>

                <div className={"form-input"}>
                  <ReadonlyField>
                    <i>(Sales person name)</i>
                  </ReadonlyField>
                </div>

              </div>
            </div>

            <div className={"form-section"}>
              <h2>Customer</h2>

              <div className={"entry-form invoice-entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"customer-name"}>
                    Customer Name:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"contact-name"}>
                    Contact Name:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"contact-no"}>
                    Contact No:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"billing-address"}>
                    Billing Address:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"shipping-address"}>
                    Delivery Address:<span className="error-message">*</span>
                  </label>
                </div>

                <div className={"form-input"}>
                  <input type={"text"} id={"customer-name"} className={"form-control"} 
                         autoComplete={"none"} disabled={isSubmitting} maxLength={50}
                         value={null} onChange={null} />
                  <FieldErrorMessage visible={hasErrors} messages={formErrors['customerName']} />
                </div>

                <div className={"form-input"}>
                  <input type={"text"} id={"contact-name"} className={"form-control"} 
                         autoComplete={"none"} disabled={isSubmitting} maxLength={50}
                         value={null} onChange={null} />
                </div>

                <div className={"form-input"}>
                  <input type={"text"} id={"contact-no"} className={"form-control"} 
                         autoComplete={"none"} disabled={isSubmitting} maxLength={50}
                         value={null} onChange={null} />
                </div>

                <div className={"form-input"}>
                  <textarea id={"billing-address"} className={"form-control"} autoComplete={"none"} 
                            disabled={isSubmitting} maxLength={200} rows={3}
                            value={null} onChange={null}>
                  </textarea>
                </div>

                <div className={"form-input"}>
                  <textarea id={"shipping-address"} className={"form-control"} autoComplete={"none"} 
                            disabled={isSubmitting} maxLength={200} rows={3}
                            value={null} onChange={null}>
                  </textarea>
                </div>

                <div className={"form-input"}>
                  <label htmlFor={"is-same-addresses"} className={"checkbox-field"}>
                    <input type={"checkbox"} id={"is-same-address"} name={"is-same-address"} 
                           className={"form-input"} onChange={null} />
                    Same as billing address
                  </label>
                </div>

              </div>
            </div>

            <div className={"form-section"}>
              <h2>Product Details</h2>

              <div className={"entry-form invoice-entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"genset-model"}>
                    Genset Model (or ATS):<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"genset-type"}>
                    Generator Type:<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"price"}>
                    Price:<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"quantity"}>
                    Quantity:<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"amount"}>
                    Amount:<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-input"}>
                  <input type={"text"} id={"genset-model"} className={"form-control"} 
                         autoComplete={"none"} disabled={isSubmitting} maxLength={50}
                         value={null} onChange={null} />
                </div>

                <div className={"form-input"}>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"generatorType"} name={"generatorType"} disabled={isSubmitting}
                           value={null} 
                           checked={null}
                           onChange={null} />
                    Open
                  </label>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"generatorType"} name={"generatorType"} disabled={isSubmitting}
                           value={null} 
                           checked={null}
                           onChange={null} />
                    Canopy
                  </label>
                </div>

                <div className={"form-input"}>
                  <input type={"text"} id={"price"} className={"form-control"} 
                         autoComplete={"none"} disabled={isSubmitting} maxLength={50}
                         value={null} onChange={null} />
                </div>

                <div className={"form-input"}>
                  <input type={"text"} id={"quantity"} className={"form-control"} 
                         autoComplete={"none"} disabled={isSubmitting} maxLength={50}
                         value={null} onChange={null} />
                </div>

                <div className={"form-input"}>
                  <ReadonlyField>
                    0.00
                  </ReadonlyField>
                </div>
              </div>

              <div className={"form-button-controls"}>
                <div className={"left-side"}>
                  <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                          onClick={null}>
                    {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                    {!isSubmitting && <i className={"fa-solid fa-plus"}></i>}
                    <span>Add</span>
                  </button>
                </div>
              </div>
            </div>

            <div className={"form-section table-section"}>
              <div className={"data-table"}>
                <table>
                  <thead>
                    <tr>
                      <th scope={"col"} className={"index-col-head"}>#</th>
                      <th scope="col">Generator Model (or ATS)</th>
                      <th scope="col" style={{ width: "15%" }}>Generator Type</th>
                      <th scope="col" className={"right-aligned"} style={{ width: "15%" }}>
                        Price
                      </th>
                      <th scope="col" className={"right-aligned"} style={{ width: "10%" }}>
                        Quantity
                      </th>
                      <th scope="col" className={"right-aligned"} style={{ width: "15%" }}>
                        Amount
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableEmptyRow colSpan={7} />

                    <tr className={"footer-row"} style={{ borderBottom: 'unset' }}>
                      <td colSpan={5} className={"right-aligned"}>
                        Total:
                      </td>
                      <td className={"right-aligned"}>
                        <span className={"currency-symbol"}>$</span>
                        0.00
                      </td>
                      <td colSpan={2}>
                      </td>
                    </tr>
                    <tr className={"footer-row"} style={{ borderTop: 'unset', borderBottom: 'unset' }}>
                      <td colSpan={5} className={"right-aligned"}>
                        Freight:
                      </td>
                      <td className={"right-aligned"}>
                        <span className={"currency-symbol"}>$</span>
                        0.00
                      </td>
                      <td colSpan={2}>
                      </td>
                    </tr>

                    <tr className={"footer-row"} style={{ borderTop: 'unset', borderBottom: 'unset' }}>
                      <td colSpan={5} className={"right-aligned"}>
                        5% Tax:
                      </td>
                      <td className={"right-aligned"}>
                        <span className={"currency-symbol"}>$</span>
                        0.00
                      </td>
                      <td colSpan={2}>
                      </td>
                    </tr>

                    <tr className={"footer-row"} style={{ borderTop: 'unset', borderBottom: 'unset' }}>
                      <td colSpan={5} className={"right-aligned"}>
                        Deposit:
                      </td>
                      <td className={"right-aligned"}>
                        <span className={"currency-symbol"}>$</span>
                        0.00
                      </td>
                      <td colSpan={2}>
                      </td>
                    </tr>

                    <tr className={"footer-row"}>
                      <td colSpan={5} className={"right-aligned"}>
                        Grand Total:
                      </td>
                      <td className={"right-aligned"}>
                        <span className={"currency-symbol"}>$</span>
                        0.00
                      </td>
                      <td colSpan={2}>
                      </td>
                    </tr>


                  </tbody>
                </table>
              </div>
            </div>

            <div className={"form-section"}>
              <h2>Terms and Conditions</h2>

              <div className={"entry-form invoice-entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"payment-term"}>
                    Payment Term:<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"delivery-date"}>
                    Delivery Date:<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"delivery-due-date"}>
                    Delivery Due Date:<span className={"error-message"}>*</span>
                  </label>
                </div>

                <div className={"form-input"}>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"paymentTermType"} name={"paymentTermType"} disabled={isSubmitting}
                           value={null} checked={null}
                           onChange={null} />
                    Cash
                  </label>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"paymentTermType"} name={"paymentTermType"} disabled={isSubmitting}
                           value={null} checked={null}
                           onChange={null} />
                    Credit
                  </label>
                </div>

                <div className={"form-input"}>
                  <DatePicker id={"invoice-date"} className={getEntryDateClassName()} placeholderText={"Type a valid date or click to choose"} 
                              dateFormat={formatters.datetimePicker} todayButton={"Today"} required={true} showWeekNumbers
                              disabled={isSubmitting} autoComplete={"none"}
                              selected={null} onChange={null} />
                </div>

                <div className={"form-input"}>
                  <DatePicker id={"invoice-date"} className={getEntryDateClassName()} placeholderText={"Type a valid date or click to choose"} 
                              dateFormat={formatters.datetimePicker} todayButton={"Today"} required={true} showWeekNumbers
                              disabled={isSubmitting} autoComplete={"none"}
                              selected={null} onChange={null} />
                </div>
              </div>
            </div>

            <div className={"form-section"}>
              <h2>Other Information</h2>

              <div className={"entry-form invoice-entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"remarks"}>
                    Memo/Remarks:
                  </label>
                </div>

                <div className={"form-input"}>
                  <textarea id={"remarks"} className={"form-control"} autoComplete={"none"} 
                            disabled={isSubmitting} maxLength={200} rows={3}
                            value={null} onChange={null}>
                  </textarea>
                </div>
              </div>
            </div>

            <div className={"form-section"}>
              <h2>Form Signatures</h2>

              <div className={"entry-form invoice-entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"status"}>
                    Status:
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"status"}>
                    Created By:
                  </label>
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"status"}>
                    Reviewed By:
                  </label>
                </div>

                <div className={"form-input"}>
                  <ReadonlyField>
                    (Draft)
                  </ReadonlyField>
                </div>

                <div className={"form-input"}>
                  <ReadonlyField>
                    (Created by name)
                  </ReadonlyField>
                </div>

                <div className={"form-input"}>
                  <ReadonlyField>
                    (Reviewed by name)
                  </ReadonlyField>
                </div>
              </div>
            </div>

            <div className={"form-button-controls"}>
              <div className={"left-side"}>
                <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                        onClick={null}>
                  {!isSubmitting && <i className="fa-solid fa-check"></i>}
                  Submit
                </button>

                <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                        onClick={null}>
                  {!isSubmitting && <i className="fa-solid fa-floppy-disk"></i>}
                  Save Draft
                </button>
              </div>
            </div>

          </form>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}