import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { JobStatusBadge } from "./JobStatusBadge";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { ServiceEntryGenerator } from "./ServiceEntryGenerator";
import { displayColumn } from "./ServiceEntryTestChecklist";
import { ServiceEntryTestChecklist } from "./ServiceEntryTestChecklist";
import { ServiceEntrySignature } from "./ServiceEntrySignature";
import { ChecklistRadio } from "./ChecklistRadio";
import { TableEmptyRow } from "../shared/DataTable";
import { NewContactLogModal } from "./NewContactLogModal";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";
import "./ServiceProfilePage.css";


const serviceHistoryTypes = refs.inventory.serviceHistoryType;
const serviceTypes = refs.inventory.serviceType;
const testMode = refs.inventory.testMode;
const jobStatuses = refs.inventory.jobStatus;

export function InspectionEntryPage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceGeneratorId, setServiceGeneratorId] = useState(null);
  const [serviceHistoryId, setServiceHistoryId] = useState(null);     // null for new entries

  const [isFormReadonly, setIsFormReadonly] = useState(true);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isLoadingEntry, setIsLoadingEntry] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [servicedDatetime, setServicedDatetime] = useState(new Date());
  const [runningTest, setRunningTest] = useState("");

  const [serviceGenerator, setServiceGenerator] = useState(null);
  const [stock, setStock] = useState(null);
  const [generatorSerial, setGeneratorSerial] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("Hi there!");

  const [jobStatus, setJobStatus] = useState(jobStatuses.draft);
  const [testedBy, setTestedBy] = useState({});
  const [checkedBy, setCheckedBy] = useState({});
  const [checkedDate, setCheckedDate] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region States; Generator Status fields
  const [runHours, setRunHours] = useState(null);
  const [oilPressure, setOilPressure] = useState(null);
  const [waterTemperature, setWaterTemperature] = useState(null);
  const [engineRPM, setEngineRPM] = useState(null);
  const [dcOutputVoltage, setDcOutputVoltage] = useState(null);

  const [checkAirCleanerFilter, setCheckAirCleanerFilter] = useState(null);
  const [checkOilFilter, setCheckOilFilter] = useState(null);
  const [checkFuelFilter, setCheckFuelFilter] = useState(null);
  const [checkWaterRadiatorCap, setCheckWaterRadiatorCap] = useState(null);
  const [checkWaterRadiatorHosesClamps, setCheckWaterRadiatorHosesClamps] = useState(null);
  const [checkFanBeltBlade, setCheckFanBeltBlade] = useState(null);
  const [checkTurboCharger, setCheckTurboCharger] = useState(null);
  const [checkLeakage, setCheckLeakage] = useState(null);
  const [checkFuelHosesClamps, setCheckFuelHosesClamps] = useState(null);
  //#endregion

  //#region States; Test Results and Checklist fields
  const [testChecklistId, setTestChecklistId] = useState(null);
  const [checkEngineCondition, setCheckEngineCondition] = useState(null);
  const [checkCoolantLevel, setCheckCoolantLevel] = useState(null);
  const [checkBatteriesCondition, setCheckBatteriesCondition] = useState(null);
  const [checkStarterMotor, setCheckStarterMotor] = useState(null);
  const [checkAlternator, setCheckAlternator] = useState(null);
  const [checkControlSystem, setCheckControlSystem] = useState(null);
  const [checkExhaustCondition, setCheckExhaustCondition] = useState(null);
  const [checkEngineNoise, setCheckEngineNoise] = useState(null);
  const [checkRadiator, setCheckRadiator] = useState(null);
  const [checkWiringConnection, setCheckWiringConnection] = useState(null);
  const [checkDCAlternator, setCheckDCAlternator] = useState(null);
  const [checkACAlternator, setCheckACAlternator] = useState(null);
  const [checkElectricControlUnit, setCheckElectricControlUnit] = useState(null);

  const [technicalComments, setTechnicalComments] = useState([]);
  const [remarks, setRemarks] = useState("");
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchUserPermissions();

    let state = location.state;
    if (!state) {
      navigate(routes.serviceProfiles.url);
    }
    //let _customerId = state['serviceProfileId'];
    let _serviceProfileId = state['serviceProfileId'];
    let _serviceGeneratorId = state['serviceGeneratorId'];
    let _serviceHistoryId = state['serviceHistoryId'];
    if (!_serviceProfileId || !_serviceGeneratorId || !_serviceHistoryId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      //setCustomerId(_customerId);
      setServiceProfileId(_serviceProfileId);
      setServiceGeneratorId(_serviceGeneratorId);
      setServiceHistoryId(_serviceHistoryId);
      fetchInspectionServiceEntry(_serviceProfileId, _serviceGeneratorId, _serviceHistoryId);
    }

    prepareEntrySignatures();
  }, []);

  const prepareEntrySignatures = () => {
    // TODO(yemon): Set to the current entry's creator
    let _currentEmployee = {
      ...auth.userInfo,
    }
    _currentEmployee['id'] = auth.getUserId()['eid'];
    setTestedBy(_currentEmployee);
  }

  const prepareEntryHeaderPayload = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    return {
      'employee_id': auth.getUserId()['eid'],
      'history_type': serviceHistoryTypes.serviceHistory,
      'service_type': serviceTypes.inspection,
      'service_profile_id': serviceProfileId,
      'service_generator_id': serviceGeneratorId,
      'service_history_id': serviceHistoryId,
    };
  }

  const fetchInspectionServiceEntry = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    let payload = prepareEntryHeaderPayload(serviceProfileId, serviceGeneratorId, serviceHistoryId);
    setIsAlertVisible(false);
    setIsLoadingEntry(true);
    inventoryServices.fetchServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _serviceHistory = response['data'];
        let _serviceGenerator = _serviceHistory['serviceGenerator'];
        let _serviceLocation = _serviceGenerator['serviceLocation'];
        prepareServiceHistoryDetails(_serviceHistory);
        prepareServiceProfileDetails(_serviceLocation);
        prepareServiceGeneratorDetails(_serviceGenerator);
        prepareInspectionEntryDetails(_serviceHistory['generatorTests'], _serviceHistory['testChecklist']);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => setIsLoadingEntry(false));
  }

  const prepareServiceHistoryDetails = (serviceHistory) => {
    setServicedDatetime(serviceHistory['servicedDatetime']);

    setTechnicalComments(serviceHistory['comments']);
    setRemarks(serviceHistory['remarks']);
    setJobStatus(serviceHistory['status']);

    setTestedBy(serviceHistory['servicedBy']);
    setCheckedBy(serviceHistory['reviewedBy']);
    setCheckedDate(serviceHistory['reviewedDatetime']);
  }

  const prepareServiceProfileDetails = (serviceLocation) => {
    let _serviceProfile = serviceLocation['profile'];
    let _customer = _serviceProfile['customer'];
    setCustomerId(_customer['id']);
    setCustomerName(_customer['customerName']);
    setContactFullName(serviceLocation['contactFullName']);
    setContactNo(serviceLocation['contactNo']);
  }

  const prepareServiceGeneratorDetails = (serviceGenerator) => {
    setServiceGenerator(serviceGenerator);
    let _stock = serviceGenerator['stock'];
    setStock(_stock);
    setGeneratorSerial(_stock['generatorSerial']);
  }

  const prepareInspectionEntryDetails = (generatorTests, testChecklist) => {
    if (generatorTests) {
      setRunHours(generatorTests['runHours']);
      setOilPressure(generatorTests['oilPressure']);
      setWaterTemperature(generatorTests['waterTemperature']);
      setEngineRPM(generatorTests['engineRPM']);
      setDcOutputVoltage(generatorTests['dcVoltage']);
      setRunningTest(generatorTests['testingMode']);

      setCheckAirCleanerFilter(generatorTests['checkAirCleanerFilter']);
      setCheckOilFilter(generatorTests['checkOilFilter']);
      setCheckFuelFilter(generatorTests['checkFuelFilter']);
      setCheckWaterRadiatorCap(generatorTests['checkWaterRadiatorCap']);
      setCheckWaterRadiatorHosesClamps(generatorTests['checkWaterRadiatorHosesClamps']);
      setCheckFanBeltBlade(generatorTests['checkFanBeltBlade']);
      setCheckTurboCharger(generatorTests['checkTurboCharger']);
      setCheckLeakage(generatorTests['checkLeakage']);
      setCheckFuelHosesClamps(generatorTests['checkFuelHosesClamps']);
    }

    if (testChecklist) {
      setCheckEngineCondition(testChecklist['checkEngineCondition']);
      setCheckCoolantLevel(testChecklist['checkCoolantLevel']);
      setCheckBatteriesCondition(testChecklist['checkBatteriesCondition']);
      setCheckStarterMotor(testChecklist['checkStarterMotor']);
      setCheckAlternator(testChecklist['checkAlternator']);
      setCheckControlSystem(testChecklist['checkControlSystem']);
      setCheckExhaustCondition(testChecklist['checkExhaustCondition']);
      setCheckEngineNoise(testChecklist['checkEngineNoise']);
      setCheckRadiator(testChecklist['checkRadiator']);
      setCheckWiringConnection(testChecklist['checkWiringConnection']);
      setCheckDCAlternator(testChecklist['checkDCAlternator']);
      setCheckACAlternator(testChecklist['checkACAlternator']);
      setCheckElectricControlUnit(testChecklist['checkElectricControlUnit']);
    }
  }
  //#endregion

  //#region Utilities
  const getEntryDateClassName = () => {
    let className = "form-control";
    if (servicedDatetime) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region States; User permissions
  const [allowCareContactLog, setAllowCareContactLog] = useState(false);

  const fetchUserPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _permissions = response['data'];
        setAllowCareContactLog(_permissions['service']['allowCareContactLog']);
        setAllowServiceHistoryReview(_permissions['service']['allowStockInspectionReviews']);
      });
  }

  const [allowServiceHistoryReview, setAllowServiceHistoryReview] = useState(false);
  const [isSubmittingReview, setIsSubmittingReview] = useState(false);

  const isFormReviewable = () => {
    return allowServiceHistoryReview && (jobStatus === jobStatuses.pendingReview || jobStatus === jobStatuses.active);
  }

  const isFormReviewed = () => {
    return allowServiceHistoryReview && (jobStatus === jobStatuses.active);
  }

  const onReviewClicked = (ev) => {
    let payload = {
      'employee_id': auth.getUserId()['eid'],
      'service_history_id': serviceHistoryId,
    }
    setIsSubmittingReview(true);
    inventoryServices.submitServiceHistoryReview(payload)
      .then((response) => {
        let _responseData = response['data'];
        setJobStatus(_responseData['status']);
        setCheckedBy(_responseData['reviewedBy']);
        setCheckedDate(_responseData['reviewedDatetime']);
      })
      .finally(() => {
        setIsSubmittingReview(false);
      });
  }
  //#endregion

  //#region Control handlers
  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }

  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorSerialClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
          'generatorId': serviceGeneratorId,
        }
      })
    }, 200);
  }

  const onReturnClicked = (ev) => {
  }

  const onRefreshClicked = (ev) => {
  }

  const onSubmitClicked = (ev, isDraft) => {
  }
  //#endregion

  //#region New Contact Log modal;
  const [isNewContactModalOpened, setIsNewContactModalOpened] = useState(false);

  const onNewContactLogClicked = (ev) => {
    newContactModal.onOpenButtonClicked();
  }

  const newContactModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(false);
      }, 200);
    },
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                  {generatorSerial}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.inspection.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Inspection Report Form</h1>
          </div>

          <div className={"form-section"}>
            <h2>Customer Details</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Address:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Region:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactFullName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-name"}>{serviceGenerator['serviceLocation']['name']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div>{serviceGenerator['serviceLocation']['address']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div>{getRegionDisplay(serviceGenerator['serviceLocation']['city'], serviceGenerator['serviceLocation']['township'])}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Serviced Date:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Running Test:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <Moment date={servicedDatetime} format={formatters.datetimeShort} />
                </ReadonlyField>
                {/*<DatePicker id={"entryDate"} className={getEntryDateClassName()}*/}
                {/*            placeholder={"Type a valid date for click to choose"}*/}
                {/*            dateFormat={formatters.datetimePicker} minDate={null}*/}
                {/*            required={true} todayButton={"Today"} showWeekNumbers*/}
                {/*            autoComplete={"off"} disabled={isLoadingEntry}*/}
                {/*            selected={entryDate} onChange={(ev) => setEntryDate(ev)} />*/}
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {refs.inventory.testMode[runningTest]}
                </ReadonlyField>
              </div>
            </div>
          </div>

          {stock &&
            <ServiceEntryGenerator generatorSerial={generatorSerial} onGeneratorSerialChanged={null}
                                   gensetModel={stock['generator']['gensetModel']} onGensetModelChanged={null}
                                   generator={stock['manufacturer']} onGeneratorChanged={null}
                                   alternatorSerial={stock['alternatorSerial']} onAlternatorSerialChanged={null}
                                   alternatorModel={stock['alternatorModel']} onAlternatorModelChanged={null}
                                   alternator={stock['alternator']} onAlternatorChanged={null}
                                   machineSerial={stock['machineSerial']} onMachineSerialChanged={null}
                                   machineModel={stock['machineModel']} onMachineModelChanged={null}
                                   machine={stock['machine']} onMachineChanged={null}
                                   controllerSerial={stock['controllerSerial']} onControllerSerialChanged={null}
                                   controllerModel={stock['controllerModel']} onControllerModelChanged={null}
                                   controller={stock['controller']} onControllerChanged={null}
                                   isLoading={isLoadingEntry} isEditable={false}
                                   isSubmitting={false} hasErrors={false} formErrors={null}
            />
          }

          <NewContactLogModal isOpen={isNewContactModalOpened}
                              onRequestClose={newContactModal.onCloseButtonClicked}
                              serviceProfileId={serviceProfileId} serviceGeneratorId={serviceGeneratorId}
                              followupServiceHistoryId={serviceHistoryId} followupServiceType={serviceTypes.inspection}
          />

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isLoadingEntry || isSubmitting}
                      onClick={onGeneratorSerialClicked}>
                <i className={"fa-solid fa-arrow-left"}></i>
                Return to Generator
              </button>

              {allowCareContactLog &&
                <button className={"btn btn-primary"} disabled={isLoadingEntry || isSubmitting}
                        onClick={onNewContactLogClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New Contact Log...
                </button>
              }
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Generator Status</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Running Hours:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Oil Pressure:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Water Temperature:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={runHours} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={oilPressure} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={waterTemperature} />
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Engine RPM:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  DC Output Voltage:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={engineRPM} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={dcOutputVoltage} />
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Test Checklist</h2>

            {/* TODO(yemon): Standardize that 'gridAutoFlow' property */}
            <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
              <div className={"form-label"}>
                <label>
                  Engine Condition:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Air Cleaner Filter:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Oil Filter:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Fuel Filter:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Water Radiator Cap:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Water Radiator Hoses & Clamps:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Fan Belt & Blade:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Turbo Charger:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Any Leakages:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Coolant Level:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Batteries Condition:
                </label>
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkEngineCondition} elementId={"checkEngineCondition"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkAirCleanerFilter} elementId={"checkAirCleanerFilter"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkOilFilter} elementId={"checkOilFilter"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkFuelFilter} elementId={"checkFuelFilter"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkWaterRadiatorCap} elementId={"checkWaterRadiatorCap"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkWaterRadiatorHosesClamps} elementId={"checkWaterRadiatorHosesClamps"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkFanBeltBlade} elementId={"checkFanBeltBlade"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkTurboCharger} elementId={"checkTurboCharger"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkLeakage} elementId={"checkLeakage"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkCoolantLevel} elementId={"checkCoolantLevel"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input"}>
                <ChecklistRadio value={checkBatteriesCondition} elementId={"checkBatteriesCondition"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-label-r"}>
                <label>
                  Starter Motor:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Alternator:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Control System:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Exhaust Condition:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Engine Noise:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Radiator:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Wire Connection:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  DC Alternator:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  AC Alternator:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Electric Control Unit:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Fuel Hoses & Clamps:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkStarterMotor} elementId={"checkStarterMotor"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkAlternator} elementId={"checkAlternator"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkControlSystem} elementId={"checkControlSystem"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkExhaustCondition} elementId={"checkExhaustCondition"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkEngineNoise} elementId={"checkEngineNoise"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkRadiator} elementId={"checkRadiator"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkWiringConnection} elementId={"checkWiringConnection"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkDCAlternator} elementId={"checkDCAlternator"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkACAlternator} elementId={"checkACAlternator"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkElectricControlUnit} elementId={"checkElectricControlUnit"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

              <div className={"form-input-r"}>
                <ChecklistRadio value={checkFuelHosesClamps} elementId={"checkFuelHosesClamps"}
                                onValueChanged={null}
                                isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
              </div>

            </div>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>Technical Comments</th>
                <th scope={"col"}>Estimate Time</th>
                <th scope={"col"}>Actual Time</th>
              </tr>
              </thead>
              <tbody>
              {technicalComments && technicalComments.length > 0 &&
                technicalComments.map((technicalComment, index) =>
                  <InspectionTechnicalCommentRow index={index}
                                                 key={technicalComment['id']}
                                                 technicalComment={technicalComment} />
                )
              }
              {technicalComments && technicalComments.length === 0 &&
                <TableEmptyRow colSpan={4} />
              }
              </tbody>
            </table>
          </div>

          <div className={"form-section"}>
            <h2>Other Information</h2>

            <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
              <div className={"form-label"}>
                <label htmlFor={"testedBy"}>
                  Remarks:
                </label>
              </div>

              <div className={"form-input"}>
                <div className={"readonly-field"}>
                  <ReadonlyField>
                    <NullBlankValue value={remarks} />
                  </ReadonlyField>
                </div>
              </div>
            </div>
          </div>

          <ServiceEntrySignature jobStatus={jobStatus} testedBy={testedBy}
                                 checkedBy={checkedBy} checkedDate={checkedDate} />

          <div className={"form-section-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary"} disabled={isLoadingEntry || isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, false)}>
                {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}
                Submit
              </button>

              <button type={"button"} className={"btn btn-secondary"} disabled={isLoadingEntry || isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, true)}>
                {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}
                Save Draft
              </button>

              {isFormReviewable() &&
                <button type={"button"} className={"btn btn-success"} disabled={isSubmitting || isSubmittingReview || isFormReviewed()}
                        onClick={onReviewClicked}>
                  {isSubmittingReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isSubmittingReview && <i className={"fa-solid fa-list-check"}></i>}
                  Mark as Reviewed
                </button>
              }
            </div>
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={onAlertDismissButtonClicked}>
            {alertMessage}
          </PageAlert>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const InspectionTechnicalCommentRow = ({ technicalComment, index }) => {
  return (
    <tr>
      <td className={"index-col"}>{index + 1}</td>
      <td>
        {technicalComment['comment']}
      </td>
      <td>
        {technicalComment['estimateTime']}
      </td>
      <td>
        {technicalComment['actualTime']}
      </td>
    </tr>
  )
}
