import ReactModal from 'react-modal';

import { useAuth } from "../auth/AuthProvider";
import { getRegionDisplay } from "../../repos/utilities";

import { ActorNameDisplay } from "./ActorNameDisplay";
import { TableEmptyRow } from "../shared/DataTable";

import "../shared/Modals.css";


export const InquiryUpdateRequestsModal = ({
                                             isOpen, isLoading,
                                             onRequestClose,
                                             inquiryUpdateRequests,
                                             onUpdateRequestRowClicked,
                                           }) => {

  return (
    <ReactModal isOpen={isOpen} id={"update-requests-modal"}
                className={"popup-modal"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onRequestClose}>
      <div className={"modal-heading"}>
        <h2>Inquiry Update Requests</h2>
      </div>
      {isLoading &&
        <div className={"modal-loading-indicator"}>
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      }
      {!isLoading &&
        <>
          <div className={"modal-floated-controls"}>
            <button type={"button"} className={"btn btn-light"} onClick={onRequestClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className={"modal-contents"}>
            <div>
              Submitted inquiry update requests that are pending for review.
            </div>
          </div>

          <div className={"modal-data-table"}>
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Contact No</th>
                <th scope="col">City/Township</th>
                <th scope="col">Contact Updates By</th>
                <th scope="col">Inquiry Updates By</th>
              </tr>
              </thead>
              <tbody>
              {inquiryUpdateRequests && inquiryUpdateRequests.length > 0 &&
                inquiryUpdateRequests.map((updateRequest, index) =>
                  <UpdateRequestRow updateRequest={updateRequest}
                                    index={index} key={updateRequest.entryId}
                                    onUpdateRequestRowClicked={onUpdateRequestRowClicked}
                  />
                )
              }
              {(!inquiryUpdateRequests || inquiryUpdateRequests.length === 0) &&
                <TableEmptyRow colSpan={6} />
              }
              </tbody>
            </table>
          </div>
        </>
      }
    </ReactModal>
  )

}


const UpdateRequestRow = ({
                            updateRequest, index,
                            onUpdateRequestRowClicked,
                          }) => {
  const auth = useAuth();

  const aggregateHistoryUpdateRequesters = (historyUpdates) => {
    let requesterNames = [];
    for (let update of historyUpdates) {
      let requester = update['requestedBy'];
      let name = auth.getUserId().eid === requester['id'] ? 'You' : requester['name'];
      if (!requesterNames.includes(name)) {
        requesterNames.push(name);
      }
    }
    return requesterNames.join(', ');
  }

  return (
    <tr key={index}>
      <td className={"index-col"}>{index + 1}</td>
      <td>
        <a href="#" role={"button"} className={"record-link"}
           onClick={(ev) => onUpdateRequestRowClicked(ev, updateRequest)}>
          {updateRequest.inquiry['contactFullName']}
        </a>
      </td>
      <td>{updateRequest.inquiry['contactNo']}</td>
      <td>{getRegionDisplay(updateRequest.inquiry['city'], updateRequest.inquiry['township'])}</td>
      <td>
        {updateRequest['contactUpdate'] !== null ?
          <>
            <i className="fa-solid fa-square-pen"></i>
            <ActorNameDisplay employee={updateRequest['contactUpdate']['requestedBy']} />
          </>
          : ""}
      </td>
      <td>
        {updateRequest['historyUpdates'] && updateRequest['historyUpdates'].length > 0 &&
          <>
            <i className="fa-solid fa-square-pen"></i>
            {aggregateHistoryUpdateRequesters(updateRequest['historyUpdates'])}
          </>
        }
      </td>
    </tr>
  )
}
