import React from "react";

import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { expandSuggestionsResult } from "../../repos/searchUtilities";
import { formatSourceName } from "../../repos/utilities";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { EmployeeBranchGroupsDropdown } from "../shared/EmployeesDropdown";

export const QuotationRequestSearchPanel = ({
                                              isSearchLoading, isListLoading,
                                              customerName, onCustomerNameChanged, contactName, onContactNameChanged,
                                              contactNo, onContactNoChanged, source, onSourceChanged, businessType, onBusinessTypeChanged,
                                              cityId, townshipId, regionTerm, onRegionTermChanged,
                                              onRegionSuggestionCityClicked, onRegionSuggestionTownshipClicked,
                                              dateFrom, onDateFromChanged, dateTo, onDateToChanged, requesterId, onRequesterChanged,
                                              currencyCode, onCurrencyChanged,
                                              reviewStatus, onReviewStatusChanged,
                                              onSearchClicked, onClearClicked, onExportExcelClicked, isExporting,
                                              hasErrors, errorMessage,
                                            }) => {
  //#region States
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [isLoadingReporters, setIsLoadingReporters] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);
  const [reviewStatuses, setReviewStatuses] = useState([]);

  const [regionSuggestions, setRegionSuggestions] = useState([]);
  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effect
  useEffect(() => {
    fetchBusinessTypes();
    fetchRequesters();
    loadReviewStatuses();
  }, []);

  const fetchBusinessTypes = () => {
    setIsLoadingBusinessTypes(true);
    refServices2.fetchBusinessTypes()
      .then((response) => setBusinessTypes(response.data))
      .finally(() => setIsLoadingBusinessTypes(false));
  }

  const fetchRequesters = () => {
    setIsLoadingReporters(true);
    refServices2.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _responseData = response['data'];
        setEmployeeBranchGroups(_responseData);
      })
      .finally(() => setIsLoadingReporters(false));
  }

  const loadReviewStatuses = () => {
    setReviewStatuses(refs.sales.reviewStatusOptions);
  }
  //#endregion

  //#region Utilities
  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (dateFrom && !isToDate) className += " has-autocompleted-value";
    else if (dateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }

  const getRegionClassName = () => {
    let className = "form-control";
    if (cityId || townshipId) className += " has-autocompleted-value";
    return className;
  }

  const isFormControlsDisabled = () => {
    return isSearchLoading || isListLoading || isExporting;
  }
  //#endregion

  //#region Style helpers
  //#endregion

  //#region Control handlers
  let _prevRegionTerm = '';
  let _regionTerm = '';
  const onRegionTermFieldChanged = (ev) => {
    _prevRegionTerm = _regionTerm.trim();
    _regionTerm = ev.target.value.trim();

    let isRegionChanged = onRegionTermChanged(ev, _regionTerm, _prevRegionTerm);
    if (!isRegionChanged) return;

    refServices2.searchRegions(_regionTerm)
      .then((response) => {
        setRegionSuggestions(response.data);
        expandSuggestionsResult('searchRegion', 'regionSuggestions');
      })
      .catch((error) => {
        setRegionSuggestions([]);
      });
  }

  const onRegionTermFieldBlurred = (ev) => {
    setTimeout(() => {
      setRegionSuggestions([]);
    }, 200);
  }

  const onRegionCityClicked = (ev, city) => {
    onRegionSuggestionCityClicked(ev, city);
    setRegionSuggestions([]);
  }

  const onRegionTownshipClicked = (ev, township) => {
    onRegionSuggestionTownshipClicked(ev, township);
    setRegionSuggestions([]);
  }
  //#endregion

  //#region Render
  return (
    <div className={"search-panel"}>
      <form onSubmit={onSearchClicked}>
        <div className={"search-fields"}>
          <input type={"text"} id={"searchCustomerName"} className={"form-control md-field"} autoComplete={"off"}
                 disabled={isFormControlsDisabled()} placeholder={"Customer name"}
                 value={customerName} onChange={onCustomerNameChanged} />
          <input type={"text"} id={"searchContactName"} className={"form-control md-field"} autoComplete={"off"}
                 disabled={isFormControlsDisabled()} placeholder={"Contact name"}
                 value={contactName} onChange={onContactNameChanged} />
          <input type={"text"} id={"searchContactNo"} className={"form-control sm-field"} autoComplete={"off"}
                 disabled={isFormControlsDisabled()} placeholder={"Contact no."}
                 value={contactNo} onChange={onContactNoChanged} />
          <div className={"search-fields hide-mobile"}>
            <select name={"searchSource"} className={"form-control form-select lg-field"}
                    disabled={isFormControlsDisabled()} value={source}
                    onChange={(ev) => onSourceChanged(parseInt(ev.target.value))}>
              <option value={0}>- Any sources -</option>
              <option value={1}>{formatSourceName(1)}</option>
              <option value={2}>{formatSourceName(2)}</option>
              <option value={3}>{formatSourceName(3)}</option>
            </select>
            <select name={"searchBusinessType"} className={"form-control form-select md-field"}
                    disabled={isFormControlsDisabled() || isLoadingBusinessTypes}
                    value={businessType} onChange={(ev) => onBusinessTypeChanged(parseInt(ev.target.value))}>
              <option value={0}>- Any business types -</option>
              {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
            </select>
          </div>
        </div>

        <div className={"search-fields show-mobile"}>
          <select name={"searchSource"} className={"form-control form-select lg-field"}
                  disabled={isFormControlsDisabled()} value={source}
                  onChange={(ev) => onSourceChanged(parseInt(ev.target.value))}>
            <option value={0}>- Any sources -</option>
            <option value={1}>{formatSourceName(1)}</option>
            <option value={2}>{formatSourceName(2)}</option>
            <option value={3}>{formatSourceName(3)}</option>
          </select>
          <select name={"searchBusinessType"} className={"form-control form-select md-field"}
                  disabled={isFormControlsDisabled() || isLoadingBusinessTypes}
                  value={businessType} onChange={(ev) => onBusinessTypeChanged(parseInt(ev.target.value))}>
            <option value={0}>- Any business types -</option>
            {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
          </select>
        </div>

        <div className={"search-fields"}>
          <div className={"date-field from-date-field"}>
            <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                        placeholderText={"Date from."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={dateFrom} onChange={onDateFromChanged} />
          </div>
          <div className={"date-field"}>
            <DatePicker id={"dateTo"} className={getDateClassName(true)}
                        placeholderText={"Date to."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={dateTo} onChange={onDateToChanged} />
          </div>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"searchReporters"} name={"searchReporters"}
                                        isDisabled={isFormControlsDisabled() || isLoadingReporters}
                                        value={requesterId} onChange={(ev) => onRequesterChanged(ev.target.value)} />
          <div className={"hide-mobile"}>
            <select name={"searchReviewStatus"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                    value={reviewStatus} onChange={(ev) => onReviewStatusChanged(parseInt(ev.target.value))}>
              <option value={-1}>- Any status -</option>
              {reviewStatuses && reviewStatuses.map(status => <option key={status['id']} value={status['id']}>{status['name']}</option>)}
            </select>
            <select name={"currency"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                    value={currencyCode} onChange={(ev) => onCurrencyChanged(parseInt(ev.target.value))}>
              <option value={0}>- Any currency -</option>
              {Object.entries(refs.currency).map(([key, item], index) =>
                <option key={key} value={item.code}>{item.name} ({item.sign})</option>
              )}
            </select>
          </div>
        </div>

        <div className={"search-fields show-mobile"}>
          <select name={"searchReviewStatus"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={reviewStatus} onChange={(ev) => onReviewStatusChanged(parseInt(ev.target.value))}>
            <option value={-1}>- Any status -</option>
            {reviewStatuses && reviewStatuses.map(status => <option key={status['id']} value={status['id']}>{status['name']}</option>)}
          </select>
          <select name={"currency"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={currencyCode} onChange={(ev) => onCurrencyChanged(parseInt(ev.target.value))}>
            <option value={0}>- Any currency -</option>
            {Object.entries(refs.currency).map(([key, item], index) =>
              <option key={key} value={item.code}>{item.name} ({item.sign})</option>
            )}
          </select>
        </div>

        <div className={"search-fields"}>
          <FieldErrorMessage visible={hasErrors} message={errorMessage} />
        </div>

        <div className={"search-controls"}>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
          <button type={"button"} className={"btn btn-secondary padded-left"}
                  disabled={isFormControlsDisabled()} onClick={onExportExcelClicked}>
            {isExporting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isExporting && <i className="fa-solid fa-file-excel"></i>}
            Export Excel
          </button>
        </div>
      </form>
    </div>
  )
  //#endregion
}
