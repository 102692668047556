import React from "react";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { role as roleConstants } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatSourceDescription } from "../../repos/utilities";
import { getFollowUpStageActionDisplay } from "../../repos/utilities";
import { getDisplayTermFromGeneratorDetail } from "../../repos/utilities"
import { calculatePoEstimationDate } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { EntryContactDetails } from "./EntryContactDetails";
import { EntryPOEstimation } from "./EntryPOEstimation";
import { EntryContactControls } from "./EntryContactControls";
import { EntryHistoryTable } from "./EntryHistoryTable";
import { EntryInquiryDetails } from "./EntryInquiryDetails";

import '../shared/ContentArea.css';
import '../shared/EntryForm.css';
import './SalesInquiryEntryPage.css';


export const SalesInquiryEntryPage = () => {

  //#region States
  const [hasErrors, setHasErrors] = useState(false);

  // Contact Details form fields
  const [contactName, setContactName] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [cityId, setCityId] = useState(null);
  const [townshipId, setTownshipId] = useState(null);
  const [regionSuggestions, setRegionSuggestions] = useState([]);
  const [regionTerm, setRegionTerm] = useState('');
  const [source, setSource] = useState(2);
  const [sourceDescription, setSourceDescription] = useState('');
  const [businessType, setBusinessType] = useState(1);
  const [formErrors, setFormErrors] = useState({});

  // PO Estimation fields
  const [poDuration, setPoDuration] = useState(0);
  const [poDate, setPoDate] = useState(null);
  const [editingPoEstimation, setEditingPoEstimation] = useState(null);
  const [poEstimationFormErrors, setPoEstimationFormErrors] = useState({});

  // Inquiry Details form fields
  const [reportDate, setReportDate] = useState(new Date());
  const [gensetOrAtsKeyword, setGensetOrAtsKeyword] = useState('');
  const [gensetModelId, setGensetModelId] = useState('');
  const [atsId, setAtsId] = useState('');
  const [generatorDetails, setGeneratorDetails] = useState([]);
  const [contactForm, setContactForm] = useState(2);
  const [discussionDetails, setDiscussionDetails] = useState('');
  const [followUpStage, setFollowUpStage] = useState(0);
  const [followUpAction, setFollowUpAction] = useState('');
  const [followUpStageActionId, setFollowUpStageActionId] = useState('');
  const [followUpStageActionDisplay, setFollowUpStageActionDisplay] = useState('');
  const [hasReminder, setHasReminder] = useState(false);
  const [reminderDate, setReminderDate] = useState(null);
  const [followUpErrors, setFollowUpErrors] = useState({});

  // Lost sales
  const [isLostSales, setIsLostSales] = useState(false);
  const [lostReason, setLostReason] = useState('');
  const [competitorName, setCompetitorName] = useState('');
  const [competitorCurrency, setCompetitorCurrency] = useState(refs.currency.usd.code);
  const [competitorPrice, setCompetitorPrice] = useState(0);
  const [competitorBrand, setCompetitorBrand] = useState('');
  const [competitorDelivery, setCompetitorDelivery] = useState('');
  const [lostRemarks, setLostRemarks] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditButtonAllowed, setIsEditButtonAllowed] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingEntryId, setEditingEntryId] = useState('');
  const [isReviewButtonsAllowed, setIsReviewButtonsAllowed] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);
  const [contactUpdateRequest, setContactUpdateRequest] = useState(null);

  const [isLoadingHistories, setIsLoadingHistories] = useState(true);
  const [inquiryHistories, setInquiryHistories] = useState([]);
  const [isInquiryEditButtonAllowed, setIsInquiryEditButtonAllowed] = useState(true);
  const [isInquiryHistoryEditMode, setIsInquiryHistoryEditMode] = useState(false);
  const [isInquiryHistoryEditing, setIsInquiryHistoryEditing] = useState(false);
  const [isInquiryReviewButtonAllowed, setIsInquiryReviewButtonAllowed] = useState(false);
  const [editingInquiryHistory, setEditingInquiryHistory] = useState(null);
  const [inquiryUpdateRequest, setInquiryUpdateRequest] = useState(null);
  const [isInquiryReminderEditAllowed, setIsInquiryReminderEditAllowed] = useState(true);

  const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
  const [entryPermissions, setEntryPermissions] = useState(null);

  // Contact details duplicate check
  const [hasDuplicatedContacts, setHasDuplicatedContacts] = useState(false);
  const [duplicateContacts, setDuplicateContacts] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    resetContactFormFields();
    resetPoEstimationFields();
    resetInquiryFormFields();

    // Form edit mode
    if (location.state != null) {
      setIsEditMode(true);
      setIsEditing(false);

      let selectedEntry = location.state;
      setEditingEntry(selectedEntry);
      setEditingEntryId(selectedEntry['entryId']);
      prepareContactAndPoEstimationForEditMode(selectedEntry);

      fetchUserPermissionsForEdit(selectedEntry);
      fetchInquiryHistory(selectedEntry['entryId']);
    } else {
      setIsEditing(true);
    }
  }, []);
  //#endregion

  //#region Utilities
  const resetContactFormFields = () => {
    setSource(2);
    updateSourceDescription(2);
  }

  const resetPoEstimationFields = () => {
    setPoDuration(refs.sales.poDurations.unspecified);
    setPoDate(null);
  }

  const resetInquiryFormFields = () => {
    setReportDate(new Date());
    setGensetModelId("");
    setGensetOrAtsKeyword("");
    setGeneratorDetails([]);
    setContactForm(2);
    setDiscussionDetails("");

    let [stageId, actionId] = [1, '11'];
    updateFollowUpActionValues(stageId, actionId);

    setHasReminder(false);
    setReminderDate(null);

    setIsLostSales(false);
    setLostReason('');
    setCompetitorName('');
    setCompetitorCurrency(refs.currency.usd.code);
    setCompetitorPrice(0);
    setCompetitorBrand('');
    setCompetitorDelivery('');
    setLostRemarks('');
  }

  const updateFollowUpActionValues = (stageId, actionId) => {
    setFollowUpStageActionId(`${stageId}|${actionId}`);
    setFollowUpStage(stageId);
    setFollowUpAction(actionId);
    setFollowUpStageActionDisplay(getFollowUpStageActionDisplay(stageId, actionId));

    if (stageId === refs.sales.followUpStagesConstants.lost) {
      setIsLostSales(true);
    }
  }

  const prepareContactAndPoEstimationForEditMode = (selectedEntry) => {
    setIsLoadingPermissions(true);
    let _editingEntry = selectedEntry;
    let _updateRequest = _editingEntry['updateRequest'];

    let formDetails = {};
    formDetails['entryId'] = _editingEntry['entryId'];
    if (_updateRequest) {
      formDetails['contactFullName'] = _updateRequest['contactFullNameNew'];
      formDetails['contactNo'] = _updateRequest['contactNoNew'];
      formDetails['customerName'] = _updateRequest['customerNameNew'];
      formDetails['township'] = _updateRequest['townshipNew'];
      formDetails['city'] = _updateRequest['cityNew'];
      formDetails['source'] = _updateRequest['sourceNew'];
      formDetails['businessType'] = _updateRequest['businessTypeNew']['id'];

      formDetails['estimatePoDuration'] = _updateRequest['estimatePoDurationNew'];
      formDetails['estimatePoDate'] = _updateRequest['estimatePoDateNew'];

      setContactUpdateRequest(_updateRequest);
    } else {
      formDetails['contactFullName'] = _editingEntry['contactFullName'];
      formDetails['contactNo'] = _editingEntry['contactNo'];
      formDetails['customerName'] = _editingEntry['customerName'];
      formDetails['township'] = _editingEntry['township'];
      formDetails['city'] = _editingEntry['city'];
      formDetails['source'] = _editingEntry['source'];
      formDetails['businessType'] = _editingEntry['businessType']['id'];

      formDetails['estimatePoDuration'] = _editingEntry['estimatePoDuration'];
      formDetails['estimatePoDate'] = _editingEntry['estimatePoDate'];
    }

    setContactName(formDetails['contactFullName']);
    setContactNo(formDetails['contactNo']);
    setCustomerName(formDetails['customerName'] ?? '');
    setSource(formDetails['source']);
    updateSourceDescription(formDetails['source']);
    setBusinessType(formDetails['businessType']);

    setPoDuration(formDetails['estimatePoDuration']);
    setPoDate(formDetails['estimatePoDate'] ? new Date(formDetails['estimatePoDate']) : null);

    let _regionTerm = '';
    if (formDetails['township'] !== null) {
      _regionTerm = formatTownshipDisplay(formDetails['township']);
      setCityId(formDetails['township']['cityId']);
      setTownshipId(formDetails['township']['id']);
    } else {
      _regionTerm = `${formDetails['city']['name']}`;
      setCityId(formDetails['city']['id']);
      setTownshipId(null);
    }
    setRegionTerm(_regionTerm);

    checkContactDuplicates({
      enteredContactNo: _editingEntry['contactNo'],
      selectedCityId: _editingEntry['city']['id'],
      inquiryEntryId: _editingEntry['entryId'],
    });
  }

  const prepareInquiryForEditMode = (inquiryHistory) => {
    let formDetails = {};
    let updateRequest = inquiryHistory['updateRequest'];
    if (updateRequest) {
      formDetails['reportDate'] = updateRequest['followUpEntryDatetimeNew'];
      formDetails['generators'] = updateRequest['generatorsNew'].length > 0 ? updateRequest['generatorsNew'].map(generator => {
        return createGeneratorDetail(getDisplayTermFromGeneratorDetail(generator), generator['generatorId'], generator['atsId']);
      }) : [];
      formDetails['contactForm'] = updateRequest['contactFormNew'];
      formDetails['discussions'] = updateRequest['discussionsNew'] !== null ? updateRequest['discussionsNew'] : '';
      formDetails['followUpStage'] = updateRequest['followUpStageNew'];
      formDetails['followUpAction'] = updateRequest['followUpActionNew'];
      formDetails['hasReminder'] = updateRequest['hasReminderNew'];
      formDetails['reminderDatetime'] = updateRequest['reminderDatetimeNew'];

      formDetails['lostReason'] = updateRequest['lostReasonNew'];
      formDetails['competitorName'] = updateRequest['competitorNameNew'];
      formDetails['competitorCurrency'] = updateRequest['competitorCurrencyNew'] ? updateRequest['competitorCurrencyNew'] : refs.currency.usd.code;
      formDetails['competitorPrice'] = updateRequest['competitorPriceNew'];
      formDetails['competitorBrand'] = updateRequest['competitorBrandNew'];
      formDetails['competitorDelivery'] = updateRequest['competitorDeliveryNew'];
      formDetails['lostRemarks'] = updateRequest['lostRemarksNew'];
    } else {
      formDetails['reportDate'] = inquiryHistory['followUpEntryDatetime'];
      formDetails['generators'] = inquiryHistory['generators'].length > 0 ? inquiryHistory['generators'].map(generator => {
        return createGeneratorDetail(getDisplayTermFromGeneratorDetail(generator), generator['generatorId'], generator['atsId']);
      }) : [];
      formDetails['contactForm'] = inquiryHistory['contactForm'];
      formDetails['discussions'] = inquiryHistory['discussions'] !== null ? inquiryHistory['discussions'] : '';
      formDetails['followUpStage'] = inquiryHistory['followUpStage'];
      formDetails['followUpAction'] = inquiryHistory['followUpAction'];
      formDetails['hasReminder'] = inquiryHistory['hasReminder'];
      formDetails['reminderDatetime'] = inquiryHistory['reminderDatetime'];

      formDetails['lostReason'] = inquiryHistory['lostReason'];
      formDetails['competitorName'] = inquiryHistory['competitorName'];
      formDetails['competitorCurrency'] = inquiryHistory['competitorCurrency'] ? inquiryHistory['competitorCurrency'] : refs.currency.usd.code;
      formDetails['competitorPrice'] = inquiryHistory['competitorPrice'];
      formDetails['competitorBrand'] = inquiryHistory['competitorBrand'];
      formDetails['competitorDelivery'] = inquiryHistory['competitorDelivery'];
      formDetails['lostRemarks'] = inquiryHistory['lostRemarks'];
    }

    setReportDate(new Date(formDetails['reportDate']));
    setGensetModelId(formDetails['gensetModelId']);
    setGensetOrAtsKeyword(formDetails['gensetModel']);
    setGeneratorDetails(formDetails['generators']);
    setContactForm(formDetails['contactForm']);
    setDiscussionDetails(formDetails['discussions']);
    setFollowUpStage(formDetails['followUpStage']);
    setFollowUpAction(formDetails['followUpAction']);
    updateFollowUpActionValues(formDetails['followUpStage'], formDetails['followUpAction']);
    setHasReminder(formDetails['hasReminder']);
    setReminderDate(formDetails['reminderDatetime'] !== null ? new Date(formDetails['reminderDatetime']) : null);

    if (formDetails['followUpStage'] !== refs.sales.followUpStagesConstants.lost) {
      setIsLostSales(false);
      return;
    }
    setIsLostSales(true);
    setLostReason(formDetails['lostReason']);
    setCompetitorName(formDetails['competitorName']);
    setCompetitorCurrency(formDetails['competitorCurrency']);
    setCompetitorPrice(formDetails['competitorPrice']);
    setCompetitorBrand(formDetails['competitorBrand']);
    setCompetitorDelivery(formDetails['competitorDelivery']);
    setLostRemarks(formDetails['lostRemarks']);
  }

  const fetchUserPermissionsForEdit = (editingEntry) => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _entryPermissions = response.data['salesInquiries'];
        setEntryPermissions(_entryPermissions);
        let _detailsEditLevel = _entryPermissions['detailsEditLevel'];

        if (_detailsEditLevel === roleConstants.salesInquiry.detailsEditLevels.readOnly) {
          setIsReviewButtonsAllowed(false);
          setIsEditButtonAllowed(false);
        } else {
          if (editingEntry['updateRequest']) {
            if (_detailsEditLevel === roleConstants.salesInquiry.detailsEditLevels.reviewer) {
              setIsReviewButtonsAllowed(true);
              setIsEditButtonAllowed(false);
            } else if (_detailsEditLevel === roleConstants.salesInquiry.detailsEditLevels.requester) {
              setIsReviewButtonsAllowed(false);
              setIsEditButtonAllowed(true);
            }
          } else {
            setIsReviewButtonsAllowed(false);
            setIsEditButtonAllowed(true);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoadingPermissions(false);
      });
  }

  const fetchInquiryHistory = (selectedEntryId) => {
    setInquiryHistories([]);
    setIsLoadingHistories(true);

    salesServices.fetchInquiryEntryHistory(selectedEntryId)
      .then((response) => {
        let _inquiryHistory = response.data.data;
        for (let history of _inquiryHistory) {
          history['isEditing'] = false;
        }
        setInquiryHistories(_inquiryHistory);

        // auto-select the last followup action in the inquiry form
        if (_inquiryHistory.length > 0) {
          let lastInquiry = _inquiryHistory[0];
          updateFollowUpActionValues(lastInquiry['followUpStage'], lastInquiry['followUpAction']);
        }
      })
      .finally(() => {
        setIsLoadingHistories(false);
      });
  }

  const updateSourceDescription = (sourceId) => {
    setSourceDescription(formatSourceDescription(sourceId));
  }

  const checkContactDuplicates = ({
                                    enteredContactNo,
                                    selectedCityId,
                                    inquiryEntryId,
                                  }) => {
    setTimeout(() => {
      if (enteredContactNo.trim() === '') {
        return;
      } else if (selectedCityId === null) {
        return;
      }

      let payload = {
        'sales_person_id': auth.getUserId()['eid'],
        'contact_no': enteredContactNo.trim(),
        'city_id': selectedCityId,
      }
      if (inquiryEntryId !== null) {  // used during edit mode
        payload['editing_entry_id'] = inquiryEntryId;
      }
      salesServices.checkInquiryContactDuplicate(payload)
        .then((response) => {
          let result = response.data;
          setHasDuplicatedContacts(result['hasDuplicates']);
          setDuplicateContacts(result['duplicateContacts']);
        })
        .catch((error) => {
          console.log('ERROR: ', error);
          setHasDuplicatedContacts(false);
          setDuplicateContacts([]);
        });
    }, 200);
  }

  const resetDuplicateContacts = () => {
    setHasDuplicatedContacts(false);
    setDuplicateContacts([]);
  }
  //#endregion

  //#region Control handlers
  const onContactNameChanged = (ev) => {
    setContactName(ev.target.value);
    setFormErrors({ ...formErrors, contactName: [] });
  }

  const onContactNoChanged = (ev) => {
    setContactNo(ev.target.value);
    setFormErrors({ ...formErrors, contactNo: [] });
  }

  const onCustomerNameChanged = (ev) => {
    setCustomerName(ev.target.value);
  }

  const onRegionChanged = (ev, regionTerm, prevRegionTerm) => {
    setFormErrors({ ...formErrors, region: [] });
    if (regionTerm.trim() === '') {
      setRegionTerm('');
      setCityId(null);
      setTownshipId(null);
      return false;
    }
    if (regionTerm === prevRegionTerm) {
      return false;
    }
    setRegionTerm(regionTerm);
    setCityId(null);
    setTownshipId(null);
    return true;
  }

  const onRegionSuggestionCityClicked = (ev, city) => {
    setRegionTerm(city.name);
    setCityId(city.id);
    setTownshipId(null);
  }

  const onRegionSuggestionTownshipClicked = (ev, township) => {
    setRegionTerm(formatTownshipDisplay(township));
    setCityId(township.cityId);
    setTownshipId(township.id);
  }

  const formatTownshipDisplay = (township) => {
    if (!township) return '';
    return `${township['cityName']} / ${township['name']}`;
  }

  const onSourceChanged = (ev) => {
    let sourceId = ev.target.value;
    updateSourceDescription(sourceId);
    setSource(parseInt(sourceId));
  }

  const onBusinessTypeChanged = (ev) => {
    setBusinessType(parseInt(ev.target.value));
  }

  const onPoDurationChanged = (ev) => {
    let _duration = parseInt(ev.target.value);
    if (_duration === refs.sales.poDurations.unspecified) {
      resetPoEstimationFields();
      return;
    }
    else if (_duration === refs.sales.poDurations.custom) {
      if (poDate !== null) {
        setPoDuration(_duration);
        return;
      }
      else {
        setPoDate(new Date());
        return;
      }
    }
    let _newPoDate = calculatePoEstimationDate(_duration);
    setPoDuration(_duration);
    setPoDate(_newPoDate);
  }

  const onPoDateChanged = (date) => {
    let _date = date < new Date() ? new Date() : date;
    setPoDate(_date);
  }

  const onReportDateChanged = (date) => {
    setReportDate(date);
    setFollowUpErrors({ ...followUpErrors, reportDate: [] });
  }

  const onGensetOrAtsKeywordChanged = (ev, keyword, prevKeyword) => {
    if (keyword.trim() === '') {
      setGensetOrAtsKeyword('');
      setGensetModelId('');
      setAtsId('');
      return false;
    }
    if (keyword === prevKeyword) {
      return false;
    }
    setGensetOrAtsKeyword(keyword);
    setGensetModelId('');
    setAtsId('');
    return true;
  }

  const onGeneratorSuggestionClicked = (ev, generator) => {
    setGensetOrAtsKeyword(generator['gensetModel']);
    setGensetModelId(generator['id']);
    setAtsId('');
  }

  const onAtsSuggestionClicked = (ev, ats) => {
    setGensetOrAtsKeyword(ats['name']);
    setGensetModelId('');
    setAtsId(ats['id']);
  }

  const onGensetOrAtsAddClicked = (ev) => {
    if (gensetModelId === '' && atsId === '') return;
    if (generatorDetails.find(item => item['term'] === gensetOrAtsKeyword) !== undefined) return;

    let _detailItem = createGeneratorDetail(gensetOrAtsKeyword, gensetModelId, atsId);
    setGeneratorDetails([...generatorDetails, _detailItem]);
    setGensetOrAtsKeyword('');
    setGensetModelId('');
    setAtsId('');
  }

  const createGeneratorDetail = (term, gensetModelId, atsId) => {
    return {
      term, gensetModelId, atsId
    }
  }

  const onGensetModelRemoveClicked = (ev, generator) => {
    let _generators = [...generatorDetails];
    let indexOfItem = -1;
    if (generator['gensetModelId']) {
      indexOfItem = _generators.findIndex(item => item['gensetModelId'] === generator['gensetModelId']);
    }
    else if (generator['atsId']) {
      indexOfItem = _generators.findIndex(item => item['atsId'] === generator['atsId']);
    }
    else indexOfItem = -1;
    if (indexOfItem === -1) return;
    _generators.splice(indexOfItem, 1);
    setGeneratorDetails(_generators);
  }

  const onContactFormChanged = (ev) => {
    setContactForm(parseInt(ev.target.value));
  }

  const onDiscussionDetailsChanged = (ev) => {
    setDiscussionDetails(ev.target.value);
  }

  const onFollowUpStageActionIdChanged = (stageId, actionId) => {
    setFollowUpStage(stageId);
    setFollowUpAction(actionId);
    setFollowUpStageActionId(`${stageId}|${actionId}`);
    setFollowUpStageActionDisplay(getFollowUpStageActionDisplay(stageId, actionId));

    setIsLostSales(stageId === refs.sales.followUpStagesConstants.lost);  // Lost
  }

  const onHasReminderChanged = (ev) => {
    let newHasReminder = !hasReminder;
    setHasReminder(newHasReminder);
    if (!newHasReminder) {
      setHasReminder(false);
      setReminderDate(null);
      setFollowUpErrors({ ...followUpErrors, reminderDate: [] });
    }
  }

  const onReminderDateChanged = (date) => {
    setReminderDate(date);
    setFollowUpErrors({ ...followUpErrors, reminderDate: [] });
  }

  const onLostReasonChanged = (ev) => {
    setLostReason(ev.target.value);
  }

  const onCompetitorNameChanged = (ev) => {
    setCompetitorName(ev.target.value);
  }

  const onCompetitorCurrencyChanged = (ev) => {
    setCompetitorCurrency(parseInt(ev.target.value));
  }

  const onCompetitorPriceChanged = (ev) => {
    setCompetitorPrice(ev.target.value);
  }

  const onCompetitorPriceBlurred = (ev) => {
    // sanitize the price value
    let _priceStr = ev.target.value.trim();
    if (_priceStr === '' || isNaN(parseFloat(_priceStr))) {
      setCompetitorPrice(0);
    } else {
      let _price = parseFloat(_priceStr);
      if (_price < 0) {
        setCompetitorPrice(0);
      } else {
        setCompetitorPrice(_price);
      }
    }
  }

  const onCompetitorBrandChanged = (ev) => {
    setCompetitorBrand(ev.target.value);
  }

  const onCompetitorDeliveryChanged = (ev) => {
    setCompetitorDelivery(ev.target.value);
  }

  const onLostRemarksChanged = (ev) => {
    setLostRemarks(ev.target.value);
  }

  const isContactFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      contactName: [],
      contactNo: [],
      region: [],
    }
    if (!contactName || contactName.trim() === '') {
      _hasErrors = true;
      _formErrors.contactName = ["Contact name is required."];
    }
    if (!contactNo || contactNo.trim() === '') {
      _hasErrors = true;
      _formErrors.contactNo = ["Contact number is required."];
    }
    if (cityId === null && townshipId === null) {
      _hasErrors = true;
      _formErrors.region = ["Choose either a city or a township."];
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const hasContactFormFieldsChanges = () => {
    let hasFieldChanges = false;
    if (contactName.trim() !== editingEntry['contactFullName']) {
      hasFieldChanges = true;
    }
    if (contactNo.trim() !== editingEntry['contactNo']) {
      hasFieldChanges = true;
    }
    if (customerName && customerName.trim() !== editingEntry['customerName']) {
      hasFieldChanges = true;
    }
    if (cityId !== editingEntry['city']['id']) {
      hasFieldChanges = true;
    }
    if (townshipId !== null || editingEntry['township'] !== null) {
      let editingTownshipId = editingEntry['township'] !== null ? editingEntry['township']['id'] : -1;
      hasFieldChanges = townshipId !== editingTownshipId || hasFieldChanges;
    }
    if (source !== editingEntry['source']) {
      hasFieldChanges = true;
    }
    if (businessType !== editingEntry['businessType']['id']) {
      hasFieldChanges = true;
    }
    return hasFieldChanges;
  }

  const hasEstimatePoFormFieldChanges = () => {
    let hasFieldChanges = false;
    if (poDuration !== editingEntry['estimatePoDuration']) {
      hasFieldChanges = true;
    }
    let _poDate = poDate ? new Date(poDate) : null;
    let _editingPoDate = editingEntry['estimatePoDate'] ? new Date(editingEntry['estimatePoDate']) : null;
    if (_poDate !== _editingPoDate) {
      hasFieldChanges = true;
    }
    return hasFieldChanges;
  }

  const isInquiryFormFieldsValid = (validateReportDate = true, validateReminder = true) => {
    let _hasErrors = false;
    let _followUpErrors = {
      reportDate: [],
      reminderDate: []
    }

    if (!reportDate) {
      _hasErrors = true;
      _followUpErrors.reportDate.push("Report date is required.");
    }
    if (reportDate && validateReportDate && editingEntry != null) {
      let _lastEntryDatetime = new Date(editingEntry.lastEntryDatetime);
      if (reportDate < _lastEntryDatetime) {
        _hasErrors = true;
        _followUpErrors.reportDate.push("Report date cannot be earlier than the last entry date " +
          moment(editingEntry.lastEntryDatetime).format("MMM DD, yyyy"));
      }
    }
    if (validateReminder && hasReminder && !reminderDate) {
      _hasErrors = true;
      _followUpErrors.reminderDate = ["Reminder date is now required."];
    }

    if (isLostSales && lostReason.trim() === '') {
      _hasErrors = true;
      _followUpErrors.lostReason = ["Lost reason needs to be provided for lost sales."];
    }

    setFollowUpErrors(_followUpErrors);
    return !_hasErrors;
  }

  const hasInquiryFormFieldChanges = (includeReminderDifference = false) => {
    let hasFieldChanges = false;
    let editingFollowupEntryDatetime = new Date(editingInquiryHistory['followUpEntryDatetime']);
    if (reportDate.getTime() !== editingFollowupEntryDatetime.getTime()) {
      hasFieldChanges = true;
    }
    if (gensetModelId !== editingInquiryHistory['gensetModelId']) {
      hasFieldChanges = true;
    }
    if (contactForm !== editingInquiryHistory['contactForm']) {
      hasFieldChanges = true;
    }
    if (discussionDetails.trim() !== editingInquiryHistory['discussions']) {
      hasFieldChanges = true;
    }
    if (followUpStage !== editingInquiryHistory['followUpAction']) {
      hasFieldChanges = true;
    }
    if (includeReminderDifference) {
      if (hasReminder !== editingInquiryHistory['hasReminder']) {
        hasFieldChanges = true;
      }
      let _reminderDate = reminderDate !== null ? reminderDate.getTime() : -1;
      let _editingReminderDate = editingFollowupEntryDatetime['reminderDatetime'] !== null ? new Date(editingInquiryHistory['reminderDatetime']).getTime() : -1;
      if (_reminderDate !== _editingReminderDate) {
        hasFieldChanges = true;
      }
    }
    if (isLostSales) {
      if (lostReason.trim() !== editingInquiryHistory['lostReason']) {
        hasFieldChanges = true;
      }
      if (competitorName && competitorName.trim() !== editingInquiryHistory['competitorName']) {
        hasFieldChanges = true;
      }
      if (competitorCurrency !== editingInquiryHistory['competitorCurrency']) {
        hasFieldChanges = true;
      }
      if (competitorPrice && competitorPrice !== editingInquiryHistory['competitorPrice']) {
        hasFieldChanges = true;
      }
      if (competitorBrand && competitorBrand.trim() !== editingInquiryHistory['competitorBrand']) {
        hasFieldChanges = true;
      }
      if (competitorDelivery && competitorDelivery.trim() !== editingInquiryHistory['competitorDelivery']) {
        hasFieldChanges = true;
      }
      if (lostRemarks && lostRemarks.trim() !== editingInquiryHistory['lostRemarks']) {
        hasFieldChanges = true;
      }
    }
    return hasFieldChanges;
  }

  const prepareContactPayload = () => {
    let employeeId = auth.getUserId()['eid'];
    return {
      'sales_person_id': employeeId,
      'contact_fullname': contactName,
      'contact_no': contactNo,
      'customer_name': customerName,
      'city_id': cityId,
      'township_id': townshipId,
      'source': source,
      'business_type_id': businessType,
    };
  }

  const preparePoEstimationPayload = () => {
    return {
      'estimate_po_duration': poDuration,
      'estimate_po_date': poDate,
    }
  }

  const prepareInquiryPayload = () => {
    let employeeId = auth.getUserId()['eid'];
    let inquiryPayload = {
      'sales_person_id': employeeId,
      'entry_datetime': reportDate,
      'contact_form': contactForm,
      'generator_details': generatorDetails.length > 0 ? generatorDetails.map(item => {
        return {
          'genset_model_id': item['gensetModelId'] !== '' ? item['gensetModelId'] : null,
          'ats_id': item['atsId'] !== '' ? item['atsId'] : null,
        }
      }) : [],
      'discussion_details': discussionDetails,
      'followup_stage': followUpStage,
      'followup_action': followUpAction,
      'has_reminder': hasReminder,
    }
    inquiryPayload['reminder_datetime'] = reminderDate;

    if (isLostSales) {
      inquiryPayload['lost_reason'] = lostReason;
      inquiryPayload['competitor_name'] = competitorName ?? null;
      inquiryPayload['competitor_currency'] = competitorPrice !== 0 ? competitorCurrency : null;
      inquiryPayload['competitor_price'] = competitorPrice;
      inquiryPayload['competitor_brand'] = competitorBrand ?? null;
      inquiryPayload['competitor_delivery'] = competitorDelivery ?? null;
      inquiryPayload['lost_remarks'] = lostRemarks ?? null;
    }

    return inquiryPayload;
  }

  const onSubmitClicked = (ev) => {
    let contactFormFieldsValid = isContactFormFieldsValid();
    let inquiryFormFieldsValid = isInquiryFormFieldsValid();
    if (!contactFormFieldsValid || !inquiryFormFieldsValid) {
      setHasErrors(true);
      return;
    } else setHasErrors(false);

    let payload = {
      ...prepareContactPayload(),
      ...preparePoEstimationPayload(),
      ...prepareInquiryPayload(),
    };

    setIsSubmitting(true);
    salesServices.saveSalesInquiryEntry(payload)
      .then((response) => {
        resetContactFormFields();
        resetPoEstimationFields();
        navigate(routes.salesInquiriesList.url);
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      })
      .finally(() => setIsSubmitting(false));
  }

  const onContactEditClicked = (ev) => {
    setTimeout(() => {
      setIsEditing(true);
    }, 200);
  }

  const onContactUpdateSubmitted = (ev) => {
    if (!isContactFormFieldsValid()) {
      setHasErrors(true);
      return;
    } else setHasErrors(false);

    if (!hasContactFormFieldsChanges() && !hasEstimatePoFormFieldChanges()) {
      setIsEditing(false);
      return;
    }

    let payload = {
      ...prepareContactPayload(),
      ...preparePoEstimationPayload(),
    };
    payload['entry_id'] = editingEntryId;

    setIsSubmitting(true);
    salesServices.updateSalesInquiryContactDetails(payload)
      .then((response) => {
        let isUpdateRequest = response.data['isUpdateRequest'];
        let inquiryUpdate = response.data['inquiryUpdate'];
        let inquiry = response.data['inquiry'];
        if (isUpdateRequest) {
          setContactUpdateRequest(inquiryUpdate);
        } else {
          setEditingEntry(inquiry);
        }

        setIsEditing(false);
      })
      .catch((error) => {
        console.log('ERROR:', error);
      })
      .finally(() => setIsSubmitting(false));
  }

  const onContactCancelClicked = (ev) => {
    setRegionSuggestions([]);
    setTimeout(() => {
      setIsEditing(false);
    }, 200);
  }

  const onContactUpdateApproved = () => {
    if (!contactUpdateRequest) return;

    setIsSubmitting(true);
    let payload = {
      'updateId': contactUpdateRequest.updateId,
      'userId': auth.getUserId(),
    }
    salesServices.approveContactDetailsReview(payload)
      .then((response) => {
        let updatedEntry = response.data['updatedInquiry'];
        setEditingEntry(updatedEntry);
        prepareContactAndPoEstimationForEditMode(updatedEntry);

        setContactUpdateRequest(null);

        setIsEditButtonAllowed(true);
        setIsReviewButtonsAllowed(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onContactUpdateRejected = () => {
    if (!contactUpdateRequest) return;

    setIsSubmitting(true);
    let payload = {
      'updateId': contactUpdateRequest.updateId,
      'userId': auth.getUserId(),
    }
    salesServices.rejectContactDetailsReview(payload)
      .then((response) => {
        let inquiry = response.data['inquiry'];
        setEditingEntry(inquiry);
        setContactUpdateRequest(null);

        setIsEditButtonAllowed(true);
        setIsReviewButtonsAllowed(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onInquiryHistorySubmitClicked = (ev) => {
    let inquiryFormFieldsValid = isInquiryFormFieldsValid();
    if (!inquiryFormFieldsValid) {
      setHasErrors(true);
      return;
    } else setHasErrors(false);

    let payload = prepareInquiryPayload();
    payload['editing_entry_id'] = editingEntryId;
    payload['last_entry_id'] = editingEntry['lastEntryId'];
    setIsSubmitting(true);
    salesServices.saveSalesInquiryHistory(payload)
      .then((response) => {
        resetInquiryFormFields();
        setEditingEntry(response.data['editingEntry']);
        setIsSubmitting(false);
      })
      .catch(error => {
        console.log('ERROR:', error);
      })
      .then(() => fetchInquiryHistory(editingEntryId));
  }
  //#endregion

  //#region Control Handlers - Inquiry History update request
  const onInquiryHistoryRowSelected = (ev, inquiryHistory, rowIndex) => {
    ev.preventDefault();
    for (let history of inquiryHistories) {
      history.isEditing = false;
    }
    inquiryHistory.isEditing = true;
    setIsInquiryHistoryEditMode(true);
    setIsInquiryHistoryEditing(false);
    setEditingInquiryHistory(inquiryHistory);
    if (rowIndex === 0) setIsInquiryReminderEditAllowed(true);
    else setIsInquiryReminderEditAllowed(false);

    if (inquiryHistory['generators'].length > 0) {
      let _generators = inquiryHistory['generators'].map(generator => {
        return {
          term: generator['gensetModel'],
          id: generator['generatorId']
        }
      });
      setGeneratorDetails(_generators);
    }

    if (inquiryHistory['updateRequest']) {
      setInquiryUpdateRequest(inquiryHistory['updateRequest']);
    } else {
      setInquiryUpdateRequest(null);
    }
    setIsEditing(false);

    prepareInquiryForEditMode(inquiryHistory);
    verifyUserPermissionForInquiryEdit(inquiryHistory);
  }

  const verifyUserPermissionForInquiryEdit = (inquiryHistory) => {
    if (!entryPermissions) return;

    let _historyEditLevel = entryPermissions['historyEditLevel'];
    if (_historyEditLevel === roleConstants.salesInquiry.historyEditLevels.readOnly) {
      setIsInquiryReviewButtonAllowed(false);
      setIsInquiryEditButtonAllowed(false);
    } else {
      if (inquiryHistory['updateRequest']) {
        if (_historyEditLevel === roleConstants.salesInquiry.historyEditLevels.reviewer) {
          setIsInquiryReviewButtonAllowed(true);
          setIsInquiryEditButtonAllowed(false);
        } else if (_historyEditLevel === roleConstants.salesInquiry.historyEditLevels.requester) {
          setIsInquiryReviewButtonAllowed(false);
          setIsInquiryEditButtonAllowed(true);
        }
      } else {
        setIsInquiryReviewButtonAllowed(false);
        setIsInquiryEditButtonAllowed(true);
      }
    }
  }

  const onInquiryHistoryEditClicked = (ev) => {
    setTimeout(() => {
      setIsInquiryHistoryEditing(true);
    }, 200);
  }

  const onInquiryHistoryClearClicked = (ev) => {
    for (let history of inquiryHistories) {
      history.isEditing = false;
    }
    setTimeout(() => {
      setIsInquiryHistoryEditMode(false);
      setIsInquiryHistoryEditing(false);
      setEditingInquiryHistory(null);
      setInquiryUpdateRequest(null);
      setIsInquiryReminderEditAllowed(false);
      resetInquiryFormFields();
      if (inquiryHistories.length > 0) {
        let lastInquiry = inquiryHistories[0];
        updateFollowUpActionValues(lastInquiry['followUpStage'], lastInquiry['followUpAction']);
      }

      setIsInquiryReviewButtonAllowed(false);
      setIsInquiryEditButtonAllowed(false);
    }, 200);
  }

  const onInquiryHistoryUpdateSubmitClicked = (ev) => {
    if (!isInquiryFormFieldsValid(false, true)) {
      setHasErrors(true);
      return;
    } else setHasErrors(false);

    if (!hasInquiryFormFieldChanges(true)) {
      onInquiryHistoryUpdateCancelClicked(ev);
      return;
    }

    let payload = prepareInquiryPayload();
    payload['editing_entry_id'] = editingEntryId;
    payload['followup_entry_id'] = editingInquiryHistory['followUpEntryId'];
    payload['is_updating_last_entry'] = isInquiryReminderEditAllowed;

    setIsSubmitting(true);
    salesServices.updateSalesInquiryHistoryDetails(payload)
      .then((response) => {
        let isUpdateRequest = response.data['isUpdateRequest'];
        let inquiryHistoryUpdate = response.data['inquiryHistoryUpdate'];
        if (isUpdateRequest) {
          setEditingInquiryHistory(inquiryHistoryUpdate);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => fetchInquiryHistory(editingEntryId))
      .finally(() => {
        resetInquiryFormFields();
        if (inquiryHistories.length > 0) {
          let lastInquiry = inquiryHistories[0];
          updateFollowUpActionValues(lastInquiry['followUpStage'], lastInquiry['followUpAction']);
        }
        setIsSubmitting(false);
        onInquiryHistoryClearClicked(ev);
      });
  }

  const onInquiryHistoryUpdateCancelClicked = (ev) => {
    setIsInquiryHistoryEditing(false);
  }

  const onInquiryHistoryUpdateApproved = (ev) => {
    if (!inquiryUpdateRequest) return;

    setIsSubmitting(true);
    let payload = {
      'updateId': inquiryUpdateRequest.updateId,
      'userId': auth.getUserId(),
      'is_updating_last_entry': isInquiryReminderEditAllowed,
    }
    salesServices.approveInquiryHistoryReview(payload)
      .then((response) => {
        let inquiryHistory = response.data['inquiryHistory'];
        setEditingInquiryHistory(inquiryHistory);
        prepareInquiryForEditMode(inquiryHistory);

        setInquiryUpdateRequest(null);

        setIsInquiryEditButtonAllowed(true);
        setIsInquiryReviewButtonAllowed(false)
        setIsInquiryReminderEditAllowed(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => fetchInquiryHistory(editingEntryId))
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onInquiryHistoryUpdateRejected = (ev) => {
    if (!inquiryUpdateRequest) return;

    setIsSubmitting(true);
    let payload = {
      'updateId': inquiryUpdateRequest.updateId,
      'userId': auth.getUserId(),
    }
    salesServices.rejectInquiryHistoryReview(payload)
      .then((response) => {
        let inquiryHistory = response.data['inquiryHistory'];
        setEditingInquiryHistory(inquiryHistory);
        setInquiryUpdateRequest(null);

        setIsInquiryEditButtonAllowed(true);
        setIsInquiryReviewButtonAllowed(false);
        setIsInquiryReminderEditAllowed(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => fetchInquiryHistory(editingEntryId))
      .finally(() => {
        setIsSubmitting(false);
      });
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container sales-inquiries-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.salesInquiriesList.displayShort} anchorTo={routes.salesInquiriesList.url} />
              <BreadcrumbItem text={routes.salesInquiriesEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>Sales Inquiry Entry</h1>
          </div>

          <form>
            <EntryContactDetails contactName={contactName} onContactNameChanged={onContactNameChanged}
                                 contactNo={contactNo} onContactNoChanged={onContactNoChanged}
                                 customerName={customerName} onCustomerNameChanged={onCustomerNameChanged}
                                 cityId={cityId} townshipId={townshipId} regionTerm={regionTerm} onRegionChanged={onRegionChanged}
                                 onRegionSuggestionCityClicked={onRegionSuggestionCityClicked}
                                 onRegionSuggestionTownshipClicked={onRegionSuggestionTownshipClicked}
                                 source={source} onSourceChanged={onSourceChanged} sourceDescription={sourceDescription}
                                 regionSuggestions={regionSuggestions} setRegionSuggestions={setRegionSuggestions}
                                 businessType={businessType} onBusinessTypeChanged={onBusinessTypeChanged}
                                 isEditMode={isEditMode} isEditAllowed={isEditButtonAllowed}
                                 isEditing={isEditing} isSubmitting={isSubmitting} hasErrors={hasErrors} formErrors={formErrors}
                                 editingEntry={editingEntry} onEditClicked={onContactEditClicked}
                                 onUpdateClicked={onContactUpdateSubmitted} onCancelClicked={onContactCancelClicked}
                                 isReviewAllowed={isReviewButtonsAllowed} contactUpdateRequest={contactUpdateRequest}
                                 isLoadingPermissions={isLoadingPermissions}
                                 onApproveClicked={onContactUpdateApproved} onRejectClicked={onContactUpdateRejected}
                                 isInquiryHistoryEditMode={isInquiryHistoryEditMode}
                                 checkContactDuplicates={checkContactDuplicates}
                                 hasDuplicatedContacts={hasDuplicatedContacts} duplicateContacts={duplicateContacts}
                                 resetDuplicateContacts={resetDuplicateContacts}
            />

            <EntryPOEstimation poDuration={poDuration} onPoDurationChanged={onPoDurationChanged}
                               poDate={poDate} onPoDateChanged={onPoDateChanged}
                               isEditing={isEditing} editingEntry={editingEntry}
                               poEstimationUpdateRequest={contactUpdateRequest}
                               hasErrors={hasErrors} poEstimationErrors={poEstimationFormErrors}
                               isSubmitting={isSubmitting}
            />

            <EntryContactControls isEditMode={isEditMode} isEditing={isEditing}
                                  isEditAllowed={isEditButtonAllowed} isReviewAllowed={isReviewButtonsAllowed}
                                  isLoadingPermissions={isLoadingPermissions} isSubmitting={isSubmitting}
                                  isInquiryHistoryEditMode={isInquiryHistoryEditMode}
                                  contactUpdateRequest={contactUpdateRequest}
                                  onEditClicked={onContactEditClicked} onUpdateClicked={onContactUpdateSubmitted}
                                  onCancelClicked={onContactCancelClicked}
                                  onApproveClicked={onContactUpdateApproved} onRejectClicked={onContactUpdateRejected}
            />

            <EntryInquiryDetails reportDate={reportDate} gensetModelId={gensetModelId} atsId={atsId} gensetOrAtsKeyword={gensetOrAtsKeyword}
                                 generatorDetails={generatorDetails} contactForm={contactForm} discussionDetails={discussionDetails}
                                 followUpStageActionId={followUpStageActionId} followUpStageActionDisplay={followUpStageActionDisplay}
                                 hasReminder={hasReminder} reminderDate={reminderDate} isLostSales={isLostSales} lostReason={lostReason}
                                 competitorName={competitorName} competitorCurrency={competitorCurrency} competitorPrice={competitorPrice}
                                 competitorBrand={competitorBrand} competitorDelivery={competitorDelivery} lostRemarks={lostRemarks}
                                 onReportDateChanged={onReportDateChanged} onGensetOrAtsKeywordChanged={onGensetOrAtsKeywordChanged}
                                 onGeneratorSuggestionClicked={onGeneratorSuggestionClicked} onAtsSuggestionClicked={onAtsSuggestionClicked}
                                 onGensetModelAddClicked={onGensetOrAtsAddClicked} onGensetModelRemoveClicked={onGensetModelRemoveClicked}
                                 onContactFormChanged={onContactFormChanged} onDiscussionDetailsChanged={onDiscussionDetailsChanged}
                                 onFollowUpStageActionIdChanged={onFollowUpStageActionIdChanged}
                                 onHasReminderChanged={onHasReminderChanged} onReminderDateChanged={onReminderDateChanged}
                                 onLostReasonChanged={onLostReasonChanged} onCompetitorNameChanged={onCompetitorNameChanged}
                                 onCompetitorCurrencyChanged={onCompetitorCurrencyChanged} onCompetitorPriceChanged={onCompetitorPriceChanged}
                                 onCompetitorPriceBlurred={onCompetitorPriceBlurred} onCompetitorBrandChanged={onCompetitorBrandChanged}
                                 onCompetitorDeliveryChanged={onCompetitorDeliveryChanged} onLostRemarksChanged={onLostRemarksChanged}
                                 isContactEditMode={isEditMode} isContactEditing={isEditing} isSubmitting={isSubmitting}
                                 hasErrors={hasErrors} followUpErrors={followUpErrors} editingEntry={editingEntry}
                                 isEditAllowed={isInquiryEditButtonAllowed}
                                 isInquiryEditMode={isInquiryHistoryEditMode} isInquiryEditing={isInquiryHistoryEditing}
                                 isReviewAllowed={isInquiryReviewButtonAllowed}
                                 editingInquiryHistory={editingInquiryHistory} inquiryUpdateRequest={inquiryUpdateRequest}
                                 onSubmitClicked={onInquiryHistorySubmitClicked}
                                 onUpdateEditClicked={onInquiryHistoryEditClicked} onUpdateClearClicked={onInquiryHistoryClearClicked}
                                 onUpdateSubmitClicked={onInquiryHistoryUpdateSubmitClicked} onUpdateCancelClicked={onInquiryHistoryUpdateCancelClicked}
                                 onApproveClicked={onInquiryHistoryUpdateApproved} onRejectClicked={onInquiryHistoryUpdateRejected}
                                 isReminderEditAllowed={isInquiryReminderEditAllowed}
            />

            {!isEditMode &&
              <div className={"form-button-controls"}>
                <div className={"left-side"}>
                  <button type={"button"} className={"btn btn-primary"} onClick={onSubmitClicked} disabled={(isEditMode && isEditing) || isSubmitting}>
                    {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                    {!isSubmitting && <i className="fa-solid fa-check"></i>}
                    Submit
                  </button>
                </div>
              </div>
            }

            {(editingEntryId !== '') &&
              <div className={"form-section table-section"}>
                <EntryHistoryTable isLoading={isLoadingHistories}
                                   inquiryHistories={inquiryHistories}
                                   onInquiryHistoryRowSelected={onInquiryHistoryRowSelected}
                />
              </div>
            }

          </form>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion

}
