import { refs } from "./constants";

const warrantyHoursValidity = refs.inventory.serviceGeneratorWarrantyHoursValidity;

export const isUnderWarrantyFromStatus = (warrantyStatus) => {
  if (warrantyStatus.runHoursValidity === warrantyHoursValidity.unidentified) {
    return warrantyStatus.withinValidDate;
  }
  return warrantyStatus.withinValidDate && warrantyStatus.runHoursValidity === warrantyHoursValidity.under;
}

export const isUnderWarranty = (withinValidDate, runHoursValidity) => {
  if (runHoursValidity === warrantyHoursValidity.unidentified) {
    return withinValidDate;
  }
  return withinValidDate && runHoursValidity === warrantyHoursValidity.under;
}
