import React from "react";
import { useState } from "react";
import { portalServices } from "../../repos/apiServices";

import { useAuth } from "../auth/AuthProvider";
import { ModalContainer } from "../shared/ModalContainer";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ErrorMessage } from "../sales/FormMessages";

import "./ServiceProfilePage.css";


export const NewPortalUserModal = ({
                                     serviceProfileId,
                                     displayFullname, setDisplayFullname,
                                     loginUsername, setLoginUsername,
                                     resetPortalUserFields,
                                     isOpen, onRequestClose,
                                     onUserSubmitted,
                                   }) => {
  //#region States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasFormAlert, setHasFormAlert] = useState(false);
  const [formAlertMessage, setFormAlertMessage] = useState("You probably did something wrong.");
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const auth = useAuth();
  //#endregion

  //#region Control handlers
  const onRequestModalClose = () => {
    resetFormFields();
    onRequestClose();
  }

  const resetFormFields = () => {
    resetPortalUserFields();
    setHasErrors(false);
    setFormErrors({});
  }

  const containsSpace = (password) => {
    for (const char of password) {
      if (char === ' ') {
        return true;
      }
    }
    return false;
  }

  const isConformStandard = (password) => {
    // !@#$%^&*()-
    const _specialChars = [33, 64, 35, 36, 37, 94, 38, 42, 40, 41, 45];
    let containsSpecials = false;

    let containsAlpha = false;
    let containsNumbers = false;
    for (let idx = 0; idx < password.length; idx+=1) {
      const asciiCode = password.charCodeAt(idx);
      // A-Z, a-z
      if ((asciiCode >= 65 && asciiCode <= 90) || (asciiCode >= 97 && asciiCode <= 122)) {
        containsAlpha = true;
        continue;
      }
      // 0-9
      if (asciiCode >= 48 && asciiCode <= 57) {
        containsNumbers = true;
        continue;
      }
      // special characters
      if (_specialChars.indexOf(asciiCode) !== -1) {
        containsSpecials = true;
      }
    }
    if (containsSpecials) {
      return false;
    }
    return containsAlpha && containsNumbers;
  }

  const isFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      displayFullname: [],
      loginUsername: [],
    };
    if (!displayFullname || displayFullname.trim() === '') {
      _hasErrors = true;
      _formErrors.displayFullname = ["Display name is required."];
    }
    if (!loginUsername || loginUsername.trim() === '') {
      _hasErrors = true;
      _formErrors.loginUsername = ["Login user name is required."];
    }

    const _loginUsername = loginUsername.trim();
    if (containsSpace(_loginUsername)) {
      _hasErrors = true;
      _formErrors.loginUsername = ["Login user name must not contain spaces \" \"."];
    }
    if (!isConformStandard(_loginUsername)) {
      _hasErrors = true;
      _formErrors.loginUsername = ["Login user name should only comprise of alphabets, numbers and underscore (A-Z, a-z, 0-9, _)."];
    }

    setHasErrors(_hasErrors);
    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const preparePayload = () => {
    let employeeId = auth.getUserId()['eid'];
    return {
      'employee_id': employeeId,
      'service_profile_id': serviceProfileId,
      'display_fullname': displayFullname.trim(),
      'login_username': loginUsername.trim(),
    }
  }

  const onCreateClicked = (ev) => {
    let isValid = isFormFieldsValid();
    if (!isValid) {
      return;
    }

    let payload = preparePayload();
    setHasErrors(false);
    setIsSubmitting(true);
    portalServices.submitPortalUser(payload)
      .then((_) => {
        resetFormFields();
        onUserSubmitted();
        onRequestModalClose();
      })
      .catch((error) => {
        const errorResponse = error['response'];
        let _formErrors = {
          displayFullname: [],
          loginUsername: [],
        };

        if (errorResponse && errorResponse.status === 403) {
          // forbidden, due to authentication related stuffs
          return;   // TODO(yemon): Temp!
        }
        if (errorResponse && errorResponse.status === 400) {
          // bad request, due to validation errors
          const responseData = errorResponse['data'];
          _formErrors.displayFullname = responseData['errors']['displayFullname'] ?? [];
          _formErrors.loginUsername = responseData['errors']['loginUsername'] ?? [];
          setFormErrors(_formErrors);
          setHasErrors(true);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  }
  //#endregion

  //#region Render
  return (
    <ModalContainer elementId={"new-portal-user-modal"}
                    isOpen={isOpen} onRequestClose={onRequestModalClose} isLoading={false}
                    height={360} title={"New Portal User"}
                    shortDescription={"Create a new user to allow access to the Customer Portal..."}>
      <form>
        {hasFormAlert &&
          <div className={"modal-form"}>
            <div className={"form-message"}>
                <ErrorMessage>
                  {formAlertMessage}
                </ErrorMessage>
            </div>
          </div>
        }

        <div className={"modal-form"}>
          <div className={"form-label"}>
            <label htmlFor={"displayFullname"}>
              Display Full Name:<span className="error-message">*</span>
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"displayFullname"} name={"displayFullname"} className={"form-control"}
                   autoComplete={"off"} disabled={isSubmitting} maxLength={100} autoFocus={true}
                   value={displayFullname} onChange={(ev) => setDisplayFullname(ev.target.value)} />
            <FieldErrorMessages visible={hasErrors} messages={formErrors["displayFullname"]} />
          </div>

          <div className={"form-label"}>
            <label htmlFor={"loginUsername"}>
              Login Username:<span className="error-message">*</span>
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"loginUsername"} name={"loginUsername"} className={"form-control"}
                   autoComplete={"off"} disabled={isSubmitting} maxLength={30}
                   value={loginUsername} onChange={(ev) => setLoginUsername(ev.target.value)} />
            <FieldErrorMessages visible={hasErrors} messages={formErrors["loginUsername"]} />
          </div>
        </div>

        <div className={"modal-controls form-button-controls"}>
          <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                  onClick={onCreateClicked}>
            {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmitting && <i className={"fa-solid fa-user-check"}></i>}
            Create
          </button>
        </div>
      </form>

    </ModalContainer>
  )
  //#endregion
}
