import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { inventoryServices } from "../../repos/apiServices";
import { useInspectionForm } from "./InspectionFormContext";
import { inspectionFormMode } from "./InspectionFormContext";
import { authService } from "../../repos/apiServices";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { InspectionFormProvider } from "./InspectionFormContext";

import '../shared/ContentArea.css';


export const ArrivalInspectionEntryPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [isDraftEnabled, setIsDraftEnabled] = useState(false);
  const [stockId, setStockId] = useState(null);
  const [inspectionId, setInspectionId] = useState(null);

  const [generatorSerialDisplay, setGeneratorSerialDisplay] = useState('');
  const [stockEntry, setStockEntry] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (state) {
      // Edit entry mode
      fetchInspectionEntryForEditMode(state);
    } else {
      // New entry mode
      setIsLoading(false);
      setIsEditable(true);
      setIsDraftEnabled(true);
      setGeneratorSerialDisplay('New Stock');
    }
  }, []);

  const fetchInspectionEntryForEditMode = (state) => {
    let params = {
      'stock_id': state['stockId'],
      'inspection_id': state['inspectionId'],
    };
    setStockId(state['stockId']);
    setInspectionId(state['inspectionId']);
    inventoryServices.fetchArrivalInspectionEntry(params)
      .then((response) => {
        let stockEntry = response['data'];
        setGeneratorSerialDisplay(stockEntry['generatorSerial']);
        setStockEntry(stockEntry);

        setIsEditable(stockEntry['inspection']['status'] !== refs.inventory.jobStatus.active);
        setIsDraftEnabled(stockEntry['inspection']['status'] === refs.inventory.jobStatus.draft);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse && errorResponse.status === 404) {
          navigate(routes.stockDetails.url, {
            state: {
              ...params,
            }
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //#endregion

  //#region Control handlers
  const onGeneratorSerialClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.stockDetails.url, {
        state: {
          'stockId': stockId,
          'inspectionId': inspectionId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container sales-inquiries-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.stocks.displayShort} anchorTo={routes.stocks.url} />
              <BreadcrumbItem isActive={true}>
                {(stockId || inspectionId) ?
                  <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                    {generatorSerialDisplay}
                  </a>
                  :
                  <span>New Stock</span>
                }
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.arrivalInspection.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <InspectionFormProvider>
            {!isLoading &&
              <ArrivalInspectionFormControls isEditable={isEditable}
                                             isDraftEnabled={isDraftEnabled} setIsDraftEnabled={setIsDraftEnabled}
                                             stockId={stockId} setStockId={setStockId}
                                             inspectionId={inspectionId} setInspectionId={setInspectionId}
                                             setGeneratorSerialDisplay={setGeneratorSerialDisplay}
                                             editingStockEntry={stockEntry}
              />
            }
          </InspectionFormProvider>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const ArrivalInspectionFormControls = ({
                                         isEditable,
                                         isDraftEnabled, setIsDraftEnabled,
                                         stockId, setStockId,
                                         inspectionId, setInspectionId,
                                         setGeneratorSerialDisplay,
                                         editingStockEntry,
                                       }) => {
  //#region States
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isSubmittingReview, setIsSubmittingReview] = useState(false);

  const [editingStockId, setEditingStockId] = useState(null);
  const [editingInspectionId, setEditingInspectionId] = useState(null);

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [allowInspectionReviews, setAllowInspectionReviews] = useState(false);

  const auth = useAuth();
  const location = useLocation();
  const form = useInspectionForm();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchPermissions();

    form.setFormMode(inspectionFormMode.arrivalInspection);
    form.setIsLoading(true);

    if (stockId) {
      setEditingStockId(stockId)
    }
    if (inspectionId) {
      setEditingInspectionId(inspectionId);
    }
    if (editingStockEntry) {
      // Edit entry mode
      const _inspectionEntry = editingStockEntry['inspection'];

      form.prepareEntryForEditMode(editingStockEntry, _inspectionEntry);

      let _generator = editingStockEntry['generator'];
      _generator['generatorSerial'] = editingStockEntry['generatorSerial'];
      _generator['manufacturer'] = editingStockEntry['manufacturer'];
      _generator['alternatorSerial'] = editingStockEntry['alternatorSerial'];
      _generator['alternatorModel'] = editingStockEntry['alternatorModel'];
      _generator['alternator'] = editingStockEntry['alternator'];
      _generator['machineSerial'] = editingStockEntry['machineSerial'];
      _generator['machineModel'] = editingStockEntry['machineModel'];
      _generator['machine'] = editingStockEntry['machine'];
      _generator['controllerSerial'] = editingStockEntry['controllerSerial'];
      _generator['controllerModel'] = editingStockEntry['controllerModel'];
      _generator['controller'] = editingStockEntry['controller'];
      form.prepareGeneratorForEditMode(_generator);

      form.setTestedBy(_inspectionEntry['testedBy']);
      form.setCheckedBy(_inspectionEntry['checkedBy']);
      form.setCheckedDate(_inspectionEntry['checkedDate']);
    } else {
      // New entry mode
      form.setIsLoading(false);
      let _currentEmployee = {
        ...auth.userInfo,
      }
      _currentEmployee['id'] = auth.getUserId()['eid'];
      form.setTestedBy(_currentEmployee);
      form.setCheckedBy(null);
    }

    form.setIsEditable(isEditable);
    form.setIsEditableGenerator(isEditable);
  }, []);

  const fetchPermissions = () => {
    return authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response.data['service'];
        setAllowInspectionReviews(_servicePermissions['allowStockInspectionReviews']);
      });
  }
  //#endregion

  //#region Control handlers
  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }

  const onSubmitClicked = (ev, isDraft) => {
    let _hasErrors = form.isHeaderFieldsValid() || form.isGeneratorDetailsFieldValid();
    if (_hasErrors) {
      setAlertMessage("Please fix the indicated errors on the respective fields before submitting again.");
      setAlertType(alertTypes.error);
      setIsAlertVisible(true);
      return;
    }
    setIsAlertVisible(false);

    let payload = {
      ...form.prepareHeaderPayload(),
      ...form.prepareGeneratorPayload(),
      ...form.prepareTestDetailsPayload(),
      ...form.prepareTestResultsPayload(),
      ...form.prepareAdditionalInfoPayload(),
    }
    payload['employee_id'] = auth.getUserId()['eid'];
    payload['draft_state'] = isDraft;

    // Edit mode
    payload['stock_id'] = editingStockId ?? null;
    payload['inspection_id'] = editingInspectionId ?? null;

    form.setIsSubmitting(true);
    if (isDraft) {
      setIsSubmittingForReview(false);
      setIsSubmittingDraft(true);
    } else {
      setIsSubmittingForReview(true);
      setIsSubmittingDraft(false);
    }
    // noinspection DuplicatedCode
    inventoryServices.postArrivalInspection(payload)
      .then((response) => {
        let _stock = response['data']['stock'];
        let _inspection = _stock['inspection'];
        form.setJobStatus(_inspection['status']);
        updateStatusStates(_inspection['status']);
        setStockId(_stock['id']);
        setInspectionId(_inspection['id']);
        setGeneratorSerialDisplay(_stock['generatorSerial']);

        setAlertType(alertTypes.info);
        setAlertMessage(response.data['message']);
        setIsAlertVisible(true);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            setAlertType(alertTypes.error);
            setAlertMessage(errorResponse.data['message']);
            setIsAlertVisible(true);
          } else if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unhandled server error while trying to save the inspection details. Contact and notify the system administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        form.setIsSubmitting(false);
        setIsSubmittingForReview(false);
        setIsSubmittingDraft(false);
      });
  }

  const onReviewClicked = (ev) => {
    let payload = {
      'employee_id': auth.getUserId()['eid'],
      'inspection_id': inspectionId,
    }
    setIsSubmittingReview(true);
    inventoryServices.postArrivalInspectionReview(payload)
      .then((response) => {
        let _responseData = response['data'];
        updateStatusStates(_responseData['status']);
        form.setJobStatus(_responseData['status']);
        form.setCheckedBy(_responseData['checkedBy']);
        form.setCheckedDate(_responseData['checkedDate']);
      })
      .finally(() => {
        setIsSubmittingReview(false);
      });
  }
  //#endregion

  //#region Utilities
  const updateStatusStates = (status) => {
    if (status === refs.inventory.jobStatus.active) {
      form.setIsEditable(false);
      form.setIsEditableGenerator(false);
    }
    setIsDraftEnabled(status === refs.inventory.jobStatus.draft);
  }

  const isFormReviewable = () => {
    return allowInspectionReviews &&
      (form.jobStatus === refs.inventory.jobStatus.pendingReview || form.jobStatus === refs.inventory.jobStatus.active);
  }

  const isFormReviewed = () => {
    return allowInspectionReviews && form.jobStatus === refs.inventory.jobStatus.active;
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={"form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-primary"} disabled={!form.isEditable || form.isSubmitting}
                  onClick={(ev) => onSubmitClicked(ev, false)}>
            {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}
            Submit
          </button>

          <button type={"button"} className={"btn btn-secondary right-margin"} disabled={form.isSubmitting || !isDraftEnabled}
                  onClick={(ev) => onSubmitClicked(ev, true)}>
            {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}
            Save Draft
          </button>

          {isFormReviewable() &&
            <button type={"button"} className={"btn btn-success"} disabled={form.isSubmitting || isSubmittingReview || isFormReviewed()}
                    onClick={onReviewClicked}>
              {isSubmittingReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isSubmittingReview && <i className={"fa-solid fa-list-check"}></i>}
              Mark as Reviewed
            </button>
          }
        </div>
      </div>

      <PageAlert visible={isAlertVisible} type={alertType}
                 onDismissButtonClicked={onAlertDismissButtonClicked}>
        {alertMessage}
      </PageAlert>
    </>
  )
  //#endregion
}
