import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { refs } from "../../repos/constants";
import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";

import "./OptionsField.css";


const commissionTypes = [
  {
    id: refs.inventory.serviceGeneratorCommissionType.pti,
    name: refs.inventory.serviceGeneratorCommissionType[refs.inventory.serviceGeneratorCommissionType.pti],
  },
  {
    id: refs.inventory.serviceGeneratorCommissionType.ptiContract,
    name: refs.inventory.serviceGeneratorCommissionType[refs.inventory.serviceGeneratorCommissionType.ptiContract],
  },
  {
    id: refs.inventory.serviceGeneratorCommissionType.other,
    name: refs.inventory.serviceGeneratorCommissionType[refs.inventory.serviceGeneratorCommissionType.other],
  },
  {
    id: refs.inventory.serviceGeneratorCommissionType.otherContract,
    name: refs.inventory.serviceGeneratorCommissionType[refs.inventory.serviceGeneratorCommissionType.otherContract],
  },
]

export const CommissionTypeOptionsField = ({
                                           selectedOptions, setSelectedOptions,
                                           disabled,
                                         }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const onOpenClicked = () => {
    setIsModalOpened(true);
  }

  const onCloseClicked = () => {
    updateButtonLabel();
    setIsModalOpened(false);
  }

  useEffect(() => {
    updateButtonLabel();
  }, [selectedOptions]);

  const [buttonLabel, setButtonLabel] = useState("Select...");

  const updateButtonLabel = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setButtonLabel("Select...");
    }
    else if (selectedOptions.length === commissionTypes.length) {
      setButtonLabel("All selected");
    }
    else {
      let _label = "";
      let _selectedNames = [];
      let _selectedOptions = [];
      if (selectedOptions) {
        // NOTE(yemon): Check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#comparefn
        // for toSorted(compareFn) semantics.
        _selectedOptions = selectedOptions.toSorted((a, b) => a - b);
      }

      // NOTE(yemon): The button label will only take a max of 3 names into account, (2 name + remaining count)
      // so there is absolutely no need to go through the entire `selectedOptions` array.
      for (let i = 0; i < Math.max(3, _selectedOptions.length); i += 1) {
        const commissionType = commissionTypes.find((item) => item['id'] === _selectedOptions[i]);
        if (commissionType) {
          _selectedNames.push(commissionType['name'].trim());
        }
      }

      if (_selectedNames.length === 1) {
        _label = _selectedNames[0];
      }
      else if (_selectedNames.length <= 3) {
        _label = _selectedNames.join(' + ');
      }
      else {
        // NOTE(yemon): Shouldn't happen. Just there to serve as a catch-all solution, just in case.
        _label = `Selected ${_selectedOptions.length} options`;
      }
      setButtonLabel(_label);
    }
  }

  return (
    <div className={"options-field"}>
      <CommissionTypeOptionsModal isOpen={isModalOpened} onRequestClose={onCloseClicked}
                                  selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />

      <button type={"button"} className={"btn btn-secondary"} disabled={disabled}
              title={"Click to choose commission types to apply."}
              onClick={onOpenClicked}>
        {buttonLabel}
      </button>
    </div>
  )
}

const CommissionTypeOptionsModal = ({
                                    isOpen, onRequestClose,
                                    selectedOptions, setSelectedOptions,
                                  }) => {
  const getDescription = () => {
    let count = 0;
    if (selectedOptions) {
      count = selectedOptions.length;
    }
    return `${count} of ${commissionTypes.length} total selected`;
  }

  const isAllSelected = () => {
    let count = 0;
    if (selectedOptions) {
      count = selectedOptions.length;
    }
    return count === commissionTypes.length;
  }

  const onSelectAllChecked = (ev, checked) => {
    let _allOptions = [];
    if (checked) {
      for (let item of commissionTypes) {
        _allOptions.push(item['id']);
      }
      setSelectedOptions(_allOptions);
    }
    else {
      setSelectedOptions([]);
    }
  }

  const isSelected = (commissionTypeId) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return false;
    }
    return selectedOptions.some((id) => id === commissionTypeId);
  }

  const onSelectChecked = (ev, commissionTypeId, checked) => {
    const alreadySelected = (_id) => {
      return _id === commissionTypeId;
    }

    // NOTE(yemon): The many-funsies in the world of JavaScript.
    // Array.slice() "force clone" the entire array into a completely new array.
    let _selectedOptions = selectedOptions.slice();

    if (checked && !_selectedOptions.some(alreadySelected)) {
      _selectedOptions.push(commissionTypeId);
      setSelectedOptions(_selectedOptions);
    }

    if (!checked && _selectedOptions.some(alreadySelected)) {
      let existingIndex = _selectedOptions.findIndex(alreadySelected);
      if (existingIndex !== -1) {
        _selectedOptions.splice(existingIndex, 1);
        setSelectedOptions(_selectedOptions);
      }
    }
  }

  return (
    <ModalContainer elementId={"commission-type-options-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose}
                    modalSize={modalSizes.tiny}
                    title={"Select Commission Types"}
                    shortDescription={getDescription()}>
      <div className={"modal-data-table options-field-elements"}>
        <table>
          <thead>
          <tr className={"clickable-row"} onClick={(ev) => onSelectAllChecked(ev, !isAllSelected())}>
            <th scope="col" className={"index-col-head fixed-width"}>
              <label htmlFor={"selectAll"}>
                <input type={"checkbox"} id={"selectAll"} name={"selectAll"}
                       title={"Select all business types"}
                       checked={isAllSelected()}
                       onChange={(ev) => onSelectAllChecked(ev, ev.target.checked)} />
              </label>
            </th>
            <th scope="col">Name</th>
          </tr>
          </thead>
          <tbody>
          {commissionTypes.map((commissionType, index) =>
            <CommissionTypeTableRow key={commissionType['id']}
                                    commissionType={commissionType}
                                    isSelected={isSelected} onCheckChanged={onSelectChecked} />
          )}
          </tbody>
        </table>
      </div>

      <div className={"options-field-margin"}></div>
    </ModalContainer>
  )
}

const CommissionTypeTableRow = ({
                                 commissionType,
                                 isSelected, onCheckChanged,
                               }) => {
  return (
    <tr className={"clickable-row"}
        onClick={(ev) => onCheckChanged(ev, commissionType['id'], !isSelected(commissionType['id']))}>
      <td className={"index-col"}>
        <label htmlFor={`select-commission-type-${commissionType['id']}`}>
          <input type={"checkbox"} id={`select-commission-type-${commissionType['id']}}`}
                 name={`select-commission-type-${commissionType['id']}}`}
                 title={`Select ${commissionType['name']}`}
                 checked={isSelected(commissionType['id'])}
                 onChange={(ev) => onCheckChanged(ev, commissionType['id'], ev.target.checked)} />
        </label>
      </td>
      <td title={`Select ${commissionType['name']}`}>
        <label htmlFor={`select-commission-type-${commissionType['id']}`} className={"no-padding"}>
          {commissionType["name"]}
        </label>
      </td>
    </tr>
  )
}
