import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { getManufacturerDisplay } from "../../repos/utilities";
import { getAlternatorDisplay } from "../../repos/utilities";
import { getMachineDisplay } from "../../repos/utilities";
import { getControllerDisplay } from "../../repos/utilities";
import { getListRowSerial } from "../../repos/utilities";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { StockStatusBadge } from "./StockStatusBadge";
import { SortIndicator } from "../shared/SortIndicator";
import { StocksListSearchPanel } from "./StocksListSearchPanel";

import "../shared/ListingPage.css";
import "./StocksListPage.css";


const stockInspectionTypes = refs.inventory.stockInspectionType;
const generatorManufacturers = refs.inventory.generatorManufacturers;
const generatorAlternators = refs.inventory.generatorAlternators;
const generatorMachines = refs.inventory.generatorMachines;
const generatorControllers = refs.inventory.generatorControllers;

export function StocksListPage() {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [stocks, setStocks] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [servicePermissions, setServicePermissions] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.generatorStocks);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext !== null) {
      triggerSearchByContext(searchOptionsContext, paginationContext);
    }
    else {
      fetchStocksList(paginationContext ? paginationContext['currentPage'] : 1);
    }
    //resetSearchPanelFields();
    fetchListPermissions();
  }, []);

  const fetchListPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response.data['service'];
        // NOTE(yemon): Why did I do this exactly??
        _servicePermissions['allowPredeliveryInspection'] = true;
        setServicePermissions(_servicePermissions);
      });
  }

  const prepareListPayload = (page) => {
    let settings = getViewSettings(viewSettings.generatorStocks);
    return {
      'uid': auth.getUserId(),
      'sorting': null,
      'pagination': {
        'current_page': page,
        'page_size': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const fetchStocksList = (page) => {
    setIsLoading(true);
    let payload = prepareListPayload(page);

    inventoryServices.getStocksList(payload)
      .then((response) => {
        updateListingStates(response['data']);
      })
      .finally(() => setIsLoading(false));
  }

  const updateListingStates = (responseData) => {
    setStocks(responseData['data']);
    setPagination(responseData['pagination']);
    setSorting(responseData['sorting']);
    savePaginationContext(contexts.generatorStocks, responseData['pagination']);
  }

  const resetListStates = () => {
    setStocks([]);
    setPagination(null);
    setSorting(null);
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();
    setIsSearchOpen(false);
    saveSearchPanelOpenContext(contexts.generatorStocks, false);

    let paginationContext = getPaginationContext(contexts.generatorStocks);
    fetchListPermissions();
    fetchStocksList(paginationContext ? paginationContext['currentPage'] : 1);
  }

  const onNewArrivalInspectionClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.arrivalInspection.url);
    }, 200);
  }

  const onRowClicked = (ev, stock) => {
    setTimeout(() => {
      navigate(routes.stockDetails.url, {
        state: {
          'stockId': stock['stockId'],
        }
      });
    }, 200);
  }

  const onPageClick = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchStocksList(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchStocksList(page);
    }
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchStocksList(page);
    }
  }
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const [searchFields, setSearchFields] = useState({});
  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);
  const [gensetModelSearchTerm, setGensetModelSearchTerm] = useState('');

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Control handlers; Search panel
  const onSearchToggleClicked = () => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.generatorStocks, _isOpen);
  }

  const validateAndPrepareSearchOptions = () => {
    // NOTE(yemon): There isn't really anything to validate for now...
    return searchFields;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;

    let searchOptionsContext = {
      ...searchOptions,
      'genset_model_term': gensetModelSearchTerm,
    };
    saveSearchPanelOptionsContext(contexts.generatorStocks, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    };
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListStates();
    setIsSearching(true);
    setIsLoading(true);

    inventoryServices.getStocksList(listPayload)
      .then((response) => {
        setIsSearching(true);
        let responseData = response['data'];
        updateListingStates(responseData);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.log(errorResponse);
      })
      .finally(() => {
        setIsSearchLoading(false);
        setIsLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.generatorStocks);
    fetchStocksList(1);
    resetSearchPanelFields();
  }

  const resetSearchPanelFields = () => {
    setSearchFields({
      'generator_serial': '',
      'genset_model_id': '',
      'stock_status': -1,   // -1="- Any -"
    });
    setGensetModelSearchTerm('');
  }

  const onGeneratorSerialSearchChanged = (ev) => {
    setSearchFields({
      ...searchFields,
      'generator_serial': ev.target.value,
    });
  }

  const onGensetModelTermSearchChanged = (ev, gensetModelTerm, prevGensetModelTerm) => {
    if (gensetModelTerm.trim() === '') {
      setGensetModelSearchTerm('');
      setSearchFields({
        ...searchFields,
        'genset_model_id': '',
      });
      return false;
    }
    if (gensetModelTerm === prevGensetModelTerm) {
      return false;
    }
    setGensetModelSearchTerm(gensetModelTerm);
    setSearchFields({
      ...searchFields,
      'genset_model_id': '',
    });
    return true;
  }

  const onGensetModelSuggestionClicked = (ev, generator) => {
    setGensetModelSearchTerm(generator['gensetModel']);
    setSearchFields({
      ...searchFields,
      'genset_model_id': generator['id'],
    });
  }

  const onStockStatusSearchChanged = (status) => {
    setSearchFields({
      ...searchFields,
      'stock_status': status,
    });
  }
  //#endregion

  //#region View Settings modal; handlers and utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    }
    else {
      fetchStocksList(1);
    }
  }
  //#endregion

  //#region Listing view context
  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.generatorStocks);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.generatorStocks);
    if (searchOptions === null) {
      return null;
    }

    setSearchFields({
      'generator_serial': searchOptions['generator_serial'],
      'genset_model_id': searchOptions['genset_model_id'],
      'stock_status': searchOptions['stock_status'],
    });
    setGensetModelSearchTerm(searchOptions['genset_model_term']);

    return searchOptions;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container stocks-list-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.stocks.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>Generator Stocks</h1>
            <div className={"listing-controls"}>
              <button className={"btn btn-secondary"} disabled={isLoading || isSearchLoading}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              {servicePermissions && servicePermissions['allowArrivalInspection'] &&
                <button className={"btn btn-primary btn-md"} disabled={isLoading || isSearchLoading}
                        onClick={onNewArrivalInspectionClicked}>
                  <i className="fa-solid fa-dolly"></i>
                  New Arrival Inspection
                </button>
              }

              <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button>
            </div>
          </div>

          <StocksListSearchPanel isSearchOpen={isSearchOpen} isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                 searchFields={searchFields}
                                 onGeneratorSerialChanged={onGeneratorSerialSearchChanged}
                                 gensetModelTerm={gensetModelSearchTerm}
                                 onGensetModelTermChanged={onGensetModelTermSearchChanged}
                                 onGensetModelSuggestionClicked={onGensetModelSuggestionClicked}
                                 onStockStatusChanged={onStockStatusSearchChanged}
                                 onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                 hasErrors={hasSearchErrors} errorMessage={searchErrorMessage} />

          <div className="data-table">
            <table>
              <thead>
              <StocksTableHeading />
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={10} />}

              {stocks && stocks.length > 0 && !isLoading &&
                stocks.map((stock, index) =>
                  <StocksTableRow key={stock['stockId']} stock={stock} index={index}
                                  currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                  onRowClicked={onRowClicked}
                  />
                )
              }

              {!stocks || (stocks.length === 0 && !isLoading &&
                <TableEmptyRow colSpan={10} />
              )}
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.generatorStocks}
                               onViewSettingsSaved={onViewSettingsSaved}
               />
            }
          </div>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


export const StocksTableHeading = () => {
  return (
    <tr>
      <th scope="col" className="index-col-head">#</th>
      <th scope="col">Generator Serial</th>
      <th scope="col">Generator Model</th>
      <th scope="col">Manufacturer</th>
      <th scope="col">Alternator</th>
      <th scope="col">Machine</th>
      <th scope="col">Controller</th>
      <th scope="col">Stock Status</th>
      <th scope="col">Last Inspection</th>
      <th scope="col">
        Last Inspection On
        <SortIndicator isAscending={false} />
      </th>
    </tr>
  )
}

export const StocksTableRow = ({
                     stock, index,
                     currentPage, pageSize,
                     onRowClicked,
                   }) => {
  //#region Utilities
  const getInspectionStyle = (inspectionType) => {
    return inspectionType === stockInspectionTypes.imported ?
      { color: 'gray', fontStyle: 'italic' } : null;
  }

  const getAlternatorStyle = (alternator) => {
    return alternator === generatorAlternators.unspecified ?
      { color: 'gray', fontStyle: 'italic' } : null;
  }

  const getMachineStyle = (machine) => {
    return machine === generatorMachines.unspecified ?
      { color: 'gray', fontStyle: 'italic' } : null;
  }

  const getControllerStyle = (controller) => {
    return controller === generatorControllers.unspecified ?
      { color: 'gray', fontStyle: 'italic' } : null;
  }
  //#endregion

  //#region Render
  return (
    <tr>
      <td className={"index-col"}>{getListRowSerial(pageSize, currentPage, index)}</td>
      <td>
        <a href="#" role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, stock)}>
          {stock['generatorSerial']}
        </a>
      </td>
      <td>
        {stock['gensetModel']}
      </td>
      <td>
        {getManufacturerDisplay(stock['manufacturer'])}
      </td>
      <td>
        <span style={getAlternatorStyle(stock['alternator'])}>
          {getAlternatorDisplay(stock['alternator'])}
        </span>
      </td>
      <td>
        <span style={getMachineStyle(stock['machine'])}>
          {getMachineDisplay(stock['machine'])}
        </span>
      </td>
      <td>
        <span style={getControllerStyle(stock['controller'])}>
          {getControllerDisplay(stock['controller'])}
        </span>
      </td>
      <td>
        <StockStatusBadge status={stock['stockStatus']} />
      </td>
      <td>
        <span style={getInspectionStyle(stock['lastInspectionType'])}>
          {stockInspectionTypes[stock['lastInspectionType']]}
        </span>
      </td>
      <td>
        <span style={getInspectionStyle(stock['lastInspectionType'])}>
          {stock['lastInspectionType'] === stockInspectionTypes.imported &&
            <>(NA)</>
          }
          {stock['lastInspectionType'] !== stockInspectionTypes.imported &&
            <Moment date={stock['lastInspectionDatetime']} format={formatters.datetimeShort} />
          }
        </span>
      </td>
    </tr>
  )
  //#endreigon
}
