import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { ReadonlyField } from "../shared/ReadonlyField";
import { JobStatusBadge } from "./JobStatusBadge";
import { ActorNameRankDisplay } from "../sales/ActorNameDisplay";

export const ServiceEntryAdditionalInfo = ({
                                             remarks, onRemarksChanged,
                                             disconnectBatteryNegative, onDisconnectBatteryNegative,
                                             connectFuelSectionReturnPipe, onConnectFuelSectionReturnPipeChanged,
                                             jobStatus,
                                             testedBy,
                                             isLoading, isEditable, isSubmitting,
                                           }) => {
  //#region States
  const auth = useAuth();
  //#endregion

  //#region Effects
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <div className={"form-section"}>
      <h2>Additional Info</h2>

      <div className={"entry-form arrival-inspection-form additional-info-section"}>
        <div className={"form-label"}>
          <label htmlFor={"remarks"}>
            Remarks:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"disconnectBatteryNegativeCable"}>
            Disconnect Battery Negative Cable
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"connectFuelSectionAndReturnPipe"}>
            Connect Fuel Section & Return Pipe:
          </label>
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{remarks === '' ? '-' : remarks}</ReadonlyField>}
          {isEditable &&
            <textarea id={"remarks"} className={"form-control"}
                      rows={3} maxLength={500} disabled={isLoading || isSubmitting}
                      value={remarks} onChange={onRemarksChanged}>
            </textarea>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{disconnectBatteryNegative ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"disconnectBatteryNegativeCable"}>
              <input type={"checkbox"} id={"disconnectBatteryNegativeCable"} name={"disconnectBatteryNegativeCable"}
                     disabled={isLoading || isSubmitting}
                     checked={disconnectBatteryNegative} onChange={onDisconnectBatteryNegative} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{connectFuelSectionReturnPipe ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"connectFuelSectionAndReturnPipe"}>
              <input type={"checkbox"} id={"connectFuelSectionAndReturnPipe"} name={"connectFuelSectionAndReturnPipe"}
                     disabled={isLoading || isSubmitting}
                     checked={connectFuelSectionReturnPipe} onChange={onConnectFuelSectionReturnPipeChanged} />
            </label>
          }
        </div>

      </div>

    </div>
  )
  //#endregion
}
