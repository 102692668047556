import React from "react";
import ReactModal from 'react-modal';
import { useEffect } from "react";
import { useState } from "react";

import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { getViewSettings } from "../../repos/viewContexts";
import { saveViewSettings } from "../../repos/viewContexts";

import "../shared/Modals.css";

export const ListViewSettingsModal = ({
                                        isOpen, onRequestClose,
                                        settingsNamespace, onSettingsSaved,
                                      }) => {
  //#region States
  const [isSaving, setIsSaving] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_LIST_PAGE_SIZE.toString());
  //#endregion

  //#region Effects
  useEffect(() => {
    let settings = getViewSettings(settingsNamespace);
    if (settings) {
      setPageSize(settings['pageSize'].toString());
    } else {
      setPageSize(DEFAULT_LIST_PAGE_SIZE.toString());
    }
  }, []);
  //#endregion

  //#region Control handlers
  const onSaveClicked = (ev) => {
    setIsSaving(true);
    saveViewSettings(settingsNamespace, {
      'pageSize': parseInt(pageSize),
    });
    onSettingsSaved();
    setIsSaving(false);
  }
  //#endregion

  //#region Render
  return (
    <ReactModal isOpen={isOpen} id={"listing-view-settings-modal"}
                className={"popup-modal popup-modal-tiny"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onRequestClose}>
      <div className={"modal-heading"}>
        <h2>List View Settings</h2>
      </div>

      <div className={"modal-floated-controls"}>
        <button type={"button"} className={"btn btn-light"} onClick={onRequestClose}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div className={"modal-contents"}>
        <div className={"modal-warning-message"}>
          <i className="fa-solid fa-triangle-exclamation"></i>
          Larger page size may take a bit more time for the data list to load.
        </div>
      </div>

      <div className={"modal-form"}>
        <div className={"form-label"}>
          No. of rows per page:
        </div>
        <div className={"form-input"}>
          <select id={"pageSize"} name={"pageSize"} className={"form-control form-select"} disabled={isSaving}
                  value={pageSize} onChange={(ev) => setPageSize(ev.target.value)}>
            <option value={DEFAULT_LIST_PAGE_SIZE}>{DEFAULT_LIST_PAGE_SIZE} (default)</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>

      <div className={"modal-controls form-button-controls"} style={{ marginTop: '90px' }}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-secondary"} disabled={isSaving} onClick={onSaveClicked}>
            <i className="fa-solid fa-check"></i>
            Save
          </button>
        </div>
      </div>
    </ReactModal>
  )
  //#region
}
