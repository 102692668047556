import { useEffect } from "react";
import { useState } from "react";

import { refs } from "../../repos/constants";

import "../shared/AutoCompleteResults.css";


export const FollowUpStageNestedDropdown = ({
                                              id, name, disabled,
                                              value, onChange,
                                            }) => {
  const [followUpStagesRef, setFollowUpStagesRef] = useState([]);

  useEffect(() => {
    let _stages = refs.sales.followUpStages;
    setFollowUpStagesRef(_stages);
  }, []);

  return (
    <select id={id} name={name} className={"form-control form-select"} disabled={disabled}
            value={value} onChange={onChange}>
      {followUpStagesRef && followUpStagesRef.length > 0 && followUpStagesRef.map(stage =>
        <optgroup key={stage['id']} label={stage['name']}>
          {stage['actions'].length > 0 && stage['actions'].map(action =>
            <option key={action['id']} value={stage['id'] + '|' + action['id']}>{action['name']}</option>
          )}
        </optgroup>
      )}
    </select>
  )
}


export const FollowUpStageDropdown = ({
                                        id, name, disabled,
                                        value, onChange,
                                        refs,
                                      }) => {
  return (
    <select id={id} name={name} className={"form-control form-select"}
            disabled={disabled}
            value={value} onChange={onChange}>
      {refs && refs.map(stage =>
        <option key={stage['id']} value={stage["id"]}>{stage["name"]}</option>
      )}
    </select>
  )
}
