import React from "react";

import { useAuth } from "../auth/AuthProvider";

export const EmployeeBranchGroupsDropdown = ({
                                               branchGroups,
                                               id, name, isDisabled,
                                               value, onChange
                                             }) => {
  return (
    <select id={id} name={name} className={"form-control form-select md-field"}
            disabled={isDisabled}
            value={value} onChange={onChange}>
      <option value={''}>- Any employee -</option>
      {branchGroups && branchGroups.map(branch =>
        <optgroup label={branch['name']}>
          {branch['employees'] && branch['employees'].map(employee => <EmployeesDropdown employee={employee} />)}
        </optgroup>
      )}
    </select>
  )
}

const EmployeesDropdown = ({ employee }) => {
  const auth = useAuth();

  if (!employee) {
    return <></>
  } else {
    return <option key={employee['id']} value={employee['id']}>{employee['displayName']} {employee['id'] === auth.getUserId()['eid'] ? " (You)" : ""}</option>
  }
}
