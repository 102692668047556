import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { refServices } from "../../repos/apiServices2";
import { salesServices } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { EmployeesSelectorModal } from "../shared/EmployeesSelectorModal";
import { EmptySaleTargetsGrid } from "./SalesTargetsGrid";
import { LoadingSalesTargetIndicator } from "./SalesTargetsGrid";
import { SalesTargetsGrid } from "./SalesTargetsGrid";
import { SalesTargetsHeading } from "./SalesTargetsGrid";
import { SalesTargetsItems } from "./SalesTargetsGrid";

import "../shared/DataTable.css";
import '../shared/ListingPage.css';
import '../shared/ControlsGrid.css';
import "./SalesTargetsListPage.css";


export const SalesTargetsListPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [salesTeam, setSalesTeam] = useState([]);

  const [listPermissions, setListPermissions] = useState({});

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchSalesTeamWithTargets();
  }, []);

  const fetchSalesTeamWithTargets = (resetAlert = true) => {
    if (resetAlert) {
      setIsAlertVisible(false);
      setAlertMessage("");
    }

    setIsLoading(true);
    salesServices.salesTarget.fetchSalesTeamWithTargets()
      .then((response) => {
        const responseData = response['data'];
        setSalesTeam(responseData);

        const salesTeamEmployeeIDs = responseData.map((salesPerson) => {
          return salesPerson['employeeId'];
        });
        setSalesTeamOriginalOptions(salesTeamEmployeeIDs.slice());
        setSalesTeamOptions(salesTeamEmployeeIDs.slice());
      })
      .catch((error) => {
        const errorResponse = error['response'];
        if (errorResponse.status === 400 || errorResponse.status === 404) {
          setAlertType(alertTypes.warning);
          setAlertMessage("(TODO(yemon): handle non-500 error responses.");
          setIsAlertVisible(true);
        }
        if (errorResponse.status === 500) {
          setAlertType(alertTypes.error);
          setAlertMessage("Unknown server error occurred when retrieving the report. Please contact the administrator.");
          setIsAlertVisible(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchSalesTeamWithTargets();
  }

  const onNewClicked = (ev) => {
  }

  const onRowClicked = (ev, entry) => {
  }

  const isListDisabled = () => {
    return isLoading || isSubmittingSalesTeam || isSearchLoading;
  }

  const onNewTargetButtonClicked = (ev, employee) => {
    if (!employee) {
      return;
    }
    setTimeout(() => {
      navigate(routes.salesTargetEntry.url, {
        state: {
          'employee': employee,
        },
      });
    }, 200);
  }
  //#endregion

  //#region "Sales Team" (employees) field options picker
  const [isSalesTeamModalOpened, setIsSalesTeamModalOpened] = useState(false);

  const [salesTeamOriginalOptions, setSalesTeamOriginalOptions] = useState([]);
  const [salesTeamOptions, setSalesTeamOptions] = useState([]);
  const [isSubmittingSalesTeam, setIsSubmittingSalesTeam] = useState(false);

  const salesTeamModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsSalesTeamModalOpened(true);
      }, 200);
    },
    
    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsSalesTeamModalOpened(false);
        submitSalesTeamSelection();
      }, 200);
    },
  }

  const getSalesTeamButtonLabel = () => {
    if (!salesTeamOptions || salesTeamOptions.length === 0) {
      return "Specify Sales Team...";
    };
    return `${salesTeamOptions.length} member${salesTeamOptions.length > 1 ? "s" : ""} selected...`;
  }

  const getSalesTeamButtonTooltip = () => {
    if (!salesTeamOptions || salesTeamOptions.length === 0) {
      return "";
    }
    return "Specify Sales Team...";
  }

  const renderSalesTeamSelectorModal = () => {
    return (
      <EmployeesSelectorModal isOpen={isSalesTeamModalOpened} 
                              onRequestClose={salesTeamModal.onCloseButtonClicked}
                              isParentLoading={isLoading}
                              selectedOptions={salesTeamOptions} 
                              setSelectedOptions={setSalesTeamOptions} />
    )
  }

  const submitSalesTeamSelection = () => {
    if (!hasSalesTeamChanges()) {
      // TODO(yemon): Bug here! Not triggering as much as it should...
      console.log('does not have any changes to the sales team.');
      return;
    }

    console.log('has sales team changes, doing post back...');
    setIsSubmittingSalesTeam(true);
    const payload = {
      'uid': auth.getUserId()['eid'],
      'employees': salesTeamOptions,
    };
    salesServices.salesTarget.submitSalesTeam(payload)
      .then((response) => {
        const responseData = response['data'];
        setAlertType(alertTypes.info);
        setAlertMessage(responseData['message']);
        setIsAlertVisible(true);

        // TODO(yemon): receive the updated sales team list as response body
        // instead of calling the API again
        fetchSalesTeamWithTargets(false);
      })
      .catch((error) => {
        const errorResponse = error['response'];
        if (errorResponse.status == 304) {
          setAlertType(alertTypes.info);
          setAlertMessage("No changes to the sales team members.");
          setIsAlertVisible(true);
        }
        if (errorResponse.status === 400 || errorResponse.status === 404) {
          setAlertType(alertTypes.warning);
          setAlertMessage(errorResponse['error']);
          setIsAlertVisible(true);
        }
        if (errorResponse.status === 500) {
          setAlertType(alertTypes.error);
          setAlertMessage("Unknown server error occurred when retrieving the report. Please contact the administrator.");
          setIsAlertVisible(true);
        }
      })
      .finally(() => {
        setIsSubmittingSalesTeam(false);
      });
  }

  const hasSalesTeamChanges = () => {
    if (salesTeamOriginalOptions.length === 0 && salesTeamOptions.length === 0) {
      return false;
    }

    if (salesTeamOriginalOptions.length !== salesTeamOptions.length) {
      return true;
    }

    // NOTE(yemon): This could return a false-positive 'true' if either of the options arrays
    // contains the exact same elements but in a slightly different order.
    // But I'm not caring about that right now, since server-side handler will also do a similar
    // check before proceeding as well.
    const totalOptions = Math.max(salesTeamOriginalOptions.length, salesTeamOptions.length);
    for (let i = 0; i < totalOptions; i += 1) {
      if (salesTeamOriginalOptions[i] !== salesTeamOptions[i]) {
        return true;
      }
    }

    return false;
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Search Panel; Control handlers, states and other utilities
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // flag to indicate whether the last post back was a search trigger
  
  const onSearchToggleClicked = (ev) => {
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.salesTargetsList.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.salesTargetsList.display}</h1>

            <div className="listing-controls">
              <button className={"btn btn-secondary"} disabled={isListDisabled()}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              <button className={"btn btn-primary"} disabled={isListDisabled()}
                      title={getSalesTeamButtonTooltip()}
                      onClick={salesTeamModal.onOpenButtonClicked}>
                <i className="fa-solid fa-users-line"></i>
                {getSalesTeamButtonLabel()}
              </button>
              {/* <button className={"btn btn-primary"} disabled={isListDisabled()} 
                      onClick={onNewClicked}>
                <i className="fa-solid fa-plus"></i>
                New
              </button> */}

              {/* <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isListDisabled()}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button> */}
            </div>
          </div>

          {renderSalesTeamSelectorModal()}

          {isListDisabled() && <LoadingSalesTargetIndicator />}

          {!isListDisabled() && (!salesTeam || salesTeam.length === 0) && 
            <EmptySaleTargetsGrid />
          }

          {!isListDisabled() && salesTeam && salesTeam.length > 0 && 
            <SalesTargetsGrid>
              {salesTeam.map((salesPerson, index) => 
                <SalesTargetMemberItem salesPerson={salesPerson} 
                                       key={salesPerson['employeeId']} index={index} 
                                       onNewTargetClicked={onNewTargetButtonClicked} />
              )}
            </SalesTargetsGrid>
          }

          <PageAlert visible={isAlertVisible} type={alertType} 
                     onDismissButtonClicked={(ev) => setIsAlertVisible(false)}> 
            {alertMessage}
          </PageAlert>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const SalesTargetMemberItem = ({ 
                                salesPerson, 
                                onNewTargetClicked 
                              }) => {
  const getSalesPersonNameDisplay = (salesPerson) => {
    if (!salesPerson) {
      return "(NA)";
    }
    const fullName = salesPerson['fullName'];
    let branchName = salesPerson['branchName'] ? salesPerson['branchName'] : '-';
    return `${fullName} / ${branchName}`;
  }

  return (
    <>
      <SalesTargetsHeading title={getSalesPersonNameDisplay(salesPerson)} />
      <SalesTargetsItems salesPerson={salesPerson}
                         activeTargets={salesPerson['activeTargets']}
                         inactiveTargets={salesPerson['inactiveTargets']}
                         allowNew={true} onNewTargetClicked={onNewTargetClicked} />
    </>
  )
}
