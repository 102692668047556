import React from "react";
import { useState } from "react";

import { ChecklistRadio } from "./ChecklistRadio";

export const displayColumn = {
  left: 1,
  right: 1,
}

export const ServiceEntryTestChecklist = ({
                                            isFormReadonly, isFormDisabled,
                                            checkEngineCondition, setCheckEngineCondition,
                                            checkFuelLevel, setCheckFuelLevel,
                                            checkCoolantLevel, setCheckCoolantLevel,
                                            showCheckOilCleaner = true, checkOilCleaner, setCheckOilCleaner,
                                            checkEngineOilLevel, setCheckEngineOilLevel,
                                            checkBatteriesCondition, setCheckBatteriesCondition,
                                            checkDriveBeltTension, setCheckDriveBeltTension,
                                            checkControlPanelBox, setCheckControlPanelBox,
                                            checkSwitchNCircuitBreaker, setCheckSwitchNCircuitBreaker,
                                            showCheckWiringConnection = true, checkWiringConnection, setCheckWiringConnection,
                                            showCheckOilCoolantFuelLeaks = true, checkOilCoolantFuelLeaks, setCheckOilCoolantFuelLeaks,
                                            checkHosesPipesClamps, setCheckHosesPipesClamps,
                                            showCheckWiringCondition, checkWiringCondition, setCheckWiringCondition,
                                            exhaustConditionColumn = displayColumn.left, checkExhaustCondition, setCheckExhaustCondition,
                                            showCheckEarthWire, checkEarthWire, setCheckEarthWire,
                                            starterMotorColumn = displayColumn.left, checkStarterMotor, setCheckStarterMotor,
                                            showCheckEngineFoundation = true, checkEngineFoundation, setCheckEngineFoundation,
                                            showCheckATS = true, checkATS, setCheckATS,
                                            showCheckFuelOilPressure = true, checkFuelOilPressure, setCheckFuelOilPressure,
                                            showCheckWaterTemperature = true, checkWaterTemperature, setCheckWaterTemperature,
                                            showCheckBatteryChargingBolts = true, checkBatteryChargingVolts, setCheckBatteryChargingVolts,
                                            showCheckEngineRPM = true, checkEngineRPM, setCheckEngineRPM,
                                            showCheckFrequency = true, checkFrequency, setCheckFrequency,
                                            showCheckL1V = true, checkL1V, setCheckL1V,
                                            showCheckL2V = true, checkL2V, setCheckL2V,
                                            showCheckL3V = true, checkL3V, setCheckL3V,
                                            showCheckL1NV = true, checkL1NV, setCheckL1NV,
                                            showCheckL2NV = true, checkL2NV, setCheckL2NV,
                                            showCheckL3NV = true, checkL3NV, setCheckL3NV,
                                            showCheckL1C = true, checkL1C, setCheckL1C,
                                            showCheckL2C = true, checkL2C, setCheckL2C,
                                            showCheckL3C = true, checkL3C, setCheckL3C,
                                            showCheckLoadTestKVA = true, checkLoadTestKVA, setCheckLoadTestKVA,
                                            showCheckRecordRunningHours = true, checkRecordRunningHours, setCheckRecordRunningHours,
                                            showCheckAlternator = true, checkAlternator, setCheckAlternator,
                                            showCheckControlSystem = true, checkControlSystem, setCheckControlSystem,
                                            showCheckAirInletFilter = true, checkAirInletFilter, setCheckAirInletFilter,
                                            showCheckTightenHoldingDownBolts = true, checkTightenHoldingDownBolts, setCheckTightenHoldingDownBolts,
                                            showCheckCleanAroundGenerator = true, checkCleanAroundGenerator, setCheckCleanAroundGenerator,
                                            showCheckRecordVoltsHz = true, checkRecordVoltsHz, setCheckRecordVoltsHz,
                                            showCheckEngineOilPressure = true, checkEngineOilPressure, setCheckEngineOilPressure,
                                            showCheckTestOffLoadOnLoad = true, checkTestOffLoadOnLoad, setCheckTestOffLoadOnLoad,
                                          }) => {
  //#region Render
  return (
    <div className={"form-section no-margins"}>
      {/* TODO(yemon): Standardize that 'gridAutoFlow' property */}
      <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
        <div className={"form-label"}>
          <label>
            Engine Condition:
          </label>
        </div>
        <div className={"form-label"}>
          <label>
            Check Fuel Level:
          </label>
        </div>
        <div className={"form-label"}>
          <label>
            Check Coolant Level:
          </label>
        </div>
        {showCheckOilCleaner &&
          <div className={"form-label"}>
            <label>
              Check Oil Cleaner:
            </label>
          </div>
        }
        <div className={"form-label"}>
          <label>
            Check Engine Oil Level:
          </label>
        </div>
        <div className={"form-label"}>
          <label>
            Check Batteries Condition:
          </label>
        </div>
        <div className={"form-label"}>
          <label>
            Check Drive Belt Tension:
          </label>
        </div>
        <div className={"form-label"}>
          <label>
            Check Control Panel Box:
          </label>
        </div>
        <div className={"form-label"}>
          <label>
            Check Switch & Circuit Breaker:
          </label>
        </div>
        {showCheckWiringConnection &&
          <div className={"form-label"}>
            <label>
              Check Wiring Connection:
            </label>
          </div>
        }
        {showCheckOilCoolantFuelLeaks &&
          <div className={"form-label"}>
            <label>
              Check Oil, Coolant & Fuel Leaks:
            </label>
          </div>
        }
        <div className={"form-label"}>
          <label>
            Check All Hoses, Pipes & Clamps:
          </label>
        </div>
        {checkWiringCondition &&
          <div className={"form-label"}>
            <label>
              Check Wiring Condition:
            </label>
          </div>
        }
        {exhaustConditionColumn === displayColumn.left &&
          <div className={"form-label"}>
            <label>
              Check Exhaust Condition:
            </label>
          </div>
        }
        {showCheckEarthWire &&
          <div className={"form-label"}>
            <label>
              Check Earth, Wire:
            </label>
          </div>
        }
        {starterMotorColumn === displayColumn.left &&
          <div className={"form-label"}>
            <label>
              Check Starter Motor:
            </label>
          </div>
        }
        {showCheckEngineFoundation &&
          <div className={"form-label"}>
            <label>
              Engine Foundation:
            </label>
          </div>
        }
        {showCheckATS &&
          <div className={"form-label"}>
            <label>
              Check ATS (optional):
            </label>
          </div>
        }

        <div className={"form-input"}>
          <ChecklistRadio value={checkEngineCondition} elementId={"checkEngineCondition"}
                          onValueChanged={setCheckEngineCondition}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        <div className={"form-input"}>
          <ChecklistRadio value={checkFuelLevel} elementId={"checkFuelLevel"}
                          onValueChanged={setCheckFuelLevel}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        <div className={"form-input"}>
          <ChecklistRadio value={checkCoolantLevel} elementId={"checkCoolantLevel"}
                          onValueChanged={setCheckCoolantLevel}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        {showCheckOilCleaner &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkOilCleaner} elementId={"checkOilCleaner"}
                            onValueChanged={setCheckOilCleaner}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        <div className={"form-input"}>
          <ChecklistRadio value={checkEngineOilLevel} elementId={"checkEngineOilLevel"}
                          onValueChanged={setCheckEngineOilLevel}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        <div className={"form-input"}>
          <ChecklistRadio value={checkBatteriesCondition} elementId={"checkBatteriesCondition"}
                          onValueChanged={setCheckBatteriesCondition}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        <div className={"form-input"}>
          <ChecklistRadio value={checkDriveBeltTension} elementId={"checkDriveBeltTension"}
                          onValueChanged={setCheckDriveBeltTension}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        <div className={"form-input"}>
          <ChecklistRadio value={checkControlPanelBox} elementId={"checkControlPanelBox"}
                          onValueChanged={setCheckControlPanelBox}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        <div className={"form-input"}>
          <ChecklistRadio value={checkSwitchNCircuitBreaker} elementId={"checkSwitchNCircuitBreaker"}
                          onValueChanged={setCheckSwitchNCircuitBreaker}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        {showCheckWiringConnection &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkWiringConnection} elementId={"checkWiringConnection"}
                            onValueChanged={setCheckWiringConnection}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckOilCoolantFuelLeaks &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkOilCoolantFuelLeaks} elementId={"checkOilCoolantFuelLeaks"}
                            onValueChanged={setCheckOilCoolantFuelLeaks}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        <div className={"form-input"}>
          <ChecklistRadio value={checkHosesPipesClamps} elementId={"checkHosesPipesClamps"}
                          onValueChanged={setCheckHosesPipesClamps}
                          isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
        </div>
        {checkWiringCondition &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkWiringCondition} elementId={"checkWiringCondition"}
                            onValueChanged={setCheckWiringCondition}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {exhaustConditionColumn === displayColumn.right &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkExhaustCondition} elementId={"checkExhaustCondition"}
                            onValueChanged={setCheckExhaustCondition}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckEarthWire &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkEarthWire} elementId={"checkEarthWire"}
                            onValueChanged={setCheckEarthWire}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {starterMotorColumn === displayColumn.right &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkStarterMotor} elementId={"checkStarterMotor"}
                            onValueChanged={setCheckStarterMotor}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckEngineFoundation &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkEngineFoundation} elementId={"checkEngineFoundation"}
                            onValueChanged={setCheckEngineFoundation}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckATS &&
          <div className={"form-input"}>
            <ChecklistRadio value={checkATS} elementId={"checkATS"}
                            onValueChanged={setCheckATS}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }

        {showCheckFuelOilPressure &&
          <div className={"form-label-r"}>
            <label>
              Fuel Oil Pressure:
            </label>
          </div>
        }
        {showCheckWaterTemperature &&
          <div className={"form-label-r"}>
            <label>
              Water Temperature:
            </label>
          </div>
        }
        {showCheckBatteryChargingBolts &&
          <div className={"form-label-r"}>
            <label>
              Battery Charging Volts:
            </label>
          </div>
        }
        {showCheckEngineRPM &&
          <div className={"form-label-r"}>
            <label>
              Engine RPM:
            </label>
          </div>
        }
        {showCheckFrequency &&
          <div className={"form-label-r"}>
            <label>
              Frequency:
            </label>
          </div>
        }
        {showCheckL1V &&
          <div className={"form-label-r"}>
            <label>
              L1 Volts:
            </label>
          </div>
        }
        {showCheckL2V &&
          <div className={"form-label-r"}>
            <label>
              L2 Volts:
            </label>
          </div>
        }
        {showCheckL3V &&
          <div className={"form-label-r"}>
            <label>
              L3 Volts:
            </label>
          </div>
        }
        {showCheckL1NV &&
          <div className={"form-label-r"}>
            <label>
              L1-N-Volts:
            </label>
          </div>
        }
        {showCheckL1NV &&
          <div className={"form-label-r"}>
            <label>
              L2-N-Volts:
            </label>
          </div>
        }
        {showCheckL3NV &&
          <div className={"form-label-r"}>
            <label>
              L3-N-Volts:
            </label>
          </div>
        }
        {showCheckL1C &&
          <div className={"form-label-r"}>
            <label>
              L1-Current:
            </label>
          </div>
        }
        {showCheckL2C &&
          <div className={"form-label-r"}>
            <label>
              L2-Current:
            </label>
          </div>
        }
        {showCheckL3C &&
          <div className={"form-label-r"}>
            <label>
              L3-Current:
            </label>
          </div>
        }
        {showCheckLoadTestKVA &&
          <div className={"form-label-r"}>
            <label>
              Load Test KVA:
            </label>
          </div>
        }
        {exhaustConditionColumn === displayColumn.right &&
          <div className={"form-label-r"}>
            <label>
              Check Exhaust Condition:
            </label>
          </div>
        }
        {showCheckRecordRunningHours &&
          <div className={"form-label-r"}>
            <label>
              Record Running Hours:
            </label>
          </div>
        }
        {starterMotorColumn === displayColumn.right &&
          <div className={"form-label-r"}>
            <label>
              Check Starter Motor:
            </label>
          </div>
        }
        {showCheckAlternator &&
          <div className={"form-label-r"}>
            <label>
              Alternator (AVR performance & all sensor):
            </label>
          </div>
        }
        {showCheckControlSystem &&
          <div className={"form-label-r"}>
            <label>
              Check Control System:
            </label>
          </div>
        }
        {showCheckAirInletFilter &&
          <div className={"form-label-r"}>
            <label>
              Check Air Inlet Filter:
            </label>
          </div>
        }
        {showCheckTightenHoldingDownBolts &&
          <div className={"form-label-r"}>
            <label>
              All Tighten Holding Down Bolts:
            </label>
          </div>
        }
        {showCheckCleanAroundGenerator &&
          <div className={"form-label-r"}>
            <label>
              Clean around the generator:
            </label>
          </div>
        }
        {showCheckRecordVoltsHz &&
          <div className={"form-label-r"}>
            <label>
              Record Volts/Hz:
            </label>
          </div>
        }
        {showCheckEngineOilPressure &&
          <div className={"form-label-r"}>
            <label>
              Record Engine Oil Pressure:
            </label>
          </div>
        }
        {showCheckTestOffLoadOnLoad &&
          <div className={"form-label-r"}>
            <label>
              Test Off-Load/Test On-Load:
            </label>
          </div>
        }

        {showCheckFuelOilPressure &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkFuelOilPressure} elementId={"checkFuelOilPressure"}
                            onValueChanged={setCheckFuelOilPressure}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckWaterTemperature &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkWaterTemperature} elementId={"checkWaterTemperature"}
                            onValueChanged={setCheckWaterTemperature}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckBatteryChargingBolts &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkBatteryChargingVolts} elementId={"checkBatteryChargingVolts"}
                            onValueChanged={setCheckBatteryChargingVolts}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckEngineRPM &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkEngineRPM} elementId={"checkEngineRPM"}
                            onValueChanged={setCheckEngineRPM}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckFrequency &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkFrequency} elementId={"checkFrequency"}
                            onValueChanged={setCheckFrequency}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL1V &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL1V} elementId={"checkL1V"}
                            onValueChanged={setCheckL1V}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL2V &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL2V} elementId={"checkL2V"}
                            onValueChanged={setCheckL2V}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL3V &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL3V} elementId={"checkL3V"}
                            onValueChanged={setCheckL3V}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL1NV &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL1NV} elementId={"checkL1NV"}
                            onValueChanged={setCheckL1NV}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL2NV &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL2NV} elementId={"checkL2NV"}
                            onValueChanged={setCheckL2NV}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL3NV &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL3NV} elementId={"checkL3NV"}
                            onValueChanged={setCheckL3NV}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL1C &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL1C} elementId={"checkL1C"}
                            onValueChanged={setCheckL1C}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL2C &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL2C} elementId={"checkL2C"}
                            onValueChanged={setCheckL2C}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckL3C &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkL3C} elementId={"checkL3C"}
                            onValueChanged={setCheckL3C}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckLoadTestKVA &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkLoadTestKVA} elementId={"checkLoadTestKVA"}
                            onValueChanged={setCheckLoadTestKVA}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {exhaustConditionColumn === displayColumn.right &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkExhaustCondition} elementId={"checkExhaustCondition"}
                            onValueChanged={setCheckExhaustCondition}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckRecordRunningHours &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkRecordRunningHours} elementId={"checkRecordRunningHours"}
                            onValueChanged={setCheckRecordRunningHours}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {starterMotorColumn === displayColumn.right &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkStarterMotor} elementId={"checkStarterMotor"}
                            onValueChanged={setCheckStarterMotor}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckAlternator &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkAlternator} elementId={"checkAlternator"}
                            onValueChanged={setCheckAlternator}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckControlSystem &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkControlSystem} elementId={"checkControlSystem"}
                            onValueChanged={setCheckControlSystem}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckAirInletFilter &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkAirInletFilter} elementId={"checkAirInletFilter"}
                            onValueChanged={setCheckAirInletFilter}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckTightenHoldingDownBolts &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkTightenHoldingDownBolts} elementId={"checkTightenHoldingDownBolts"}
                            onValueChanged={setCheckTightenHoldingDownBolts}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckCleanAroundGenerator &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkCleanAroundGenerator} elementId={"checkCleanAroundGenerator"}
                            onValueChanged={setCheckCleanAroundGenerator}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckRecordVoltsHz &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkRecordVoltsHz} elementId={"checkRecordVoltsHz"}
                            onValueChanged={setCheckRecordVoltsHz}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckEngineOilPressure &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkEngineOilPressure} elementId={"checkEngineOilPressure"}
                            onValueChanged={setCheckEngineOilPressure}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }
        {showCheckTestOffLoadOnLoad &&
          <div className={"form-input-r"}>
            <ChecklistRadio value={checkTestOffLoadOnLoad} elementId={"checkTestOffLoadOnLoad"}
                            onValueChanged={setCheckTestOffLoadOnLoad}
                            isReadonly={isFormReadonly} isDisabled={isFormDisabled} />
          </div>
        }

      </div>
    </div>
  )
  //#endregion
}
