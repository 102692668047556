import React from "react";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { useAuth } from "./AuthProvider";
import { clearAllViewContexts } from "../../repos/viewContexts";

/// routeId=0 indicates to bypass the grant check.
/// NOTE(yemon): Probably not the best solution but it works well.
export const RequireAuth = ({ routeId, children }) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.hasUserSession()) {
    clearAllViewContexts();
    return <Navigate to='/login' state={{ path: location.pathname }} />
  }

  const grants = auth.getUserGrants();
  if (routeId === 0 || grants.routes.includes(routeId)) {
    return children;
  }
  else {
    clearAllViewContexts();
    return <Navigate to='/login' state={{ path: location.pathname }} />
  }
}
