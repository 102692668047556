import React from "react";

export const EntryContactControls = ({
                                       isEditMode, isEditing, isEditAllowed, isReviewAllowed,
                                       isLoadingPermissions, isSubmitting, isInquiryHistoryEditMode,
                                       contactUpdateRequest,
                                       onEditClicked, onUpdateClicked, onCancelClicked,
                                       onApproveClicked, onRejectClicked,
                                     }) => {
  const onCancelButtonClicked = (ev) => {
    onCancelClicked();
  }

  return (
    <div className={"form-button-controls"}>
      <div className={"left-side"}>
        {isEditMode && !isEditing && isEditAllowed && !isLoadingPermissions &&
          <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isInquiryHistoryEditMode}
                  onClick={onEditClicked}>
            <i className="fa-solid fa-pen"></i><span>Edit</span>
          </button>
        }
        {isEditMode && isEditing && !isLoadingPermissions &&
          <>
            <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting || isInquiryHistoryEditMode}
                    onClick={onUpdateClicked}>
              {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
              {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
              <span>Update</span>
            </button>
            <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isInquiryHistoryEditMode}
                    onClick={(ev) => onCancelButtonClicked(ev)}>
              <i className="fa-solid fa-xmark"></i>
              Cancel
            </button>
          </>
        }
      </div>
      <div className={"right-side"}>
        {contactUpdateRequest && !isLoadingPermissions && isReviewAllowed &&
          <>
            <button type={"button"} className={"btn btn-success"} disabled={isSubmitting || isInquiryHistoryEditMode} onClick={onApproveClicked}>
              {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
              {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
              <span>Approve</span>
            </button>
            <button type={"button"} className={"btn btn-danger"} disabled={isSubmitting || isInquiryHistoryEditMode} onClick={onRejectClicked}>
              <i className={"fa-solid fa-xmark"}></i>
              <span>Reject</span>
            </button>
          </>
        }
      </div>
    </div>
  )
}
