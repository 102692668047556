import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { formatSourceName } from "../../repos/utilities";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { AutoCompleteResults } from "../shared/AutoCompleteResults";
import { AutoCompleteNestedResults } from "../shared/AutoCompleteResults";
import { EmployeeBranchGroupsDropdown } from "../shared/EmployeesDropdown";


export const SalesInquirySearchPanel = ({
                                          isSearchOpen, isSearchLoading, isListLoading, followUpStages, followUpActions,
                                          contactName, onContactNameChanged,
                                          contactNo, onContactNoChanged,
                                          customerName, onCustomerNameChanged,
                                          cityId, townshipId, regionTerm, onRegionTermChanged,
                                          onRegionSuggestionCityClicked, onRegionSuggestionTownshipClicked,
                                          source, onSourceChanged,
                                          businessType, onBusinessTypeChanged,
                                          dateFrom, onDateFromChanged,
                                          dateTo, onDateToChanged,
                                          gensetModelId, gensetModel, onGensetModelChanged, onGensetModelSuggestionClicked,
                                          followUpStage, onFollowUpStageChanged,
                                          followUpAction, onFollowUpActionChanged,
                                          reporter, onReporterChanged,
                                          isFirstContact, onIsFirstContactChanged,
                                          isHistoryIncluded, onIsHistoryIncludedChanged,
                                          estimatePoDateFrom, onEstimatePoDateFromChanged,
                                          estimatePoDateTo, onEstimatePoDateToChanged,
                                          onSearchClicked, onClearClicked, onExportExcelClicked, isExporting,
                                          hasErrors, errorMessage,
                                        }) => {
  //#region States
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [isLoadingReporters, setIsLoadingReporters] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);

  const [regionSuggestions, setRegionSuggestions] = useState([]);
  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effect
  useEffect(() => {
    fetchBusinessTypes();
    fetchReporters();
    // loadFollowUpStages();
  }, []);

  const fetchBusinessTypes = () => {
    setIsLoadingBusinessTypes(true);
    refServices2.fetchBusinessTypes()
      .then((response) => setBusinessTypes(response.data))
      .finally(() => setIsLoadingBusinessTypes(false));
  }

  const fetchReporters = () => {
    setIsLoadingReporters(true);
    refServices2.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _responseData = response['data'];
        setEmployeeBranchGroups(_responseData);
      })
      .finally(() => setIsLoadingReporters(false));
  }
  //#endregion

  //#region Utilities
  const getSearchPanelClass = () => {
    return "search-panel" + (isSearchOpen ? " search-panel-open" : " search-panel-hide");
  }

  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (dateFrom && !isToDate) className += " has-autocompleted-value";
    else if (dateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }

  const getEstimatePoDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (estimatePoDateFrom && !isToDate) className += " has-autocompleted-value";
    else if (estimatePoDateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }

  const getGensetModelClassName = () => {
    let className = "form-control md-field";
    if (gensetModelId) className += " has-autocompleted-value";
    return className;
  }

  const getRegionClassName = () => {
    let className = "form-control sm-field";
    if (cityId || townshipId) className += " has-autocompleted-value";
    return className;
  }

  const expandSuggestionsResult = (elementId, suggestionsElementId) => {
    setTimeout(() => {
      let fieldElement = document.getElementById(elementId);
      let suggestionsElement = document.getElementById(suggestionsElementId);
      if (suggestionsElement) {
        suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
        suggestionsElement.style.left = `${fieldElement.offsetLeft}px`;
      }
    }, 100);
  }

  const isFormControlsDisabled = () => {
    return isSearchLoading || isListLoading || isExporting;
  }
  //#endregion

  //#region Control handlers
  let _prevRegionTerm = '';
  let _regionTerm = '';
  const onRegionTermFieldChanged = (ev) => {
    _prevRegionTerm = _regionTerm.trim();
    _regionTerm = ev.target.value;

    let isRegionChanged = onRegionTermChanged(ev, _regionTerm, _prevRegionTerm);
    if (!isRegionChanged) return;

    refServices2.searchRegions(_regionTerm)
      .then((response) => {
        setRegionSuggestions(response.data);
        expandSuggestionsResult('searchRegion', 'regionSuggestions');
      })
      .catch((error) => {
        setRegionSuggestions([]);
      });
  }

  const onRegionTermFieldBlurred = (ev) => {
    setTimeout(() => {
      setRegionSuggestions([]);
    }, 200);
  }

  const onRegionCityClicked = (ev, city) => {
    onRegionSuggestionCityClicked(ev, city);
    setRegionSuggestions([]);
  }

  const onRegionTownshipClicked = (ev, township) => {
    onRegionSuggestionTownshipClicked(ev, township);
    setRegionSuggestions([]);
  }

  const getEmptyGensetSuggestion = () => {
    return {
      'id': '0',
      'gensetModel': '(No genset)',
    };
  }

  let _prevGensetModelTerm = '';
  let _gensetModelTerm = '';
  const onGensetModelTermFieldChanged = (ev) => {
    _prevGensetModelTerm = _gensetModelTerm.trim();
    _gensetModelTerm = ev.target.value.trim();

    let isGensetModelChanged = onGensetModelChanged(ev, _gensetModelTerm, _prevGensetModelTerm);
    if (!isGensetModelChanged) return;

    if (_gensetModelTerm.length >= 2) {
      refServices2.searchGenerators(_gensetModelTerm)
        .then((response) => {
          let suggestions = [getEmptyGensetSuggestion(), ...response.data];
          setGeneratorSuggestions(suggestions);
          expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
        });
    } else {
      setGeneratorSuggestions([]);
    }
  }

  const onGensetModelTermBlurred = (ev) => {
    setTimeout(() => {
      setGeneratorSuggestions([]);
    }, 200);
  }

  const onGensetModelFieldClicked = (ev) => {
    setGeneratorSuggestions([getEmptyGensetSuggestion()]);
    expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
  }

  const onGensetModelClicked = (ev, generator) => {
    onGensetModelSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
  }
  //#endregion

  //#region Render
  return (
    <div className={getSearchPanelClass()}>
      <form onSubmit={onSearchClicked}>
        <div className={"search-fields"}>
          <input type={"text"} id={"searchContactName"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"Contact name"} value={contactName} onChange={onContactNameChanged} />
          <input type={"text"} id={"searchContactNo"} className={"form-control sm-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"Contact no."} value={contactNo} onChange={onContactNoChanged} />
          <input type={"text"} id={"searchContactNo"} className={"form-control sm-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"Customer Name"} value={customerName} onChange={onCustomerNameChanged} />
          <input type={"text"} id={"searchRegion"} className={getRegionClassName()} autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"City/Township"} value={regionTerm} onChange={onRegionTermFieldChanged} onBlur={onRegionTermFieldBlurred} />
          {regionSuggestions && regionSuggestions.length > 0 &&
            <AutoCompleteNestedResults id={"regionSuggestions"} style={{ width: 400, maxHeight: 600 }}
                                       collection={regionSuggestions} itemDisplayField={"name"} onItemClicked={onRegionCityClicked}
                                       childCollectionField={"townships"} childItemDisplayField={"name"}
                                       onChildItemClicked={onRegionTownshipClicked} />
          }
          <div className={"hide-mobile"}>
            <select name={"searchSource"} className={"form-control form-select lg-field"} disabled={isFormControlsDisabled()}
                    value={source} onChange={(ev) => onSourceChanged(parseInt(ev.target.value))}>
              <option value={0}>- Any sources -</option>
              <option value={1}>{formatSourceName(1)}</option>
              <option value={2}>{formatSourceName(2)}</option>
              <option value={3}>{formatSourceName(3)}</option>
            </select>
            <select name={"searchBusinessType"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled() || isLoadingBusinessTypes}
                    value={businessType} onChange={(ev) => onBusinessTypeChanged(parseInt(ev.target.value))}>
              <option value={0}>- Any business types -</option>
              {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
            </select>
          </div>
        </div>
        <div className={"search-fields show-mobile"}>
          <select name={"searchSource"} className={"form-control form-select lg-field"} disabled={isFormControlsDisabled()}
                  value={source} onChange={(ev) => onSourceChanged(parseInt(ev.target.value))}>
            <option value={0}>- Any sources -</option>
            <option value={1}>{formatSourceName(1)}</option>
            <option value={2}>{formatSourceName(2)}</option>
            <option value={3}>{formatSourceName(3)}</option>
          </select>
          <select name={"searchBusinessType"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled() || isLoadingBusinessTypes}
                  value={businessType} onChange={(ev) => onBusinessTypeChanged(parseInt(ev.target.value))}>
            <option value={0}>-Any business types-</option>
            {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
          </select>
        </div>

        <div className={"search-fields"}>
          <div className={"date-field from-date-field"}>
            <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                        placeholderText={"Report date from."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={dateFrom} onChange={onDateFromChanged} />
          </div>
          <div className={"date-field"}>
            <DatePicker id={"dateTo"} className={getDateClassName(true)}
                        placeholderText={"Report date to."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={dateTo} onChange={onDateToChanged} />
          </div>
          <input type={"text"} id={"searchGensetModel"} className={getGensetModelClassName()} autoComplete={"off"}
                 placeholder={"Genset model"} disabled={isFormControlsDisabled()}
                 value={gensetModel} onChange={onGensetModelTermFieldChanged} onBlur={onGensetModelTermBlurred}
                 onClick={onGensetModelFieldClicked}
          />
          {generatorSuggestions && generatorSuggestions.length > 0 &&
            <AutoCompleteResults id={'gensetModelSuggestions'} style={{ width: '400px' }}
                                 collection={generatorSuggestions} itemDisplayField={'gensetModel'}
                                 onItemClicked={onGensetModelClicked} />
          }
          <div className={"hide-mobile"}>
            <select name={"searchFollowUpStage"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                    value={followUpStage} onChange={onFollowUpStageChanged}>
              <option value={0}>- Any stages -</option>
              {followUpStages && followUpStages.map(stage => <option key={stage['id']} value={stage['id']}>{stage['name']}</option>)}
            </select>
            <select name={"searchFollowUpAction"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                    value={followUpAction} onChange={onFollowUpActionChanged}>
              <option value={'0'}>- Any actions -</option>
              {followUpActions && followUpActions.map(action => <option key={action['id']} value={action['id']}>{action['name']}</option>)}
            </select>
            <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                          id={"searchReporter"} name={"searchReporter"}
                                          isDisabled={isFormControlsDisabled() || isLoadingReporters}
                                          value={reporter} onChange={(ev) => onReporterChanged(ev.target.value)} />
          </div>
        </div>
        <div className={"search-fields show-mobile"}>
          <select name={"searchFollowUpStage2"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={followUpStage} onChange={onFollowUpStageChanged}>
            <option value={0}>- Any stages -</option>
            {followUpStages && followUpStages.map(stage => <option key={stage['id']} value={stage['id']}>{stage['name']}</option>)}
          </select>
          <select name={"searchFollowUpAction2"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={followUpAction} onChange={onFollowUpActionChanged}>
            <option value={'0'}>- Any actions -</option>
            {followUpActions && followUpActions.map(action => <option key={action['id']} value={action['id']}>{action['name']}</option>)}
          </select>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"searchReporterMobile"} name={"searchReporterMobile"}
                                        isDisabled={isFormControlsDisabled() || isLoadingReporters}
                                        value={reporter} onChange={(ev) => onReporterChanged(ev.target.value)} />
        </div>

        <div className={"search-fields"}>
          <label htmlFor={"firstContactSearch"} className={"with-border"}>
            <input type={"checkbox"} id={"firstContactSearch"} className={""} disabled={isFormControlsDisabled()}
                   checked={isFirstContact} onChange={onIsFirstContactChanged} />
            First contact within date range
          </label>
          <div className={"date-field from-date-field padded-left"}>
            <DatePicker id={"estimatePoDateFrom"} className={getEstimatePoDateClassName(false)}
                        placeholderText={"Estimate PO date from."} required={false} autoComplete={"off"}
                        showWeekNumbers minDate={new Date()}
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={estimatePoDateFrom} onChange={onEstimatePoDateFromChanged} />
          </div>
          <div className={"date-field"}>
            <DatePicker id={"estimatePoDateTo"} className={getEstimatePoDateClassName(true)}
                        placeholderText={"Estimate PO date to."} required={false} autoComplete={"off"}
                        showWeekNumbers minDate={new Date()}
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={estimatePoDateTo} onChange={onEstimatePoDateToChanged} />
          </div>
        </div>

        <div className={"search-fields"}>
          <label htmlFor={"includeHistorySearch"} className={"with-border"}>
            <input type={"checkbox"} id={"includeHistorySearch"} className={""} disabled={isFormControlsDisabled()}
                   checked={isHistoryIncluded} onChange={onIsHistoryIncludedChanged} />
            Include inquiry histories
          </label>
        </div>

        <div className={"search-fields"}>
          <FieldErrorMessage visible={hasErrors} message={errorMessage} />
        </div>

        <div className={"search-fields"}>
          <ListSearchWarningMessage visible={isHistoryIncluded} />
        </div>

        <div className={"search-controls"}>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
          <button type={"button"} className={"btn btn-secondary"} style={{ marginLeft: '8px' }}
                  disabled={isFormControlsDisabled()} onClick={onExportExcelClicked}>
            {isExporting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isExporting && <i className="fa-solid fa-file-excel"></i>}
            Export Excel
          </button>
        </div>
      </form>
    </div>
  )
  //#endregion
}


export const ListSearchWarningMessage = ({
                                           visible,
                                         }) => {
  return (
    <>
      {visible &&
        <div className={"warning-message"}>
          <i className="fa-solid fa-triangle-exclamation"></i>
          Performance intensive search operation.
        </div>
      }
    </>
  )
}
