import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { inspectionFormMode } from "./InspectionFormContext";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";

export const ServiceEntryHeader = ({
                                     standbyPower, onStandbyPowerChanged,
                                     productYear, onProductYearChanged,
                                     entryDate, onEntryDateChanged, minEntryDate,
                                     inspectionType, onInspectionTypeChanged,
                                     testMode, onTestModeChanged,
                                     isLoading, isEditable, isSubmitting, hasErrors, formErrors,
                                     formMode,
                                   }) => {
  //#region States
  //#endregion

  //#region Effects
  //#endregion

  //#region Utilities
  const getEntryDateClassName = () => {
    let className = "form-control";
    if (entryDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <div className={"form-section"}>

      <div className={"entry-form arrival-inspection-form service-header-section"}>
        <div className={"form-label"}>
          <label htmlFor={"standbyPower"}>
            Standby Power:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"productYear"}>
            Product Year:
          </label>
        </div>

        <div className={"form-input"}>
          {formMode === inspectionFormMode.arrivalInspection &&
            <>
            {isEditable &&
              <input type={"text"} id={"standbyPower"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={standbyPower} onChange={onStandbyPowerChanged} />
            }
            {!isEditable &&
              <ReadonlyField><NullBlankValue value={standbyPower} /></ReadonlyField>
            }
            </>
          }
          {formMode !== inspectionFormMode.arrivalInspection &&
            <ReadonlyField><NullBlankValue value={standbyPower} /></ReadonlyField>
          }
        </div>

        <div className={"form-input"}>
          {formMode === inspectionFormMode.arrivalInspection &&
            <>
            {isEditable &&
              <input type={"text"} id={"productYear"} className={"form-control"}
                     autoComplete={"off"} maxLength={18} disabled={isLoading || isSubmitting}
                     value={productYear} onChange={onProductYearChanged} />
            }
            {!isEditable &&
              <ReadonlyField>{productYear === '' || productYear === null ? '-' : productYear}</ReadonlyField>
            }
            </>
          }
          {formMode !== inspectionFormMode.arrivalInspection &&
            <ReadonlyField>{productYear === '' || productYear === null ? '-' : productYear}</ReadonlyField>
          }
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"entryDate"}>
            Date:<span className="error-message">*</span>
          </label>
        </div>

        {formMode !== inspectionFormMode.arrivalInspection &&
          <div className={"form-label-r"}>
            <label htmlFor={"inspectionType"}>
              Inspection Type:
            </label>
          </div>
        }

        <div className={"form-label-r"}>
          <label htmlFor={"testingMode"}>
            Testing:
          </label>
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField><Moment date={entryDate} format={formatters.datetimeShort} /></ReadonlyField>}
          {isEditable &&
            <>
              <DatePicker id={"entryDate"} className={getEntryDateClassName()} placeholderText={"Type a valid date or click to choose"}
                          dateFormat={formatters.datetimePicker} maxDate={new Date()} minDate={minEntryDate}
                          required={true} todayButton={"Today"} showWeekNumbers
                          disabled={isLoading || isSubmitting} autoComplete={"off"}
                          selected={entryDate} onChange={onEntryDateChanged} />
              <FieldErrorMessages visible={hasErrors} messages={formErrors['entryDate']} />
            </>
          }
        </div>

        {formMode !== inspectionFormMode.arrivalInspection &&
          <div className={"form-input-r"}>
            {!isEditable && <ReadonlyField>{refs.inventory.stockInspectionType[inspectionType]}</ReadonlyField>}
            {isEditable &&
              <>
                <label className={"option-field"}>
                  <input type={"radio"} id={"inspectionType"} name={"inspectionType"} disabled={isLoading || isSubmitting}
                         value={refs.inventory.stockInspectionType.predelivery} onChange={onInspectionTypeChanged}
                         checked={inspectionType === refs.inventory.stockInspectionType.predelivery} />
                  Pre-Delivery
                </label>
                <label className={"option-field"}>
                  <input type={"radio"} id={"inspectionType"} name={"inspectionType"} disabled={isLoading || isSubmitting}
                         value={refs.inventory.stockInspectionType.monthly} onChange={onInspectionTypeChanged}
                         checked={inspectionType === refs.inventory.stockInspectionType.monthly} />
                  Monthly
                </label>
              </>
            }
          </div>
        }

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{refs.inventory.testMode[testMode]}</ReadonlyField>}
          {isEditable &&
            <>
              <label className={"option-field"}>
                <input type={"radio"} id={"testingMode"} name={"testingMode"} disabled={isLoading || isSubmitting}
                       value={refs.inventory.testMode.onLoad} onChange={onTestModeChanged}
                       checked={testMode === refs.inventory.testMode.onLoad} />
                On Load
              </label>
              <label className={"option-field"}>
                <input type={"radio"} id={"testingMode"} name={"testingMode"} disabled={isLoading || isSubmitting}
                       value={refs.inventory.testMode.offLoad} onChange={onTestModeChanged}
                       checked={testMode === refs.inventory.testMode.offLoad} />
                Off Load
              </label>
            </>
          }
        </div>

      </div>
    </div>
  )
  //#endregion
}
