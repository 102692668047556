import React from "react";
import Moment from "react-moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from '../auth/AuthProvider';
import { authService } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { role as roleConstants } from "../../repos/constants"
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { formatTownshipDisplay } from "../../repos/utilities";
import { validateSearchDateRange } from "../../repos/searchUtilities";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getCancelRequestsContext } from "../../repos/viewContexts";
import { saveCancelRequestsContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";
import { doVirtualDownload } from "../../repos/exports";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { BreadcrumbItem, Breadcrumbs } from "../shared/Breadcrumbs";
import { TableLoadingIndicator, TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { ReviewStatusBadge } from "./ReviewStatusBadge";
import { QuotationRequestCancelRequestsModal } from "./QuotationRequestCancelRequestsModal";
import { QuotationRequestSearchPanel } from "./QuotationRequestSearchPanel";

import '../shared/DataTable.css';
import '../shared/ListingPage.css';
import '../shared/SearchPanel.css';
import "./QuotationRequestListPage.css";


export function QuotationRequestListPage() {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [quotationRequests, setQuotationRequests] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [listPermissions, setListPermissions] = useState();

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // flag to indicate whether the last post back was a search trigger
  const [isExporting, setIsExporting] = useState(false);

  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [contactNoSearch, setContactNoSearch] = useState('');
  const [regionTermSearch, setRegionTermSearch] = useState('');
  const [cityIdSearch, setCityIdSearch] = useState(null);
  const [townshipIdSearch, setTownshipIdSearch] = useState(null);
  const [sourceSearch, setSourceSearch] = useState(0);
  const [businessTypeSearch, setBusinessTypeSearch] = useState(0);
  const [dateFromSearch, setDateFromSearch] = useState(null);
  const [dateToSearch, setDateToSearch] = useState(null);
  const [requesterIdSearch, setRequesterIdSearch] = useState('');
  const [reviewStatusSearch, setReviewStatusSearch] = useState(-1);
  const [currencyCodeSearch, setCurrencyCodeSearch] = useState(0);

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.quotationRequests);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext != null) {
      // trigger search with the existing context
      triggerSearchByContext(searchOptionsContext, paginationContext);
    } else {
      // load the regular list
      fetchQuotationRequestList(paginationContext ? paginationContext['currentPage'] : 1);
    }
    fetchPermissions();

    let cancelRequestContext = getCancelRequestsContext(contexts.quotationRequests);
    if (cancelRequestContext && cancelRequestContext['isModalOpened']) cancelRequestsModal.onOpenButtonClicked();
    fetchQuotationRequestCancelRequests();
  }, []);

  const fetchPermissions = () => {
    return authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _listPermissions = response.data['quotationRequests'];
        setListPermissions(_listPermissions);
      });
  }

  const prepareListPayload = (page) => {
    let settings = getViewSettings(viewSettings.quotationRequests);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }
  //#endregion

  //#region Utilities
  const fetchQuotationRequestList = (page) => {
    setIsLoading(true);
    resetListingStates();
    let listPayload = prepareListPayload(page);
    salesServices.quotationRequestList(listPayload)
      .then((response) => {
        updateListingStates(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }

  const updateListingStates = (responseData) => {
    setQuotationRequests(responseData['data'])
    setPagination(responseData['pagination']);
    savePaginationContext(contexts.quotationRequests, responseData['pagination']);
    setSorting((responseData['sorting']));
  }

  const resetListingStates = () => {
    setQuotationRequests([]);
    setSorting(null);
    setPagination(null);
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();
    setIsSearchOpen(false);
    saveSearchPanelOpenContext(contexts.quotationRequests, false);

    let paginationContext = getPaginationContext(contexts.quotationRequests);
    fetchQuotationRequestList(paginationContext ? paginationContext['currentPage'] : 1);
    fetchQuotationRequestCancelRequests();
  }

  const onNewEntryClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.quotationRequestEntry.url);
    }, 200);
  }

  const onRowClicked = (ev, request) => {
    setTimeout(() => {
      navigate(routes.quotationRequestEntry.url, { state: request });
    }, 200);
  }

  const onPageClicked = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    } else {
      fetchQuotationRequestList(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    } else {
      fetchQuotationRequestList(page);
    }
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    } else {
      fetchQuotationRequestList(page);
    }
  }
  //#endregion

  //#region Search panel; Control handlers, loaders and utilities
  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.quotationRequests);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.quotationRequests);
    if (searchOptions === null) return null;

    // NOTE(yemon): Field values in the context were saved from the postback payload.
    setCustomerNameSearch(searchOptions['customer_name']);
    setContactNameSearch(searchOptions['contact_name']);
    setContactNoSearch(searchOptions['contact_no']);

    setSourceSearch(searchOptions['source_id']);
    setBusinessTypeSearch(searchOptions['business_type_id']);
    setRequesterIdSearch(searchOptions['requester_id']);
    setReviewStatusSearch(searchOptions['review_status']);
    setCurrencyCodeSearch(searchOptions['currency_code']);

    setDateFromSearch(searchOptions['date_from'] ? new Date(searchOptions['date_from']) : null);
    setDateToSearch(searchOptions['date_to'] ? new Date(searchOptions['date_to']) : null);

    return searchOptions;
  }

  const onSearchToggleClicked = (ev) => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.quotationRequests, _isOpen);
  }

  const onCustomerNameSearchChanged = (ev) => {
    setCustomerNameSearch(ev.target.value);
  }

  const onContactNameSearchChanged = (ev) => {
    setContactNameSearch(ev.target.value);
  }

  const onContactNoSearchChanged = (ev) => {
    setContactNoSearch(ev.target.value);
  }

  const onRegionTermSearchChanged = (ev, regionTerm, prevRegionTerm) => {
    if (regionTerm.trim() === '') {
      setRegionTermSearch('');
      setCityIdSearch(null);
      setTownshipIdSearch(null);
      return false;
    }
    if (regionTerm === prevRegionTerm) {
      return false;
    }
    setRegionTermSearch(regionTerm);
    setCityIdSearch(null);
    setTownshipIdSearch(null);
    return true;
  }

  const onRegionCityClicked = (ev, city) => {
    setRegionTermSearch(city['name']);
    setCityIdSearch(city['id']);
    setTownshipIdSearch(null);
  }

  const onRegionTownshipClicked = (ev, township) => {
    setRegionTermSearch(formatTownshipDisplay(township));
    setCityIdSearch(township['cityId']);
    setTownshipIdSearch(township['id']);
  }

  const onSourceSearchChanged = (sourceId) => {
    setSourceSearch(sourceId);
  }

  const onBusinessTypeSearchChanged = (businessTypeId) => {
    setBusinessTypeSearch(businessTypeId);
  }

  const onDateFromSearchChanged = (fromDate) => {
    setDateFromSearch(fromDate);
  }

  const onDateToSearchChanged = (toDate) => {
    setDateToSearch(toDate);
  }

  const onRequesterSearchChanged = (requesterId) => {
    setRequesterIdSearch(requesterId);
  }

  const onReviewStatusSearchChanged = (statusId) => {
    setReviewStatusSearch(statusId);
  }

  const onCurrencyCodeSearchChanged = (currencyCode) => {
    setCurrencyCodeSearch(currencyCode);
  }

  const validateAndPrepareSearchOptions = () => {
    let dateValidation = validateSearchDateRange(dateFromSearch, dateToSearch);
    if (dateValidation.hasErrors) {
      setHasSearchErrors(true);
      setSearchErrorMessage(dateValidation.errorMessage);
      return null;
    }

    let searchOptions = {
      'customer_name': customerNameSearch,
      'contact_name': contactNameSearch,
      'contact_no': contactNoSearch,
      'source_id': sourceSearch,
      'business_type_id': businessTypeSearch,
      'requester_id': requesterIdSearch,
      'review_status': reviewStatusSearch,
      'currency_code': currencyCodeSearch,
    };
    if (!dateValidation.hasErrors && (dateValidation.fromDate && dateValidation.toDate)) {
      setDateFromSearch(dateValidation.fromDate);
      setDateToSearch(dateValidation.toDate);
      searchOptions['date_from'] = dateValidation.fromDate.toLocaleString();
      searchOptions['date_to'] = dateValidation.toDate.toLocaleString();
    }
    return searchOptions;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;

    let searchOptionsContext = {
      ...searchOptions,
    }
    saveSearchPanelOptionsContext(contexts.quotationRequests, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    }
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);
    setIsLoading(true);
    salesServices.quotationRequestList(listPayload)
      .then((response) => {
        setIsSearching(true);
        updateListingStates(response.data);
      })
      .catch((error) => {
        setIsSearching(false);
        setIsLoading(false);
      })
      .finally(() => {
        setIsSearchLoading(false);
        setIsLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const resetSearchPanelFields = () => {
    setIsSearching(false);
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    setCustomerNameSearch('');
    setContactNameSearch('');
    setContactNoSearch('');
    setRegionTermSearch('');
    setSourceSearch(0);
    setBusinessTypeSearch(0);
    setDateFromSearch(null);
    setDateToSearch(null);
    setRequesterIdSearch('');
    setReviewStatusSearch(-1);
    setCurrencyCodeSearch(0);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.quotationRequests);
    resetSearchPanelFields();
    fetchQuotationRequestList(1);
  }

  const onExcelExportButtonClicked = (ev) => {
    let listOptionsPayload = prepareListPayload(1);

    if (isSearching) {
      let searchOptions = validateAndPrepareSearchOptions();
      if (searchOptions === null) return;
      listOptionsPayload['is_searching'] = isSearching;
      listOptionsPayload['search_options'] = searchOptions;
    }

    setIsExporting(true);
    salesServices.postQuotationRequestListExport(listOptionsPayload)
      .then((response) => {
        doVirtualDownload(response, 'QuotationRequests', 'xlsx');
      })
      .catch((error) => {
        console.error(error['response']);
      })
      .finally(() => setIsExporting(false));
  }
  //#endregion

  //#region Control handlers; Cancel Requests modal related stuffs
  const [isCancelRequestsModalOpen, setIsCancelRequestsModalOpen] = useState(false);
  const [isCancelRequestsLoading, setIsCancelRequestsLoading] = useState(false);
  const [cancelRequests, setCancelRequests] = useState([]);

  const cancelRequestsModal = {
    onOpenButtonClicked: function() {
      saveCancelRequestsContext(contexts.quotationRequests, true);
      setTimeout(() => {
        setIsCancelRequestsModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      saveCancelRequestsContext(contexts.quotationRequests, false);
      setTimeout(() => {
        setIsCancelRequestsModalOpen(false);
      }, 200);
    },
  }

  const fetchQuotationRequestCancelRequests = () => {
    setIsCancelRequestsLoading(true);
    let tableOptions = {
      'uid': auth.getUserId(),
    };
    return salesServices.fetchQuotationRequestCancelRequests(tableOptions)
      .then((response) => {
        let _cancelRequests = response.data.data;
        setCancelRequests(_cancelRequests);
      })
      .finally(() => setIsCancelRequestsLoading(false));
  }

  const onCancelRequestRowClicked = (ev, cancelRequest) => {
    ev.preventDefault();
    setTimeout(() => {
      navigate(routes.quotationRequestEntry.url, { state: cancelRequest });
    })
  }
  //#endregion

  //#region View Settings modal; handlers and utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    } else {
      fetchQuotationRequestList(1);
    }
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container quotation-requests-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.quotationRequestsList.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>Quotation Requests</h1>
            <div className="listing-controls">
              <button className={"btn btn-secondary"} disabled={isLoading}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              {listPermissions && listPermissions['reviewLevel'] === roleConstants.quotationRequest.reviewLevels.requester &&
                <button className="btn btn-primary btn-md" disabled={isLoading} onClick={onNewEntryClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New Request
                </button>
              }
              <button type={"button"} className={"btn btn-secondary"} disabled={isLoading || isCancelRequestsLoading}
                      onClick={cancelRequestsModal.onOpenButtonClicked}>
                {isCancelRequestsLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isCancelRequestsLoading && <i className="fa-solid fa-file-circle-xmark"></i>}
                <span>Cancel Requests...</span>
              </button>

              <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button>
            </div>
          </div>

          <QuotationRequestCancelRequestsModal isOpen={isCancelRequestsModalOpen} isLoading={isCancelRequestsLoading}
                                               onRequestClose={cancelRequestsModal.onCloseButtonClicked}
                                               cancelRequests={cancelRequests}
                                               onCancelRequestRowClicked={onCancelRequestRowClicked}
          />

          {isSearchOpen &&
            <QuotationRequestSearchPanel isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                         customerName={customerNameSearch} onCustomerNameChanged={onCustomerNameSearchChanged}
                                         contactName={contactNameSearch} onContactNameChanged={onContactNameSearchChanged}
                                         contactNo={contactNoSearch} onContactNoChanged={onContactNoSearchChanged}
                                         regionTerm={regionTermSearch} onRegionTermChanged={onRegionTermSearchChanged}
                                         onRegionSuggestionCityClicked={onRegionCityClicked} onRegionSuggestionTownshipClicked={onRegionTownshipClicked}
                                         source={sourceSearch} onSourceChanged={onSourceSearchChanged}
                                         businessType={businessTypeSearch} onBusinessTypeChanged={onBusinessTypeSearchChanged}
                                         dateFrom={dateFromSearch} onDateFromChanged={onDateFromSearchChanged}
                                         dateTo={dateToSearch} onDateToChanged={onDateToSearchChanged}
                                         requesterId={requesterIdSearch} onRequesterChanged={onRequesterSearchChanged}
                                         reviewStatus={reviewStatusSearch} onReviewStatusChanged={onReviewStatusSearchChanged}
                                         currencyCode={currencyCodeSearch} onCurrencyChanged={onCurrencyCodeSearchChanged}
                                         onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                         onExportExcelClicked={onExcelExportButtonClicked} isExporting={isExporting}
                                         hasErrors={hasSearchErrors} errorMessage={searchErrorMessage}
            />
          }

          <div className="data-table quotation-requests-table">
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th>Customer Name</th>
                <th>Contact Person</th>
                <th>Contact No.</th>
                <th>Source</th>
                <th>Business Type</th>
                <th>Request Date</th>
                <th>Requested By</th>
                <th>Review Status</th>
                <th>Reviewed Date</th>
                <th>Reviewed By</th>
              </tr>
              </thead>

              <tbody>
              {isLoading && <TableLoadingIndicator colspan={11} />}

              {!isLoading && quotationRequests && quotationRequests.length > 0 &&
                quotationRequests.map((request, index) =>
                  <QuotationRequestRow key={request['requestId']} request={request} index={index}
                                       currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                       onRowClicked={(ev) => onRowClicked(ev, request)}
                  />
                )
              }

              {!quotationRequests || (quotationRequests.length === 0 && !isLoading &&
                <TableEmptyRow colSpan={11} />)}
              </tbody>
            </table>

            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClicked}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.quotationRequests} onViewSettingsSaved={onViewSettingsSaved}
              />
            }

          </div>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const QuotationRequestRow = ({
                               request, index,
                               currentPage, pageSize,
                               onRowClicked,
                             }) => {
  let source = refs.sales.source[request['source']];

  return (
    <>
      <tr>
        <td className={"index-col"}>{(pageSize * (currentPage - 1) + (index + 1))}</td>
        <td className={"customer-name-col"}>
          <a href="#" role={"button"} className={"record-link"} onClick={(ev) => onRowClicked(ev, request)}>
            {request['customer']['customerName']}
          </a>
        </td>
        <td className={"contact-name-col"}>{request['customer']['contactFullName']}</td>
        <td className={"contact-no-col"}>{request['customer']['contactNo']}</td>
        <td className={"source-col"}>
          <span title={request['sourceDisplay']['name']}>
            {request['sourceDisplay']['code']}
          </span>
        </td>
        <td className={"business-type-col"}>{request['customer']['businessType']['name']}</td>
        <td className={"date-col"}>
          <Moment date={request['requestedDatetime']} format={formatters.datetimeShort} />
        </td>
        <td className={"actor-col"}>
          <ActorNameDisplay employee={request['requestedBy']} />
        </td>
        <td className={"review-status-col"}>
          <ReviewStatusBadge status={request['reviewStatus']} />
        </td>
        <td className={"date-col"}>
          {request['reviewedDatetime'] ? <Moment date={request['reviewedDatetime']} format={formatters.datetime} /> : <>-</>}
        </td>
        <td className={"actor-col"}>
          {request['reviewedBy'] ? <ActorNameDisplay employee={request['reviewedBy']} /> : <>-</>}
        </td>
      </tr>
    </>
  )
}
