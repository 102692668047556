import { getDisplayTermFromGeneratorDetail } from "../../repos/utilities";

export const GeneratorsSummary = ({
                                    generators,
                                    summarized = true,
                                  }) => {
  if (generators.length === 0) {
    return <>-</>
  }
  if (!summarized) {
    let _terms = generators.map(generator => getDisplayTermFromGeneratorDetail(generator));
    return (
      <>{_terms.join(', ')}</>
    )
  } else {
    if (generators.length === 1) {
      return (
        <>{getDisplayTermFromGeneratorDetail(generators[0])}</>
      )
    } else {
      let _terms = generators.map(generator => getDisplayTermFromGeneratorDetail(generator));
      let generatorsTooltip = _terms.join(', ');
      return (
        <span title={generatorsTooltip}>
          {getDisplayTermFromGeneratorDetail(generators[0])}<span style={{ color: 'gray' }}> (+{generators.length - 1})</span>
        </span>
      )
    }
  }
}
