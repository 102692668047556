import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useRef } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { sanitizeCurrencyValue } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { WarningMessage } from "../sales/FormMessages";
import { InfoMessage } from "../sales/FormMessages";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";

import "./SalesTargetEntryPage.css";

const conditionTypes = {
  1: "Only USD", onlyUSD: 1,
  2: "Only MMK", onlyMMK: 2,
  3: "Both", both: 3,
};

export const SalesTargetEntryPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [fullNameDisplay, setFullNameDisplay] = useState("");
  const [rankDisplay, setRankDisplay] = useState("");
  const [branchDisplay, setBranchDisplay] = useState("");

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [amountUSD, setAmountUSD] = useState('0.00');
  const [condition, setCondition] = useState(conditionTypes.onlyUSD);
  const [amountMMK, setAmountMMK] = useState('0.00');
  const [isUSDMandatory, setIsUSDMandatory] = useState(false);
  const [isMMKMandatory, setIsMMKMandatory] = useState(false);

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    const state = location.state;
    if (!state || !state['employee']) {
      navigate(routes.salesTargetsList.url);
    }

    const employee = state['employee'];
    console.log(state);
    setFullNameDisplay(employee['fullName']);
    setRankDisplay(employee['rankName']);
    setBranchDisplay(employee['branchName'] ? employee['branchName'] : '-');

    updateAmountMandatoryStates(conditionTypes.onlyUSD);
  }, []);
  //#endregion

  //#region Control handlers
  const onDateFromChanged = (fromDate) => {
    setDateFrom(fromDate);
  }

  const onDateToChanged = (toDate) => {
    setDateTo(toDate);
  }

  const onAmountUSDChanged = (ev) => {
    setAmountUSD(ev.target.value);
  }

  const onAmountUSDBlurred = (ev) => {
    setAmountUSD(sanitizeCurrencyValue(ev.target.value.trim()));
  }

  const onAmountMMKChanged = (ev) => {
    setAmountMMK(ev.target.value);
  }

  const onAmountMMKBlurred = (ev) => {
    setAmountMMK(sanitizeCurrencyValue(ev.target.value.trim()));
  }

  const onConditionChanged = (ev) => {
    setCondition(parseInt(ev.target.value));
    updateAmountMandatoryStates(parseInt(ev.target.value));
  }

  const updateAmountMandatoryStates = (condition) => {
    switch (condition) {
      case conditionTypes.onlyUSD: {
        setIsUSDMandatory(true);
        setIsMMKMandatory(false);
        break;
      }
      case conditionTypes.onlyMMK: {
        setIsUSDMandatory(false);
        setIsMMKMandatory(true);
        break;
      }
      case conditionTypes.both: {
        setIsUSDMandatory(true);
        setIsMMKMandatory(true);
        break;
      }
      default: {
        setIsUSDMandatory(true);
        setIsMMKMandatory(false);
      }
    }
  }

  const validateFormFields = () => {
    // TODO(yemon):
  }

  const onSaveClicked = (ev) => {
    // TODO(yemon):
  }
  //#endregion

  //#region Utilities
  const isFormControlsDisabled = () => {
    return isLoading || isSaving;
  }

  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (dateFrom && !isToDate) className += " has-autocompleted-value";
    else if (dateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container-grid"}>
        <div className={"content-area content-area-main"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.salesTargetsList.displayShort} anchorTo={routes.salesTargetsList.url} />
              <BreadcrumbItem text={fullNameDisplay} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1 title={"Employee name / Branch"}>
              {fullNameDisplay}&nbsp;/&nbsp;{branchDisplay}
            </h1>
            <h4 title={"Employee rank"}>
              {rankDisplay}
            </h4>
          </div>

          <div className={"form-section"}>
            <div className={"entry-form sales-target-entry-form"}>
              <div className={"form-label-wide"}>
                <label htmlFor={"active-from-date"}>
                  Active Period From:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label-wide"}>
                <label htmlFor={"active-to-date"}>
                  Active Period To:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label-wide"}>
                <label htmlFor={"amount-usd"}>
                  Target Amount in USD ($):
                  {isUSDMandatory && <span className={"error-message"}>*</span>}
                </label>
              </div>

              <div className={"form-label-wide"}>
                <label htmlFor={"condition-usd"}>
                  Condition:<span className={"error-message"}>*</span>
                </label>
              </div>

              <div className={"form-label-wide"}>
                <label htmlFor={"amount-mmk"}>
                  Target Amount in Myanmar Kyats (MMK):
                  {isMMKMandatory && <span className={"error-message"}>*</span>}
                </label>
              </div>

              <div className={"form-input-wide"}>
                <DatePicker id={"active-from-date"} className={getDateClassName(false)}
                            placeholderText={"From date."} required={true} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateFrom} onChange={onDateFromChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateFrom']} />
              </div>

              <div className={"form-input-wide"}>
                <DatePicker id={"active-to-date"} className={getDateClassName(true)}
                            placeholderText={"To date."} required={true} autoComplete={"off"} showWeekNumbers
                            dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                            selected={dateTo} onChange={onDateToChanged} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['dateTo']} />
              </div>

              <div className={"form-input-wide"}>
                <input type={"text"} id={"amount-usd"} className={"form-control"} autoComplete={"none"} 
                       disabled={isFormControlsDisabled() || !isUSDMandatory}
                       value={amountUSD} onChange={onAmountUSDChanged} onBlur={onAmountUSDBlurred} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['amountUSD']} />
              </div>

              <div className={"form-input-wide has-block-options"}>
                <label className={"option-field"}>
                  <input type={"radio"} id={"only-usd"} name={"condition"} disabled={isFormControlsDisabled()}
                         value={conditionTypes.onlyUSD} checked={condition === conditionTypes.onlyUSD}
                        onChange={onConditionChanged} />
                  {conditionTypes[conditionTypes.onlyUSD]}
                </label>
                <label className={"option-field"}>
                  <input type={"radio"} id={"only-mmk"} name={"condition"} disabled={isFormControlsDisabled()}
                         value={conditionTypes.onlyMMK} checked={condition === conditionTypes.onlyMMK}
                        onChange={onConditionChanged} />
                  {conditionTypes[conditionTypes.onlyMMK]}
                </label>
                <label className={"option-field"}>
                  <input type={"radio"} id={"both-usd-mmk"} name={"condition"} disabled={isFormControlsDisabled()}
                         value={conditionTypes.both} checked={condition === conditionTypes.both}
                        onChange={onConditionChanged} />
                  {conditionTypes[conditionTypes.both]}
                </label>
              </div>

              <div className={"form-input-wide"}>
                <input type={"text"} id={"amount-mmk"} className={"form-control"} autoComplete={"none"} 
                       disabled={isFormControlsDisabled() || !isMMKMandatory}
                       value={amountMMK} onChange={onAmountMMKChanged} onBlur={onAmountMMKBlurred} />
                <FieldErrorMessage visible={hasErrors} message={formErrors['amountMMK']} />
              </div>
            </div>
          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary"} disabled={false}
                      onClick={null}>
                {isSaving && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSaving && <i className={"fa-solid fa-check"}></i>}
                Save
              </button>
            </div>
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>
        </div>

        <div className={"content-area content-area-side"}>
          <SalesTargetWidget fullNameDisplay={fullNameDisplay} 
                             dateFrom={dateFrom} dateTo={dateTo}
                             isUSDMandatory={isUSDMandatory} 
                             isMMKMandatory={isMMKMandatory} />
        </div>

      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const SalesTargetWidget = ({
                            fullNameDisplay, dateFrom, dateTo,
                            isUSDMandatory, isMMKMandatory,
                          }) => { 
  const [isCheckingProgress, setIsCheckingProgress] = useState(false);
  const [noOfInvoices, setNoOfInvoices] = useState(0);

  const getEmployeeNameDisplay = () => {
    if (fullNameDisplay) {
      return <b>{fullNameDisplay}</b>
    }
    return "the employee";
  }

  const getInvoiceCountDisplay = () => {
    if (!noOfInvoices) {
      return "invoices are";
    }

    if (noOfInvoices === 1) {
      return `invoice is`;
    }
    else {
      return `invoices are`;
    }
  }

  return (
    <div className={"info-panel"}>
      <h2>Target Progress</h2>
      <div className={"sales-target-widget"}>
        <div className={"target-date-range"}>
          {dateFrom && 
            <Moment date={dateFrom} format={formatters.datetimeShort} />
          }
          {!dateFrom && <i>(unspecified)</i>}
          &nbsp;-&nbsp;
          {dateTo && 
            <Moment date={dateTo} format={formatters.datetimeShort} />
          }
          {!dateTo && <i>(unspecified)</i>}
        </div>
        <div className={"target-progresses"}>
          {isUSDMandatory && 
            <div className={"target-progress"}>
              <div className={"target-progress-label"}>USD</div>
              <div className={"target-progress-bar"} title={" 70,000 / 100,000 USD"}>
                <div className={"target-progress-bar-foreground"} style={{ width: '70%' }}></div>
              </div>
            </div>
          }
          {isMMKMandatory && 
            <div className={"target-progress"}>
              <div className={"target-progress-label"}>MMK</div>
              <div className={"target-progress-bar"} title={"[40%] 8,000,000 / 10,00,000 MMK"}>
                <div className={"target-progress-bar-foreground"} style={{ width: '40%' }}></div>
              </div>
            </div>
          }
        </div>
        <div className={"panel-contents"}>
          {isCheckingProgress && <i>Identifying progress...</i>}
          {!isCheckingProgress && 
            <>
              <b>{noOfInvoices}</b> {getInvoiceCountDisplay()} fulfilling this target for {getEmployeeNameDisplay()}.
            </>
          }
        </div>
        <div className={"panel-controls"}>
          <button role={"button"} className={"btn btn-secondary"}
                  disabled={isCheckingProgress || noOfInvoices < 1} onClick={null}>
            {isCheckingProgress && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
            {!isCheckingProgress && <i className={"fa-solid fa-search"}></i>}
            View Invoices
          </button>
        </div>
      </div>
    </div>
  )
}