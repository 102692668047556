export const FieldErrorMessages = (
  { visible, messages }
) => {
  return (
    <>
      {visible &&
        <div className={"error-message validation-message"}>
          {messages && messages.length > 0 &&
            messages.map((message, index) => <div key={index}>{message}</div>)
          }
        </div>
      }
    </>
  )
}

export const FieldErrorMessage = (
  { visible, message }
) => {
  return (
    <>
      {visible &&
        <div className={"error-message validation-message"}>
          {message && <div>{message}</div>}
        </div>
      }
    </>
  )
}
