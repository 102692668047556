import { Component } from "react";
import Moment from "react-moment";

import { formatters } from "../../repos/constants";

export class FormSideLabel extends Component {
  render() {
    let { value, type } = this.props;

    return (
      <div className={"form-side-label"}>
        <>
          <i className="fa-solid fa-square-caret-left"></i>
          {value === null && <span>(blank)</span>}
          {value !== null && type === 'string' && <span>{String(value)}</span>}
          {value !== null && type === 'datetime' &&
            <Moment date={value} format={formatters.datetime} />
          }
        </>
      </div>
    )
  }
}

FormSideLabel.defaultProps = {
  type: 'string',
}
