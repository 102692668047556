import ReactModal from "react-modal";

import { useAuth } from "../auth/AuthProvider";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";

import { CustomerProfileRow } from "./CustomerListPage";
import { TableEmptyRow } from "../shared/DataTable";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import "../shared/Modals.css";

export const CustomerListModal = ({
                                    isOpen, isLoading,
                                    onRequestClose,
                                    customerProfiles,
                                    pagination,
                                    onCustomerRowClicked,
                                    onPageClicked,
                                    onPrevPageClicked,
                                    onNextPageClicked,
                                  }) => {
  //#region States
  const auth = useAuth();
  //#endregion

  //#region Utilities
  //#endregion

  //#region Render
  return (
    <ReactModal isOpen={isOpen} id={"customer-profiles-modal"}
                className={"popup-modal"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onRequestClose}>
      <div className={"modal-heading"}>
        <h2>Customer Profiles</h2>
      </div>
      {isLoading &&
        <div className={"modal-loading-indicator"}>
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      }
      {!isLoading &&
        <>
          <div className={"modal-floated-controls"}>
            <button type={"button"} className={"btn btn-light"} onClick={onRequestClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className={"modal-contents"}>
            <div>
              Select a customer profile from the table below to fill up the <b>Customer Details</b> form fields automatically.
            </div>
          </div>

          <div className={"modal-data-table inquiry-table"}>
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th>Customer Name</th>
                <th>Company Name</th>
                <th>Business Type</th>
                <th>Contact Name</th>
                <th>Contact No</th>
                <th>Designation</th>
              </tr>
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={7} />}

              {customerProfiles && customerProfiles['data'].length > 0 &&
                customerProfiles['data'].map((customer, index) =>
                  <CustomerProfileRow key={customer['id']} customer={customer} index={index}
                                      currentPage={customerProfiles['pagination']['currentPage']} pageSize={customerProfiles['pagination']['pageSize']}
                                      onRowClicked={onCustomerRowClicked}
                  />
                )}

              {!customerProfiles || (customerProfiles['data'].length === 0 &&
                <TableEmptyRow colSpan={7} />
              )}
              </tbody>
            </table>

            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={DEFAULT_LIST_PAGE_SIZE}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClicked}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
              />
            }
          </div>
        </>
      }
    </ReactModal>
  )
  //#endregion
}
