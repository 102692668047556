import { refs } from "./constants";
import { role } from "./constants";

export const cancelNeedsReview = (cancelPermissionLevel, entry) => {
  if (cancelPermissionLevel !== role.cancelLevels.reviewer) return false;
  let _cancelStatus = entry['cancelStatus'];
  if (_cancelStatus === null) return false;
  if (_cancelStatus === refs.sales.reviewStatus.approved || _cancelStatus === refs.sales.reviewStatus.rejected) return false;
  return true;
}

export const cancelHasBeenReviewed = (cancelPermissionLevel, entry) => {
  if (cancelPermissionLevel !== role.cancelLevels.reviewer) return false;
  if (entry === null) return false;
  let _cancelStatus = entry['cancelStatus'];
  if (_cancelStatus === null) return false;
  if (_cancelStatus === refs.sales.reviewStatus.approved || _cancelStatus === refs.sales.reviewStatus.rejected) return true;
  return false;
}

export const shouldExpandCancelSection = (entry) => {
  let shouldExpand = false;
  let _cancelStatus = entry['cancelStatus'];
  if (_cancelStatus &&
    (_cancelStatus === refs.sales.reviewStatus.pendingReview ||
      _cancelStatus === refs.sales.reviewStatus.approved || _cancelStatus === refs.sales.reviewStatus.rejected)) {
    shouldExpand = true;
  }
  return shouldExpand;
}

export const isEntryCanceled = (entry) => {
  if (entry === null) return false;
  let _entryCanceled = false;
  let _cancelStatus = entry['cancelStatus'];
  if (_cancelStatus &&
    (_cancelStatus === refs.sales.reviewStatus.approved || _cancelStatus === refs.sales.reviewStatus.rejected)) {
    _entryCanceled = true;
  }
  let _reviewStatus = entry['reviewStatus'];
  if (_reviewStatus === refs.sales.reviewStatus.canceled) {
    _entryCanceled = true;
  }
  return _entryCanceled;
}
