import React from "react";
import Moment from "react-moment";

import { formatters } from "../../repos/constants";

import { JobStatusBadge } from "./JobStatusBadge";
import { ActorNameRankDisplay } from "../sales/ActorNameDisplay";
import { ReadonlyField } from "../shared/ReadonlyField";

import { EMPTY_GUID } from "../../repos/constants";

export const ServiceEntrySignature = ({
                                        jobStatus,
                                        testedBy,
                                        checkedBy, checkedDate,
                                      }) => {
  return (
    <div className={"form-section"}>
      <h2>Form Signatures</h2>

      {/* TODO(yemon): Standardize that 'gridAutoFlow' property */}
      <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
        <div className={"form-label"}>
          <label htmlFor={"testedBy"}>
            Status:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"testedBy"}>
            Tested/Submitted By:
          </label>
        </div>

        {checkedBy && checkedBy['id'] !== EMPTY_GUID &&
          <div className={"form-label"}>
            <label htmlFor={"testedBy"}>
              Checked/Reviewed By:
            </label>
          </div>
        }

        {checkedDate && checkedBy['id'] !== EMPTY_GUID &&
          <div className={"form-label"}>
            <label htmlFor={"testedBy"}>
              Checked/Reviewed Date:
            </label>
          </div>
        }

        <div className={"form-input"}>
          <div className={"readonly-field"}>
            <JobStatusBadge status={jobStatus} />
          </div>
        </div>

        <div className={"form-input"}>
          <ReadonlyField>
            <ActorNameRankDisplay employee={testedBy} />
          </ReadonlyField>
        </div>

        {checkedBy && checkedBy['id'] !== EMPTY_GUID &&
          <div className={"form-input"}>
            <ReadonlyField>
              <ActorNameRankDisplay employee={checkedBy} />
            </ReadonlyField>
          </div>
        }

        {checkedDate && checkedBy['id'] !== EMPTY_GUID &&
          <div className={"form-input"}>
            <ReadonlyField>
              <Moment date={checkedDate} format={formatters.datetimeShort} />
            </ReadonlyField>
          </div>
        }

      </div>
    </div>
  )
}
