import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useAuth } from "../auth/AuthProvider";
import { formatters } from "../../repos/constants";
import { reportServices } from "../../repos/apiServices2";
import { navigableRoutes as routes } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { ActorPureNameDisplay } from "../sales/ActorNameDisplay";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { SortIndicator } from "../shared/SortIndicator";

import "../shared/ListingPage.css";
import "../shared/DataTable.css";


export const reportStatus = {
  draft: 0,
  active: 1,
}

export const ReportsListPageView = ({
                                      reportType, entryPageUrl,
                                      listingTitle, pageBreadcrumbs,
                                    }) => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchReportsList();
  }, []);

  const fetchReportsList = () => {
    const payload = {
      'userId': auth.getUserId()['eid'],
      'reportType': reportType,
    };

    setIsLoading(true);
    reportServices.fetchReportsList(payload)
      .then((response) => {
        const responseData = response['data'];
        setReports(responseData['data']);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchReportsList();
  }

  const onNewReportClicked = (ev) => {
    navigate(entryPageUrl);
  }

  const onReportItemClicked = (ev, reportId) => {
    navigate(entryPageUrl, {
      state: {
        'reportId': reportId,
      }
    });
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            {pageBreadcrumbs}
          </div>

          <div className={"row"}>
            <h1>{listingTitle}</h1>

            <div className={"listing-controls"}>
              <div className={"left"}>
                <button className={"btn btn-secondary"} disabled={isLoading}
                        onClick={onRefreshClicked}>
                  {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>

                <button className={"btn btn-primary"} disabled={isLoading}
                        onClick={onNewReportClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New Report
                </button>
              </div>
            </div>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <ReportItemsTableHeading />
              </thead>
              <tbody>
              {isLoading && reports &&
                <TableLoadingIndicator colspan={4} />
              }

              {!isLoading && reports && reports.length > 0 &&
                reports.map((report, index) =>
                  <ReportItemRow key={report['id']} index={index}
                                 report={report} onItemClicked={onReportItemClicked} />
                )
              }

              {!isLoading && reports && reports.length === 0 &&
                <TableEmptyRow colSpan={4} />
              }
              </tbody>
            </table>
          </div>

        </div>

      </main>
    </MasterPageContainer>
  )
  //#endregion
}

export const ReportItemsTableHeading = ({}) => {
  return (
    <tr>
      <th scope={"col"} className={"index-col-head"}>#</th>
      <th scope={"col"}>
        Title
      </th>
      {/*<th>*/}
      {/*  Locked?*/}
      {/*</th>*/}
      <th scope={"col"}>
        Created by
      </th>
      <td scope={"col"}>
        Created datetime
        <SortIndicator isAscending={false} />
      </td>
    </tr>
  )
}

export const ReportItemRow = ({ index, report, onItemClicked }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {index + 1}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           title={"Click to view the report"} onClick={(ev) => onItemClicked(ev, report["id"])}>
          {report["title"]}
        </a>
      </td>
      {/*<td>*/}
      {/*  <LockedIndicator isLocked={report["isLocked"]} />*/}
      {/*</td>*/}
      <td>
        <ActorPureNameDisplay employeeId={report["createdById"]}
                              employeeName={report["createdByFullName"]} />
      </td>
      <td>
        <Moment date={report["createdDatetime"]} format={formatters.datetimeShort} />
      </td>
    </tr>
  )
}

export const LockedIndicator = ({ isLocked }) => {
  return (
    <span title={isLocked === true ? "This report is already locked." : ""}>
    {isLocked === true &&
      <i className={"fa-solid fa-lock"} />
    }
      {isLocked === false &&
        <>&bull;</>
      }
    </span>
  )
}

export const validateReportDateRanges = (fromDate, toDate) => {
  let validation = {
    hasErrors: false,
    fromDateError: '',
    toDateError: '',
  };

  if (!fromDate) {
    validation.hasErrors = true;
    validation.fromDateError = "Both dates have to be specified.";
  }
  if (!toDate) {
    validation.hasErrors = true;
    validation.toDateError = "Both dates have to be specified.";
  }

  if (fromDate && toDate) {
    if (toDate < fromDate) {
      validation.hasErrors = true;
      validation.toDateError = "'To Date' has to be the same or later than the 'From Date'.";
    }
  }

  return validation;
}

