import React from "react";

import { roundFloat } from "../../repos/utilities";

export const NullBlankField = ({ record, fieldName }) => {
  return (
    <>
      {record[fieldName] !== null && record[fieldName] !== '' ? record[fieldName] : '-'}
    </>
  )
}

export const NullBlankValue = ({ value }) => {
  return (
    <>
      {value !== null && value !== '' ? value : '-'}
    </>
  )
}

export const NullBlankFloatValue = ({ value, fractions = 2, stripInteger = true }) => {
  if (value !== null && value !== '') {
    return (
      <>
        {Number.isInteger(value) && stripInteger ? value : roundFloat(value, fractions)}
      </>
    )
  }
  else {
    return (
      <>-</>
    )
  }
}
