import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { EMPTY_GUID } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ControlsGrid } from "../shared/ControlsGrid";
import { ControlsGroup } from "../shared/ControlsGrid";
import { ControlsItems } from "../shared/ControlsGrid";
import { ControlButton } from "../shared/ControlsGrid";

import "../shared/ListingPage.css";


export const ManageUsersPage = () => {
  //#region Control handlers
  const navigate = useNavigate();

  const onClickUserAccounts = (ev) => {
    navigate(routes.userAccountsSetup.url);
  }

  const onClickRolesPermissions = (ev) => {
    navigate(routes.rolesPermissionsSetup.url);
  }

  const onClickRanks = (ev) => {
    navigate(routes.ranksSetup.url);
  }

  const onClickGroups = (ev) => {
    navigate(routes.groupsSetup.url);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.manageUsersAndPermissions.display} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.manageUsersAndPermissions.displayShort}</h1>
          </div>

          <ControlsGrid>
            <ControlsGroup title={"Access Controls"} />
            <ControlsItems>
              <ControlButton icon={"fa-user"} label={"User Accounts"}
                             disabled={false} onClick={onClickUserAccounts} />
              <ControlButton icon={"fa-unlock"} label={"Roles & Permissions"}
                             disabled={false} onClick={onClickRolesPermissions} />
            </ControlsItems>

            <ControlsGroup title={"Employee Structure"} />
            <ControlsItems>
              <ControlButton icon={"fa-id-badge"} label={"Ranks"}
                             disabled={false} onClick={null} />
              <ControlButton icon={"fa-building"} label={"Branches"}
                             disabled={false} onClick={null} />
              <ControlButton icon={"fa-user-group"} label={"Groups"}
                             disabled={false} onClick={onClickGroups} />
            </ControlsItems>
          </ControlsGrid>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
