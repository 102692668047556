import React from "react";

import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { getEmptyGensetSuggestion } from "../../repos/searchUtilities";
import { getLeftPadding } from "../../repos/searchUtilities";
import { expandSuggestionsResult } from "../../repos/searchUtilities";

import { AutoCompleteResults } from "../shared/AutoCompleteResults";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { EmployeeBranchGroupsDropdown } from "../shared/EmployeesDropdown";

export const OrderConfirmationSearchPanel = ({
                                               isSearchLoading, isListLoading,
                                               customerName, onCustomerNameChanged, companyName, onCompanyNameChanged, contactNo, onContactNoChanged,
                                               gensetModelId, gensetModel, onGensetModelChanged, onGensetModelSuggestionClicked,
                                               paymentTermId, onPaymentTermIdChanged,
                                               reviewStatus, onReviewStatusChanged,
                                               dateFrom, onDateFromChanged, dateTo, onDateToChanged,
                                               reporterId, onReporterIdChanged,
                                               currencyCode, onCurrencyChanged,
                                               onSearchClicked, onClearClicked, onExportExcelClicked, isExporting,
                                               hasErrors, errorMessage,
                                             }) => {
  //#region States
  const [isLoadingReporters, setIsLoadingReporters] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);
  const [paymentTermOptions, setPaymentTermOptions] = useState([]);
  const [reviewStatusOptions, setReviewStatusOptions] = useState([]);

  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchSalesUsers();
    loadPaymentTerms();
    loadReviewStatuses();
  }, []);

  const fetchSalesUsers = () => {
    setIsLoadingReporters(true);
    refServices2.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _responseData = response['data'];
        setEmployeeBranchGroups(_responseData);
      })
      .finally(() => setIsLoadingReporters(false));
  }

  const loadPaymentTerms = () => {
    setPaymentTermOptions(refs.sales.paymentTermOptions);
  }

  const loadReviewStatuses = () => {
    setReviewStatusOptions(refs.sales.reviewStatusOptions);
  }
  //#endregion

  //#region Utilities
  const getDateClassName = (isToDate = false) => {
    let className = "form-control md-field";
    if (dateFrom && !isToDate) className += " has-autocompleted-value";
    else if (dateTo && isToDate) className += " has-autocompleted-value";
    return className;
  }

  const getGensetModelClassName = () => {
    let className = "form-control sm-field";
    if (gensetModelId) className += " has-autocompleted-value";
    return className;
  }

  const isFormControlsDisabled = () => {
    return isSearchLoading || isListLoading || isExporting;
  }
  //#endregion

  //#region Control handlers
  let _prevGensetModelTerm = '';
  let _gensetModelTerm = '';
  const onGensetModelTermFieldChanged = (ev) => {
    _prevGensetModelTerm = _gensetModelTerm.trim();
    _gensetModelTerm = ev.target.value.trim();

    let isGensetModelChanged = onGensetModelChanged(ev, _gensetModelTerm, _prevGensetModelTerm);
    if (!isGensetModelChanged) return;

    if (_gensetModelTerm.length >= 2) {
      refServices2.searchGenerators(_gensetModelTerm)
        .then((response) => {
          let suggestions = [getEmptyGensetSuggestion(), ...response.data];
          setGeneratorSuggestions(suggestions);
          expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
        });
    } else {
      setGeneratorSuggestions([]);
    }
  }

  const onGensetModelTermBlurred = (ev) => {
    setTimeout(() => {
      setGeneratorSuggestions([]);
    }, 200);
  }

  const onGensetModelFieldClicked = (ev) => {
    setGeneratorSuggestions([getEmptyGensetSuggestion()]);
    expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
  }

  const onGensetModelClicked = (ev, generator) => {
    onGensetModelSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
  }
  //#endregion

  //#region Render
  return (
    <div className={"search-panel"}>
      <form onSubmit={onSearchClicked}>
        <div className={"search-fields"}>
          <input type={"text"} id={"searchCustomerName"} className={"form-control md-field"} autoComplete={"off"}
                 disabled={isFormControlsDisabled()} placeholder={"Customer name"}
                 value={customerName} onChange={onCustomerNameChanged} />
          <input type={"text"} id={"searchCompanyName"} className={"form-control md-field"} autoComplete={"off"}
                 disabled={isFormControlsDisabled()} placeholder={"Company name"}
                 value={companyName} onChange={onCompanyNameChanged} />
          <input type={"text"} id={"searchContactNo"} className={"form-control sm-field"} autoComplete={"off"}
                 disabled={isFormControlsDisabled()} placeholder={"Contact no."}
                 value={contactNo} onChange={onContactNoChanged} />
          <input type={"text"} id={"searchGensetModel"} className={getGensetModelClassName()} autoComplete={"off"}
                 placeholder={"Genset model"} disabled={isFormControlsDisabled()}
                 value={gensetModel} onChange={onGensetModelTermFieldChanged} onBlur={onGensetModelTermBlurred}
                 onClick={onGensetModelFieldClicked}
          />
          {generatorSuggestions && generatorSuggestions.length > 0 &&
            <AutoCompleteResults id={'gensetModelSuggestions'} style={{ width: '400px' }}
                                 collection={generatorSuggestions} itemDisplayField={'gensetModel'}
                                 onItemClicked={onGensetModelClicked} />
          }
        </div>

        <div className={"search-fields"}>
          <div className={"date-field from-date-field"}>
            <DatePicker id={"dateFrom"} className={getDateClassName(false)}
                        placeholderText={"Date from."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={dateFrom} onChange={onDateFromChanged} />
          </div>
          <div className={"date-field"}>
            <DatePicker id={"dateTo"} className={getDateClassName(true)}
                        placeholderText={"Date to."} required={false} autoComplete={"off"} showWeekNumbers
                        dateFormat={formatters.datetimePickerShort} todayButton={"Today"} disabled={isFormControlsDisabled()}
                        selected={dateTo} onChange={onDateToChanged} />
          </div>
          <select name={"searchPaymentTerm"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={paymentTermId} onChange={(ev) => onPaymentTermIdChanged(parseInt(ev.target.value))}>
            <option value={0}>- Any payment term -</option>
            {paymentTermOptions && paymentTermOptions.map(term => <option key={term['id']} value={term['id']}>{term['name']}</option>)}
          </select>
          <div className={"hide-mobile"}>
            <select name={"searchReviewStatus"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                    value={reviewStatus} onChange={(ev) => onReviewStatusChanged(parseInt(ev.target.value))}>
              <option value={-1}>- Any status -</option>
              {reviewStatusOptions && reviewStatusOptions.map(status => <option key={status['id']} value={status['id']}>{status['name']}</option>)}
            </select>
          </div>
        </div>

        <div className={"search-fields hide-mobile"}>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"searchReporter"} name={"searchReporter"}
                                        isDisabled={isFormControlsDisabled() || isLoadingReporters}
                                        value={reporterId} onChange={(ev) => onReporterIdChanged(ev.target.value)} />
          <select name={"currency"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={currencyCode} onChange={(ev) => onCurrencyChanged(parseInt(ev.target.value))}>
            <option value={0}>- Any currency -</option>
            {Object.entries(refs.currency).map(([key, item], index) =>
              <option key={key} value={item.code}>{item.name} ({item.sign})</option>
            )}
          </select>
        </div>
        <div className={"search-fields show-mobile"}>
          <select name={"searchReviewStatus"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={reviewStatus} onChange={(ev) => onReviewStatusChanged(parseInt(ev.target.value))}>
            <option value={-1}>- Any status -</option>
            {reviewStatusOptions && reviewStatusOptions.map(status => <option key={status['id']} value={status['id']}>{status['name']}</option>)}
          </select>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"searchReporterMobile"} name={"searchReporterMobile"}
                                        isDisabled={isFormControlsDisabled() || isLoadingReporters}
                                        value={reporterId} onChange={(ev) => onReporterIdChanged(ev.target.value)} />
          <select name={"currency"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled()}
                  value={currencyCode} onChange={(ev) => onCurrencyChanged(parseInt(ev.target.value))}>
            <option value={0}>- Any currency -</option>
            {Object.entries(refs.currency).map(([key, item], index) =>
              <option key={key} value={item.code}>{item.name} ({item.sign})</option>
            )}
          </select>
        </div>

        <div className={"search-fields"}>
          <FieldErrorMessage visible={hasErrors} message={errorMessage} />
        </div>

        <div className={"search-controls"}>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
          <button type={"button"} className={"btn btn-secondary"} style={getLeftPadding()}
                  disabled={isFormControlsDisabled()} onClick={onExportExcelClicked}>
            {isExporting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isExporting && <i className="fa-solid fa-file-excel"></i>}
            Export Excel
          </button>
        </div>
      </form>
    </div>
  )
  //#endregion
}
