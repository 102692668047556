import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { roundFloat } from "../../repos/utilities";

import { ReadonlyField } from "../shared/ReadonlyField";
import { HourMinutesPicker } from "../shared/TimePickers";
import { MinutesPicker } from "../shared/TimePickers";
import { formatHourMinutes } from "../shared/TimePickers";
import { formatMinutes } from "../shared/TimePickers";

const fractions = 2;

export const ServiceEntryArrivalTestDetails = ({
                                          engineOil, onEngineOilChanged,
                                          coolantWater, onCoolantWaterChanged,
                                          fuel, onFuelChanged,
                                          runHours, onRunHoursChanged,
                                          runHoursMin, onRunHoursMinChanged,
                                          oilPressure, onOilPressureChanged,
                                          waterTemperature, onWaterTemperatureChanged,
                                          engineRPM, onEngineRPMChanged,
                                          testingPeriodMin, onTestingPeriodMinChanged,
                                          dcVoltage, onDCVoltageChanged,
                                          isLoading, isEditable, isSubmitting,
                                        }) => {
  //#region States
  //#endregion

  //#region Effects
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <div className={"form-section"}>
      <h2>Test Details</h2>

      <div className={"entry-form arrival-inspection-form test-details-section"}>
        <div className={"form-label"}>
          <label htmlFor={"engineOil"}>
            Engine Oil:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"coolantWater"}>
            Coolant Water:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"fuel"}>
            Fuel:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"runHours"}>
            Run Hours:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"oilPressure"}>
            Oil Pressure:
          </label>
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField>{engineOil === '' ? '-' : roundFloat(engineOil, fractions)} Liters</ReadonlyField>
          }
          {isEditable &&
            <>
              <input type={"text"} id={"engineOil"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={engineOil} onChange={onEngineOilChanged} />
              <div className={"form-field-message right-aligned"}>
                Liters
              </div>
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField>{coolantWater === '' ? '-' : roundFloat(coolantWater, fractions)} Liters</ReadonlyField>
          }
          {isEditable &&
            <>
              <input type={"text"} id={"coolantWater"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={coolantWater} onChange={onCoolantWaterChanged} />
              <div className={"form-field-message right-aligned"}>
                Liters
              </div>
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable &&
            <ReadonlyField>{fuel === '' ? '-' : roundFloat(fuel, fractions)} Liters</ReadonlyField>
          }
          {isEditable &&
            <>
              <input type={"text"} id={"fuel"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={fuel} onChange={onFuelChanged} />
              <div className={"form-field-message right-aligned"}>
                Liters
              </div>
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{formatHourMinutes(runHours, runHoursMin)}</ReadonlyField>}
          {isEditable &&
            <>
              <HourMinutesPicker hourId={"runHoursHr"} minuteId={"runHoursMin"} disabled={isLoading || isSubmitting}
                                 hour={runHours} onHourChanged={onRunHoursChanged}
                                 minute={runHoursMin} onMinuteChanged={onRunHoursMinChanged} />
              <div className={"form-field-message right-aligned"}>
                hr:min
              </div>
            </>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{oilPressure === '' ? '-' : roundFloat(oilPressure, fractions)} Bar</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"oilPressure"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={oilPressure} onChange={onOilPressureChanged} />
              <div className={"form-field-message right-aligned"}>
                Bar
              </div>
            </>
          }
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"waterTemp"}>
          Water Temperature:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"engineRPM"}>
            Engine RPM:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"testingPeriod"}>
            Testing Period:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"dcVoltage"}>
            DC Voltage:
          </label>
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{waterTemperature === '' ? '-' : roundFloat(waterTemperature, fractions)} &deg;C</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"waterTemp"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={waterTemperature} onChange={onWaterTemperatureChanged} />
              <div className={"form-field-message right-aligned"}>
                &deg;C
              </div>
            </>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{engineRPM === '' ? '-' : roundFloat(engineRPM, fractions)} r/min</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"engineRPM"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={engineRPM} onChange={onEngineRPMChanged} />
              <div className={"form-field-message right-aligned"}>
                r/min
              </div>
            </>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{formatMinutes(testingPeriodMin)}</ReadonlyField>}
          {isEditable &&
            <>
              <MinutesPicker id={"testingPeriodMin"} disabled={isLoading || isSubmitting}
                             minute={testingPeriodMin} onMinuteChanged={onTestingPeriodMinChanged} />
              <div className={"form-field-message right-aligned"}>
                min
              </div>
            </>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{dcVoltage === '' ? '-' : roundFloat(dcVoltage, fractions)} V</ReadonlyField>}
          {isEditable &&
            <>
              <input type={"text"} id={"dcVoltage"} className={"form-control"}
                     autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                     value={dcVoltage} onChange={onDCVoltageChanged} />
              <div className={"form-field-message right-aligned"}>
                V
              </div>
            </>
        }
      </div>

    </div>
</div>
)
  //#endregion
}
