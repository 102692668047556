import React from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formModes } from "../../repos/constants";
import { formatCurrency } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";

import { ReadonlyField } from "../shared/ReadonlyField";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { AutoCompleteGroupedResults } from "../shared/AutoCompleteResults";
import { EntryGeneratorsDetailsTable } from "./EntryGeneratorsDetailsTable";

export const EntryGeneratorsDetails = ({
                                         editingEntry, editingDetailsEntry, formMode, currency, orderConfirmationDetails,
                                         gensetOrAtsKeyword, gensetModelId, atsId,
                                         onGensetOrAtsKeywordChanged, onGeneratorSuggestionClicked, onAtsSuggestionClicked,
                                         selectedItemLabel, selectedItemInfo, isGeneratorSuggested,
                                         type, onTypeChanged,
                                         gensetPrice, onGensetPriceChanged, onGensetPriceBlurred,
                                         quantity, onQuantityChanged, onQuantityBlurred,
                                         gensetAmount,
                                         isSubmitting, isSubmittingDetails, hasErrors, formErrors, isLoading,
                                         isFormSubmitted, isFormDraft, isFormOwner, isDetailsEditing, hasEditPermission, shouldRenderInputFields,
                                         onAddClicked, onItemRowSelected, onEditClicked, onClearClicked, onUpdateClicked, onCancelClicked, onRemoveClicked,
                                       }) => {
  //#region States
  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);
  const [atsSuggestions, setAtsSuggestions] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  //#endregion

  //#region Utilities
  const isFormElementsDisabled = () => {
    return isSubmitting || isSubmittingDetails || formMode === formModes.orderConfirmation.editCustomer;
  }

  const isFormEditMode = () => {
    return editingDetailsEntry !== null;
  }

  const shouldRenderGeneratorInputFields = () => {
    //if (isFormDraft() || hasEditPermission()) return true;
    if (editingDetailsEntry === null) return true;
    else if (isDetailsEditing) return true;
    else return false;
  }

  const getGensetOrAtsClassName = () => {
    let className = "form-control";
    if (gensetModelId || atsId) className += " has-autocompleted-value";
    return className;
  }

  const hasSuggestionResults = () => {
    return generatorSuggestions.length > 0 || atsSuggestions.length > 0;
  }

  //#endregion

  //#region Control handlers
  let _prevKeyword = '';
  let _keyword = '';
  const onKeywordFieldChanged = (ev) => {
    _prevKeyword = _keyword.trim();
    _keyword = ev.target.value.trim();

    if (_keyword.trim() === '') {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }
    let isKeywordChanged = onGensetOrAtsKeywordChanged(ev, _keyword, _prevKeyword);
    if (!isKeywordChanged) return;

    if (_keyword.length >= 2) {
      refServices2.searchGeneratorsOrATS(_keyword)
        .then((response) => {
          let _generators = response.data['generators'];
          let _atsItems = response.data['atsItems'];
          setGeneratorSuggestions(_generators);
          setAtsSuggestions(_atsItems);
          expandSuggestionResults();
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
          setAtsSuggestions([]);
        })
    } else {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }
  }

  const onKeywordFieldBlurred = (ev) => {
    // a small delay to make sure that the selected suggestion is to be assigned properly to gensetModel state
    setTimeout(() => {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }, 200);
  }

  const expandSuggestionResults = () => {
    setTimeout(() => {
      let fieldElement = document.getElementById('gensetModel');
      let suggestionsElement = document.getElementById('gensetOrAtsSuggestionResults');
      if (suggestionsElement) suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
    }, 100);
  }

  const onGeneratorItemClicked = (ev, generator) => {
    onGeneratorSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
    setAtsSuggestions([]);
  }

  const onAtsItemClicked = (ev, ats) => {
    onAtsSuggestionClicked(ev, ats);
    setGeneratorSuggestions([]);
    setAtsSuggestions([]);
  }
  //#endregion

  //#region Render
  return (
    <>
      {formMode !== formModes.orderConfirmation.newEntry &&
        <div className={"form-section"}>
          <h2>Product Details</h2>

          {!isFormSubmitted() && isFormOwner() &&
            <div className={"entry-form purchase-agreement-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"gensetModel"}>
                  Genset Model (or ATS):<span className={"error-message"}>*</span>
                </label>
              </div>
              <div className={"form-input"}>
                {shouldRenderGeneratorInputFields() ?
                  <>
                    <input type={"text"} id={"gensetModel"} className={getGensetOrAtsClassName()} autoComplete={"off"}
                           disabled={isFormElementsDisabled()}
                           value={gensetOrAtsKeyword} onChange={onKeywordFieldChanged} onBlur={onKeywordFieldBlurred}
                           placeholder={"Type keyword to search..."} />
                    {hasSuggestionResults() &&
                      <AutoCompleteGroupedResults id={"gensetOrAtsSuggestionResults"} style={{ width: 400 }}
                                                  collections={[generatorSuggestions, atsSuggestions]}
                                                  collectionLabels={["GENERATORS", "ATS"]}
                                                  collectionDisplayFields={["gensetModel", "name"]}
                                                  collectionItemClickHandlers={[onGeneratorItemClicked, onAtsItemClicked]} />
                    }
                    <FieldErrorMessages visible={hasErrors} messages={formErrors['gensetModelKeyword']} />
                  </>
                  :
                  <ReadonlyField>
                    {gensetOrAtsKeyword}
                  </ReadonlyField>
                }
              </div>

              <div className={"form-label"}>
                {selectedItemLabel}:
              </div>
              <div className={"form-input"}>
                <ReadonlyField>
                  {selectedItemInfo && selectedItemInfo.length > 0 ?
                    selectedItemInfo.map(item => <>{item}<br /></>) : <>-</>
                  }
                </ReadonlyField>
              </div>

              {isGeneratorSuggested &&
                <>
                  <div className={"form-label"}>
                    <label htmlFor={"type"}>
                      Generator Type:
                    </label>
                  </div>
                  <div className={"form-input"}>
                    {shouldRenderGeneratorInputFields() ?
                      <>
                        <label className={"option-field"}>
                          <input type={"radio"} id={"generatorType"} name={"generatorType"} disabled={isFormElementsDisabled()}
                                 value={refs.inventory.generatorType.open} checked={type === refs.inventory.generatorType.open}
                                 onChange={onTypeChanged} />
                          Open
                        </label>
                        <label className={"option-field"}>
                          <input type={"radio"} id={"generatorType"} name={"generatorType"} disabled={isFormElementsDisabled()}
                                 value={refs.inventory.generatorType.canopy} checked={type === refs.inventory.generatorType.canopy}
                                 onChange={onTypeChanged} />
                          Canopy
                        </label>
                      </>
                      :
                      <ReadonlyField>
                        {refs.inventory.generatorType[type]}
                      </ReadonlyField>
                    }
                  </div>
                </>
              }

              <div className={"form-label"}>
                <label htmlFor={"gensetPrice"}>
                  Price ({getCurrency(currency).sign}):<span className={"error-message"}>*</span>
                </label>
              </div>
              <div className={"form-input"}>
                {shouldRenderGeneratorInputFields() ?
                  <>
                    <input type={"text"} id={"gensetPrice"} className={"form-control"} autoComplete={"none"} disabled={isFormElementsDisabled()}
                           value={gensetPrice} onChange={onGensetPriceChanged} onBlur={onGensetPriceBlurred} />
                    <FieldErrorMessages visible={hasErrors} messages={formErrors['gensetPrice']} />
                  </>
                  :
                  <ReadonlyField>
                    {formatCurrency(parseFloat(gensetPrice))}
                  </ReadonlyField>
                }
              </div>

              <div className={"form-label"}>
                <label htmlFor={"quantity"}>
                  Quantity:<span className={"error-message"}>*</span>
                </label>
              </div>
              <div className={"form-input"}>
                {shouldRenderGeneratorInputFields() ?
                  <>
                    <input type={"text"} id={"quantity"} className={"form-control"} autoComplete={"none"} disabled={isFormElementsDisabled()}
                           value={quantity} onChange={onQuantityChanged} onBlur={onQuantityBlurred} />
                    <FieldErrorMessages visible={hasErrors} messages={formErrors['quantity']} />
                  </>
                  :
                  <ReadonlyField>
                    {quantity}
                  </ReadonlyField>
                }
              </div>

              <div className={"form-label"}>
                <label>
                  Amount ({getCurrency(currency).sign}):
                </label>
              </div>
              <div className={"form-input"}>
                <ReadonlyField>{gensetAmount}</ReadonlyField>
              </div>
            </div>
          }

          {isFormOwner() &&
            <div className={"form-button-controls"}>
              <div className={"left-side"}>
                {isFormDraft() && !isFormEditMode() &&
                  <button type={"button"} className={"btn btn-primary"} disabled={isFormElementsDisabled()}
                          onClick={onAddClicked}>
                    {isSubmittingDetails && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                    {!isSubmittingDetails && <i className={"fa-solid fa-plus"}></i>}
                    <span>Add</span>
                  </button>
                }
                {isFormDraft() && isFormEditMode() && !isDetailsEditing &&
                  <>
                    <button type={"button"} className={"btn btn-secondary"} disabled={isFormElementsDisabled()}
                            onClick={onEditClicked}>
                      <i className="fa-solid fa-pen"></i>
                      <span>Edit</span>
                    </button>
                    <button type={"button"} className={"btn btn-secondary"} disabled={isFormElementsDisabled()}
                            onClick={onClearClicked}>
                      <i className="fa-solid fa-xmark"></i>
                      <span>Clear</span>
                    </button>
                  </>
                }
                {isFormDraft() && isFormEditMode() && isDetailsEditing &&
                  <>
                    <button type={"button"} className={"btn btn-primary"} disabled={isFormElementsDisabled()}
                            onClick={onUpdateClicked}>
                      {isSubmittingDetails && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                      {!isSubmittingDetails && <i className="fa-solid fa-check"></i>}
                      Update
                    </button>
                    <button type={"button"} className={"btn btn-secondary"} disabled={isFormElementsDisabled()}
                            onClick={onCancelClicked}>
                      <i className="fa-solid fa-xmark"></i>
                      Cancel
                    </button>
                  </>
                }
              </div>
            </div>
          }
        </div>
      }

      <EntryGeneratorsDetailsTable editingEntry={editingEntry} isLoading={isLoading} orderConfirmationDetails={orderConfirmationDetails}
                                   isFormOwner={isFormOwner} isFormSubmitted={isFormSubmitted} currency={currency}
                                   onItemRowSelected={onItemRowSelected} onItemRemoveClicked={onRemoveClicked}
      />
    </>
  )
  //#endregion
}
