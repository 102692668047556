import React from "react";

import { reportTypes } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";

import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReportsListPageView } from "../reports/Reports";

export const WarrantyStatusesReportsListPage = () => {
  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs>
        <BreadcrumbItem text={routes.serviceReports.displayShort} anchorTo={routes.serviceReports.url} />
        <BreadcrumbItem text={routes.serviceReports_WarrantyStatuses.displayShort} isActive={true} />
      </Breadcrumbs>
    )
  }

  return (
    <ReportsListPageView reportType={reportTypes.service.warrantyStatusByDuration}
                         entryPageUrl={routes.serviceReports_WarrantyStatusesReport.url}
                         listingTitle={routes.serviceReports_WarrantyStatuses.display}
                         pageBreadcrumbs={renderBreadcrumbs()} />
  )
}
