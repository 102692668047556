/// Both the price value and termPercent array elements should be validated and sanitized before calling this.
export const breakdownPaymentTerm = (price, termPercents) => {
  let totalPercent = termPercents.reduce((_, current) => _ += current);
  let amounts = [];
  for (let i = 0; i < termPercents.length; i++) {
    amounts[i] = (price * (termPercents[i] / 100)).toFixed(2);
  }

  if (totalPercent < 100) {
    let remainingPercent = 100 - totalPercent;
    let amount = (price * (remainingPercent / 100)).toFixed(2);
    amounts.push(amount);
  }

  return amounts;
}

export const breakdownInstallments = (amount, months) => {
  let breakdown = [];
  let average = amount / months;
  for (let i = 0; i < months; i++) {
    breakdown[i] = average.toFixed(2);
  }
  return breakdown;
}
