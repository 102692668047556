import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { technicalServices } from "../../repos/apiServices2";
import { authService } from "../../repos/apiServices";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { viewSettings } from "../../repos/viewContexts";
import { getRegionDisplayByName } from "../../repos/utilities";
import { isUnderWarrantyFromStatus } from "../../repos/generatorUtilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { ServiceEntryGenerator } from "./ServiceEntryGenerator";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { JobStatusBadge } from "./JobStatusBadge";
import { NewServiceJobModal } from "./NewServiceJobModal";
import { NewContactLogModal } from "./NewContactLogModal";
import { CommissionContractModal } from "./CommissionContractModal";
import { SortIndicator } from "../shared/SortIndicator";
import { InfoMessage } from "../sales/FormMessages";
import { WarningMessage } from "../sales/FormMessages";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";


const commissionTypes = refs.inventory.serviceGeneratorCommissionType;
const warrantyStatuses = refs.inventory.serviceGeneratorWarrantyStatus;
const serviceHistoryTypes = refs.inventory.serviceHistoryType;
const serviceTypes = refs.inventory.serviceType;
const careContactTypes = refs.customer.contactType;
const commissionStatuses = refs.inventory.serviceGeneratorCommissionStatus;


export function ServiceGeneratorPage() {
  //#region States
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedGeneratorId, setSelectedGeneratorId] = useState(null);

  const [isLoadingGenerator, setIsLoadingGenerator] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [gensetModel, setGensetModel] = useState("");
  const [generatorSerial, setGeneratorSerial] = useState("");
  const [manufacturerId, setManufacturerId] = useState(refs.inventory.generatorManufacturers.AKSA);
  const [alternatorSerial, setAlternatorSerial] = useState("");
  const [alternatorModel, setAlternatorModel] = useState("");
  const [alternatorId, setAlternatorId] = useState(refs.inventory.generatorAlternators.unspecified);
  const [machineSerial, setMachineSerial] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [machineId, setMachineId] = useState(refs.inventory.generatorMachines.unspecified);
  const [controllerSerial, setControllerSerial] = useState("");
  const [controllerModel, setControllerModel] = useState("");
  const [controllerId, setControllerId] = useState(refs.inventory.generatorControllers.unspecified);

  const [warrantyHours, setWarrantyHours] = useState(null);
  const [warrantyMonths, setWarrantyMonths] = useState(null);
  const [warrantyStatus, setWarrantyStatus] = useState(null);
  const [commissionType, setCommissionType] = useState(commissionTypes.na);

  const [serviceGenerator, setServiceGenerator] = useState(null);
  const [isLoadingCommissions, setIsLoadingCommissions] = useState(false);
  const [serviceGeneratorCommissions, setServiceGeneratorCommissions] = useState([]);
  const [isLoadingJobHistory, setIsLoadingJobHistory] = useState(false);
  const [serviceJobHistory, setServiceJobHistory] = useState([]);
  const [pagination, setPagination] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.serviceProfiles.url);
    }
    let _customerId = state['customerId'];
    let _profileId = state['profileId'];
    let _generatorId = state['generatorId'];
    if (!_customerId || !_profileId) {
      navigate(routes.serviceProfiles.url);
    }
    else if (!_generatorId) {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': _customerId,
          'serviceProfileId': _profileId,
        }
      });
    }
    else {
      fetchUserPermissions();

      setSelectedCustomerId(_customerId);
      setSelectedProfileId(_profileId);
      setSelectedGeneratorId(_generatorId);
      fetchServiceGenerator(_profileId, _generatorId);
    }
  }, []);

  const fetchServiceGenerator = (profileId, generatorId) => {
    let params = {
      'uid': auth.getUserId(),
      'service_profile_id': profileId,
      'service_generator_id': generatorId,
    };
    setIsLoadingGenerator(true);
    setIsLoadingCommissions(true);
    setIsLoadingJobHistory(true);
    technicalServices.fetchServiceProfileGenerator(params)
      .then((response) => {
        let _serviceGenerator = response['data'];
        let _serviceProfile = _serviceGenerator['serviceLocation']['profile'];
        setSelectedCustomerId(_serviceProfile['customer']['id']);
        setSelectedProfileId(_serviceProfile['id']);
        setSelectedGeneratorId(_serviceGenerator['id'])

        setServiceGenerator(_serviceGenerator);
        prepareProfileAndGeneratorDetails(_serviceGenerator);

        let _currentCommissionId = _serviceGenerator['commissionId'];
        setSelectedCommissionId(_currentCommissionId);
        loadCommissionsAndJobHistories(_serviceProfile['id'], _serviceGenerator['id'], _currentCommissionId);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404 || errorResponse.status === 400) {
            navigate(routes.serviceProfiles.url);
          }
        }
        setIsLoadingCommissions(false);
        setIsLoadingJobHistory(false);
      })
      .finally(() => {
        setIsLoadingGenerator(false);
      })
  }

  const prepareProfileAndGeneratorDetails = (serviceGenerator) => {
    if (!serviceGenerator) {
      return;
    }
    let _serviceLocation = serviceGenerator['serviceLocation'];
    let _serviceProfile = _serviceLocation['profile'];

    setCustomerName(_serviceProfile['customer']['customerName']);
    setContactFullName(_serviceLocation['contactFullName']);
    setContactNo(_serviceLocation['contactNo']);

    setGeneratorSerial(serviceGenerator['generatorSerial']);
    setGensetModel(serviceGenerator['gensetModel']);
    setManufacturerId(serviceGenerator['manufacturerId']);
    setAlternatorSerial(serviceGenerator['alternatorSerial']);
    setAlternatorModel(serviceGenerator['alternatorModel']);
    setAlternatorId(serviceGenerator['alternatorId']);
    setMachineSerial(serviceGenerator['machineSerial']);
    setMachineModel(serviceGenerator['machineModel']);
    setMachineId(serviceGenerator['machineId']);
    setControllerSerial(serviceGenerator['controllerSerial']);
    setControllerModel(serviceGenerator['controllerModel']);
    setControllerId(serviceGenerator['controllerId']);

    setStockId(serviceGenerator['id']);

    setWarrantyStatus(serviceGenerator['warrantyStatus']);
    setWarrantyHours(serviceGenerator['warrantyHours']);
    setWarrantyMonths(serviceGenerator['warrantyMonths']);
    setCommissionType(serviceGenerator['commissionType']);
  }

  const getWarrantySpecifications = () => {
    let specs = [];
    let label = "";
    if (warrantyHours) {
      label = "hour";
      if (warrantyHours > 1) {
        label += "s";
      }
      specs.push(`${warrantyHours} ${label}`);
    }
    if (warrantyMonths) {
      label = "month";
      if (warrantyMonths > 1) {
        label += "s";
      }
      specs.push(`${warrantyMonths} ${label}`);
    }
    return specs.join(", ");
  }

  const loadCommissionsAndJobHistories = (serviceProfileId, serviceGeneratorId, currentCommissionId) => {
    fetchServiceGeneratorCommissions(serviceGeneratorId);

    // TODO(yemon): Apply the listing context
    fetchJobHistory(serviceProfileId, serviceGeneratorId, currentCommissionId, 1);
  }

  const fetchServiceGeneratorCommissions = (serviceGeneratorId) => {
    let payload = {
      'service_generator_id': serviceGeneratorId
    };
    inventoryServices.fetchServiceGeneratorCommissions(payload)
      .then((response) => {
        const responseData = response['data'];
        const generatorCommissions = responseData['data'];
        if (generatorCommissions && generatorCommissions.length > 0) {
          setServiceGeneratorCommissions(generatorCommissions);
          prepareCommissionInfoModalDetails(generatorCommissions[0]);
        }
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
      })
      .finally(() => {
        setIsLoadingCommissions(false);
      });
  }

  const prepareListPayload = (page) => {
    //let settings = getViewSettings(viewSettings.salesInquiries);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        //'pageSize': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
        'page_size': DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const fetchJobHistory = (serviceProfileId, serviceGeneratorId, selectedCommissionId, page) => {
    resetListingStates();

    let payload = prepareListPayload(page);
    payload['service_profile_id'] = serviceProfileId;
    payload['service_generator_id'] = serviceGeneratorId;
    payload['commission_id'] = selectedCommissionId;
    inventoryServices.fetchServiceGeneratorJobHistory(payload)
      .then((response) => {
        updateListingStates(response['data']);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
      })
      .finally(() => {
        setIsLoadingJobHistory(false);
      });
  }

  const updateListingStates = (responseData) => {
    setServiceJobHistory(responseData['data']);
    setPagination(responseData['pagination']);
    // TODO(yemon): Update listing context
  }

  const resetListingStates = () => {
    setServiceJobHistory([]);
    setPagination(null);
  }
  //#endregion

  //#region States; User permissions
  const [allowStockEdit, setAllowStockEdit] = useState(false);
  const [allowCareContactLog, setAllowCareContactLog] = useState(false);

  const fetchUserPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response['data']['service'];
        setAllowStockEdit(_servicePermissions['allowProfileStockEdit']);
        setAllowCareContactLog(_servicePermissions['allowCareContactLog']);
      });
  }
  //#endregion

  //#region Control handlers
  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': selectedCustomerId,
          'serviceProfileId': selectedProfileId,
        }
      });
    }, 200);
  }

  const onReturnClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': selectedCustomerId,
          'serviceProfileId': selectedProfileId,
        }
      });
    }, 200);
  }

  const onRefreshClicked = (ev) => {
    fetchUserPermissions();
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, 1);
  }

  const onJobHistoryRowClicked = (ev, serviceJob) => {
    ev.preventDefault();

    const historyTypes = refs.inventory.serviceHistoryType;
    const serviceTypes = refs.inventory.serviceType;
    const careContactType = refs.customer.contactType;

    let historyType = serviceJob['historyType'];
    let serviceType = serviceJob['serviceType'];
    let serviceGeneratorId = serviceJob['serviceGeneratorId'];
    let serviceHistoryId = serviceJob['id'];

    if (historyType === historyTypes.serviceHistory) {
      switch (serviceType) {
        case serviceTypes.tnc:
          return navigateToServiceHistoryEntry(routes.testingAndCommission.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.pm:
          return navigateToServiceHistoryEntry(routes.preventiveMaintenance.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.regular:
          return navigateToServiceHistoryEntry(routes.regularService.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.repair:
          return navigateToServiceHistoryEntry(routes.repairService.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.emergency:
          return navigateToServiceHistoryEntry(routes.emergencyBreakdown.url, serviceGeneratorId, serviceHistoryId);
        case serviceTypes.inspection:
          return navigateToServiceHistoryEntry(routes.inspection.url, serviceGeneratorId, serviceHistoryId);
        default:
          break;
      }
    }
    else if (historyType === historyTypes.contactHistory) {
      return navigateToCareContactEntry(routes.contactLogEntry.url, serviceGeneratorId, serviceHistoryId);
    }
  }

  const navigateToServiceHistoryEntry = (url, serviceGeneratorId, serviceHistoryId) => {
    setTimeout(() => {
      navigate(url, {
        state: {
          'serviceProfileId': selectedProfileId,
          'serviceGeneratorId': serviceGeneratorId,
          'serviceHistoryId': serviceHistoryId,
        }
      });
    }, 200);
  }

  const navigateToCareContactEntry = (url, serviceGeneratorId, serviceHistoryId) => {
    setTimeout(() => {
      navigate(url, {
        state: {
          'serviceProfileId': selectedProfileId,
          'serviceGeneratorId': serviceGeneratorId,
          'serviceHistoryId': serviceHistoryId,
        }
      });
    }, 200);
  }

  const onPageClick = (page) => {
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, page);
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, page);
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, page);
  }
  //#endregion

  //#region Generator Details section; States & Control handlers for updating the Stock
  const [stockId, setStockId] = useState(null);

  const [isEditingStock, setIsEditingStock] = useState(false);
  const [isSubmittingStock, setIsSubmittingStock] = useState(false);
  const [originalStockDetails, setOriginalStockDetails] = useState({});
  const [hasStockErrors, setHasStockErrors] = useState(false);
  const [stockErrors, setStockErrors] = useState({});

  const [isCheckingStockUsage, setIsCheckingStockUsage] = useState(false);
  const [stockUsage, setStockUsage] = useState({});
  const [stockUsageCount, setStockUsageCount] = useState(1);

  const onEditStockClicked = (ev) => {
    setIsEditingStock(true);
    setOriginalStockDetails({
      'manufacturer': manufacturerId,
      'alternatorSerial': alternatorSerial,
      'alternatorModel': alternatorModel,
      'alternator': alternatorId,
      'machineSerial': machineSerial,
      'machineModel': machineModel,
      'machine': machineId,
      'controllerSerial': controllerSerial,
      'controllerModel': controllerModel,
      'controller': controllerId,
    });

    setIsCheckingStockUsage(true);
    inventoryServices.checkStockUsage({ 'stock_id': stockId })
      .then((response) => {
        const data = response['data'];
        setStockUsage(data['stockUsage']);
        setStockUsageCount(data['count']);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsCheckingStockUsage(false);
      });
  }

  const onSaveStockClicked = (ev) => {
    setIsSubmittingStock(true);

    let payload = {
      'stock_id': stockId,
      'manufacturer': manufacturerId,
      'alternator_serial': alternatorSerial ? alternatorSerial.trim() : null,
      'alternator_model': alternatorModel ? alternatorModel.trim() : null,
      'alternator': alternatorId,
      'machine_serial': machineSerial ? machineSerial.trim() : null,
      'machine_model': machineModel ? machineModel.trim() : null,
      'machine': machineId,
      'controller_serial': controllerSerial ? controllerSerial.trim() : null,
      'controller_model': controllerModel ? controllerModel.trim() : null,
      'controller': controllerId,
      'employee_id': auth.getUserId()['eid'],
    }
    inventoryServices.updateStockDetails(payload)
      .then((response) => {
        //const data = response['data'];
        setIsEditingStock(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmittingStock(false);
      });
  }

  const onCancelStockClicked = (ev) => {
    setIsEditingStock(false);
    setIsSubmittingStock(false);

    setIsCheckingStockUsage(false);
    setStockUsage({});
    setStockUsageCount(0);

    if (!originalStockDetails) {
      return;
    }
    setAlternatorSerial(originalStockDetails['alternatorSerial']);
    setAlternatorModel(originalStockDetails['alternatorModel']);
    setAlternatorId(originalStockDetails['alternator']);
    setMachineSerial(originalStockDetails['machineSerial']);
    setMachineModel(originalStockDetails['machineModel']);
    setMachineId(originalStockDetails['machine']);
    setControllerSerial(originalStockDetails['controllerSerial']);
    setControllerModel(originalStockDetails['controllerModel']);
    setControllerId(originalStockDetails['controller']);
  }

  const getStockUsageInfoSection = () => {
    if (!isEditingStock) {
      return <></>
    }

    return (
      <div className={"form-message"}>
        {isCheckingStockUsage &&
          <InfoMessage>
            Identifying usage...
          </InfoMessage>
        }
        {!isCheckingStockUsage && stockUsageCount === 0 &&
          <InfoMessage>
            This generator has <b><i>never been</i></b> commissioned or used before. Its details can be <b>safely updated</b>.
          </InfoMessage>
        }

        {!isCheckingStockUsage && stockUsageCount > 0 &&
          <WarningMessage>
            This generator stock is commissioned (associated) with <b>{stockUsageCount}</b> {stockUsageCount === 1 ? "Service Profile" : "Service Profiles"}.<br />
            Any changes you have made here will be effected to all Generators under {stockUsageCount === 1 ? "that Service Profile" : "those Service Profiles"}.

            {/*<div style={{ marginTop: '8px' }}>*/}
            {/*  <button type={"button"} className={"btn btn-outline-secondary"}*/}
            {/*          onClick={null}>*/}
            {/*    View...*/}
            {/*  </button>*/}
            {/*</div>*/}
          </WarningMessage>
        }
      </div>
    )
  }
  //#endregion

  //#region New Service Job modal;
  const [isNewServiceModalOpened, setIsNewServiceModalOpened] = useState(false);

  const onNewServiceJobClicked = (ev) => {
    newServiceModal.onOpenButtonClicked();
  }

  const newServiceModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsNewServiceModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsNewServiceModalOpened(false);
      }, 200);
    },
  }
  //#endregion

  //#region New Contact Log modal;
  const [isNewContactModalOpened, setIsNewContactModalOpened] = useState(false);

  const onNewContactLogClicked = (ev) => {
    newContactModal.onOpenButtonClicked();
  }

  const newContactModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(false);
      }, 200);
    },
  }
  //#endregion

  //#region Recommission (or) Contract modal;
  const [isCommissionContractModalOpened, setIsCommissionContractModalOpened] = useState(false);

  const onCommissionContractClicked = (ev) => {
    commissionContractModal.onOpenButtonClicked();
  }

  const commissionContractModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsCommissionContractModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsCommissionContractModalOpened(false);
      }, 200);
    },
  }

  const onRecommissionSuccessful = (newCommission) => {
    fetchServiceGenerator(selectedProfileId, selectedGeneratorId);
  }
  //#endregion

  //#region Commissions dropdown and Commission Info modal
  const [isCommissionInfoModalOpened, setIsCommissionInfoModalOpened] = useState(false);
  const [selectedCommissionId, setSelectedCommissionId] = useState(null);
  const [infoCommissionType, setInfoCommissionType] = useState(commissionTypes.pti);
  const [infoCommissionDate, setInfoCommissionDate] = useState(null);
  const [infoWarrantyMonths, setInfoWarrantyMonths] = useState(0);
  const [infoWarrantyHours, setInfoWarrantyHours] = useState("");
  const [infoCreatedBy, setInfoCreatedBy] = useState(null);
  const [infoCreatedDatetime, setInfoCreatedDatetime] = useState(null);

  const onCommissionChanged = (ev) => {
    if (!serviceGeneratorCommissions || serviceGeneratorCommissions.length === 0) {
      return;
    }
    let _commissionId = ev.target.value;
    setSelectedCommissionId(_commissionId);

    setIsLoadingJobHistory(true);
    fetchJobHistory(selectedProfileId, selectedGeneratorId, _commissionId, 1);

    let _commission = serviceGeneratorCommissions.find((c) => c['id'] === _commissionId);
    prepareCommissionInfoModalDetails(_commission);
  }

  const prepareCommissionInfoModalDetails = (commission) => {
    if (!commission) {
      return;
    }
    setInfoCommissionType(commission['commissionType']);
    setInfoCommissionDate(commission['commissionDate']);
    setInfoWarrantyMonths(commission['warrantyMonths']);
    setInfoWarrantyHours(commission['warrantyHours']);
    setInfoCreatedBy(commission['createdBy']);
    setInfoCreatedDatetime(commission['createdDatetime']);
  }

  const onCommissionInfoClicked = (ev) => {
    commissionInfoModal.onOpenButtonClicked();
  }

  const commissionInfoModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsCommissionInfoModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsCommissionInfoModalOpened(false);
      }, 200);
    },
  }
  //#endregion

  //#region View Settings modal and view context related
  const onViewSettingsSaved = () => {
    fetchJobHistory(selectedProfileId, selectedGeneratorId, selectedCommissionId, 1);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={generatorSerial} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>[{gensetModel}] {generatorSerial}</h1>
          </div>

          <div className={"form-section"}>
            <h2>Contact & Commission Details</h2>

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Address:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Region:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactFullName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-name"} title={"Location name"}>{serviceGenerator['serviceLocation']['name']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-address"} title={"Location address"}>{serviceGenerator['serviceLocation']['address']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div title={"Designated region (City / Township)"}>
                        {getRegionDisplayByName(serviceGenerator['serviceLocation']['cityName'], serviceGenerator['serviceLocation']['townshipName'])}
                      </div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Commission/Installation Date:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Commission Type:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Warranty Specification:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Warranty Status:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {serviceGenerator &&
                    <Moment date={serviceGenerator['commissionDate']} format={formatters.datetimeShort} />
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {commissionTypes[commissionType]}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {getWarrantySpecifications()}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {warrantyStatus && refs.inventory.serviceGeneratorWarrantyStatus[isUnderWarrantyFromStatus(warrantyStatus)]}
                </ReadonlyField>
              </div>

            </div>
          </div>

          <ServiceEntryGenerator gensetModel={gensetModel} onGensetModelChanged={null}
                                 generatorSerial={generatorSerial} onGeneratorSerialChanged={null}
                                 generator={manufacturerId} onGeneratorChanged={(ev) => setManufacturerId(parseInt(ev.target.value))}
                                 alternatorSerial={alternatorSerial} onAlternatorSerialChanged={(ev) => setAlternatorSerial(ev.target.value)}
                                 alternatorModel={alternatorModel} onAlternatorModelChanged={(ev) => setAlternatorModel(ev.target.value)}
                                 alternator={alternatorId} onAlternatorChanged={(ev) => setAlternatorId(parseInt(ev.target.value))}
                                 machineSerial={machineSerial} onMachineSerialChanged={(ev) => setMachineSerial(ev.target.value)}
                                 machineModel={machineModel} onMachineModelChanged={(ev) => setMachineModel(ev.target.value)}
                                 machine={machineId} onMachineChanged={(ev) => setMachineId(parseInt(ev.target.value))}
                                 controllerSerial={controllerSerial} onControllerSerialChanged={(ev) => setControllerSerial(ev.target.value)}
                                 controllerModel={controllerModel} onControllerModelChanged={(ev) => setControllerModel(ev.target.value)}
                                 controller={controllerId} onControllerChanged={(ev) => setControllerId(parseInt(ev.target.value))}
                                 isLoading={isLoadingGenerator} isEditable={isEditingStock} isSubmitting={isSubmittingStock}
                                 hasErrors={hasStockErrors} formErrors={stockErrors}
                                 messageSection={allowStockEdit ? getStockUsageInfoSection() : null}>
            {allowStockEdit &&
              <div className={"form-section-controls"}>
                {!isEditingStock &&
                  <button className={"btn btn-secondary"} onClick={onEditStockClicked}>
                    <i className={"fa-solid fa-pencil"}></i>Edit
                  </button>
                }
                {isEditingStock &&
                  <>
                    <button className={"btn btn-primary"} disabled={isSubmittingStock}
                            onClick={onSaveStockClicked}>
                      {isSubmittingStock && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                      {!isSubmittingStock && <i className={"fa-solid fa-check"}></i>}
                      Save
                    </button>
                    <button className={"btn btn-secondary"} disabled={isSubmittingStock}
                            onClick={onCancelStockClicked}>
                      <i className={"fa-solid fa-xmark"}></i>Cancel
                    </button>
                  </>
                }
              </div>
            }
          </ServiceEntryGenerator>

          <NewServiceJobModal isOpen={isNewServiceModalOpened}
                              onRequestClose={newServiceModal.onCloseButtonClicked}
          />
          <NewContactLogModal isOpen={isNewContactModalOpened}
                              onRequestClose={newContactModal.onCloseButtonClicked}
                              serviceProfileId={selectedProfileId} serviceGeneratorId={selectedGeneratorId}
          />

          <CommissionContractModal isOpen={isCommissionContractModalOpened}
                                   onRequestClose={commissionContractModal.onCloseButtonClicked}
                                   serviceProfileId={selectedProfileId} serviceGeneratorId={selectedGeneratorId}
                                   isInfoMode={false}
                                   onRecommissionSuccessful={onRecommissionSuccessful}
          />
          <CommissionContractModal isOpen={isCommissionInfoModalOpened}
                                   onRequestClose={commissionInfoModal.onCloseButtonClicked}
                                   serviceProfileId={selectedProfileId} serviceGeneratorId={selectedGeneratorId}
                                   isInfoMode={true}
                                   infoCommissionType={infoCommissionType} infoCommissionDate={infoCommissionDate}
                                   infoWarrantyMonths={infoWarrantyMonths} infoWarrantyHours={infoWarrantyHours}
                                   infoCreatedBy={infoCreatedBy} infoCreatedDatetime={infoCreatedDatetime}
          />

          <div className={"form-section"}>
            <h2>Job History</h2>

            <div className={"form-button-controls no-margin-bottom"}>
              <div className={"left-side wide-col"}>
                <button type={"button"} className={"btn btn-secondary"} disabled={isLoadingGenerator}
                        onClick={onReturnClicked}>
                  <i className={"fa-solid fa-arrow-left"}></i>
                  Return to Profile
                </button>
                <button className={"btn btn-secondary right-margin"} disabled={isLoadingGenerator}
                        onClick={onRefreshClicked}>
                  {isLoadingGenerator && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoadingGenerator && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>

                {/*<button className={"btn btn-primary"} disabled={isLoadingGenerator}*/}
                {/*        onClick={onNewServiceJobClicked}>*/}
                {/*  <i className="fa-solid fa-plus"></i>*/}
                {/*  New Service Job...*/}
                {/*</button>*/}
                {allowCareContactLog &&
                  <button className={"btn btn-primary"} disabled={isLoadingGenerator}
                          onClick={onNewContactLogClicked}>
                    <i className="fa-solid fa-plus"></i>
                    New Contact Log...
                  </button>
                }
              </div>
              <div className={"right-side narrow-col right-aligned"}>
                {/*<button className={"btn btn-secondary"} disabled={isLoadingGenerator}*/}
                {/*        onClick={onCommissionContractClicked}>*/}
                {/*  <i className={"fa-solid fa-arrow-right-arrow-left"}></i>*/}
                {/*  Transfer...*/}
                {/*</button>*/}
                <button className={"btn btn-secondary"} disabled={isLoadingGenerator}
                        onClick={onCommissionContractClicked}>
                  <i className={"fa-solid fa-file-contract"}></i>
                  Recommission / Contract...
                </button>
              </div>
            </div>

            <GeneratorCommissionsDropdown generatorCommissions={serviceGeneratorCommissions}
                                          selectedCommissionId={selectedCommissionId}
                                          onCommissionChanged={onCommissionChanged}
                                          onCommissionInfoClicked={onCommissionInfoClicked} />
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>
                  Date
                  <SortIndicator isAscending={false} />
                </th>
                <th scope={"col"}>History Type</th>
                <th scope={"col"}>Service/Contact Type</th>
                <th scope={"col"}>Submitted By</th>
                <th scope={"col"}>Status</th>
              </tr>
              </thead>
              <tbody>
              {isLoadingGenerator && <TableLoadingIndicator colspan={6} />}

              {serviceJobHistory && serviceJobHistory.length > 0 && !isLoadingJobHistory &&
                serviceJobHistory.map((serviceJob, index) =>
                  <JobHistoryRow serviceJob={serviceJob} index={index} key={serviceJob['id']}
                                 currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                 onRowClicked={onJobHistoryRowClicked}
                  />
                )
              }

              {!serviceJobHistory || (serviceJobHistory.length === 0 && !isLoadingGenerator &&
                <TableEmptyRow colSpan={6} />
              )}
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoadingGenerator}
                               viewSettingsNamespace={viewSettings.serviceGeneratorJobHistories}
                               onViewSettingsSaved={onViewSettingsSaved}
              />
            }
          </div>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const GeneratorCommissionsDropdown = ({
                                        generatorCommissions,
                                        selectedCommissionId, onCommissionChanged,
                                        onCommissionInfoClicked,
                                      }) => {
  const activeCommissions = () => {
    if (!generatorCommissions || generatorCommissions.length === 0) {
      return [];
    }
    return generatorCommissions.filter((commission) => commission['status'] === commissionStatuses.active);
  }

  const inactiveCommissions = () => {
    if (!generatorCommissions || generatorCommissions.length === 0) {
      return [];
    }
    return generatorCommissions.filter((commission) => commission['status'] === commissionStatuses.inactive);
  }

  const hasGeneratorCommissions = () => {
    return generatorCommissions && generatorCommissions.length > 0;
  }

  return (
    <div className={"entry-form service-profile-form"}>
      <div className={"form-label"}>
        <select id={"commissions"} name={"commissions"} className={"form-control form-select"}
                disabled={!hasGeneratorCommissions()}
                value={selectedCommissionId} onChange={onCommissionChanged}>
            {activeCommissions().length > 0 &&
              <optgroup key={0} label={"Active Commission"}>
                {activeCommissions() && activeCommissions().map((commission, _) =>
                  <option key={commission['id']} value={commission['id']}>
                    {commissionTypes[commission['commissionType']]}&nbsp;on&nbsp;
                    <Moment date={commission['commissionDate']} format={formatters.datetimeShort} />
                  </option>
                  )
                }
              </optgroup>
            }

            {inactiveCommissions().length > 0 &&
              <optgroup key={1} label={"Inactive Commissions"}>
                {inactiveCommissions() && inactiveCommissions().map((commission, _) =>
                  <option key={commission['id']} value={commission['id']}>
                    {commissionTypes[commission['commissionType']]}&nbsp;on&nbsp;
                    <Moment date={commission['commissionDate']} format={formatters.datetimeShort} />
                  </option>
                  )
                }
              </optgroup>
            }
        </select>
      </div>

      <div className={"form-input"} style={{ paddingTop: '6px' }}>
        <button className={"btn btn-sm btn-secondary"} style={{ minWidth: '100px' }}
                disabled={!hasGeneratorCommissions()} title={"Click to view the selected commission details."}
                onClick={onCommissionInfoClicked}>
          <i className={"fa-solid fa-circle-info"}></i>
          Info
        </button>
      </div>
    </div>
  )
}


const JobHistoryRow = ({
                         serviceJob, index,
                         currentPage, pageSize,
                         onRowClicked,
                       }) => {
  const isFollowupContactEntry = () => {
    if (!serviceJob) {
      return false;
    }
    if (serviceJob['historyType'] !== serviceHistoryTypes.contactHistory) {
      return false;
    }
    let _careContact = serviceJob['careContact'];
    return _careContact !== null && _careContact['followupServiceHistoryId'] !== null;
  }

  return (
    <tr>
      <td className={"index-col"}>{(pageSize * (currentPage - 1)) + (index + 1)}</td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, serviceJob)}>
          <Moment date={serviceJob['servicedDatetime']} format={formatters.datetimeShort} />
        </a>
      </td>
      <td>
        {serviceHistoryTypes[serviceJob['historyType']]}
      </td>
      <td>
        {serviceJob['historyType'] === serviceHistoryTypes.serviceHistory &&
          <span>{serviceTypes[serviceJob['serviceType']]}</span>
        }
        {serviceJob['historyType'] === serviceHistoryTypes.contactHistory &&
          <span>{careContactTypes[serviceJob['careContactType']]}</span>
        }
        {isFollowupContactEntry() &&
          <i className="fa-solid fa-link" style={{ color: 'gray', fontSize: '100%', marginLeft: '8px' }}></i>
        }
      </td>
      <td>
        <ActorNameDisplay employee={serviceJob['servicedBy']} />
      </td>
      <td>
        <JobStatusBadge status={serviceJob['status']} />
      </td>
    </tr>
  )
}
