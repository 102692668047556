import React from "react";
import { useState } from "react";
import { useRef } from "react";

import "./Accordion.css";


export const Accordion = ({
                            children
                          }) => {
  return (
    <>
      {children}
    </>
  )
}

export const AccordionSection = ({
                                   header,
                                   content
                                 }) => {
  const headerRef = useRef();
  const contentRef = useRef();

  const [isExpanded, setIsExpanded] = useState(false);

  const onHeaderClicked = (ev) => {
    ev.preventDefault();
    setIsExpanded(!isExpanded);
    if (headerRef.current) {
      headerRef.current.classList.toggle("collapsed");
    }
    if (contentRef.current) {
      contentRef.current.classList.toggle("collapsed");
    }
  }

  return (
    <div className={"accordion-section"}>
      <a ref={headerRef} href={"#"} role={"button"} className={"accordion-header collapsed"}
         onClick={onHeaderClicked}>
        {header}

        <div className={"indicator"}>
          {!isExpanded && <i className={"fa-solid fa-caret-left"}></i>}
          {isExpanded && <i className={"fa-solid fa-caret-down"}></i>}
        </div>
      </a>

      <div ref={contentRef} className={"accordion-content collapsed"}>
        {content}
      </div>
    </div>
  )
}

export const AccordionHeader = ({
                                  children,
                                }) => {
  return (
    <>{children}</>
  )
}

export const AccordionContent = ({
                                   children
                                 }) => {
  return (
    <>{children}</>
  )
}
