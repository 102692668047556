import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { expandSuggestionsResult } from "../../repos/searchUtilities";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { AutoCompleteResults } from "../shared/AutoCompleteResults";

import "../shared/SearchPanel.css";


const stockStatuses = refs.inventory.stockStatus;

export const StocksListSearchPanel = ({
                                        isSearchOpen, isSearchLoading, isListLoading,
                                        searchFields,
                                        onGeneratorSerialChanged,
                                        gensetModelTerm, onGensetModelTermChanged, onGensetModelSuggestionClicked,
                                        onStockStatusChanged,
                                        onSearchClicked, onClearClicked,
                                        hasErrors, errorMessage,
                                      }) => {
  //#region States
  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
  }, []);
  //#endregion

  //#region Utilities
  const getSearchPanelClass = () => {
    return "search-panel" + (isSearchOpen ? " search-panel-open" : " search-panel-hide");
  }

  const isFormControlsDisabled = () => {
    return isSearchLoading || isListLoading;
  }
  //#endregion

  //#region Control handlers
  const getGensetModelClassName = () => {
    let className = "form-control md-field";
    if (searchFields['genset_model_id']) className += " has-autocompleted-value";
    return className;
  }

  const getEmptyGensetSuggestion = () => {
    return {
      'id': '0',
      'gensetModel': '(No genset)',
    };
  }

  let _prevGensetModelTerm = '';
  let _gensetModelTerm = '';
  const onGensetModelTermFieldChanged = (ev) => {
    _prevGensetModelTerm = _gensetModelTerm.trim();
    _gensetModelTerm = ev.target.value.trim();

    let isGensetModelChanged = onGensetModelTermChanged(ev, _gensetModelTerm, _prevGensetModelTerm);
    if (!isGensetModelChanged) return;

    if (_gensetModelTerm.length >= 3) {
      refServices2.searchGenerators(_gensetModelTerm)
        .then((response) => {
          let suggestions = [getEmptyGensetSuggestion(), ...response['data']];
          setGeneratorSuggestions(suggestions);
          expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
        })
    }
    else {
      setGeneratorSuggestions([]);
    }
  }

  const onGensetModelTermBlurred = (ev) => {
    setTimeout(() => {
      setGeneratorSuggestions([]);
    }, 200);
  }

  const onGensetModelFieldClicked = (ev) => {
    setGeneratorSuggestions([getEmptyGensetSuggestion()]);
    expandSuggestionsResult('searchGensetModel', 'gensetModelSuggestions');
  }

  const onGensetModelClicked = (ev, generator) => {
    onGensetModelSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
  }
  //#endregion

  //#region Render
  return (
    <div className={getSearchPanelClass()}>
      <form onSubmit={onSearchClicked}>
        <div className={"search-fields"}>
          <input type={"text"} id={"searchGeneratorSerial"} className={"form-control md-field"}
                 autoComplete={"off"} disabled={isFormControlsDisabled()} placeholder={"Generator Serial"}
                 value={searchFields['generator_serial']} onChange={onGeneratorSerialChanged} />

          <input type={"text"} id={"searchGensetModel"} className={getGensetModelClassName()}
                 autoComplete={"off"} disabled={isFormControlsDisabled()} placeholder={"Genset model"}
                 value={gensetModelTerm} onChange={onGensetModelTermFieldChanged} onBlur={onGensetModelTermBlurred}
                 onClick={onGensetModelFieldClicked} />
          {generatorSuggestions && generatorSuggestions.length > 0 &&
            <AutoCompleteResults id={"gensetModelSuggestions"} style={{ width: '400px' }}
                                 collection={generatorSuggestions} itemDisplayField={'gensetModel'}
                                 onItemClicked={onGensetModelClicked} />
          }

          <select name={"searchStockStatus"} className={"form-control form-select md-field padded-left"}
                  disabled={isFormControlsDisabled()}
                  value={searchFields['stock_status']} onChange={(ev) => onStockStatusChanged(parseInt(ev.target.value))}>
            <option value={-1}>- Any status -</option>
            <option value={stockStatuses.draft}>{stockStatuses[stockStatuses.draft]}</option>
            <option value={stockStatuses.underInspection}>{stockStatuses[stockStatuses.underInspection]}</option>
            <option value={stockStatuses.available}>{stockStatuses[stockStatuses.available]}</option>
            <option value={stockStatuses.commissioned}>{stockStatuses[stockStatuses.commissioned]}</option>
          </select>
        </div>

        <div className={"search-fields"}>
          <FieldErrorMessage visible={hasErrors} message={errorMessage} />
        </div>

        <div className={"search-controls"}>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
        </div>
      </form>
    </div>
  )
  //#endregion
}
