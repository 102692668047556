import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";

import { authService } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { role } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { sanitizeCurrencyValue } from "../../repos/utilities";
import { formatCurrency } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";
import { getCurrencyDisplay } from "../../repos/utilities";
import { getInstallmentOptionDisplay } from "../../repos/utilities";
import { breakdownInstallments } from "../../repos/paymentTermUtilities";
import { breakdownPaymentTerm } from "../../repos/paymentTermUtilities";
import { calculateTotalAmount } from "./EntryGeneratorsDetailsTable";

import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { Footer } from "../shared/Footer";
import { Navigation } from "../shared/Navigation";
import { ReadonlyField } from "../shared/ReadonlyField";

import "../shared/EntryForm.css";
import "./PaymentBreakdownPage.css";

export const PaymentBreakdownPage = (props) => {
  const {} = props;

  //#region States
  const [entryPermissions, setEntryPermissions] = useState({});
  const [editingEntry, setEditingEntry] = useState({});
  const [paymentBreakdownEntry, setPaymentBreakdownEntry] = useState();

  const [totalAmount, setTotalAmount] = useState('');
  const [paymentTerm, setPaymentTerm] = useState('');
  const [termPercents, setTermPercents] = useState([]);
  const [installmentOption, setInstallmentOption] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [breakdowns, setBreakdowns] = useState([]);
  const [breakdownsTotal, setBreakdownsTotal] = useState(0);
  const [installmentsBreakdowns, setInstallmentsBreakdowns] = useState([]);
  const [installmentsTotal, setInstallmentsTotal] = useState(0);
  //#endregion

  //#region Hooks
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [formOwnerStatus, setFormOwnerStatus] = useState(refs.sales.isFormOwner.inapplicable);
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchPermissions();
    if (location.state !== null) {
      let entry = location.state;
      setEditingEntry(entry);

      if (entry['createdBy']['id'] === auth.getUserId()['eid']) {
        setFormOwnerStatus(refs.sales.isFormOwner.yes);
      }
      else {
        setFormOwnerStatus(refs.sales.isFormOwner.no);
      }

      fetchAndPrepareBreakdownForEditMode(entry);
    }
  }, []);

  const fetchAndPrepareBreakdownForEditMode = (orderConfirmationEntry) => {
    let orderConfirmationId = orderConfirmationEntry['entryId'];
    setIsSubmitting(true);
    salesServices.fetchOrderConfirmationPaymentBreakdown(orderConfirmationId)
      .then((response) => {
        if (response.data['error']) {
          // No payment breakdown present yet, so auto-calculate the total amount from entry details.
          setIsSubmitting(false);
          fetchDetailsAndCalculateTotal(orderConfirmationId);
          return;
        }

        let _paymentBreakdown = response.data['paymentBreakdown'];
        setPaymentBreakdownEntry(_paymentBreakdown);

        // set the form fields
        setTotalAmount(sanitizeCurrencyValue(_paymentBreakdown['gensetPrice']));
        setPaymentTerm(_paymentBreakdown['paymentTerm']);

        // set the breakdowns
        let _breakdowns = _paymentBreakdown['breakdowns'].map(item => item['amount'].toFixed(2));
        setBreakdowns(_breakdowns);
        setBreakdownsTotal(recalculateBreakdownTotal(_breakdowns));

        // split payment term percentages
        onPaymentTermBlurred(_paymentBreakdown['paymentTerm']);

        // set installments
        let _monthOption = _paymentBreakdown['installmentOption'];
        setInstallmentOption(_monthOption);
        if (_monthOption === 0) {
          setInstallmentsBreakdowns([]);
          return;
        }
        let _installmentBreakdowns = _paymentBreakdown['installmentBreakdowns'].map(item => item['amount'].toFixed(2));
        setInstallmentsBreakdowns(_installmentBreakdowns);
        setInstallmentsTotal(recalculateBreakdownTotal(_installmentBreakdowns));
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => setIsSubmitting(false));
  }

  const fetchDetailsAndCalculateTotal = (entryId) => {
    setIsSubmitting(true);
    salesServices.fetchOrderConfirmationDetails(entryId)
      .then((response) => {
        let _details = response.data['entryDetails'];
        if (!_details || _details.length === 0) return;
        setTotalAmount(sanitizeCurrencyValue(calculateTotalAmount(_details)));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const fetchPermissions = () => {
    setIsSubmitting(true);
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        setEntryPermissions(response.data['orderConfirmations']);
      })
      .finally(() => setIsSubmitting(false));
  }

  //#endregion

  //#region Control handlers
  const onGensetPriceChanged = (ev) => {
    setTotalAmount(ev.target.value);
  }

  const onGensetPriceBlurred = (ev) => {
    setTotalAmount(sanitizeCurrencyValue(ev.target.value.trim()));
  }

  const onPaymentTermChanged = (ev) => {
    setPaymentTerm(ev.target.value);
  }

  const onPaymentTermBlurred = (paymentTermValue) => {
    if (paymentTermValue === '') {
      setPaymentTerm('');
      setHasErrors(false);
      setFormErrors({});
      setTermPercents([]);
      setBreakdowns([]);
      return;
    }
    let _terms = paymentTermValue.split('/').map(item => item.trim());
    let _containsInvalidNums = false;
    let _containsExceededPercentage = false;
    let _total = 0;
    for (let term of _terms) {
      let nTerm = parseInt(term);
      if (isNaN(nTerm) || !isFinite(nTerm)) {
        _containsInvalidNums = true;
      }
      if (nTerm > 100) {
        _containsExceededPercentage = true;
      }

      if (!_containsInvalidNums && !_containsExceededPercentage) {
        _total += nTerm;
      }
    }
    let _isInvalidTotal = false;
    if (_total > 100) {
      _isInvalidTotal = true;
    }

    let _hasErrors = false;
    let _formErrors = {
      'paymentTerm': [],
    }
    if (_containsInvalidNums) {
      _hasErrors = true;
      _formErrors['paymentTerm'].push('One or more percentage values in the payment term are not valid.');
    }
    if (_containsExceededPercentage) {
      _hasErrors = true;
      _formErrors['paymentTerm'].push('The individual percentage values should not exceed 100.');
    }
    if (_isInvalidTotal) {
      _hasErrors = true;
      _formErrors['paymentTerm'].push('The total accumulated percentage should not exceed more than 100.');
    }

    if (_hasErrors) {
      setHasErrors(_hasErrors);
      setFormErrors(_formErrors);
      setTermPercents([]);
      setBreakdowns([]);
    } else {
      setHasErrors(false);
      setFormErrors({});
      let _nTerms = _terms.map(item => parseInt(item));
      setTermPercents(_nTerms);
      setPaymentTerm(_nTerms.join('/'));
    }
  }

  const onBreakdownValueChanged = (ev, index) => {
    let value = ev.target.value.trim();
    // should clone the array to make sure it doesn't get conflicted with React state handling (even though the arrays are mutable)
    let _breakdowns = [...breakdowns];
    _breakdowns[index] = value;
    setBreakdowns(_breakdowns);
  }

  const onBreakdownValueBlurred = (ev, index) => {
    // Sanitize the breakdowns array elements into floating point numbers
    let _breakdowns = [...breakdowns];
    let value = _breakdowns[index];
    let fValue = parseFloat(value);
    if (isNaN(fValue) || !isFinite(fValue)) {
      fValue = 0;
    }
    _breakdowns[index] = fValue.toFixed(2);
    setBreakdowns(_breakdowns);
    setBreakdownsTotal(recalculateBreakdownTotal(_breakdowns));
    setInstallmentOption(0);
  }

  const recalculateBreakdownTotal = (breakdownValues) => {
    let total = 0;
    for (let amount of breakdownValues) {
      total += parseFloat(amount);
    }
    return total;
  }

  const onBreakdownClicked = (ev) => {
    if (!isFormFieldsValid(false)) {
      return;
    }
    setTimeout(() => {
      setHasErrors(false);
      let _price = sanitizeCurrencyValue(totalAmount);
      let _termBreakdowns = breakdownPaymentTerm(_price, termPercents);
      setTotalAmount(_price);
      setBreakdowns(_termBreakdowns);
      setBreakdownsTotal(recalculateBreakdownTotal(_termBreakdowns));
      setInstallmentOption(0);
      setInstallmentsBreakdowns([]);
      setInstallmentsTotal(0);
    }, 200);
  }

  const onGoBackClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.orderConfirmationEntry.url, { state: editingEntry });
    }, 200);
  }

  const onInstallmentOptionChanged = (installmentOptionValue) => {
    let _monthOption = installmentOptionValue;
    setInstallmentOption(_monthOption);
    if (_monthOption === 0) {
      setInstallmentsBreakdowns([]);
      return;
    }
    let _installmentBreakdowns = breakdownInstallments(breakdowns[breakdowns.length - 1], _monthOption);
    setInstallmentsBreakdowns(_installmentBreakdowns);
    setInstallmentsTotal(recalculateBreakdownTotal(_installmentBreakdowns));
  }

  const onInstallmentValueChanged = (ev, index) => {
    let value = ev.target.value.trim();
    let _installments = [...installmentsBreakdowns];
    _installments[index] = value;
    setInstallmentsBreakdowns(_installments);
    setInstallmentsTotal(recalculateBreakdownTotal(_installments));
  }

  const onInstallmentValueBlurred = (ev, index) => {
    let _installments = [...installmentsBreakdowns];
    let fValue = parseFloat(_installments[index]);
    if (isNaN(fValue) || !isFinite(fValue)) {
      fValue = 0;
    }
    _installments[index] = fValue.toFixed(2);
    setInstallmentsBreakdowns(_installments);
    setInstallmentsTotal(recalculateBreakdownTotal(_installments));
  }

  const isFormFieldsValid = (validateBreakdowns = false) => {
    let _hasErrors = false;
    let _formErrors = {
      'gensetPrice': [],
      'paymentTerm': [],
    };
    if (!totalAmount || totalAmount === '') {
      _hasErrors = true;
      _formErrors['gensetPrice'].push('Genset price should not be left as blank.');
    }
    let _gensetPrice = parseFloat(totalAmount);
    if (_gensetPrice <= 0) {
      _hasErrors = true;
      _formErrors['gensetPrice'].push('Genset price should not be zero.');
    }

    if (!paymentTerm || paymentTerm === '') {
      _hasErrors = true;
      _formErrors['paymentTerm'].push('Payment Terms should not be left as blank.')
    }
    if (validateBreakdowns) {
      if (paymentTerm && breakdowns.length === 0) {
        _hasErrors = true;
        _formErrors['paymentTerm'].push('Make sure to breakdown the payment term by clicking the button below.');
      }
    }

    setHasErrors(_hasErrors);
    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const onSaveClicked = (ev) => {
    // validate the installment values to see if they amount to the last breakdown value
    if (!isFormFieldsValid()) return;

    let _orderConfirmationId = null;
    let _isEditMode = false;
    if (paymentBreakdownEntry) {
      // edit mode
      _isEditMode = true;
      _orderConfirmationId = paymentBreakdownEntry['orderConfirmationId'];
    } else {
      _orderConfirmationId = editingEntry['entryId'];
    }

    let employeeId = auth.getUserId()['eid'];
    let payload = {
      'order_confirmation_id': _orderConfirmationId,
      'genset_price': parseFloat(totalAmount),
      'payment_term': paymentTerm,
      'breakdowns': breakdowns.map(item => parseFloat(item)),
      'breakdowns_total': breakdownsTotal,
      'installment_option': installmentOption,
      'installments_breakdowns': installmentsBreakdowns.map(item => parseFloat(item)),
      'installments_total': installmentsTotal,
      'created_by_id': employeeId,
    }

    setIsSubmitting(true);
    if (!_isEditMode) {
      salesServices.saveOrderConfirmationPaymentBreakdown(payload)
        .then((response) => {
          setPaymentBreakdownEntry(response.data['paymentBreakdown']);
          navigate(routes.orderConfirmationEntry.url, { state: editingEntry });
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      payload['id'] = paymentBreakdownEntry['id'];
      salesServices.updateOrderConfirmationPaymentBreakdown(payload)
        .then((response) => {
          setPaymentBreakdownEntry(response.data['paymentBreakdown']);
          navigate(routes.orderConfirmationEntry.url, { state: editingEntry });
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => setIsSubmitting(false));
    }
  }
  //#endregion

  //#region Utilities
  const hasBreakdowns = () => {
    return breakdowns && breakdowns.length > 0;
  }

  const hasBreakdownWarning = () => {
    return parseFloat(totalAmount) !== breakdownsTotal;
  }

  const hasInstallmentBreakdowns = () => {
    return installmentOption !== 0 && installmentsBreakdowns && installmentsBreakdowns.length > 0;
  }

  const hasInstallmentTotalWarning = () => {
    if (!hasBreakdowns()) return false;
    return parseFloat(breakdowns[breakdowns.length - 1]) !== installmentsTotal;
  }

  const isFormOwner = () => {
    return formOwnerStatus === refs.sales.isFormOwner.yes;
  }

  const isFormDraft = () => {
    return editingEntry &&
      (editingEntry['reviewStatus'] === refs.sales.reviewStatus.draft || editingEntry['reviewStatus'] === refs.sales.reviewStatus.pendingReview);
  }

  const shouldRenderFormInput = () => {
    if (!isFormDraft()) return false;
    return entryPermissions['editLevel'] !== role.orderConfirmation.editLevels.readOnly;
  }
  //#endregion

  //#region Render
  return (
    <>
      <Navigation />

      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.orderConfirmationsList.displayShort} anchorTo={routes.orderConfirmationsList.url} />
              <BreadcrumbItem text={routes.orderConfirmationEntry.displayShort} isActive={true} />
              <BreadcrumbItem text={routes.orderConfirmationPaymentBreakdown.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Payment Term Breakdown</h1>
          </div>

          <div className={"row"}>
            <div className={"form-contents"}>
              Re-enter the <b>price</b> and <b>payment terms</b> below based on the given instructions.
            </div>
          </div>

          <form autoComplete="off">
            <div className={"form-section"}>
              <div className={"entry-form payment-breakdown-entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"gensetPrice"}>
                    Total Price ({getCurrency(editingEntry['currency']).sign}):<span className={"error-message"}>*</span>
                  </label>
                </div>
                <div className={"form-input"}>
                  {(isFormOwner() && shouldRenderFormInput()) ?
                    <>
                      <input type={"text"} id={"gensetPrice"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                             value={totalAmount} onChange={onGensetPriceChanged} onBlur={onGensetPriceBlurred} />
                      <FieldErrorMessages visible={hasErrors} messages={formErrors['gensetPrice']} />
                    </>
                    :
                    <ReadonlyField>
                      {formatCurrency(parseFloat(totalAmount))}
                    </ReadonlyField>
                  }
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"paymentTerm"}>
                    Payment Term:<span className={"error-message"}>*</span>
                  </label>
                </div>
                <div className={"form-input"}>
                  {(isFormOwner() && shouldRenderFormInput()) ?
                    <>
                      <input type={"text"} id={"paymentTerm"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                             value={paymentTerm} onChange={onPaymentTermChanged} onBlur={(ev) => onPaymentTermBlurred(ev.target.value)} />
                      <FieldErrorMessages visible={hasErrors} messages={formErrors['paymentTerm']} />
                      <div className={"info-message"}>
                        <ul>
                          <li>Enter the individual percentage (%) values separated by '/' (e.g., 60/20/20).</li>
                          <li>Entering just a single value will automatically identify the remaining portion. (e.g., 30 = 30/70)</li>
                          <li>If all the percents values do not accumulate to the total of 100, the remaining portion will also be automatically identified.
                            (e.g., 20/20 = 20/20/60)
                          </li>
                        </ul>
                      </div>
                    </>
                    :
                    <ReadonlyField>
                      {paymentTerm ? paymentTerm: '-'}
                    </ReadonlyField>
                  }
                </div>

                {shouldRenderFormInput() &&
                  <div className={"form-input"}>
                    <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                            onClick={onBreakdownClicked}>
                      <i className="fa-solid fa-bars-staggered"></i>
                      <span>Breakdown</span>
                    </button>
                  </div>
                }

                <div className={"form-label"}>
                  Breakdowns ({getCurrency(editingEntry['currency']).sign}):
                </div>
                {!hasBreakdowns() && !isFormOwner() &&
                  <div className={"form-input"}>
                    <div className={"instruction-message"}>
                      (No breakdowns specified yet)
                    </div>
                  </div>
                }
                {!hasBreakdowns() && isFormOwner() && shouldRenderFormInput() &&
                  <div className={"form-input"}>
                    <div className={"instruction-message"}>
                      (Click the button above after entering the payment term.)
                    </div>
                  </div>
                }

                {hasBreakdowns() && shouldRenderFormInput() &&
                  breakdowns.map((value, index) =>
                    <div key={index} className={"form-input"}>
                      <input type={"text"} className={"form-control"} disabled={isSubmitting}
                             value={value} onChange={(ev) => onBreakdownValueChanged(ev, index)}
                             onBlur={(ev) => onBreakdownValueBlurred(ev, index)} />
                    </div>
                  )
                }
                {hasBreakdowns() && !shouldRenderFormInput() &&
                  breakdowns.map((value, index) =>
                    <div key={index} className={"form-input"}>
                      <ReadonlyField>
                        {formatCurrency(parseFloat(value))}
                      </ReadonlyField>
                    </div>
                  )
                }
                {hasBreakdowns() && hasBreakdownWarning() &&
                  <div className={"form-input"}>
                    <div className={"warning-message"}>
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      <span>
                        The total accumulated value of the breakdown amounts does not match up with the genset price
                        it's trying to cover; <b>{formatCurrency(parseFloat(totalAmount))}</b>
                      </span>
                    </div>
                  </div>
                }

                <div className={"form-label"}>
                  {!hasBreakdowns() && <span>Installments:</span>}
                  {hasBreakdowns() &&
                    <span>
                      Installments on <b>{getCurrency(editingEntry['currency']).sign}
                      {formatCurrency(parseFloat(breakdowns[breakdowns.length - 1]))}</b>:
                    </span>
                  }
                </div>
                {!hasBreakdowns() && !isFormOwner() &&
                  <div className={"form-input"}>
                    <div className={"instruction-message"}>
                      (No installments specified yet)
                    </div>
                  </div>
                }
                {!hasBreakdowns() && isFormOwner() && shouldRenderFormInput() &&
                  <div className={"form-input"}>
                    <div className={"instruction-message"}>
                      (Breakdown the payment term first before specifying the installment plan.)
                    </div>
                  </div>
                }
                {hasBreakdowns() &&
                  <div className={"form-input"}>
                    {shouldRenderFormInput() &&
                      <select id={"installments"} name={"installments"} className={"form-control form-select"} disabled={isSubmitting}
                              value={installmentOption} onChange={(ev) => onInstallmentOptionChanged(ev.target.value)}>
                        {refs.sales.paymentInstallmentOptions.map((item, index) =>
                          <option key={index} value={item.id}>{item.name}</option>
                        )}
                      </select>
                    }
                    {!shouldRenderFormInput() &&
                      <ReadonlyField>
                        {getInstallmentOptionDisplay(installmentOption)}
                      </ReadonlyField>
                    }
                  </div>
                }

                {hasInstallmentBreakdowns() && shouldRenderFormInput() &&
                  installmentsBreakdowns.map((item, index) =>
                    <div key={index} className={"form-input"}>
                      <input type={"text"} className={"form-control"} value={item} disabled={isSubmitting}
                             onChange={(ev) => onInstallmentValueChanged(ev, index)}
                             onBlur={(ev) => onInstallmentValueBlurred(ev, index)} />
                    </div>
                  )
                }
                {hasInstallmentBreakdowns() && !shouldRenderFormInput() &&
                  installmentsBreakdowns.map((item, index) =>
                    <div key={index} className={"form-input"}>
                      <ReadonlyField>
                        {formatCurrency(parseFloat(item))}
                      </ReadonlyField>
                    </div>
                  )
                }
                {hasInstallmentBreakdowns() &&
                  <>
                    <div className={"form-label"}>
                      Total {getCurrency(editingEntry['currency']).sign}:
                    </div>
                    <div className={"form-input"}>
                      <ReadonlyField>
                        <div>{formatCurrency(parseFloat(installmentsTotal.toFixed(2)))}</div>
                      </ReadonlyField>
                      {hasInstallmentTotalWarning() &&
                        <div className={"warning-message"}>
                          <i className="fa-solid fa-triangle-exclamation"></i>
                          <span>
                          This total accumulated value of individual installments does not match up with the amount
                          it's trying to cover; <b>{breakdowns[breakdowns.length - 1]}</b>.
                        </span>
                        </div>
                      }
                    </div>
                  </>
                }
              </div>
            </div>

            <div className={"form-section"}>
              <div className={"form-button-controls"}>
                <div className={"left-side"}>
                  {shouldRenderFormInput() &&
                    <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                            onClick={onSaveClicked}>
                      {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                      {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                      <span>Save</span>
                    </button>
                  }
                  <button type={"button"} className={"btn btn-secondary"} onClick={onGoBackClicked}>
                    <i className={"fa-solid fa-arrow-left"}></i>
                    <span>Go back</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>

      <Footer />
    </>
  )
  //#endregion
}
