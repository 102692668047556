import { refs } from "../../repos/constants";
import { role } from "../../repos/constants";
import { formModes } from "../../repos/constants";
import { formatCurrency } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";

import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ReadonlyField } from "../shared/ReadonlyField";

export const EntryTermsAndConditions = ({
                                          editingEntry, formMode, currency,
                                          paymentTerm, onPaymentTermChanged,
                                          creditPaymentTerm, onCreditPaymentTermChanged,
                                          deliveryTerm, onDeliveryTermChanged,
                                          warrantyTerm, onWarrantyTermChanged,
                                          serviceTerm, onServiceTermChanged,
                                          deposit, onDepositChanged,
                                          remarks, onRemarksChanged,
                                          hasErrors, formErrors, isSubmitting,
                                          entryPermissions,
                                          onPaymentBreakdownClicked,
                                          isFormDraftOrReview, isFormOwner,
                                          hasEditPermission, shouldRenderInputFields,
                                        }) => {
  //#region States
  //#endregion

  //#region Effects
  //#endregion

  //#region Utilities
  const isFormFieldsDisabled = () => {
    return isSubmitting || formMode === formModes.orderConfirmation.editCustomer;
  }

  const isFormEditable = () => {
    return isFormDraftOrReview() && hasEditPermission() && isFormOwner();
  }
  //#endregion

  //#region Render
  return (
    <>
      {formMode !== formModes.orderConfirmation.newEntry &&
        <div className={"form-section"}>
          <h2>Terms and Conditions</h2>

          <div className={"entry-form purchase-agreement-entry-form"}>
            <div className={"form-label"}>
              <label htmlFor={"paymentTerm"}>
                Payment Term:
              </label>
            </div>
            <div className={"form-input"}>
              {isFormEditable() &&
                <>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"paymentTermType"} name={"paymentTermType"} disabled={isFormFieldsDisabled()}
                           value={refs.sales.paymentTerm.cash} checked={paymentTerm === refs.sales.paymentTerm.cash}
                           onChange={onPaymentTermChanged} />
                    Cash
                  </label>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"paymentTermType"} name={"paymentTermType"} disabled={isFormFieldsDisabled()}
                           value={refs.sales.paymentTerm.credit} checked={paymentTerm === refs.sales.paymentTerm.credit}
                           onChange={onPaymentTermChanged} />
                    Credit
                  </label>
                </>
              }
              {!isFormEditable() &&
                <ReadonlyField>{refs.sales.paymentTerm[paymentTerm]}</ReadonlyField>
              }
            </div>

            {paymentTerm === refs.sales.paymentTerm.credit &&
              <>
                <div className={"form-label"}>
                  <label htmlFor={"creditPaymentTerm"}>
                    Credit Payment Term:<span className={"error-message"}>*</span>
                  </label>
                </div>
                <div className={"form-input"}>
                  {isFormEditable() &&
                    <>
                      <input type={"text"} id={"paymentTerm"} className={"form-control"} autoComplete={"none"} disabled={isFormFieldsDisabled()}
                             value={creditPaymentTerm} onChange={onCreditPaymentTermChanged} />
                      <FieldErrorMessages visible={hasErrors} messages={formErrors['creditPaymentTerm']} />
                    </>
                  }
                  {!isFormEditable() &&
                    <ReadonlyField>
                      {creditPaymentTerm ? creditPaymentTerm : '-'}
                    </ReadonlyField>
                  }
                </div>
              </>
            }

            <div className={"form-input"}>
              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                      onClick={onPaymentBreakdownClicked}>
                <i className="fa-solid fa-list-check"></i>
                <span>Breakdown...</span>
              </button>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"deliveryTerm"}>
                Delivery:
              </label>
            </div>
            <div className={"form-input"}>
              {isFormEditable() &&
                <input type={"text"} id={"deliveryTerm"} className={"form-control"} autoComplete={"none"} disabled={isFormFieldsDisabled()}
                       value={deliveryTerm} onChange={onDeliveryTermChanged} maxLength={100} />
              }
              {!isFormEditable() &&
                <ReadonlyField>
                  {deliveryTerm ? deliveryTerm : '-'}
                </ReadonlyField>
              }
            </div>

            <div className={"form-label"}>
              <label htmlFor={"warrantyTerm"}>
                Warranty:
              </label>
            </div>
            <div className={"form-input"}>
              {isFormEditable() &&
                <input type={"text"} id={"warrantyTerm"} className={"form-control"} autoComplete={"none"} disabled={isFormFieldsDisabled()}
                       value={warrantyTerm} onChange={onWarrantyTermChanged} maxLength={100} />
              }
              {!isFormEditable() &&
                <ReadonlyField>
                  {warrantyTerm ? warrantyTerm : '-'}
                </ReadonlyField>
              }
            </div>

            <div className={"form-label"}>
              <label htmlFor={"serviceTerm"}>
                Service:
              </label>
            </div>
            <div className={"form-input"}>
              {isFormEditable() &&
                <input type={"text"} id={"serviceTerm"} className={"form-control"} autoComplete={"none"} disabled={isFormFieldsDisabled()}
                       value={serviceTerm} onChange={onServiceTermChanged} maxLength={100} />
              }
              {!isFormEditable() &&
                <ReadonlyField>
                  {serviceTerm ? serviceTerm : '-'}
                </ReadonlyField>
              }
            </div>

            <div className={"form-label"}>
              <label htmlFor={"deposit"}>
                Payment in Advance (Deposit):
              </label>
            </div>
            <div className={"form-input"}>
              {isFormEditable() &&
                <>
                  <input type={"text"} id={"deposit"} className={"form-control"} autoComplete={"none"} disabled={isFormFieldsDisabled()}
                         value={deposit} onChange={onDepositChanged} maxLength={100} />
                  <FieldErrorMessages visible={hasErrors} messages={formErrors['deposit']} />
                </>
              }
              {!isFormEditable() &&
                <ReadonlyField>
                  {deposit ? deposit : '-'}
                </ReadonlyField>
              }
            </div>
          </div>

          <h2>Other Information</h2>

          <div className={"entry-form purchase-agreement-entry-form"}>
            <div className={"form-label"}>
              <label htmlFor={"remarks"}>
                Remarks:
              </label>
            </div>
            <div className={"form-input"}>
              {isFormEditable() &&
                <textarea id={"remarks"} className={"form-control"} rows={3} autoComplete={"none"} disabled={isFormFieldsDisabled()}
                          value={remarks} onChange={onRemarksChanged}>
                </textarea>
              }
              {!isFormEditable() &&
                <ReadonlyField>
                  {remarks ? remarks : '-'}
                </ReadonlyField>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
  //#endregion
}
