import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { inventoryServices } from "../../repos/apiServices";
import { authService } from "../../repos/apiServices";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { InspectionFormProvider } from "./InspectionFormContext";
import { inspectionFormMode } from "./InspectionFormContext";
import { useInspectionForm } from "./InspectionFormContext";

import "./StockInspectionEntryPage.css";


export const StockInspectionEntryPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [isDraftEnabled, setIsDraftEnabled] = useState(false);
  const [selectedStockId, setSelectedStockId] = useState(null);
  const [selectedInspectionId, setSelectedInspectionId] = useState(null);

  const [inspectionType, setInspectionType] = useState(refs.inventory.stockInspectionType.predelivery);
  const [inspectionTypeTitle, setInspectionTypeTitle] = useState("");

  const [stockEntry, setStockEntry] = useState(null);
  const [generatorSerialDisplay, setGeneratorSerialDisplay] = useState('');
  const [generator, setGenerator] = useState(null);
  const [inspectionEntry, setInspectionEntry] = useState(null);

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.stocks.url);
    }

    let _stockId = state['stockId'];
    setSelectedStockId(_stockId);

    let _inspectionId = state['inspectionId'];
    if (_inspectionId) {
      // Edit entry mode
      fetchInspectionEntryForEditMode(_stockId, _inspectionId);
    }
    else {
      // New entry mode
      fetchStockDetails(_stockId);
      setIsEditable(true);
      setIsDraftEnabled(true);
    }
  }, []);

  const fetchStockDetails = (selectedStockId) => {
    setIsLoading(true);

    let payload = {
      'uid': auth.getUserId(),
      'stock_id': selectedStockId,
    };
    inventoryServices.fetchPredeliveryInspectionStockDetails(payload)
      .then((response) => {
        let _stockEntry = response['data'];
        setStockEntry(_stockEntry);
        setGeneratorSerialDisplay(_stockEntry['generatorSerial']);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse && errorResponse.status === 404) {
          navigate(routes.stocks.url);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const fetchInspectionEntryForEditMode = (stockId, inspectionId) => {
    setIsLoading(true);

    let payload = {
      'uid': auth.getUserId(),
      'stock_id': stockId,
      'inspection_id': inspectionId,
    };
    setSelectedInspectionId(inspectionId);
    inventoryServices.fetchPredeliveryInspectionStockDetails(payload)
      .then((response) => {
        let _responseData = response['data'];
        let _stockDetails = _responseData['stock'];
        setStockEntry(_stockDetails);
        setGeneratorSerialDisplay(_stockDetails['generatorSerial']);

        let _inspectionEntry = _responseData['inspection'];
        setInspectionEntry(_inspectionEntry);
        setIsEditable(_inspectionEntry['status'] !== refs.inventory.jobStatus.active);
        setIsDraftEnabled(_inspectionEntry['status'] === refs.inventory.jobStatus.draft);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse && errorResponse.status === 404) {
          navigate(routes.stockDetails.url, {
            state: {
              ...payload,
            }
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

  }
  //#endregion

  //#region Control handlers
  const onGeneratorSerialClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.stockDetails.url, { state: {
          'stockId': selectedStockId,
          'inspectionId': selectedInspectionId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container sales-inquiries-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.stocks.displayShort} anchorTo={routes.stocks.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                  {generatorSerialDisplay}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.stockInspection.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <InspectionFormProvider>
            {!isLoading &&
              <StockInspectionEntryFormControls isEditable={isEditable} stockEntry={stockEntry}
                                                isDraftEnabled={isDraftEnabled} setIsDraftEnabled={setIsDraftEnabled}
                                                inspectionType={inspectionType}
                                                editingInspectionEntry={inspectionEntry}
              />
            }
          </InspectionFormProvider>
        </div>
      </main>

    </MasterPageContainer>
  )
  //#endregion
}


const StockInspectionEntryFormControls = ({
                                            isEditable, stockEntry,
                                            isDraftEnabled, setIsDraftEnabled, inspectionType,
                                            editingInspectionEntry,
                                          }) => {
  //#region States
  const [editingInspectionId, setEditingInspectionId] = useState(null);

  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isSubmittingReview, setIsSubmittingReview] = useState(false);

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [allowInspectionReviews, setAllowInspectionReviews] = useState(false);

  const auth = useAuth();
  const location = useLocation();
  const form = useInspectionForm();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchPermissions();

    if (inspectionType === refs.inventory.stockInspectionType) {
      form.setFormMode(inspectionFormMode.arrivalInspection);
    }
    else {
      // NOTE(yemon): A bit hacky. Just make sure that `refs.inventory.stockInspectionType` stays
      // in correlations with `inspectionFormMode` defined in the `InspectionFormContext` file.
      form.setFormMode(inspectionType);
    }
    form.setInspectionType(inspectionType);
    form.setIsLoading(true);

    if (stockEntry) {
      let _firstInspectionDate = stockEntry['firstArrivalInspection']['inspectionDate'];
      form.setMinEntryDate(new Date(_firstInspectionDate));
      form.setStandbyPower(stockEntry['powerStandby']);
      form.setProductYear(stockEntry['productYear']);
    }
    else {
      form.setMinEntryDate(null);
    }

    let _generator = stockEntry['generator'];
    if (_generator) {
      _generator['generatorSerial'] = stockEntry['generatorSerial'];
      _generator['manufacturer'] = stockEntry['manufacturer'];
      _generator['alternatorSerial'] = stockEntry['alternatorSerial'];
      _generator['alternatorModel'] = stockEntry['alternatorModel'];
      _generator['alternator'] = stockEntry['alternator'];
      _generator['machineSerial'] = stockEntry['machineSerial'];
      _generator['machineModel'] = stockEntry['machineModel'];
      _generator['machine'] = stockEntry['machine'];
      _generator['controllerSerial'] = stockEntry['controllerSerial'];
      _generator['controllerModel'] = stockEntry['controllerModel'];
      _generator['controller'] = stockEntry['controller'];
      form.prepareGeneratorForEditMode(_generator);

      form.setIsEditableGenerator(false);
    }

    if (editingInspectionEntry) {
      // Edit entry mode
      setEditingInspectionId(editingInspectionEntry['id']);
      form.prepareEntryForEditMode(stockEntry, editingInspectionEntry);
    }
    else {
      // New entry mode
      form.setIsLoading(false);
      let _currentEmployee = {
        ...auth.userInfo,
      }
      _currentEmployee['id'] = auth.getUserId()['eid'];
      form.setTestedBy(_currentEmployee);
    }

    form.setIsEditable(isEditable);
  }, []);

  const fetchPermissions = () => {
    return authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response.data['service'];
        setAllowInspectionReviews(_servicePermissions['allowStockInspectionReviews']);
      });
  }
  //#endregion

  //#region Control handlers
  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }

  const onSubmitClicked = (ev, isDraft) => {
    let _hasErrors = form.isHeaderFieldsValid();
    if (_hasErrors) {
      setAlertMessage("Please fix the indicated errors on the respective fields, before submitting again.");
      setAlertType(alertTypes.error);
      setIsAlertVisible(true);
      return;
    }
    setIsAlertVisible(false);

    let payload = {
      ...form.prepareHeaderPayload(),
      ...form.prepareGeneratorPayload(false),
      ...form.prepareTestDetailsPayload(),
      ...form.prepareTestResultsPayload(),
      ...form.prepareAdditionalInfoPayload(),
    }
    payload['employee_id'] = auth.getUserId()['eid'];
    payload['stock_id'] = stockEntry['id']
    payload['generator_id'] = stockEntry['generatorId']
    payload['draft_state'] = isDraft;

    if (editingInspectionId) {
      // Edit mode
      payload['inspection_id'] = editingInspectionId;
    }

    form.setIsSubmitting(false);
    if (isDraft) {
      setIsSubmittingForReview(false);
      setIsSubmittingDraft(true);
    }
    else {
      setIsSubmittingForReview(true);
      setIsSubmittingDraft(false);
    }
    // noinspection DuplicatedCode
    inventoryServices.postPredeliveryInspection(payload)
      .then((response) => {
        let stock = response['data']['stock'];
        let inspection = stock['inspection'];

        setEditingInspectionId(inspection['id']);

        form.setJobStatus(inspection['status']);
        updateStatusStates(inspection['status']);

        setAlertType(alertTypes.info);
        setAlertMessage(response.data['message']);
        setIsAlertVisible(true);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 400 || errorResponse.status === 403) {
            setAlertType(alertTypes.error);
            setAlertMessage(errorResponse.data['message']);
            setIsAlertVisible(true);
          }
          else if (errorResponse.status === 500) {
            setAlertType(alertTypes.error);
            setAlertMessage("Unhandled server error while trying to save the inspection details. Contact and notify the system administrator.");
            setIsAlertVisible(true);
          }
        }
      })
      .finally(() => {
        form.setIsSubmitting(false);
        setIsSubmittingForReview(false);
        setIsSubmittingDraft(false);
      });
  }

  const onReviewClicked = (ev) => {
    if (editingInspectionId === null) {
      return;
    }

    let payload = {
      'employee_id': auth.getUserId()['eid'],
      'inspection_id': editingInspectionId,
    }
    setIsSubmittingReview(true);
    inventoryServices.postPredeliveryInspectionReview(payload)
      .then((response) => {
        let _responseData = response['data'];
        updateStatusStates(_responseData['status']);
        form.setJobStatus(_responseData['status']);
        form.setCheckedBy(_responseData['checkedBy']);
        form.setCheckedDate(_responseData['checkedDate']);
      })
      .finally(() => {
        setIsSubmittingReview(false);
      });
  }
  //#endregion

  //#region Utilities
  const updateStatusStates = (status) => {
    if (status === refs.inventory.jobStatus.active) {
      form.setIsEditable(false);
    }
    setIsDraftEnabled(status === refs.inventory.jobStatus.draft);
  }

  const isFormReviewable = () => {
    return allowInspectionReviews &&
      (form.jobStatus === refs.inventory.jobStatus.pendingReview || form.jobStatus === refs.inventory.jobStatus.active);
  }

  const isFormReviewed = () => {
    return allowInspectionReviews && form.jobStatus === refs.inventory.jobStatus.active;
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={"form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-primary"} disabled={form.isSubmitting || !form.isEditable}
                  onClick={(ev) => onSubmitClicked(ev, false)}>
            {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}
            Submit
          </button>

          <button type={"button"} className={"btn btn-secondary right-margin"} disabled={form.isSubmitting || !isDraftEnabled}
                  onClick={(ev) => onSubmitClicked(ev, true)}>
            {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}
            Save Draft
          </button>

          {isFormReviewable() &&
            <button type={"button"} className={"btn btn-success"} disabled={form.isSubmitting || isSubmittingReview || isFormReviewed()}
                    onClick={onReviewClicked}>
              {isSubmittingReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isSubmittingReview && <i className={"fa-solid fa-list-check"}></i>}
              Mark as Reviewed
            </button>
          }
        </div>
      </div>

      <PageAlert visible={isAlertVisible} type={alertType}
                 onDismissButtonClicked={onAlertDismissButtonClicked}>
        {alertMessage}
      </PageAlert>
    </>
  )
  //#endregion
}
