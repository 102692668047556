import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { ServiceEntryGenerator } from "./ServiceEntryGenerator";
import { StockStatusBadge } from "./StockStatusBadge";
import { JobStatusBadge } from "./JobStatusBadge";
import { ReadonlyField } from "../shared/ReadonlyField";
import { InfoMessage } from "../sales/FormMessages";
import { WarningMessage } from "../sales/FormMessages";

import '../shared/ContentArea.css';
import "../shared/ListingPage.css";


export function StockDetailsPage() {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [stockId, setStockId] = useState(null);
  const [stockInspections, setStockInspections] = useState([]);

  const [gensetModel, setGensetModel] = useState("");
  const [generatorSerial, setGeneratorSerial] = useState("");
  const [manufacturer, setManufacturer] = useState(refs.inventory.generatorManufacturers.AKSA);
  const [alternatorSerial, setAlternatorSerial] = useState("");
  const [alternatorModel, setAlternatorModel] = useState("");
  const [alternator, setAlternator] = useState(refs.inventory.generatorAlternators.unspecified);
  const [machineSerial, setMachineSerial] = useState("");
  const [machineModel, setMachineModel] = useState("");
  const [machine, setMachine] = useState(refs.inventory.generatorMachines.unspecified);
  const [controllerSerial, setControllerSerial] = useState("");
  const [controllerModel, setControllerModel] = useState("");
  const [controller, setController] = useState(refs.inventory.generatorControllers.unspecified);
  const [stockStatus, setStockStatus] = useState(refs.inventory.stockStatus.draft);

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (state) {
      fetchUserPermissions();

      let _stockId = state['stockId'];
      setStockId(_stockId);
      fetchStockDetails(_stockId);
      fetchStockInspections(_stockId);
    }
    else {
      navigate(routes.stocks.url);
    }
  }, []);

  const prepareDetailsPayload = (selectedStockId) => {
    return {
      'uid': auth.getUserId(),
      'stock_id': selectedStockId,
    };
  }

  const fetchStockDetails = (selectedStockId) => {
    setIsLoading(true);

    let params = prepareDetailsPayload(selectedStockId);
    inventoryServices.fetchStockDetails(params)
      .then((response) => {
        let stockEntry = response['data'];
        prepareStockEntry(stockEntry);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404) {
            navigate(routes.stocks.url);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const fetchStockInspections = (selectedStockId) => {
    setIsLoadingTable(true);

    let params = prepareDetailsPayload(selectedStockId);
    inventoryServices.fetchStockInspections(params)
      .then((response) => {
        let responseData = response['data']['data'];
        setStockInspections(responseData);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404) {
            navigate(routes.stocks.url);
          }
        }
      })
      .finally(() => {
        setIsLoadingTable(false);
      });
  }

  const prepareStockEntry = (entry) => {
    setGensetModel(entry['generator']['gensetModel']);
    setGeneratorSerial(entry['generatorSerial']);
    setManufacturer(entry['manufacturer']);
    setAlternatorSerial(entry['alternatorSerial']);
    setAlternatorModel(entry['alternatorModel']);
    setAlternator(entry['alternator']);
    setMachineSerial(entry['machineSerial']);
    setMachineModel(entry['machineModel']);
    setMachine(entry['machine']);
    setControllerModel(entry['controllerModel']);
    setControllerSerial(entry['controllerSerial']);
    setController(entry['controller']);

    setStockStatus(entry['status']);
  }
  //#endregion

  //#region States; User permissions
  const [allowStockEdit, setAllowStockEdit] = useState(false);

  const fetchUserPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response['data']['service'];
        setAllowStockEdit(_servicePermissions['allowStockEdit']);
      });
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchStockDetails(stockId);
    fetchStockInspections(stockId);
  }

  const onNewStockInspectionClicked = (ev) => {
    navigate(routes.stockInspection.url, {
      state: {
        'stockId': stockId,
        'generatorSerial': generatorSerial,
      }
    });
  }

  const onRowClicked = (ev, inspection) => {
    setTimeout(() => {
      if (inspection['inspectionType'] === refs.inventory.stockInspectionType.arrival) {
        navigate(routes.arrivalInspection.url, {
          state: {
            'stockId': inspection['stockId'],
            'inspectionId': inspection['id'],
          }
        });
      }
      else {
        navigate(routes.stockInspection.url, {
          state: {
            'stockId': inspection['stockId'],
            'inspectionId': inspection['id'],
          }
        });
      }
    }, 200);
  }
  //#endregion

  //#region Utilities
  const isStockInUse = () => {
    return stockStatus === refs.inventory.stockStatus.commissioned ||
      stockStatus === refs.inventory.stockStatus.inactive;
  }
  //#endregion

  //#region Generator Details section; States & Control handlers for updating the Stock
  const [isEditingStock, setIsEditingStock] = useState(false);
  const [isSubmittingStock, setIsSubmittingStock] = useState(false);
  const [originalStockDetails, setOriginalStockDetails] = useState({});
  const [hasStockErrors, setHasStockErrors] = useState(false);
  const [stockErrors, setStockErrors] = useState({});

  const [isCheckingStockUsage, setIsCheckingStockUsage] = useState(false);
  const [stockUsage, setStockUsage] = useState({});
  const [stockUsageCount, setStockUsageCount] = useState(1);

  const onEditStockClicked = (ev) => {
    setIsEditingStock(true);
    setOriginalStockDetails({
      'manufacturer': manufacturer,
      'alternatorSerial': alternatorSerial,
      'alternatorModel': alternatorModel,
      'alternator': alternator,
      'machineSerial': machineSerial,
      'machineModel': machineModel,
      'machine': machine,
      'controllerSerial': controllerSerial,
      'controllerModel': controllerModel,
      'controller': controller,
    });

    setIsCheckingStockUsage(true);
    inventoryServices.checkStockUsage({ 'stock_id': stockId })
      .then((response) => {
        const data = response['data'];
        setStockUsage(data['stockUsage']);
        setStockUsageCount(data['count']);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsCheckingStockUsage(false);
      });
  }

  const onSaveStockClicked = (ev) => {
    setIsSubmittingStock(true);

    let payload = {
      'stock_id': stockId,
      'manufacturer': manufacturer,
      'alternator_serial': alternatorSerial ? alternatorSerial.trim() : null,
      'alternator_model': alternatorModel ? alternatorModel.trim() : null,
      'alternator': alternator,
      'machine_serial': machineSerial ? machineSerial.trim() : null,
      'machine_model': machineModel ? machineModel.trim() : null,
      'machine': machine,
      'controller_serial': controllerSerial ? controllerSerial.trim() : null,
      'controller_model': controllerModel ? controllerModel.trim() : null,
      'controller': controller,
      'employee_id': auth.getUserId()['eid'],
    }
    inventoryServices.updateStockDetails(payload)
      .then((response) => {
        //const data = response['data'];
        setIsEditingStock(false);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmittingStock(false);
      });
  }

  const onCancelStockClicked = (ev) => {
    setIsEditingStock(false);
    setIsSubmittingStock(false);

    setIsCheckingStockUsage(false);
    setStockUsage({});
    setStockUsageCount(0);

    if (!originalStockDetails) {
      return;
    }
    setAlternatorSerial(originalStockDetails['alternatorSerial']);
    setAlternatorModel(originalStockDetails['alternatorModel']);
    setAlternator(originalStockDetails['alternator']);
    setMachineSerial(originalStockDetails['machineSerial']);
    setMachineModel(originalStockDetails['machineModel']);
    setMachine(originalStockDetails['machine']);
    setControllerSerial(originalStockDetails['controllerSerial']);
    setControllerModel(originalStockDetails['controllerModel']);
    setController(originalStockDetails['controller']);
  }

  const getStockUsageInfoSection = () => {
    if (!isEditingStock) {
      return <></>
    }

    return (
      <div className={"form-message"}>
        {isCheckingStockUsage &&
          <InfoMessage>
            Identifying usage...
          </InfoMessage>
        }
        {!isCheckingStockUsage && stockUsageCount === 0 &&
          <InfoMessage>
            This generator has <b><i>never been</i></b> commissioned or used before. Its details can be <b>safely updated</b>.
          </InfoMessage>
        }

        {!isCheckingStockUsage && stockUsageCount > 0 &&
          <WarningMessage>
            This generator stock is commissioned (associated) with <b>{stockUsageCount}</b> {stockUsageCount === 1 ? "Service Profile" : "Service Profiles"}.<br />
            Any changes you have made here will be effected to all Generators under {stockUsageCount === 1 ? "that Service Profile" : "those Service Profiles"}.

            {/*<div style={{ marginTop: '8px' }}>*/}
            {/*  <button type={"button"} className={"btn btn-outline-secondary"}*/}
            {/*          onClick={null}>*/}
            {/*    View...*/}
            {/*  </button>*/}
            {/*</div>*/}
          </WarningMessage>
        }
      </div>
    )
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container sales-inquiries-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.stocks.displayShort} anchorTo={routes.stocks.url} />
              <BreadcrumbItem text={generatorSerial} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>[{gensetModel}] {generatorSerial}</h1>
          </div>

          <ServiceEntryGenerator gensetModel={gensetModel} onGensetModelChanged={null}
                                 generatorSerial={generatorSerial} onGeneratorSerialChanged={null}
                                 generator={manufacturer} onGeneratorChanged={(ev) => setManufacturer(parseInt(ev.target.value))}
                                 alternatorSerial={alternatorSerial} onAlternatorSerialChanged={(ev) => setAlternatorSerial(ev.target.value)}
                                 alternatorModel={alternatorModel} onAlternatorModelChanged={(ev) => setAlternatorModel(ev.target.value)}
                                 alternator={alternator} onAlternatorChanged={(ev) => setAlternator(parseInt(ev.target.value))}
                                 machineSerial={machineSerial} onMachineSerialChanged={(ev) => setMachineSerial(ev.target.value)}
                                 machineModel={machineModel} onMachineModelChanged={(ev) => setMachineModel(ev.target.value)}
                                 machine={machine} onMachineChanged={(ev) => setMachine(parseInt(ev.target.value))}
                                 controllerSerial={controllerSerial} onControllerSerialChanged={(ev) => setControllerSerial(ev.target.value)}
                                 controllerModel={controllerModel} onControllerModelChanged={(ev) => setControllerModel(ev.target.value)}
                                 controller={controller} onControllerChanged={(ev) => setController(parseInt(ev.target.value))}
                                 isLoading={isLoading} isEditable={isEditingStock} isSubmitting={isSubmittingStock}
                                 hasErrors={hasStockErrors} formErrors={stockErrors}
                                 messageSection={allowStockEdit ? getStockUsageInfoSection() : null}>
            {allowStockEdit &&
              <div className={"form-section-controls"}>
                {!isEditingStock &&
                  <button className={"btn btn-secondary"} onClick={onEditStockClicked}>
                    <i className={"fa-solid fa-pencil"}></i>Edit
                  </button>
                }
                {isEditingStock &&
                  <>
                    <button className={"btn btn-primary"} disabled={isSubmittingStock}
                            onClick={onSaveStockClicked}>
                      {isSubmittingStock && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                      {!isSubmittingStock && <i className={"fa-solid fa-check"}></i>}
                      Save
                    </button>
                    <button className={"btn btn-secondary"} disabled={isSubmittingStock}
                            onClick={onCancelStockClicked}>
                      <i className={"fa-solid fa-xmark"}></i>Cancel
                    </button>
                  </>
                }
              </div>
            }
          </ServiceEntryGenerator>

          <div className={"form-section"}>
            <h2>Stock Inspections</h2>

            <div className={"entry-form"}>
              <div className={"form-label"}>
                <label>
                  Stock Status:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <StockStatusBadge status={stockStatus} />
                </ReadonlyField>
              </div>
            </div>

            <div className={"form-section-controls"}>
              <button className={"btn btn-secondary"} disabled={isLoading || isSubmittingStock}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              <button className={"btn btn-primary btn-md"} disabled={isLoading || isStockInUse() || isSubmittingStock}
                      onClick={onNewStockInspectionClicked}>
                <i className="fa-solid fa-truck-ramp-box"></i>
                New Stock Inspection
              </button>
            </div>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>Inspection Date</th>
                <th scope={"col"}>Inspection Type</th>
                <th scope={"col"}>Tested By</th>
                <th scope={"col"}>Status</th>
                <th scope={"col"}>Checked By</th>
                <th scope={"col"}>Checked Date</th>
              </tr>
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={7} />}

              {stockInspections && stockInspections.length > 0 && !isLoading &&
                stockInspections.map((inspection, index) =>
                  <InspectionRow key={inspection['id']} inspection={inspection} index={index}
                                 onRowClicked={onRowClicked} />
                )
              }

              {!stockInspections || (stockInspections.length === 0 && !isLoading &&
                <TableEmptyRow colSpan={7} />
              )}
              </tbody>
            </table>
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const InspectionRow = ({
                         inspection, index, onRowClicked,
                       }) => {
  return (
    <tr>
      <td className={"index-col"}>{index + 1}</td>
      <td>
        <a href="#" role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, inspection)}>
        <Moment date={inspection['inspectionDate']} format={formatters.datetimeShort} />
        </a>
      </td>
      <td>
        {refs.inventory.stockInspectionType[inspection['inspectionType']]}
      </td>
      <td>
        {inspection['testedBy']['name']}
      </td>
      <td>
        <JobStatusBadge status={inspection['status']} />
      </td>
      <td>
        {inspection['checkedBy'] ? inspection['checkedBy']['name'] : '-'}
      </td>
      <td>
        {inspection['checkedDate'] ?
          <Moment date={inspection['checkedDate']} format={formatters.datetimeShort} /> : '-'
        }
      </td>
    </tr>
  )
}
