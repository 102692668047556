import moment from "moment";

import { formatters } from "./constants";

export const timestampFilename = (name, extension) => {
  let today = new Date();
  return `${name}_${moment(today).format(formatters.datetimeLiteral)}.${extension}`;
}

export const doVirtualDownload = (exportResponse, fileName, fileExtension) => {
  const url = window.URL.createObjectURL(new Blob([exportResponse.data]));
  const downloadLink = document.createElement('a');
  downloadLink.classList.add('download-export');
  downloadLink.href = url;
  downloadLink.setAttribute('download', timestampFilename(fileName, fileExtension));
  document.body.appendChild(downloadLink);
  downloadLink.click();
  setTimeout(() => {
    document.body.removeChild(downloadLink);
  }, 1000);
}
