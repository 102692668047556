import React from "react";
import Moment from "react-moment";

import { getFollowUpStageActionDisplay } from "../../repos/utilities";

import { ActorNameDisplay } from "./ActorNameDisplay";
import { TableLoadingIndicator } from "../shared/DataTable";
import { GeneratorsSummary } from "./GeneratorsSummary";

import "../shared/DataTable.css";
import "./EntryHistoryTable.css";


export const EntryHistoryTable = ({
                                    isLoading,
                                    inquiryHistories,
                                    onInquiryHistoryRowSelected,
                                  }) => {
  return (
    <div className="data-table inquiry-history-table">
      <table>
        <thead>
        <tr>
          <th scope="col" className="index-col-head">#</th>
          <th scope="col">Date</th>
          <th scope="col">Reporter</th>
          <th scope="col">Genset Models</th>
          <th scope="col">Visit/Call</th>
          <th scope="col">Discussion Details</th>
          <th scope="col">Follow Up Action</th>
          <th scope="col">Reminder Date</th>
        </tr>
        </thead>
        <tbody>
        {isLoading && <TableLoadingIndicator colspan={7} />}
        {inquiryHistories && inquiryHistories.length > 0 && !isLoading &&
          inquiryHistories.map((history, index) =>
            <EntryHistoryRow key={history['followUpEntryId']} history={history} index={index}
                             onRowSelected={(ev) => onInquiryHistoryRowSelected(ev, history, index)}
            />
          )
        }
        </tbody>
      </table>
    </div>
  )
}


const EntryHistoryRow = ({
                           history, index,
                           onRowSelected,
                         }) => {
  return (
    <>
      {history &&
        <tr>
          <td className={"index-col"}>{index + 1}</td>
          <td className={"has-hint-icon date-col"}>
            <a href="#" role={"button"} onClick={(ev) => onRowSelected(ev, history)}>
              <Moment date={history['followUpEntryDatetime']} format={"MMM DD, YYYY"} />
            </a>
            {history.isEditing && <i className="fa-solid fa-pen-to-square" style={{ color: 'gray' }}></i>}
            {!history.isEditing && history['updateRequest'] &&
              <i className="fa-solid fa-square-pen" style={{ color: 'gray', fontSize: '100%' }}></i>
            }
          </td>
          <td className={"reporter-col"}>
            <ActorNameDisplay employee={history['followUpReporter']} />
          </td>
          <td className={"genset-model-col"}>
            <GeneratorsSummary generators={history['generators']} summarized={false} />
          </td>
          <td className={"contact-form-col"}>
            {history['contactFormDisplay']}
          </td>
          <td className={"discussions-col"}>
            {history['discussions'] === null || history['discussions'] === '' ? '-' : history['discussions']}
          </td>
          <td className={"follow-up-col"}>
            {getFollowUpStageActionDisplay(history['followUpStage'], history['followUpAction'])}
          </td>
          <td className={"reminder-date-col"}>
            {history['reminderDatetime'] && <Moment date={history['reminderDatetime']} format={"MMM DD, yyyy"} />}
          </td>
        </tr>
      }
    </>
  )
}
