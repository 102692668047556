import React from "react";

export const MasterPageContainer = ({children}) => {
  //#region Render
  return (
    <div id={"master-content"}>
      {children}
    </div>
  )
  //#endregion
}
