import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { refs } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";

import { TableEmptyRow } from "../shared/DataTable";
import { TableLoadingIndicator } from "../shared/DataTable";
import { ReviewStatusBadge } from "../quotation/ReviewStatusBadge";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";

import './DashboardWidget.css';
import '../shared/DataTable.css';
import '../quotation/QuotationRequestListPage.css';


export const QuotationRequestsWidget = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [listPermissions, setListPermissions] = useState();
  const [quotationRequests, setQuotationRequests] = useState([]);
  const [viewLimit, setViewLimit] = useState(0);
  const [viewingStatuses, setViewingStatuses] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchQuotationRequestList();
  }, []);

  const fetchQuotationRequestList = () => {
    setIsLoading(true);
    let options = {
      'uid': auth.getUserId(),
    };
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _listPermissions = response.data['quotationRequests'];
        setListPermissions(_listPermissions);

        return salesServices.fetchDashboardQuotationRequests(options);
      })
      .then((response) => {
        let result = response.data;
        setQuotationRequests(result['data']);
        setViewLimit(result['limit']);

        let _viewingStatuses = result['viewingStatuses'];
        if (_viewingStatuses && _viewingStatuses.length > 0) {
          setViewingStatuses(_viewingStatuses.map(status => refs.sales.reviewStatus[status]).join(', '));
        } else setViewingStatuses("");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchQuotationRequestList();
  }

  const onRowClicked = (ev, entry) => {
    setTimeout(() => {
      navigate(routes.quotationRequestEntry.url, { state: entry });
    }, 200);
  }

  const onViewAllClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.quotationRequestsList.url);
    }, 200);
  }
  //#endregion

  //#region Render
  return (
    <div className={"content-area"}>
      <div className={"row"}>
        <h1>
          Quotation Requests
        </h1>
      </div>

      <div className={"dashboard-widget"}>
        <div className={"widget-floated-controls"}>
          <button className={"btn btn-secondary"} disabled={isLoading}
                  onClick={onRefreshClicked}>
            {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isLoading && <i className="fa-solid fa-rotate"></i>}
            Refresh
          </button>
        </div>

        <div className={"widget-contents"}>
          Viewing the latest <b>{viewLimit}</b> Quotation Requests under the review status of: <b>{viewingStatuses}</b>
        </div>

        <div className={"widget-data-table data-table quotation-requests-table"}>
          <table>
            <thead>
            <tr>
              <th scope="col" className={"index-col-head"}>#</th>
              <th>Customer Name</th>
              <th>Business Type</th>
              <th>Contact Person</th>
              <th>Contact No.</th>
              <th>Request Date</th>
              <th>Review Status</th>
              <th>Reviewed Date</th>
              <th>Reviewed By</th>
            </tr>
            </thead>

            <tbody>
            {isLoading && <TableLoadingIndicator colspan={9} />}

            {!isLoading && quotationRequests && quotationRequests.length > 0 &&
              quotationRequests.map((request, index) =>
                <QuotationRequestWidgetRow key={request['requestId']} request={request} index={index}
                                           onRowClicked={(ev) => onRowClicked(ev, request)}
                />
              )
            }

            {!quotationRequests || (quotationRequests.length === 0 && !isLoading &&
              <TableEmptyRow colSpan={9} />)}
            </tbody>
          </table>
        </div>

        <div className={"widget-contents"}>
          <button type={"button"} className={"btn btn-secondary"} onClick={onViewAllClicked}>
            <i className="fa-solid fa-file-invoice-dollar"></i>
            View all...
          </button>
        </div>
      </div>
    </div>
  )
  //#endregion
}


const QuotationRequestWidgetRow = ({
                                     request, index,
                                     onRowClicked,
                                   }) => {
  return (
    <>
      <tr>
        <td className={"index-col"}>{index + 1}</td>
        <td className={"customer-name-col"}>
          <a href="#" role={"button"} className={"record-link"} onClick={(ev) => onRowClicked(ev, request)}>
            {request['customer']['customerName']}
          </a>
        </td>
        <td className={"business-type-col"}>{request['customer']['businessType']['name']}</td>
        <td className={"contact-name-col"}>{request['customer']['contactFullName']}</td>
        <td className={"contact-no-col"}>{request['customer']['contactNo']}</td>
        <td className={"date-col"}>
          <Moment date={request['requestedDatetime']} format={formatters.datetime} />
        </td>
        <td className={"review-status-col"}>
          <ReviewStatusBadge status={request['reviewStatus']} />
        </td>
        <td className={"date-col"}>
          {request['reviewedDatetime'] ? <Moment date={request['reviewedDatetime']} format={formatters.datetime} /> : <>-</>}
        </td>
        <td className={"actor-col"}>
          {request['reviewedBy'] ? <ActorNameDisplay employee={request['reviewedBy']} /> : <>-</>}
        </td>
      </tr>
    </>
  )
}
