import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { inventoryServices } from "../../repos/apiServices";
import { customerServices } from "../../repos/apiServices";
import { formatters } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "../service/NullBlankField";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ActorNameRankDisplay } from "../sales/ActorNameDisplay";
import { JobStatusBadge } from "../service/JobStatusBadge";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "./ContactEntryPage.css";


const callTypes = refs.customer.callType;
const contactTypes = refs.customer.contactType;
const satisfactions = refs.customer.satisfaction;

export const ContactLogProfileEntryPage = () => {
  //#region States
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [careContactId, setCareContactId] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessTypeName, setBusinessTypeName] = useState("");
  const [address, setAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [nrc, setNrc] = useState("");
  const [designation, setDesignation] = useState("");

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingEntry, setIsLoadingEntry] = useState(false);
  const [callType, setCallType] = useState(callTypes.outbound);
  const [contactType, setContactType] = useState(contactTypes.na);
  const [actualContactName, setActualContactName] = useState("");
  const [actualContactNo, setActualContactNo] = useState("");
  const [contactDate, setContactDate] = useState(new Date());
  const [discussionDetails, setDiscussionDetails] = useState("");
  const [satisfaction, setSatisfaction] = useState(satisfactions.na);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [isFormReadonly, setIsFormReadonly] = useState(false);
  const [status, setStatus] = useState(refs.customer.jobStatus.draft);
  const [recordedBy, setRecordedBy] = useState({});

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.serviceProfiles.url);
    }

    let _serviceProfileId = state['serviceProfileId'];
    let _customerId = state['customerId'];
    let _contactLogId = state['serviceHistoryId'];
    let _callType = state['callType'];
    let _contactType = state['contactType'];

    if (!_serviceProfileId || !_customerId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      setServiceProfileId(_serviceProfileId);
      setCustomerId(_customerId);
      fetchCustomerServiceProfile(_customerId, _serviceProfileId);
    }

    if (_contactLogId) {
      // Edit mode
      fetchCareProfileContactEntry(_serviceProfileId, _contactLogId);
    }

    if (_callType) {
      setCallType(_callType);
    }
    if (_contactType) {
      setContactType(_contactType);
    }

    let _currentEmployee = {
      ...auth.userInfo,
    }
    _currentEmployee['id'] = auth.getUserId()['eid'];
    setRecordedBy(_currentEmployee);
  }, []);

  useEffect(() => {
    if (status === refs.customer.jobStatus.draft) {
      setIsFormReadonly(false);
    }
    else {
      setIsFormReadonly(true);
    }
  }, [status])

  const fetchCustomerServiceProfile = (customerId, serviceProfileId) => {
    setIsLoadingProfile(true);
    let params = {
      'uid': auth.getUserId(),
      'customer_id': customerId,
      'service_profile_id': serviceProfileId,
    };
    inventoryServices.fetchCustomerServiceProfile(params)
      .then((response) => {
        let _serviceProfile = response['data'];
        prepareServiceProfileDetails(_serviceProfile);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404 || errorResponse.status === 400) {
            navigate(routes.serviceProfiles.url);
          }
        }
      })
      .finally(() => {
        setIsLoadingProfile(false);
      });
  }

  const prepareServiceProfileDetails = (serviceProfile) => {
    if (!serviceProfile) {
      return;
    }
    let _customer = serviceProfile['customer'];

    setCustomerName(_customer['customerName']);
    setCompanyName(_customer['companyName']);
    setBusinessTypeName(_customer['businessType']['name']);
    setAddress(_customer['address']);
    setContactName(_customer['contactFullName']);
    setContactNo(_customer['contactNo']);
    setNrc(_customer['nrc']);
    setDesignation(_customer['designation']);
  }

  const fetchCareProfileContactEntry = (_serviceProfileId, _contactLogId) => {
    let payload = {
      'service_profile_id': _serviceProfileId,
      'service_history_id': _contactLogId,
    };
    setIsLoadingEntry(true);
    customerServices.fetchCareProfileContactEntry(payload)
      .then((response) => {
        let _careContact = response['data'];
        prepareCareContactEntryDetails(_careContact, _serviceProfileId);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => {
        setIsLoadingEntry(false);
      })
  }

  const prepareCareContactEntryDetails = (_careContact, _serviceProfileId) => {
    if (!_careContact) {
      return;
    }

    setCareContactId(_careContact['id']);

    setContactDate(new Date(_careContact['recordedDatetime']));
    setCallType(_careContact['callType']);
    setContactType(_careContact['contactType']);
    setActualContactName(_careContact['contactFullName']);
    setActualContactNo(_careContact['contactNo']);
    setDiscussionDetails(_careContact['description']);
    setSatisfaction(_careContact['satisfaction']);

    setStatus(_careContact['status']);
    setRecordedBy(_careContact['recordedBy']);
  }
  //#endregion

  //#region Control handlers
  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onContactDateChanged = (date) => {
    setContactDate(date);
    setFormErrors({ ...formErrors, contactDate: [] });
  }

  const isFormDisabled = () => {
    return isLoadingProfile || isLoadingEntry || isSubmitting;
  }

  const isFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      contactDate: [],
    }

    if (!contactDate) {
      _hasErrors = true;
      _formErrors.contactDate.push("Contact date is required.");
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareFormPayload = (isDraft) => {
    let employeeId = auth.getUserId()['eid'];
    let json = {
      'id': null,
      'serviceProfileId': serviceProfileId,
      'contactDatetime': contactDate,
      'callType': callType,
      'contactType': contactType,
      'contactFullName': actualContactName,
      'contactNo': actualContactNo,
      'discussionDetails': discussionDetails,
      'satisfaction': satisfaction,
      'isDraft': isDraft,
      'recordedById': employeeId,
    }
    if (careContactId) {
      // Edit mode, potentially
      json['id'] = careContactId;
    }
    return json;
  }

  const onSubmitClicked = (ev, isDraft) => {
    let isFormValid = isFormFieldsValid();
    if (!isFormValid) {
      setAlertMessage("Please fix the form errors before submitting again.");
      setAlertType(alertTypes.error);
      setIsAlertVisible(true);
      setHasErrors(true);
      return;
    }
    setIsAlertVisible(false);
    setHasErrors(false);

    setIsSubmitting(true);
    if (isDraft) {
      setIsSubmittingForReview(false);
      setIsSubmittingDraft(true);
    }
    else {
      setIsSubmittingForReview(true);
      setIsSubmittingDraft(false);
    }

    let payload = {
      ...prepareFormPayload(isDraft),
    };

    customerServices.submitCareProfileContact(payload)
      .then((response) => {
        let _responseData = response['data'];
        setAlertType(alertTypes.info);
        setAlertMessage(_responseData['message']);
        setIsAlertVisible(true);

        let _careContact = _responseData['careContact'];
        setCareContactId(_careContact['id']);
        setServiceProfileId(_careContact['serviceProfileId']);
        setStatus(_careContact['status']);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsSubmittingForReview(false);
        setIsSubmittingDraft(false);
      });
  }
  //#endregion

  //#region Utilities
  const getContactTypeDisplay = () => {
    let display = contactTypes[contactType];
    if (contactType === contactTypes.na) {
      return "";
    }
    else {
      return ` - ${display}`;
    }
  }

  const getContactDateClassName = () => {
    let className = "form-control";
    if (contactDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.contactLogProfileEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{customerName}{getContactTypeDisplay()}</h1>
          </div>

          <div className={"form-section"}>
            <h2>Customer Details</h2>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"customerName"}>
                  Customer Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Company:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Business Type:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Address:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{customerName}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={companyName} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{businessTypeName}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={address} />
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactName"}>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactNo"}>
                  Contact No:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactNo"}>
                  Contact NRC:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactNo"}>
                  Contact Designation:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>{contactName}</ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>{contactNo}</ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={nrc} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={designation} />
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isFormDisabled()}
                      onClick={onCustomerNameClicked}>
                <i className={"fa-solid fa-arrow-left"}></i>
                Return to Profile
              </button>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Care Contact Log</h2>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Date:<span className="error-message">*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Call Type:<span className="error-message">*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Contact Type:<span className="error-message">*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Actual Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Actual Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Discussion/Feedback:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Satisfaction:
                </label>
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <Moment date={contactDate} format={formatters.datetimeShort} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <DatePicker id={"contactDate"} className={getContactDateClassName()} placeholderText={"Type a valid date or click to choose"}
                              dateFormat={formatters.datetimePickerShort} maxDate={new Date()} minDate={null}
                              required={true} todayButton={"Today"} showWeekNumbers
                              disabled={isFormDisabled()} autoComplete={"off"}
                              selected={contactDate} onChange={onContactDateChanged} />
                }
                <FieldErrorMessages visible={hasErrors} messages={formErrors['contactDate']} />
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    {callTypes[callType]}
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <>
                    <label className={"option-field"}>
                      <input type={"radio"} id={"contactType"} name={"contactType"} disabled={isFormDisabled()}
                             value={callTypes.outbound} onChange={(ev) => setCallType(parseInt(ev.target.value))}
                             checked={callType === callTypes.outbound} />
                      {callTypes[1]}
                    </label>
                    <label className={"option-field"}>
                      <input type={"radio"} id={"contactType"} name={"contactType"} disabled={isFormDisabled()}
                             value={callTypes.inbound} onChange={(ev) => setCallType(parseInt(ev.target.value))}
                             checked={callType === callTypes.inbound} />
                      {callTypes[2]}
                    </label>
                  </>
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    {contactTypes[contactType]}
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <select id={"contactType"} className={"form-control form-select"} disabled={isFormDisabled()}
                          value={contactType} onChange={(ev) => setContactType(parseInt(ev.target.value))}>
                    <option value={contactTypes.na}>{contactTypes[0]}</option>
                    <option value={contactTypes.regularCall}>{contactTypes[1]}</option>
                    <option value={contactTypes.inquiry}>{contactTypes[3]}</option>
                    <option value={contactTypes.complaint}>{contactTypes[4]}</option>
                  </select>
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <NullBlankValue value={actualContactName} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <input type={"text"} id={"actualContactName"} className={"form-control"}
                         maxLength={100} disabled={isFormDisabled()}
                         value={actualContactName} onChange={(ev) => setActualContactName(ev.target.value)} />
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <NullBlankValue value={actualContactNo} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <input type={"text"} id={"actualContactName"} className={"form-control"}
                         maxLength={50} disabled={isFormDisabled()}
                         value={actualContactNo} onChange={(ev) => setActualContactNo(ev.target.value)} />
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <NullBlankValue value={discussionDetails} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <textarea id={"discussionDetails"} className={"form-control"} rows={7} disabled={isFormDisabled()}
                            value={discussionDetails} onChange={(ev) => setDiscussionDetails(ev.target.value)}
                            maxLength={500}>
                  </textarea>
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    {satisfactions[satisfaction]}
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <select id={"contactType"} className={"form-control form-select"} disabled={isFormDisabled()}
                          value={satisfaction} onChange={(ev) => setSatisfaction(parseInt(ev.target.value))}>
                    <option value={satisfactions.na}>{satisfactions[0]}</option>
                    <option value={satisfactions.satisfied}>{satisfactions[1]}</option>
                    <option value={satisfactions.dissatisfied}>{satisfactions[3]}</option>
                    <option value={satisfactions.unreachable}>{satisfactions[4]}</option>
                  </select>
                }
              </div>
            </div>

            <h3>Form Signatures</h3>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"status"}>
                  Status:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"loggedBy"}>
                  Logged by:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <JobStatusBadge status={status} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <ActorNameRankDisplay employee={recordedBy} />
                </ReadonlyField>
              </div>
            </div>

          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, false)}>
                {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}
                Save
              </button>

              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, true)}>
                {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}
                Save Draft
              </button>
            </div>
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
