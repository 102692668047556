export const TableLoadingIndicator = ({ colspan }) => {
  return (
    <tr>
      <td colSpan={colspan}>
        <div className={"table-loading-indicator"}>
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      </td>
    </tr>
  )
}

export const TableEmptyRow = ({ colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className={"table-empty-row"}>
          No data to display
        </div>
      </td>
    </tr>
  )
}
