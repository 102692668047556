import React from "react";

export const getSummaryCount = (data, summaryId) => {
  const summary = data.find((row) => row['id'] === summaryId);
  // NOTE(yemon): Array.find(..) returns `undefined` when no match was found, not `null`
  // for whatever reason. YavaScript funsies!!
  if (summary !== undefined) {
    return summary['count'];
  }
  else {
    return 0;
  }
}

export const TwoColumnsRow = ({ index, description, count, isNAColumn = false }) => {
  return (
    <tr>
      <td className={"index-col"}>{index+1}</td>
      <td className={`${isNAColumn ? "na-col" : ""}`}>
        {description}
      </td>
      <td className={`number-col ${count === 0 ? "zero-col" : ""}`}>
        {count}
      </td>
      <td></td>
    </tr>
  )
}

export const TwoColumnsFooter = ({ total }) => {
  return (
    <tfoot>
    <tr>
      <td className={"index-col"}></td>
      <td>
        <b>Total:</b>
      </td>
      <td className={"number-col"}>
        <b>{total}</b>
      </td>
      <td></td>
    </tr>
    </tfoot>
  )
}
