import React from "react";
import ReactModal from "react-modal";

import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";

import { TableEmptyRow } from "../shared/DataTable";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { SalesInquiryRow } from "./SalesInquiryListPage";

import "../shared/Modals.css";
import "../sales/SalesInquiryListPage.css";

export const SalesInquiryListModal = ({
                                        isOpen, isLoading,
                                        onRequestClose,
                                        salesInquiries,
                                        pagination,
                                        onInquiryRowClicked,
                                        onPageClicked,
                                        onPrevPageClicked,
                                        onNextPageClicked,
                                      }) => {
  //#region Utilities
  //#endregion

  //#region Render
  return (
    <ReactModal isOpen={isOpen} id={"sales-inquiries-modal"}
                className={"popup-modal"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onRequestClose}>
      <div className={"modal-heading"}>
        <h2>Sales Inquiries</h2>
      </div>
      {isLoading &&
        <div className={"modal-loading-indicator"}>
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      }
      {!isLoading &&
        <>
          <div className={"modal-floated-controls"}>
            <button type={"button"} className={"btn btn-light"} onClick={onRequestClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className={"modal-contents"}>
            <div>
              Select an entry from the table below to fill up the <b>Customer Details</b> form fields automatically.
            </div>
          </div>

          <div className={"modal-data-table inquiry-table"}>
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Contact No</th>
                <th scope="col">City/Township</th>
                <th scope="col">Source</th>
                <th scope="col">Business Type</th>
                <th scope="col">Last Entry Date</th>
                <th scope="col">Genset Model</th>
                <th scope="col">Visit/Call</th>
                <th scope="col">Follow Up Action</th>
                <th scope="col">Last Entry Reporter</th>
              </tr>
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={11} />}

              {salesInquiries && salesInquiries['data'].length > 0 &&
                salesInquiries['data'].map((inquiry, index) =>
                  <SalesInquiryRow key={inquiry['entryId']} inquiry={inquiry} index={index}
                                   currentPage={salesInquiries['pagination']['currentPage']} pageSize={salesInquiries['pagination']['pageSize']}
                                   onRowClicked={onInquiryRowClicked}
                                   allowExpandableAction={false}
                  />
                )}

              {!salesInquiries || (salesInquiries['data'].length === 0 &&
                <TableEmptyRow colSpan={11} />)
              }
              </tbody>
            </table>

            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={DEFAULT_LIST_PAGE_SIZE}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClicked}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
              />
            }
          </div>
        </>
      }
    </ReactModal>
  )
  //#endregion
}
