import { useNavigate } from "react-router-dom";

import { useAuth } from "./AuthProvider";
import { clearAllViewContexts } from "../../repos/viewContexts";

import './LoginInfo.css';

export const LoginInfo = () => {

  const auth = useAuth();
  const navigate = useNavigate();

  const onLogoutClicked = (ev) => {
    ev.preventDefault();
    auth.logout();
    clearAllViewContexts();
    navigate('/', { replace: true });
  }

  return (
    <>
      <div className="login-info">
        <div className="user-info">
          <div className="user-fullname">
            {auth.userInfo.fullName ?? "(NA)"}
          </div>
          <div className="user-rank">
            {auth.userInfo.rank ?? "(NA)"}
          </div>
        </div>
        <div className="logout">
          <a href="#" role="button" onClick={onLogoutClicked}>Logout</a>
        </div>
      </div>
    </>
  )

}
