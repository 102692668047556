import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";

import "../shared/SearchPanel.css";


const customerSources = refs.customer.source;

export const CustomerListSearchPanel = ({
                                          isSearchOpen, isSearchLoading, isListLoading,
                                          searchFields,
                                          onCustomerNameChanged,
                                          onBusinessTypeChanged,
                                          onSourceChanged,
                                          onSearchClicked, onClearClicked,
                                          hasErrors, errorMessage,
                                        }) => {
  //#region States
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const fetchBusinessTypes = () => {
    setIsLoadingBusinessTypes(true);
    refServices2.fetchBusinessTypes()
      .then((response) => setBusinessTypes(response.data))
      .finally(() => setIsLoadingBusinessTypes(false));
  }
  //#endregion

  //#region Utilities
  const getSearchPanelClass = () => {
    return "search-panel" + (isSearchOpen ? " search-panel-open" : " search-panel-hide");
  }

  const isFormControlsDisabled = () => {
    return isSearchLoading || isListLoading;
  }
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <div className={getSearchPanelClass()}>
      <form onSubmit={onSearchClicked}>
        <div className={"search-fields"}>
          <input type={"text"} id={"searchCustomerName"} className={"form-control md-field"} autoComplete={"off"} disabled={isFormControlsDisabled()}
                 placeholder={"Customer Name"} value={searchFields['customer_name']} onChange={onCustomerNameChanged} />
          <select name={"searchBusinessType"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled() || isLoadingBusinessTypes}
                  value={searchFields['business_type_id']} onChange={(ev) => onBusinessTypeChanged(parseInt(ev.target.value))}>
            <option value={-1}>- Any business types -</option>
            {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
          </select>
          <select name={"searchSource"} className={"form-control form-select md-field"} disabled={isFormControlsDisabled() || isLoadingBusinessTypes}
                  value={searchFields['source_id']} onChange={(ev) => onSourceChanged(parseInt(ev.target.value))}>
            <option value={-1}>- Any source -</option>
            <option value={customerSources.unspecified}>{customerSources[customerSources.unspecified]}</option>
            <option value={customerSources.sales}>{customerSources[customerSources.sales]}</option>
            <option value={customerSources.service}>{customerSources[customerSources.service]}</option>
          </select>
        </div>

        <div className={"search-fields"}>
          <FieldErrorMessage visible={hasErrors} message={errorMessage} />
        </div>

        <div className={"search-controls"}>
          <button type={"submit"} className={"btn btn-success"} disabled={isFormControlsDisabled()}
                  onClick={onSearchClicked}>
            {isSearchLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSearchLoading && <i className="fa-solid fa-magnifying-glass"></i>}
            Search
          </button>
          <button type={"button"} className={"btn btn-secondary"} disabled={isFormControlsDisabled()}
                  onClick={onClearClicked}>
            <i className="fa-solid fa-xmark"></i>
            Clear
          </button>
        </div>
      </form>
    </div>
  )
  //#endregion
}
