import React from "react";
import ReactModal from 'react-modal';
import Moment from "react-moment";

import { formatters } from "../../repos/constants";
import { formatCurrency } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";

import { TableEmptyRow } from "../shared/DataTable";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { GeneratorsSummary } from "../sales/GeneratorsSummary";

import "../shared/Modals.css";

export const OrderConfirmationCancelRequestsModal = ({
                                                       isOpen, isLoading, onRequestClose,
                                                       cancelRequests, onCancelRequestRowClicked,
                                                     }) => {
  //#region Render
  return (
    <ReactModal isOpen={isOpen} id={"order-confirmation-cancel-requests-modal"}
                className={"popup-modal"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onRequestClose}>
      <div className={"modal-heading"}>
        <h2>Cancel Requests</h2>
      </div>
      {isLoading &&
        <div className={"modal-loading-indicator"}>
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      }
      {!isLoading &&
        <>
          <div className={"modal-floated-controls"}>
            <button type={"button"} className={"btn btn-light"} onClick={onRequestClose}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div className={"modal-contents"}>
            <div>
              Order Confirmation cancel requests that are pending for review.
            </div>
          </div>

          <div className={"modal-data-table"}>
            <table>
              <thead>
              <tr>
                <th scope="col" className={"index-col-head"}>#</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Company</th>
                <th scope="col">Generators</th>
                <th scope="col" className={"right-aligned"}>Qty</th>
                <th scope="col" className={"right-aligned"}>Total Amount</th>
                <th scope="col">Cancel Requested Date</th>
                <th scope="col">Cancel Requested By</th>
              </tr>
              </thead>

              <tbody>
              {cancelRequests && cancelRequests.length > 0 &&
                cancelRequests.map((cancelRequest, index) =>
                  <CancelRequestRow key={cancelRequest['id']} index={index} cancelRequest={cancelRequest}
                                    onCancelRequestRowClicked={onCancelRequestRowClicked} />
                )}
              {(!cancelRequests || cancelRequests.length === 0) &&
                <TableEmptyRow colSpan={8} />
              }
              </tbody>
            </table>
          </div>
        </>
      }
    </ReactModal>
  )
  //#endregion
}


const CancelRequestRow = ({
                            cancelRequest, index,
                            onCancelRequestRowClicked,
                          }) => {
  const renderCompanyName = (customer) => {
    if (!customer['companyName'] || customer['companyName'] === '') return <span className="na-value">-</span>
    return <span>{customer['companyName']}</span>
  }

  const renderGeneratorsSummary = (generatorDetails) => {
    if (!generatorDetails || generatorDetails.length === 0) return <span className="na-value">-</span>
    return <GeneratorsSummary generators={generatorDetails} summarized={false} />
  }

  const renderTotalQuantity = (generatorDetails) => {
    if (!generatorDetails || generatorDetails.length === 0) return <span className={"na-value"}>0</span>
    return <>{generatorDetails.map(item => item['quantity']).reduce((accumulated, current) => accumulated + current)}</>
  }

  const renderTotalAmount = (generatorDetails) => {
    if (!generatorDetails || generatorDetails.length === 0) return <span className={"na-value"}>0</span>
    return (
      <>
        {formatCurrency(generatorDetails.map(item => item['quantity'] * item['price']).reduce((accumulated, current) => accumulated + current))}
      </>
    )
  }

  return (
    <tr key={index}>
      <td className={"index-col"}>{index + 1}</td>
      <td>
        <a href="#" role={"button"} className={"record-link"} onClick={(ev) => onCancelRequestRowClicked(ev, cancelRequest)}>
          {cancelRequest['customer']['customerName']}
        </a>
      </td>
      <td>
        {renderCompanyName(cancelRequest['customer'])}
      </td>
      <td>
        {renderGeneratorsSummary(cancelRequest['generators'])}
      </td>
      <td>
        {renderTotalQuantity(cancelRequest['generators'])}
      </td>
      <td>
        <span className="currency-symbol">{getCurrency(cancelRequest['currency']).sign}</span>
        {renderTotalAmount(cancelRequest['generators'])}
      </td>
      <td>
        <Moment date={cancelRequest['cancelRequestedDate']} format={formatters.datetime} />
      </td>
      <td>
        <ActorNameDisplay employee={cancelRequest['cancelRequestedBy']} />
      </td>
    </tr>
  )
}
