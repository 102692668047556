import React from "react";
import ReactModal from "react-modal";

import "../shared/Modals.css";
import "../shared/ModalControlsGrid.css";

export const modalSizes = {
  regular: 0,
  small: 1,
  tiny: 2,
};

export const ModalContainer = ({
                                 elementId, isOpen, onRequestClose, isLoading,
                                 title, shortDescription, modalSize,
                                 height, bottom,
                                 children,
                               }) => {
  const getModalSizeClassName = () => {
    let sizeClass = "popup-modal-small";
    if (modalSize) {
      if (modalSize === modalSizes.regular) {
        sizeClass = "";
      }
      else if (modalSize === modalSizes.small) {
        sizeClass = "popup-modal-small";
      }
      else if (modalSize === modalSizes.tiny) {
        sizeClass = "popup-modal-tiny";
      }
    }
    return sizeClass;
  }

  return (
    <ReactModal id={elementId} isOpen={isOpen} onRequestClose={onRequestClose}
                className={`popup-modal ${getModalSizeClassName()} popup-modal-expand`}
                overlayClassName={"popup-modal-overlay"}
                style={{ content: { maxHeight: height, bottom: bottom }}}>
      <div className={"modal-heading"}>
        <h2>{title}</h2>
      </div>
      {isLoading &&
        <div className={"modal-loading-indicator"}>
          <i className="fa-solid fa-circle-notch fa-spin"></i>
        </div>
      }
      <ModalCloseButton onRequestClose={onRequestClose} />
      <div className={"modal-contents"}>
        <div>
          {shortDescription}
        </div>
      </div>

      {children}
    </ReactModal>
  )
}

export const ModalCloseButton = ({ onRequestClose }) => {
  return (
    <div className={"modal-floated-controls"}>
      <button type={"button"} className={"btn btn-light"} onClick={onRequestClose}>
        <i className="fa-solid fa-xmark"></i>
      </button>
    </div>
  )
}
