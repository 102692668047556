import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { customerServices } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { role } from "../../repos/constants";
import { customer } from "../../repos/constants";
import { formModes } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatCurrency } from "../../repos/utilities";
import { doVirtualDownload } from "../../repos/exports";

import { BreadcrumbItem, Breadcrumbs } from "../shared/Breadcrumbs";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { Navigation } from "../shared/Navigation";
import { Footer } from "../shared/Footer";
import { ReadonlyField } from "../shared/ReadonlyField";
import { CancelStatus } from "../shared/CancelStatus";
import { EntryCustomerDetails } from "./EntryCustomerDetails";
import { EntryGeneratorsDetails } from "./EntryGeneratorsDetails";
import { EntryTermsAndConditions } from "./EntryTermsAndConditions";

import "./OrderConfirmationEntryPage.css";
import '../shared/EntryForm.css';


export function OrderConfirmationEntryPage() {
  //#region States
  const [formEditMode, setFormEditMode] = useState(formModes.orderConfirmation.newEntry);
  const [editingEntry, setEditingEntry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingDetails, setIsSubmittingDetails] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [hasCustomerErrors, setHasCustomerErrors] = useState(false);
  const [customerErrors, setCustomerErrors] = useState({});
  const [hasGeneratorErrors, setHasGeneratorErrors] = useState(false);
  const [generatorErrors, setGeneratorErrors] = useState({});
  const [hasTNCErrors, setHasTNCErrors] = useState(false);
  const [tncErrors, setTNCErrors] = useState({});

  const [entryPermissions, setEntryPermissions] = useState({});
  const [inquiryListPermissions, setInquiryListPermissions] = useState({});

  const [isExporting, setIsExporting] = useState(false);
  const [generalError, setGeneralError] = useState('');

  //#region Customer details
  const [entryDate, setEntryDate] = useState(null);
  const [currency, setCurrency] = useState(refs.currency.usd.code);
  const [customerName, setCustomerName] = useState('');
  const [customerNRC, setCustomerNRC] = useState('');
  const [customerDesignation, setCustomerDesignation] = useState('');
  const [customerCompanyName, setCustomerCompanyName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerPhoneNo, setCustomerPhoneNo] = useState('');

  const [customerProfileId, setCustomerProfileId] = useState(null);
  const [customerProfileStatus, setCustomerProfileStatus] = useState(customer.profileStatus.draft);
  //#endregion

  //#region Generator details
  const [orderConfirmationDetails, setOrderConfirmationDetails] = useState([]);
  const [gensetOrAtsKeyword, setGensetOrAtsKeyword] = useState('');
  const [gensetModelId, setGensetModelId] = useState('');
  const [atsId, setAtsId] = useState('');
  const [selectedItemLabel, setSelectedItemLabel] = useState('');
  const [selectedItemInfo, setSelectedItemInfo] = useState([]);
  const [isGeneratorSelected, setIsGeneratorSelected] = useState(true);
  const [gensetType, setGensetType] = useState(refs.inventory.generatorType.open);

  const [editingGeneratorDetails, setEditingGeneratorDetails] = useState(null);
  const [isGeneratorDetailsEditing, setIsGeneratorDetailsEditing] = useState(false);
  //#endregion

  //#region TNC details
  const [gensetPriceStr, setGensetPriceStr] = useState('0');
  const [gensetPrice, setGensetPrice] = useState(0);
  const [quantityStr, setQuantityStr] = useState('0');
  const [quantity, setQuantity] = useState(1);
  const [gensetAmount, setGensetAmount] = useState('0.00');
  const [paymentTerm, setPaymentTerm] = useState(refs.sales.paymentTerm.credit);
  const [creditPaymentTerm, setCreditPaymentTerm] = useState('');
  const [deliveryTerm, setDeliveryTerm] = useState('');
  const [warrantyTerm, setWarrantyTerm] = useState('');
  const [serviceTerm, setServiceTerm] = useState('');
  const [deposit, setDeposit] = useState('');
  const [remarks, setRemarks] = useState('');
  //#endregion
  //#endregion

  //#region Hooks
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [formOwnerStatus, setFormOwnerStatus] = useState(refs.sales.isFormOwner.inapplicable);
  //#endregion

  //#region Effects
  useEffect(() => {
    resetFormFields();
    setIsLoading(true);
    fetchPermissions().then(() => {
      if (location.state != null) {
        let state = location.state;
        if (state['entryId']) { // Edit mode
          setFormEditMode(formModes.orderConfirmation.editEntry);
          let selectedEntry = location.state;
          setEditingEntry(selectedEntry);
          preparePurchaseConfirmationForEditMode(selectedEntry);
          fetchOrderConfirmationDetails(selectedEntry['entryId']);
          return;
        }
        if (state['customerId']) { // Customer Profile quick action
          prepareCustomerDetails(state);
        }
      } else { // new mode
        prepareEntryForNewMode();
      }
    }).finally(() => setIsLoading(false));
  }, []);
  //#endregion

  //#region Utilities
  const isFormOwner = () => {
    return formOwnerStatus === refs.sales.isFormOwner.yes;
  }

  const isFormDraft = () => {
    if (editingEntry === null) return true;
    return editingEntry && editingEntry['reviewStatus'] === refs.sales.reviewStatus.draft;
  }

  const isFormReview = () => {
    if (editingEntry === null) return true;
    return editingEntry && editingEntry['reviewStatus'] === refs.sales.reviewStatus.pendingReview;
  }

  const isFormDraftOrReview = () => {
    if (editingEntry === null) return true;
    return editingEntry &&
      (editingEntry['reviewStatus'] === refs.sales.reviewStatus.draft || editingEntry['reviewStatus'] === refs.sales.reviewStatus.pendingReview);
  }

  const hasEditPermission = () => {
    return entryPermissions['editLevel'] !== role.orderConfirmation.editLevels.readOnly;
  }

  const shouldRenderInputFields = () => {
    return formEditMode === formModes.orderConfirmation.newEntry || formEditMode === formModes.orderConfirmation.editEntry;
  }

  const isEditingCustomer = () => {
    return formEditMode === formModes.orderConfirmation.editCustomer;
  }

  const isRequestSubmitted = () => {
    if (!editingEntry) return false;
    return (editingEntry['reviewStatus'] !== refs.sales.reviewStatus.draft && editingEntry['reviewStatus'] !== refs.sales.reviewStatus.pendingReview);
  }

  const isFormElementsDisabled = () => {
    return isSubmitting || isSubmittingDetails; // || formEditMode === formModes.orderConfirmation.editEntry;
  }

  const isRequestApproved = () => {
    return isRequestSubmitted() && editingEntry['reviewStatus'] === refs.sales.reviewStatus.approved;
  }

  const isRequestRejected = () => {
    return isRequestSubmitted() && editingEntry['reviewStatus'] === refs.sales.reviewStatus.rejected;
  }

  const isRequestReviewed = () => {
    return isRequestApproved() || isRequestRejected();
  }

  const userIsReviewer = () => {
    return entryPermissions && entryPermissions['reviewLevel'] === role.orderConfirmation.reviewLevels.reviewer;
  }

  const fetchPermissions = () => {
    return authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        setEntryPermissions(response.data['orderConfirmations']);
        setInquiryListPermissions(response.data['salesInquiries']);
      });
  }

  const fetchOrderConfirmationDetails = (entryId) => {
    setIsLoading(true);
    salesServices.fetchOrderConfirmationDetails(entryId)
      .then((response) => {
        setOrderConfirmationDetails(response.data['entryDetails']);
      })
      .finally(() => {
        setIsLoading(false);
        for (let item of orderConfirmationDetails) {
          item.isEditing = false;
        }
      });
  }

  const resetCustomerFields = () => {
    setEntryDate(new Date());
    setCustomerName('');
    setCustomerNRC('');
    setCustomerDesignation('');
    setCustomerCompanyName('');
    setCustomerAddress('');
    setCustomerPhoneNo('');
  }

  const resetFormFields = () => {
    resetCustomerFields();
    resetGeneratorFields();

    setPaymentTerm(refs.sales.paymentTerm.cash);
    setCreditPaymentTerm('');
    setDeliveryTerm('');
    setWarrantyTerm('');
    setServiceTerm('');
    setDeposit('');

    setGeneralError('');
  }

  const prepareEntryForEditMode = (editingEntry) => {
    setEntryDate(new Date(editingEntry['entryDatetime']));
    setCurrency(editingEntry['currency']);

    prepareCustomerDetails(editingEntry['customer']);
  }

  const prepareEntryForNewMode = () => {
    setFormEditMode(formModes.orderConfirmation.newEntry);
    setFormOwnerStatus(refs.sales.isFormOwner.yes);
  }

  const prepareCustomerDetails = (customer) => {
    setCustomerProfileId(customer['id']);
    setCustomerProfileStatus(customer['profileStatus']);

    setCustomerName(customer['customerName']);
    setCustomerNRC(customer['nrc'] ? customer['nrc'] : '');
    setCustomerDesignation(customer['designation'] ? customer['designation'] : '');
    setCustomerCompanyName(customer['companyName'] ? customer['companyName'] : '');
    setCustomerAddress(customer['address'] ? customer['address'] : '');
    setCustomerPhoneNo(customer['contactNo']);
  }

  const preparePurchaseConfirmationForEditMode = (editingEntry) => {
    prepareEntryForEditMode(editingEntry);

    setPaymentTerm(editingEntry['paymentTerm']);
    setCreditPaymentTerm(editingEntry['creditPaymentTerm']);
    setDeliveryTerm(editingEntry['deliveryTerm']);
    setWarrantyTerm(editingEntry['warrantyTerm']);
    setServiceTerm(editingEntry['serviceTerm']);
    setDeposit(editingEntry['deposit'] ? editingEntry['deposit'] : '');
    setRemarks(editingEntry['remarks'] ? editingEntry['remarks'] : '');

    setCancelReason(editingEntry['cancelReason'] ? editingEntry['cancelReason'] : '');
    setIsCancelExpanded(shouldExpandCancelSection(editingEntry));

    if (editingEntry['createdBy']['id'] === auth.getUserId()['eid']) {
      setFormOwnerStatus(refs.sales.isFormOwner.yes);
    }
    else {
      setFormOwnerStatus(refs.sales.isFormOwner.no);
    }
  }
  //#endregion

  //#region Control handlers - Customer details
  const onEntryDateChanged = (date) => {
    setEntryDate(date);
    setCustomerErrors({ ...customerErrors, entryDate: [] });
  }

  const onCurrencyChanged = (ev) => {
    setCurrency(parseInt(ev.target.value));
  }

  const onCustomerNameChanged = (ev) => {
    setCustomerName(ev.target.value);
    setCustomerErrors({ ...customerErrors, customerName: [] });
  }

  const onCustomerNRCChanged = (ev) => {
    setCustomerNRC(ev.target.value);
  }

  const onCustomerDesignationChanged = (ev) => {
    setCustomerDesignation(ev.target.value);
  }

  const onCustomerCompanyNameChanged = (ev) => {
    setCustomerCompanyName(ev.target.value);
  }

  const onCustomerAddressChanged = (ev) => {
    setCustomerAddress(ev.target.value);
  }

  const onCustomerPhoneNoChanged = (ev) => {
    setCustomerPhoneNo(ev.target.value);
    setCustomerErrors({ ...customerErrors, phoneNo: [] });
  }

  const fillUpCustomerDetailsFromInquiry = (salesInquiry) => {
    // customer details
    setCustomerName(salesInquiry['contactFullName']);
    let _addressShort = "";
    let _township = salesInquiry['township'];
    let _city = salesInquiry['city'];
    if (_township !== null) {
      _addressShort = _township['name'];
    } else {
      _addressShort = _city['name'];
    }
    setCustomerAddress(_addressShort);
    setCustomerPhoneNo(salesInquiry['contactNo']);

    // TODO(yemon): Refactor to take the sales inquiry's multiple generators into account
    if (salesInquiry['lastEntryGenerator'] !== null) {
      // NOTE(yemon): This won't happen at all, since the sales inquiry models don't have 'lastEntryGenerator' field anymore
      let inquiryGenerator = salesInquiry['lastEntryGenerator'];
    } else {
      setGensetOrAtsKeyword('');
      setGensetModelId('');
      resetGeneratorFields();
    }
  }

  const fillUpCustomerDetailsFromProfile = (customer) => {
    prepareCustomerDetails(customer);
  }

  const isCustomerFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      entryDate: [],
      customerName: [],
      phoneNo: [],
    }

    if (!entryDate) {
      _hasErrors = true;
      _formErrors['entryDate'] = ['Date is required.'];
    }
    if (!customerName || customerName.trim() === '') {
      _hasErrors = true;
      _formErrors['customerName'] = ['Customer name is required.'];
    }
    if (!customerPhoneNo || customerPhoneNo.trim() === '') {
      _hasErrors = true;
      _formErrors['phoneNo'] = ['Customer phone number is required.'];
    }

    setCustomerErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareCustomerPayload = () => {
    let requesterId = auth.getUserId()['eid'];
    return {
      'entry_datetime': entryDate,
      'currency': currency,
      'created_by_id': requesterId,
      'customer_name': customerName,
      'nrc': customerNRC,
      'designation': customerDesignation,
      'company_name': customerCompanyName,
      'address': customerAddress,
      'contact_no': customerPhoneNo,
    }
  }

  const onCustomerSaveClicked = (ev, isEditMode = false) => {
    if (!isCustomerFieldsValid()) {
      setHasCustomerErrors(true);
      return;
    } else setHasCustomerErrors(false);

    setIsSubmitting(true);
    let payload = {
      ...prepareCustomerPayload(),
    }
    if (customerProfileId) {
      payload['customer_id'] = customerProfileId;
    }
    if (!isEditMode) {
      salesServices.saveOrderConfirmationCustomerDetails(payload)
        .then((response) => {
          let orderConfirmation = response.data['orderConfirmation'];
          resetCustomerFields();
          setEditingEntry(orderConfirmation);
          prepareEntryForEditMode(orderConfirmation);
          setFormEditMode(formModes.orderConfirmation.editEntry);
        })
        .catch((error) => {
          console.error(error.response.data);
        })
        .finally(() => setIsSubmitting(false));
    } else {
      payload['entry_id'] = editingEntry['entryId'];
      salesServices.updateOrderConfirmationCustomerDetails(payload)
        .then((response) => {
          let orderConfirmation = response.data['orderConfirmation'];
          resetCustomerFields();
          setEditingEntry(orderConfirmation);
          prepareEntryForEditMode(orderConfirmation);
          setFormEditMode(formModes.orderConfirmation.editEntry);
        })
        .catch((error) => {
          console.error(error.response.data);
        })
        .finally(() => setIsSubmitting(false));
    }
  }

  const onCustomerCancelClicked = (ev) => {
    setTimeout(() => {
      setFormEditMode(formModes.orderConfirmation.editEntry);
    }, 200);
  }

  const onCustomerEditClicked = (ev) => {
    setTimeout(() => {
      setFormEditMode(formModes.orderConfirmation.editCustomer);
    }, 200);
  }

  const onCustomerProfileCreateClicked = (ev) => {
    setIsSubmitting(true);
    let employeeId = auth.getUserId()['eid'];
    let payload = {
      'employee_id': employeeId,
      'profile_id': customerProfileId,
      'new_profile_status': customer.profileStatus.active,
    };
    customerServices.setProfileStatus(payload)
      .then((response) => {
        let profile = response.data['profile'];
        setCustomerProfileStatus(profile['status']);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      })
  }
  //#endregion

  //#region Control handlers - Generator details
  const onGensetModelKeywordChanged = (ev, keyword, prevKeyword) => {
    if (keyword.trim() === '') {
      setGensetOrAtsKeyword('');
      setGensetModelId('');
      setAtsId('');
      resetGeneratorInfo();
      return false;
    }
    if (keyword === prevKeyword) {
      resetGeneratorInfo();
      return false;
    }
    setGensetOrAtsKeyword(keyword);
    setGensetModelId('');
    setAtsId('');
    setGeneratorErrors({ ...generatorErrors, generatorModelKeyword: [] });
    resetGeneratorInfo();
    return true;
  }

  const resetGeneratorInfo = () => {
    setSelectedItemLabel('Generator Info');
    setSelectedItemInfo([]);
    setIsGeneratorSelected(true);
  }

  const resetGeneratorFields = () => {
    setGensetOrAtsKeyword('');
    setGensetModelId('');
    setAtsId('');
    resetGeneratorInfo();
    setGensetType(refs.inventory.generatorType.open);
    setGensetPrice(0);
    setGensetPriceStr('0');
    setQuantity(1);
    setQuantityStr('1');
    setGensetAmount(formatCurrency(0));
  }

  const onGeneratorSuggestionClicked = (ev, generator) => {
    setGensetOrAtsKeyword(generator['gensetModel']);
    setGensetModelId(generator['id']);
    setAtsId('');
    setIsGeneratorSelected(true);
    setGeneratorErrors({ ...generatorErrors, gensetModelKeyword: [] });

    let itemInfo = [];
    if (generator['engineModel']) itemInfo.push(`Engine: ${generator['engineModel']}`);
    if (generator['alternator']) itemInfo.push(`Alternator: ${refs.inventory.generatorAlternators[generator['alternator']]}`);
    if (generator['powerStandby']) itemInfo.push(`Standby Power: ${generator['powerStandby']}`);
    if (generator['powerPrime']) itemInfo.push(`Prime Power: ${generator['powerPrime']}`);
    setSelectedItemLabel('Generator Info');
    setSelectedItemInfo(itemInfo);
  }

  const onAtsSuggestionClicked = (ev, ats) => {
    setGensetOrAtsKeyword(ats['name']);
    setGensetModelId('');
    setAtsId(ats['id']);
    setIsGeneratorSelected(false);
    setGeneratorErrors({ ...generatorErrors, gensetModelKeyword: [] });

    let itemInfo = [];
    if (ats['kva'] !== null) itemInfo.push(`KVA Unit: ${ats['kva']}`);
    setSelectedItemLabel('ATS Info');
    setSelectedItemInfo(itemInfo);
  }

  const onGensetPriceStrChanged = (ev) => {
    setGensetPriceStr(ev.target.value.trim());
    setGeneratorErrors({ ...generatorErrors, gensetPrice: [] });
  }

  const onGensetPriceStrBlurred = (ev) => {
    // sanitize the genset price string value, and track it in a separate float state
    let _priceStr = ev.target.value.trim();
    if (_priceStr === '' || isNaN(parseFloat(_priceStr))) { // blank or unable to parse
      setGensetPriceStr('0');
      setGensetPrice(0);
      setGensetAmount(formatCurrency(0));
    } else { // parsed, but invalid
      let _price = parseFloat(_priceStr);
      if (_price <= 0) {
        setGensetPriceStr('0');
        setGensetPrice(0);
        setGensetAmount(formatCurrency(0));
      } else {
        setGensetPriceStr(_price.toString());
        setGensetPrice(_price);
        setGensetAmount(formatCurrency(quantity * _price));
      }
    }
  }

  const onQuantityStrChanged = (ev) => {
    setQuantityStr(ev.target.value.trim());
    setGeneratorErrors({ ...generatorErrors, quantity: [] });
  }

  const onQuantityStrBlurred = (ev) => {
    // sanitize the genset quantity string value, and track it in a separate int state
    let _quantityStr = ev.target.value.trim();
    if (_quantityStr === '' || isNaN(parseInt(_quantityStr))) { // blank or unable to parse
      setQuantityStr('1');
      setQuantity(1);
      setGensetAmount(formatCurrency(0));
    } else {  // parsed, but invalid
      let _quantity = parseInt(_quantityStr);
      if (_quantity < 1) {
        _quantity = 1;
        setQuantityStr('1');
        setQuantity(1);
      } else {
        setQuantityStr(_quantity.toString());
        setQuantity(_quantity);
      }
      setGensetAmount(formatCurrency(_quantity * gensetPrice));
    }
  }

  const prepareGeneratorPayload = () => {
    return {
      'generator_id': gensetModelId !== '' ? gensetModelId : null,
      'ats_id': atsId !== '' ? atsId : null,
      'type': isGeneratorSelected ? gensetType : refs.inventory.generatorType.none,
      'price': gensetPrice,
      'quantity': quantity,
    }
  }

  const onGensetTypeChanged = (ev) => {
    setGensetType(parseInt(ev.target.value));
  }

  const isGeneratorFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      gensetModelKeyword: [],
      gensetPrice: [],
      quantity: [],
    }

    if (gensetModelId === '' && atsId === '') {
      _hasErrors = true;
      _formErrors['gensetModelKeyword'] = ['Please make sure to select a proper genset (or) ATS item.'];
    }

    if (gensetPrice <= 0) {
      _hasErrors = true;
      _formErrors['gensetPrice'] = ['Please enter a valid price value.'];
    }
    if (quantity < 1) {
      _hasErrors = true;
      _formErrors['quantity'] = ['Quantity should be more than 1.'];
    }

    setGeneratorErrors(_formErrors);
    return !_hasErrors;
  }

  const onDetailsItemAddClicked = (ev) => {
    if (!isGeneratorFieldsValid()) {
      setHasGeneratorErrors(true);
      //setSubmissionError('Fix the errors before submitting for review.');
      return;
    } else {
      setHasGeneratorErrors(false);
      //setSubmissionError('');
    }

    let payload = {
      ...prepareGeneratorPayload(),
      'order_confirmation_id': editingEntry['entryId'],
    }
    setIsSubmittingDetails(true);
    salesServices.addOrderConfirmationDetails(payload)
      .then((response) => {
        fetchOrderConfirmationDetails(editingEntry['entryId']);
        resetGeneratorFields();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsSubmittingDetails(false));
  }

  const onDetailsItemRowSelected = (ev, detailsItem, rowIndex) => {
    ev.preventDefault();
    for (let item of orderConfirmationDetails) {
      item.isEditing = false;
    }
    detailsItem.isEditing = true;
    prepareDetailsEntryForEditMode(detailsItem);
  }

  const onDetailsEditClicked = (ev) => {
    setTimeout(() => {
      setIsGeneratorDetailsEditing(true);
    }, 200);
  }

  const onDetailsClearClicked = (ev) => {
    for (let item of orderConfirmationDetails) {
      item.isEditing = false;
    }
    setTimeout(() => {
      setIsGeneratorDetailsEditing(false);
      setEditingGeneratorDetails(null);
      resetGeneratorFields();
    }, 200);
  }

  const prepareDetailsEntryForEditMode = (detailsEntry) => {
    let _editingDetailsEntry = detailsEntry;
    setEditingGeneratorDetails(_editingDetailsEntry);
    resetGeneratorFields();
    if (_editingDetailsEntry['generator']) {
      setGensetOrAtsKeyword(_editingDetailsEntry['gensetModel']);
      setGensetModelId(_editingDetailsEntry['generatorId']);
      setAtsId('');
      onGeneratorSuggestionClicked(null, _editingDetailsEntry['generator']);
    } else if (_editingDetailsEntry['ats']) {
      setGensetOrAtsKeyword(_editingDetailsEntry['atsName']);
      setGensetModelId('');
      setAtsId(_editingDetailsEntry['atsId']);
      onAtsSuggestionClicked(null, _editingDetailsEntry['ats']);
    }
    setGensetType(_editingDetailsEntry['type']);
    let price = _editingDetailsEntry['price'];
    setGensetPrice(price);
    setGensetPriceStr(price.toString());
    let quantity = _editingDetailsEntry['quantity'];
    setQuantity(quantity);
    setQuantityStr(quantity.toString());
    setGensetAmount(formatCurrency(price * quantity));
  }

  const onDetailsUpdateClicked = (ev) => {
    if (!isGeneratorFieldsValid()) {
      setHasGeneratorErrors(true);
      //setSubmissionError('Fix the errors before submitting for review.');
      return;
    } else {
      setHasGeneratorErrors(false);
      //setSubmissionError('');
    }

    let payload = {
      ...prepareGeneratorPayload(),
      'details_id': editingGeneratorDetails['detailsId'],
      'order_confirmation_id': editingEntry['entryId'],
    }
    setIsSubmittingDetails(true);
    salesServices.updateOrderConfirmationDetails(payload)
      .then((response) => {
        fetchOrderConfirmationDetails(editingEntry['entryId']);
        setIsGeneratorDetailsEditing(false);
        setEditingGeneratorDetails(null);
        resetGeneratorFields();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsSubmittingDetails(false));
  }

  const onDetailsUpdateCancelClicked = (ev) => {
    setTimeout(() => {
      setIsGeneratorDetailsEditing(false);
    }, 200);
  }

  const onDetailsItemRemoveClicked = (ev, detailsEntry, rowIndex) => {
    ev.preventDefault();
    onDetailsClearClicked(null);
    setIsSubmittingDetails(true);
    salesServices.removeOrderConfirmationDetails(editingEntry['entryId'], detailsEntry['detailsId'])
      .then((response) => {
        fetchOrderConfirmationDetails(editingEntry['entryId']);
      })
      .finally(() => setIsSubmittingDetails(false));
  }
  //#endregion

  //#region Control handlers - TNC details
  const onPaymentTermChanged = (ev) => {
    setPaymentTerm(parseInt(ev.target.value));
  }

  const onCreditPaymentTermChanged = (ev) => {
    setCreditPaymentTerm(ev.target.value);
  }

  const onDeliveryTermChanged = (ev) => {
    setDeliveryTerm(ev.target.value);
  }

  const onWarrantyTermChanged = (ev) => {
    setWarrantyTerm(ev.target.value);
  }

  const onServiceTermChanged = (ev) => {
    setServiceTerm(ev.target.value);
  }

  const onDepositChanged = (ev) => {
    setDeposit(ev.target.value);
    setTNCErrors({ ...tncErrors, deposit: [] });
  }

  const onRemarksChanged = (ev) => {
    setRemarks(ev.target.value);
  }

  const isTNCFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      'creditPaymentTerm': [],
      'deposit': [],
    }
    if (paymentTerm === refs.sales.paymentTerm.credit && !creditPaymentTerm) {
      _hasErrors = true;
      _formErrors['creditPaymentTerm'] = ["Payment term has to be specified for 'Credit' payment mode."];
    }

    setTNCErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareTNCPayload = () => {
    return {
      'genset_price': gensetPrice,
      'quantity': quantity,
      'payment_term': paymentTerm,
      'credit_payment_term': paymentTerm === refs.sales.paymentTerm.credit ? creditPaymentTerm : "",
      'delivery_term': deliveryTerm ? deliveryTerm : "",
      'warranty_term': warrantyTerm ? warrantyTerm : "",
      'service_term': serviceTerm ? serviceTerm : "",
      'deposit': deposit ? deposit : "0",
      'remarks': remarks ? remarks : "",
    }
  }
  //#endregion

  //#region Control handlers
  const onSubmitClicked = (ev, draftState) => {
    if (!isTNCFieldsValid()) {
      setHasTNCErrors(true);
      return;
    } else setTNCErrors(false);

    let payload = {
      ...prepareTNCPayload(),
    }
    payload['entry_id'] = editingEntry['entryId'];
    payload['draft_state'] = draftState;
    if (draftState === refs.sales.draftState.submitDraft || draftState === refs.sales.draftState.submitReviewUpdate) {
      setIsSavingDraft(true);
    } else {
      setIsSubmitting(true);
    }
    salesServices.submitOrderConfirmation(payload)
      .then((response) => {
        setEditingEntry(response.data['orderConfirmation']);
        // if (!isDraft) navigate(routes.orderConfirmationsList.url);
      })
      .catch((error) => {
        console.error(error.response.data);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsSavingDraft(false);
      });
  }

  const onApproveOrRejectClicked = (ev, status) => {
    ev.preventDefault();
    let reviewerId = auth.getUserId()['eid'];
    let payload = {
      'entry_id': editingEntry['entryId'],
      'reviewed_by': reviewerId,
      'review_status': status,
    };
    setIsSubmitting(true);

    salesServices.approveOrRejectOrderConfirmation(payload)
      .then((response) => {
        editingEntry['reviewStatus'] = parseInt(response.data['reviewStatus']);
      })
      .catch((error) => {
        console.error(error['response']);
        let _error = error['response']['data']['error'];
        setGeneralError(_error);
      })
      .finally(() => setIsSubmitting(false));
  }

  const onPaymentBreakdownClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.orderConfirmationPaymentBreakdown.url, { state: editingEntry });
    }, 200);
  }

  const onExportClicked = (ev) => {
    let payload = {
      uid: auth.getUserId(),
      entryId: editingEntry['entryId'],
    };

    setIsExporting(true);
    setGeneralError('');
    salesServices.postOrderConfirmationExport(payload)
      .then((response) => {
        doVirtualDownload(response, 'OrderConfirmation', 'xlsx');
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
        setGeneralError(errorResponse['data']['error']);
      })
      .finally(() => setIsExporting(false));
  }
  //#endregion

  //#region Control handlers - Cancellation
  const [isCancelExpanded, setIsCancelExpanded] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [isCancelSubmitting, setIsCancelSubmitting] = useState(false);
  const [hasCancelErrors, setHasCancelErrors] = useState(false);
  const [cancelError, setCancelError] = useState('');

  const onCancelExpandClicked = (ev) => {
    setIsCancelExpanded(!isCancelExpanded);
  }

  const onCancelReasonChanged = (ev) => {
    setCancelReason(ev.target.value);
  }

  const onCancelSubmitClicked = (ev) => {
    let _hasError = false;
    let _errorMsg = '';
    if (cancelReason.trim() === '') {
      _hasError = true;
      _errorMsg = 'Please provide a valid reason.';
    }
    setHasCancelErrors(_hasError);
    if (_hasError) {
      setCancelError(_errorMsg);
      return;
    }

    let payload = {
      'entry_id': editingEntry['entryId'],
      'cancel_reason': cancelReason,
      'eid': auth.getUserId()['eid'],
    }

    setIsCancelSubmitting(true);
    salesServices.submitOrderConfirmationCancel(payload)
      .then((response) => {
        let updatedEntry = response.data['orderConfirmation'];
        setEditingEntry(updatedEntry);

        setCancelReason(updatedEntry['cancelReason'] ? updatedEntry['cancelReason'] : '');
        setIsCancelExpanded(shouldExpandCancelSection(updatedEntry));
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => setIsCancelSubmitting(false));
  }

  const cancelNeedsReview = () => {
    if (entryPermissions['cancelLevel'] !== role.orderConfirmation.cancelLevels.reviewer) return false;
    let _cancelStatus = editingEntry['cancelStatus'];
    if (_cancelStatus === null) return false;
    if (_cancelStatus === refs.sales.reviewStatus.approved || _cancelStatus === refs.sales.reviewStatus.rejected) return false;
    return true;
  }

  const cancelHasBeenReviewed = () => {
    if (entryPermissions['cancelLevel'] !== role.orderConfirmation.cancelLevels.reviewer) return false;
    if (editingEntry === null) return false;
    let _cancelStatus = editingEntry['cancelStatus'];
    if (_cancelStatus === null) return false;
    if (_cancelStatus === refs.sales.reviewStatus.approved || _cancelStatus === refs.sales.reviewStatus.rejected) return true;
    return false;
  }

  const isAllowCancelButton = () => {
    if (isFormDraftOrReview() || editingEntry === null) return false;
    let allowCancel = false;
    if (editingEntry['createdBy']['id'] === auth.getUserId()['eid']) {
      allowCancel = true;
    }
    return allowCancel;
  }

  const shouldExpandCancelSection = (entry) => {
    let shouldExpand = false;
    let _cancelStatus = entry['cancelStatus'];
    if (_cancelStatus &&
      (_cancelStatus === refs.sales.reviewStatus.pendingReview ||
        _cancelStatus === refs.sales.reviewStatus.approved ||
        _cancelStatus === refs.sales.reviewStatus.rejected)) {
      shouldExpand = true;
    }
    return shouldExpand;
  }

  const isEntryCanceled = () => {
    if (editingEntry === null) return false;
    let _entryCanceled = false;
    let _cancelStatus = editingEntry['cancelStatus'];
    if (_cancelStatus &&
      (_cancelStatus === refs.sales.reviewStatus.approved || _cancelStatus === refs.sales.reviewStatus.rejected)) {
      _entryCanceled = true;
    }
    return _entryCanceled;
  }

  const onCancelApproveOrRejectClicked = (ev, status) => {
    let reviewerId = auth.getUserId()['eid'];
    let payload = {
      'entry_id': editingEntry['entryId'],
      'reviewer_id': reviewerId,
      'review_status': status,
    };
    setIsSubmitting(true);

    salesServices.reviewOrderConfirmationCancel(payload)
      .then((response) => {
        let updatedEntry = response.data['orderConfirmation'];
        setEditingEntry(updatedEntry);
      })
      .catch((error) => {
        console.error(error['response']);
        let _error = error['response']['data']['error'];
        setCancelError(_error);
      })
      .finally(() => setIsSubmitting(false));
  }
  //#endregion

  //#region Render
  return (
    <>
      <Navigation />

      <main className={"content-container purchase-agreements-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.orderConfirmationsList.displayShort} anchorTo={routes.orderConfirmationsList.url} />
              <BreadcrumbItem text={routes.orderConfirmationEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Order Confirmation Entry</h1>
          </div>

          <form autoComplete="off">
            <EntryCustomerDetails editingEntry={editingEntry} formMode={formEditMode}
                                  entryDate={entryDate} onEntryDateChanged={onEntryDateChanged}
                                  currency={currency} onCurrencyChanged={onCurrencyChanged}
                                  customerProfileId={customerProfileId} customerProfileStatus={customerProfileStatus}
                                  customerName={customerName} onCustomerNameChanged={onCustomerNameChanged}
                                  nrc={customerNRC} onNRCChanged={onCustomerNRCChanged}
                                  designation={customerDesignation} onDesignationChanged={onCustomerDesignationChanged}
                                  companyName={customerCompanyName} onCompanyNameChanged={onCustomerCompanyNameChanged}
                                  address={customerAddress} onAddressChanged={onCustomerAddressChanged}
                                  phoneNo={customerPhoneNo} onPhoneNoChanged={onCustomerPhoneNoChanged}
                                  hasErrors={hasCustomerErrors} formErrors={customerErrors}
                                  isSubmitting={isSubmitting || isSavingDraft} isFormOwner={isFormOwner}
                                  fillUpDetailsFromInquiry={fillUpCustomerDetailsFromInquiry}
                                  fillUpDetailsFromProfile={fillUpCustomerDetailsFromProfile}
                                  onSaveClicked={onCustomerSaveClicked}
                                  onCancelClicked={onCustomerCancelClicked} onEditClicked={onCustomerEditClicked}
                                  onCreateProfileClicked={onCustomerProfileCreateClicked}
            />

            <EntryGeneratorsDetails editingEntry={editingEntry} editingDetailsEntry={editingGeneratorDetails} formMode={formEditMode} currency={currency}
                                    orderConfirmationDetails={orderConfirmationDetails}
                                    gensetOrAtsKeyword={gensetOrAtsKeyword} gensetModelId={gensetModelId} atsId={atsId}
                                    onGensetOrAtsKeywordChanged={onGensetModelKeywordChanged}
                                    onGeneratorSuggestionClicked={onGeneratorSuggestionClicked} onAtsSuggestionClicked={onAtsSuggestionClicked}
                                    selectedItemLabel={selectedItemLabel} selectedItemInfo={selectedItemInfo} isGeneratorSuggested={isGeneratorSelected}
                                    type={gensetType} onTypeChanged={onGensetTypeChanged}
                                    gensetPrice={gensetPriceStr} onGensetPriceChanged={onGensetPriceStrChanged} onGensetPriceBlurred={onGensetPriceStrBlurred}
                                    quantity={quantityStr} onQuantityChanged={onQuantityStrChanged} onQuantityBlurred={onQuantityStrBlurred}
                                    gensetAmount={gensetAmount}
                                    isSubmitting={isSubmitting} isSubmittingDetails={isSubmittingDetails}
                                    hasErrors={hasGeneratorErrors} formErrors={generatorErrors} isLoading={isLoading}
                                    isFormSubmitted={isRequestSubmitted} isFormDraft={isFormDraftOrReview}
                                    isFormOwner={isFormOwner} isDetailsEditing={isGeneratorDetailsEditing}
                                    hasEditPermission={hasEditPermission}
                                    shouldRenderInputFields={shouldRenderInputFields}
                                    onAddClicked={onDetailsItemAddClicked} onItemRowSelected={onDetailsItemRowSelected}
                                    onEditClicked={onDetailsEditClicked} onClearClicked={onDetailsClearClicked}
                                    onUpdateClicked={onDetailsUpdateClicked} onCancelClicked={onDetailsUpdateCancelClicked}
                                    onRemoveClicked={onDetailsItemRemoveClicked}
            />

            <EntryTermsAndConditions editingEntry={editingEntry} formMode={formEditMode} currency={currency}
                                     paymentTerm={paymentTerm} onPaymentTermChanged={onPaymentTermChanged}
                                     creditPaymentTerm={creditPaymentTerm} onCreditPaymentTermChanged={onCreditPaymentTermChanged}
                                     deliveryTerm={deliveryTerm} onDeliveryTermChanged={onDeliveryTermChanged}
                                     warrantyTerm={warrantyTerm} onWarrantyTermChanged={onWarrantyTermChanged}
                                     serviceTerm={serviceTerm} onServiceTermChanged={onServiceTermChanged}
                                     deposit={deposit} onDepositChanged={onDepositChanged}
                                     remarks={remarks} onRemarksChanged={onRemarksChanged}
                                     hasErrors={hasTNCErrors} formErrors={tncErrors}
                                     isSubmitting={isSubmitting || isSavingDraft}
                                     entryPermissions={entryPermissions}
                                     onPaymentBreakdownClicked={onPaymentBreakdownClicked}
                                     isFormDraftOrReview={isFormDraftOrReview} isFormOwner={isFormOwner}
                                     hasEditPermission={hasEditPermission}
                                     shouldRenderInputFields={shouldRenderInputFields}
            />

            {formEditMode !== formModes.orderConfirmation.newEntry &&
              <div className={"form-section"}>
                <div className={"form-button-controls"}>
                  <div className={"left-side"}>
                    {isFormOwner() && !isEntryCanceled() && !userIsReviewer() &&
                      <button type={"button"} className={"btn btn-primary"}
                              disabled={isSubmitting || isSavingDraft || !isFormDraft() || isExporting || isCancelSubmitting || isEditingCustomer()}
                              onClick={(ev) => onSubmitClicked(ev, refs.sales.draftState.submitReview)}>
                        {isSubmitting && isFormDraft() && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                        {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                        <span>{isFormDraft() ? "Submit for review" : "Submitted for review"}</span>
                      </button>
                    }
                    {isFormOwner() && isFormDraft() &&
                      <button type={"button"} className={"btn btn-secondary"}
                              disabled={isSubmitting || isSavingDraft || isExporting || isCancelSubmitting || isEditingCustomer()}
                              onClick={(ev) => onSubmitClicked(ev, refs.sales.draftState.submitDraft)}>
                        {isSavingDraft && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                        {!isSavingDraft && <i className={"fa-solid fa-floppy-disk"}></i>}
                        <span>Save Draft</span>
                      </button>
                    }
                    {isFormOwner() && isFormReview() &&
                      <button type={"button"} className={"btn btn-secondary"}
                              disabled={isSavingDraft || isEditingCustomer()}
                              onClick={(ev) => onSubmitClicked(ev, refs.sales.draftState.submitReviewUpdate)}>
                        {isSavingDraft && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                        {!isSavingDraft && <i className={"fa-solid fa-floppy-disk"}></i>}
                        <span>Update</span>
                      </button>
                    }

                    {userIsReviewer() && !isEntryCanceled() &&
                      <>
                        <button className={"btn btn-success"} disabled={isSubmitting || isRequestReviewed()}
                                onClick={(ev) => onApproveOrRejectClicked(ev, refs.sales.reviewStatus.approved)}>
                          {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                          {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                          <span>{isRequestApproved() ? "Approved" : "Approve"}</span>
                        </button>
                        <button className={"btn btn-danger"} disabled={isSubmitting || isRequestReviewed()}
                                onClick={(ev) => onApproveOrRejectClicked(ev, refs.sales.reviewStatus.rejected)}>
                          {!isSubmitting && <i className={"fa-solid fa-xmark"}></i>}
                          <span>{isRequestRejected() ? "Rejected" : "Reject"}</span>
                        </button>
                      </>
                    }

                    {isEntryCanceled() &&
                      <button type={"button"} className={"btn btn-secondary"} disabled={true} onClick={null}>
                        <i className="fa-solid fa-circle-xmark"></i>
                        <span>Confirmation Canceled</span>
                      </button>
                    }
                    {isAllowCancelButton() && !isEntryCanceled() &&
                      <button type={"button"} className={"btn btn-secondary"}
                              disabled={isSubmitting || isSavingDraft || isExporting || isCancelSubmitting}
                              onClick={onCancelExpandClicked}>
                        {!isCancelExpanded && <i className="fa-solid fa-circle-xmark"></i>}
                        {isCancelExpanded && <i className="fa-solid fa-circle-chevron-down"></i>}
                        <span>Cancel</span>
                      </button>
                    }

                    {!isFormDraftOrReview() && isRequestReviewed() &&
                      <button type={"button"} className={"btn btn-secondary left-margin"}
                              disabled={isSubmitting || isSavingDraft || isExporting || isCancelSubmitting}
                              onClick={onExportClicked}>
                        {isExporting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                        {!isExporting && <i className={"fa-solid fa-file-export"}></i>}
                        <span>Export</span>
                      </button>
                    }

                    {generalError &&
                      <div className={"inline-error-message"}>
                        {generalError}
                      </div>
                    }
                  </div>
                </div>

                {isCancelExpanded &&
                  <>
                    <h2>Cancel Order Confirmation</h2>

                    <div className={"entry-form"}>
                      <div className={"form-label"}>
                        <label htmlFor={"gensetPrice"}>
                          Reason:<span className={"error-message"}>*</span>
                        </label>
                      </div>
                      <div className={"form-input"}>
                        {!isEntryCanceled() && !cancelNeedsReview() &&
                          <>
                            <textarea id={"cancelReason"} className={"form-control"} rows={3} autoComplete={"none"} disabled={isCancelSubmitting}
                                      value={cancelReason} onChange={onCancelReasonChanged}>
                            </textarea>
                            <FieldErrorMessage visible={hasCancelErrors} message={cancelError} />
                          </>
                        }
                        {(isEntryCanceled() || cancelNeedsReview()) && <ReadonlyField>{cancelReason}</ReadonlyField>}
                      </div>
                    </div>

                    {!isEntryCanceled() &&
                      <div className={"form-button-controls"}>
                        <div className={"left-side"}>
                          {!cancelNeedsReview() &&
                            <button type={"button"} className={"btn btn-secondary"}
                                    disabled={isSubmitting || isCancelSubmitting}
                                    onClick={onCancelSubmitClicked}>
                              {isCancelSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                              {!isCancelSubmitting && <i className="fa-solid fa-file-circle-xmark"></i>}
                              <span>Submit Cancel</span>
                            </button>
                          }
                          {cancelNeedsReview() &&
                            <>
                              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isCancelSubmitting}
                                      onClick={(ev) => onCancelApproveOrRejectClicked(ev, refs.sales.reviewStatus.approved)}>
                                {isCancelSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                                {!isCancelSubmitting && <i className={"fa-solid fa-check"}></i>}
                                <span>Approve</span>
                              </button>
                              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isCancelSubmitting}
                                      onClick={(ev) => onCancelApproveOrRejectClicked(ev, refs.sales.reviewStatus.rejected)}>
                                <i className={"fa-solid fa-xmark"}></i>
                                <span>Reject</span>
                              </button>
                            </>
                          }
                          {!cancelNeedsReview() && editingEntry['cancelStatus'] !== null &&
                            <span className={"cancel-warning"}>
                              <i className="fa-solid fa-triangle-exclamation"></i>
                              <span>Waiting for approval.</span>
                            </span>
                          }
                          {cancelHasBeenReviewed() && editingEntry['cancelStatus'] !== null &&
                            <>{editingEntry['cancelStatus']}</>
                          }
                        </div>
                      </div>
                    }

                    {cancelHasBeenReviewed() &&
                      <div className={"form-button-controls"}>
                        <div className={"left-side"}>
                          <CancelStatus status={editingEntry['cancelStatus']}
                                        reviewedBy={editingEntry['cancelReviewedBy']}
                                        reviewDate={editingEntry['cancelReviewDate']} />
                        </div>
                      </div>
                    }
                  </>
                }

              </div>
            }
          </form>

        </div>
      </main>

      <Footer />
    </>
  )
  //#endregion
}
