import React from "react";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { refs } from "../../repos/constants";
import { utils } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { getFollowUpStageActionDisplay } from "../../repos/utilities";
import { getCurrencyDisplay } from "../../repos/utilities";
import { formatCurrency } from "../../repos/utilities";
import { getDisplayTermFromGeneratorDetail } from "../../repos/utilities";

import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ReadonlyField } from "../shared/ReadonlyField";
import { ActorNameDisplay } from "./ActorNameDisplay";
import { AutoCompleteGroupedResults } from "../shared/AutoCompleteResults";
import { InfoMessage } from "./FormMessages";
import { ErrorMessage } from "./FormMessages";
import { FormSideLabel } from "./FormSideLabel";
import { InquiryAssignModal } from "./InquiryAssignModal";
import { FollowUpStageNestedDropdown } from "./CustomDropdowns";
import { GeneratorsSummary } from "./GeneratorsSummary";


export const EntryInquiryDetails = ({
                                      reportDate, gensetModelId, atsId, gensetOrAtsKeyword, generatorDetails, contactForm, discussionDetails,
                                      followUpStageActionId, followUpStageActionDisplay, hasReminder, reminderDate,
                                      isLostSales, lostReason, competitorName, competitorCurrency, competitorPrice, competitorBrand,
                                      competitorDelivery, lostRemarks,
                                      onReportDateChanged, onGensetOrAtsKeywordChanged, onGeneratorSuggestionClicked, onAtsSuggestionClicked,
                                      onGensetModelAddClicked, onGensetModelRemoveClicked, onContactFormChanged, onDiscussionDetailsChanged,
                                      onFollowUpStageActionIdChanged, onHasReminderChanged, onReminderDateChanged, onLostReasonChanged,
                                      onCompetitorNameChanged, onCompetitorCurrencyChanged, onCompetitorPriceChanged, onCompetitorPriceBlurred,
                                      onCompetitorBrandChanged, onCompetitorDeliveryChanged, onLostRemarksChanged,
                                      isContactEditMode, isContactEditing, isSubmitting, hasErrors, followUpErrors, editingEntry,
                                      isEditAllowed, isInquiryEditMode, isInquiryEditing, isReviewAllowed, editingInquiryHistory, inquiryUpdateRequest,
                                      onSubmitClicked, onUpdateEditClicked, onUpdateClearClicked, onUpdateSubmitClicked, onUpdateCancelClicked,
                                      onApproveClicked, onRejectClicked, isReminderEditAllowed,
                                    }) => {
  //#region States
  const [followUpStagesRef, setFollowUpStagesRef] = useState([]);
  const [followUpActionsRef, setFollowUpActionsRef] = useState([]);

  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);
  const [atsSuggestions, setAtsSuggestions] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
  }, []);
  //#endregion

  //#region Utilities
  const isLastReminderOverdue = () => {
    if (editingEntry.lastEntryReminderDatetime == null) return false;
    let reminderDate = new Date(editingEntry.lastEntryReminderDatetime);
    return reminderDate < new Date();
  }

  const getReportDateClassName = () => {
    let className = "form-control";
    if (reportDate) className += " has-autocompleted-value";
    return className;
  }

  const getGensetOrAtsClassName = () => {
    let className = "form-control";
    if (gensetModelId || atsId) className += " has-autocompleted-value";
    return className;
  }

  const hasSuggestionResults = () => {
    return generatorSuggestions.length > 0 || atsSuggestions.length > 0;
  }

  const expandGensetSuggestionResults = () => {
    setTimeout(() => {
      let fieldElement = document.getElementById('gensetModel');
      let suggestionsElement = document.getElementById('gensetModelSuggestionResults');
      if (suggestionsElement) {
        suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
        suggestionsElement.style.marginTop = `${fieldElement.offsetHeight + 8}px`;
      }
    }, 100);
  }

  const getReminderDateClassName = () => {
    let className = "form-control";
    if (hasReminder && reminderDate != null) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Control handlers
  let _preKeyword = '';
  let _keyword = '';
  const onKeywordChanged = (ev) => {
    _preKeyword = _keyword.trim();
    _keyword = ev.target.value.trim();

    let isKeywordChanged = onGensetOrAtsKeywordChanged(ev, _keyword, _preKeyword);
    if (!isKeywordChanged) return;

    if (_keyword.length >= 2) {
      refServices.searchGeneratorsOrATS(_keyword)
        .then((response) => {
          let _generators = response.data['generators'];
          let _atsItems = response.data['atsItems'];
          setGeneratorSuggestions(_generators);
          setAtsSuggestions(_atsItems);
          expandGensetSuggestionResults();
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
          setAtsSuggestions([]);
        })
    } else {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }
  }

  const onKeywordBlurred = (ev) => {
    // a small delay to make sure that the selected suggestion is to be assigned properly to gensetModel state
    setTimeout(() => {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }, 200);
  }

  const onGeneratorClicked = (ev, generator) => {
    onGeneratorSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
    setAtsSuggestions([]);
  }

  const onAtsClicked = (ev, ats) => {
    onAtsSuggestionClicked(ev, ats);
    setGeneratorSuggestions([]);
    setAtsSuggestions([]);
  }

  const onFollowUpStageActionDropdownChanged = (ev) => {
    let _stageActionId = ev.target.value;
    if (_stageActionId === null || _stageActionId === '') {
      onFollowUpStageActionIdChanged(-1, -1);
      return;
    }

    let [stageId, actionId] = _stageActionId.split('|');
    stageId = parseInt(stageId);
    onFollowUpStageActionIdChanged(stageId, actionId);
  }

  const shouldRenderInputField = () => {
    let renderInputField = false;
    if (isInquiryEditMode) {
      renderInputField = isInquiryEditing && editingInquiryHistory != null;
    } else {
      renderInputField = true;
    }
    return renderInputField;
  }

  const shouldDisableInputField = () => {
    return (isContactEditMode && isContactEditing) || isSubmitting;
  }

  const isReminderDisabled = () => {
    if (!isReminderEditAllowed) return true;
    if (isContactEditMode && isContactEditing) return true;
    return isSubmitting;
  }

  const renderGensetModelsIfDifferent = () => {
    let generators = inquiryUpdateRequest['generators'].length > 0 ? inquiryUpdateRequest['generators'] : [];
    let generatorsNew = inquiryUpdateRequest['generatorsNew'].length > 0 ? inquiryUpdateRequest['generatorsNew'] : [];
    if (generators.length === 0 && generatorsNew.length === 0) return;
    if (generatorsNew.length === 0) return <FormSideLabel value={null} />
    let _terms = generatorsNew.map(generator => getDisplayTermFromGeneratorDetail(generator));
    return <FormSideLabel value={_terms.join(', ')} />
  }

  const renderFollowUpActionIfDifferent = () => {
    let isDifferentStage = inquiryUpdateRequest['followUpStage'] !== inquiryUpdateRequest['followUpStageNew'];
    let isDifferentAction = inquiryUpdateRequest['followUpAction'] !== inquiryUpdateRequest['followUpActionNew'];
    if (isDifferentStage || isDifferentAction) {
      let stageActionDisplay = getFollowUpStageActionDisplay(inquiryUpdateRequest['followUpStageNew'], inquiryUpdateRequest['followUpActionNew']);
      return <FormSideLabel value={stageActionDisplay} />
    }
  }

  const renderReminderDatetimeIfDifferent = () => {
    let reminderDatetime = inquiryUpdateRequest['reminderDatetime'] !== null ? new Date(inquiryUpdateRequest['reminderDatetime']).getTime() : -1;
    let reminderDatetimeNew = inquiryUpdateRequest['reminderDatetimeNew'] !== null ? new Date(inquiryUpdateRequest['reminderDatetimeNew']).getTime() : -1;
    if (reminderDatetime !== reminderDatetimeNew) {
      if (reminderDatetimeNew === -1) return <FormSideLabel value={null} />
      return <FormSideLabel value={inquiryUpdateRequest['reminderDatetimeNew']} type={'datetime'} />
    }
  }
  //#endregion

  //#region Control Handler - Assign modal
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const onAssignButtonClicked = (ev) => {
    setTimeout(() => {
      setIsAssignModalOpen(true);
    }, 200);
  }

  const onAssignModalRequestClose = (ev) => {
    setTimeout(() => {
      setIsAssignModalOpen(false);
    }, 200);
  }
  //#endregion


  //#region Render
  return (
    <>
      <div className={"form-section"}>
        <h2>Inquiry Details</h2>

        <div className={"entry-form followup-form"}>
          {editingEntry &&
            <div className={"form-message"}>
              {isLastReminderOverdue() ?
                <ErrorMessage>
                  {isInquiryEditMode ? "[EDITING] " : ""}
                  OVERDUE: Following up the entry from <b><Moment date={editingEntry.lastEntryDatetime} format={"MMMM DD, yyyy"} /></b>
                  {editingEntry.lastEntryGenerator && <> on genset <b>{editingEntry.lastEntryGenerator.gensetModel}</b></>}
                  {editingEntry.lastEntryDiscussions && <> about <b>{editingEntry.lastEntryDiscussions}</b></>}:
                </ErrorMessage>
                :
                <InfoMessage>
                  {isInquiryEditMode ? "[EDITING] " : ""}
                  Following up the entry from <b><Moment date={editingEntry.lastEntryDatetime} format={"MMMM DD, yyyy"} /></b>
                  {editingEntry.lastEntryGenerator && <> on genset <b>{editingEntry.lastEntryGenerator.gensetModel}</b></>}
                  {editingEntry.lastEntryDiscussions && <> about <b>{editingEntry.lastEntryDiscussions}</b></>}:
                </InfoMessage>
              }
            </div>
          }

          <div className={"form-label"}>
            <label htmlFor={"reporter"}>
              Reporter:
            </label>
          </div>
          <div className={"form-input"}>
            {isContactEditMode && editingEntry &&
              <div className={"readonly-field"}>
                <ActorNameDisplay employee={utils.sales.getPrimaryReporterFromInquiry(editingEntry)} />
              </div>
            }
            {!isContactEditMode &&
              <div className={"readonly-field"}>You</div>
            }
          </div>

          {isContactEditMode &&
            <div className={"form-input"}>
              <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={onAssignButtonClicked}>
                <i className="fa-solid fa-user-gear"></i>
                Assign...
              </button>
            </div>
          }

          {isContactEditMode && editingEntry &&
            <InquiryAssignModal isOpen={isAssignModalOpen} onRequestClose={onAssignModalRequestClose}
                                inquiryReporters={editingEntry['reporters']} inquiryEntryId={editingEntry['entryId']} />
          }

          <div className={"form-label"}>
            <label htmlFor={"reportDate"}>
              Report Date:<span className="error-message">*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {shouldRenderInputField() ?
              <>
                <DatePicker id={"reportDate"} className={getReportDateClassName()} placeholderText={"Type a valid date or click to choose"} required={true}
                            dateFormat={formatters.datetimePicker} maxDate={new Date()} todayButton={"Today"} showWeekNumbers
                            disabled={shouldDisableInputField()} autoComplete={"off"}
                            selected={reportDate} onChange={onReportDateChanged} />
                <FieldErrorMessages visible={hasErrors} messages={followUpErrors.reportDate} />
              </>
              :
              <div className={"readonly-field"}>
                <Moment date={editingInquiryHistory['followUpEntryDatetime']} format={formatters.datetime} />
              </div>
            }
          </div>
          {inquiryUpdateRequest && inquiryUpdateRequest['followUpEntryDatetime'] !== inquiryUpdateRequest['followUpEntryDatetimeNew'] &&
            <FormSideLabel value={inquiryUpdateRequest['followUpEntryDatetimeNew']} type={'datetime'} />
          }

          <div className={"form-label"}>
            <label htmlFor={"gensetModel"}>
              Genset Model (or ATS):
            </label>
          </div>
          {shouldRenderInputField() && generatorDetails && generatorDetails.length > 0 &&
            <div className={"form-input"}>
              <div className={"readonly-field inquiry-history-generators"}>
                {generatorDetails.map(item =>
                  <div className={"generator-item"}>
                    <div key={item['id']} className={"item-name"}>{item['term']}</div>
                    <button type={"button"} className={"btn btn-light btn-sm danger-button"} title={"Remove item"}
                            disabled={isSubmitting} onClick={(ev) => onGensetModelRemoveClicked(ev, item)}>
                      <i className="fa-solid fa-circle-minus"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
          {shouldRenderInputField() && inquiryUpdateRequest && renderGensetModelsIfDifferent()}
          <div className={"form-input genset-model-wrapper"}>
            {shouldRenderInputField() ?
              <>
                <input type={"text"} id={"gensetModel"} className={getGensetOrAtsClassName()}
                       placeholder={"Type keyword to search..."} autoComplete={"off"} disabled={shouldDisableInputField()}
                       value={gensetOrAtsKeyword} onChange={onKeywordChanged} onBlur={onKeywordBlurred} />
                {hasSuggestionResults() &&
                  <AutoCompleteGroupedResults id={"gensetModelSuggestionResults"}
                                              collections={[generatorSuggestions, atsSuggestions]}
                                              collectionLabels={["GENERATORS", "ATS"]}
                                              collectionDisplayFields={["gensetModel", "name"]}
                                              collectionItemClickHandlers={[onGeneratorClicked, onAtsClicked]} />
                }
                <button type={"button"} className={"btn btn-secondary genset-add"} disabled={isSubmitting}
                        onClick={onGensetModelAddClicked}>
                  <i className="fa-solid fa-plus"></i>
                </button>
              </>
              :
              <div className={"readonly-field"}>
                <GeneratorsSummary generators={editingInquiryHistory['generators']} summarized={false} />
              </div>
            }
          </div>
          {!shouldRenderInputField() && inquiryUpdateRequest && renderGensetModelsIfDifferent()}

          <div className={"form-label"}>
            <label>
              Visit/Call:
            </label>
          </div>
          <div className={"form-input"}>
            {shouldRenderInputField() ?
              <>
                <label className={"option-field"}>
                  <input type={"radio"} id={"contactFormVisit"} name={"contactForm"} disabled={shouldDisableInputField()}
                         value={1} checked={contactForm === 1} onChange={onContactFormChanged} />
                  Visit
                </label>
                <label className={"option-field"}>
                  <input type={"radio"} id={"contactFormCall"} name={"contactForm"} disabled={shouldDisableInputField()}
                         value={2} checked={contactForm === 2} onChange={onContactFormChanged} />
                  Call
                </label>
              </>
              :
              <div className={"readonly-field"}>
                {editingInquiryHistory['contactFormDisplay']}
              </div>
            }
          </div>
          {inquiryUpdateRequest && inquiryUpdateRequest['contactForm'] !== inquiryUpdateRequest['contactFormNew'] &&
            <FormSideLabel value={inquiryUpdateRequest['contactFormNewDisplay']} />
          }

          <div className={"form-label"}>
            <label htmlFor={"discussionDetails"}>
              Discussion Details:
            </label>
          </div>
          <div className={"form-input"}>
            {shouldRenderInputField() ?
              <textarea id={"discussionDetails"} className={"form-control"} rows={3} disabled={shouldDisableInputField()}
                        value={discussionDetails} onChange={onDiscussionDetailsChanged} maxLength={500}>
              </textarea>
              :
              <div className={"readonly-field"}>
                {editingInquiryHistory['discussions'] ? editingInquiryHistory['discussions'] : '-'}
              </div>
            }
          </div>
          {inquiryUpdateRequest && inquiryUpdateRequest['discussions'] !== inquiryUpdateRequest['discussionsNew'] &&
            <FormSideLabel value={inquiryUpdateRequest['discussionsNew']} />
          }

          <div className={"form-label"}>
            <label htmlFor={"followUpAction"}>
              Follow Up Action:
            </label>
          </div>
          <div className={"form-input"}>
            {shouldRenderInputField() ?
              <ReadonlyField>
                {followUpStageActionDisplay}
              </ReadonlyField>
              :
              <ReadonlyField>
                {editingInquiryHistory && getFollowUpStageActionDisplay(editingInquiryHistory['followUpStage'], editingInquiryHistory['followUpAction'])}
              </ReadonlyField>
            }
          </div>

          {shouldRenderInputField() &&
            <>
              <div className={"form-input"}>
                <FollowUpStageNestedDropdown id={"followUpStagesActions"} name={"followUpStagesActions"} disabled={shouldDisableInputField()}
                                             value={followUpStageActionId} onChange={onFollowUpStageActionDropdownChanged}
                />
              </div>
            </>
          }
          {inquiryUpdateRequest && renderFollowUpActionIfDifferent()}

          <div className={"form-label"}>
            <label htmlFor={"hasReminder"}>
              Reminder:
            </label>
          </div>
          <div className={"form-input"}>
            {shouldRenderInputField() ?
              <label htmlFor={"hasReminder"}>
                <input type={"checkbox"} id={"hasReminder"} name={"hasReminder"}
                       checked={hasReminder} onChange={onHasReminderChanged}
                       disabled={isReminderDisabled()} />
              </label>
              :
              <div className={"readonly-field"}>
                {editingInquiryHistory['hasReminder'] ? "Yes" : "No"}
              </div>
            }
          </div>
          {inquiryUpdateRequest && inquiryUpdateRequest['hasReminder'] !== inquiryUpdateRequest['hasReminderNew'] &&
            <FormSideLabel value={inquiryUpdateRequest['hasReminderNew'] ? "Yes" : "No"} />
          }

          <div className={"form-label"}>
            <label htmlFor={"reminderDate"}>
              Reminder Date:
            </label>
          </div>
          <div className={"form-input"}>
            {shouldRenderInputField() ?
              <>
                <DatePicker id={"reminderDate"} className={getReminderDateClassName()} placeholderText={"Type a valid date or click to choose"}
                            dateFormat={"MMMM dd, yyyy"} todayButton={"Today"} showWeekNumbers showMonthDropdown dropdownMode={"select"} autoComplete={"off"}
                            disabled={(!isReminderEditAllowed && !hasReminder) || (isContactEditMode && isContactEditing) || isSubmitting}
                            selected={reminderDate} onChange={onReminderDateChanged} />
                {/*minDate={new Date()}*/}
                <FieldErrorMessages visible={hasErrors} messages={followUpErrors.reminderDate} />
              </>
              :
              <div className={"readonly-field"}>
                {editingInquiryHistory['reminderDatetime'] ? <Moment date={editingInquiryHistory['reminderDatetime']} format={formatters.datetime} /> : '-'}
              </div>
            }
          </div>
          {inquiryUpdateRequest && renderReminderDatetimeIfDifferent()}
        </div>
      </div>

      {isLostSales &&
        <div className={"form-section"}>
          <h2>Lost Sales</h2>

          <div className={"entry-form"}>
            <div className={"form-label"}>
              <label htmlFor={"lostReason"}>
                Reason of Loss<span className={"error-message"}>*</span>
              </label>
            </div>
            <div className={"form-input"}>
              {shouldRenderInputField() ?
                <>
                  <input type={"text"} id={"lostReason"} className={"form-control"} maxLength={100}
                         autoComplete={"off"} disabled={shouldDisableInputField()}
                         value={lostReason} onChange={onLostReasonChanged} />
                  <FieldErrorMessages visible={hasErrors} messages={followUpErrors['lostReason']} />
                </>
                :
                <div className={"readonly-field"}>
                  {editingInquiryHistory['lostReason'] ? editingInquiryHistory['lostReason'] : '-'}
                </div>
              }
            </div>
            {inquiryUpdateRequest && inquiryUpdateRequest['lostReason'] !== inquiryUpdateRequest['lostReasonNew'] &&
              <FormSideLabel value={inquiryUpdateRequest['lostReasonNew']} />
            }
          </div>

          <h3>Competitor's Status</h3>

          <div className={"entry-form"}>
            <div className={"form-label"}>
              <label htmlFor={"competitorName"}>
                Name:
              </label>
            </div>
            <div className={"form-input"}>
              {shouldRenderInputField() ?
                <input type={"text"} id={"competitorName"} className={"form-control"} maxLength={100}
                       autoComplete={"off"} disabled={shouldDisableInputField()}
                       value={competitorName} onChange={onCompetitorNameChanged} />
                :
                <div className={"readonly-field"}>
                  {editingInquiryHistory['competitorName'] ? editingInquiryHistory['competitorName'] : '-'}
                </div>
              }
            </div>
            {inquiryUpdateRequest && inquiryUpdateRequest['competitorName'] !== inquiryUpdateRequest['competitorNameNew'] &&
              <FormSideLabel value={inquiryUpdateRequest['competitorNameNew']} />
            }

            <div className={"form-label"}>
              Currency:
            </div>
            <div className={"form-input"}>
              {shouldRenderInputField() ?
                <select id={"currency"} className={"form-control form-select"} disabled={shouldDisableInputField()}
                        value={competitorCurrency} onChange={onCompetitorCurrencyChanged}>
                  {Object.entries(refs.currency).map(([key, item], index) =>
                    <option key={key} value={item.code}>{item.name} ({item.sign})</option>
                  )}
                </select>
                :
                <div className={"readonly-field"}>
                  {editingInquiryHistory['competitorCurrency'] ? getCurrencyDisplay(editingInquiryHistory['competitorCurrency']) : '-'}
                </div>
              }
            </div>
            {inquiryUpdateRequest && inquiryUpdateRequest['competitorCurrency'] !== inquiryUpdateRequest['competitorCurrencyNew'] &&
              <FormSideLabel value={getCurrencyDisplay(inquiryUpdateRequest['competitorCurrencyNew'])} />
            }

            <div className={"form-label"}>
              <label htmlFor={"competitorPrice"}>
                Price:
              </label>
            </div>
            <div className={"form-input"}>
              {shouldRenderInputField() ?
                <input type={"text"} id={"competitorPrice"} className={"form-control"} maxLength={30}
                       autoComplete={"off"} disabled={shouldDisableInputField()}
                       value={competitorPrice} onChange={onCompetitorPriceChanged} onBlur={onCompetitorPriceBlurred} />
                :
                <div className={"readonly-field"}>
                  {editingInquiryHistory['competitorPrice'] ? formatCurrency(editingInquiryHistory['competitorPrice']) : '-'}
                </div>
              }
            </div>
            {inquiryUpdateRequest && inquiryUpdateRequest['competitorPrice'] !== inquiryUpdateRequest['competitorPriceNew'] &&
              <FormSideLabel value={formatCurrency(inquiryUpdateRequest['competitorPriceNew'])} />
            }

            <div className={"form-label"}>
              <label htmlFor={"competitorBrand"}>
                Brand:
              </label>
            </div>
            <div className={"form-input"}>
              {shouldRenderInputField() ?
                <input type={"text"} id={"competitorBrand"} className={"form-control"} maxLength={100}
                       autoComplete={"off"} disabled={shouldDisableInputField()}
                       value={competitorBrand} onChange={onCompetitorBrandChanged} />
                :
                <div className={"readonly-field"}>
                  {editingInquiryHistory['competitorBrand'] ? editingInquiryHistory['competitorBrand'] : '-'}
                </div>
              }
            </div>
            {inquiryUpdateRequest && inquiryUpdateRequest['competitorBrand'] !== inquiryUpdateRequest['competitorBrandNew'] &&
              <FormSideLabel value={inquiryUpdateRequest['competitorBrandNew']} />
            }

            <div className={"form-label"}>
              <label htmlFor={"competitorDelivery"}>
                Delivery:
              </label>
            </div>
            <div className={"form-input"}>
              {shouldRenderInputField() ?
                <input type={"text"} id={"competitorDelivery"} className={"form-control"} maxLength={100}
                       autoComplete={"off"} disabled={shouldDisableInputField()}
                       value={competitorDelivery} onChange={onCompetitorDeliveryChanged} />
                :
                <div className={"readonly-field"}>
                  {editingInquiryHistory['competitorDelivery'] ? editingInquiryHistory['competitorDelivery'] : '-'}
                </div>
              }
            </div>
            {inquiryUpdateRequest && inquiryUpdateRequest['competitorDelivery'] !== inquiryUpdateRequest['competitorDeliveryNew'] &&
              <FormSideLabel value={inquiryUpdateRequest['competitorDeliveryNew']} />
            }

            <div className={"form-label"}>
              <label htmlFor={"lostRemarks"}>
                Remarks:
              </label>
            </div>
            <div className={"form-input"}>
              {shouldRenderInputField() ?
                <input type={"text"} id={"lostRemarks"} className={"form-control"} autoComplete={"off"} disabled={shouldDisableInputField()}
                       value={lostRemarks} onChange={onLostRemarksChanged} />
                :
                <div className={"readonly-field"}>
                  {editingInquiryHistory['lostRemarks'] ? editingInquiryHistory['lostRemarks'] : '-'}
                </div>
              }
            </div>
            {inquiryUpdateRequest && inquiryUpdateRequest['lostRemarks'] !== inquiryUpdateRequest['lostRemarksNew'] &&
              <FormSideLabel value={inquiryUpdateRequest['lostRemarksNew']} />
            }
          </div>
        </div>
      }

      {isContactEditMode && !isInquiryEditMode &&
        <div className={"form-button-controls"}>
          <div className={"left-side"}>
            <button type={"button"} className={"btn btn-primary"} onClick={onSubmitClicked}
                    disabled={isSubmitting || (isContactEditMode && isContactEditing)}>
              {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
              {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
              Submit New Inquiry
            </button>
          </div>
        </div>
      }
      {isContactEditMode && isInquiryEditMode && !isInquiryEditing && isEditAllowed &&
        <div className={"form-button-controls"}>
          <div className={"left-side"}>
            <button type={"button"} className={"btn btn-secondary"} onClick={onUpdateEditClicked}>
              <i className="fa-solid fa-pen"></i>
              Edit
            </button>
            <button type={"button"} className={"btn btn-secondary"} onClick={onUpdateClearClicked} disabled={isSubmitting}>
              <i className="fa-solid fa-xmark"></i>
              Clear
            </button>
          </div>
        </div>
      }
      {isContactEditMode && isInquiryEditMode && isInquiryEditing && isEditAllowed &&
        <div className={"form-button-controls"}>
          <div className={"left-side"}>
            <button type={"button"} className={"btn btn-primary"} onClick={onUpdateSubmitClicked}
                    disabled={isSubmitting || (!editingInquiryHistory)}>
              {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
              {!isSubmitting && <i className="fa-solid fa-check"></i>}
              Submit Update
            </button>
            <button type={"button"} className={"btn btn-secondary"} onClick={onUpdateCancelClicked} disabled={isSubmitting}>
              <i className="fa-solid fa-xmark"></i>
              Cancel
            </button>
          </div>
        </div>
      }

      {isContactEditMode && !shouldRenderInputField() && isReviewAllowed &&
        <div className={"form-button-controls"}>
          <div className={"right-side"}>
            <button type={"button"} className={"btn btn-success"} disabled={isSubmitting || isContactEditing} onClick={onApproveClicked}>
              {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
              {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
              Approve
            </button>
            <button type={"button"} className={"btn btn-danger"} disabled={isSubmitting || isContactEditing} onClick={onRejectClicked}>
              <i className={"fa-solid fa-xmark"}></i>
              Reject
            </button>
          </div>
        </div>
      }
    </>
  )
  //#endregion

}
