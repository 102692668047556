import React from "react";

import "../shared/ControlsGrid.css";

export const ControlsGrid = ({ children }) => {
  return (
    <div className={"controls-grid"}>
      {children}
    </div>
  )
}

export const ControlsGroup = ({ title }) => {
  return (
    <div className={"controls-group"}>
      {title}
    </div>
  )
}

export const ControlsItems = ({ children }) => {
  return (
    <div className={"controls-items"}>
      {children}
    </div>
  )
}

export const ControlButton = ({ icon, label, disabled, onClick }) => {
  return (
    <button type={"button"} disabled={disabled} onClick={onClick}>
      <i className={`fa-solid ${icon}`}></i>
      <span>{label}</span>
    </button>
  )
}
