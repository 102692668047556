import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { ServiceEntryGenerator } from "./ServiceEntryGenerator";
import { TabsPanelProvider } from "../shared/TabsPanelContext";
import { TabHeader } from "../shared/TabsPanelContext";
import { TabHeaderItem } from "../shared/TabsPanelContext";
import { TabContent } from "../shared/TabsPanelContext";
import { TabContentPane } from "../shared/TabsPanelContext";
import { NullBlankValue } from "./NullBlankField";
import { displayColumn } from "./ServiceEntryTestChecklist";
import { ServiceEntryTestChecklist } from "./ServiceEntryTestChecklist";
import { ServiceEntryTestResultsList } from "./ServiceEntryTestResultsList";
import { ServiceEntrySignature } from "./ServiceEntrySignature";
import { NewContactLogModal } from "./NewContactLogModal";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";
import "./ServiceProfilePage.css";


const generatorManufacturers = refs.inventory.generatorManufacturers;
const generatorAlternators = refs.inventory.generatorAlternators;
const stockStatuses = refs.inventory.stockStatus;
const jobStatuses = refs.inventory.jobStatus;
const serviceHistoryTypes = refs.inventory.serviceHistoryType;
const serviceTypes = refs.inventory.serviceType;
const testChecklist = refs.inventory.testChecklist;

export function EmergencyBreakdownEntryPage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceGeneratorId, setServiceGeneratorId] = useState(null);
  const [serviceHistoryId, setServiceHistoryId] = useState(null);

  const [isLoadingServiceEntry, setIsLoadingServiceEntry] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [servicedDatetime, setServicedDatetime] = useState(new Date());
  const [installationDate, setInstallationDate] = useState(new Date());
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [jobStartTime, setJobStartTime] = useState(null);
  const [jobEndTime, setJobEndTime] = useState(null);

  const [serviceGenerator, setServiceGenerator] = useState(null);
  const [stock, setStock] = useState(null);
  const [generatorSerial, setGeneratorSerial] = useState("");

  const [isEditable, setIsEditable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("Hi there!");

  const [jobStatus, setJobStatus] = useState(jobStatuses.draft);
  const [testedBy, setTestedBy] = useState({});
  const [checkedBy, setCheckedBy] = useState({});
  const [checkedDate, setCheckedDate] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region States; Test Results form sections
  const [isFormReadonly, setIsFormReadonly] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const _testChecklist = refs.inventory.testChecklist;  // NOTE(yemon): Value reference for the following checklist fields.
  const [emergencyBreakdownId, setEmergencyBreakdownId] = useState(null);
  const [emergencyBreakdown, setEmergencyBreakdown] = useState(null);
  const [emergencyCooling, setEmergencyCooling] = useState(null);
  const [emergencyLubOil, setEmergencyLubOil] = useState(null);
  const [emergencyFuel, setEmergencyFuel] = useState(null);
  const [emergencyAirCirculation, setEmergencyAirCirculation] = useState(null);
  const [emergencyElectrical, setEmergencyElectrical] = useState(null);

  const [remarks, setRemarks] = useState("");
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchUserPermissions();

    let state = location.state;
    if (!state) {
      navigate(routes.serviceProfiles.url);
    }
    let _serviceProfileId = state['serviceProfileId'];
    let _serviceGeneratorId = state['serviceGeneratorId'];
    let _serviceHistoryId = state['serviceHistoryId'];
    if (!_serviceProfileId || !_serviceGeneratorId || !_serviceHistoryId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      setServiceProfileId(_serviceProfileId);
      setServiceGeneratorId(_serviceGeneratorId);
      setServiceHistoryId(_serviceHistoryId);
      fetchCommissionServiceEntry(_serviceProfileId, _serviceGeneratorId, _serviceHistoryId);
    }

    prepareEntrySignatures();
  }, []);

  const prepareEntrySignatures = () => {
    // TODO(yemon): Set to the current entry's creator
    let _currentEmployee = {
      ...auth.userInfo,
    };
    _currentEmployee['id'] = auth.getUserId()['eid'];
    setTestedBy(_currentEmployee);
  }

  const prepareEntryHeaderPayload = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    return {
      'employee_id': auth.getUserId()['eid'],
      'history_type': serviceHistoryTypes.serviceHistory,
      'service_type': serviceTypes.emergency,
      'service_profile_id': serviceProfileId,
      'service_generator_id': serviceGeneratorId,
      'service_history_id': serviceHistoryId,
    };
  }

  const fetchCommissionServiceEntry = (serviceProfileId, serviceGeneratorId, serviceHistoryId) => {
    let payload = prepareEntryHeaderPayload(serviceProfileId, serviceGeneratorId, serviceHistoryId);
    setIsAlertVisible(false);
    setIsLoadingServiceEntry(true);
    inventoryServices.fetchServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _serviceHistory = response['data'];
        let _serviceGenerator = _serviceHistory['serviceGenerator'];
        let _serviceLocation = _serviceGenerator['serviceLocation'];
        prepareServiceHistoryDetails(_serviceHistory);
        prepareServiceProfileDetails(_serviceLocation);
        prepareServiceGeneratorDetails(_serviceGenerator);
        prepareEmergencyBreakdownDetails(_serviceHistory['emergencyBreakdown']);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => setIsLoadingServiceEntry(false));
  }

  const prepareServiceHistoryDetails = (serviceHistory) => {
    setServicedDatetime(serviceHistory['servicedDatetime'])

    setReferenceNumber(serviceHistory['referenceNumber']);
    setJobStartTime(serviceHistory['jobStartTime']);
    setJobEndTime(serviceHistory['jobEndTime']);

    setJobStatus(serviceHistory['status']);
    setRemarks(serviceHistory['remarks']);

    setTestedBy(serviceHistory['servicedBy']);
    setCheckedBy(serviceHistory['reviewedBy']);
    setCheckedDate(serviceHistory['reviewedDatetime']);
  }

  const prepareServiceProfileDetails = (serviceLocation) => {
    let _serviceProfile = serviceLocation['profile'];
    let _customer = _serviceProfile['customer'];
    setCustomerId(_customer['id']);
    setCustomerName(_customer['customerName']);
    setContactFullName(serviceLocation['contactFullName']);
    setContactNo(serviceLocation['contactNo']);
  }

  const prepareServiceGeneratorDetails = (serviceGenerator) => {
    setServiceGenerator(serviceGenerator);
    setInstallationDate(serviceGenerator['installationDate'])
    let _stock = serviceGenerator['stock'];
    setStock(_stock);
    setGeneratorSerial(_stock['generatorSerial']);
  }

  const prepareEmergencyBreakdownDetails = (emergency) => {
    if (!emergency) {
      return;
    }

    setEmergencyCooling({...emergency['cooling']});
    setEmergencyLubOil({...emergency['lubOil']});
    setEmergencyFuel({...emergency['fuel']});
    setEmergencyAirCirculation({...emergency['airCirculation']});
    setEmergencyElectrical({...emergency['electrical']});
  }
  //#endregion

  //#region Effects; Test Results form sections
  useEffect(() => {
    if (jobStatus === jobStatuses.draft) {
      setIsFormReadonly(false);
    }
    else {
      setIsFormReadonly(true);
    }
  }, [jobStatus]);
  //#endregion

  //#region States; User permissions
  const [allowCareContactLog, setAllowCareContactLog] = useState(false);

  const fetchUserPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _permissions = response['data'];
        setAllowCareContactLog(_permissions['service']['allowCareContactLog']);
        setAllowServiceHistoryReview(_permissions['service']['allowStockInspectionReviews']);
      });
  }

  const [allowServiceHistoryReview, setAllowServiceHistoryReview] = useState(false);
  const [isSubmittingReview, setIsSubmittingReview] = useState(false);

  const isFormReviewable = () => {
    return allowServiceHistoryReview && (jobStatus === jobStatuses.pendingReview || jobStatus === jobStatuses.active);
  }

  const isFormReviewed = () => {
    return allowServiceHistoryReview && (jobStatus === jobStatuses.active);
  }

  const onReviewClicked = (ev) => {
    let payload = {
      'employee_id': auth.getUserId()['eid'],
      'service_history_id': serviceHistoryId,
    }
    setIsSubmittingReview(true);
    inventoryServices.submitServiceHistoryReview(payload)
      .then((response) => {
        let _responseData = response['data'];
        setJobStatus(_responseData['status']);
        setCheckedBy(_responseData['reviewedBy']);
        setCheckedDate(_responseData['reviewedDatetime']);
      })
      .finally(() => {
        setIsSubmittingReview(false);
      });
  }
  //#endregion

  //#region Control handlers
  const onAlertDismissButtonClicked = () => {
    setIsAlertVisible(false);
  }

  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorSerialClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
          'generatorId': serviceGeneratorId,
        }
      });
    }, 200);
  }
  //#endregion

  //#region Control handlers; Test Results form sections
  const prepareEmergencyBreakdownPayload = () => {
    return null;
  }

  const onSubmitClicked = (ev, isDraft) => {
    let _hasErrors = false;
    if (_hasErrors) {
      setAlertMessage("Please fix the indicated errors on the respective fields before submitting again.");
      setAlertType(alertTypes.error);
      setIsAlertVisible(true);
      return;
    }
    setIsAlertVisible(false);

    let payload = {
      ...prepareEntryHeaderPayload(serviceProfileId, serviceGeneratorId, serviceHistoryId),
      'job_status': isDraft ? jobStatuses.draft : jobStatuses.pendingReview,
      //'emergency_breakdown': null,
    };

    setIsSubmitting(true);
    if (isDraft) {
      setIsSubmittingForReview(false);
      setIsSubmittingDraft(true);
    }
    else {
      setIsSubmittingForReview(true);
      setIsSubmittingDraft(false);
    }

    inventoryServices.submitServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _responseData = response['data'];
        console.log(_responseData);
        setAlertType(alertTypes.info);
        setAlertMessage(_responseData['message']);
        setIsAlertVisible(true);

        setJobStatus(_responseData['jobStatus']);
        //setEmergencyBreakdownId(...);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => {
        setIsSubmittingForReview(false);
        setIsSubmittingDraft(false);
        setIsSubmitting(false);
      });
  }
  //#endregion

  //#region New Contact Log modal;
  const [isNewContactModalOpened, setIsNewContactModalOpened] = useState(false);

  const onNewContactLogClicked = (ev) => {
    newContactModal.onOpenButtonClicked();
  }

  const newContactModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsNewContactModalOpened(false);
      }, 200);
    },
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                  {generatorSerial}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.emergencyBreakdown.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Emergency Breakdown Service Form</h1>
          </div>

          <div className={"form-section"}>
            {/*<h2>Customer Details</h2>*/}

            <div className={"entry-form service-profile-form"}>
              <div className={"form-label"}>
                <label>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Address:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Region:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactFullName}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {contactNo}
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-name"} title={"Location title"}>{serviceGenerator['serviceLocation']['name']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div title={"Location address"}>{serviceGenerator['serviceLocation']['address']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div title={"Location designated region"}>
                        {getRegionDisplay(serviceGenerator['serviceLocation']['city'], serviceGenerator['serviceLocation']['township'])}
                      </div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Serviced Date:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Reference Number:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label>
                  Job Start & Finish Times:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {servicedDatetime && <Moment date={servicedDatetime} format={formatters.datetimeShort} />}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={referenceNumber} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={jobStartTime} /> - <NullBlankValue value={jobEndTime} />
                </ReadonlyField>
              </div>

            </div>
          </div>

          {stock &&
            <ServiceEntryGenerator generatorSerial={generatorSerial} onGeneratorSerialChanged={null}
                                   gensetModel={stock['generator']['gensetModel']} onGensetModelChanged={null}
                                   generator={stock['manufacturer']} onGeneratorChanged={null}
                                   alternatorSerial={stock['alternatorSerial']} onAlternatorSerialChanged={null}
                                   alternatorModel={stock['alternatorModel']} onAlternatorModelChanged={null}
                                   alternator={stock['alternator']} onAlternatorChanged={null}
                                   machineSerial={stock['machineSerial']} onMachineSerialChanged={null}
                                   machineModel={stock['machineModel']} onMachineModelChanged={null}
                                   machine={stock['machine']} onMachineChanged={null}
                                   controllerSerial={stock['controllerSerial']} onControllerSerialChanged={null}
                                   controllerModel={stock['controllerModel']} onControllerModelChanged={null}
                                   controller={stock['controller']} onControllerChanged={null}
                                   isLoading={isLoadingServiceEntry} isEditable={false}
                                   isSubmitting={false} hasErrors={false} formErrors={null}
            />
          }

          <NewContactLogModal isOpen={isNewContactModalOpened}
                              onRequestClose={newContactModal.onCloseButtonClicked}
                              serviceProfileId={serviceProfileId} serviceGeneratorId={serviceGeneratorId}
                              followupServiceHistoryId={serviceHistoryId} followupServiceType={serviceTypes.emergency}
          />

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isSubmitting || !isEditable}
                      onClick={onGeneratorSerialClicked}>
                <i className={"fa-solid fa-arrow-left"}></i>
                Return to Generator
              </button>

              {allowCareContactLog &&
                <button className={"btn btn-primary"} disabled={isSubmitting || !isEditable}
                        onClick={onNewContactLogClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New Contact Log...
                </button>
              }
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Service Engineer Report</h2>
          </div>

          <TabsPanelProvider initialHeaderElementId={"cooling-system-tab"}
                             initialContentPaneElementId={"cooling-system-content"}>
            <TabHeader>
              <TabHeaderItem itemElementId={"cooling-system-tab"} contentPaneElementId={"cooling-system-content"}
                             itemLabel={"Cooling System"} />
              <TabHeaderItem itemElementId={"lub-oil-system-tab"} contentPaneElementId={"lub-oil-system-content"}
                             itemLabel={"Lub Oil System"} />
              <TabHeaderItem itemElementId={"fuel-system-tab"} contentPaneElementId={"fuel-system-content"}
                             itemLabel={"Fuel System"} />
              <TabHeaderItem itemElementId={"air-circulation-system-tab"} contentPaneElementId={"air-circulation-system-content"}
                             itemLabel={"Air Circulation System"} />
              <TabHeaderItem itemElementId={"electrical-system-tab"} contentPaneElementId={"electrical-system-content"}
                             itemLabel={"Electrical System"} />
            </TabHeader>
            <TabContent>
              <TabContentPane contentPaneElementId={"cooling-system-content"}>
                <EmergencyCoolingSystem emergencyCooling={emergencyCooling}
                                        isFormReadonly={true}
                                        isFormDisabled={true} />
              </TabContentPane>
              <TabContentPane contentPaneElementId={"lub-oil-system-content"}>
                <EmergencyLubOilSystem emergencyLubOil={emergencyLubOil}
                                       isFormReadonly={true}
                                       isFormDisabled={true} />
              </TabContentPane>
              <TabContentPane contentPaneElementId={"fuel-system-content"}>
                <EmergencyFuelSystem emergencyFuel={emergencyFuel}
                                     isFormReadonly={true}
                                     isFormDisabled={true} />
              </TabContentPane>
              <TabContentPane contentPaneElementId={"air-circulation-system-content"}>
                <EmergencyAirCirculationSystem emergencyAirCirculation={emergencyAirCirculation}
                                               isFormReadonly={true}
                                               isFormDisabled={true} />
              </TabContentPane>
              <TabContentPane contentPaneElementId={"electrical-system-content"}>
                <EmergencyElectricalSystem emergencyElectrical={emergencyElectrical}
                                           isFormReadonly={true}
                                           isFormDisabled={true} />
              </TabContentPane>
            </TabContent>
          </TabsPanelProvider>

          <div className={"form-section"}>
            <h2>Other Information</h2>

            <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
              <div className={"form-label"}>
                <label htmlFor={"testedBy"}>
                  Remarks:
                </label>
              </div>

              <div className={"form-input"}>
                <div className={"readonly-field"}>
                  <ReadonlyField>
                    <NullBlankValue value={remarks} />
                  </ReadonlyField>
                </div>
              </div>
            </div>
          </div>

          <ServiceEntrySignature jobStatus={jobStatus} testedBy={testedBy}
                                 checkedBy={checkedBy} checkedDate={checkedDate} />

          <div className={"form-section-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting || !isEditable || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, false)}>
                {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}
                Submit
              </button>

              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || !isEditable || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, true)}>
                {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}
                Save Draft
              </button>

              {isFormReviewable() &&
                <button type={"button"} className={"btn btn-success"} disabled={isSubmitting || isSubmittingReview || isFormReviewed()}
                        onClick={onReviewClicked}>
                  {isSubmittingReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isSubmittingReview && <i className={"fa-solid fa-list-check"}></i>}
                  Mark as Reviewed
                </button>
              }
            </div>
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={onAlertDismissButtonClicked}>
            {alertMessage}
          </PageAlert>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const EmergencyCoolingSystem = ({ emergencyCooling, isFormReadonly, isFormDisabled }) => {
  return (
    <>
      {!emergencyCooling && <div>Nothing to show here.</div>}
      {emergencyCooling &&
        <div className={"form-section"}>
          <div className={"data-table"}>
            <table>
              <EmergencyReportTableHeading />

              <tbody>
              <EmergencyReportTableRow reportItem={emergencyCooling['radiator']} />
              <EmergencyReportTableRow reportItem={emergencyCooling['fanAndBelts']} />
              <EmergencyReportTableRow reportItem={emergencyCooling['tempSensor']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['tempGauge']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['leakage']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['filters']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['turboCharger']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['loadPercent']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['exhaustSmoke']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['enginePerformance']} />
                <EmergencyReportTableRow reportItem={emergencyCooling['environment']} />
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

const EmergencyLubOilSystem = ({ emergencyLubOil, isFormReadonly, isFormDisabled }) => {
  return (
    <>
      {!emergencyLubOil && <div>Nothing to show here.</div>}
      {emergencyLubOil &&
        <div className={"form-section"}>
          <div className={"data-table"}>
            <table>
              <EmergencyReportTableHeading />

              <tbody>
                <EmergencyReportTableRow reportItem={emergencyLubOil['lubOilPressure']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['gauge']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['sensor']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['lubOilLeakage']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['smoke']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['mixFuelAndOil']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['mixWaterAndOil']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['oilFilter']} />
                <EmergencyReportTableRow reportItem={emergencyLubOil['breatherPipe']} />
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

const EmergencyFuelSystem = ({ emergencyFuel, isFormReadonly, isFormDisabled }) => {
  return (
    <>
      {!emergencyFuel && <div>Nothing to show here.</div>}
      {emergencyFuel &&
        <div className={"form-section"}>
          <div className={"data-table"}>
            <table>
              <EmergencyReportTableHeading />

              <tbody>
                <EmergencyReportTableRow reportItem={emergencyFuel['fuelGauge']} />
                <EmergencyReportTableRow reportItem={emergencyFuel['fuelLeakage']} />
                <EmergencyReportTableRow reportItem={emergencyFuel['primaryPump']} />
                <EmergencyReportTableRow reportItem={emergencyFuel['injectionPump']} />
                <EmergencyReportTableRow reportItem={emergencyFuel['nozzlesInjector']} />
                <EmergencyReportTableRow reportItem={emergencyFuel['pipingSystem']} />
                <EmergencyReportTableRow reportItem={emergencyFuel['fuelFilter']} />
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

const EmergencyAirCirculationSystem = ({ emergencyAirCirculation, isFormReadonly, isFormDisabled }) => {
  return (
    <>
      {!emergencyAirCirculation && <div>Nothing to show here.</div>}
      {emergencyAirCirculation &&
        <div className={"form-section"}>
          <div className={"data-table"}>
            <table>
              <EmergencyReportTableHeading />

              <tbody>
                <EmergencyReportTableRow reportItem={emergencyAirCirculation['airIndicator']} />
                <EmergencyReportTableRow reportItem={emergencyAirCirculation['airFilter']} />
                <EmergencyReportTableRow reportItem={emergencyAirCirculation['afterCooler']} />
                <EmergencyReportTableRow reportItem={emergencyAirCirculation['turboCharger2']} />
                <EmergencyReportTableRow reportItem={emergencyAirCirculation['smoke2']} />
                <EmergencyReportTableRow reportItem={emergencyAirCirculation['enginePerformance2']} />
                <EmergencyReportTableRow reportItem={emergencyAirCirculation['environment2']} />
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

const EmergencyElectricalSystem = ({ emergencyElectrical, isFormReadonly, isFormDisabled }) => {
  return (
    <>
      {!emergencyElectrical && <div>Nothing to show here.</div>}
      {emergencyElectrical &&
        <div className={"form-section"}>
          <div className={"data-table"}>
            <table>
              <EmergencyReportTableHeading />

              <tbody>
                <EmergencyReportTableRow reportItem={emergencyElectrical['dcSystem']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['batteryPerformance']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['metering']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['terminalsCables']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['switchEmergency']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['chargingAlternator']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['fuses']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['relay']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['acSystem']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['metering2']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['terminalsCables2']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['circuitBreaker']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['mpu']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['avr']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['mainAlternator']} />
                <EmergencyReportTableRow reportItem={emergencyElectrical['fusesCT']} />
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

const EmergencyReportTableHeading = () => {
  return (
    <thead>
    <tr>
      <th scope={"col"} style={{ minWidth: 14 }}></th>
      <th scope={"col"}>Description</th>
      <th scope={"col"}>Troubleshooting Result</th>
      <th scope={"col"}>Solution</th>
      <th scope={"col"}>Remark</th>
    </tr>
    </thead>
  )
}

const EmergencyReportTableRow = ({ reportItem }) => {
  return (
    <tr>
      <td></td>
      <td>
        <ReadonlyField>
          <span style={{ fontWeight: 'bold' }}>
            {reportItem && reportItem['label']}
          </span>
        </ReadonlyField>
      </td>
      <td>
        {!reportItem && <ReadonlyField>(NA)</ReadonlyField>}
        {reportItem && reportItem['result'] === null && <ReadonlyField>(NA)</ReadonlyField>}
        {reportItem && reportItem['result'] &&
          <ReadonlyField>
            {testChecklist[reportItem['result']]}
          </ReadonlyField>
        }
      </td>
      <td>
        {reportItem && <NullBlankValue value={reportItem['solution']} />}
      </td>
      <td>
        {reportItem && <NullBlankValue value={reportItem['remark']} />}
      </td>
    </tr>
  )
}
