import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { salesServices } from "../../repos/apiServices";
import { customerServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { customer } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { formModes } from "../../repos/constants";
import { formatSourceName } from "../../repos/utilities";
import { formatSourceDescription } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";
import { getCurrencyDisplay } from "../../repos/utilities";

import { SourceDropdown } from "../shared/SourceRadios";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { ReadonlyField } from "../shared/ReadonlyField";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ReviewStatusBadge } from "./ReviewStatusBadge";
import { SalesInquiryListModal } from "../sales/SalesInquiryListModal";
import { CustomerListModal } from "../customer/CustomerListModal";

export const EntryCustomerDetails = ({
                                       editingEntry,
                                       requestDate, onRequestDateChanged,
                                       currency, onCurrencyChanged,
                                       customerProfileId, customerProfileStatus,
                                       customerName, onCustomerNameChanged,
                                       businessType, onBusinessTypeChanged,
                                       getBusinessTypeDisplay,
                                       contactName, onContactNameChanged,
                                       contactNo, onContactNoChanged,
                                       designation, onDesignationChanged,
                                       address, onAddressChanged,
                                       source, onSourceChanged, sourceDescription,
                                       remarks, onRemarksChanged,
                                       hasErrors, formErrors, isSubmitting, formMode, isSubmittingDetails,
                                       onSaveClicked, onCancelClicked, onEditClicked, onCreateProfileClicked,
                                       isFormSubmitted, isFormOwner,
                                       fillUpDetailsFromInquiry, fillUpDetailsFromProfile,
                                     }) => {
  //#region States
  const [businessTypes, setBusinessTypes] = useState([]);

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    refServices2.fetchBusinessTypes().then((response) => setBusinessTypes(response.data));

    if (formMode === formModes.quotationRequest.newEntry) {
      // NOTE: this will trigger the API call no matter what the 'formMode' value is
      // mainly due to the fact that the 'formMode' won't be bound immediately until the
      // parent component finish binding its states
      fetchSalesInquiries(1);
      fetchCustomerProfiles(1);
    }
  }, []);
  //#endregion

  //#region Utilities
  const fetchSalesInquiries = (page) => {
    setIsSalesInquiriesLoading(true);
    let tableOptions = {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
      },
    };
    salesServices.inquiryList(tableOptions)
      .then((response) => {
        setSalesInquiries(response.data);
        setInquiriesPagination(response.data['pagination']);
      })
      .catch((error) => {
        console.error(error.response.data);
      })
      .finally(() => setIsSalesInquiriesLoading(false));
  }

  const fetchCustomerProfiles = (page) => {
    setIsCustomerProfilesLoading(true);
    let tableOptions = {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'currentPage': page,
      }
    };
    customerServices.fetchCustomerProfiles(tableOptions)
      .then((response) => {
        setCustomerProfiles(response.data);
        setCustomersPagination(response.data['pagination']);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => setIsCustomerProfilesLoading(false));
  }

  const isFormReadonlyMode = () => {
    return formMode === formModes.quotationRequest.quotationEntry;
  }

  const isFormEditMode = () => {
    return formMode === formModes.quotationRequest.editEntry;
  }

  const getRequestDateClassName = () => {
    let className = "form-control";
    if (requestDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Control handlers - Sales inquiries pop up modal
  const [isSalesInquiriesModalOpen, setIsSalesInquiriesModalOpen] = useState(false);
  const [isSalesInquiriesLoading, setIsSalesInquiriesLoading] = useState(false);
  const [salesInquiries, setSalesInquiries] = useState();
  const [inquiriesPagination, setInquiriesPagination] = useState(null);

  const salesInquiriesModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsSalesInquiriesModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsSalesInquiriesModalOpen(false);
      }, 200);
    },
  }

  const onSalesInquiryRowClicked = (ev, salesInquiry) => {
    // NOTE(yemon): This callback has a small bug of not displaying the "description" of
    // the "Source" field value, because it does not (and could not) re-trigger the
    // `onSourceChanged` field state change callback. Might wanna figure this out later.
    ev.preventDefault();
    setTimeout(() => {
      fillUpDetailsFromInquiry(salesInquiry);
      setIsSalesInquiriesModalOpen(false);
    }, 200);
  }

  const onSalesInquiriesPageClicked = (page) => {
    fetchSalesInquiries(page);
  }

  const onSalesInquiriesPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    fetchSalesInquiries(page);
  }

  const onSalesInquiriesNextPageClicked = (fromPage) => {
    let page = Math.min(salesInquiries['pagination']['totalPages'], fromPage + 1);
    fetchSalesInquiries(page);
  }
  //#endregion

  //#region Control handlers - Customer profiles pop up modal
  const [isCustomerProfilesModalOpen, setIsCustomerProfilesModalOpen] = useState(false);
  const [isCustomerProfilesLoading, setIsCustomerProfilesLoading] = useState(false);
  const [customerProfiles, setCustomerProfiles] = useState();
  const [customersPagination, setCustomersPagination] = useState(null);

  const customerProfilesModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsCustomerProfilesModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsCustomerProfilesModalOpen(false);
      }, 200);
    },
  }

  const onCustomerProfileRowClicked = (ev, customer) => {
    ev.preventDefault();
    setTimeout(() => {
      fillUpDetailsFromProfile(customer);
      setIsCustomerProfilesModalOpen(false);
    }, 100);
  }

  const onCustomerProfilesPageClicked = (page) => {
    fetchCustomerProfiles(page);
  }

  const onCustomerProfilesPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    fetchCustomerProfiles(page);
  }

  const onCustomerProfilesNextPageClicked = (fromPage) => {
    let page = Math.min(salesInquiries['pagination']['totalPages'], fromPage + 1);
    fetchCustomerProfiles(page);
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={"form-section"}>
        <div className={"entry-form"}>
          {formMode !== formModes.quotationRequest.newEntry &&
            <>
              <div className={"form-label"}>
                Review Status:
              </div>
              <div className={"form-input has-badge"}>
                <ReviewStatusBadge status={editingEntry['reviewStatus']} />
              </div>
            </>
          }

          <div className={"form-label"}>
            <label htmlFor={"currency"}>
              Currency:
            </label>
          </div>
          <div className={"form-input"}>
            {!isFormReadonlyMode() &&
              <select id={"currency"} className={"form-control form-select"} disabled={isSubmitting}
                      value={currency} onChange={onCurrencyChanged}>
                {Object.entries(refs.currency).map(([key, item], index) =>
                  <option key={key} value={item.code}>{item.name} ({item.sign})</option>
                )}
              </select>
            }
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {getCurrencyDisplay(currency)}
              </ReadonlyField>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"requestDate"}>
              Request Date:<span className={"error-message"}>*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                <Moment date={requestDate} format={formatters.datetime} />
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <DatePicker id={"requestDate"} className={getRequestDateClassName()} placeholderText={"Type a valid date or click to choose"} required={true}
                            dateFormat={formatters.datetimePicker} todayButton={"Today"} showWeekNumbers
                            disabled={isSubmitting} autoComplete={"none"}
                            selected={requestDate} onChange={onRequestDateChanged} />
                <FieldErrorMessages visible={hasErrors} messages={formErrors['requestDate']} />
              </>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"requestedBy"}>
              Requested By:
            </label>
          </div>
          <div className={"form-input"}>
            {editingEntry &&
              <ReadonlyField>
                <ActorNameDisplay employee={editingEntry['requestedBy']} />
              </ReadonlyField>
            }
            {!editingEntry &&
              <ReadonlyField>You</ReadonlyField>
            }
          </div>

          {!isFormReadonlyMode() &&
            <>
              <div className={"form-input"}>
                <button type={"button"} className={"btn btn-secondary"} disabled={isSalesInquiriesLoading}
                        onClick={salesInquiriesModal.onOpenButtonClicked}>
                  {isSalesInquiriesLoading && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                  {!isSalesInquiriesLoading && <i className="fa-solid fa-magnifying-glass-arrow-right"></i>}
                  Pull details from inquiry...
                </button>
              </div>
              <div className={"form-input"}>
                <button type={"button"} className={"btn btn-secondary"} disabled={isCustomerProfilesLoading}
                        onClick={customerProfilesModal.onOpenButtonClicked}>
                  <i className={"fa-solid fa-clipboard-user"}></i>
                  Choose customer profile...
                </button>
              </div>
            </>
          }

          <SalesInquiryListModal isOpen={isSalesInquiriesModalOpen} isLoading={isSalesInquiriesLoading}
                                 onRequestClose={salesInquiriesModal.onCloseButtonClicked}
                                 salesInquiries={salesInquiries} pagination={inquiriesPagination}
                                 onInquiryRowClicked={onSalesInquiryRowClicked}
                                 onPageClicked={onSalesInquiriesPageClicked}
                                 onPrevPageClicked={onSalesInquiriesPrevPageClicked}
                                 onNextPageClicked={onSalesInquiriesNextPageClicked} />

          <CustomerListModal isOpen={isCustomerProfilesModalOpen} isLoading={isCustomerProfilesLoading}
                             onRequestClose={customerProfilesModal.onCloseButtonClicked}
                             customerProfiles={customerProfiles} pagination={customersPagination}
                             onCustomerRowClicked={onCustomerProfileRowClicked}
                             onPageClicked={onCustomerProfilesPageClicked}
                             onPrevPageClicked={onCustomerProfilesPrevPageClicked}
                             onNextPageClicked={onCustomerProfilesNextPageClicked} />
        </div>

        <h2>Customer Details</h2>

        <div className={"entry-form quotation-entry-form"}>
          <div className={"form-label"}>
            <label htmlFor={"dontIntervene1"}>
              Customer Name:<span className={"error-message"}>*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>{customerName}</ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <input type={"text"} id={"dontIntervene"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                       value={customerName} onChange={onCustomerNameChanged} maxLength={100} />
                <FieldErrorMessages visible={hasErrors} messages={formErrors['customerName']} />
              </>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"dontIntervene2"}>
              Contact Person:<span className={"error-message"}>*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {contactName}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <input type={"text"} id={"dontIntervene2"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                       value={contactName} onChange={onContactNameChanged} maxLength={100} />
                <FieldErrorMessages visible={hasErrors} messages={formErrors['contactName']} />
              </>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"contactNo"}>
              Contact Phone No:<span className={"error-message"}>*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {contactNo}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <input type={"text"} id={"contactNo"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                       value={contactNo} onChange={onContactNoChanged} maxLength={50} />
                <FieldErrorMessages visible={hasErrors} messages={formErrors['contactNo']} />
              </>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"dontIntervene3"}>
              Designation:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {designation ? designation : '-'}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <input type={"text"} id={"dontIntervene3"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                       value={designation} onChange={onDesignationChanged} maxLength={50} />
              </>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"address"}>
              Address:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {address ? address : '-'}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <textarea id={"address"} className={"form-control"} rows={3} autoComplete={"none"} disabled={isSubmitting}
                        value={address} onChange={onAddressChanged}>
              </textarea>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"source"}>
              Source:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {formatSourceName(source)}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <SourceDropdown sourceId={source} onSourceChanged={onSourceChanged} isDisabled={isSubmitting} />
            }
            {!isFormReadonlyMode() && sourceDescription &&
              <div className={"form-field-message"}>
                {sourceDescription}
              </div>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"businessType"}>
              Business Type:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {getBusinessTypeDisplay()}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <select id={"businessType"} name={"businessType"} className={"form-control form-select"} disabled={isSubmitting}
                        value={businessType} onChange={onBusinessTypeChanged}>
                  {businessTypes && businessTypes.map(type => <option key={type.id} value={type.id}>{type.name}</option>)}
                </select>
              </>
            }
          </div>
        </div>

        <h2>Other Information</h2>

        <div className={"entry-form quotation-entry-form"}>
          <div className={"form-label"}>
            <label htmlFor={"remarks"}>
              Remarks:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {remarks ? remarks : '-'}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <textarea id={"remarks"} className={"form-control"} rows={3} autoComplete={"none"} disabled={isSubmitting}
                        value={remarks} onChange={onRemarksChanged}>
              </textarea>
            }
          </div>
        </div>

        <div className={"form-button-controls"}>
          <div className={"left-side"}>
            {!isFormReadonlyMode() && !isFormEditMode() && isFormOwner() &&
              <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                      onClick={onSaveClicked}>
                {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                {!isSubmitting && <i className={"fa-solid fa-circle-right"}></i>}
                <span>Save and continue</span>
              </button>
            }
            {isFormReadonlyMode() && !isFormSubmitted() && isFormOwner() &&
              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isSubmittingDetails}
                      onClick={onEditClicked}>
                <i className="fa-solid fa-pen"></i>
                <span>Edit</span>
              </button>
            }
            {isFormEditMode() && isFormOwner() &&
              <>
                <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                        onClick={(ev) => onSaveClicked(ev, true)}>
                  {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                  {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                  <span>Update</span>
                </button>
                <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                        onClick={onCancelClicked}>
                  <i className={"fa-solid fa-xmark"}></i>
                  <span>Cancel</span>
                </button>
              </>
            }
            {isFormReadonlyMode() && customerProfileStatus === customer.profileStatus.draft &&
              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                      onClick={onCreateProfileClicked}>
                {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                {!isSubmitting && <i className="fa-solid fa-id-card-clip"></i>}
                <span>Create Customer Profile</span>
              </button>
            }
            {isFormReadonlyMode() && customerProfileStatus === customer.profileStatus.active &&
              <div className={"customer-profile-status-label"}>
                <i className="fa-solid fa-check-circle"></i>
                Customer profile created/attached.
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
  //#endregion
}
