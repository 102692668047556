import React from "react";
import { useNavigate } from "react-router-dom";

import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { ModalContainer } from "../shared/ModalContainer";

const callTypes = refs.customer.callType;
const contactTypes = refs.customer.contactType;

export const NewContactLogModal = ({
                                     isOpen, onRequestClose,
                                     serviceProfileId, customerId = null, serviceGeneratorId = null,
                                     followupServiceHistoryId = null, followupServiceType = null,
                                   }) => {
  const navigate = useNavigate();

  //#region Control handlers
  const onPostServiceFollowUpCallClicked = (ev) => {
    let url = routes.contactLogEntry.url;
    navigate(url, {
      state: {
        'serviceProfileId': serviceProfileId,
        'serviceGeneratorId': serviceGeneratorId,
        'callType': null,
        'contactType': contactTypes.postServiceCall,
        'followupServiceHistoryId': followupServiceHistoryId,
        'followupServiceType': followupServiceType,
      }
    });
  }

  const onInquiryClicked = (ev) => {
    let url = routes.contactLogEntry.url;
    if (serviceGeneratorId === null) {
      url = routes.contactLogProfileEntry.url;
    }
    navigate(url, {
      state: {
        'serviceProfileId': serviceProfileId,
        'customerId': customerId,
        'serviceGeneratorId': serviceGeneratorId,
        'callType': null,
        'contactType': contactTypes.inquiry,
        'followupServiceHistoryId': followupServiceHistoryId,
        'followupServiceType': followupServiceType,
      }
    });
  }

  const onComplaintClicked = (ev) => {
    let url = routes.contactLogEntry.url;
    if (serviceGeneratorId === null) {
      url = routes.contactLogProfileEntry.url;
    }
    navigate(url, {
      state: {
        'serviceProfileId': serviceProfileId,
        'customerId': customerId,
        'serviceGeneratorId': serviceGeneratorId,
        'callType': null,
        'contactType': contactTypes.complaint,
        'followupServiceHistoryId': followupServiceHistoryId,
        'followupServiceType': followupServiceType,
      }
    });
  }

  const onOutboundCallClicked = (ev) => {
    let url = routes.contactLogEntry.url;
    if (serviceGeneratorId === null) {
      url = routes.contactLogProfileEntry.url;
    }
    navigate(url, {
      state: {
        'serviceProfileId': serviceProfileId,
        'customerId': customerId,
        'serviceGeneratorId': serviceGeneratorId,
        'callType': callTypes.outbound,
        'contactType': null,
        'followupServiceHistoryId': followupServiceHistoryId,
        'followupServiceType': followupServiceType,
      }
    });
  }

  const onInboundCallClicked = (ev) => {
    let url = routes.contactLogEntry.url
    if (serviceGeneratorId === null) {
      url = routes.contactLogProfileEntry.url;
    }
    navigate(url, {
      state: {
        'serviceProfileId': serviceProfileId,
        'customerId': customerId,
        'serviceGeneratorId': serviceGeneratorId,
        'callType': callTypes.inbound,
        'contactType': null,
        'followupServiceHistoryId': followupServiceHistoryId,
        'followupServiceType': followupServiceType,
      }
    });
  }
  //#endregion

  //#region Render
  return (
    <ModalContainer elementId={"new-contact-log-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose} isLoading={false}
                    title={"New Contact Log"}
                    shortDescription={"Choose an option to create a new Contact Log..."}>
      <div className={"modal-controls-grid"}>
        <div className={"modal-controls-group"}>
          Service-related Calls
        </div>
        <div className={"modal-controls-items"}>
          {serviceGeneratorId &&
            <button type={"button"} disabled={false} onClick={onPostServiceFollowUpCallClicked}>
              <i className="fa-solid fa-person-chalkboard"></i>
              <span>Post-service Follow Up Call</span>
            </button>
          }
          <button type={"button"} disabled={false} onClick={onInquiryClicked}>
            <i className="fa-solid fa-user-pen"></i>
            <span>Inquiry</span>
          </button>
          <button type={"button"} disabled={false} onClick={onComplaintClicked}>
            <i className="fa-solid fa-user-clock"></i>
            <span>Complaint</span>
          </button>
        </div>

        <div className={"modal-controls-group"}>
          General Calls
        </div>
        <div className={"modal-controls-items"}>
          <button type={"button"} disabled={false} onClick={onOutboundCallClicked}>
            <i className="fa-solid fa-phone-flip"></i>
            <span>Outbound Call</span>
          </button>
          <button type={"button"} disabled={false} onClick={onInboundCallClicked}>
            <i className="fa-solid fa-phone-volume"></i>
            <span>Inbound Call</span>
          </button>
        </div>
      </div>
    </ModalContainer>
  )
  //#endregion
}
