import React from "react";
import ReactModal from "react-modal";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";

import { navigableRoutes as routes } from "./repos/constants";

import { AuthProvider } from "./components/auth/AuthProvider";
import { RequireAuth } from "./components/auth/RequireAuth";

import { NotFound } from "./components/shared/NotFound";
import { Header } from "./components/shared/Header";
import { Footer } from "./components/shared/Footer";
import { Navigation } from "./components/shared/Navigation";
import { LoginPage } from "./components/auth/LoginPage";
import { DashboardPage } from "./components/dashboard/DashboardPage";

import { SalesInquiryListPage } from "./components/sales/SalesInquiryListPage";
import { SalesInquiryEntryPage } from "./components/sales/SalesInquiryEntryPage";
import { QuotationRequestListPage } from "./components/quotation/QuotationRequestListPage";
import { QuotationRequestEntryPage } from "./components/quotation/QuotationRequestEntryPage";
import { OrderConfirmationListPage } from "./components/order/OrderConfirmationListPage";
import { OrderConfirmationEntryPage } from "./components/order/OrderConfirmationEntryPage";
import { PaymentBreakdownPage } from "./components/order/PaymentBreakdownPage";
import { InvoicesListPage } from "./components/invoice/InvoicesListPage";
import { InvoiceEntryPage } from "./components/invoice/InvoiceEntryPage";
import { SalesTargetsListPage } from "./components/sales/SalesTargetsListPage";
import { SalesTargetEntryPage } from "./components/sales/SalesTargetEntryPage";

import { StocksListPage } from "./components/service/StocksListPage";
import { StockDetailsPage } from "./components/service/StockDetailsPage";
import { ArrivalInspectionEntryPage } from "./components/service/ArrivalInspectionEntryPage";
import { StockInspectionEntryPage } from "./components/service/StockInspectionEntryPage";
import { StockInspectionsListPage } from "./components/service/StockInspectionsListPage";

import { ServiceProfilesListPage } from "./components/service/ServiceProfilesListPage";
import { NewServiceProfilePage } from "./components/service/NewServiceProfilePage";
import { ServiceProfilePage } from "./components/service/ServiceProfilePage";
import { ManageProfileLocationsPage } from "./components/service/ManageProfileLocationsPage";
import { ManagePortalUsersPage } from "./components/service/ManagePortalUsersPage";
import { NewCommissionEntryPage } from "./components/service/NewCommissionEntryPage";
import { ServiceGeneratorPage } from "./components/service/ServiceGeneratorPage";
import { TestingAndCommissionEntryPage } from "./components/service/TestingAndCommissionEntryPage";
import { PreventiveMaintenanceEntryPage } from "./components/service/PreventiveMaintenanceEntryPage";
import { RegularEntryPage } from "./components/service/RegularEntryPage";
import { RepairEntryPage } from "./components/service/RepairEntryPage";
import { EmergencyBreakdownEntryPage } from "./components/service/EmergencyBreakdownEntryPage";
import { InspectionEntryPage } from "./components/service/InspectionEntryPage";
import { ContactLogEntryPage } from "./components/customer/ContactLogEntryPage";
import { ContactLogProfileEntryPage } from "./components/customer/ContactLogProfileEntryPage";

import { JobHistoriesListPage } from "./components/service/JobHistoriesListPage";

import { ServiceReportsPage } from "./components/service_reports/ServiceReportsPage";
import { OverWarrantyGeneratorReportsListPage } from "./components/service_reports/OverWarrantyGeneratorReportsListPage";
import { OverWarrantyGeneratorReportPage } from "./components/service_reports/OverWarrantyGeneratorReportPage";
import { WarrantyStatusesReportsListPage } from "./components/service_reports/WarrantyStatusesReportsListPage";
import { WarrantyStatusesReportPage } from "./components/service_reports/WarrantyStatusesReportPage";
import { WorkDoneSummaryReportsListPage } from "./components/service_reports/WorkDoneSummaryReportsListPage";
import { WorkDoneSummaryReportPage } from "./components/service_reports/WorkDoneSummaryReportPage";

import { CustomerListPage } from "./components/customer/CustomerListPage";
import { CustomerProfilePage } from "./components/customer/CustomerProfilePage";
import { CareContactLogsListPage } from "./components/customer/CareContactLogsListPage";

import { ManageUsersPage } from "./components/system/ManageUsersPage";
import { UserAccountsSetupPage } from "./components/system/UserAccountsSetupPage";
import { RolesPermissionsSetupPage } from "./components/system/RolesPermissionsSetupPage";
import { GroupsSetupPage } from "./components/system/GroupsSetupPage";
import { ManageReferencesPage } from "./components/system/ManageReferencesPage";
import { StatesDivisionsSetupPage } from "./components/system/StatesDivisionsSetupPage";
import { BusinessTypesSetupPage } from "./components/system/BusinessTypesSetupPage";

import './App.css';


function App() {
  ReactModal.setAppElement('#root');

  return (
    <AuthProvider>
      <Header />

      <div id={"master-container"}>
        <Navigation />

        <Routes>
          <Route path={routes.login.url} element={<LoginPage />}></Route>
          <Route path={routes.dashboard.url} element={<RequireAuth routeId={0}><DashboardPage /></RequireAuth>}></Route>

          {/*Sales Inquiries*/}
          <Route path={routes.salesInquiriesList.url} element={<RequireAuth routeId={routes.salesInquiriesList.id}><SalesInquiryListPage /></RequireAuth>}></Route>
          <Route path={routes.salesInquiriesEntry.url} element={<RequireAuth routeId={routes.salesInquiriesList.id}><SalesInquiryEntryPage /></RequireAuth>}></Route>

          {/*Quotation Requests*/}
          <Route path={routes.quotationRequestsList.url} element={<RequireAuth routeId={routes.quotationRequestsList.id}><QuotationRequestListPage /></RequireAuth>}></Route>
          <Route path={routes.quotationRequestEntry.url} element={<RequireAuth routeId={routes.quotationRequestsList.id}><QuotationRequestEntryPage /></RequireAuth>}></Route>

          {/*Order Confirmations*/}
          <Route path={routes.orderConfirmationsList.url} element={<RequireAuth routeId={routes.orderConfirmationsList.id}><OrderConfirmationListPage /></RequireAuth>}></Route>
          <Route path={routes.orderConfirmationEntry.url} element={<RequireAuth routeId={routes.orderConfirmationsList.id}><OrderConfirmationEntryPage /></RequireAuth>}></Route>
          <Route path={routes.orderConfirmationPaymentBreakdown.url} element={<RequireAuth routeId={routes.orderConfirmationsList.id}><PaymentBreakdownPage /></RequireAuth>}></Route>

          {/* Invoices */}
          <Route path={routes.invoicesList.url} element={<RequireAuth routeId={routes.invoicesList.id}><InvoicesListPage /></RequireAuth>}></Route>
          <Route path={routes.invoiceEntry.url} element={<RequireAuth routeId={routes.invoicesList.id}><InvoiceEntryPage /></RequireAuth>}></Route>

          {/* Sales Targets */}
          <Route path={routes.salesTargetsList.url} element={<RequireAuth routeId={routes.salesTargetsList.id}><SalesTargetsListPage /></RequireAuth>}></Route>
          <Route path={routes.salesTargetEntry.url} element={<RequireAuth routeId={routes.salesTargetsList.id}><SalesTargetEntryPage /></RequireAuth>}></Route>

          {/*Generator Stocks*/}
          <Route path={routes.stocks.url} element={<RequireAuth routeId={routes.stocks.id}><StocksListPage /></RequireAuth>}></Route>
          <Route path={routes.stockDetails.url} element={<RequireAuth routeId={routes.stockDetails.id}><StockDetailsPage /></RequireAuth>}></Route>
          <Route path={routes.arrivalInspection.url} element={<RequireAuth routeId={routes.stocks.id}><ArrivalInspectionEntryPage /></RequireAuth>}></Route>
          <Route path={routes.stockInspection.url} element={<RequireAuth routeId={routes.stocks.id}><StockInspectionEntryPage /></RequireAuth>}></Route>

          {/*Stock Inspections*/}
          <Route path={routes.stockInspections.url} element={<RequireAuth routeId={routes.stockInspections.id}><StockInspectionsListPage /></RequireAuth>}></Route>

          {/*Service Profiles*/}
          <Route path={routes.serviceProfiles.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><ServiceProfilesListPage /></RequireAuth>}></Route>
          <Route path={routes.newServiceProfile.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><NewServiceProfilePage /></RequireAuth>}></Route>
          <Route path={routes.serviceProfile.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><ServiceProfilePage /></RequireAuth>}></Route>
          <Route path={routes.manageProfileLocations.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><ManageProfileLocationsPage /></RequireAuth>}></Route>
          <Route path={routes.managePortalUsers.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><ManagePortalUsersPage /></RequireAuth>}></Route>
          <Route path={routes.newGeneratorCommission.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><NewCommissionEntryPage /></RequireAuth>}></Route>
          <Route path={routes.serviceGenerator.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><ServiceGeneratorPage /></RequireAuth>}></Route>
          <Route path={routes.testingAndCommission.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><TestingAndCommissionEntryPage /></RequireAuth>}></Route>
          <Route path={routes.preventiveMaintenance.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><PreventiveMaintenanceEntryPage /></RequireAuth>}></Route>
          <Route path={routes.regularService.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><RegularEntryPage /></RequireAuth>}></Route>
          <Route path={routes.repairService.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><RepairEntryPage /></RequireAuth>}></Route>
          <Route path={routes.emergencyBreakdown.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><EmergencyBreakdownEntryPage /></RequireAuth>}></Route>
          <Route path={routes.inspection.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><InspectionEntryPage /></RequireAuth>}></Route>
          <Route path={routes.contactLogEntry.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><ContactLogEntryPage /></RequireAuth>}></Route>
          <Route path={routes.contactLogProfileEntry.url} element={<RequireAuth routeId={routes.serviceProfiles.id}><ContactLogProfileEntryPage /></RequireAuth>}></Route>

          {/*Job Histories*/}
          <Route path={routes.jobHistories.url} element={<RequireAuth routeId={routes.jobHistories.id}><JobHistoriesListPage /></RequireAuth>}></Route>

          {/*Service Reports*/}
          <Route path={routes.serviceReports.url} element={<RequireAuth routeId={routes.serviceReports.id}><ServiceReportsPage /></RequireAuth>}></Route>
          <Route path={routes.serviceReports_OverWarrantyGenerators.url} element={<RequireAuth routeId={routes.serviceReports.id}><OverWarrantyGeneratorReportsListPage /></RequireAuth>}></Route>
          <Route path={routes.serviceReports_OverWarrantyGeneratorReport.url} element={<RequireAuth routeId={routes.serviceReports.id}><OverWarrantyGeneratorReportPage /></RequireAuth>}></Route>
          <Route path={routes.serviceReports_WarrantyStatuses.url} element={<RequireAuth routeId={routes.serviceReports.id}><WarrantyStatusesReportsListPage /></RequireAuth>}></Route>
          <Route path={routes.serviceReports_WarrantyStatusesReport.url} element={<RequireAuth routeId={routes.serviceReports.id}><WarrantyStatusesReportPage /></RequireAuth>}></Route>
          <Route path={routes.serviceReports_WorkDoneSummary.url} element={<RequireAuth routeId={routes.serviceReports.id}><WorkDoneSummaryReportsListPage /></RequireAuth>}></Route>
          <Route path={routes.serviceReports_WorkDoneSummaryReport.url} element={<RequireAuth routeId={routes.serviceReports.id}><WorkDoneSummaryReportPage /></RequireAuth>}></Route>


          {/*Customer Profiles*/}
          <Route path={routes.customersList.url} element={<RequireAuth routeId={routes.customersList.id}><CustomerListPage /></RequireAuth>}></Route>
          <Route path={routes.customerProfile.url} element={<RequireAuth routeId={routes.customersList.id}><CustomerProfilePage /></RequireAuth>}></Route>

          {/*Contact Logs*/}
          <Route path={routes.careContactLogs.url} element={<RequireAuth routeId={routes.careContactLogs.id}><CareContactLogsListPage /></RequireAuth>}></Route>

          {/*System Setups*/}
          <Route path={routes.manageUsersAndPermissions.url} element={<RequireAuth routeId={routes.manageUsersAndPermissions.id}><ManageUsersPage /></RequireAuth>}></Route>
          <Route path={routes.userAccountsSetup.url} element={<RequireAuth routeId={routes.manageUsersAndPermissions.id}><UserAccountsSetupPage /></RequireAuth>}></Route>
          <Route path={routes.rolesPermissionsSetup.url} element={<RequireAuth routeId={routes.manageUsersAndPermissions.id}><RolesPermissionsSetupPage /></RequireAuth>}></Route>
          <Route path={routes.groupsSetup.url} element={<RequireAuth routeId={routes.manageUsersAndPermissions.id}><GroupsSetupPage /></RequireAuth>}></Route>
          <Route path={routes.manageReferences.url} element={<RequireAuth routeId={routes.manageReferences.id}><ManageReferencesPage /></RequireAuth>}></Route>
          <Route path={routes.statesDivisionsSetup.url} element={<RequireAuth routeId={routes.manageReferences.id}><StatesDivisionsSetupPage /></RequireAuth>}></Route>
          <Route path={routes.businessTypesSetup.url} element={<RequireAuth routeId={routes.manageReferences.id}><BusinessTypesSetupPage /></RequireAuth>}></Route>

          <Route path="*" element={<NotFound />}></Route>
        </Routes>

        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
