import ReactModal from 'react-modal';
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { salesServices } from "../../repos/apiServices";
import { utils } from "../../repos/constants";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { ActorNameDisplay } from "./ActorNameDisplay";
import { EmployeeBranchGroupsDropdown } from "../shared/EmployeesDropdown";

import "../shared/EntryForm.css";
import "../shared/Modals.css";
import "./OrderConfirmationAssignModal.css";


export const OrderConfirmationAssignModal = ({
                                               isOpen, onRequestClose,
                                               assignments, editingEntry,
                                             }) => {
  //#region States
  const [entryAssignments, setEntryAssignments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);

  const [assignSalesPerson, setAssignSalesPerson] = useState('');
  const [isSubmittingAssign, setIsSubmittingAssign] = useState(false);
  const [assignErrorMessage, setAssignErrorMessage] = useState('');

  const auth = useAuth();
  //#endregion

  //#region Effects
  useEffect(() => {
    setIsLoading(true);
    setEntryAssignments(assignments);
    refServices2.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _responseData = response['data'];
        setEmployeeBranchGroups(_responseData);
      })
      .finally(() => setIsLoading(false));
  }, []);
  //#endregion

  //#region Utilities
  const resetFormFields = () => {
    setAssignSalesPerson('');
    setAssignErrorMessage('');
    setIsSubmittingAssign(false);
  }
  //#endregion

  //#region Control handlers
  const onModalRequestClose = (ev) => {
    resetFormFields();
    onRequestClose(ev);
  }

  const onAssignSalesPersonChanged = (ev) => {
    setAssignSalesPerson(ev.target.value);
  }

  const onAssignClicked = (ev) => {
    if (assignSalesPerson === '') {
      setAssignErrorMessage('Choose applicable sales user to assign.');
      return;
    }
    setAssignErrorMessage('');

    let payload = {
      'entryId': editingEntry['entryId'],
      'assignEmployeeId': assignSalesPerson,
      'userId': auth.getUserId(),
    }
    setIsSubmittingAssign(true);
    salesServices.orderConfirmationCCAssign(payload)
      .then((response) => {
        setEntryAssignments(response.data['assignments']);
        setAssignSalesPerson('');
        setAssignErrorMessage('');
      })
      .catch((error) => {
        let errorMessage = error.response.data['error'];
        setAssignErrorMessage(errorMessage);
      })
      .finally(() => setIsSubmittingAssign(false));
  }

  const onUnAssignClicked = (ev, assignee) => {
    let payload = {
      'assignId': assignee['assignId'],
      'entryId': assignee['orderConfirmationEntryId'],
      'userId': auth.getUserId(),
    }
    setIsLoading(true);
    salesServices.orderConfirmationCCUnassign(payload)
      .then((response) => {
        setEntryAssignments(response.data['assignments']);
        setAssignErrorMessage('');
      })
      .catch((error) => {
        let errorMessage = error.response.data['error'];
        setAssignErrorMessage(errorMessage);
      })
      .finally(() => setIsLoading(false));
  }
  //#endregion

  //#region Render
  return (
    <ReactModal isOpen={isOpen} id={"order-confirmation-assign-modal"}
                className={"popup-modal popup-modal-small"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onModalRequestClose}>

      <div className={"modal-heading"}>
        <h2>Assign Users</h2>
      </div>

      <div className={"modal-floated-controls"}>
        <button type={"button"} className={"btn btn-light"} onClick={onModalRequestClose}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div className={"modal-contents"}>
        <div>
          Assign another sales department user to let them have access to this order confirmation entry, when its <i>status becomes <b>active</b></i>.
        </div>
      </div>

      <div className={"modal-form"}>
        <div className={"form-label"}>
          Created by:
        </div>
        <div className={"form-input"}>
          <div className={"readonly-field"}>
            <ActorNameDisplay employee={editingEntry['createdBy']} />
          </div>
        </div>

        {entryAssignments && entryAssignments.length > 0 &&
          <>
            <div className={"form-label"}>
              Assignment{entryAssignments.length > 1 ? "s" : ""}:
            </div>
            <div className={"form-input"}>
              <div className={"values-grid"}>
                {entryAssignments.map(assignment =>
                  <OrderConfirmationAssignment key={assignment['id']} assignment={assignment}
                                               onUnAssignClicked={onUnAssignClicked} isLoading={isLoading}
                                               isSubmittingAssign={isSubmittingAssign} />
                )}
              </div>
            </div>
          </>
        }

        <div className={"form-label"}>
          Assign to:<span className={"error-message"}>*</span>
        </div>
        <div className={"form-input"}>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"assignSalesPersons"} name={"assignSalesPersons"}
                                        isDisabled={isLoading || isSubmittingAssign}
                                        value={assignSalesPerson} onChange={onAssignSalesPersonChanged} />
          <FieldErrorMessage visible={assignErrorMessage !== ''} message={assignErrorMessage} />
        </div>
      </div>

      <div className={"modal-controls form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-secondary"} disabled={isLoading || isSubmittingAssign}
                  onClick={onAssignClicked}>
            {isSubmittingAssign && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
            {!isSubmittingAssign && <i className="fa-solid fa-user-plus"></i>}
            Assign
          </button>
        </div>
      </div>

    </ReactModal>
  )
  //#endregion
}


const OrderConfirmationAssignment = ({
                                       key, assignment, onUnAssignClicked,
                                       isLoading, isSubmittingAssign,
                                     }) => {
  return (
    <>
      <div className={"readonly-field"}>
        <ActorNameDisplay employee={assignment['assignee']} />
      </div>
      <div className={"field-control"}>
        <button type={"button"} className={"btn btn-light btn-sm danger-button"}
                disabled={isLoading || isSubmittingAssign}
                onClick={(ev) => onUnAssignClicked(ev, assignment)}>
          <i className="fa-solid fa-user-minus"></i>
        </button>
      </div>
    </>
  )
}
