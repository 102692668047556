import Moment from "react-moment";

import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";

import { ActorNameDisplay } from "../sales/ActorNameDisplay";

export const CancelStatus = ({
                               status, reviewedBy, reviewDate,
                             }) => {
  return (
    <span className={"cancel-warning"}>
      <i className="fa-solid fa-triangle-exclamation"></i>
      <span>
        Cancel request {refs.sales.reviewStatus[status].toLowerCase()} by&nbsp;
        <ActorNameDisplay employee={reviewedBy}></ActorNameDisplay> on&nbsp;
        <Moment date={reviewDate} format={formatters.datetime} />.
      </span>
    </span>
  )
}
