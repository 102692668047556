import React from "react";

import { refs } from "../../repos/constants";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { getSummaryCount } from "../shared/SummaryModal";
import { TwoColumnsRow } from "../shared/SummaryModal";
import { TwoColumnsFooter } from "../shared/SummaryModal";

export const contactLogsSummaryTypes = {
  1: "Satisfaction Level", satisfactionLevel: 1,
  2: "Call Type", callType: 2,
  3: "Contact Type", contactType: 3,
  4: "Follow Up Service", followUpService: 4,
}

export const CareContactLogsSummaryModal = ({
                                              isOpen, onRequestClose,
                                              isSummaryLoading,
                                              summaryType, setSummaryType,
                                              data, total,
                                            }) => {
  const onSummaryTypeChanged = (ev) => {
    const _summaryType = parseInt(ev.target.value);
    setSummaryType(_summaryType);
  }

  // #region Render
  return (
    <ModalContainer elementId={"care-contact-logs-summaries"}
                    isOpen={isOpen} onRequestClose={onRequestClose} isLoading={false}
                    title={"Quick Summary"}
                    shortDescription={"Please note that the listing search criteria are being applied to the summary too."}
                    modalSize={modalSizes.tiny} bottom={"160px"}>
      <div className={"modal-form"}>
        <div className={"form-label"}>
          View by:
        </div>
        <div className={"form-input"}>
          <select id={"viewByOptions"} name={"viewByOptions"} className={"form-control form-select md-field"}
                  disabled={isSummaryLoading} value={summaryType} onChange={onSummaryTypeChanged}>
            <option value={contactLogsSummaryTypes.satisfactionLevel}>
              {contactLogsSummaryTypes[contactLogsSummaryTypes.satisfactionLevel]}
            </option>
            <option value={contactLogsSummaryTypes.callType}>
              {contactLogsSummaryTypes[contactLogsSummaryTypes.callType]}
            </option>
            <option value={contactLogsSummaryTypes.contactType}>
              {contactLogsSummaryTypes[contactLogsSummaryTypes.contactType]}
            </option>
            <option value={contactLogsSummaryTypes.followUpService}>
              {contactLogsSummaryTypes[contactLogsSummaryTypes.followUpService]}
            </option>
          </select>
        </div>
      </div>

      <div className={"modal-data-table modal-padded-top"}>
        <table>
          <thead>
          <tr>
            <th scope={"col"} className={"index-col-head small-col"}>#</th>
            <th scope={"col"}>
              Description
            </th>
            <th scope={"col"} className={"number-col"}>
              Count
            </th>
            <td scope={"col"} className={"right-padded"}></td>
          </tr>
          </thead>

          <tbody>
          {isSummaryLoading &&
            <TableLoadingIndicator colspan={4} />
          }

          {!isSummaryLoading && data.length === 0 &&
            <TableEmptyRow colSpan={4} />
          }

          {!isSummaryLoading && data.length > 0 &&
            <SummaryRows data={data} summaryType={summaryType} />
          }
          </tbody>
          {!isSummaryLoading && data.length > 0 &&
            <TwoColumnsFooter total={total} />
          }
        </table>
      </div>

      <div className={"modal-contents"}>
      </div>

    </ModalContainer>
  )
  //#endregion
}

const satisfactionRefs = refs.customer.satisfaction;
const callTypeRefs = refs.customer.callType;
const contactTypeRefs = refs.customer.contactType;
const followUpServiceTypeRefs = refs.inventory.serviceType;

const SummaryRows = ({ data, summaryType }) => {
  switch (summaryType) {
    case contactLogsSummaryTypes.satisfactionLevel: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={satisfactionRefs[satisfactionRefs.satisfied]}
                         count={getSummaryCount(data, satisfactionRefs.satisfied)} />
          <TwoColumnsRow index={1}
                         description={satisfactionRefs[satisfactionRefs.dissatisfied]}
                         count={getSummaryCount(data, satisfactionRefs.dissatisfied)} />
          <TwoColumnsRow index={4}
                         description={satisfactionRefs[satisfactionRefs.unreachable]}
                         count={getSummaryCount(data, satisfactionRefs.unreachable)} />
          <TwoColumnsRow index={3}
                         description={satisfactionRefs[satisfactionRefs.na]}
                         count={getSummaryCount(data, satisfactionRefs.na)}
                         isNAColumn={true} />
        </>
      )
    }

    case contactLogsSummaryTypes.callType: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={callTypeRefs[callTypeRefs.inbound]}
                         count={getSummaryCount(data, callTypeRefs.inbound)} />
          <TwoColumnsRow index={1}
                         description={callTypeRefs[callTypeRefs.outbound]}
                         count={getSummaryCount(data, callTypeRefs.outbound)} />
        </>
      )
    }

    case contactLogsSummaryTypes.contactType: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={contactTypeRefs[contactTypeRefs.regularCall]}
                         count={getSummaryCount(data, contactTypeRefs.regularCall)} />
          <TwoColumnsRow index={1}
                         description={contactTypeRefs[contactTypeRefs.postServiceCall]}
                         count={getSummaryCount(data, contactTypeRefs.postServiceCall)} />
          <TwoColumnsRow index={3}
                         description={contactTypeRefs[contactTypeRefs.inquiry]}
                         count={getSummaryCount(data, contactTypeRefs.inquiry)} />
          <TwoColumnsRow index={4}
                         description={contactTypeRefs[contactTypeRefs.complaint]}
                         count={getSummaryCount(data, contactTypeRefs.complaint)} />
          <TwoColumnsRow index={5}
                         description={contactTypeRefs[contactTypeRefs.na]}
                         count={getSummaryCount(data, contactTypeRefs.na)}
                         isNAColumn={true} />
        </>
      )
    }

    case contactLogsSummaryTypes.followUpService: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.tnc]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.tnc)} />
          <TwoColumnsRow index={1}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.pm]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.pm)} />
          <TwoColumnsRow index={2}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.regular]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.regular)} />
          <TwoColumnsRow index={3}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.repair]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.repair)} />
          <TwoColumnsRow index={4}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.emergency]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.emergency)} />
          <TwoColumnsRow index={5}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.inspection]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.inspection)} />
          <TwoColumnsRow index={6}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.na]}
                         count={getSummaryCount(data, null)}
                         isNAColumn={true} />
        </>
      )
    }

    default: {
      return <TableEmptyRow colSpan={4} />
    }
  }
}
