import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { navigableRoutes as routes } from "../../repos/constants";
import { systemServices } from "../../repos/apiServices2";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { SortIndicator } from "../shared/SortIndicator";

import "../shared/ListingPage.css";
import "../shared/DataTable.css";

export const BusinessTypesSetupPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  const fetchBusinessTypes = () => {
    setIsLoading(true);
    systemServices.fetchBusinessTypes()
      .then((response) => {
        setBusinessTypes(response['data']);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchBusinessTypes();
  }

  const onNewClicked = (ev) => {
    // TODO(yemon): Show new entry modal
  }

  const onItemClicked = (ev, businessType) => {
    ev.preventDefault();
    // TODO(yemon): Populate the select item into the entry modal and show it
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.manageReferences.displayShort} anchorTo={routes.manageReferences.url} />
              <BreadcrumbItem text={routes.businessTypesSetup.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.businessTypesSetup.display}</h1>

            <div className={"listing-controls"}>
              <div className={"left"}>
                <button className={"btn btn-secondary"} disabled={isLoading}
                        onClick={onRefreshClicked}>
                  {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                  {!isLoading && <i className="fa-solid fa-rotate"></i>}
                  Refresh
                </button>

                <button className={"btn btn-primary"} disabled={isLoading}
                        onClick={onNewClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New
                </button>
              </div>
            </div>

          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <BusinessTypesTableHeading />
              </thead>
              <tbody>
              {isLoading && businessTypes &&
                <TableLoadingIndicator colspan={3} />
              }

              {!isLoading && businessTypes && businessTypes.length > 0 &&
                businessTypes.map((businessType, index) =>
                  <BusinessTypesTableRow key={businessType['id']} index={index}
                                         businessType={businessType} onItemClicked={onItemClicked} />
                )
              }

              {!isLoading && businessTypes && businessTypes.length === 0 &&
                <TableEmptyRow colSpan={3} />
              }
              </tbody>
            </table>
          </div>

        </div>

      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const BusinessTypesTableHeading = ({}) => {
  return (
    <tr>
      <th scope={"col"} className={"index-col-head fixed-width"}>#</th>
      <th scope={"col"}>
        Name
        <SortIndicator isAscending={true} />
      </th>
      <th>
        Active?
      </th>
    </tr>
  )
}

const BusinessTypesTableRow = ({ index, businessType, onItemClicked }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {businessType['id']}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onItemClicked(ev, businessType)}>
          {businessType["name"]}
        </a>
      </td>
      <td>
        {businessType["isActive"] === true ? "Yes" : "No"}
      </td>
    </tr>
  )
}
