import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { inventoryServices } from "../../repos/apiServices";
import { customerServices } from "../../repos/apiServices";
import { formatters } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { PageAlert } from "../shared/PageAlert";
import { alertTypes } from "../shared/PageAlert";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "../service/NullBlankField";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ActorNameRankDisplay } from "../sales/ActorNameDisplay";
import { JobStatusBadge } from "../service/JobStatusBadge";

import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "./ContactEntryPage.css";


const serviceHistoryTypes = refs.inventory.serviceHistoryType;
const serviceTypes = refs.inventory.serviceType;
const callTypes = refs.customer.callType;
const contactTypes = refs.customer.contactType;
const satisfactions = refs.customer.satisfaction;
const jobStatuses = refs.customer.jobStatus;

export const ContactLogEntryPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);

  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceGeneratorId, setServiceGeneratorId] = useState(null);
  const [serviceHistoryId, setServiceHistoryId] = useState(null);
  const [careContactId, setCareContactId] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessTypeName, setBusinessTypeName] = useState("");
  const [address, setAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [nrc, setNrc] = useState("");
  const [designation, setDesignation] = useState("");

  const [locationContactName, setLocationContactName] = useState("");
  const [locationContactNo, setLocationContactNo] = useState("");

  const [warrantyHours, setWarrantyHours] = useState(null);
  const [warrantyMonths, setWarrantyMonths] = useState(null);
  const [warrantyStatus, setWarrantyStatus] = useState("");

  const [isLoadingGenerator, setIsLoadingGenerator] = useState(false);
  const [isLoadingEntry, setIsLoadingEntry] = useState(false);
  const [serviceGenerator, setServiceGenerator] = useState(null);
  const [generatorSerial, setGeneratorSerial] = useState("");
  const [gensetModel, setGensetModel] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [generatorLocation, setGeneratorLocation] = useState("(location)");

  const [isLoadingFollowupEntry, setIsLoadingFollowupEntry] = useState(false);
  const [followupEntryId, setFollowupEntryId] = useState(null);
  const [followupEntryType, setFollowupEntryType] = useState(null);
  const [followupEntryServiceDate, setFollowupEntryServiceDate] = useState(new Date());
  const [followupEntryServicedBy, setFollowupEntryServicedBy] = useState(null);

  const [callType, setCallType] = useState(callTypes.outbound);
  const [contactType, setContactType] = useState(contactTypes.na);
  const [actualContactName, setActualContactName] = useState("");
  const [actualContactNo, setActualContactNo] = useState("");
  const [contactDate, setContactDate] = useState(new Date());
  const [discussionDetails, setDiscussionDetails] = useState("");
  const [satisfaction, setSatisfaction] = useState(satisfactions.na);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);
  const [isSubmittingDraft, setIsSubmittingDraft] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState(alertTypes.info);
  const [alertMessage, setAlertMessage] = useState("");

  const [isFormReadonly, setIsFormReadonly] = useState(false);
  const [status, setStatus] = useState(refs.customer.jobStatus.draft);
  const [recordedBy, setRecordedBy] = useState({});

  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    if (!state) {
      navigate(routes.serviceProfiles.url);
    }

    let _serviceProfileId = state['serviceProfileId'];
    let _serviceGeneratorId = state['serviceGeneratorId'];
    let _serviceHistoryId = state['serviceHistoryId'];
    let _callType = state['callType'];
    let _contactType = state['contactType'];

    let _followupServiceHistoryId = state['followupServiceHistoryId'];
    let _followupServiceType = state['followupServiceType'];

    if (!_serviceProfileId || !_serviceGeneratorId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      setServiceProfileId(_serviceProfileId);
      setServiceGeneratorId(_serviceGeneratorId);
      fetchServiceGenerator(_serviceProfileId, _serviceGeneratorId);
    }

    if (_followupServiceHistoryId && _followupServiceType) {
      // Following up on another service history entry
      // NOTE(yemon): These can potentially be set during edit mode below as well.
      setFollowupEntryId(_followupServiceHistoryId);
      setFollowupEntryType(_followupServiceType);
      fetchFollowupServiceHistoryEntry(_followupServiceHistoryId, _followupServiceType,
        _serviceProfileId, _serviceGeneratorId)
    }

    if (_serviceHistoryId) {
      // Edit mode
      setServiceHistoryId(_serviceHistoryId);
      fetchCareHistoryContactEntry(_serviceProfileId, _serviceGeneratorId, _serviceHistoryId);
    }

    if (_callType) {
      setCallType(_callType);
    }
    if (_contactType) {
      setContactType(_contactType);
    }

    let _currentEmployee = {
      ...auth.userInfo,
    }
    _currentEmployee['id'] = auth.getUserId()['eid'];
    setRecordedBy(_currentEmployee);
  }, []);

  useEffect(() => {
    if (status === refs.customer.jobStatus.draft) {
      setIsFormReadonly(false);
    }
    else {
      setIsFormReadonly(true);
    }
  }, [status])

  const fetchServiceGenerator = (profileId, generatorId) => {
    let params = {
      'uid': auth.getUserId(),
      'service_profile_id': profileId,
      'service_generator_id': generatorId,
    };
    setIsLoadingGenerator(true);
    inventoryServices.fetchServiceProfileGenerator(params)
      .then((response) => {
        let _serviceGenerator = response['data'];
        let _serviceProfile = _serviceGenerator['serviceLocation']['profile'];
        setCustomerId(_serviceProfile['customer']['id']);
        setServiceProfileId(_serviceProfile['id']);
        setServiceGeneratorId(_serviceGenerator['id']);

        setServiceGenerator(_serviceGenerator);
        prepareProfileAndGeneratorDetails(_serviceGenerator);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        if (errorResponse) {
          if (errorResponse.status === 404 || errorResponse.status === 400) {
            navigate(routes.serviceProfiles.url);
          }
        }
      })
      .finally(() => {
        setIsLoadingGenerator(false);
      })
  }

  const prepareProfileAndGeneratorDetails = (serviceGenerator) => {
    if (!serviceGenerator) {
      return;
    }
    let _serviceLocation = serviceGenerator['serviceLocation'];
    let _serviceProfile = _serviceLocation['profile'];
    let _customer = _serviceProfile['customer'];
    let _stock = serviceGenerator['stock'];

    setCustomerName(_customer['customerName']);
    setCompanyName(_customer['companyName']);
    setBusinessTypeName(_customer['businessType']['name']);
    setAddress(_customer['address']);
    setContactName(_customer['contactFullName']);
    setContactNo(_customer['contactNo']);
    setNrc(_customer['nrc']);
    setDesignation(_customer['designation']);

    setLocationContactName(_serviceLocation['contactFullName']);
    setLocationContactNo(_serviceLocation['contactNo']);

    setGeneratorSerial(_stock['generatorSerial']);
    setGensetModel(_stock['generator']['gensetModel']);
    setManufacturer(_stock['manufacturer']);

    setWarrantyHours(serviceGenerator['warrantyHours']);
    setWarrantyMonths(serviceGenerator['warrantyMonths']);
    setWarrantyStatus(serviceGenerator['warrantyStatus']);
  }

  const fetchCareHistoryContactEntry = (profileId, generatorId, historyId) => {
    let payload = {
      'employee_id': auth.getUserId()['eid'],
      'history_type': serviceHistoryTypes.contactHistory,
      'service_type': serviceTypes.na,
      'service_profile_id': profileId,
      'service_generator_id': generatorId,
      'service_history_id': historyId,
    };
    setIsLoadingEntry(true);
    inventoryServices.fetchServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _serviceHistory = response['data'];
        let _careContact = _serviceHistory['careContact'];
        prepareCareContactEntryDetails(_careContact, profileId, generatorId);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => {
        setIsLoadingEntry(false);
      })
  }

  const prepareCareContactEntryDetails = (careContact, profileId, generatorId) => {
    if (!careContact) {
      return;
    }

    setContactDate(new Date(careContact['recordedDatetime']));
    setCallType(careContact['callType']);
    setContactType(careContact['contactType']);
    setActualContactName(careContact['contactFullName']);
    setActualContactNo(careContact['contactNo']);
    setDiscussionDetails(careContact['description']);
    setSatisfaction(careContact['satisfaction']);

    if (careContact['followupServiceHistoryId'] !== null) {
      let _followupServiceHistoryId = careContact['followupServiceHistoryId'];
      let _followupServiceType = careContact['followupServiceHistoryType'];

      setFollowupEntryId(_followupServiceHistoryId);
      setFollowupEntryType(_followupServiceType);
      fetchFollowupServiceHistoryEntry(_followupServiceHistoryId, _followupServiceType,
        profileId, generatorId);
    }

    setStatus(careContact['status']);
    setRecordedBy(careContact['recordedBy']);
  }

  const fetchFollowupServiceHistoryEntry = (followupServiceHistoryId, followupServiceHistoryType,
                                            profileId, generatorId) => {
    let payload = {
      'employee_id': auth.getUserId()['eid'],
      'history_type': serviceHistoryTypes.serviceHistory,
      'service_type': followupServiceHistoryType,
      'service_profile_id': profileId,
      'service_generator_id': generatorId,
      'service_history_id': followupServiceHistoryId,
    };
    setIsLoadingFollowupEntry(true);
    inventoryServices.fetchServiceGeneratorJobEntry(payload)
      .then((response) => {
        let _serviceHistory = response['data'];
        prepareFollowupServiceHistoryEntry(_serviceHistory);
      })
      .finally(() => {
        setIsLoadingFollowupEntry(false);
      });
  }

  const prepareFollowupServiceHistoryEntry = (followupServiceHistory) => {
    if (!followupServiceHistory) {
      setFollowupEntryServiceDate(new Date());
      setFollowupEntryServicedBy(null);
      return;
    }

    setFollowupEntryServiceDate(new Date(followupServiceHistory['servicedDatetime']));
    setFollowupEntryServicedBy(followupServiceHistory['servicedBy']);
  }

  const getWarrantySpecifications = () => {
    let specs = [];
    if (warrantyHours) {
      specs.push(`${warrantyHours} hours`);
    }
    if (warrantyMonths) {
      let label = "month";
      if (warrantyMonths > 1) {
        label += "s";
      }
      specs.push(`${warrantyMonths} ${label}`);
    }
    return specs.join(", ");
  }

  const getContactTypeDisplay = () => {
    let display = contactTypes[contactType];
    if (contactType === contactTypes.na) {
      return "";
    }
    else {
      return ` - ${display}`;
    }
  }
  //#endregion

  //#region Control handlers
  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onGeneratorSerialClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'customerId': customerId,
          'profileId': serviceProfileId,
          'generatorId': serviceGeneratorId,
        }
      })
    }, 200);
  }

  const onViewAssociatedEntryClicked = (ev) => {
    if (followupEntryId) {
      switch (followupEntryType) {
        case serviceTypes.tnc:
          return navigateToServiceHistoryEntry(routes.testingAndCommission.url, followupEntryId);
        case serviceTypes.pm:
          return navigateToServiceHistoryEntry(routes.preventiveMaintenance.url, followupEntryId);
        case serviceTypes.regular:
          return navigateToServiceHistoryEntry(routes.regularService.url, followupEntryId);
        case serviceTypes.repair:
          return navigateToServiceHistoryEntry(routes.repairService.url, followupEntryId);
        case serviceTypes.emergency:
          return navigateToServiceHistoryEntry(routes.emergencyBreakdown.url, followupEntryId);
        case serviceTypes.inspection:
          return navigateToServiceHistoryEntry(routes.inspection.url, followupEntryId);
        default:
          break;
      }
    }
  }

  const navigateToServiceHistoryEntry = (url, serviceHistoryId) => {
    setTimeout(() => {
      navigate(url, {
        state: {
          'serviceProfileId': serviceProfileId,
          'serviceGeneratorId': serviceGeneratorId,
          'serviceHistoryId': serviceHistoryId,
        }
      });
    }, 200);
  }

  const onContactDateChanged = (date) => {
    setContactDate(date);
    setFormErrors({ ...formErrors, contactDate: [] });
  }

  const isFormDisabled = () => {
    return isLoadingGenerator || isLoadingEntry || isSubmitting;
  }

  const onReturnClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const isFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      contactDate: [],
    }

    if (!contactDate) {
      _hasErrors = true;
      _formErrors.contactDate.push("Contact date is required.");
    }

    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const prepareFormPayload = (isDraft) => {
    let employeeId = auth.getUserId()['eid'];
    let json = {
      'id': null,
      'serviceHistoryId': null,
      'serviceGeneratorId': serviceGeneratorId,
      'contactDatetime': contactDate,
      'callType': callType,
      'contactType': contactType,
      'contactFullName': actualContactName,
      'contactNo': actualContactNo,
      'discussionDetails': discussionDetails,
      'satisfaction': satisfaction,
      'isDraft': isDraft,
      'recordedById': employeeId,
    }
    if (careContactId) {
      // Edit mode, potentially
      json['id'] = careContactId;
    }
    if (serviceHistoryId) {
      // Linked service history entry mode
      json['serviceHistoryId'] = serviceHistoryId;
    }
    if (followupEntryId) {
      // Follow-up service history entry link
      json['followupServiceHistoryId'] = followupEntryId;
      json['followupServiceHistoryType'] = followupEntryType;
    }
    return json;
  }

  const onSubmitClicked = (ev, isDraft) => {
    let isFormValid = isFormFieldsValid();
    if (!isFormValid) {
      setAlertMessage("Please fix the form errors before submitting again.");
      setAlertType(alertTypes.error);
      setIsAlertVisible(true);
      setHasErrors(true);
      return;
    }
    setIsAlertVisible(false);
    setHasErrors(false);

    setIsSubmitting(true);
    if (isDraft) {
      setIsSubmittingForReview(false);
      setIsSubmittingDraft(true);
    }
    else {
      setIsSubmittingForReview(true);
      setIsSubmittingDraft(false);
    }

    let payload = {
      ...prepareFormPayload(isDraft),
    };

    customerServices.submitCareHistoryContact(payload)
      .then((response) => {
        let _responseData = response['data'];
        setAlertType(alertTypes.info);
        setAlertMessage(_responseData['message']);
        setIsAlertVisible(true);

        let _careContact = _responseData['careContact'];
        setCareContactId(_careContact['id']);
        setServiceHistoryId(_careContact['serviceHistoryId']);
        setStatus(_careContact['status']);
      })
      .catch((error) => {
        let _errorResponse = error['response'];
        setAlertType(alertTypes.error);
        if (_errorResponse['status'] === 500) {
          setAlertMessage("Something went wrong during the server postback. Please contact the administrator.");
        }
        else {
          setAlertMessage(_errorResponse['data']['message']);
        }
        setIsAlertVisible(true);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsSubmittingForReview(false);
        setIsSubmittingDraft(false);
      });
  }
  //#endregion

  //#region Utilities
  const getContactDateClassName = () => {
    let className = "form-control";
    if (contactDate) className += " has-autocompleted-value";
    return className;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onGeneratorSerialClicked}>
                  {generatorSerial}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.contactLogEntry.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>[{gensetModel}] {generatorSerial}{getContactTypeDisplay()}</h1>
          </div>

          <div className={"form-section"}>
            <h2>Customer Details</h2>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"customerName"}>
                  Customer Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Company:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Business Type:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Address:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{customerName}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={companyName} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{businessTypeName}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <NullBlankValue value={address} />
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactName"}>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactNo"}>
                  Contact No:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactNo"}>
                  Contact NRC:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactNo"}>
                  Contact Designation:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>{contactName}</ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>{contactNo}</ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={nrc} />
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  <NullBlankValue value={designation} />
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Contact Details</h2>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"customerName"}>
                  Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"location"}>
                  Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Location Address:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Region:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{locationContactName}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{locationContactNo}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-field"}>
                      <div className={"location-name"} title={"Location name"}>{serviceGenerator['serviceLocation']['name']}</div>
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div className={"location-address"} title={"Location address"}>{serviceGenerator['serviceLocation']['address']}</div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  {serviceGenerator && serviceGenerator['serviceLocation'] &&
                    <div title={"Designated region (City / Township)"}>
                      {getRegionDisplay(serviceGenerator['serviceLocation']['city'], serviceGenerator['serviceLocation']['township'])}
                    </div>
                  }
                </ReadonlyField>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactName"}>
                  Installation Date:
                </label>
              </div>

              <div className={"form-label-r"}>
              <label htmlFor={"contactNo"}>
                  Warranty Specification:
                </label>
              </div>

              <div className={"form-label-r"}>
                <label htmlFor={"contactNo"}>
                  Warranty Status:
                </label>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {serviceGenerator &&
                    <Moment date={serviceGenerator['installationDate']} format={formatters.datetimeShort} />
                  }
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {getWarrantySpecifications()}
                </ReadonlyField>
              </div>

              <div className={"form-input-r"}>
                <ReadonlyField>
                  {warrantyStatus}
                </ReadonlyField>
              </div>
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Generator {followupEntryId && "Service "}Details</h2>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label>
                  Generator Serial:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Genset Model:
                </label>
              </div>

              <div className={"form-label"}>
                <label>
                  Generator:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{generatorSerial}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{gensetModel}</ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>{refs.inventory.generatorManufacturers[manufacturer]}</ReadonlyField>
              </div>

              {followupEntryId &&
                <div className={"form-label-r"}>
                  <label>
                    Serviced Date:
                  </label>
                </div>
              }

              {followupEntryId &&
                <div className={"form-label-r"}>
                  <label>
                    Service Type:
                  </label>
                </div>
              }

              {followupEntryId &&
                <div className={"form-label-r"}>
                  <label>
                    Serviced By:
                  </label>
                </div>
              }

              {followupEntryId &&
                <div className={"form-input-r"}>
                    <ReadonlyField>
                      <Moment date={followupEntryServiceDate} format={formatters.datetimeShort} />
                    </ReadonlyField>
                </div>
              }

              {followupEntryId &&
                <div className={"form-input-r"}>
                  <ReadonlyField>
                    {serviceTypes[followupEntryType]}
                  </ReadonlyField>
                </div>
              }

              {followupEntryId &&
                <div className={"form-input-r"}>
                  <ReadonlyField>
                    <ActorNameRankDisplay employee={followupEntryServicedBy} />
                  </ReadonlyField>
                </div>
              }

            </div>
          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isLoading || isSubmitting}
                      onClick={onGeneratorSerialClicked}>
                <i className={"fa-solid fa-arrow-left"}></i>
                Return to Generator
              </button>

              {followupEntryId &&
                <button type={"button"} className={"btn btn-secondary"} disabled={isLoading || isSubmitting}
                        onClick={onViewAssociatedEntryClicked}>
                  <i className={"fa-solid fa-link"}></i>
                  View associated service entry
                </button>
              }
            </div>
          </div>

          <div className={"form-section"}>
            <h2>Care Contact Log</h2>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Date:<span className="error-message">*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Call Type:<span className="error-message">*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Contact Type:<span className="error-message">*</span>
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Actual Contact Name:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Actual Contact No:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Discussion/Feedback:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"contactType"}>
                  Satisfaction:
                </label>
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <Moment date={contactDate} format={formatters.datetimeShort} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <DatePicker id={"contactDate"} className={getContactDateClassName()} placeholderText={"Type a valid date or click to choose"}
                              dateFormat={formatters.datetimePickerShort} maxDate={new Date()} minDate={null}
                              required={true} todayButton={"Today"} showWeekNumbers
                              disabled={isLoading || isSubmitting} autoComplete={"off"}
                              selected={contactDate} onChange={onContactDateChanged} />
                }
                <FieldErrorMessages visible={hasErrors} messages={formErrors['contactDate']} />
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    {callTypes[callType]}
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <>
                    <label className={"option-field"}>
                      <input type={"radio"} id={"contactType"} name={"contactType"} disabled={isLoading || isSubmitting}
                             value={callTypes.outbound} onChange={(ev) => setCallType(parseInt(ev.target.value))}
                             checked={callType === callTypes.outbound} />
                      {callTypes[1]}
                    </label>
                    <label className={"option-field"}>
                      <input type={"radio"} id={"contactType"} name={"contactType"} disabled={isLoading || isSubmitting}
                             value={callTypes.inbound} onChange={(ev) => setCallType(parseInt(ev.target.value))}
                             checked={callType === callTypes.inbound} />
                      {callTypes[2]}
                    </label>
                  </>
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    {contactTypes[contactType]}
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <select id={"contactType"} className={"form-control form-select"} disabled={isLoading || isSubmitting}
                          value={contactType} onChange={(ev) => setContactType(parseInt(ev.target.value))}>
                    <option value={contactTypes.na}>{contactTypes[0]}</option>
                    <option value={contactTypes.regularCall}>{contactTypes[1]}</option>
                    <option value={contactTypes.postServiceCall}>{contactTypes[2]}</option>
                    <option value={contactTypes.inquiry}>{contactTypes[3]}</option>
                    <option value={contactTypes.complaint}>{contactTypes[4]}</option>
                  </select>
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <NullBlankValue value={actualContactName} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <input type={"text"} id={"actualContactName"} className={"form-control"}
                         maxLength={100} disabled={isLoading || isSubmitting}
                         value={actualContactName} onChange={(ev) => setActualContactName(ev.target.value)} />
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <NullBlankValue value={actualContactNo} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <input type={"text"} id={"actualContactName"} className={"form-control"}
                         maxLength={50} disabled={isLoading || isSubmitting}
                         value={actualContactNo} onChange={(ev) => setActualContactNo(ev.target.value)} />
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    <NullBlankValue value={discussionDetails} />
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <textarea id={"discussionDetails"} className={"form-control"} rows={7} disabled={isLoading || isSubmitting}
                            value={discussionDetails} onChange={(ev) => setDiscussionDetails(ev.target.value)}
                            maxLength={500}>
                  </textarea>
                }
              </div>

              <div className={"form-input"}>
                {isFormReadonly &&
                  <ReadonlyField>
                    {satisfactions[satisfaction]}
                  </ReadonlyField>
                }
                {!isFormReadonly &&
                  <select id={"contactType"} className={"form-control form-select"} disabled={isLoading || isSubmitting}
                          value={satisfaction} onChange={(ev) => setSatisfaction(parseInt(ev.target.value))}>
                    <option value={satisfactions.na}>{satisfactions[0]}</option>
                    <option value={satisfactions.satisfied}>{satisfactions[1]}</option>
                    <option value={satisfactions.dissatisfied}>{satisfactions[3]}</option>
                    <option value={satisfactions.unreachable}>{satisfactions[4]}</option>
                  </select>
                }
              </div>
            </div>

            <h3>Form Signatures</h3>

            <div className={"entry-form contact-log-entry-form"}>
              <div className={"form-label"}>
                <label htmlFor={"status"}>
                  Status:
                </label>
              </div>

              <div className={"form-label"}>
                <label htmlFor={"loggedBy"}>
                  Logged by:
                </label>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <JobStatusBadge status={status} />
                </ReadonlyField>
              </div>

              <div className={"form-input"}>
                <ReadonlyField>
                  <ActorNameRankDisplay employee={recordedBy} />
                </ReadonlyField>
              </div>
            </div>

          </div>

          <div className={"form-button-controls"}>
            <div className={"left-side"}>
              <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, false)}>
                {isSubmittingForReview && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingForReview && <i className="fa-solid fa-check"></i>}
                Save
              </button>

              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting || isFormReadonly}
                      onClick={(ev) => onSubmitClicked(ev, true)}>
                {isSubmittingDraft && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isSubmittingDraft && <i className="fa-solid fa-floppy-disk"></i>}
                Save Draft
              </button>
            </div>
          </div>

          <PageAlert visible={isAlertVisible} type={alertType}
                     onDismissButtonClicked={(ev) => setIsAlertVisible(false)}>
            {alertMessage}
          </PageAlert>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
