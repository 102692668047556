import React from "react";
import { useState } from "react";

import { refs } from "../../repos/constants";
import { inventoryServices } from "../../repos/apiServices";

import { NullBlankValue } from "./NullBlankField";
import { NullBlankFloatValue } from "./NullBlankField";
import { ReadonlyField } from "../shared/ReadonlyField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";

import "../shared/DataTable.css";

const generatorType = refs.inventory.generatorType;

export const ServiceEntryTestResultsList = ({
                                              isFormReadonly, isFormDisabled,
                                              serviceHistoryId, testChecklistId,
                                              groupType, setGroupType,
                                              runningHours, setRunningHours,
                                              testResults, addTestResults, onTestResultItemDeleted,
                                              isLoading,
                                            }) => {

  //#region Test Results row entry form fields and related stuffs
  const [time, setTime] = useState("");
  const [load, setLoad] = useState("");
  const [ambientTemperature, setAmbientTemperature] = useState("");
  const [waterTemperature, setWaterTemperature] = useState("");
  const [oilPressure, setOilPressure] = useState("");
  const [currentR, setCurrentR] = useState("");
  const [currentY, setCurrentY] = useState("");
  const [currentB, setCurrentB] = useState("");
  const [voltsPh1, setVoltsPh1] = useState("");
  const [voltsPh2, setVoltsPh2] = useState("");
  const [voltsPh3, setVoltsPh3] = useState("");
  const [frequency, setFrequency] = useState("");
  const [power, setPower] = useState("");
  const [powerFactor, setPowerFactor] = useState("");

  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isRemovingItem, setIsRemovingItem] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isFieldBlank = (field) => {
    return field && field.trim() === "";
  }

  const isFormValid = () => {
    let isValid = true;
    if (isFieldBlank(time) && isFieldBlank(load) && isFieldBlank(ambientTemperature) && isFieldBlank(waterTemperature) &&
        isFieldBlank(oilPressure) && isFieldBlank(currentR) && isFieldBlank(currentY) && isFieldBlank(currentB) &&
        isFieldBlank(voltsPh1) && isFieldBlank(voltsPh2) && isFieldBlank(voltsPh3) && isFieldBlank(frequency) &&
        isFieldBlank(power) && isFieldBlank(powerFactor)) {
      isValid = false;
    }
    return isValid;
  }

  const resetForm = () => {
    setTime("");
    setLoad("");
    setAmbientTemperature("");
    setWaterTemperature("");
    setOilPressure("");
    setCurrentR("");
    setCurrentY("");
    setCurrentB("");
    setVoltsPh1("");
    setVoltsPh2("");
    setVoltsPh3("");
    setFrequency("");
    setPower("");
    setPowerFactor("");
  }

  const preparePayload = () => {
    return {
      'service_history_id': serviceHistoryId,
      'test_checklist_id': testChecklistId,
      'time': time,
      'load': load,
      'ambient_temperature': ambientTemperature,
      'water_temperature': waterTemperature,
      'oil_pressure': oilPressure,
      'current_r': currentR,
      'current_y': currentY,
      'current_b': currentB,
      'voltage_ph1': voltsPh1,
      'voltage_ph2': voltsPh2,
      'voltage_ph3': voltsPh3,
      'frequency': frequency,
      'power': power,
      'power_factor': powerFactor,
    }
  }

  const onRowItemAddClicked = () => {
    if (!isFormValid()) {
      return;
    }

    setHasErrors(false);
    setErrorMessage("");

    let payload = preparePayload();
    setIsAddingItem(true);
    inventoryServices.submitServiceGeneratorTestChecklistDetails(payload)
      .then((response) => {
        let testChecklistDetails = response['data'];
        addTestResults(testChecklistDetails);
        resetForm();
      })
      .catch((error) => {
        const _errorResponse = error['response']['data'];
        setErrorMessage(_errorResponse['message']);
        setHasErrors(true);
      })
      .finally(() => {
        setIsAddingItem(false);
      })
  }

  const onRowItemRemoveClicked = (testChecklistDetailsId) => {
    setIsRemovingItem(true);
    inventoryServices.deleteServiceGeneratorTestChecklistDetails(testChecklistId, testChecklistDetailsId)
      .then((response) => {
        let responseData = response['data'];
        if (onTestResultItemDeleted) {
          onTestResultItemDeleted(responseData['testChecklistId'], responseData['testChecklistDetailsId']);
        }
      })
      .finally(() => {
        setIsRemovingItem(false);
      })
  }
  //#endregion

  const onRunningHoursChanged = (ev) => {
    let _runningHours = ev.target.value;
    setRunningHours(_runningHours);
  }

  //#region Render
  return (
    <>
      <div className={"form-section"}>
        <div className={"entry-form"} style={{ gridAutoFlow: "column" }}>
          <div className={"form-label"}>
            <label>
              Group Type:
            </label>
          </div>

          <div className={"form-label"}>
            <label>
              Running Hours:
            </label>
          </div>

          <div className={"form-input"}>
            {isFormReadonly && <ReadonlyField>{generatorType[groupType]}</ReadonlyField>}
            {!isFormReadonly &&
              <>
                <label className={"option-field"}>
                  <input type={"radio"} id={"groupTypeOpen"} name={"groupTypeOpen"} disabled={isLoading}
                         value={generatorType.open} checked={groupType === generatorType.open}
                         onChange={(ev) => setGroupType(generatorType.open)} />
                  {generatorType[1]}
                </label>
                <label className={"option-field"}>
                  <input type={"radio"} id={"groupTypeCanopy"} name={"groupTypeCanopy"} disabled={isLoading}
                         value={generatorType.canopy} checked={groupType === generatorType.canopy}
                         onChange={(ev) => setGroupType(generatorType.canopy)} />
                  {generatorType[2]}
                </label>
              </>
            }
          </div>

          <div className={"form-input"}>
            {isFormReadonly && <ReadonlyField><NullBlankValue value={runningHours} /></ReadonlyField>}
            {!isFormReadonly &&
              <input type={"text"} id={"runningHours"} className={"form-control"} autoComplete={"off"}
                     disabled={isLoading} value={runningHours} onChange={onRunningHoursChanged} />
            }
          </div>
        </div>
      </div>

      <div className={"form-section"}>
        <div className={"data-table"}>
          <table>
            <TestResultsTableHeading />

            <tbody>
            {isLoading && <TableLoadingIndicator colspan={16} />}

            {!isLoading && testResults && testResults.length > 0 &&
              testResults.map((testResult, index) =>
                <TestResultsTableRow key={testResult['id']} testResult={testResult} index={index}
                                     onRowClicked={null} onRowItemRemoveClicked={onRowItemRemoveClicked}
                                     isReadonly={isFormReadonly} isRemoving={isRemovingItem} />
              )
            }

            {!isLoading && (!testResults || (testResults.length === 0)) &&
              <TableEmptyRow colSpan={16} />
            }

            {!isFormReadonly &&
              <TestResultsTableRowForm time={time} setTime={setTime}
                                       load={load} setLoad={setLoad}
                                       ambientTemperature={ambientTemperature} setAmbientTemperature={setAmbientTemperature}
                                       waterTemperature={waterTemperature} setWaterTemperature={setWaterTemperature}
                                       oilPressure={oilPressure} setOilPressure={setOilPressure}
                                       currentR={currentR} setCurrentR={setCurrentR}
                                       currentY={currentY} setCurrentY={setCurrentY}
                                       currentB={currentB} setCurrentB={setCurrentB}
                                       voltsPh1={voltsPh1} setVoltsPh1={setVoltsPh1}
                                       voltsPh2={voltsPh2} setVoltsPh2={setVoltsPh2}
                                       voltsPh3={voltsPh3} setVoltsPh3={setVoltsPh3}
                                       frequency={frequency} setFrequency={setFrequency}
                                       power={power} setPower={setPower}
                                       powerFactor={powerFactor} setPowerFactor={setPowerFactor}
                                       onRowItemAddClicked={onRowItemAddClicked}
                                       isAdding={isAddingItem} isRemoving={isRemovingItem}
                                       hasErrors={hasErrors} errorMessage={errorMessage} />
            }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
  //#endregion
}

const TestResultsTableHeading = () => {
  return (
    <thead>
    <tr>
      <th scope={"col"} style={{ minWidth: 14 }}></th>
      <th scope={"col"}>Time</th>
      <th scope={"col"}>Load</th>
      <th scope={"col"}>Ambient Temp.</th>
      <th scope={"col"}>Water Temp.</th>
      <th scope={"col"}>Oil Press.</th>
      <th scope={"col"} colSpan={3}>Current (A)</th>
      <th scope={"col"} colSpan={3}>Volts</th>
      <th scope={"col"}>Frequency</th>
      <th scope={"col"}>Power</th>
      <th scope={"col"}>Power Factor</th>
      <th scope={"col"}></th>
    </tr>
    <tr>
      <th scope={"col"}></th>
      <th scope={"col"}>(Min)</th>
      <th scope={"col"}>(%)</th>
      <th scope={"col"}>(C)</th>
      <th scope={"col"}>(C)</th>
      <th scope={"col"}>(Bar)</th>
      <th scope={"col"}>R (U)</th>
      <th scope={"col"}>Y (V)</th>
      <th scope={"col"}>B (W)</th>
      <th scope={"col"}>PH1 (V)</th>
      <th scope={"col"}>PH2 (V)</th>
      <th scope={"col"}>PH3 (V)</th>
      <th scope={"col"}>(Hz)</th>
      <th scope={"col"}>(KW)</th>
      <th scope={"col"}>(cos &empty;)</th>
      <th scope={"col"}></th>
    </tr>
    </thead>
  )
}

const TestResultsTableRow = ({
                               testResult, index,
                               onRowClicked, onRowItemRemoveClicked,
                               isReadonly, isRemoving,
                             }) => {
  return (
    <tr>
      <td></td>
      <td>
        <NullBlankFloatValue value={testResult['time']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['load']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['ambientTemperature']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['waterTemperature']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['oilPressure']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['currentR']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['currentY']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['currentB']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['voltagePh1']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['voltagePh2']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['voltagePh3']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['frequency']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['power']} />
      </td>
      <td>
        <NullBlankFloatValue value={testResult['powerFactor']} />
      </td>
      {!isReadonly &&
        <td className={"row-controls"} style={{ minWidth: '82px' }}>
          <button type={"button"} className={"btn btn-light btn-sm danger-button"}
                  title={"Remove item"} disabled={isRemoving}
                  onClick={(ev) => onRowItemRemoveClicked(testResult['id'])}>
            <i className="fa-solid fa-circle-minus"></i>
          </button>
        </td>
      }
      {isReadonly &&
        <td></td>
      }
    </tr>
  )
}

const TestResultsTableRowForm = ({
                                   time, setTime,
                                   load, setLoad,
                                   ambientTemperature, setAmbientTemperature,
                                   waterTemperature, setWaterTemperature,
                                   oilPressure, setOilPressure,
                                   currentR, setCurrentR,
                                   currentY, setCurrentY,
                                   currentB, setCurrentB,
                                   voltsPh1, setVoltsPh1,
                                   voltsPh2, setVoltsPh2,
                                   voltsPh3, setVoltsPh3,
                                   frequency, setFrequency,
                                   power, setPower,
                                   powerFactor, setPowerFactor,
                                   onRowItemAddClicked,
                                   isAdding, isRemoving,
                                   hasErrors, errorMessage,
                                 }) => {

  return (
    <>
      <tr className={"solid-background row-form"}>
        <td className={"index-col"}>
          <span><b>+</b></span>
        </td>
        <td>
          <input type={"text"} style={{ width: '72px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Time"} value={time} onChange={(ev) => setTime(ev.target.value)} />
        </td>
        <td>
          <input type={"text"} style={{ width: '72px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Load"} value={load} onChange={(ev) => setLoad(ev.target.value)} />
        </td>
        <td>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Temp."} value={ambientTemperature} onChange={(ev) => setAmbientTemperature(ev.target.value)} />
        </td>
        <td>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Temp."} value={waterTemperature} onChange={(ev) => setWaterTemperature(ev.target.value)} />
        </td>
        <td>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Pres."} value={oilPressure} onChange={(ev) => setOilPressure(ev.target.value)} />
        </td>
        <td colSpan={3}>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"R"} value={currentR} onChange={(ev) => setCurrentR(ev.target.value)} />
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Y"} value={currentY} onChange={(ev) => setCurrentY(ev.target.value)} />
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"B"} value={currentB} onChange={(ev) => setCurrentB(ev.target.value)} />
        </td>
        <td colSpan={3}>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"PH1"} value={voltsPh1} onChange={(ev) => setVoltsPh1(ev.target.value)} />
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"PH2"} value={voltsPh2} onChange={(ev) => setVoltsPh2(ev.target.value)} />
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"PH3"} value={voltsPh3} onChange={(ev) => setVoltsPh3(ev.target.value)} />
        </td>
        <td>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Freq."} value={frequency} onChange={(ev) => setFrequency(ev.target.value)} />
        </td>
        <td>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Pow."} value={power} onChange={(ev) => setPower(ev.target.value)} />
        </td>
        <td>
          <input type={"text"} style={{ width: '128px' }} className={"form-control"} autoComplete={"off"}
                 placeholder={"Pow. Fact."} value={powerFactor} onChange={(ev) => setPowerFactor(ev.target.value)} />
        </td>
        <td></td>
      </tr>
      <tr className={"solid-background row-form"}>
        <td style={{ paddingTop: '0' }}></td>
        <td style={{ paddingTop: '0' }} colSpan={16}>
          {hasErrors &&
            <div style={{ marginBottom: '8px' }}>
                <FieldErrorMessage visible={hasErrors} message={errorMessage} />
            </div>
          }
          <button type={"button"} className={"btn btn-secondary"} style={{ minWidth: '120px' }}
                  disabled={isAdding || isRemoving} onClick={onRowItemAddClicked}>
            {isAdding && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isAdding && <i className={"fa-solid fa-add"} />}
            Add
          </button>
        </td>
      </tr>
    </>
  )
}
