import React from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";

import { ModalContainer } from "../shared/ModalContainer";
import { AutoCompleteNestedResults} from "../shared/AutoCompleteResults";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ErrorMessage } from "../sales/FormMessages";

import "./ServiceProfilePage.css";


export const ServiceLocationModal = ({
                                          serviceProfileId, selectedLocationId = null,
                                          locationName, setLocationName,
                                          contactFullName, setContactFullName,
                                          contactNo, setContactNo,
                                          address, setAddress,
                                          cityId, setCityId, city,
                                          townshipId, setTownshipId, township,
                                          onRegionSuggestionCityClicked,
                                          onRegionSuggestionTownshipClicked,
                                          regionTerm, onRegionTermChanged,
                                          regionSuggestions, setRegionSuggestions,
                                          resetLocationFormFields,
                                          isOpen, onRequestClose,
                                          onLocationSubmitted,
                                        }) => {
  //#region States
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasFormAlert, setHasFormAlert] = useState(false);
  const [formAlertMessage, setFormAlertMessage] = useState("You probably did something wrong.");
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const auth = useAuth();
  //#endregion

  //#region Control handlers
  const onRequestModalClose = () => {
    resetFormFields();
    if (onRequestClose) {
      onRequestClose();
    }
  }

  const resetFormFields = () => {
    if (resetLocationFormFields) {
      resetLocationFormFields();
    }
    setHasErrors(false);
    setFormErrors({});
  }

  const isFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      locationName: [],
      contactFullName: [],
      contactNo: [],
      region: [],
    };
    if (!locationName || locationName.trim() === '') {
      _hasErrors = true;
      _formErrors.locationName = ["Location name is required."];
    }
    if (!contactFullName || contactFullName.trim() === '') {
      _hasErrors = true;
      _formErrors.contactFullName = ["Contact name is required."];
    }
    if (!contactNo || contactNo.trim() === '') {
      _hasErrors = true;
      _formErrors.contactNo = ["Contact number is required."];
    }
    if (!cityId) {
      _hasErrors = true;
      _formErrors.region = ["Please search and choose the region properly."];
    }

    setHasErrors(_hasErrors);
    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const preparePayload = () => {
    let employeeId = auth.getUserId()['eid'];
    return {
      'employee_id': employeeId,
      'service_profile_id': serviceProfileId,
      'location_id': selectedLocationId ?? null,
      'location_name': locationName,
      'contact_fullname': contactFullName,
      'contact_no': contactNo,
      'address': address,
      'city_id': cityId,
      'township_id': townshipId ?? null,
    }
  }

  const onSaveClicked = (ev) => {
    let isValid = isFormFieldsValid();
    if (!isValid) {
      return;
    }

    let payload = preparePayload();
    setHasErrors(false);
    setIsSubmitting(true);
    inventoryServices.submitServiceProfileLocation(payload)
      .then((response) => {
        const responseData = response['data'];
        const serviceLocation = responseData['serviceLocation'];

        resetFormFields();
        onLocationSubmitted();
        onRequestModalClose();
      })
      .catch((error) => {
        const errorResponse = error['response'];
        // TODO(yemon):
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }
  //#endregion

  //#region Control handlers; Region auto-complete field
  const getRegionClassName = () => {
    let className = "form-control";
    if (cityId || townshipId) className += " has-autocompleted-value";
    return className;
  }

  const getRegionStyle = () => {
    // NOTE(yemon): Refer to '.has-autocompleted-value' class definition in the Modals.css
    // For whatever stupid-ist reason, the class from the getRegionClassName() above is not
    // getting applied at all.
    if (cityId || township) {
      return {
        backgroundColor: "#d9eaff",
      };
    }
    else {
      return {};
    }
  }

  const expandRegionSuggestionResults = () => {
    setTimeout(() => {
      let fieldElement = document.getElementById('region');
      let suggestionsElement = document.getElementById('regionSuggestionResults');
      if (suggestionsElement) suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
    }, 100);
  }

  let _prevRegionTerm = '';
  let _regionTerm = '';
  const onRegionFieldChanged = (ev) => {
    _prevRegionTerm = _regionTerm.trim();
    _regionTerm = ev.target.value;

    let isRegionChanged = onRegionTermChanged(ev, _regionTerm, _prevRegionTerm);
    if (!isRegionChanged) return;

    refServices2.searchRegions(_regionTerm)
      .then((response) => {
        setRegionSuggestions(response['data']);
        expandRegionSuggestionResults();
      })
      .catch((_) => {
        setRegionSuggestions([]);
      });
  }

  const onRegionFieldBlurred = (ev) => {
    setTimeout(() => {
      setRegionSuggestions([]);
    }, 200);
  }

  const onRegionCityClicked = (ev, city) => {
    onRegionSuggestionCityClicked(ev, city);
    setRegionSuggestions([]);
  }

  const onRegionTownshipClicked = (ev, township) => {
    onRegionSuggestionTownshipClicked(ev, township);
    setRegionSuggestions([]);
  }
  //#endregion

  //#region Render
  return (
    <ModalContainer elementId={"new-portal-user-modal"}
                    isOpen={isOpen} onRequestClose={onRequestModalClose} isLoading={false}
                    height={624} title={"New Location"}
                    shortDescription={"Create a new location in which the service generators can be commissioned..."}>
      <form>
        {hasFormAlert &&
          <div className={"modal-form"}>
            <div className={"form-message"}>
              <ErrorMessage>
                {formAlertMessage}
              </ErrorMessage>
            </div>
          </div>
        }

        <div className={"modal-form"}>
          <div className={"form-label"}>
            <label htmlFor={"locationName"}>
              Location Name:<span className="error-message">*</span>
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"locationName"} name={"locationName"} className={"form-control"}
                   autoComplete={"off"} disabled={isSubmitting} maxLength={100} autoFocus={true}
                   value={locationName} onChange={(ev) => setLocationName(ev.target.value)} />
            <FieldErrorMessages visible={hasErrors} messages={formErrors["locationName"]} />
          </div>

          <div className={"form-label"}>
            <label htmlFor={"contactFullName"}>
              Contact Full Name:<span className="error-message">*</span>
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"contactFullName"} name={"contactFullName"} className={"form-control"}
                   autoComplete={"off"} disabled={isSubmitting} maxLength={100}
                   value={contactFullName} onChange={(ev) => setContactFullName(ev.target.value)} />
            <FieldErrorMessages visible={hasErrors} messages={formErrors["contactFullName"]} />
          </div>

          <div className={"form-label"}>
            <label htmlFor={"contactNo"}>
              Contact No:<span className="error-message">*</span>
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"contactNo"} name={"contactNo"} className={"form-control"}
                   autoComplete={"off"} disabled={isSubmitting} maxLength={100}
                   value={contactNo} onChange={(ev) => setContactNo(ev.target.value)} />
            <FieldErrorMessages visible={hasErrors} messages={formErrors["contactNo"]} />
          </div>

          <div className={"form-label"}>
            <label htmlFor={"address"}>
              Address:
            </label>
          </div>

          <div className={"form-input"}>
            <textarea id={"address"} className={"form-control"} rows={3} disabled={isSubmitting}
                      value={address} onChange={(ev) => setAddress(ev.target.value)}
                      maxLength={200}>
            </textarea>
          </div>

          <div className={"form-label"}>
            <label htmlFor={"region"}>
              Region:<span className="error-message">*</span>
            </label>
          </div>

          <div className={"form-input"}>
            <input type={"text"} id={"region"} name={"contactNo"}
                   className={"form-control"} style={getRegionStyle()}
                   autoComplete={"off"} disabled={isSubmitting} maxLength={100}
                   placeholder={"Type city or township to choose a region..."}
                   value={regionTerm} onChange={onRegionFieldChanged} onBlur={onRegionFieldBlurred} />
            {regionSuggestions && regionSuggestions.length > 0 &&
              <AutoCompleteNestedResults id={"regionSuggestionResults"} style={{ width: 400, maxHeight: 400 }}
                                         collection={regionSuggestions} itemDisplayField={"name"} onItemClicked={onRegionCityClicked}
                                         childCollectionField={"townships"} childItemDisplayField={"name"} onChildItemClicked={onRegionTownshipClicked}
              />
            }
            <FieldErrorMessages visible={hasErrors} messages={formErrors["region"]} />
          </div>
        </div>

        <div className={"modal-controls form-button-controls"}>
          <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                  onClick={onSaveClicked}>
            {isSubmitting && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
            Save
          </button>
        </div>

      </form>
    </ModalContainer>
  )
//#endregion
}
