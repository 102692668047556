import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";

import { inspectionFormMode } from "./InspectionFormContext";
import { refs } from "../../repos/constants";
import { roundFloat } from "../../repos/utilities";
import { ReadonlyField } from "../shared/ReadonlyField";

const fractions = 2;

export const ServiceEntryTestResults = ({
                                          formMode,
                                          l1n, onL1NChanged,
                                          l2n, onL2NChanged,
                                          l3n, onL3NChanged,
                                          l1l2, onL1L2Changed,
                                          l2l3, onL2L3Changed,
                                          l3l1, onL3L1Changed,
                                          frequency, onFrequencyChanged,
                                          avrType, onAVRTypeChanged,
                                          governorType, onGovernorTypeChanged,
                                          checkEngineOil, onCheckEngineOil,
                                          checkCoolant, onCheckCoolantChanged,
                                          checkDriveBeltTension, onCheckDriveBeltTensionChanged,
                                          checkBatteriesCondition, onCheckBatteriesConditionChanged,
                                          checkWiringConnection, onCheckWiringConnectionChanged,
                                          checkSwitchNCircuitBreaker, onCheckSwitchNCircuitBreakerChanged,
                                          checkOilCoolantFuelLeaks, onCheckOilCoolantFuelLeaksChanged,
                                          checkHosesPipesClamps, onCheckHosesPipesClamps,
                                          checkGensetHousekeeping, onCheckGensetHousekeepingChanged,
                                          checkSensors, onCheckSensorsChanged,
                                          checkTightenHoldingDownBolts, onCheckTightenHoldingDownBoltsChanged,
                                          checkFuelDrainPlugSeal, onCheckFuelDrainPlugSealChanged,
                                          checkAKSAToolsKit, onCheckAKSAToolsKitChanged,
                                          checkDrawingManualBooks, onCheckDrawingManualBooks,
                                          checkRadiatorHeadCover, onCheckRadiatorHeadCoverChanged,
                                          checkStickers, onCheckStickersChanged,
                                          checkDoorsPanelsKeys, onCheckDoorsPanelsKeysChanged,
                                          isLoading, isEditable, isSubmitting,
                                        }) => {
  //#region States
  //#endregion

  //#region Effects
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Render
  return (
    <div className={"form-section"}>
      <h2>Test Results</h2>

      <div className={"entry-form arrival-inspection-form test-results-section"}>
        {formMode === inspectionFormMode.arrivalInspection &&
          <>
            <div className={"form-label"}>
              <label htmlFor={"l1n"}>
                L1-N:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"l2n"}>
                L2-N:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"l3n"}>
                L3-N:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"l1l2"}>
                L1-L2:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"l2l3"}>
                L2-L3:
              </label>
            </div>

            <div className={"form-label"}>
              <label htmlFor={"l3l1"}>
                L3-L1:
              </label>
            </div>
          </>
        }

        {formMode === inspectionFormMode.arrivalInspection &&
          <>
            <div className={"form-input"}>
              {!isEditable && <ReadonlyField>{l1n === '' ? '-' : roundFloat(l1n, fractions)} V</ReadonlyField>}
              {isEditable &&
                <>
                  <input type={"text"} id={"l1n"} className={"form-control"}
                         autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                         value={l1n} onChange={onL1NChanged} />
                  <div className={"form-field-message right-aligned"}>
                    V
                  </div>
                </>
              }
            </div>

            <div className={"form-input"}>
              {!isEditable && <ReadonlyField>{l2n === '' ? '-' : roundFloat(l2n, fractions)} V</ReadonlyField>}
              {isEditable &&
                <>
                  <input type={"text"} id={"l2n"} className={"form-control"}
                         autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                         value={l2n} onChange={onL2NChanged} />
                  <div className={"form-field-message right-aligned"}>
                    V
                  </div>
                </>
              }
            </div>

            <div className={"form-input"}>
              {!isEditable && <ReadonlyField>{l3n === '' ? '-' : roundFloat(l3n, fractions)} V</ReadonlyField>}
              {isEditable &&
                <>
                  <input type={"text"} id={"l3n"} className={"form-control"}
                         autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                         value={l3n} onChange={onL3NChanged} />
                  <div className={"form-field-message right-aligned"}>
                    V
                  </div>
                </>
              }
            </div>

            <div className={"form-input"}>
              {!isEditable && <ReadonlyField>{l1l2 === '' ? '-' : roundFloat(l1l2, fractions)} V</ReadonlyField>}
              {isEditable &&
                <>
                  <input type={"text"} id={"l1l2"} className={"form-control"}
                         autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                         value={l1l2} onChange={onL1L2Changed} />
                  <div className={"form-field-message right-aligned"}>
                    V
                  </div>
                </>
              }
            </div>

            <div className={"form-input"}>
              {!isEditable && <ReadonlyField>{l2l3 === '' ? '-' : roundFloat(l2l3, fractions)} V</ReadonlyField>}
              {isEditable &&
                <>
                  <input type={"text"} id={"l2l3"} className={"form-control"}
                         autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                         value={l2l3} onChange={onL2L3Changed} />
                  <div className={"form-field-message right-aligned"}>
                    V
                  </div>
                </>
              }
            </div>

            <div className={"form-input"}>
              {!isEditable && <ReadonlyField>{l3l1 === '' ? '-' : roundFloat(l3l1, fractions)} V</ReadonlyField>}
              {isEditable &&
                <>
                  <input type={"text"} id={"l3l1"} className={"form-control"}
                         autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                         value={l3l1} onChange={onL3L1Changed} />
                  <div className={"form-field-message right-aligned"}>
                    V
                  </div>
                </>
              }
            </div>
          </>
        }

        {formMode === inspectionFormMode.arrivalInspection &&
          <>
            <div className={"form-label-r"}>
              <label htmlFor={"frequency"}>
                Frequency:
              </label>
            </div>

            <div className={"form-label-r"}>
              <label htmlFor={"avrType"}>
                AVR Type:
              </label>
            </div>

            <div className={"form-label-r"}>
              <label htmlFor={"governorType"}>
                Governor Type:
              </label>
            </div>
          </>
        }

        {formMode === inspectionFormMode.arrivalInspection &&
          <>
            <div className={"form-input-r"}>
              {!isEditable && <ReadonlyField>{frequency === '' ? '-' : roundFloat(frequency, fractions)} Hz</ReadonlyField>}
              {isEditable &&
                <>
                  <input type={"text"} id={"frequency"} className={"form-control"}
                         autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                         value={frequency} onChange={onFrequencyChanged} />
                  <div className={"form-field-message right-aligned"}>
                    Hz
                  </div>
                </>
              }
            </div>

            <div className={"form-input-r"}>
              {!isEditable && <ReadonlyField>{avrType === '' ? '-' : avrType}</ReadonlyField>}
              {isEditable &&
                <input type={"text"} id={"avrType"} className={"form-control"}
                       autoComplete={"off"} maxLength={30} disabled={isLoading || isSubmitting}
                       value={avrType} onChange={onAVRTypeChanged} />
              }
            </div>

            <div className={"form-input-r"}>
              {!isEditable && <ReadonlyField>{refs.inventory.governorType[governorType]}</ReadonlyField>}
              {isEditable &&
                <>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"governorType"} name={"governorType"} disabled={isLoading || isSubmitting}
                           value={refs.inventory.governorType.electronic}
                           checked={governorType === refs.inventory.governorType.electronic}
                           onChange={onGovernorTypeChanged} />
                    Electronic
                  </label>
                  <label className={"option-field"}>
                    <input type={"radio"} id={"governorType"} name={"governorType"} disabled={isLoading || isSubmitting}
                           value={refs.inventory.governorType.mechanical}
                           checked={governorType === refs.inventory.governorType.mechanical}
                           onChange={onGovernorTypeChanged} />
                    Mechanical
                  </label>
                </>
              }
            </div>
          </>
        }

      </div>

      <div className={"entry-form arrival-inspection-form test-results-section"}>
        <div className={"form-label"}>
          <label htmlFor={"checkEngineOilLevel"}>
            Check Engine Oil Level:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"checkCoolantLevel"}>
            Check Coolant Level
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"checkDriveBeltTension"}>
            Check Drive Belt Tension:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"checkBatteriesCondition"}>
            Check Batteries Condition:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"checkWiringConnection"}>
            Check Wiring Connection:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"checkSwitchAndCircuitBreaker"}>
            Check Switch & Circuit Breaker:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"checkOilCoolantAndFuelLeaks"}>
            Check Oil, Coolant & Fuel Leaks:
          </label>
        </div>

        <div className={"form-label"}>
          <label htmlFor={"checkAllHosesPipesAndClamps"}>
            Check All Hoses, Pipes & Clamps:
          </label>
        </div>

        {formMode === inspectionFormMode.predeliveryInspection &&
          <div className={"form-label"}>
            <label htmlFor={"checkGensetHousekeeping"}>
              Genset Housekeeping:
            </label>
          </div>
        }

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkEngineOil ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkEngineOilLevel"}>
              <input type={"checkbox"} id={"checkEngineOilLevel"} name={"checkEngineOilLevel"}
                     disabled={isLoading || isSubmitting}
                     checked={checkEngineOil} onChange={(ev) => onCheckEngineOil(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkCoolant ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkCoolantLevel"}>
              <input type={"checkbox"} id={"checkCoolantLevel"} name={"checkCoolantLevel"}
                     disabled={isLoading || isSubmitting}
                     checked={checkCoolant} onChange={(ev) => onCheckCoolantChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkDriveBeltTension ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkDriveBeltTension"}>
              <input type={"checkbox"} id={"checkDriveBeltTension"} name={"checkDriveBeltTension"}
                     disabled={isLoading || isSubmitting}
                     checked={checkDriveBeltTension} onChange={(ev) => onCheckDriveBeltTensionChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkBatteriesCondition ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkBatteriesCondition"}>
              <input type={"checkbox"} id={"checkBatteriesCondition"} name={"checkBatteriesCondition"}
                     disabled={isLoading || isSubmitting}
                     checked={checkBatteriesCondition} onChange={(ev) => onCheckBatteriesConditionChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkWiringConnection ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkWiringConnection"}>
              <input type={"checkbox"} id={"checkWiringConnection"} name={"checkWiringConnection"}
                     disabled={isLoading || isSubmitting}
                     checked={checkWiringConnection} onChange={(ev) => onCheckWiringConnectionChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkSwitchNCircuitBreaker ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkSwitchAndCircuitBreaker"}>
              <input type={"checkbox"} id={"checkSwitchAndCircuitBreaker"} name={"checkSwitchAndCircuitBreaker"}
                     disabled={isLoading || isSubmitting}
                     checked={checkSwitchNCircuitBreaker} onChange={(ev) => onCheckSwitchNCircuitBreakerChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkOilCoolantFuelLeaks ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkOilCoolantAndFuelLeaks"}>
              <input type={"checkbox"} id={"checkOilCoolantAndFuelLeaks"} name={"checkOilCoolantAndFuelLeaks"}
                     disabled={isLoading || isSubmitting}
                     checked={checkOilCoolantFuelLeaks} onChange={(ev) => onCheckOilCoolantFuelLeaksChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input"}>
          {!isEditable && <ReadonlyField>{checkHosesPipesClamps ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkAllHosesPipesAndClamps"}>
              <input type={"checkbox"} id={"checkAllHosesPipesAndClamps"} name={"checkAllHosesPipesAndClamps"}
                     disabled={isLoading || isSubmitting}
                     checked={checkHosesPipesClamps} onChange={(ev) => onCheckHosesPipesClamps(ev.target.checked)} />
            </label>
          }
        </div>

        {formMode === inspectionFormMode.predeliveryInspection &&
          <div className={"form-input"}>
            {!isEditable && <ReadonlyField>{checkGensetHousekeeping ? 'Yes' : 'No'}</ReadonlyField>}
            {isEditable &&
              <label htmlFor={"checkGensetHousekeeping"}>
                <input type={"checkbox"} id={"checkGensetHousekeeping"} name={"checkGensetHousekeeping"}
                       disabled={isLoading || isSubmitting}
                       checked={checkGensetHousekeeping} onChange={(ev) => onCheckGensetHousekeepingChanged(ev.target.checked)} />
              </label>
            }
          </div>
        }

        <div className={"form-label-r"}>
          <label htmlFor={"checkAllSensors"}>
            Check All Sensors:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"checkAllTightenHoldingDownBolts"}>
            Check All Tighten Holding Down Bolts:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"checkFuelDrainPlugSeal"}>
            Check Fuel Drain Plug Seal:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"checkAKSAToolsKit"}>
            Check AKSA Tools Kit:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"checkDrawingAndManualBooks"}>
            Check Drawing & Manual Books:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"checkRadiatorHeadCover"}>
            Check Radiator Head Cover:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"checkAllStickers"}>
            Check All Stickers:
          </label>
        </div>

        <div className={"form-label-r"}>
          <label htmlFor={"checkDoorsAndPanelKeys"}>
            Check Doors & Panel Keys:
          </label>
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkSensors ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkAllSensors"}>
              <input type={"checkbox"} id={"checkAllSensors"} name={"checkAllSensors"}
                     disabled={isLoading || isSubmitting}
                     checked={checkSensors} onChange={(ev) => onCheckSensorsChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkTightenHoldingDownBolts ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkAllTightenHoldingDownBolts"}>
              <input type={"checkbox"} id={"checkAllTightenHoldingDownBolts"} name={"checkAllTightenHoldingDownBolts"}
                     disabled={isLoading || isSubmitting}
                     checked={checkTightenHoldingDownBolts} onChange={(ev) => onCheckTightenHoldingDownBoltsChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkFuelDrainPlugSeal ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkFuelDrainPlugSeal"}>
              <input type={"checkbox"} id={"checkFuelDrainPlugSeal"} name={"checkFuelDrainPlugSeal"}
                     disabled={isLoading || isSubmitting}
                     checked={checkFuelDrainPlugSeal} onChange={(ev) => onCheckFuelDrainPlugSealChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkAKSAToolsKit ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkAKSAToolsKit"}>
              <input type={"checkbox"} id={"checkAKSAToolsKit"} name={"checkAKSAToolsKit"}
                     disabled={isLoading || isSubmitting}
                     checked={checkAKSAToolsKit} onChange={(ev) => onCheckAKSAToolsKitChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkDrawingManualBooks ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkDrawingAndManualBooks"}>
              <input type={"checkbox"} id={"checkDrawingAndManualBooks"} name={"checkDrawingAndManualBooks"}
                     disabled={isLoading || isSubmitting}
                     checked={checkDrawingManualBooks} onChange={(ev) => onCheckDrawingManualBooks(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkRadiatorHeadCover ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkRadiatorHeadCover"}>
              <input type={"checkbox"} id={"checkRadiatorHeadCover"} name={"checkRadiatorHeadCover"}
                     disabled={isLoading || isSubmitting}
                     checked={checkRadiatorHeadCover} onChange={(ev) => onCheckRadiatorHeadCoverChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkStickers ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkAllStickers"}>
              <input type={"checkbox"} id={"checkAllStickers"} name={"checkAllStickers"}
                     disabled={isLoading || isSubmitting}
                     checked={checkStickers} onChange={(ev) => onCheckStickersChanged(ev.target.checked)} />
            </label>
          }
        </div>

        <div className={"form-input-r"}>
          {!isEditable && <ReadonlyField>{checkDoorsPanelsKeys ? 'Yes' : 'No'}</ReadonlyField>}
          {isEditable &&
            <label htmlFor={"checkDoorsAndPanelKeys"}>
              <input type={"checkbox"} id={"checkDoorsAndPanelKeys"} name={"checkDoorsAndPanelKeys"}
                     disabled={isLoading || isSubmitting}
                     checked={checkDoorsPanelsKeys} onChange={(ev) => onCheckDoorsPanelsKeysChanged(ev.target.checked)} />
            </label>
          }
        </div>

      </div>

    </div>
  )
  //#endregion
}
