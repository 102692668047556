import React from "react";
import ReactModal from 'react-modal';
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { salesServices } from "../../repos/apiServices";
import { navigableRoutes as routes } from "../../repos/constants";
import { utils } from "../../repos/constants";

import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { ActorNameDisplay } from "./ActorNameDisplay";
import { EmployeeBranchGroupsDropdown } from "../shared/EmployeesDropdown";

import "../shared/EntryForm.css";
import "../shared/Modals.css";
import "./InquiryAssignModal.css";

export const InquiryAssignModal = ({
                                     isOpen, onRequestClose,
                                     inquiryReporters, inquiryEntryId,
                                   }) => {
  //#region States
  const [reporters, setReporters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);

  const [assignSalesPerson, setAssignSalesPerson] = useState('');
  const [isSubmittingAssign, setIsSubmittingAssign] = useState(false);
  const [assignErrorMessage, setAssignErrorMessage] = useState('');

  const [transferSalesPerson, setTransferSalesPerson] = useState('');
  const [isSubmittingTransfer, setIsSubmittingTransfer] = useState(false);
  const [transferErrorMessage, setTransferErrorMessage] = useState('');

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    setIsLoading(true);
    setReporters(inquiryReporters);
    refServices2.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _responseData = response['data'];
        setEmployeeBranchGroups(_responseData);
      })
      .finally(() => setIsLoading(false));
  }, []);
  //#endregion

  //#region Utilities
  const resetFormFields = () => {
    setAssignSalesPerson('');
    setAssignErrorMessage('');
    setIsSubmittingAssign(false);
    setTransferSalesPerson('');
    setTransferErrorMessage('');
    setIsSubmittingTransfer(false);
  }

  const getSecondaryReporters = () => {
    return utils.sales.getSecondaryReporters(reporters);
  }
  //#endregion

  //#region Control handlers
  const onModalRequestClose = (ev) => {
    resetFormFields();
    onRequestClose(ev);
  }

  const onAssignSalesPersonChanged = (ev) => {
    setAssignSalesPerson(ev.target.value);
  }

  const onAssignClicked = (ev) => {
    if (assignSalesPerson === '') {
      setAssignErrorMessage('Choose applicable sales person to assign.');
      return;
    }
    setAssignErrorMessage('');

    let payload = {
      'inquiryEntryId': inquiryEntryId,
      'reporterId': assignSalesPerson,
      'userId': auth.getUserId(),
    }
    setIsSubmittingAssign(true);
    salesServices.reporterAssign(payload)
      .then((response) => {
        setReporters(response.data['reporters']);
        setAssignSalesPerson('');
        setAssignErrorMessage('');
      })
      .catch((error) => {
        let errorMessage = error.response.data['error'];
        setAssignErrorMessage(errorMessage);
      })
      .finally(() => setIsSubmittingAssign(false));
  }

  const onUnAssignClicked = (ev, reporter) => {
    let payload = {
      'assignId': reporter['assignId'],
      'inquiryEntryId': reporter['inquiryEntryId'],
    }
    setIsLoading(true);
    salesServices.reporterUnAssign(payload)
      .then((response) => {
        setReporters(response.data['reporters']);
        setAssignErrorMessage('');
      })
      .catch((error) => {
        let errorMessage = error.response.data['error'];
        setAssignErrorMessage(errorMessage);
      })
      .finally(() => setIsLoading(false));
  }

  const onTransferSalesPersonChanged = (ev) => {
    setTransferSalesPerson(ev.target.value);
  }

  const onTransferClicked = (ev) => {
    if (transferSalesPerson === '') {
      setTransferErrorMessage('Choose application sales person to transfer.');
      return;
    }
    setTransferErrorMessage('');

    let payload = {
      'inquiryEntryId': inquiryEntryId,
      'reporterId': transferSalesPerson,
      'userId': auth.getUserId(),
    }
    setIsSubmittingTransfer(true);
    salesServices.reporterTransfer(payload)
      .then(() => {
        navigate(routes.salesInquiriesList.url);
      })
      .catch((error) => {
        let errorMessage = error.response.data['error'];
        setTransferErrorMessage(errorMessage);
      })
      .finally(() => setIsSubmittingTransfer(false));
  }
  //#endregion

  //#region Render
  return (
    <ReactModal isOpen={isOpen} id={"inquiry-assign-modal"}
                className={"popup-modal popup-modal-small"} overlayClassName={"popup-modal-overlay"}
                onRequestClose={onModalRequestClose}>

      <div className={"modal-heading"}>
        <h2>Assign Reporters</h2>
      </div>

      <div className={"modal-floated-controls"}>
        <button type={"button"} className={"btn btn-light"} onClick={onModalRequestClose}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>

      <div className={"modal-contents"}>
        <div>
          Assign a Sales Person for duty cover. Both assignees will have full access to this inquiry entry.
        </div>
      </div>

      <div className={"modal-form"}>
        <div className={"form-label"}>
          Primary reporter:
        </div>
        <div className={"form-input"}>
          <div className={"readonly-field"}>
            <ActorNameDisplay employee={utils.sales.getPrimaryReporter(reporters)} />
          </div>
        </div>

        {getSecondaryReporters().length > 0 &&
          <>
            <div className={"form-label"}>
              Duty cover reporter{getSecondaryReporters().length > 1 ? "s" : ""}:
            </div>
            <div className={"form-input"}>
              <div className={"values-grid"}>
                {getSecondaryReporters().map(reporter =>
                  <SecondaryReporter key={reporter['id']} reporter={reporter}
                                     onUnAssignClicked={onUnAssignClicked} isLoading={isLoading}
                                     isSubmittingAssign={isSubmittingAssign} isSubmittingTransfer={isSubmittingTransfer} />
                )}
              </div>
            </div>
          </>
        }

        <div className={"form-label"}>
          Assign to:<span className={"error-message"}>*</span>
        </div>
        <div className={"form-input"}>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"assignSalesPerson"} name={"assignSalesPerson"}
                                        isDisabled={isLoading || isSubmittingAssign || isSubmittingTransfer}
                                        value={assignSalesPerson} onChange={onAssignSalesPersonChanged} />
          <FieldErrorMessage visible={assignErrorMessage !== ''} message={assignErrorMessage} />
        </div>
      </div>

      <div className={"modal-controls form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-secondary"} disabled={isLoading || isSubmittingAssign || isSubmittingTransfer}
                  onClick={onAssignClicked}>
            {isSubmittingAssign && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
            {!isSubmittingAssign && <i className="fa-solid fa-user-plus"></i>}
            Assign
          </button>
        </div>
      </div>

      <div className={"modal-heading"}>
        <h3>Transfer Inquiry</h3>
      </div>

      <div className={"modal-contents"}>
        <div>
          Permanently transfer this inquiry entry to another Sales Person. (Note: This will remove all currently duty covers.)
        </div>
      </div>

      <div className={"modal-form"}>
        <div className={"form-label"}>
          Transfer to:<span className={"error-message"}>*</span>
        </div>
        <div className={"form-input"}>
          <EmployeeBranchGroupsDropdown branchGroups={employeeBranchGroups}
                                        id={"transferSalesPerson"} name={"transferSalesPerson"}
                                        isDisabled={isLoading || isSubmittingAssign || isSubmittingTransfer}
                                        value={transferSalesPerson} onChange={onTransferSalesPersonChanged} />
          <FieldErrorMessage visible={transferErrorMessage !== ''} message={transferErrorMessage} />
        </div>
      </div>

      <div className={"modal-controls form-button-controls"}>
        <div className={"left-side"}>
          <button type={"button"} className={"btn btn-secondary"} disabled={isLoading || isSubmittingAssign || isSubmittingTransfer}
                  onClick={onTransferClicked}>
            {isSubmittingTransfer && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
            {!isSubmittingTransfer && <i className="fa-solid fa-people-arrows-left-right"></i>}
            Transfer
          </button>
        </div>
      </div>

    </ReactModal>
  )
  //#endregion
}


const SecondaryReporter = ({
                             key, reporter, onUnAssignClicked,
                             isLoading, isSubmittingAssign, isSubmittingTransfer,
                           }) => {
  return (
    <>
      <div className={"readonly-field"}>
        <ActorNameDisplay employee={reporter['salesPerson']} />
      </div>
      <div className={"field-control"}>
        <button type={"button"} className={"btn btn-light btn-sm danger-button"}
                disabled={isLoading || isSubmittingAssign || isSubmittingTransfer}
                onClick={(ev) => onUnAssignClicked(ev, reporter)}>
          <i className="fa-solid fa-user-minus"></i>
        </button>
      </div>
    </>
  )
}
