import React from "react";

import { refs } from "../../repos/constants";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { getSummaryCount } from "../shared/SummaryModal";
import { TwoColumnsRow } from "../shared/SummaryModal";
import { TwoColumnsFooter } from "../shared/SummaryModal";

export const stockInspectionSummaryTypes = {
  1: "Inspection Type", inspectionType: 1,
  2: "Job Status", jobStatus: 2,
}

export const StockInspectionsListSummaryModal = ({
                                                   isOpen, onRequestClose,
                                                   isSummaryLoading,
                                                   summaryType, setSummaryType,
                                                   data, total,
                                                 }) => {
  const onSummaryTypeChanged = (ev) => {
    const _summaryType = parseInt(ev.target.value);
    setSummaryType(_summaryType);
  }

  return (
    <ModalContainer elementId={"job-histories-list-summaries"}
                    isOpen={isOpen} onRequestClose={onRequestClose} isLoading={false}
                    title={"Quick Summary"}
                    shortDescription={"Please note that the listing search criteria are being applied to the summary too."}
                    modalSize={modalSizes.tiny} bottom={"240px"}>
      <div className={"modal-form"}>
        <div className={"form-label"}>
          View by:
        </div>
        <div className={"form-input"}>
          <select id={"viewByOptions"} name={"viewByOptions"} className={"form-control form-select md-field"}
                  disabled={isSummaryLoading} value={summaryType} onChange={onSummaryTypeChanged}>
            <option value={stockInspectionSummaryTypes.inspectionType}>
              {stockInspectionSummaryTypes[stockInspectionSummaryTypes.inspectionType]}
            </option>
            <option value={stockInspectionSummaryTypes.jobStatus}>
              {stockInspectionSummaryTypes[stockInspectionSummaryTypes.jobStatus]}
            </option>
          </select>
        </div>
      </div>

      <div className={"modal-data-table modal-padded-top"}>
        <table>
          <thead>
          <tr>
            <th scope={"col"} className={"index-col-head small-col"}>#</th>
            <th scope={"col"}>
              (Description)
            </th>
            <th scope={"col"} className={"number-col"}>
              (Count)
            </th>
            <td></td>
          </tr>
          </thead>

          <tbody>
          {isSummaryLoading &&
            <TableLoadingIndicator colspan={4} />
          }

          {!isSummaryLoading && data.length === 0 &&
            <TableEmptyRow colSpan={4} />
          }

          {!isSummaryLoading && data.length > 0 &&
            <SummaryRows data={data} summaryType={summaryType} />
          }
          </tbody>
          {!isSummaryLoading && data.length > 0 &&
            <TwoColumnsFooter total={total} />
          }
        </table>
      </div>

      <div className={"modal-contents"}>
      </div>

    </ModalContainer>
  )
}
const stockInspectionTypeRefs = refs.inventory.stockInspectionType;
const jobStatusRefs = refs.inventory.jobStatus;

const SummaryRows = ({ data, summaryType }) => {
  switch (summaryType) {
    case stockInspectionSummaryTypes.inspectionType: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={stockInspectionTypeRefs[stockInspectionTypeRefs.arrival]}
                         count={getSummaryCount(data, stockInspectionTypeRefs.arrival)} />
          <TwoColumnsRow index={1}
                         description={stockInspectionTypeRefs[stockInspectionTypeRefs.predelivery]}
                         count={getSummaryCount(data, stockInspectionTypeRefs.predelivery)} />
          <TwoColumnsRow index={2}
                         description={stockInspectionTypeRefs[stockInspectionTypeRefs.monthly]}
                         count={getSummaryCount(data, stockInspectionTypeRefs.monthly)} />
          <TwoColumnsRow index={3}
                         description={stockInspectionTypeRefs[stockInspectionTypeRefs.imported]}
                         count={getSummaryCount(data, null)}
                         isNAColumn={true} />
        </>
      )
    }

    case stockInspectionSummaryTypes.jobStatus: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={jobStatusRefs[jobStatusRefs.draft]}
                         count={getSummaryCount(data, jobStatusRefs.draft)} />
          <TwoColumnsRow index={1}
                         description={jobStatusRefs[jobStatusRefs.pendingReview]}
                         count={getSummaryCount(data, jobStatusRefs.pendingReview)} />
          <TwoColumnsRow index={2}
                         description={jobStatusRefs[jobStatusRefs.active]}
                         count={getSummaryCount(data, jobStatusRefs.active)} />
        </>
      )
    }

    default: {
      return <TableEmptyRow colSpan={4} />
    }
  }
}
