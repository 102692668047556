import React from "react";

export const ReadonlyField = ({
                                isLoading = false,
                                children
                              }) =>
{
  return (
    <div className={"readonly-field"}>{children}</div>
  )
}
