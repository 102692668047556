import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { EMPTY_GUID } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ControlsGrid } from "../shared/ControlsGrid";
import { ControlsGroup } from "../shared/ControlsGrid";
import { ControlsItems } from "../shared/ControlsGrid";
import { ControlButton } from "../shared/ControlsGrid";

import "../shared/ListingPage.css";

export const ServiceReportsPage = () => {
  //#region Control handlers
  const navigate = useNavigate();

  const onClickOverWarrantyGenerators = (ev) => {
    navigate(routes.serviceReports_OverWarrantyGenerators.url);
  }

  const onClickGeneratorsWarrantyStatus = (ev) => {
    navigate(routes.serviceReports_WarrantyStatuses.url);
  }

  const onClickWorkDoneSummary = (ev) => {
    navigate(routes.serviceReports_WorkDoneSummary.url);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceReports.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.serviceReports.displayShort}</h1>
          </div>

          <ControlsGrid>
            <ControlsGroup title={"Service Generators"} />
            <ControlsItems>
              <ControlButton icon={routes.serviceReports_OverWarrantyGenerators.faIcon}
                             label={routes.serviceReports_OverWarrantyGenerators.displayShort}
                             disabled={false} onClick={onClickOverWarrantyGenerators} />
              <ControlButton icon={routes.serviceReports_WarrantyStatuses.faIcon}
                             label={routes.serviceReports_WarrantyStatuses.displayShort}
                             disabled={false} onClick={onClickGeneratorsWarrantyStatus} />
            </ControlsItems>
            <ControlsGroup title={"Service Jobs"} />
            <ControlsItems>
              <ControlButton icon={routes.serviceReports_WorkDoneSummary.faIcon}
                             label={routes.serviceReports_WorkDoneSummary.displayShort}
                             disabled={false} onClick={onClickWorkDoneSummary} />
            </ControlsItems>

          </ControlsGrid>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}
