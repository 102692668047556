import React from "react";
import { refs } from "../../repos/constants";

import { ReadonlyField } from "../shared/ReadonlyField";

export const ChecklistRadio = ({ elementId,
                                 value, onValueChanged,
                                 isReadonly, isDisabled,
                               }) => {
  const testChecklist = refs.inventory.testChecklist;

  return (
    <>
      {isReadonly && value !== null &&
        <ReadonlyField>
          {testChecklist[value]}
        </ReadonlyField>
      }
      {isReadonly && value === null &&
        <ReadonlyField>
          (NA)
        </ReadonlyField>
      }
      {!isReadonly &&
        <>
          <label className={"option-field"}>
            <input type={"radio"} id={elementId} name={elementId} disabled={isDisabled}
                   value={1} checked={value === 1} onChange={(ev) => onValueChanged(1)} />
            {testChecklist[1]}
          </label>
          <label className={"option-field"}>
            <input type={"radio"} id={elementId} name={elementId} disabled={isDisabled}
                   value={2} checked={value === 2} onChange={(ev) => onValueChanged(2)} />
            {testChecklist[2]}
          </label>
        </>
      }
    </>
  )
}
