import React from "react";

// TODO(yemon): Refactor all of these components, and their references, into the shared/ folder

export const InfoMessage = ({ children }) => {
  return (
    <div className={"alert alert-info"} role={"alert"}>
      {children}
    </div>
  )
}

export const WarningMessage = ({ children }) => {
  return (
    <div className={"alert alert-warning"} role={"alert"}>
      {children}
    </div>
  )
}

export const ErrorMessage = ({ children }) => {
  return (
    <div className={"alert alert-danger"} role={"alert"}>
      {children}
    </div>
  )
}
