import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { inventoryServices } from "../../repos/apiServices";
import { ReadonlyField } from "../shared/ReadonlyField";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { ModalContainer } from "../shared/ModalContainer";
import { FieldErrorMessage } from "../shared/FieldErrorMessages";
import { InfoMessage } from "../sales/FormMessages";
import { WarningMessage } from "../sales/FormMessages";
import { ErrorMessage } from "../sales/FormMessages";

import "../shared/Modals.css";

const commissionTypes = refs.inventory.serviceGeneratorCommissionType;

const modalHeights = {
  default: '500px',
  defaultInfo: '500px',
  messageVisible: '580px',
}

export const CommissionContractModal = ({
                                          isOpen, onRequestClose,
                                          serviceProfileId, serviceGeneratorId,
                                          isInfoMode,
                                          infoCommissionType, infoCommissionDate,
                                          infoWarrantyMonths, infoWarrantyHours,
                                          infoCreatedBy, infoCreatedDatetime,
                                          onRecommissionSuccessful,
                                        }) => {
  //#region States
  const [commissionType, setCommissionType] = useState(commissionTypes.pti);
  const [commissionDate, setCommissionDate] = useState(null);
  const [minCommissionDate, setMinCommissionDate] = useState(null);
  const [warrantyMonths, setWarrantyMonths] = useState(0);
  const [warrantyHours, setWarrantyHours] = useState("");

  const [modalHeight, setModalHeight] = useState(modalHeights.default);
  const [hasErrors, setHasErrors] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isConfirming, setIsConfirming] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let _height = !isInfoMode ? modalHeights.default : modalHeights.defaultInfo;
    setModalHeight(_height);
  }, []);
  //#endregion

  //#region Utilities
  const getTitleLabel = () => {
    return !isInfoMode ?
      "Recommission (or) Contract" :
      "Commission Info";
  }

  const getDescriptionLabel = () => {
    return !isInfoMode ?
      "Create and setup new commission setting for the current Service Generator..." : "";
  }
  //#endregion

  //#region Control handlers
  const onCommissionTypeChanged = (ev) => {
    setCommissionType(parseInt(ev.target.value));
  }

  const onCommissionDateChanged = (date) => {
    setCommissionDate(date);
  }

  const getCommissionDateClassName = () => {
    let className = "form-control";
    if (commissionDate) className += " has-autocompleted-value";
    return className;
  }

  const getCommissionDateStyle = () => {
    if (commissionDate !== null) {
      return { backgroundColor: '#d9eaff' };
    }
    else {
      return null;
    }
  }

  const onWarrantyMonthsChanged = (ev) => {
    if (!isNaN(ev.target.value)) {
      setWarrantyMonths(null);
    }
    let months = parseInt(ev.target.value);
    if (months === 0) {
      setWarrantyMonths(null);
    } else {
      setWarrantyMonths(months);
    }
  }

  const onWarrantyHoursChanged = (ev) => {
    // TODO(yemon): Warranty hours needs to be sanitized as an integer
    setWarrantyHours(ev.target.value);
  }

  const isWarrantyHoursValid = () => {
    if (!warrantyHours) {
      return true;
    }
    const iWarrantyHours = parseInt(warrantyHours.toString());
    return !(isNaN(iWarrantyHours) || !isFinite(iWarrantyHours));
  }

  const isFormFieldsValid = () => {
    let _hasErrors = false;
    let _formErrors = {
      commissionDate: "",
      warrantyHours: "",
    };

    // TODO(yemon): The specified 'commission/installation date' should at least be validated
    //  against the very first 'Arrival Inspection date' of the selected stock.
    if (commissionDate === null) {
      _hasErrors = true;
      _formErrors.commissionDate = "Commission/installation date is required.";
    }

    // TODO(yemon): 'warranty hours' validation should probably be standardized with the
    //  one being done in the `NewCommissionEntryPage`
    if (warrantyHours) {
      if (!isWarrantyHoursValid()) {
        _hasErrors = true;
        _formErrors.warrantyHours = "Enter a valid number value for the warranty hours.";
      }
      else {
        const iWarrantyHours = parseInt(warrantyHours.toString());
        if (iWarrantyHours < 0 || iWarrantyHours > 99999) {
          _hasErrors = true;
          _formErrors.warrantyHours = "Warranty hours value should be within a positive number range.";
        }
      }
    }

    setHasErrors(_hasErrors);
    setFormErrors(_formErrors);
    return !_hasErrors;
  }

  const preparePayload = () => {
    let payload = {
      'service_profile_id': serviceProfileId,
      'service_generator_id': serviceGeneratorId,
      'commission_type': commissionType,
      'commission_date': commissionDate,
    };
    if (warrantyMonths !== 0) {
      payload['warranty_months'] = warrantyMonths;
    }
    if (warrantyHours && isWarrantyHoursValid()) {
      payload['warranty_hours'] = parseInt(warrantyHours.toString());
    }
    return payload;
  }

  const onSubmitClicked = (ev) => {
    let _isValid = isFormFieldsValid();
    if (!_isValid) {
      return;
    }
    setIsConfirming(true);
  }

  const onConfirmYesClicked = (ev) => {
    // TODO(yemon): Should the whole validation, postback and state handling stuffs
    //  be pulled out into the `ServiceGeneratorPage` itself?
    let payload = preparePayload();
    payload['created_by_id'] = auth.getUserId()['eid'];
    setIsConfirming(true);

    setIsSubmitting(true);
    inventoryServices.postServiceProfileGeneratorRecommission(payload)
      .then((response) => {
        const responseData = response['data'];

        if (onRecommissionSuccessful) {
          onRecommissionSuccessful(responseData['newCommission']);
        }
        onCloseClicked(ev);
      })
      .catch((error) => {
        const responseData = error['response']['data'];
        setIsErrorAlertVisible(true);
        setErrorAlertMessage(responseData['message']);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const onConfirmNoClicked = (ev) => {
    setIsConfirming(false);
    setIsSubmitting(false);
  }

  const onCloseClicked = (ev) => {
    setHasErrors(false);
    setFormErrors({});
    setCommissionType(commissionTypes.pti);
    setCommissionDate(null);
    setWarrantyMonths(0);
    setWarrantyHours("");
    setIsConfirming(false);
    setIsSubmitting(false);

    setIsErrorAlertVisible(false);
    setErrorAlertMessage("");

    onRequestClose();
  }
  //#endregion

  //#region Render
  return (
    <ModalContainer elementId={"commission-contract-modal"}
                    isOpen={isOpen} onRequestClose={(ev) => onCloseClicked(ev)}
                    isLoading={false} height={modalHeight}
                    title={getTitleLabel()} shortDescription={getDescriptionLabel()}>
      <div className={"modal-form"}>
        <div className={"form-label"}>
          <label htmlFor={"commissionType"}>
            Commission Type:<span className={"error-message"}>*</span>
          </label>
        </div>
        <div className={"form-input"}>
          {!isInfoMode &&
            <select name={"commissionType"} id={"commissionType"} className={"form-control form-select"}
                    disabled={isSubmitting}
                    value={commissionType} onChange={onCommissionTypeChanged}>
              <option value={commissionTypes.pti}>
                {commissionTypes[commissionTypes.pti]}
              </option>
              <option value={commissionTypes.ptiContract}>
                {commissionTypes[commissionTypes.ptiContract]}
              </option>
              <option value={commissionTypes.otherContract}>
                {commissionTypes[commissionTypes.otherContract]}
              </option>
            </select>
          }
          {isInfoMode &&
            <ReadonlyField>
              {commissionTypes[infoCommissionType]}
            </ReadonlyField>
          }
        </div>

        <div className={"form-label"}>
          <label htmlFor={"installationDate"}>
            Commission/Installation Date:<span className={"error-message"}>*</span>
          </label>
        </div>
        <div className={"form-input"}>
          {!isInfoMode &&
            <>
              <DatePicker id={"installationDate"} className={"form-control"}
                          placeholder={"Type a valid date or click to choose"}
                          dateFormat={formatters.datetimePicker} minDate={minCommissionDate}
                          required={true} todayButton={"Today"} showWeekNumbers
                          autoComplete={"off"} disabled={isSubmitting}
                          selected={commissionDate} onChange={onCommissionDateChanged} />
              <FieldErrorMessage visible={hasErrors} message={formErrors["commissionDate"]} />
            </>
          }
          {isInfoMode &&
            <ReadonlyField>
              <Moment date={infoCommissionDate} format={formatters.datetimeShort} />
            </ReadonlyField>
          }
        </div>

        <div className={"form-label"}>
          <label htmlFor={"warrantyMonths"}>
            Warranty Months:
          </label>
        </div>
        <div className={"form-input"}>
          {!isInfoMode &&
            <select name={"warrantyMonths"} id={"warrantyMonths"} className={"form-control form-select"}
                    disabled={isSubmitting}
                    value={warrantyMonths} onChange={onWarrantyMonthsChanged}>
              <option value={0}>(Unspecified)</option>
              <option value={1}>1 month</option>
              {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month, index) =>
                <option value={month} key={index}>{month} months</option>
              )}
              <option value={12}>12 months (1 year)</option>
              {[13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((month, index) =>
                <option value={month} key={index}>{month} months (1 year, {month - 12} months)</option>
              )}
              <option value={24}>24 months (2 years)</option>
            </select>
          }
          {isInfoMode && !infoWarrantyMonths &&
            <ReadonlyField>-</ReadonlyField>
          }
          {isInfoMode && infoWarrantyMonths &&
            <ReadonlyField>
              {infoWarrantyMonths} Month{infoWarrantyMonths > 1 ? "s" : ""}
            </ReadonlyField>
          }
        </div>

        <div className={"form-label"}>
          <label htmlFor={"warrantyHours"}>
            Warranty Hours:
          </label>
        </div>
        <div className={"form-input"}>
          {!isInfoMode &&
            <>
              <input type={"text"} id={"warrantyHours"} className={"form-control"} autoComplete={"off"}
                     disabled={isSubmitting}
                     value={warrantyHours} onChange={onWarrantyHoursChanged} />
              <FieldErrorMessage visible={hasErrors} message={formErrors["warrantyHours"]} />
            </>
          }
          {isInfoMode && !infoWarrantyHours &&
            <ReadonlyField>-</ReadonlyField>
          }
          {isInfoMode && infoWarrantyHours &&
            <ReadonlyField>
              {infoWarrantyHours} Hour{infoWarrantyHours > 1 ? "s" : ""}
            </ReadonlyField>
          }
        </div>

        {isInfoMode &&
          <div className={"form-label"}>
            <label htmlFor={"createdBy"}>
              Created By:
            </label>
          </div>
        }
        {isInfoMode &&
          <div className={"form-input"}>
            <ReadonlyField>
              <ActorNameDisplay employee={infoCreatedBy} />
            </ReadonlyField>
          </div>
        }

        {isInfoMode &&
          <div className={"form-label"}>
            <label htmlFor={"createdDatetime"}>
              Created Date:
            </label>
          </div>
        }
        {isInfoMode &&
          <div className={"form-input"}>
            <ReadonlyField>
              <Moment date={infoCreatedDatetime} format={formatters.datetimeShort} />
            </ReadonlyField>
          </div>
        }

      </div>

      {!isConfirming &&
        <div className={"modal-controls form-button-controls"}>
          <div className={"left-side"}>
            {!isInfoMode &&
              <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                      onClick={onSubmitClicked}>
                {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                {!isSubmitting && <i className="fa-solid fa-check"></i>}
                Submit
              </button>
            }
            {isInfoMode &&
              <button type={"button"} className={"btn btn-secondary"}
                      onClick={onCloseClicked}>
                <i className={"fa-solid fa-xmark"}></i>
                Close
              </button>
            }
          </div>
        </div>
      }

      {isConfirming &&
        <div className={"modal-contents"}>
          {isConfirming && !isErrorAlertVisible &&
            <WarningMessage>
              Are you sure you want to create a new commission setting <b>{commissionTypes[commissionType]}</b>? This will setup a new series of Service Job
              History.
            </WarningMessage>
          }
          {isConfirming && isErrorAlertVisible &&
            <ErrorMessage>
              {errorAlertMessage}
            </ErrorMessage>
          }
        </div>
      }
      {isConfirming &&
        <div className={"modal-controls form-button-controls"}>
          <div className={"left-side"}>
            <button type={"button"} className={"btn btn-success"} disabled={isSubmitting}
                    onClick={onConfirmYesClicked}>
              {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
              {!isSubmitting && <i className="fa-solid fa-check"></i>}
              Yes
            </button>
            <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                    onClick={onConfirmNoClicked}>
              <i className={"fa-solid fa-xmark"}></i>
              No
            </button>
          </div>
        </div>
      }

    </ModalContainer>
  )
//#endregion
}
