import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { portalServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { getRegionDisplay } from "../../repos/utilities";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { ServiceLocationModal } from "./ServiceLocationModal";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/Modals.css";
import "../shared/DataTable.css";

const portalRefs = refs.portal;


export function ManageProfileLocationsPage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceProfile, setServiceProfile] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [displayFullname, setDisplayFullname] = useState("");

  const [selectedLocationId, setSelectedLocationId] = useState(null);   // not null in edit (row selected) mode
  const [locationName, setLocationName] = useState("");
  const [contactFullName, setContactFullName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [address, setAddress] = useState("");
  const [cityId, setCityId] = useState(null);
  const [city, setCity] = useState(null);
  const [townshipId, setTownshipId] = useState(null);
  const [township, setTownship] = useState(null);
  const [regionTerm, setRegionTerm] = useState("");
  const [regionSuggestions, setRegionSuggestions] = useState([]);

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingServiceLocations, setIsLoadingServiceLocations] = useState(false);
  const [serviceLocations, setServiceLocations] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    let _customerId = state['customerId'];
    let _profileId = state['serviceProfileId'];
    if (!state || !_customerId || !_profileId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      setCustomerId(_customerId);
      setServiceProfileId(_profileId);
      fetchCustomerServiceProfile(_customerId, _profileId);
      fetchServiceLocations(_profileId);
    }
  }, []);

  const fetchCustomerServiceProfile = (customerId, serviceProfileId) => {
    setIsLoadingProfile(true);
    let params = {
      'uid': auth.getUserId(),
      'customer_id': customerId,
      'service_profile_id': serviceProfileId,
    };
    inventoryServices.fetchCustomerServiceProfile(params)
      .then((response) => {
        let _serviceProfile = response['data'];
        setServiceProfile(_serviceProfile);
        prepareCustomerDetails(_serviceProfile['customer']);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingProfile(false);
      });
  }

  const prepareCustomerDetails = (customer) => {
    if (!customer) {
      return;
    }

    setCustomerName(customer['customerName']);
  }

  const fetchServiceLocations = (serviceProfileId) => {
    setIsLoadingServiceLocations(true);
    setServiceLocations([]);
    inventoryServices.fetchServiceProfileLocations(serviceProfileId)
      .then((response) => {
        let _profileLocations = response['data'];
        setServiceLocations(_profileLocations);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingServiceLocations(false);
      })
  }

  const handleErrorResponse = (error) => {
    let errorResponse = error['response'];
    if (errorResponse) {
      if (errorResponse.status === 404 || errorResponse.status === 400) {
        navigate(routes.serviceProfiles.url);
      }
    }
  }
  //#endregion

  //#region Utilities
  const isLoading = () => {
    return isLoadingProfile || isLoadingServiceLocations;
  }
  //#endregion

  //#region Control handlers
  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onReturnClicked = () => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onRefreshClicked = () => {
    fetchCustomerServiceProfile(customerId, serviceProfileId);
    fetchServiceLocations(serviceProfileId);
  }

  const resetLocationFormFields = () => {
    setSelectedLocationId(null);
    setLocationName("");
    setContactFullName("");
    setContactNo("");
    setAddress("");
    setCityId(null);
    setCity(null);
    setTownshipId(null);
    setTownship(null);
    setRegionTerm("");
    setRegionSuggestions([]);
  }
  //#endregion

  //#region Control handlers; Region term auto-complete field
  // TODO(yemon): This whole section is kinda becoming copy-pasta-y.
  // Probably should think about modularizing the whole auto-complete field as a component.
  const onRegionTermChanged = (ev, regionTerm, prevRegionTerm) => {
    if (regionTerm.trim() === '') {
      setRegionTerm('');
      setCityId(null);
      setTownshipId(null);
      return false;
    }
    if (regionTerm === prevRegionTerm) {
      return false;
    }
    setRegionTerm(regionTerm);
    setCityId(null);
    setTownshipId(null);
    return true;
  }

  const onRegionSuggestionCityClicked = (ev, city) => {
    setRegionTerm(city['name']);
    setCityId(city['id']);
    setTownshipId(null);
  }

  const onRegionSuggestionTownshipClicked = (ev, township) => {
    setRegionTerm(getRegionDisplay(city, township));
    setCityId(township['cityId']);
    setTownshipId(township['id']);
  }
  //#endregion

  //#region Control handler; New Location modal
  const [isNewLocationModalOpened, setIsNewLocationModalOpened] = useState(false);

  const newLocationModal = {
    onOpenButtonClicked: function() {
      resetLocationFormFields();
      setTimeout(() => {
        setIsNewLocationModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      resetLocationFormFields();
      setTimeout(() => {
        setIsNewLocationModalOpened(false);
      }, 200);
    },

    onNewLocationSubmitted: function() {
      fetchServiceLocations(serviceProfileId);
    },
  }
  //#endregion

  //#region Control handler; Edit Location modal
  const [isEditLocationModalOpened, setIsEditLocationModalOpened] = useState(false);

  const onLocationRowSelected = (ev, serviceLocation) => {
    ev.preventDefault();
    editPortalUserModal.onOpenButtonClicked(serviceLocation);
  }

  const editPortalUserModal = {
    onOpenButtonClicked: function(serviceLocation) {
      setSelectedLocationId(serviceLocation['id']);
      setLocationName(serviceLocation['name']);
      setContactFullName(serviceLocation['contactFullName']);
      setContactNo(serviceLocation['contactNo']);
      setAddress(serviceLocation['address']);

      setCityId(serviceLocation['city']['id']);
      setCity(serviceLocation['city']);
      if (serviceLocation['township']) {
        setTownshipId(serviceLocation['township']['id']);
        setTownship(serviceLocation['township']);
      }
      setRegionTerm(getRegionDisplay(serviceLocation['city'], serviceLocation['township']));

      setTimeout(() => {
        setIsNewLocationModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      resetLocationFormFields();
      setTimeout(() => {
        setIsNewLocationModalOpened(false);
      }, 200);
    },

    onLocationSubmitted: function() {
    },
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.manageProfileLocations.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{customerName}</h1>
          </div>

          <div className={"form-section"}>
            <h2>{routes.manageProfileLocations.display}</h2>
          </div>

          <ServiceLocationModal serviceProfileId={serviceProfileId} selectedLocationId={selectedLocationId}
                                locationName={locationName} setLocationName={setLocationName}
                                contactFullName={contactFullName} setContactFullName={setContactFullName}
                                contactNo={contactNo} setContactNo={setContactNo}
                                address={address} setAddress={setAddress}
                                cityId={cityId} setCityId={setCityId} city={city}
                                townshipId={townshipId} setTownshipId={setTownshipId} township={township}
                                regionTerm={regionTerm} onRegionTermChanged={onRegionTermChanged}
                                regionSuggestions={regionSuggestions} setRegionSuggestions={setRegionSuggestions}
                                onRegionSuggestionCityClicked={onRegionSuggestionCityClicked}
                                onRegionSuggestionTownshipClicked={onRegionSuggestionTownshipClicked}
                                resetLocationFormFields={resetLocationFormFields}
                                isOpen={isNewLocationModalOpened}
                                onRequestClose={newLocationModal.onCloseButtonClicked}
                                onLocationSubmitted={newLocationModal.onNewLocationSubmitted} />

          <div className={"form-section-controls"}>
            <button type={"button"} className={"btn btn-secondary"} disabled={isLoading()}
                    onClick={onReturnClicked}>
              <i className={"fa-solid fa-arrow-left"}></i>
              Return to Profile
            </button>
            <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isLoading()}
                    onClick={onRefreshClicked}>
              {isLoading() && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isLoading() && <i className={"fa-solid fa-sync"}></i>}
              Refresh
            </button>

            <button type={"button"} className={"btn btn-primary"} disabled={isLoading()}
                    onClick={newLocationModal.onOpenButtonClicked}>
              <i className={"fa-solid fa-location-pin"}></i>
              Create New Location
            </button>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>Location Name</th>
                <th scope={"col"}>Contact Full Name</th>
                <th scope={"col"}>Contact No</th>
                <th scope={"col"}>Region</th>
              </tr>
              </thead>

              <tbody>
              {isLoadingServiceLocations && <TableLoadingIndicator colspan={5} />}

              {!isLoadingServiceLocations && (serviceLocations && serviceLocations.length > 0) &&
                serviceLocations.map((location, index) =>
                  <LocationRow serviceLocation={location} index={index} key={location['id']}
                               onRowClicked={onLocationRowSelected} />
                )
              }

              {!isLoadingServiceLocations && serviceLocations.length === 0 &&
                <TableEmptyRow colSpan={5} />
              }
              </tbody>
            </table>
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const LocationRow = ({
                       serviceLocation, index,
                       onRowClicked,
                     }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {index + 1}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, serviceLocation)}>
          {serviceLocation['name']}
        </a>
      </td>
      <td>
        {serviceLocation['contactFullName']}
      </td>
      <td>
        {serviceLocation['contactNo']}
      </td>
      <td>
        {getRegionDisplay(serviceLocation['city'], serviceLocation['township'])}
      </td>
    </tr>
  )
}
