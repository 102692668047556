import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices2";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";

import { ActorNameDisplay } from "../sales/ActorNameDisplay";

import "./SalesTargetsGrid.css";

export const EmptySaleTargetsGrid = ({}) => {
  return (
    <div className={"empty-sales-targets-grid"}>
      Specify the sales team members first by clicking the button above.
    </div>
  )
}

export const LoadingSalesTargetIndicator = ({}) => {
  return (
    <div className={"loading-sales-targets-grid"}>
      Loading sales targets...
    </div>
  )
}

export const SalesTargetsGrid = ({ children }) => {
  return (
    <div className={"sales-targets-grid"}>
      {children}
    </div>
  )
}

export const SalesTargetsHeading = ({ title }) => {
  return (
    <div className={"sales-targets-heading"}>
      {title}
    </div>
  )
}

export const SalesTargetsItems = ({ 
                                    salesPerson, 
                                    activeTargets, inactiveTargets, 
                                    allowNew, onNewTargetClicked,
                                  }) => {
  const hasActiveTargets = () => {
    return activeTargets && activeTargets.length > 0;
  }

  const hasInactiveTargets = () => {
    return inactiveTargets && inactiveTargets.length > 0;
  }

  return (
    <div className={"sales-targets-group"}>
      {!hasActiveTargets() && !hasInactiveTargets() && !allowNew && 
        <div className={"empty-sales-targets-items"}>
          Nothing to show for this sales person.
        </div> 
      }

      {(hasActiveTargets() || allowNew) &&
        <SalesTargetsActiveLabel /> 
      }
      <div className={"sales-targets-items"}>
        {activeTargets && activeTargets.length > 0 &&
          activeTargets.map((target, index) => {
            <SalesTargetsButton salesTarget={target}
                                index={index}
                                disabled={false} onClick={null} />
          })
        }
        <SalesTargetsNewButton salesPerson={salesPerson} 
                               hidden={!allowNew} 
                               onClick={onNewTargetClicked} />
      </div> 

      {hasInactiveTargets() &&
        <SalesTargetsInactiveLabel />
      }
      {hasInactiveTargets() && 
        <div className={"sales-targets-items"}>
          {inactiveTargets.map((target, index) => 
            <SalesTargetsButton salesTarget={target}
                                index={index}
                                disabled={false} onClick={null} />) 
          }
        </div> 
      }
    </div>
  )
}

const SalesTargetsActiveLabel = ({}) => {
  return (
    <div className={"group-label"}>
      ACTIVE
    </div>
  )
}

const SalesTargetsInactiveLabel = ({}) => {
  return (
    <div className={"group-label"}>
      INACTIVE
    </div>
  )
}

export const SalesTargetsButton = ({ 
                                     salesTarget, 
                                     disabled, onClick 
                                   }) => {
  return (
    <button type={"button"} className={"sales-target-button"}
            disabled={disabled} onClick={onClick}>
      <div className={"target-date-range"}>
        (from date - to date)
      </div>
      <div className={"target-progresses"}>
        <div className={"target-progress"}>
          <div className={"target-progress-label"}>USD</div>
          <div className={"target-progress-bar"} title={"[70%] 70,000 / 100,000 USD"}>
            <div className={"target-progress-bar-foreground"} style={{ width: '70%' }}></div>
          </div>
        </div>
        <div className={"target-progress"}>
          <div className={"target-progress-label"}>MMK</div>
          <div className={"target-progress-bar"} title={"[40%] 8,000,000 / 10,00,000 MMK"}>
            <div className={"target-progress-bar-foreground"} style={{ width: '40%' }}></div>
          </div>
        </div>
      </div>
    </button>
  )
}

export const SalesTargetsNewButton = ({ 
                                        salesPerson, 
                                        hidden, onClick 
                                      }) => {
  if (hidden) {
    return (<></>)
  } 
  else {
    return (
      <button type={"button"} className={"new-button"} 
              title={`Define new sales target for ${salesPerson ? salesPerson['fullName'] : 'employee'}.`}
              disabled={hidden} onClick={(ev) => onClick(ev, salesPerson)}>
        +
      </button>
    )
  }
}