import React from "react";

import { refs } from "../../repos/constants";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { getSummaryCount } from "../shared/SummaryModal";
import { TwoColumnsRow } from "../shared/SummaryModal";
import { TwoColumnsFooter } from "../shared/SummaryModal";

export const jobHistorySummaryTypes = {
  1: "Service Type", serviceType: 1,
  2: "Job Status", jobStatus: 2,
}

export const JobHistoriesListSummaryModal = ({
                                               isOpen, onRequestClose,
                                               isSummaryLoading,
                                               summaryType, setSummaryType,
                                               data, total,
                                             }) => {
  const onSummaryTypeChanged = (ev) => {
    const _summaryType = parseInt(ev.target.value);
    setSummaryType(_summaryType);
  }

  //#region Render
  return (
    <ModalContainer elementId={"job-histories-list-summaries"}
                    isOpen={isOpen} onRequestClose={onRequestClose} isLoading={false}
                    title={"Quick Summary"}
                    shortDescription={"Please note that the listing search criteria are being applied to the summary too."}
                    modalSize={modalSizes.tiny} bottom={"160px"}>
      <div className={"modal-form"}>
        <div className={"form-label"}>
          View by:
        </div>
        <div className={"form-input"}>
          <select id={"viewByOptions"} name={"viewByOptions"} className={"form-control form-select md-field"}
                  disabled={isSummaryLoading} value={summaryType} onChange={onSummaryTypeChanged}>
            <option value={jobHistorySummaryTypes.serviceType}>
              {jobHistorySummaryTypes[jobHistorySummaryTypes.serviceType]}
            </option>
            <option value={jobHistorySummaryTypes.jobStatus}>
              {jobHistorySummaryTypes[jobHistorySummaryTypes.jobStatus]}
            </option>
          </select>
        </div>
      </div>

      <div className={"modal-data-table modal-padded-top"}>
        <table>
          <thead>
          <tr>
            <th scope={"col"} className={"index-col-head small-col"}>#</th>
            <th scope={"col"}>
              Description
            </th>
            <th scope={"col"} className={"number-col"}>
              Count
            </th>
            <td scope={"col"} className={"right-padded"}></td>
          </tr>
          </thead>

          <tbody>
          {isSummaryLoading &&
            <TableLoadingIndicator colspan={4} />
          }

          {!isSummaryLoading && data.length === 0 &&
            <TableEmptyRow colSpan={4} />
          }

          {!isSummaryLoading && data.length > 0 &&
            <SummaryRows data={data} summaryType={summaryType} />
          }
          </tbody>
          {!isSummaryLoading && data.length > 0 &&
            <TwoColumnsFooter total={total} />
          }
        </table>
      </div>

      <div className={"modal-contents"}>
      </div>

    </ModalContainer>
  )
  //#endregion
}

const followUpServiceTypeRefs = refs.inventory.serviceType;
const jobStatusRefs = refs.inventory.jobStatus;

const SummaryRows = ({ data, summaryType }) => {
  switch (summaryType) {
    case jobHistorySummaryTypes.serviceType: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.tnc]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.tnc)} />
          <TwoColumnsRow index={1}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.pm]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.pm)} />
          <TwoColumnsRow index={2}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.regular]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.regular)} />
          <TwoColumnsRow index={3}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.repair]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.repair)} />
          <TwoColumnsRow index={4}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.emergency]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.emergency)} />
          <TwoColumnsRow index={5}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.inspection]}
                         count={getSummaryCount(data, followUpServiceTypeRefs.inspection)} />
          <TwoColumnsRow index={6}
                         description={followUpServiceTypeRefs[followUpServiceTypeRefs.na]}
                         count={getSummaryCount(data, null)}
                         isNAColumn={true} />
        </>
      )
    }

    case jobHistorySummaryTypes.jobStatus: {
      return (
        <>
          <TwoColumnsRow index={0}
                         description={jobStatusRefs[jobStatusRefs.draft]}
                         count={getSummaryCount(data, jobStatusRefs.draft)} />
          <TwoColumnsRow index={1}
                         description={jobStatusRefs[jobStatusRefs.pendingReview]}
                         count={getSummaryCount(data, jobStatusRefs.pendingReview)} />
          <TwoColumnsRow index={2}
                         description={jobStatusRefs[jobStatusRefs.active]}
                         count={getSummaryCount(data, jobStatusRefs.active)} />
        </>
      )
    }

    default: {
      return <TableEmptyRow colSpan={4} />
    }
  }
}
