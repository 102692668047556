import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { NullBlankField } from "../service/NullBlankField";
import { SortIndicator } from "../shared/SortIndicator";
import { ActorPureNameDisplay } from "../sales/ActorNameDisplay";

import "../shared/DataTable.css";
import '../shared/ListingPage.css';
import "./InvoicesListPage.css";


export const InvoicesListPage = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sorting, setSorting] = useState(null);

  const [listPermissions, setListPermissions] = useState({});

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
  }, []);

  const fetchInvoices = () => {
    // TODO(yemon):
  }
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
  }

  const onTempNewInvoiceClicked = (ev) => {
    // TODO(yemon): Temporary button just for the demo!
    navigate(routes.invoiceEntry.url);
  }

  const onRowClicked = (ev, entry) => {
  }

  const onPageClick = (page) => {
  }

  const onPrevPageClicked = (fromPage) => {
  }

  const onNextPageClicked = (fromPage) => {
  }
  //#endregion

  //#region Search Panel; Control handlers, states and other utilities
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // flag to indicate whether the last post back was a search trigger
  
  const onSearchToggleClicked = (ev) => {
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.invoicesList.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{routes.invoicesList.display}</h1>

            <div className="listing-controls">
              <button className={"btn btn-secondary"} disabled={isLoading}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              <button className={"btn btn-secondary"} 
                      onClick={onTempNewInvoiceClicked}>
                New
              </button>

              <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button>
            </div>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
                <tr>
                  <th scope={"col"} className={"index-col-head"}>#</th>
                  <th scope={"col"}>
                    Invoice No.
                  </th>
                  <th scope={"col"}>
                    Invoice Date
                    <SortIndicator isAscending={false} />
                  </th>
                  <th scope={"col"}>
                    Sales Person / Branch
                  </th>
                  <th scope={"col"}>
                    Customer
                  </th>
                  <th scope={"col"} title={"Order Confirmation"}>
                    OC Customer / Date
                  </th>
                  <th scope={"col"}>
                    Created By
                  </th>
                  <th scope={"col"}>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading && <TableLoadingIndicator colspan={7} />}

                {!isLoading && invoices.length === 0 && 
                  <TableEmptyRow colSpan={7} />
                }
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={null}
                               onViewSettingsSaved={null} />
            }
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}

const InvoiceTableRow = ({
                          invoice, index,
                          currentPage, pageSize,
                          onRowClicked,
                        }) => {
  return (
    <></>
  )
}
