import React from "react";
import { NavLink } from "react-router-dom";

import "./Breadcrumb.css";


export const Breadcrumbs = ({ children }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className={"breadcrumb"}>
        {children}
      </ol>
    </nav>
  )
}


export const BreadcrumbItem = ({
                                 text, isActive, hasTailDivider, anchorTo,
                                 children,
                               }) => {
  const getItemClassName = () => {
    let className = "breadcrumb-item";
    if (isActive) className += " active";
    if (hasTailDivider) className += " has-divider";
    return className;
  }

  return (
    <li className={getItemClassName()}>
      {!children &&
        <>
          {anchorTo &&
            <NavLink to={anchorTo}>
              {text}
            </NavLink>
          }
          {!anchorTo &&
            <>{text}</>
          }
        </>
      }
      {children}
    </li>
  )
}
