import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { navigableRoutes as routes } from "../../repos/constants";
import { role as roleConstants } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "../service/NullBlankField";
import { ServiceProfileStatus } from "../service/ServiceProfileStatus";

import '../shared/EntryForm.css';


const customerSources = refs.customer.source;

export const CustomerProfilePage = () => {
  //#region States
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableNewQuotationRequest, setDisableNewQuotationRequest] = useState(true);
  const [disableNewOrderConfirmation, setDisableNewOrderConfirmation] = useState(true);

  const [serviceProfile, setServiceProfile] = useState(null);
  const [locationsCount, setLocationsCount] = useState(0);
  const [generatorsCount, setGeneratorsCount] = useState(0);
  const [disableViewServiceProfile, setDisableViewServiceProfile] = useState(true);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    if (location.state === null) {
      navigate(routes.customersList.url);
    }

    let _customer = location.state;
    setCustomer(_customer);

    setIsLoading(true);
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        const responseData = response['data'];
        let _quotationRequestPermissions = responseData['quotationRequests'];
        setDisableNewQuotationRequest(_quotationRequestPermissions['reviewLevel'] !== roleConstants.quotationRequest.reviewLevels.requester);

        let _orderConfirmationPermissions = responseData['orderConfirmations'];
        setDisableNewOrderConfirmation(_orderConfirmationPermissions['editLevel'] === roleConstants.orderConfirmation.editLevels.readOnly);

        let _servicePermissions = responseData['service'];
        setDisableViewServiceProfile(_servicePermissions['serviceProfilesGranted'] === false);
      })
      .then(() => {
        return inventoryServices.fetchCustomerServiceProfileStatus(_customer['id']);
      })
      .then((response) => {
        const _serviceProfile = response['data']['data'];
        setServiceProfile(_serviceProfile);
        setLocationsCount(_serviceProfile['summary']['locationsCount']);
        setGeneratorsCount(_serviceProfile['summary']['generatorsCount']);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, []);
  //#endregion

  //#region Control handlers
  const onNewQuotationRequestClicked = (ev) => {
    ev.preventDefault();
    setTimeout(() => {
      let _customer = customer;
      _customer['customerId'] = customer['id']; // identification on the receiving end
      navigate(routes.quotationRequestEntry.url, { state: _customer });
    }, 200);
  }

  const onNewOrderConfirmationClicked = (ev) => {
    ev.preventDefault();
    setTimeout(() => {
      let _customer = customer;
      _customer['customerId'] = customer['id']; // identification on the receiving end
      navigate(routes.orderConfirmationEntry.url, { state: _customer });
    }, 200);
  }

  const onViewServiceProfileClicked = (ev) => {
    ev.preventDefault();
    if (!serviceProfile) {
      return;
    }
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customer['id'],
          'serviceProfileId': serviceProfile['id'],
        }
      })
    }, 200);
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container-grid"}>
        <div className={"content-area content-area-main"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.customersList.displayShort} anchorTo={routes.customersList.url} />
              <BreadcrumbItem text={routes.customerProfile.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>Customer Profile</h1>
          </div>

          <form autoComplete="off">
            <div className={"form-section"}>
              <div className={"entry-form"}>
                <div className={"form-label-wide"}>
                  <label>
                    Customer Name:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && customer['customerName']}
                  </ReadonlyField>
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Company:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && (customer['companyName'] ? customer['companyName'] : '-')}
                  </ReadonlyField>
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Business Type:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && customer['businessType']['name']}
                  </ReadonlyField>
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Address:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && <NullBlankValue value={customer['address']} />}
                  </ReadonlyField>
                </div>
              </div>
            </div>

            <div className={"form-section"}>
              <h2>
                Contact Details
              </h2>
              <div className={"entry-form"}>
                <div className={"form-label-wide"}>
                  <label>
                    Contact Name:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && customer['contactFullName']}
                  </ReadonlyField>
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Contact No:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && customer['contactNo']}
                  </ReadonlyField>
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Contact NRC:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && <NullBlankValue value={customer['nrc']} />}
                  </ReadonlyField>
                </div>

                <div className={"form-label-wide"}>
                  <label>
                    Contact Designation:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && <NullBlankValue value={customer['designation']} />}
                  </ReadonlyField>
                </div>
              </div>
            </div>

            <div className={"form-section"}>
              <h2>
                Profile Status
              </h2>
              <div className={"entry-form"}>
                <div className={"form-label-wide"}>
                  <label>
                    Created Source:
                  </label>
                </div>
                <div className={"form-input-wide"}>
                  <ReadonlyField>
                    {customer && customerSources[customer['source']]}
                  </ReadonlyField>
                </div>
              </div>
            </div>

          </form>
        </div>

        <div className={"content-area content-area-side"}>
          <div className={"info-panel"}>
            <h2>Quick Actions</h2>
            <div className={"panel-controls"}>
              <button className="btn btn-md btn-secondary" onClick={onNewQuotationRequestClicked}
                      disabled={isLoading || disableNewQuotationRequest}>
                <i className="fa-solid fa-plus"></i>
                New Quotation Request
              </button>
            </div>
            <div className={"panel-controls"}>
              <button className="btn btn-md btn-secondary" onClick={onNewOrderConfirmationClicked}
                      disabled={isLoading || disableNewOrderConfirmation}>
                <i className="fa-solid fa-plus"></i>
                New Order Confirmation
              </button>
            </div>
          </div>

          <div className={"info-panel"}>
            <h2>Service Profile</h2>
            {!serviceProfile &&
              <div className={"panel-contents"}>
                No active service profile.
              </div>
            }
            {serviceProfile &&
              <div className={"panel-contents"}>
                <b>
                  <ServiceProfileStatus profileStatus={serviceProfile['status']}
                                        profileActiveDatetime={serviceProfile['activeDatetime']}
                                        profileInactiveDatetime={serviceProfile['inactiveDatetime']} />
                </b>
              </div>
            }
            {serviceProfile &&
              <div className={"panel-contents"}>
                Currently has <b>{generatorsCount}</b> service generator{generatorsCount > 1 ? "s": ""},
                commissioned at <b>{locationsCount}</b> {locationsCount > 1 ? "different" : ""} location{locationsCount > 1 ? "s": ""}.
              </div>
            }
            <div className={"panel-controls"}>
              <button role={"button"} className={"btn btn-secondary"}
                      disabled={isLoading || disableViewServiceProfile} onClick={onViewServiceProfileClicked}>
                <i className={"fa-solid fa-search"}></i>View
              </button>
            </div>
          </div>
        </div>

      </main>
    </MasterPageContainer>
)
//#endregion
}
