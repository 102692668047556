import React from "react";
import Moment from "react-moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { authService } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { getFollowUpStage } from "../../repos/utilities";
import { getFollowUpStageActionDisplay } from "../../repos/utilities";
import { getRegionDisplay } from "../../repos/utilities";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getUpdateRequestsContext } from "../../repos/viewContexts";
import { saveUpdateRequestsContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";
import { doVirtualDownload } from "../../repos/exports";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { TablePagination } from "../shared/TablePagination";
import { ActorNameDisplay } from "./ActorNameDisplay";
import { InquiryUpdateRequestsModal } from "./InquiryUpdateRequestsModal";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { TableEmptyRow } from "../shared/DataTable";
import { TableLoadingIndicator } from "../shared/DataTable";
import { SalesInquirySearchPanel } from "./SalesInquirySearchPanel";
import { GeneratorsSummary } from "./GeneratorsSummary";

import '../shared/ContentArea.css';
import '../shared/DataTable.css';
import '../shared/ListingPage.css';
import '../shared/SearchPanel.css';
import './SalesInquiryListPage.css';


export function SalesInquiryListPage() {

  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [inquiries, setInquiries] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [listPermissions, setListPermissions] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // flag to indicate whether the last post back was a search trigger
  const [isExporting, setIsExporting] = useState(false);

  const [followUpStages, setFollowUpStages] = useState([]);
  const [followUpActions, setFollowUpActions] = useState([]);

  const [contactNameSearch, setContactNameSearch] = useState('');
  const [contactNoSearch, setContactNoSearch] = useState('');
  const [customerNameSearch, setCustomerNameSearch] = useState('');
  const [regionTermSearch, setRegionTermSearch] = useState('');
  const [cityIdSearch, setCityIdSearch] = useState(null);
  const [townshipIdSearch, setTownshipIdSearch] = useState(null);
  const [sourceSearch, setSourceSearch] = useState(0);
  const [businessTypeSearch, setBusinessTypeSearch] = useState(0);
  const [dateFromSearch, setDateFromSearch] = useState(null);
  const [dateToSearch, setDateToSearch] = useState(null);
  const [gensetModelTermSearch, setGensetModelTermSearch] = useState('');
  const [gensetModelIdSearch, setGensetModelIdSearch] = useState('');
  const [followUpStageSearch, setFollowUpStageSearch] = useState(0);
  const [followUpActionSearch, setFollowUpActionSearch] = useState('0');
  const [reporterSearch, setReporterSearch] = useState('');
  const [isFirstContactSearch, setIsFirstContactSearch] = useState(false);
  const [isHistoryIncludedSearch, setIsHistoryIncludedSearch] = useState(false);
  const [estimatePoDateFromSearch, setEstimatePoDateFromSearch] = useState(null);
  const [estimatePoDateToSearch, setEstimatePoDateToSearch] = useState(null);

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.salesInquiries);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext !== null) {
      // trigger search with the existing context
      triggerSearchByContext(searchOptionsContext, paginationContext);
    } else {
      // load the regular list
      fetchInquiryList(paginationContext ? paginationContext['currentPage'] : 1);
    }
    fetchPermissions();

    let updateRequestContext = getUpdateRequestsContext(contexts.salesInquiries);
    if (updateRequestContext && updateRequestContext['isModalOpened']) updateRequestsModal.onOpenButtonClicked();
    fetchInquiryUpdateRequests();

    loadFollowUpStages();
  }, []);

  const fetchPermissions = () => {
    return authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _listPermissions = response.data['salesInquiries'];
        setListPermissions(_listPermissions);
      });
  }

  const prepareListPayload = (page) => {
    let settings = getViewSettings(viewSettings.salesInquiries);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const fetchInquiryList = (page) => {
    resetListingStates();
    setIsSearching(false);
    setIsLoading(true);
    let listPayload = prepareListPayload(page);
    salesServices.inquiryList(listPayload)
      .then((response) => {
        updateListingStates(response.data);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => setIsLoading(false));
  }

  const updateListingStates = (responseData) => {
    setInquiries(responseData['data']);
    setPagination(responseData['pagination']);
    savePaginationContext(contexts.salesInquiries, responseData['pagination']);
    setSorting((responseData['sorting']));
  }

  const resetListingStates = () => {
    setInquiries([]);
    setPagination(null);
    setSorting(null);
  }

  const loadFollowUpStages = () => {
    setFollowUpStages(refs.sales.followUpStages);
    setFollowUpActions([]);
    setFollowUpActionSearch('0');
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Utilities; Search
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();       // TODO(yemon): Should use the search values from the view context instead of resetting the search panel
    setIsSearchOpen(false);
    saveSearchPanelOpenContext(contexts.salesInquiries, false);

    let paginationContext = getPaginationContext(contexts.salesInquiries);
    fetchInquiryList(paginationContext ? paginationContext['currentPage'] : 1);
    fetchInquiryUpdateRequests();
  }

  const onNewEntryClicked = () => {
    setTimeout(() => {
      navigate(routes.salesInquiriesEntry.url);
    }, 200);
  }

  const onRowClicked = (ev, inquiry) => {
    setTimeout(() => {
      navigate(routes.salesInquiriesEntry.url, { state: inquiry });
    }, 200);
  }

  const onPageClick = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    } else {
      fetchInquiryList(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    } else {
      fetchInquiryList(page);
    }
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    } else {
      fetchInquiryList(page);
    }
  }
  //#endregion

  //#region Update requests modal control handlers and others
  const [isUpdateRequestsModalOpen, setIsUpdateRequestsModalOpen] = useState(false);
  const [isUpdateRequestsLoading, setIsUpdateRequestsLoading] = useState(false);
  const [inquiryUpdateRequests, setInquiryUpdateRequests] = useState([]);

  const updateRequestsModal = {
    onOpenButtonClicked: function() {
      saveUpdateRequestsContext(contexts.salesInquiries, true);
      setTimeout(() => {
        setIsUpdateRequestsModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      saveUpdateRequestsContext(contexts.salesInquiries, false);
      setTimeout(() => {
        setIsUpdateRequestsModalOpen(false);
      }, 200);
    },
  }

  const fetchInquiryUpdateRequests = () => {
    setIsUpdateRequestsLoading(true);
    let updateRequestsTableOptions = {
      'uid': auth.getUserId(),
    };
    return salesServices.inquiryListUpdateRequests(updateRequestsTableOptions)
      .then((response) => {
        let _updateRequests = response.data.data;
        setInquiryUpdateRequests(_updateRequests);
      })
      .finally(() => {
        setIsUpdateRequestsLoading(false);
      });
  }

  const onUpdateRequestRowClicked = (ev, updateRequest) => {
    ev.preventDefault();
    setTimeout(() => {
      navigate(routes.salesInquiriesEntry.url, { state: updateRequest.inquiry });
    }, 100);
  }
  //#endregion

  //#region Search panel; Control handlers, loaders and utilities
  useEffect(() => {
    let _stageId = followUpStageSearch;
    if (_stageId === 0) {
      setFollowUpActions([]);
      return;
    }
    let _stage = getFollowUpStage(_stageId);
    setFollowUpActions(_stage['actions']);
  }, [followUpStageSearch]);

  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.salesInquiries);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.salesInquiries);
    if (searchOptions === null) return null;

    // NOTE(yemon): Field values in the context were saved from the postback payload.
    setContactNameSearch(searchOptions['contact_name']);
    setContactNoSearch(searchOptions['contact_no']);

    setRegionTermSearch(searchOptions['region_term']);
    setCityIdSearch(searchOptions['city_id'] ? searchOptions['city_id'] : null);
    setTownshipIdSearch(searchOptions['township_id'] ? searchOptions['township_id'] : null);

    setSourceSearch(searchOptions['source_id']);
    setBusinessTypeSearch(searchOptions['business_type_id']);

    setDateFromSearch(searchOptions['date_from'] ? new Date(searchOptions['date_from']) : null);
    setDateToSearch(searchOptions['date_to'] ? new Date(searchOptions['date_to']) : null);

    setGensetModelTermSearch(searchOptions['genset_model_term']);
    setGensetModelIdSearch(searchOptions['genset_model_id']);

    setFollowUpStageSearch(searchOptions['followup_stage']);
    setTimeout(() => {
      // NOTE(yemon): A small delay to make sure that the 'followUpActions' state is properly populated first
      setFollowUpActionSearch(searchOptions['followup_action']);
    }, 200);

    setReporterSearch(searchOptions['reporter_id']);
    setIsFirstContactSearch(searchOptions['is_first_contact']);
    setIsHistoryIncludedSearch(searchOptions['is_history_included']);

    return searchOptions;
  }

  const onSearchToggleClicked = (ev) => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.salesInquiries, _isOpen);
  }

  const onContactNameSearchChanged = (ev) => {
    setContactNameSearch(ev.target.value);
  }

  const onContactNoSearchChanged = (ev) => {
    setContactNoSearch(ev.target.value);
  }

  const onCustomerNameSearchChanged = (ev) => {
    setCustomerNameSearch(ev.target.value);
  }

  const onRegionTermSearchChanged = (ev, regionTerm, prevRegionTerm) => {
    if (regionTerm.trim() === '') {
      setRegionTermSearch('');
      setCityIdSearch(null);
      setTownshipIdSearch(null);
      return false;
    }
    if (regionTerm === prevRegionTerm) {
      return false;
    }
    setRegionTermSearch(regionTerm);
    setCityIdSearch(null);
    setTownshipIdSearch(null);
    return true;
  }

  const onRegionCityClicked = (ev, city) => {
    setRegionTermSearch(city['name']);
    setCityIdSearch(city['id']);
    setTownshipIdSearch(null);
  }

  const onRegionTownshipClicked = (ev, township) => {
    setRegionTermSearch(formatTownshipDisplay(township));
    setCityIdSearch(township['cityId']);
    setTownshipIdSearch(township['id']);
  }

  const formatTownshipDisplay = (township) => {
    if (!township) return '';
    return `${township['cityName']} / ${township['name']}`;
  }

  const onSourceSearchChanged = (sourceId) => {
    setSourceSearch(sourceId);
  }

  const onBusinessTypeSearchChanged = (businessTypeId) => {
    setBusinessTypeSearch(businessTypeId);
  }

  const onDateFromSearchChanged = (fromDate) => {
    setDateFromSearch(fromDate);
  }

  const onDateToSearchChanged = (toDate) => {
    setDateToSearch(toDate);
  }

  const onGensetModelTermSearchChanged = (ev, gensetModelTerm, prevGensetModelTerm) => {
    if (gensetModelTerm.trim() === '') {
      setGensetModelTermSearch('');
      setGensetModelIdSearch('');
      return false;
    }
    if (gensetModelTerm === prevGensetModelTerm) {
      return false;
    }
    setGensetModelTermSearch(gensetModelTerm);
    setGensetModelIdSearch('');
    return true;
  }

  const onGensetModelSuggestionClicked = (ev, generator) => {
    setGensetModelTermSearch(generator['gensetModel']);
    setGensetModelIdSearch(generator.id);
  }

  const onFollowUpStageSearchChanged = (ev) => {
    let _stageId = parseInt(ev.target.value);
    setFollowUpStageSearch(_stageId);
    setFollowUpActionSearch('0');
  }

  const onFollowUpActionSearchChanged = (ev) => {
    let _actionId = ev.target.value;
    setFollowUpActionSearch(_actionId);
  }

  const onReporterSearchChanged = (reporterId) => {
    setReporterSearch(reporterId);
  }

  const onFirstContactSearchChanged = (ev) => {
    let newFirstContact = !isFirstContactSearch;
    setIsFirstContactSearch(newFirstContact);
  }

  const onIsHistoryIncludedSearchChanged = (ev) => {
    let newIsHistoryIncluded = !isHistoryIncludedSearch;
    setIsHistoryIncludedSearch(newIsHistoryIncluded);
  }

  const onEstimatePoDateFromSearchChanged = (estimatePoDateFrom) => {
    setEstimatePoDateFromSearch(estimatePoDateFrom);
  }

  const onEstimatePoDateToSearchChanged = (estimatePoDateTo) => {
    setEstimatePoDateToSearch(estimatePoDateTo);
  }

  const minTodayDate = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  const validateSearchDateRanges = () => {
    let validation = {
      hasErrors: false,
      errorMessage: '',
    };

    let _fromDate = dateFromSearch;
    let _toDate = dateToSearch;
    if (_fromDate && _toDate) {
      if (_toDate < _fromDate) {
        validation.hasErrors = true;
        validation.errorMessage = 'Invalid date range.';
      }
    }
    if (validation.hasErrors) {
      return validation;
    }

    let _estimatePoFromDate = estimatePoDateFromSearch;
    let _estimatePoToDate = estimatePoDateToSearch;
    if (_estimatePoFromDate && _estimatePoToDate) {
      if (_estimatePoToDate < _estimatePoFromDate) {
        validation.hasErrors = true;
        validation.errorMessage = 'Invalid estimate date range.';
      }
      if (_estimatePoFromDate < minTodayDate() || _estimatePoToDate < minTodayDate()) {
        validation.hasErrors = true;
        validation.errorMessage = 'Either one of Estimate PO dates cannot be earlier than "today".';
      }
    }
    if (validation.hasErrors) {
      return validation;
    }

    if (_fromDate && !_toDate) {
      _toDate = new Date();
    }
    if (!_fromDate && _toDate) {
      // set the first day of the current 'to date' month
      _fromDate = new Date(_toDate.getFullYear(), _toDate.getMonth(), 1);
    }
    validation.fromDate = _fromDate;
    validation.toDate = _toDate;

    if (_estimatePoFromDate && !_estimatePoToDate) {
      _estimatePoToDate = new Date();
    }
    if (!_estimatePoFromDate && _estimatePoToDate) {
      let today = minTodayDate();
      if (today.getMonth() === _estimatePoToDate.getMonth()) {
        // 'from date' should be today's date if the 'to date' falls under the current month
        _estimatePoFromDate = new Date(_estimatePoToDate.getFullYear(), _estimatePoToDate.getMonth(), today.getDate());
      }
      else {
        // otherwise, set the first day of the 'to date's month to 'from date'
        _estimatePoFromDate = new Date(_estimatePoToDate.getFullYear(), _estimatePoToDate.getMonth(), 1);
      }
    }
    validation.estimatePoFromDate = _estimatePoFromDate;
    validation.estimatePoToDate = _estimatePoToDate;

    return validation;
  }

  const validateAndPrepareSearchOptions = () => {
    let dateValidation = validateSearchDateRanges();
    if (dateValidation.hasErrors) {
      setHasSearchErrors(true);
      setSearchErrorMessage(dateValidation.errorMessage);
      return null;
    }
    if (isFirstContactSearch && dateValidation.fromDate == null && dateValidation.toDate == null) {
      setHasSearchErrors(true);
      setSearchErrorMessage('Date range needs to be specified when trying to filter the first contacted inquiries.');
      return null;
    }

    let searchOptions = {
      'contact_name': contactNameSearch,
      'contact_no': contactNoSearch,
      'customer_name': customerNameSearch,
      'source_id': sourceSearch,
      'business_type_id': businessTypeSearch,
      'followup_stage': followUpStageSearch,
      'followup_action': followUpActionSearch,
      'reporter_id': reporterSearch,
      'is_first_contact': isFirstContactSearch,
      'is_history_included': isHistoryIncludedSearch,
    }

    if (cityIdSearch) {
      searchOptions['city_id'] = cityIdSearch;
      if (townshipIdSearch) {
        searchOptions['township_id'] = townshipIdSearch;
      }
    }
    if (!dateValidation.hasErrors && (dateValidation.fromDate && dateValidation.toDate)) {
      setDateFromSearch(dateValidation.fromDate);
      setDateToSearch(dateValidation.toDate);
      searchOptions['date_from'] = dateValidation.fromDate.toLocaleString();
      searchOptions['date_to'] = dateValidation.toDate.toLocaleString();
    }
    if (gensetModelIdSearch) {
      searchOptions['genset_model_id'] = gensetModelIdSearch;
    }
    if (!dateValidation.hasErrors && (dateValidation.estimatePoFromDate && dateValidation.estimatePoToDate)) {
      setEstimatePoDateFromSearch(dateValidation.estimatePoFromDate);
      setEstimatePoDateToSearch(dateValidation.estimatePoToDate);
      searchOptions['estimate_po_date_from'] = dateValidation.estimatePoFromDate.toLocaleString();
      searchOptions['estimate_po_date_to'] = dateValidation.estimatePoToDate.toLocaleString();
    }

    return searchOptions;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;

    let searchOptionsContext = {
      ...searchOptions,
      'region_term': regionTermSearch,
      'genset_model_term': gensetModelTermSearch,
    }
    saveSearchPanelOptionsContext(contexts.salesInquiries, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    };
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);
    setIsLoading(true);
    salesServices.inquiryList(listPayload)
      .then((response) => {
        setIsSearching(true);
        updateListingStates(response.data);
      })
      .catch((error) => {
        setIsSearching(false);
        setIsLoading(false);
      })
      .finally(() => {
        setIsSearchLoading(false);
        setIsLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const resetSearchPanelFields = () => {
    setIsSearching(false);
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    setContactNameSearch('');
    setContactNoSearch('');
    setCustomerNameSearch('');
    setRegionTermSearch('');
    setCityIdSearch(null);
    setTownshipIdSearch(null);
    setSourceSearch(0);
    setBusinessTypeSearch(0);
    setDateFromSearch(null);
    setDateToSearch(null);
    setGensetModelTermSearch('');
    setGensetModelIdSearch('');
    setFollowUpStageSearch(0);
    setFollowUpActions([]);
    setFollowUpActionSearch('0');
    setReporterSearch('');
    setIsFirstContactSearch(false);
    setIsHistoryIncludedSearch(false);
    setEstimatePoDateFromSearch(null);
    setEstimatePoDateToSearch(null);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.salesInquiries);
    resetSearchPanelFields();
    fetchInquiryList(1);
  }

  const onExcelExportButtonClicked = (ev) => {
    let listOptionsPayload = prepareListPayload(1);

    if (isSearching) {
      let searchOptions = validateAndPrepareSearchOptions();
      if (searchOptions === null) return;
      listOptionsPayload['is_searching'] = isSearching;
      listOptionsPayload['search_options'] = searchOptions;
    }

    setIsExporting(true);
    salesServices.postInquiryListExport(listOptionsPayload)
      .then((response) => {
        doVirtualDownload(response, 'SalesInquiries', 'xlsx');
      })
      .catch((error) => {
        console.error(error['response']);
      })
      .finally(() => setIsExporting(false));
  }
  //#endregion

  //#region View Settings modal; handlers and utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    } else {
      fetchInquiryList(1);
    }
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className="content-container sales-inquiries-container">
        <div className="content-area">
          <div className="row">
            <Breadcrumbs>
              <BreadcrumbItem text={routes.salesInquiriesList.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>Sales Inquiries</h1>
            <div className="listing-controls">
              <button className={"btn btn-secondary"} disabled={isLoading || isSearchLoading}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              {listPermissions && listPermissions.canAdd &&
                <button className="btn btn-primary btn-md" disabled={isLoading || isSearchLoading} onClick={onNewEntryClicked}>
                  <i className="fa-solid fa-plus"></i>
                  New Inquiry
                </button>
              }
              <button type={"button"} className={"btn btn-secondary"} disabled={isUpdateRequestsLoading || isLoading || isSearchLoading}
                      onClick={updateRequestsModal.onOpenButtonClicked}>
                {isUpdateRequestsLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isUpdateRequestsLoading && <i className="fa-solid fa-pen"></i>}
                <span>Update Requests...</span>
              </button>

              <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isUpdateRequestsLoading}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button>
            </div>
          </div>

          <SalesInquirySearchPanel isSearchOpen={isSearchOpen} isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                   followUpStages={followUpStages} followUpActions={followUpActions}
                                   contactName={contactNameSearch} onContactNameChanged={onContactNameSearchChanged}
                                   contactNo={contactNoSearch} onContactNoChanged={onContactNoSearchChanged}
                                   customerName={customerNameSearch} onCustomerNameChanged={onCustomerNameSearchChanged}
                                   cityId={cityIdSearch} townshipId={townshipIdSearch}
                                   regionTerm={regionTermSearch} onRegionTermChanged={onRegionTermSearchChanged}
                                   onRegionSuggestionCityClicked={onRegionCityClicked} onRegionSuggestionTownshipClicked={onRegionTownshipClicked}
                                   source={sourceSearch} onSourceChanged={onSourceSearchChanged}
                                   businessType={businessTypeSearch} onBusinessTypeChanged={onBusinessTypeSearchChanged}
                                   dateFrom={dateFromSearch} onDateFromChanged={onDateFromSearchChanged}
                                   dateTo={dateToSearch} onDateToChanged={onDateToSearchChanged}
                                   gensetModelId={gensetModelIdSearch} gensetModel={gensetModelTermSearch}
                                   onGensetModelChanged={onGensetModelTermSearchChanged} onGensetModelSuggestionClicked={onGensetModelSuggestionClicked}
                                   followUpStage={followUpStageSearch} onFollowUpStageChanged={onFollowUpStageSearchChanged}
                                   followUpAction={followUpActionSearch} onFollowUpActionChanged={onFollowUpActionSearchChanged}
                                   reporter={reporterSearch} onReporterChanged={onReporterSearchChanged}
                                   isFirstContact={isFirstContactSearch} onIsFirstContactChanged={onFirstContactSearchChanged}
                                   isHistoryIncluded={isHistoryIncludedSearch} onIsHistoryIncludedChanged={onIsHistoryIncludedSearchChanged}
                                   estimatePoDateFrom={estimatePoDateFromSearch} onEstimatePoDateFromChanged={onEstimatePoDateFromSearchChanged}
                                   estimatePoDateTo={estimatePoDateToSearch} onEstimatePoDateToChanged={onEstimatePoDateToSearchChanged}
                                   onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                   onExportExcelClicked={onExcelExportButtonClicked} isExporting={isExporting}
                                   hasErrors={hasSearchErrors} errorMessage={searchErrorMessage}
          />

          <InquiryUpdateRequestsModal isOpen={isUpdateRequestsModalOpen} isLoading={isUpdateRequestsLoading}
                                      onRequestClose={updateRequestsModal.onCloseButtonClicked}
                                      inquiryUpdateRequests={inquiryUpdateRequests}
                                      onUpdateRequestRowClicked={onUpdateRequestRowClicked}
          />

          <div className="data-table inquiry-table">
            <table>
              <thead>
              <tr>
                <th scope="col" className="index-col-head">#</th>
                <th scope="col">Contact Name</th>
                <th scope="col">Contact No.</th>
                <th scope="col">City/Township</th>
                <th scope="col">Source</th>
                <th scope="col">Business Type</th>
                <th scope="col">Date</th>
                <th scope="col">Genset Models</th>
                <th scope="col">Visit/Call</th>
                <th scope="col">Follow Up Action</th>
                <th scope="col">Reporter</th>
              </tr>
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={11} />}

              {inquiries && inquiries.length > 0 && !isLoading &&
                inquiries.map((inquiry, index) =>
                  <SalesInquiryRow key={inquiry["entryId"]} inquiry={inquiry} index={index}
                                   currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                   onRowClicked={onRowClicked}
                                   allowExpandableAction={true}
                  />
                )
              }

              {!inquiries || (inquiries.length === 0 && !isLoading &&
                <TableEmptyRow colSpan={11} />)
              }
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.salesInquiries} onViewSettingsSaved={onViewSettingsSaved}
              />
            }
          </div>
        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion

}


export const SalesInquiryRow = ({
                                  inquiry, index,
                                  currentPage, pageSize,
                                  onRowClicked,
                                  allowExpandableAction,
                                }) => {
  const [isActionExpanded, setIsActionExpanded] = useState(false);
  const [isActionExpanding, setIsActionExpanding] = useState(false);
  const [inquiryHistory, setInquiryHistory] = useState([]);
  const [isHistoryCached, setIsHistoryCached] = useState(false);

  const onExpandableActionClicked = (ev) => {
    ev.preventDefault();
    fetchInquiryHistory(inquiry.entryId);
  }

  const fetchInquiryHistory = (selectedEntryId) => {
    if (isActionExpanded) {
      setIsActionExpanded(false);
      return;
    }
    if (isHistoryCached) {
      setIsActionExpanded(true);
      return;
    }

    setIsActionExpanding(true);
    salesServices.fetchInquiryEntryHistory(selectedEntryId)
      .then((response) => {
        setInquiryHistory(response.data.data);
        setIsActionExpanded(true);
        setIsHistoryCached(true);
      })
      .finally(() => {
        setIsActionExpanding(false);
      });
  }

  //#region Render
  return (
    <>
      <tr>
        <td className={"index-col"}>{(pageSize * (currentPage - 1)) + (index + 1)}</td>
        <td className={"fullname-col"}>
          <a href="#" role={"button"} className={"record-link"}
             onClick={(ev) => onRowClicked(ev, inquiry)}>
            {inquiry.contactFullName}
          </a>
        </td>
        <td className={"contact-no-col"}>
          {inquiry.contactNo}
        </td>
        <td className={"city-township-col"}>
          {getRegionDisplay(inquiry['city'], inquiry['township'])}
        </td>
        <td className={"source-col"}>
          <span title={inquiry['sourceDisplay']['name']}>
            {inquiry['sourceDisplay']['code']}
          </span>
        </td>
        <td className={"business-type-col"}>
          {inquiry.businessType !== null ? inquiry.businessType.name : "-"}
        </td>
        {allowExpandableAction && inquiry['historyCount'] > 1 &&
          <ExpandableInquiryDatetime isExpanded={isActionExpanded} isExpanding={isActionExpanding}
                                     inquiry={inquiry}
                                     onActionClicked={onExpandableActionClicked}
          />
        }
        {(!allowExpandableAction || inquiry['historyCount'] === 1) &&
          <td className={"date-col"}>
            <Moment date={inquiry['lastEntryDatetime']} format={formatters.datetimeShort} />
          </td>
        }
        <td className={"genset-model-col"}>
          <GeneratorsSummary generators={inquiry['lastEntryGenerators']} />
        </td>
        <td className={"source-col"}>
          {inquiry['lastEntryContactFormDisplay']}
        </td>
        <td className={"followup-col"}>
          {getFollowUpStageActionDisplay(inquiry['lastEntryFollowUpStage'], inquiry['lastEntryFollowUpAction'])}
        </td>
        <td className={"reporter-col"}>
          <ActorNameDisplay employee={inquiry['lastEntryReporter']} />
        </td>
      </tr>
      {inquiryHistory && inquiryHistory.length > 0 && isActionExpanded &&
        inquiryHistory.filter((history) => inquiry['lastEntryId'] !== history['followUpEntryId']).map((history) =>
          <tr key={history['followUpEntryId']}>
            <td colSpan={6}></td>
            <td>
              <Moment date={history['followUpEntryDatetime']} format={formatters.datetimeShort} />
            </td>
            <td>
              <GeneratorsSummary generators={history['generators']} />
            </td>
            <td>
              {history['contactFormDisplay']}
            </td>
            <td>
              {getFollowUpStageActionDisplay(history['followUpStage'], history['followUpAction'])}
            </td>
            <td>
              <ActorNameDisplay employee={history['followUpReporter']} />
            </td>
          </tr>
        )
      }
    </>
  )
  //#endregion
}


const ExpandableInquiryAction = ({
                                   isExpanded, isExpanding,
                                   inquiry,
                                   onActionClicked,
                                 }) => {
  return (
    <td className={isExpanding ? "expandable-action action-expanding" : "expandable-action"}>
      <a href={"#"} role={"button"} onClick={onActionClicked}>
        <span>
          {getFollowUpStageActionDisplay(inquiry['lastEntryFollowUpStage'], inquiry['lastEntryFollowAction'])}
        </span>
        {isExpanding && <i className="fa-solid fa-circle-notch fa-spin"></i>}
        {!isExpanding && !isExpanded && <i className="fa-solid fa-square-plus"></i>}
        {!isExpanding && isExpanded && <i className="fa-solid fa-square-minus"></i>}
      </a>
    </td>
  )
}


const ExpandableInquiryDatetime = ({
                                     isExpanded, isExpanding,
                                     inquiry,
                                     onActionClicked,
                                   }) => {
  return (
    <td className={isExpanding ? "date-col expandable-action action-expanding" : "date-col expandable-action"}>
      <a href={"#"} role={"button"} onClick={onActionClicked}>
        <span>
          <Moment date={inquiry.lastEntryDatetime} format={formatters.datetimeShort} />
        </span>
        {isExpanding && <i className="fa-solid fa-circle-notch fa-spin"></i>}
        {!isExpanding && !isExpanded && <i className="fa-solid fa-square-plus"></i>}
        {!isExpanding && isExpanded && <i className="fa-solid fa-square-minus"></i>}
      </a>
    </td>
  )
}
