import React from "react";
import Moment from "react-moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";
import { DEFAULT_LIST_PAGE_SIZE } from "../../repos/constants";
import { authService } from "../../repos/apiServices";
import { inventoryServices } from "../../repos/apiServices";
import { getRegionDisplay } from "../../repos/utilities";
import { contexts } from "../../repos/viewContexts";
import { viewSettings } from "../../repos/viewContexts";
import { getViewSettings } from "../../repos/viewContexts";
import { getPaginationContext } from "../../repos/viewContexts";
import { savePaginationContext } from "../../repos/viewContexts";
import { getSearchPanelOpenContext } from "../../repos/viewContexts";
import { saveSearchPanelOpenContext } from "../../repos/viewContexts";
import { getSearchPanelOptionsContext } from "../../repos/viewContexts";
import { saveSearchPanelOptionsContext } from "../../repos/viewContexts";
import { clearSearchPanelOptionsContext } from "../../repos/viewContexts";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ServiceProfilesSearchPanel } from "./ServiceProfilesSearchPanel";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { TablePagination } from "../shared/TablePagination";
import { NullBlankField } from "./NullBlankField";
import { ServiceProfileStatus } from "./ServiceProfileStatus";
import { SortIndicator } from "../shared/SortIndicator";

import "../shared/DataTable.css";
import "../shared/ListingPage.css";
import "./ServiceProfilesListPage.css";


export function ServiceProfilesListPage() {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [serviceProfiles, setServiceProfiles] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [servicePermissions, setServicePermissions] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    let paginationContext = getPaginationContext(contexts.serviceProfiles);
    let searchOptionsContext = loadSearchPanelContexts();
    if (searchOptionsContext !== null) {
      triggerSearchByContext(searchOptionsContext, paginationContext);
    }
    else {
      fetchCustomerServiceProfiles(paginationContext ? paginationContext['currentPage'] : 1);
    }
    //resetSearchPanelFields();
    fetchListPermissions();
  }, []);

  const fetchListPermissions = () => {
    authService.fetchUserPermissions(auth.getUserId())
      .then((response) => {
        let _servicePermissions = response.data['service'];
        setServicePermissions(_servicePermissions);
      });
  }

  const prepareListPayload = (page) => {
    let settings = getViewSettings(viewSettings.serviceProfiles);
    return {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
        'page_size': settings ? settings['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      }
    };
  }

  const fetchCustomerServiceProfiles = (page) => {
    resetListingStates();
    setIsLoading(true);

    let payload = prepareListPayload(page);
    payload['summarize_generators'] = true;
    inventoryServices.searchCustomersForProfile(payload)
      .then((response) => {
        let responseData = response['data'];
        updateListingStates(responseData);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  const updateListingStates = (responseData) => {
    setServiceProfiles(responseData['data']);
    setPagination(responseData['pagination']);
    setSorting(responseData['sorting']);
    savePaginationContext(contexts.serviceProfiles, responseData['pagination']);
  }

  const resetListingStates = () => {
    setServiceProfiles([]);
    setPagination(null);
    setSorting(null);
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    resetSearchPanelFields();
    setIsSearchOpen(false);
    saveSearchPanelOpenContext(contexts.serviceProfiles, false);

    let paginationContext = getPaginationContext(contexts.serviceProfiles);
    fetchListPermissions();
    fetchCustomerServiceProfiles(paginationContext ? paginationContext['currentPage'] : 1);
  }

  const onNewServiceClicked = (ev) => {
    navigate(routes.newServiceProfile.url);
  }

  const onProfileRowClicked = (ev, profile) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': profile['id'],
          'serviceProfileId': profile['profileId'],
        }
      });
    }, 200);
  }

  const onPageClick = (page) => {
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchCustomerServiceProfiles(page);
    }
  }

  const onPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchCustomerServiceProfiles(page);
    }
  }

  const onNextPageClicked = (fromPage) => {
    let page = Math.min(pagination['totalPages'], fromPage + 1);
    if (isSearching) {
      validateAndTriggerSearch(page);
    }
    else {
      fetchCustomerServiceProfiles(page);
    }
  }
  //#endregion

  //#region States; Search
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // flag to indicate whether the last post back was a search trigger

  const [searchFields, setSearchFields] = useState({});

  const [hasSearchErrors, setHasSearchErrors] = useState(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  //#endregion

  //#region Control handlers; Search panel
  const onSearchToggleClicked = () => {
    let _isOpen = !isSearchOpen;
    setIsSearchOpen(_isOpen);
    saveSearchPanelOpenContext(contexts.serviceProfiles, _isOpen);
  }

  const validateAndPrepareSearchOptions = () => {
    // NOTE(yemon): There isn't really anything to validate for now...
    return searchFields;
  }

  const validateAndTriggerSearch = (page) => {
    setHasSearchErrors(false);
    setSearchErrorMessage('');

    let listPayload = prepareListPayload(page);
    let searchOptions = validateAndPrepareSearchOptions();
    if (searchOptions === null) return;
    listPayload['is_searching'] = true;
    listPayload['search_options'] = searchOptions;
    listPayload['summarize_generators'] = true;

    let searchOptionsContext = {
      ...searchOptions,
    };
    saveSearchPanelOptionsContext(contexts.serviceProfiles, searchOptionsContext);

    triggerSearch(listPayload);
  }

  const triggerSearchByContext = (searchOptionsContext, paginationContext) => {
    let listPayload = {
      ...prepareListPayload(1),   // page number here doesn't matter, will be overridden by the pagination context below
      'summarize_generators': true,
      'is_searching': true,
      'search_options': searchOptionsContext,
      'pagination': {
        'current_page': paginationContext ? paginationContext['currentPage'] : 1,
        'page_size': paginationContext ? paginationContext['pageSize'] : DEFAULT_LIST_PAGE_SIZE,
      },
    };
    triggerSearch(listPayload);
  }

  const triggerSearch = (listPayload) => {
    resetListingStates();
    setIsSearchLoading(true);
    setIsLoading(true);

    inventoryServices.searchCustomersForProfile(listPayload)
      .then((response) => {
        setIsSearching(true);
        let responseData = response['data'];
        updateListingStates(responseData);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
      })
      .finally(() => {
        setIsSearchLoading(false);
        setIsLoading(false);
      });
  }

  const onSearchButtonClicked = (ev) => {
    validateAndTriggerSearch(1);
  }

  const onClearButtonClicked = (ev) => {
    clearSearchPanelOptionsContext(contexts.serviceProfiles);
    fetchCustomerServiceProfiles(1);
    resetSearchPanelFields();
  }

  const resetSearchPanelFields = () => {
    setSearchFields({
      'customer_name': '',
      'business_type_id': -1,
      'generator_serial': '',
    });
  }

  const onCustomerNameSearchChanged = (ev) => {
    setSearchFields({
      ...searchFields,
      'customer_name': ev.target.value,
    });
  }

  const onBusinessTypeSearchChanged = (businessTypeId) => {
    setSearchFields({
      ...searchFields,
      'business_type_id': businessTypeId,
    });
  }

  const onGeneratorSerialSearchChanged = (ev) => {
    setSearchFields({
      ...searchFields,
      'generator_serial': ev.target.value,
    });
  }
  //#endregion

  //#region View Settings modal; handlers and utilities
  const onViewSettingsSaved = () => {
    if (isSearching) {
      validateAndTriggerSearch(1);
    }
    else {
      fetchCustomerServiceProfiles(1);
    }
  }
  //#endregion

  //#region Listing view context
  const loadSearchPanelContexts = () => {
    let isSearchPanelOpen = getSearchPanelOpenContext(contexts.serviceProfiles);
    setIsSearchOpen(isSearchPanelOpen);

    let searchOptions = getSearchPanelOptionsContext(contexts.serviceProfiles);
    if (searchOptions === null) {
      return null;
    }

    setSearchFields({
      'customer_name': searchOptions['customer_name'],
      'business_type_id': searchOptions['business_type_id'],
      'generator_serial': searchOptions['generator_serial'],
    });

    return searchOptions;
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container service-profiles-list-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} isActive={true} hasTailDivider={true} />
            </Breadcrumbs>
          </div>

          <div className="row">
            <h1>{routes.serviceProfiles.display}</h1>
            <div className={"listing-controls"}>
              <button className={"btn btn-secondary"} disabled={isLoading || isSearchLoading}
                      onClick={onRefreshClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-rotate"></i>}
                Refresh
              </button>
              <button className={"btn btn-primary"} disabled={isLoading || isSearchLoading}
                      onClick={onNewServiceClicked}>
                {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
                {!isLoading && <i className="fa-solid fa-plus"></i>}
                New Service Profile
              </button>

              <button type={"button"} className={"btn btn-secondary search-toggle-button"} disabled={isLoading || isSearchLoading}
                      onClick={onSearchToggleClicked}>
                {isSearchOpen && <i className="fa-solid fa-magnifying-glass-minus"></i>}
                {!isSearchOpen && <i className="fa-solid fa-magnifying-glass-plus"></i>}
                <span>Search</span>
              </button>
            </div>
          </div>

          <ServiceProfilesSearchPanel isSearchOpen={isSearchOpen} isSearchLoading={isSearchLoading} isListLoading={isLoading}
                                      searchFields={searchFields}
                                      onCustomerNameChanged={onCustomerNameSearchChanged}
                                      onBusinessTypeChanged={onBusinessTypeSearchChanged}
                                      onGeneratorSerialChanged={onGeneratorSerialSearchChanged}
                                      onSearchClicked={onSearchButtonClicked} onClearClicked={onClearButtonClicked}
                                      hasErrors={hasSearchErrors} errorMessage={searchErrorMessage} />

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"} className={"customer-name-head"}>
                  Customer Name
                  <SortIndicator />
                </th>
                <th scope={"col"}>Company Name</th>
                <th scope={"col"} className={"business-type-head"}>Business Type</th>
                <th scope={"col"}>Contact Name</th>
                <th scope={"col"}>Profile Active Since</th>
                <th scope={"col"}>Commissioned Generators</th>
              </tr>
              </thead>
              <tbody>
              {isLoading && <TableLoadingIndicator colspan={8} />}

              {serviceProfiles && serviceProfiles.length > 0 && !(isLoading) &&
                serviceProfiles.map((profile, index) =>
                  <ServiceProfileRow key={profile['profileId']} profile={profile} index={index}
                                     currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                                     onProfileRowClicked={onProfileRowClicked}
                  />
                )
              }

              {!serviceProfiles || (serviceProfiles.length === 0 && !isLoading &&
                  <TableEmptyRow colSpan={8} />
              )}
              </tbody>
            </table>
            {pagination &&
              <TablePagination currentPage={pagination['currentPage']} pageSize={pagination['pageSize']}
                               totalPages={pagination['totalPages']} totalRecords={pagination['totalRecords']}
                               onPageClicked={onPageClick}
                               onPrevPageClicked={onPrevPageClicked}
                               onNextPageClicked={onNextPageClicked}
                               isLoading={isLoading}
                               viewSettingsNamespace={viewSettings.serviceProfiles}
                               onViewSettingsSaved={onViewSettingsSaved}
              />
            }
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const ServiceProfileRow = ({
                             profile, index,
                             currentPage, pageSize,
                             onProfileRowClicked,
                           }) => {
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);
  const [isProfileExpanding, setIsProfileExpanding] = useState(false);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [isCached, setIsCached] = useState(false);

  const auth = useAuth();

  const onExpandActionClicked = (ev) => {
    ev.preventDefault();
    fetchServiceGeneratorsOfProfile(profile['profileId']);
  }

  const fetchServiceGeneratorsOfProfile = (profileId) => {
    if (isProfileExpanded) {
      setIsProfileExpanded(false);
      return;
    }
    if (isCached) {
      setIsProfileExpanded(true);
      return;
    }

    setIsProfileExpanding(true);
    inventoryServices.fetchServiceProfileGenerators(profileId)
      .then((response) => {
        let _responseData = response['data'];
        setServiceLocations(_responseData);
        setIsProfileExpanded(true);
        setIsCached(true);
      })
      .catch((error) => {
        let errorResponse = error['response'];
        console.error(errorResponse);
        setIsProfileExpanded(false);
      })
      .finally(() => {
        setIsProfileExpanding(false);
      });
  }

  const getRowSerial = () => {
    return (pageSize * (currentPage - 1)) + (index + 1);
  }

  return (
    <>
      <tr className={isProfileExpanded ? "header-expanded" : ""}>
        <td className={"index-col"}>{getRowSerial()}</td>
        <td className={"primary-col"}>
          <a href={"#"} role={"button"} className={"record-link"}
             onClick={(ev) => onProfileRowClicked(ev, profile)}>
            {profile['customerName']}
          </a>
        </td>
        <td className={"company-name-col"}>
          <NullBlankField record={profile} fieldName={"companyName"} />
        </td>
        <td className={"business-type-col"}>
          {profile['businessTypeName']}
        </td>
        <td>
          {profile['contactFullName']}
        </td>
        <td>
          <span title={"Service Profile activation date"}>
            <Moment date={profile['profileActiveDatetime']} format={formatters.datetimeShort} />
          </span>
          {/*<ServiceProfileStatus profileStatus={profile['profileStatus']}*/}
          {/*                      profileActiveDatetime={profile['profileActiveDatetime']}*/}
          {/*                      profileInactiveDatetime={profile['profileInactiveDatetime']} />*/}
        </td>
        <ExpandableGeneratorsSummary isExpanded={isProfileExpanded} isExpanding={isProfileExpanding}
                                     generatorsSummary={profile['generatorsSummary']}
                                     onActionClicked={onExpandActionClicked}
        />
      </tr>

      <ExpandedGeneratorRows profile={profile} serviceLocations={serviceLocations}
                             isProfileExpanded={isProfileExpanded}
                             rowSerial={getRowSerial()}
      />
    </>
  )
}


const commissionTypes = refs.inventory.serviceGeneratorCommissionType;

const ExpandableGeneratorsSummary = ({
                                       isExpanded, isExpanding,
                                       generatorsSummary,
                                       onActionClicked,
                                     }) => {
  const hasSummary = () => {
    return generatorsSummary !== null;
  }

  const getSummaryLabel = () => {
    if (generatorsSummary === null) return '';

    const maxSummaryPrefix = 2;
    let summaryCount = 0;
    let summaryTexts = [];
    for (const [key, value] of Object.entries(generatorsSummary)) {
      if (summaryCount <= maxSummaryPrefix) {
        summaryTexts.push(`${value} ${commissionTypes[key]}`);
      }
      summaryCount += 1;
    }
    if (summaryCount > maxSummaryPrefix) {
      summaryTexts.push(`${summaryCount - maxSummaryPrefix} more`);
    }
    return summaryTexts.join(", ");
  }

  if (!generatorsSummary) {
    return (
      <td>-</td>
    )
  }
  else {
    return (
      <td className={isExpanding ? "expandable-action action-expanding" : "expandable-action"}>
        <a href={"#"} role={"button"} onClick={onActionClicked}>
        <span title={"Summary of commissioned generators"}>
          {getSummaryLabel()}
        </span>
          {hasSummary() &&
            <span>
              {isExpanding && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isExpanding && !isExpanded && <i className="fa-solid fa-square-plus"></i>}
              {!isExpanding && isExpanded && <i className="fa-solid fa-square-minus"></i>}
            </span>
          }
        </a>
      </td>
    )
  }
}


const ExpandedGeneratorRows = ({
                                 profile, serviceLocations, isProfileExpanded, rowSerial,
                               }) => {
  const [allServiceGenerators, setAllServiceGenerators] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    combineServiceGenerators();
  }, [serviceLocations]);

  const combineServiceGenerators = () => {
    if (!serviceLocations || serviceLocations.length === 0) {
      return [];
    }

    let allGenerators = [];
    for (const serviceLocation of serviceLocations) {
      const generators = serviceLocation['generators'];
      if (!generators || generators.length === 0) {
        continue;
      }

      let locationTemp = { ...serviceLocation };
      delete locationTemp['generators'];
      for (const generator of generators) {
        generator['serviceLocation'] = { ...locationTemp };
      }

      allGenerators = allGenerators.concat(generators);
    }
    setAllServiceGenerators(allGenerators);
  }

  const onGeneratorRowClicked = (ev, generator) => {
    setTimeout(() => {
      navigate(routes.serviceGenerator.url, {
        state: {
          'generatorId': generator['id'],
          'profileId': profile['profileId'],
          'customerId': profile['id'],
        }
      });
    }, 200);
  }

  return (
    <>
      {allServiceGenerators && allServiceGenerators.length > 0 && isProfileExpanded &&
        allServiceGenerators.map((generator, index) =>
          <tr key={generator['id']} className={"sub-row"}>
            <td className={"index-col"}>
              {`${rowSerial}.${index + 1}`}
            </td>
            <td>
              <span title={"Generator serial"}>
                <a href={"#"} role={"button"} className={"record-link"}
                   onClick={(ev) => onGeneratorRowClicked(ev, generator)}>
                  {generator['stock']['generatorSerial']}
                </a>
              </span>
            </td>
            <td>
              <span title={"Genset model"}>
                {generator['stock']['generator']['gensetModel']}
              </span>
            </td>
            <td colSpan={2}>
              <span title={"Location"}>{generator['serviceLocation']['name']}</span>&nbsp;-&nbsp;
              <span title={"City / Township"}>{getRegionDisplay(generator['serviceLocation']['city'], generator['serviceLocation']['township'])}</span>
            </td>
            <td>
              <span title={"Service Generator installation date"}>
                <Moment date={generator['installationDate']} format={formatters.datetimeShort} />
              </span>
            </td>
            <td>
              <span title={"Service Generator commission type"}>
                {commissionTypes[generator['commission']['commissionType']]}
              </span>
            </td>
          </tr>
        )
      }
    </>
  )
}
