import React from "react";
import { useRef } from "react";

import "./PageAlert.css";

export const PageAlert = ({
                            visible, type,
                            onDismissButtonClicked,
                            children
                          }) => {
  const alertRef = useRef();

  const getAlertClassName = () => {
    let _classes = "page-alert";
    switch (type) {
      case alertTypes.info:
        _classes += " info";
        break;
      case alertTypes.success:
        _classes += " success";
        break;
      case alertTypes.warning:
        _classes += " warning";
        break;
      case alertTypes.error:
        _classes += " error";
        break;
      default:
        _classes += " info";
        break;
    }
    _classes += (visible ? " show" : "");
    return _classes;
  }

  const onDismissClicked = (ev) => {
    if (alertRef.current) {
      alertRef.current.classList.remove("show");
    }
    if (onDismissButtonClicked) {
      onDismissButtonClicked(ev);
    }
  }

  return (
    <>
      {visible &&
        <div id={"page-alert"} ref={alertRef} className={getAlertClassName()} role={"alert"}>
          <div className={"page-alert-content"}>
            {children}
          </div>
          <button type={"button"} className={"btn-dismiss"}
            onClick={onDismissClicked}>
            <span>x</span>
          </button>
        </div>
      }
    </>
  )
}

/// NOTE(yemon): Import this whenever the `PageAlert` is used to enumerate its `type` prop value.
/// Or hardcode provide the value if you know what they are. Doesn't matter.
export const alertTypes = {
  info: 1,
  success: 2,
  warning: 3,
  error: 4,
}
