import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { refServices } from "../../repos/apiServices2";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";

import "./OptionsField.css";


export const BusinessTypeOptionsField = ({
                                           selectedOptions, setSelectedOptions,
                                           disabled,
                                         }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const onOpenClicked = () => {
    setIsModalOpened(true);
  }

  const onCloseClicked = () => {
    updateButtonLabel();
    setIsModalOpened(false);
  }

  const [isLoading, setIsLoading] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);

  useEffect(() => {
    fetchBusinessTypes();
  }, []);

  useEffect(() => {
    updateButtonLabel();
  }, [selectedOptions])

  const fetchBusinessTypes = () => {
    setIsLoading(true);
    refServices.fetchBusinessTypes()
      .then((response) => {
        setBusinessTypes(response['data']);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [buttonLabel, setButtonLabel] = useState("Select...");

  const updateButtonLabel = () => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setButtonLabel("Select...");
    }
    else if (selectedOptions.length === businessTypes.length) {
      setButtonLabel("All selected");
    }
    else {
      let _label = "";
      let _selectedNames = [];
      let _selectedOptions = [];
      if (selectedOptions) {
        // NOTE(yemon): Check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#comparefn
        // for toSorted(compareFn) semantics.
        _selectedOptions = selectedOptions.toSorted((a, b) => a - b);
      }

      // NOTE(yemon): The button label will only take a max of 3 names into account, (2 name + remaining count)
      // so there is absolutely no need to go through the entire `selectedOptions` array.
      for (let i = 0; i < Math.max(3, _selectedOptions.length); i += 1) {
        const businessType = businessTypes.find((item) => item['id'] === _selectedOptions[i]);
        if (businessType) {
          _selectedNames.push(businessType['name'].trim());
        }
      }

      if (_selectedNames.length === 1) {
        _label = _selectedNames[0];
      }
      else if (_selectedNames.length === 2) {
        _label = `${_selectedNames[0]} + ${_selectedNames[1]}`;
      }
      else if (_selectedNames.length >= 3) {
        _label = `${_selectedNames[0]} + ${_selectedNames[1]} + ${_selectedOptions.length - 2} more`;
      }
      else {
        // NOTE(yemon): Shouldn't happen. Just there to serve as a catch-all solution, just in case.
        _label = `Selected ${_selectedOptions.length} options`;
      }
      setButtonLabel(_label);
    }
  }

  return (
    <div className={"options-field"}>
      <BusinessTypeOptionsModal isOpen={isModalOpened} onRequestClose={onCloseClicked}
                                isLoading={isLoading} businessTypes={businessTypes}
                                selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />

      <button type={"button"} className={"btn btn-secondary"} disabled={disabled || isLoading}
              title={"Click to choose business types to apply."}
              onClick={onOpenClicked}>
        {buttonLabel}
      </button>
    </div>
  )
}

const BusinessTypeOptionsModal = ({
                                    isOpen, onRequestClose,
                                    isLoading, businessTypes,
                                    selectedOptions, setSelectedOptions,
                                  }) => {
  const getDescription = () => {
    let count = 0;
    if (selectedOptions) {
      count = selectedOptions.length;
    }
    return `${count} of ${businessTypes.length} total selected`;
  }

  const isAllSelected = () => {
    let count = 0;
    if (selectedOptions) {
      count = selectedOptions.length;
    }
    return count === businessTypes.length;
  }

  const onSelectAllChecked = (ev, checked) => {
    let _allOptions = [];
    if (checked) {
      for (let item of businessTypes) {
        _allOptions.push(item['id']);
      }
      setSelectedOptions(_allOptions);
    }
    else {
      setSelectedOptions([]);
    }
  }

  const isSelected = (businessTypeId) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return false;
    }
    return selectedOptions.some((id) => id === businessTypeId);
  }

  const onSelectChecked = (ev, businessTypeId, checked) => {
    const alreadySelected = (_id) => {
      return _id === businessTypeId;
    }

    // NOTE(yemon): The many-funsies in the world of JavaScript.
    // Array.slice() "force clone" the entire array into a completely new array.
    let _selectedOptions = selectedOptions.slice();

    if (checked && !_selectedOptions.some(alreadySelected)) {
      _selectedOptions.push(businessTypeId);
      setSelectedOptions(_selectedOptions);
    }

    if (!checked && _selectedOptions.some(alreadySelected)) {
      let existingIndex = _selectedOptions.findIndex(alreadySelected);
      if (existingIndex !== -1) {
        _selectedOptions.splice(existingIndex, 1);
        setSelectedOptions(_selectedOptions);
      }
    }
  }

  return (
    <ModalContainer elementId={"business-type-options-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose}
                    modalSize={modalSizes.tiny} height={720}
                    title={"Select Business Types"}
                    shortDescription={getDescription()}>
      <div className={"modal-data-table options-field-elements"}>
        <table>
          <thead>
          <tr className={"clickable-row"} onClick={(ev) => onSelectAllChecked(ev, !isAllSelected())}>
            <th scope="col" className={"index-col-head fixed-width"}>
              <label htmlFor={"selectAll"}>
                <input type={"checkbox"} id={"selectAll"} name={"selectAll"}
                       title={"Select all business types"}
                       checked={isAllSelected()}
                       onChange={(ev) => onSelectAllChecked(ev, ev.target.checked)} />
              </label>
            </th>
            <th scope="col">Name</th>
          </tr>
          </thead>
          <tbody>
          {isLoading && businessTypes &&
            <TableLoadingIndicator colspan={2} />
          }

          {!isLoading && businessTypes && businessTypes.length > 0 &&
            businessTypes.map((businessType, index) =>
              <BusinessTypesTableRow key={businessType['id']}
                                     businessType={businessType}
                                     isSelected={isSelected} onCheckChanged={onSelectChecked} />
            )
          }

          {!isLoading && businessTypes && businessTypes.length === 0 &&
            <TableEmptyRow colSpan={2} />
          }
          </tbody>
        </table>
      </div>

      <div className={"options-field-margin"}></div>
    </ModalContainer>
  )
}

const BusinessTypesTableRow = ({
                                 businessType,
                                 isSelected, onCheckChanged,
                               }) => {
  return (
    <tr className={"clickable-row"}
        onClick={(ev) => onCheckChanged(ev, businessType['id'], !isSelected(businessType['id']))}>
      <td className={"index-col"}>
        <label htmlFor={`select-business-type-${businessType['id']}`}>
          <input type={"checkbox"} id={`select-business-type-${businessType['id']}}`}
                 name={`select-business-type-${businessType['id']}}`}
                 title={`Select ${businessType['name']}`}
                 checked={isSelected(businessType['id'])}
                 onChange={(ev) => onCheckChanged(ev, businessType['id'], ev.target.checked)} />
        </label>
      </td>
      <td title={`Select ${businessType['name']}`}>
        <label htmlFor={`select-business-type-${businessType['id']}`} className={"no-padding"}>
          {businessType["name"]}
        </label>
      </td>
    </tr>
  )
}
