import React from "react";

import "../shared/StatusBadge.css";

export const StockStatusBadge = ({ status }) => {
  const getBadgeClass = () => {
    let className = "badge";
    switch (status) {
      case 0:
        return className + " badge-draft";
      case 1:
        return className + " badge-pending-review";
      case 2:
        return className + " badge-approved";
      case 3:
        return className + " badge-active";
      case 9:
        return className + " badge-draft";
      default:
        return className + " badge-draft";
    }
  }

  const getBadgeDescription = () => {
    switch (status) {
      case 0:
        return "Draft";
      case 1:
        return "Under Inspection";
      case 2:
        return "Available";
      case 3:
        return "Commissioned";
      case 9:
        return "Inactive";
      default:
        return "(NA)";
    }
  }

  return (
    <span className={getBadgeClass()}>{getBadgeDescription()}</span>
  )
}
