import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

import { useAuth } from "../auth/AuthProvider";
import { navigableModules as modules } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";

import './Navigation.css';


export const Navigation = () => {
  const auth = useAuth();

  if (auth.isLoggedIn) {
    return (
      <nav id={"navbar"} className="side-nav">
        <Modules>
          <ModuleSection>
            <ModuleNavItem associatedRoutes={[routes.dashboard]}
                           route={routes.dashboard} />
          </ModuleSection>

          <AuthModuleSection module={modules.sales} moduleGrants={auth.moduleGrants}>
            <AuthModuleNavItem associatedRoutes={[routes.salesInquiriesList, routes.salesInquiriesEntry]}
                               route={routes.salesInquiriesList}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.quotationRequestsList, routes.quotationRequestEntry]}
                               route={routes.quotationRequestsList}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.orderConfirmationsList, routes.orderConfirmationEntry, routes.orderConfirmationPaymentBreakdown]}
                               route={routes.orderConfirmationsList}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.invoicesList, routes.invoiceEntry]}
                               route={routes.invoicesList}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.salesTargetsList, routes.salesTargetEntry]}
                               route={routes.salesTargetsList}
                               routeGrants={auth.routeGrants} />
          </AuthModuleSection>

          <AuthModuleSection module={modules.service} moduleGrants={auth.moduleGrants}>
            <AuthModuleNavItem associatedRoutes={[routes.stocks, routes.stockDetails, routes.arrivalInspection, routes.stockInspection]}
                               route={routes.stocks}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.stockInspections]}
                               route={routes.stockInspections}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[
              routes.serviceProfiles, routes.newServiceProfile, routes.serviceProfile, routes.manageProfileLocations, routes.managePortalUsers,
              routes.newGeneratorCommission, routes.serviceGenerator, routes.testingAndCommission, routes.preventiveMaintenance,
              routes.regularService, routes.repairService, routes.emergencyBreakdown, routes.inspection,
              routes.contactLogEntry, routes.contactLogProfileEntry,
            ]} route={routes.serviceProfiles} routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.jobHistories]}
                               route={routes.jobHistories}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.serviceReports,
              routes.serviceReports_OverWarrantyGenerators, routes.serviceReports_OverWarrantyGeneratorReport,
              routes.serviceReports_WarrantyStatuses, routes.serviceReports_WarrantyStatusesReport,
              routes.serviceReports_WorkDoneSummary, routes.serviceReports_WorkDoneSummaryReport]}
                               route={routes.serviceReports}
                               routeGrants={auth.routeGrants} />
          </AuthModuleSection>

          <AuthModuleSection module={modules.customerCare} moduleGrants={auth.moduleGrants}>
            <AuthModuleNavItem associatedRoutes={[routes.customersList, routes.customerProfile]}
                               route={routes.customersList}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.careContactLogs]}
                               route={routes.careContactLogs}
                               routeGrants={auth.routeGrants} />
          </AuthModuleSection>

          <AuthModuleSection module={modules.systemSetups} moduleGrants={auth.moduleGrants}>
            <AuthModuleNavItem associatedRoutes={[routes.manageUsersAndPermissions, routes.userAccountsSetup,
              routes.rolesPermissionsSetup, routes.groupsSetup]}
                               route={routes.manageUsersAndPermissions}
                               routeGrants={auth.routeGrants} />
            <AuthModuleNavItem associatedRoutes={[routes.manageReferences,
              routes.statesDivisionsSetup,
              routes.businessTypesSetup]}
                               route={routes.manageReferences}
                               routeGrants={auth.routeGrants} />
          </AuthModuleSection>

        </Modules>
      </nav>
    )
  }
}

const Modules = ({ children }) => {
  return (
    <div className="modules">
      {children}
    </div>
  )
}

const ModuleSection = (props) => {
  const {
    module, children,
  } = props;

  return (
    <div className="module-section">
      {module &&
        <div className="module-header">
          {module.name.toUpperCase()}
        </div>
      }
      <div className="module-navbar">
        {children}
      </div>
    </div>
  )
}

const AuthModuleSection = (props) => {
  const {
    module, moduleGrants, children,
  } = props;

  const isModuleGranted = () => {
    if (!module || !moduleGrants) return false;
    return moduleGrants.includes(module.id);
  }

  return (
    <>
      {isModuleGranted() &&
        <div className="module-section">
          {module &&
            <div className="module-header">
              {module.name.toUpperCase()}
            </div>
          }
          <div className="module-navbar">
            {children}
          </div>
        </div>
      }
    </>
  )
}

/// Regular navigation menu item which doesn't need authorization.
const ModuleNavItem = (props) => {
  const {
    associatedRoutes, route,
  } = props;

  const location = useLocation();

  return (
    <>
      <div className={getNavLinkClass(location.pathname, associatedRoutes)}>
        <NavLink to={route.url}>
          <i className={`fa-solid ${route.faIcon}`}></i>
          <div className={"nav-item-label"}>{route.display}</div>
        </NavLink>
      </div>
      <div className={getNavLinkClass(location.pathname, associatedRoutes, true)}>
        <NavLink to={route.url}>
          <i className={`fa-solid ${route.faIcon}`}></i>
          <div className={"nav-item-label"}>{route.display}</div>
        </NavLink>
      </div>
    </>
  )
}

/// Authorized navigation menu item.
const AuthModuleNavItem = (props) => {
  const {
    associatedRoutes, route, routeGrants,
  } = props;

  const location = useLocation();

  const isRouteGranted = () => {
    if (!route || !routeGrants) return false;
    return routeGrants.includes(route.id);
  }

  return (
    <>
      {isRouteGranted() &&
        <>
          <div className={getNavLinkClass(location.pathname, associatedRoutes)}>
            <NavLink to={route.url}>
              <i className={`fa-solid ${route.faIcon}`}></i>
              <div className={"nav-item-label"}>{route.display}</div>
            </NavLink>
          </div>
          <div className={getNavLinkClass(location.pathname, associatedRoutes, true)}>
            <NavLink to={route.url}>
              <i className={`fa-solid ${route.faIcon}`}></i>
              <div className={"nav-item-label"}>{route.display}</div>
            </NavLink>
          </div>
        </>
      }
    </>
  )
}

const getNavLinkClass = (locationPathname, associatedRoutes, isTablet = false) => {
  let className = !isTablet ? "module-nav-item" : "module-nav-item-tablet";
  if (associatedRoutes.some(r => r.url === locationPathname)) className += " module-nav-item-selected";
  return className;
}
