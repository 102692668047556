import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Moment from "react-moment";

import { useAuth } from "../auth/AuthProvider";
import { customerServices } from "../../repos/apiServices";
import { salesServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { formModes } from "../../repos/constants";
import { customer } from "../../repos/constants";
import { getCurrency } from "../../repos/utilities";
import { getCurrencyDisplay } from "../../repos/utilities";

import { ActiveStatusBadge } from "../quotation/ActiveStatusBadge";
import { ReviewStatusBadge } from "../quotation/ReviewStatusBadge";
import { ActorNameDisplay } from "../sales/ActorNameDisplay";
import { SalesInquiryListModal } from "../sales/SalesInquiryListModal";
import { CustomerListModal } from "../customer/CustomerListModal";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { ReadonlyField } from "../shared/ReadonlyField";
import { OrderConfirmationAssignModal } from "../sales/OrderConfirmationAssignModal";

export const EntryCustomerDetails = ({
                                       editingEntry, formMode,
                                       entryDate, onEntryDateChanged,
                                       currency, onCurrencyChanged,
                                       customerProfileId, customerProfileStatus,
                                       customerName, onCustomerNameChanged,
                                       nrc, onNRCChanged,
                                       designation, onDesignationChanged,
                                       companyName, onCompanyNameChanged,
                                       address, onAddressChanged,
                                       phoneNo, onPhoneNoChanged,
                                       hasErrors, formErrors, isSubmitting, isFormOwner,
                                       fillUpDetailsFromInquiry, fillUpDetailsFromProfile,
                                       onSaveClicked, onCancelClicked, onEditClicked,
                                       onCreateProfileClicked,
                                     }) => {
  //#region States
  const auth = useAuth();

  const [formOwnerStatus, setFormOwnerStatus] = useState(refs.sales.isFormOwner.inapplicable);
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchSalesInquiries(1); // TODO: check by form mode, maybe?
    fetchCustomerProfiles(1);
  }, []);
  //#endregion

  //#region Utilities
  const getEntryDateClassName = () => {
    let className = "form-control";
    if (entryDate) className += " has-autocompleted-value";
    return className;
  }

  const fetchSalesInquiries = (page) => {
    setIsSalesInquiriesLoading(true);
    let tableOptions = {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'current_page': page,
      },
    };
    salesServices.inquiryList(tableOptions)
      .then((response) => {
        setSalesInquiries(response.data);
        setInquiriesPagination(response.data['pagination']);
      })
      .catch((error) => {
        console.error(error.response.data);
      })
      .finally(() => setIsSalesInquiriesLoading(false));
  }

  const fetchCustomerProfiles = (page) => {
    setIsCustomerProfilesLoading(true);
    let tableOptions = {
      'uid': auth.getUserId(),
      'sorting': {},
      'pagination': {
        'currentPage': page,
      }
    };
    customerServices.fetchCustomerProfiles(tableOptions)
      .then((response) => {
        setCustomerProfiles(response.data);
        setCustomersPagination(response.data['pagination']);
      })
      .catch((error) => {
        console.error(error.response);
      })
      .finally(() => setIsCustomerProfilesLoading(false));
  }

  const isFormReadonlyMode = () => {
    return formMode === formModes.orderConfirmation.editEntry;
  }

  const isFormEditMode = () => {
    return formMode === formModes.orderConfirmation.editCustomer;
  }

  const isFormEditable = () => {
    if (!editingEntry) return false;
    return editingEntry['reviewStatus'] === refs.sales.reviewStatus.draft ||
           editingEntry['reviewStatus'] === refs.sales.reviewStatus.pendingReview;
  }
  //#endregion

  //#region Control handlers
  //#endregion

  //#region Control handlers - Employee Assign modal
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const onAssignButtonClicked = (ev) => {
    setTimeout(() => {
      setIsAssignModalOpen(true);
    }, 200);
  }

  const onAssignModalRequestClose = (ev) => {
    setTimeout(() => {
      setIsAssignModalOpen(false);
    }, 200);
  }
  //#endregion

  //#region Control handlers - Sales inquiries pop up modal
  const [isSalesInquiriesModalOpen, setIsSalesInquiriesModalOpen] = useState(false);
  const [isSalesInquiriesLoading, setIsSalesInquiriesLoading] = useState(false);
  const [salesInquiries, setSalesInquiries] = useState();
  const [inquiriesPagination, setInquiriesPagination] = useState(null);

  const salesInquiriesModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsSalesInquiriesModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsSalesInquiriesModalOpen(false);
      }, 200);
    },
  }

  const onSalesInquiryRowClicked = (ev, salesInquiry) => {
    ev.preventDefault();
    setTimeout(() => {
      fillUpDetailsFromInquiry(salesInquiry);
      setIsSalesInquiriesModalOpen(false);
    }, 100);
  }

  const onSalesInquiriesPageClicked = (page) => {
    fetchSalesInquiries(page);
  }

  const onSalesInquiriesPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    fetchSalesInquiries(page);
  }

  const onSalesInquiriesNextPageClicked = (fromPage) => {
    let page = Math.min(salesInquiries['pagination']['totalPages'], fromPage + 1);
    fetchSalesInquiries(page);
  }
  //#endregion

  //#region Control handlers - Customer profiles pop up modal
  const [isCustomerProfilesModalOpen, setIsCustomerProfilesModalOpen] = useState(false);
  const [isCustomerProfilesLoading, setIsCustomerProfilesLoading] = useState(false);
  const [customerProfiles, setCustomerProfiles] = useState();
  const [customersPagination, setCustomersPagination] = useState(null);

  const customerProfilesModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsCustomerProfilesModalOpen(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsCustomerProfilesModalOpen(false);
      }, 200);
    },
  }

  const onCustomerProfileRowClicked = (ev, customer) => {
    ev.preventDefault();
    setTimeout(() => {
      fillUpDetailsFromProfile(customer);
      setIsCustomerProfilesModalOpen(false);
    }, 100);
  }

  const onCustomerProfilesPageClicked = (page) => {
    fetchCustomerProfiles(page);
  }

  const onCustomerProfilesPrevPageClicked = (fromPage) => {
    let page = Math.max(1, fromPage - 1);
    fetchCustomerProfiles(page);
  }

  const onCustomerProfilesNextPageClicked = (fromPage) => {
    let page = Math.min(salesInquiries['pagination']['totalPages'], fromPage + 1);
    fetchCustomerProfiles(page);
  }
  //#endregion

  //#region Render
  return (
    <>
      <div className={"form-section"}>
        <div className={"entry-form"}>
          {editingEntry &&
            <>
              <div className={"form-label"}>
                Status:
              </div>
              <div className={"form-input has-badge"}>
                <ReviewStatusBadge status={editingEntry['reviewStatus']} />
              </div>
            </>
          }

          <div className={"form-label"}>
            <label htmlFor={"reporter"}>
              Created by:
            </label>
          </div>
          <div className={"form-input"}>
            {editingEntry &&
              <ReadonlyField>
                <ActorNameDisplay employee={editingEntry['createdBy']} />
              </ReadonlyField>
            }
            {!editingEntry &&
              <ReadonlyField>You</ReadonlyField>
            }
          </div>

          {editingEntry &&
            <>
              <div className={"form-input"}>
                <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={onAssignButtonClicked}>
                  <i className="fa-solid fa-user-gear"></i>
                  Assign...
                </button>
              </div>

              <OrderConfirmationAssignModal isOpen={isAssignModalOpen} onRequestClose={onAssignModalRequestClose}
                                            assignments={editingEntry['assignments']}
                                            editingEntry={editingEntry} />
            </>
          }

          <div className={"form-label"}>
            <label htmlFor={"currency"}>
              Currency:
            </label>
          </div>
          <div className={"form-input"}>
            {!isFormReadonlyMode() &&
              <select id={"currency"} className={"form-control form-select"} disabled={isSubmitting}
                      value={currency} onChange={onCurrencyChanged}>
                {Object.entries(refs.currency).map(([key, item], index) =>
                  <option key={key} value={item.code}>{item.name} ({item.sign})</option>
                )}
              </select>
            }
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {getCurrencyDisplay(currency)}
              </ReadonlyField>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"entryDate"}>
              Date:<span className={"error-message"}>*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                <Moment date={entryDate} format={formatters.datetime} />
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <DatePicker id={"entryDate"} className={getEntryDateClassName()} placeholderText={"Type a valid date or click to choose"} required={true}
                            dateFormat={formatters.datetimePicker} todayButton={"Today"} showWeekNumbers
                            disabled={isSubmitting} autoComplete={"none"}
                            selected={entryDate} onChange={onEntryDateChanged} />
                <FieldErrorMessages visible={hasErrors} messages={formErrors['entryDate']} />
              </>
            }
          </div>

          {!isFormReadonlyMode() &&
            <>
              <div className={"form-input"}>
                <button type={"button"} className={"btn btn-secondary"} disabled={isSalesInquiriesLoading || isSubmitting}
                        onClick={salesInquiriesModal.onOpenButtonClicked}>
                  {isSalesInquiriesLoading && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                  {!isSalesInquiriesLoading && <i className="fa-solid fa-magnifying-glass-arrow-right"></i>}
                  Pull details from inquiry...
                </button>
              </div>
              <div className={"form-input"}>
                <button type={"button"} className={"btn btn-secondary"} disabled={isCustomerProfilesLoading}
                        onClick={customerProfilesModal.onOpenButtonClicked}>
                  <i className={"fa-solid fa-clipboard-user"}></i>
                  Choose customer profile...
                </button>
              </div>
            </>
          }
        </div>

        <SalesInquiryListModal isOpen={isSalesInquiriesModalOpen} isLoading={isSalesInquiriesLoading}
                               onRequestClose={salesInquiriesModal.onCloseButtonClicked}
                               salesInquiries={salesInquiries} pagination={inquiriesPagination}
                               onInquiryRowClicked={onSalesInquiryRowClicked}
                               onPageClicked={onSalesInquiriesPageClicked}
                               onPrevPageClicked={onSalesInquiriesPrevPageClicked}
                               onNextPageClicked={onSalesInquiriesNextPageClicked} />

        <CustomerListModal isOpen={isCustomerProfilesModalOpen} isLoading={isCustomerProfilesLoading}
                           onRequestClose={customerProfilesModal.onCloseButtonClicked}
                           customerProfiles={customerProfiles} pagination={customersPagination}
                           onCustomerRowClicked={onCustomerProfileRowClicked}
                           onPageClicked={onCustomerProfilesPageClicked}
                           onPrevPageClicked={onCustomerProfilesPrevPageClicked}
                           onNextPageClicked={onCustomerProfilesNextPageClicked} />
      </div>

      <div className={"form-section"}>
        <h2>Purchased By</h2>

        <div className={"entry-form purchase-agreement-entry-form"}>
          <div className={"form-label"}>
            <label htmlFor={"dontIntervene1"}>
              Name:<span className={"error-message"}>*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>{customerName}</ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <input type={"text"} id={"dontIntervene1"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                       value={customerName} onChange={onCustomerNameChanged} maxLength={100} />
                <FieldErrorMessages visible={hasErrors} messages={formErrors['customerName']} />
              </>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"dontIntervene2"}>
              NRC No:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {nrc ? nrc : '-'}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <input type={"text"} id={"dontIntervene2"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                     value={nrc} onChange={onNRCChanged} maxLength={50} />
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"dontIntervene3"}>
              Designation:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {designation ? designation : '-'}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <input type={"text"} id={"dontIntervene3"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                     value={designation} onChange={onDesignationChanged} maxLength={50} />
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"dontIntervene4"}>
              Company:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {companyName ? companyName : '-'}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <input type={"text"} id={"dontIntervene4"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                     value={companyName} onChange={onCompanyNameChanged} maxLength={100} />
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"dontIntervene5"}>
              Address:
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>
                {address ? address : '-'}
              </ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <textarea id={"dontIntervene5"} className={"form-control"} autoComplete={"none"} rows={3} disabled={isSubmitting} maxLength={200}
                        value={address} onChange={onAddressChanged}>
              </textarea>
            }
          </div>

          <div className={"form-label"}>
            <label htmlFor={"dontIntervene6"}>
              Phone No:<span className={"error-message"}>*</span>
            </label>
          </div>
          <div className={"form-input"}>
            {isFormReadonlyMode() &&
              <ReadonlyField>{phoneNo}</ReadonlyField>
            }
            {!isFormReadonlyMode() &&
              <>
                <input type={"text"} id={"dontIntervene6"} className={"form-control"} autoComplete={"none"} disabled={isSubmitting}
                       value={phoneNo} onChange={onPhoneNoChanged} maxLength={50} />
                <FieldErrorMessages visible={hasErrors} messages={formErrors['phoneNo']} />
              </>
            }
          </div>

        </div>

        <div className={"form-button-controls"}>
          <div className={"left-side"}>
            {!isFormReadonlyMode() && !isFormEditMode() && isFormOwner() &&
              <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                      onClick={onSaveClicked}>
                {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                {!isSubmitting && <i className={"fa-solid fa-circle-right"}></i>}
                <span>Save and continue</span>
              </button>
            }
            {isFormReadonlyMode() && isFormEditable() && isFormOwner() &&
              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                      onClick={onEditClicked}>
                <i className="fa-solid fa-pen"></i>
                <span>Edit</span>
              </button>
            }
            {isFormEditMode() && isFormOwner() &&
              <>
                <button type={"button"} className={"btn btn-primary"} disabled={isSubmitting}
                        onClick={(ev) => onSaveClicked(ev, true)}>
                  {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                  {!isSubmitting && <i className={"fa-solid fa-check"}></i>}
                  <span>Update</span>
                </button>
                <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                        onClick={onCancelClicked}>
                  <i className={"fa-solid fa-xmark"}></i>
                  <span>Cancel</span>
                </button>
              </>
            }
            {isFormReadonlyMode() && customerProfileStatus === customer.profileStatus.draft && isFormOwner() &&
              <button type={"button"} className={"btn btn-secondary"} disabled={isSubmitting}
                      onClick={onCreateProfileClicked}>
                {isSubmitting && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                {!isSubmitting && <i className="fa-solid fa-id-card-clip"></i>}
                <span>Create Customer Profile</span>
              </button>
            }
            {isFormReadonlyMode() && customerProfileStatus === customer.profileStatus.active &&
              <div className={"customer-profile-status-label"}>
                <i className="fa-solid fa-check-circle"></i>
                Customer profile created/attached.
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
  //#endregion
}
