import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

import "./TabPanel.css";

const TabsPanelContext = createContext(null);

// TODO(yemon): Refactor this whole component hierarchy structure to work with child element indices.
export const TabsPanelProvider = ({
                                    initialHeaderElementId,
                                    initialContentPaneElementId,
                                    children,
                                  }) => {
  const [currentHeaderElementId, setCurrentHeaderElementId] = useState("");
  const [currentContentPaneElementId, setCurrentContentPaneElementId] = useState("");

  useEffect(() => {
    if (initialHeaderElementId) {
      setCurrentHeaderElementId(initialHeaderElementId)
      setTimeout(() => {
        activateHeader(initialHeaderElementId);
      }, 200);
    }
    if (initialContentPaneElementId) {
      setCurrentContentPaneElementId(initialContentPaneElementId);
      setTimeout(() => {
        activateContent(initialContentPaneElementId);
      }, 200);
    }
  }, []);

  const activateHeader = (headerElementId) => {
    // NOTE(yemon): Querying the tab header <button>'s could potentially conflict if
    // there are more than one <TabsPanelProvider> in the same page.
    // However, tried querying the <TabHeader> element by its assigned element ID,
    // and loop through its children. Didn't work very well for setting the initial
    // selection state. Mainly due to the fact that the child elements didn't get rendered
    // fast enough for the <TabsPanelProvider> useEffect() to take effect properly.
    // This applies for the activateContent() function below as well.

    let headerItems = document.getElementsByClassName("tab-header-item");
    for (let item of headerItems) {
      item.classList.remove("active");
    }
    let activateItem = document.getElementById(headerElementId);
    if (activateItem) {
      activateItem.classList.add("active");
    }
    setCurrentHeaderElementId(headerElementId);
  }

  const activateContent = (contentPaneElementId) => {
    let contentPanes = document.getElementsByClassName("tab-content-pane");
    for (let pane of contentPanes) {
      pane.classList.remove("active");
    }
    let activatePane = document.getElementById(contentPaneElementId);
    if (activatePane) {
      activatePane.classList.add("active");
    }
    setCurrentContentPaneElementId(contentPaneElementId);
  }

  const switchTab = (headerElementId, contentPaneElementId) => {
    if (headerElementId === currentHeaderElementId) {
      return;
    }
    if (contentPaneElementId === currentContentPaneElementId) {
      return;
    }
    activateHeader(headerElementId);
    activateContent(contentPaneElementId);
  }

  return (
    <TabsPanelContext.Provider value={{
      switchTab,
    }}>
      <div className={"tabs-panel"}>
        {children}
      </div>
    </TabsPanelContext.Provider>
  )
}

export const useTabsPanel = () => {
  return useContext(TabsPanelContext);
}

export const TabHeader = ({
                            children,
                          }) => {
  return (
    <ul role={"tablist"} className={"tab-header"}>
      {children}
    </ul>
  )
}

export const TabHeaderItem = ({
                                itemElementId,
                                contentPaneElementId,
                                itemLabel,
                              }) => {
  const [headerElementId, setHeaderElementId] = useState("");
  const [contentElementId, setContentElementId] = useState("");

  const tabsPanel = useTabsPanel();

  useEffect(() => {
    setHeaderElementId(itemElementId);
    setContentElementId(contentPaneElementId);
  }, []);

  const onHeaderItemClicked = (ev) => {
    tabsPanel.switchTab(headerElementId, contentPaneElementId);
  }

  return (
    <li role={"presentation"} className={""}>
      <button id={headerElementId} type={"button"} role={"tab"}
              className={"tab-header-item"} onClick={onHeaderItemClicked}
              aria-controls={contentElementId}>
        {itemLabel}
      </button>
    </li>
  )
}

export const TabContent = ({
                             children,
                           }) => {
  return (
    <div className={"tab-content"}>
      {children}
    </div>
  )
}

export const TabContentPane = ({
                                 contentPaneElementId,
                                 children,
                               }) => {
  return (
    <div id={contentPaneElementId} role={"tabpanel"} className={"tab-content-pane"}>
      {children}
    </div>
  )
}
