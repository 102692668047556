import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { useAuth } from "../auth/AuthProvider";
import { refServices } from "../../repos/apiServices2";

import { ModalContainer } from "../shared/ModalContainer";
import { modalSizes } from "../shared/ModalContainer";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";


export const EmployeesSelectorModal = ({ 
                                        isOpen, onRequestClose, isParentLoading, 
                                        selectedOptions, setSelectedOptions, 
                                      }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [employeeBranchGroups, setEmployeeBranchGroups] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);

  const auth = useAuth();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = () => {
    setIsLoading(true);
    refServices.fetchEmployeesListByBranch({ 'uid': auth.getUserId() })
      .then((response) => {
        const _employeeBranches = response['data'];
        let _total = 0;
        setEmployeeBranchGroups(_employeeBranches);
        for (let branch of _employeeBranches) {
          _total += branch.employees.length;
        }
        setTotalEmployees(_total);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const isModalLoading = () => {
    return isLoading || isParentLoading;
  }

  const getDescription = () => {
    if (!selectedOptions) {
      return `0 selected.`;
    } else {
      return `${selectedOptions.length} selected.`;
    }
  }

  const isSelected = (employeeId) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      return false;
    }
    return selectedOptions.some((id) => id === employeeId);
  }

  const onSelectChecked = (ev, employeeId, checked) => {
    const alreadySelected = (_id) => {
      return _id === employeeId;
    }

    // NOTE(yemon): The many-funsies in the world of JavaScript.
    // Array.slice() "force clone" the entire array into a completely new array.
    let _selectedOptions = selectedOptions.slice();

    if (checked && !_selectedOptions.some(alreadySelected)) {
      _selectedOptions.push(employeeId);
      setSelectedOptions(_selectedOptions);
    }

    if (!checked && _selectedOptions.some(alreadySelected)) {
      let existingIndex = _selectedOptions.findIndex(alreadySelected);
      if (existingIndex !== -1) {
        _selectedOptions.splice(existingIndex, 1);
        setSelectedOptions(_selectedOptions);
      }
    }
  }

  return (
    <ModalContainer elementId={"employees-selector-modal"}
                    isOpen={isOpen} onRequestClose={onRequestClose}
                    modalSize={modalSizes.tiny} height={720}
                    title={"Specify Sales Team"}
                    shortDescription={getDescription()}>
      <div className={"modal-data-table options-field-elements"}>
        <table>
          <thead>
          <tr>
            <th scope="col" className={"index-col-head fixed-width"}>
            </th>
            <th scope="col">Name</th>
            <th scope="col">Rank</th>
          </tr>
          </thead>
          <tbody>
          {isModalLoading() && employeeBranchGroups &&
            <TableLoadingIndicator colspan={3} />
          }

          {!isModalLoading() && employeeBranchGroups && employeeBranchGroups.length > 0 &&
            employeeBranchGroups.map((employeeBranch, index) =>
              <EmployeeTableRow key={employeeBranch['id']}
                                employeeBranch={employeeBranch}
                                isSelected={isSelected} onCheckChanged={onSelectChecked} />
            )
          }

          {!isModalLoading() && employeeBranchGroups && employeeBranchGroups.length === 0 &&
            <TableEmptyRow colSpan={3} />
          }
          </tbody>
        </table>
      </div>

      <div className={"options-field-margin"}></div>
    </ModalContainer>
  )
}

const EmployeeTableRow = ({
                            employeeBranch,
                            isSelected, onCheckChanged,
                          }) => {
  return (
    <>
      <tr>
        <td className={"index-col"}></td>
        <td colSpan={2}>
          <b>{employeeBranch['name']}</b>
        </td>
      </tr>
      {employeeBranch['employees'].length > 0 && employeeBranch['employees'].map((employee, index) => 
        <tr className={"clickable-row"} key={employee['id']}
            onClick={(ev) => onCheckChanged(ev, employee['id'], !isSelected(employee['id']))}>
          <td className={"index-col"}>
            <label htmlFor={`select-business-type-${employee['id']}`}>
              <input type={"checkbox"} id={`select-business-type-${employee['id']}}`}
                    name={`select-business-type-${employee['id']}}`}
                    title={`Select ${employee['fullName']}`}
                    checked={isSelected(employee['id'])}
                    onChange={(ev) => onCheckChanged(ev, employee['id'], ev.target.checked)} />
            </label>
          </td>
          <td title={`Select ${employee['fullName']}`}>
            <label htmlFor={`select-business-type-${employee['id']}`} className={"no-padding"}>
              {employee["fullName"]}
            </label>
          </td>
          <td>
            {employee['rankName']}
          </td>
        </tr>
      )}
    </>
  )
}
