import React from "react";
import Moment from "react-moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../auth/AuthProvider";
import { inventoryServices } from "../../repos/apiServices";
import { portalServices } from "../../repos/apiServices";
import { refs } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { navigableRoutes as routes } from "../../repos/constants";

import { MasterPageContainer } from "../shared/MasterPageContainer";
import { Breadcrumbs } from "../shared/Breadcrumbs";
import { BreadcrumbItem } from "../shared/Breadcrumbs";
import { ReadonlyField } from "../shared/ReadonlyField";
import { NullBlankValue } from "./NullBlankField";
import { TableLoadingIndicator } from "../shared/DataTable";
import { TableEmptyRow } from "../shared/DataTable";
import { NewPortalUserModal } from "./NewPortalUserModal";
import { EditPortalUserModal } from "./EditPortalUserModal";

import "./ServiceProfilePage.css";
import "../shared/ContentArea.css";
import "../shared/EntryForm.css";
import "../shared/DataTable.css";

const portalRefs = refs.portal;


export function ManagePortalUsersPage() {
  //#region States
  const [customerId, setCustomerId] = useState(null);
  const [serviceProfileId, setServiceProfileId] = useState(null);
  const [serviceProfile, setServiceProfile] = useState(null);

  const [customerName, setCustomerName] = useState("");
  const [displayFullname, setDisplayFullname] = useState("");
  const [loginUsername, setLoginUsername] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [accountStatus, setAccountStatus] = useState(portalRefs.accountStatus.draft);

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  const [isLoadingPortalUsers, setIsLoadingPortalUsers] = useState(false);
  const [portalUsers, setPortalUsers] = useState([]);
  const [isLoadingGenerators, setIsLoadingGenerators] = useState(false);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [servicePermissions, setServicePermissions] = useState(null);
  const [selectedPortalUserId, setSelectedPortalUserId] = useState(null);
  const [selectedPortalUserPermissions, setSelectedPortalUserPermissions] = useState([]);
  const [checkedServicePermissions, setCheckedServicePermissions] = useState([]);

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region Effects
  useEffect(() => {
    let state = location.state;
    let _customerId = state['customerId'];
    let _profileId = state['serviceProfileId'];
    if (!state || !_customerId || !_profileId) {
      navigate(routes.serviceProfiles.url);
    }
    else {
      setCustomerId(_customerId);
      setServiceProfileId(_profileId);
      fetchCustomerServiceProfile(_customerId, _profileId);
      fetchPortalUsers(_profileId);
    }
  }, []);

  const fetchCustomerServiceProfile = (customerId, serviceProfileId) => {
    setIsLoadingProfile(true);
    let params = {
      'uid': auth.getUserId(),
      'customer_id': customerId,
      'service_profile_id': serviceProfileId,
    };
    inventoryServices.fetchCustomerServiceProfile(params)
      .then((response) => {
        let _serviceProfile = response['data'];
        setServiceProfile(_serviceProfile);
        prepareCustomerDetails(_serviceProfile['customer']);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingProfile(false);
      });
  }

  const prepareCustomerDetails = (customer) => {
    if (!customer) {
      return;
    }

    setCustomerName(customer['customerName']);
  }

  const fetchPortalUsers = (serviceProfileId) => {
    setIsLoadingPortalUsers(true);
    setPortalUsers([]);
    portalServices.fetchPortalUsers(serviceProfileId)
      .then((response) => {
        let _portalUsers = response['data'];
        setPortalUsers(_portalUsers);
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => {
        setIsLoadingPortalUsers(false);
      });

    setIsLoadingGenerators(true);
    setServiceLocations([]);
    setServicePermissions({});
    portalServices.fetchPortalUsersPermissions(serviceProfileId)
      .then((response) => {
        let _responseData = response['data'];
        setServiceLocations(_responseData['serviceLocations']);
        setServicePermissions(_responseData['servicePermissions']);
      })
      .catch((error) => {
        handleErrorResponse(error)
      })
      .finally(() => {
        setIsLoadingGenerators(false);
      });
  }

  const handleErrorResponse = (error) => {
    let errorResponse = error['response'];
    if (errorResponse) {
      if (errorResponse.status === 404 || errorResponse.status === 400) {
        navigate(routes.serviceProfiles.url);
      }
    }
  }
  //#endregion

  //#region Utilities
  const isLoading = () => {
    return isLoadingProfile || isLoadingGenerators;
  }
  //#endregion

  //#region Control handlers
  const onCustomerNameClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onReturnClicked = (ev) => {
    setTimeout(() => {
      navigate(routes.serviceProfile.url, {
        state: {
          'customerId': customerId,
          'serviceProfileId': serviceProfileId,
        }
      });
    }, 200);
  }

  const onRefreshClicked = (ev) => {
    fetchCustomerServiceProfile(customerId, serviceProfileId);
    fetchPortalUsers(serviceProfileId);
  }

  const resetPortalUserFields = () => {
    setDisplayFullname("");
    setLoginUsername("");
    setGeneratedPassword("");
    setAccountStatus(portalRefs.accountStatus.draft);

    setSelectedPortalUserId(null);
    setSelectedPortalUserPermissions([]);
    setCheckedServicePermissions([]);
  }
  //#endregion

  //#region Control handler; New Portal User modal
  const [isNewPortalUserModalOpened, setIsNewPortalUserModalOpened] = useState(false);

  const newPortalUserModal = {
    onOpenButtonClicked: function() {
      setTimeout(() => {
        setIsNewPortalUserModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      setTimeout(() => {
        setIsNewPortalUserModalOpened(false);
      }, 200);
    },

    onNewUserSubmitted: function() {
      fetchPortalUsers(serviceProfileId);
    },
  }
  //#endregion

  //#region Control handler; Edit Portal User modal
  const [isEditPortalUserModalOpened, setIsEditPortalUserModalOpened] = useState(false);

  const prepareUserFields = (portalUser) => {
    setDisplayFullname(portalUser['displayFullname']);
    setLoginUsername(portalUser['user']['username']);
    setAccountStatus(portalUser['status']);
    if (portalUser['status'] === portalRefs.accountStatus.passwordReset) {
      setGeneratedPassword(portalUser['generatedPassword']);
    }

    setSelectedPortalUserId(portalUser['id']);
    if (servicePermissions) {
      let _servicePermissions = servicePermissions[portalUser['id']]
      setSelectedPortalUserPermissions(_servicePermissions);

      let _checkedPermissions = [];
      for (let permission of _servicePermissions) {
        _checkedPermissions.push({
          "serviceLocationId": permission["serviceLocationId"],
          "serviceGeneratorId": permission["serviceGeneratorId"],
        });
      }
      setCheckedServicePermissions(_checkedPermissions);
    }
  }

  const editPortalUserModal = {
    onOpenButtonClicked: function(ev, portalUser) {
      ev.preventDefault();
      prepareUserFields(portalUser);
      setTimeout(() => {
        setIsEditPortalUserModalOpened(true);
      }, 200);
    },

    onCloseButtonClicked: function() {
      resetPortalUserFields();
      setTimeout(() => {
        setIsEditPortalUserModalOpened(false);
      }, 200);
    },

    onUserSubmitted: function() {
      fetchPortalUsers(serviceProfileId);
    },

    afterUserPasswordReset: function(portalUserId) {
      setIsLoadingPortalUsers(true);
      setPortalUsers([]);
      portalServices.fetchPortalUsers(serviceProfileId)
        .then((response) => {
          let _portalUsers = response['data'];
          setPortalUsers(_portalUsers);

          if (_portalUsers && _portalUsers.length > 0) {
            let _portalUser = _portalUsers.find((user) => user['id'] === portalUserId);
            prepareUserFields(_portalUser);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        })
        .finally(() => {
          setIsLoadingPortalUsers(false);
        });
    },

    onGeneratorCheckChanged: function(ev, serviceLocationId, serviceGeneratorId, checked) {
      const alreadyHasGenerator = (permission) => {
        return permission["serviceLocationId"] === serviceLocationId &&
          permission["serviceGeneratorId"] === serviceGeneratorId;
      };

      // NOTE(yemon): The many-funsies in the world of JavaScript.
      // Array.slice() "force clone" the entire array into a completely new array.
      // You can probably guess what happens if memory wasn't wasted like that...
      let _checkedPermissions = checkedServicePermissions.slice();

      if (checked && !_checkedPermissions.some(alreadyHasGenerator)) {
        _checkedPermissions.push({
          "serviceLocationId": serviceLocationId,
          "serviceGeneratorId": serviceGeneratorId,
        });
        setCheckedServicePermissions(_checkedPermissions);
      }

      if (!checked && _checkedPermissions.some(alreadyHasGenerator)) {
        let existingPermissionIndex = _checkedPermissions.findIndex(alreadyHasGenerator);
        if (existingPermissionIndex !== -1) {
          _checkedPermissions.splice(existingPermissionIndex, 1);
          setCheckedServicePermissions(_checkedPermissions);
        }
      }
    },

    onAllGeneratorsCheckChanged: function(ev, serviceLocationId, checked) {
      const alreadyHasGenerator = (permission, serviceGeneratorId) => {
        return permission["serviceLocationId"] === serviceLocationId &&
          permission["serviceGeneratorId"] === serviceGeneratorId;
      };

      let _serviceLocation = serviceLocations.find((location) => location['id'] === serviceLocationId);
      if (!_serviceLocation) {
        return;
      }

      let _serviceGenerators = _serviceLocation['generators'];
      if (_serviceGenerators.length === 0) {
        return;
      }

      let _checkedPermissions = checkedServicePermissions.slice();

      if (checked) {
        for (let _generator of _serviceGenerators) {
          let containsGenerator = _checkedPermissions.some((permission) => alreadyHasGenerator(permission, _generator['id']))
          if (containsGenerator) {
            continue;
          }
          _checkedPermissions.push({
            "serviceLocationId": serviceLocationId,
            "serviceGeneratorId": _generator['id'],
          });
        }
        setCheckedServicePermissions(_checkedPermissions);
      }

      if (!checked) {
        for (let _generator of _serviceGenerators) {
          let existingPermissionIndex = _checkedPermissions.findIndex((permission) => alreadyHasGenerator(permission, _generator['id']));
          if (existingPermissionIndex === -1) {
            continue;
          }
          _checkedPermissions.splice(existingPermissionIndex, 1);
        }
        setCheckedServicePermissions(_checkedPermissions);
      }
    },

    onAllLocationsCheckChanged: function(ev, checked) {
      const alreadyHasGenerator = (permission, serviceLocationId, serviceGeneratorId) => {
        return permission["serviceLocationId"] === serviceLocationId &&
          permission["serviceGeneratorId"] === serviceGeneratorId;
      };

      if (serviceLocations && serviceLocations.length === 0) {
        return;
      }

      let _checkedPermissions = checkedServicePermissions.slice();

      if (checked) {
        for (let _serviceLocation of serviceLocations) {
          for (let _serviceGenerator of _serviceLocation['generators']) {
            let containsGenerator = _checkedPermissions.some((permission) => alreadyHasGenerator(permission, _serviceLocation['id'], _serviceGenerator['id']));
            if (containsGenerator) {
              continue;
            }
            _checkedPermissions.push({
              "serviceLocationId": _serviceLocation['id'],
              "serviceGeneratorId": _serviceGenerator['id'],
            });
          }
        }
        setCheckedServicePermissions(_checkedPermissions);
      }

      if (!checked) {
        setCheckedServicePermissions([]);
      }
    },
  }
  //#endregion

  //#region Render
  return (
    <MasterPageContainer>
      <main className={"content-container"}>
        <div className={"content-area"}>
          <div className={"row"}>
            <Breadcrumbs>
              <BreadcrumbItem text={routes.serviceProfiles.displayShort} anchorTo={routes.serviceProfiles.url} />
              <BreadcrumbItem>
                <a href={"#"} role={"button"} onClick={onCustomerNameClicked}>
                  {customerName}
                </a>
              </BreadcrumbItem>
              <BreadcrumbItem text={routes.managePortalUsers.displayShort} isActive={true} />
            </Breadcrumbs>
          </div>

          <div className={"row"}>
            <h1>{customerName}</h1>
          </div>

          <div className={"form-section"}>
            <h2>{routes.managePortalUsers.display}</h2>
          </div>

          <NewPortalUserModal serviceProfileId={serviceProfileId}
                              displayFullname={displayFullname} setDisplayFullname={setDisplayFullname}
                              loginUsername={loginUsername} setLoginUsername={setLoginUsername}
                              resetPortalUserFields={resetPortalUserFields}
                              isOpen={isNewPortalUserModalOpened}
                              onRequestClose={newPortalUserModal.onCloseButtonClicked}
                              onUserSubmitted={newPortalUserModal.onNewUserSubmitted} />
          <EditPortalUserModal serviceProfileId={serviceProfileId} selectedPortalUserId={selectedPortalUserId}
                               displayFullname={displayFullname} setDisplayFullname={setDisplayFullname}
                               loginUsername={loginUsername}
                               generatedPassword={generatedPassword}
                               accountStatus={accountStatus}
                               resetPortalUserFields={resetPortalUserFields}
                               isOpen={isEditPortalUserModalOpened}
                               onRequestClose={editPortalUserModal.onCloseButtonClicked}
                               onUserSubmitted={editPortalUserModal.onUserSubmitted}
                               afterUserPasswordReset={editPortalUserModal.afterUserPasswordReset}
                               serviceLocations={serviceLocations}
                               checkedServicePermissions={checkedServicePermissions}
                               onGeneratorCheckChanged={editPortalUserModal.onGeneratorCheckChanged}
                               onAllGeneratorsCheckChanged={editPortalUserModal.onAllGeneratorsCheckChanged}
                               onAllLocationsCheckChanged={editPortalUserModal.onAllLocationsCheckChanged}
          />

          <div className={"form-section-controls"}>
            <button type={"button"} className={"btn btn-secondary"} disabled={isLoading()}
                    onClick={onReturnClicked}>
              <i className={"fa-solid fa-arrow-left"}></i>
              Return to Profile
            </button>
            <button type={"button"} className={"btn btn-secondary right-margin"} disabled={isLoading()}
                    onClick={onRefreshClicked}>
              {isLoading() && <i className="fa-solid fa-circle-notch fa-spin"></i>}
              {!isLoading() && <i className={"fa-solid fa-sync"}></i>}
              Refresh
            </button>

            <button type={"button"} className={"btn btn-primary"} disabled={isLoading()}
                    onClick={newPortalUserModal.onOpenButtonClicked}>
              <i className={"fa-solid fa-user-plus"}></i>
              Create New User
            </button>
          </div>

          <div className={"data-table"}>
            <table>
              <thead>
              <tr>
                <th scope={"col"} className={"index-col-head"}>#</th>
                <th scope={"col"}>Display Full Name</th>
                <th scope={"col"}>Login Username</th>
                <th scope={"col"}>Status</th>
                <th scope={"col"}>Last Login Date/Time</th>
              </tr>
              </thead>

              <tbody>
              {isLoadingPortalUsers && <TableLoadingIndicator colspan={5} />}

              {!isLoadingPortalUsers && (portalUsers && portalUsers.length > 0) &&
                portalUsers.map((portalUser, index) =>
                  <PortalUserRow portalUser={portalUser} index={index} key={portalUser['id']}
                                 onRowClicked={editPortalUserModal.onOpenButtonClicked} />
                )
              }

              {!isLoadingPortalUsers && (portalUsers && portalUsers.length === 0) &&
                <TableEmptyRow colSpan={5} />
              }
              </tbody>
            </table>
          </div>

        </div>
      </main>
    </MasterPageContainer>
  )
  //#endregion
}


const PortalUserRow = ({
                         portalUser, index,
                         onRowClicked,
                       }) => {
  return (
    <tr>
      <td className={"index-col"}>
        {index + 1}
      </td>
      <td>
        <a href={"#"} role={"button"} className={"record-link"}
           onClick={(ev) => onRowClicked(ev, portalUser)}>
          {portalUser['displayFullname']}
        </a>
      </td>
      <td>
        {portalUser['user']['username']}
      </td>
      <td>
        {portalRefs.accountStatus[portalUser['status']]}
      </td>
      <td>
        {portalUser['lastLoginDatetime'] &&
          <Moment date={portalUser['lastLoginDatetime']} format={formatters.datetimeShort} />
        }
        {!portalUser['lastLoginDatetime'] &&
          <i style={{ color: 'gray' }}>NA</i>
        }
      </td>
    </tr>
  )
}
