import "../shared/StatusBadge.css";

export const ReviewStatusBadge = ({ status }) => {
  const getBadgeClass = () => {
    let className = "badge";
    switch (status) {
      case 0:
        return className + " badge-draft";
      case 1:
        return className + " badge-pending-review";
      case 2:
        return className + " badge-approved";
      case 3:
        return className + " badge-rejected";
      case 8:
        return className + " badge-canceled";
    }
  }

  const getBadgeDescription = () => {
    switch (status) {
      case 0:
        return "Draft";
      case 1:
        return "Pending Review";
      case 2:
        return "Approved";
      case 3:
        return "Rejected";
      case 8:
        return "Canceled";
    }
  }

  return (
    <span className={getBadgeClass()}>{getBadgeDescription()}</span>
  )
}
