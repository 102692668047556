import { formatCurrency } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";
import { getCurrencyDisplay } from "../../repos/utilities";

import { TableLoadingIndicator } from "../shared/DataTable";

export const QuotationEntryTable = ({
                                      editingEntry, isLoading, quotationDetails,
                                      currency, isFormSubmitted, isFormOwner,
                                      onItemRowSelected, onItemRemoveClicked,
                                    }) => {
  //#region Utilities
  //#endregion

  //#region Render
  return (
    <div className={"form-section table-section"}>
      <div className={"data-table"}>
        <table>
          <thead>
          <tr>
            <th scope="col" className="index-col-head">#</th>
            <th scope="col">Genset / ATS Model</th>
            <th scope="col" className={"right-aligned"} style={{ width: "15%" }}>
              Price
            </th>
            <th scope="col" className={"right-aligned"} style={{ width: "10%" }}>
              Quantity
            </th>
            <th scope="col" className={"right-aligned"} style={{ width: "15%" }}>
              Amount
            </th>
            <th scope="col">Remark</th>
            <th>
            </th>
          </tr>
          </thead>
          <tbody>
          {isLoading && <TableLoadingIndicator colspan={6} />}

          {quotationDetails && quotationDetails.length > 0 && !isLoading &&
            quotationDetails.map((item, index) =>
              <QuotationEntryRow key={item['detailsId']} index={index} item={item} currency={currency}
                                 isLoading={isLoading} isFormSubmitted={isFormSubmitted} isFormOwner={isFormOwner}
                                 onItemRowSelected={onItemRowSelected}
                                 onItemRemoveClicked={onItemRemoveClicked} />
            )
          }

          {(!quotationDetails || quotationDetails.length === 0) && !isLoading &&
            <QuotationEmptyRow colSpan={6} />
          }

          <QuotationFooterRow currency={currency} quotationDetails={quotationDetails} />

          </tbody>
        </table>
      </div>
    </div>
  )
  //#endregion
}

const QuotationEntryRow = ({
                             index, item, currency, isLoading, isFormSubmitted, isFormOwner,
                             onItemRowSelected, onItemRemoveClicked,
                           }) => {
  const getItemDisplayName = () => {
    let generator = item['generator'];
    let ats = item['ats'];
    if (generator) {
      return generator['gensetModel'];
    } else if (ats) {
      return ats['name'];
    } else return '';
  }

  const getItemAmount = () => {
    return formatCurrency(item['price'] * item['quantity']);
  }

  return (
    <tr>
      <td className={"index-col"}>{index + 1}</td>
      <td className={"has-hint-icon"}>
        {isFormOwner() &&
          <>
            {!isFormSubmitted() &&
              <>
                <a href="#" role={"button"} onClick={(ev) => onItemRowSelected(ev, item, index)}>
                  {getItemDisplayName()}
                </a>
                {item.isEditing && <i className="fa-solid fa-pen-to-square" style={{ color: 'gray' }}></i>}
              </>
            }
            {isFormSubmitted() && getItemDisplayName()}
          </>
        }
        {!isFormOwner() && getItemDisplayName()}
      </td>
      <td className={"right-aligned"}>
        <span className="currency-symbol">{getCurrency(currency).sign}</span>
        {formatCurrency(item['price'])}
      </td>
      <td className={"right-aligned"}>
        {item['quantity']}
      </td>
      <td className={"right-aligned"}>
        <span className="currency-symbol">{getCurrency(currency).sign}</span>
        {getItemAmount()}
      </td>
      <td>
        {item['remarks'] ? item['remarks'] : '-'}
      </td>
      <td className={"row-controls"}>
        {!isFormSubmitted() && isFormOwner() &&
          <button type={"button"} className={"btn btn-light btn-sm danger-button"} title={"Remove item"}
                  disabled={isLoading} onClick={(ev) => onItemRemoveClicked(ev, item, index)}>
            <i className="fa-solid fa-circle-minus"></i>
          </button>
        }
      </td>
    </tr>
  )
}

const QuotationFooterRow = (props) => {
  const {
    currency,
    quotationDetails,
  } = props;

  const calculateTotalAmount = () => {
    if (!quotationDetails || quotationDetails.length === 0) return 0;

    let amounts = quotationDetails.map(item => item['price'] * item['quantity']);
    return amounts.reduce((accumulated, current) => accumulated + current);
  }

  return (
    <tr className={"footer-row"}>
      <td colSpan={4} className={"right-aligned"}>
        Total:
      </td>
      <td className={"right-aligned"}>
        <span className="currency-symbol">{getCurrency(currency).sign}</span>
        {formatCurrency(calculateTotalAmount())}
      </td>

      <td colSpan={2}>
      </td>
    </tr>
  )
}

const QuotationEmptyRow = (
  { colSpan }
) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className={"table-empty-row"}>
          No data to display
        </div>
      </td>
    </tr>
  )
}

