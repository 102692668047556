import { useState } from "react";

import { refServices } from "../../repos/apiServices";
import { refServices as refServices2 } from "../../repos/apiServices2";
import { formModes } from "../../repos/constants";
import { sanitizeCurrencyValue } from "../../repos/utilities";
import { getCurrencyDisplay } from "../../repos/utilities";
import { getCurrency } from "../../repos/utilities";
import { formatCurrency } from "../../repos/utilities";

import { QuotationEntryTable } from "./QuotationEntryTable";
import { FieldErrorMessages } from "../shared/FieldErrorMessages";
import { AutoCompleteGroupedResults } from "../shared/AutoCompleteResults";
import { ReadonlyField } from "../shared/ReadonlyField";

export const EntryQuotationDetails = ({
                                        editingDetailsEntry,
                                        gensetOrATSKeyword, onGensetOrATSKeywordChanged,
                                        onGeneratorSuggestionClicked, onAtsSuggestionClicked,
                                        gensetModelId, atsId,
                                        price, onPriceChanged, onPriceBlurred,
                                        quantity, onQuantityChanged, onQuantityBlurred,
                                        remarks, onRemarksChanged, currency,
                                        hasErrors, formErrors, formMode, onAddClicked, onEditClicked, onClearClicked, onUpdateClicked, onCancelClicked,
                                        isFormElementDisabled, isSubmittingDetails, isFormSubmitted, isDetailsEditing, isFormOwner,
                                      }) => {
  //#region States
  const [generatorSuggestions, setGeneratorSuggestions] = useState([]);
  const [atsSuggestions, setAtsSuggestions] = useState([]);
  //#endregion

  //#region Utilities
  const isFormEditMode = () => {
    return editingDetailsEntry !== null;
  }

  const shouldRenderInputField = () => {
    if (editingDetailsEntry === null) return true;
    else if (isDetailsEditing) return true;
    else return false;
  }
  //#endregion

  //#region Control handlers
  let _prevKeyword = '';
  let _keyword = '';
  const onKeywordChanged = (ev) => {
    _prevKeyword = _keyword.trim();
    _keyword = ev.target.value.trim();

    if (_keyword.trim() === '') {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }
    let isKeywordChanged = onGensetOrATSKeywordChanged(ev, _keyword, _prevKeyword);
    if (!isKeywordChanged) return;

    if (_keyword.length >= 2) {
      refServices2.searchGeneratorsOrATS(_keyword)
        .then((response) => {
          let generators = response.data['generators'];
          let atsItems = response.data['atsItems'];
          setGeneratorSuggestions(generators);
          setAtsSuggestions(atsItems);
          expandSuggestionResults();
        })
        .catch((error) => {
          setGeneratorSuggestions([]);
          setAtsSuggestions([]);
        })
    } else {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }
  }

  const onKeywordBlurred = (ev) => {
    // a small delay to make sure that the selected suggestion is to be assigned properly to gensetModel state
    setTimeout(() => {
      setGeneratorSuggestions([]);
      setAtsSuggestions([]);
    }, 200);
  }

  const onGeneratorItemClicked = (ev, generator) => {
    onGeneratorSuggestionClicked(ev, generator);
    setGeneratorSuggestions([]);
    setAtsSuggestions([]);
  }

  const onAtsItemClicked = (ev, ats) => {
    onAtsSuggestionClicked(ev, ats);
    setGeneratorSuggestions([]);
    setAtsSuggestions([]);
  }
  //#endregion

  //#region Utilities
  const getGensetOrAtsClassName = () => {
    let className = "form-control";
    if (gensetModelId || atsId) className += " has-autocompleted-value";
    return className;
  }

  const hasSuggestionResults = () => {
    return generatorSuggestions.length > 0 || atsSuggestions.length > 0;
  }

  const expandSuggestionResults = () => {
    setTimeout(() => {
      let fieldElement = document.getElementById('gensetOrAtsKeyword');
      let suggestionsElement = document.getElementById('gensetOrAtsSuggestionResults');
      if (suggestionsElement) suggestionsElement.style.width = `${fieldElement.offsetWidth}px`;
    }, 100);
  }
  //#endregion

  //#region Render
  return (
    <>
      {formMode !== formModes.quotationRequest.newEntry && isFormOwner() &&
        <>
          <div className={"form-section"}>
            <h2>Quotation Details</h2>

            {!isFormSubmitted() &&
              <div className={"entry-form"}>
                <div className={"form-label"}>
                  <label htmlFor={"gensetOrAtsKeyword"}>
                    Genset Model (or ATS):<span className={"error-message"}>*</span>
                  </label>
                </div>
                <div className={"form-input"}>
                  {shouldRenderInputField() ?
                    <>
                      <input type={"text"} id={"gensetOrAtsKeyword"} className={getGensetOrAtsClassName()} autoComplete={"off"}
                             disabled={isFormElementDisabled()} placeholder={"Type keyword to search..."}
                             value={gensetOrATSKeyword} onChange={onKeywordChanged} onBlur={onKeywordBlurred} />
                      {hasSuggestionResults() &&
                        <AutoCompleteGroupedResults id={"gensetOrAtsSuggestionResults"}
                                                    collections={[generatorSuggestions, atsSuggestions]}
                                                    collectionLabels={["GENERATORS", "ATS"]}
                                                    collectionDisplayFields={["gensetModel", "name"]}
                                                    collectionItemClickHandlers={[onGeneratorItemClicked, onAtsItemClicked]} />
                      }
                      <FieldErrorMessages visible={hasErrors} messages={formErrors['keyword']} />
                    </>
                    :
                    <ReadonlyField>
                      {gensetOrATSKeyword}
                    </ReadonlyField>
                  }
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"dontIntervene4"}>
                    Price ({getCurrency(currency).sign}):<span className={"error-message"}>*</span>
                  </label>
                </div>
                <div className={"form-input"}>
                  {shouldRenderInputField() ?
                    <>
                      <input type={"text"} id={"dontIntervene4"} className={"form-control"} autoComplete={"none"} disabled={isFormElementDisabled()}
                             value={price} onChange={onPriceChanged} onBlur={onPriceBlurred} />
                      <FieldErrorMessages visible={hasErrors} messages={formErrors['price']} />
                    </>
                    :
                    <ReadonlyField>
                      {formatCurrency(price)}
                    </ReadonlyField>
                  }
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"dontIntervene4"}>
                    Quantity:<span className={"error-message"}>*</span>
                  </label>
                </div>
                <div className={"form-input"}>
                  {shouldRenderInputField() ?
                    <>
                      <input type={"text"} id={"dontIntervene4"} className={"form-control"} autoComplete={"none"} disabled={isFormElementDisabled()}
                             value={quantity} onChange={onQuantityChanged} onBlur={onQuantityBlurred} />
                      <FieldErrorMessages visible={hasErrors} messages={formErrors['quantity']} />
                    </>
                    :
                    <ReadonlyField>
                      {quantity}
                    </ReadonlyField>
                  }
                </div>

                <div className={"form-label"}>
                  <label htmlFor={"dontIntervene4"}>
                    Remark:
                  </label>
                </div>
                <div className={"form-input"}>
                  {shouldRenderInputField() ?
                    <>
                    <textarea id={"remarks"} className={"form-control"} rows={3} autoComplete={"none"} disabled={isFormElementDisabled()}
                              value={remarks} onChange={onRemarksChanged}>
                    </textarea>
                    </>
                    :
                    <ReadonlyField>
                      {remarks ? remarks : '-'}
                    </ReadonlyField>
                  }
                </div>
              </div>
            }

            <div className={"form-button-controls"}>
              <div className={"left-side"}>
                {!isFormSubmitted() && !isFormEditMode() &&
                  <button type={"button"} className={"btn btn-primary"} disabled={isFormElementDisabled()}
                          onClick={onAddClicked}>
                    {isSubmittingDetails && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                    {!isSubmittingDetails && <i className={"fa-solid fa-plus"}></i>}
                    <span>Add</span>
                  </button>
                }
                {!isFormSubmitted() && isFormEditMode() && !isDetailsEditing &&
                  <>
                    <button type={"button"} className={"btn btn-secondary"} disabled={isFormElementDisabled()}
                            onClick={onEditClicked}>
                      <i className="fa-solid fa-pen"></i>
                      <span>Edit</span>
                    </button>
                    <button type={"button"} className={"btn btn-secondary"} disabled={isFormElementDisabled()}
                            onClick={onClearClicked}>
                      <i className="fa-solid fa-xmark"></i>
                      <span>Clear</span>
                    </button>
                  </>
                }
                {!isFormSubmitted() && isFormEditMode() && isDetailsEditing &&
                  <>
                    <button type={"button"} className={"btn btn-primary"} disabled={isFormElementDisabled()}
                            onClick={onUpdateClicked}>
                      {isSubmittingDetails && <i className={"fa-solid fa-circle-notch fa-spin"}></i>}
                      {!isSubmittingDetails && <i className="fa-solid fa-check"></i>}
                      Update
                    </button>
                    <button type={"button"} className={"btn btn-secondary"} disabled={isFormElementDisabled()}
                            onClick={onCancelClicked}>
                      <i className="fa-solid fa-xmark"></i>
                      Cancel
                    </button>
                  </>
                }
              </div>
            </div>
          </div>
        </>
      }
    </>
  )
  //#endregion
}
