import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { useAuth } from "../auth/AuthProvider";
import { navigableRoutes as routes } from "../../repos/constants";
import { formatters } from "../../repos/constants";
import { salesServices } from "../../repos/apiServices";

import './DashboardWidget.css';
import './UpcomingRemindersWidget.css';


export const UpcomingRemindersWidget = () => {
  //#region States
  const [isLoading, setIsLoading] = useState(false);
  const [overdueReminders, setOverdueReminders] = useState(null);
  const [reminderGroups, setReminderGroups] = useState([]);
  const [reminders, setReminders] = useState(null);

  const auth = useAuth();
  const navigate = useNavigate();
  //#endregion

  //#region Effects
  useEffect(() => {
    fetchReminders();
  }, []);

  const fetchReminders = () => {
    setIsLoading(true);
    let options = {
      'view_options': {},
      'uid': auth.getUserId(),
    };
    salesServices.fetchDashboardUpcomingReminders(options)
      .then((response) => {
        setOverdueReminders(response.data['overdueReminders']);
        setReminderGroups(response.data['reminderGroups']);
        setReminders(response.data['reminders']);
      })
      .catch((error) => {
        console.log('ERROR:', error);
      })
      .finally(() => setIsLoading(false));
  }
  //#endregion

  //#region Utilities
  //#endregion

  //#region Control handlers
  const onRefreshClicked = (ev) => {
    fetchReminders();
  }

  const onEntryClicked = (ev, entry) => {
    setTimeout(() => {
      navigate(routes.salesInquiriesEntry.url, { state: entry });
    }, 100);
  }
  //#endregion

  //#region Render
  return (
    <div className={"content-area"}>
      <div className="row">
        <h1>
          Upcoming Inquiry Reminders
        </h1>
      </div>

      <div className={"dashboard-widget"}>
        <div className={"widget-floated-controls"}>
          <button className={"btn btn-secondary"} disabled={isLoading}
                  onClick={onRefreshClicked}>
            {isLoading && <i className="fa-solid fa-circle-notch fa-spin"></i>}
            {!isLoading && <i className="fa-solid fa-rotate"></i>}
            Refresh
          </button>
        </div>

        <div className={"widget-reminder-months"}>
          {isLoading && <RemindersLoadingIndicator />}
          {!isLoading && overdueReminders &&
            <OverdueReminderCard inquiryEntries={overdueReminders} onItemClicked={onEntryClicked} />
          }
          {!isLoading && reminderGroups.length > 0 && reminders && reminderGroups.map((group) =>
            <ReminderCard key={group} title={group} inquiryEntries={reminders[group]} onItemClicked={onEntryClicked} />
          )}
        </div>
      </div>
    </div>
  )
  //#endregion
}


const ReminderCard = ({
                        title,
                        inquiryEntries,
                        onItemClicked,
                      }) => {
  const formatDateDigits = (date) => {
    return date.getDate().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
  }

  return (
    <div className={"widget-reminder"}>
      <div className={"widget-badge"}>
        {inquiryEntries ? inquiryEntries.length : 0}
      </div>
      <div className={"widget-header"}>
        <h2>
          <i className="fa-solid fa-calendar-day"></i>
          {title}
        </h2>
      </div>
      <div className={"widget-body"}>
        {inquiryEntries && inquiryEntries.map((inquiry, index) =>
          <div className={"reminder-item"} key={index}>
            <button role={"button"} onClick={(ev) => onItemClicked(ev, inquiry)}>
              <div className={"item-display"}>
                <div className={"item-title"}>
                  {inquiry.contactFullName}
                </div>
                <div className={"item-subtitle"}>
                  {moment(inquiry.lastEntryDatetime).format(formatters.datetimeShort)}{inquiry.lastEntryGenerator && ` on ${inquiry.lastEntryGenerator.gensetModel}`}
                </div>
              </div>
              <div className={"item-date-badge"}>
                {formatDateDigits(new Date(inquiry.lastEntryReminderDatetime))}
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}


const OverdueReminderCard = ({
                               inquiryEntries,
                               onItemClicked,
                             }) => {
  const formatMonthDayDigits = (date) => {
    let day = date.getDate().toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
    let month = (date.getMonth() + 1).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
    return `${month}/${day}`;
  }

  return (
    <div className={"widget-reminder widget-overdue"}>
      <div className={"widget-badge"}>
        {inquiryEntries ? inquiryEntries.length : 0}
      </div>
      <div className={"widget-header"}>
        <h2>
          <i className="fa-solid fa-stopwatch"></i>
          Overdue
        </h2>
      </div>
      <div className={"widget-body"}>
        {inquiryEntries && inquiryEntries.map((inquiry, index) =>
          <div className={"reminder-item"} key={index}>
            <button role={"button"} onClick={(ev) => onItemClicked(ev, inquiry)}>
              <div className={"item-display"}>
                <div className={"item-title"}>
                  {inquiry.contactFullName}
                </div>
                <div className={"item-subtitle"}>
                  {moment(inquiry.lastEntryDatetime).format(formatters.datetimeShort)}{inquiry.lastEntryGenerator && ` on ${inquiry.lastEntryGenerator.gensetModel}`}
                </div>
              </div>
              <div className={"item-date-badge"}>
                {formatMonthDayDigits(new Date(inquiry.lastEntryReminderDatetime))}
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}


const RemindersLoadingIndicator = () => {
  return (
    <div className={"widget-loading-indicator"}>
      <i className="fa-solid fa-circle-notch fa-spin"></i>
    </div>
  )
}
